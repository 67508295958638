<!-- @format -->

<template>
<div class="d-flex flex-column" :class="[classVal, bottomOffset ? 'mb-4' : '']" style="position: relative">
    <label
        :for="id"
        v-if="label"
        class="tm-small-text font-weight-medium black--text"
        :class="[labelClassVal, labelBottomOffset ? 'mb-1' : '']"
    >{{ label }}</label
    >
    <template v-if="chips">
        <template v-if="previews.length && showChips">
            <ul class="invoice-attachments-list mb-10" :class="chipsBlock ? 'mw-unset' : null">
                <li v-for="(item, index) in previews" :key="index" :class="chipsBlock ? 'w-100' : null">

                    <i class="pr-2 color-dark-grey"
                    :class="iconAttachClass"></i>
                    <p class="smal-text bold-text color-black">
                        {{ cutText(item.name, 30) }}
                    </p>
                    <v-spacer></v-spacer>

                    <v-btn x-small color="#fa4769" icon @click="removeFile(item, index)">
                        <icon :name="iconTimesName" :class="iconTimesClass" :color="iconTimesColor"></icon>
                    </v-btn>
                </li>
            </ul>
        </template>
        <input
            ref="chipModeInput"
            @change="getFiles"
            :multiple="multiple"
            type="file"
            class="d-none"
            :accept="accept"
        />
        <a v-if="$slots.chipModeButton" @click="$refs.chipModeInput.click()">
            <slot name="chipModeButton"></slot>
        </a>
        <button
            v-else
            @click="$refs.chipModeInput.click()"
            class="btn btn-h-auto btn-p-0 btn-attach-file medium-text bold-text color-purple"
        >
            <i class="icon-attachments-v fs-24 pr-1"></i>
            Attach a file
        </button>
    </template>
    <template v-else>
        <template v-if="$scopedSlots['preview']">
            <div v-for="(item, index) in previews" :key="index">
                <slot name="preview" v-bind="{ item }"></slot>
            </div>
        </template>
        <div @drop="getDropFiles" @dragenter.prevent @dragover.prevent>
            <label
                :style="height ? { height: isNaN(height) ? height : height + 'px' } : {}"
                :class="smallSize ? 'h-65' : ''"
                class="tm-file-input-label w-100"
            >
                <div class="d-flex pa-6 align-center tm-small-text tm-color-dark-grey not-text-transform">
                    <slot v-if="$slots.placeholder" name="placeholder"></slot>
                    <p v-else class="text-center">
                        <span class="tm-color-purple font-weight-medium custom-btn-tertiary">Upload</span> or drag and drop your files here.
                    </p>
                </div>
                <input @change="getFiles" :multiple="multiple" type="file" class="d-none" :accept="accept"/>
            </label>
        </div>
        <template v-if="showPreviews && previews.length && !$scopedSlots['preview']">
            <div class="px-1 mt-4 tm-scroll-container" :class="previewCustomClasses" style="max-height: 100px">
                <div
                    v-for="(item, index) in previews"
                    :key="index"
                    class="d-flex align-center justify-space-between mb-1"
                >

                    <!-- Bottom Preview Slot -->
                    <slot
                        v-if="$scopedSlots['bottomPreview']"
                        name="bottomPreview"
                        v-bind="{ item, index }"
                    ></slot>

                    <!-- Default Previews -->
                    <template v-else>
                        <div class="d-flex align-center">
                            <icon name="icon-pdf" class="mr-2" color="red" size="14"></icon>
                            <span
                                class="tm-small-text font-weight-medium black--text text-none cut-test"
                                :class="uploadedFilenameCustomClasses"
                                style="max-width: 24rem"
                            >
                                    {{ item.name }}
                                </span>
                        </div>
                        <div>
                            <a v-if="download && downloadByLink && item.download_link" :href="item.download_link"
                               download class="custom-upload-area-download-link">
                                <icon color="black" name="icon-download" size="0.625rem"></icon>
                            </a>
                            <v-btn
                                v-if="download && item.id && !downloadByLink"
                                style="margin-right: -4px"
                                x-small
                                color="black"
                                icon
                                @click="$emit('download', item)"
                            >
                                <icon color="black" name="icon-download" size="0.625rem"></icon>
                            </v-btn>
                            <v-btn
                                style="margin-right: -4px"
                                x-small
                                color="black"
                                icon
                                @click="removeFile(item, index)"
                            >
                                <icon color="black" name="icon-times" size="0.625rem"></icon>
                            </v-btn>
                        </div>
                    </template>
                </div>
            </div>
            <v-divider class="mb-2"></v-divider>
        </template>
    </template>
    <error-message :messages="errorMessages" :left="chips"></error-message>
</div>
</template>
<script>
import ErrorMessage from './ErrorMessage';
import Helpers from '~/shared/mixins/Helpers';

export default {
    name: 'TmUploadArea',
    components: {ErrorMessage},
    mixins: [Helpers],
    props: {
        accept: {
            default: '*',
        },
        download: {
            default: false,
        },
        showChips: {
            default: true,
        },
        showPreviews: {
            Type: Boolean,
            default: true,
        },
        smallSize: {
            default: false,
        },
        value: {
            default: () => [],
        },
        label: {
            default: null,
        },
        classVal: {
            default: '',
        },
        labelBottomOffset: {
            default: true,
        },
        labelClassVal: {
            default: '',
        },
        previewFiles: {
            default: () => [],
        },
        previewDocuments: {
            default: () => [],
        },
        bottomOffset: {
            default: true,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        id: {
            default: null,
        },
        height: {
            default: null,
        },
        errorMessages: {
            type: Array,
            default: () => [],
        },
        chips: {
            default: false,
        },
        chipsBlock: {
            default: false,
        },
        filesTypes: {
            default: () => ['*'],
        },
        filesMaxMb: {
            default: null,
        },
        downloadByLink: {
            default: false,
        },
        iconTimesColor:{
            default: '#fa4769'
        },
        iconTimesClass:{
            default: ''
        },
        iconTimesName:{
            default: 'icon-times-circle-outline'
        },
        iconAttachClass:{
            default: 'icon-attachments-v'
        },
        previewCustomClasses: {
            type: String,
            default: '',
        },
        uploadedFilenameCustomClasses: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            val: this.value,
            previews: [],
            deletes: [],
        };
    },
    watch: {
        val() {
            this.$emit('input', this.val);
            this.$emit('previewsObject', this.previews);
        },
        value() {
            this.val = this.value;
            if (!this.value.length) {
                this.previews = [];
            }
        },
        deletes() {
            this.$emit('delete', this.deletes);
        },
        /* previewFiles(){
            this.previews = this.previews.filter(item => !item.id);
            this.setPreviews();
        }, */
        previewDocuments() {
            this.previews = this.previews.filter(item => !item.id);
            this.setPreviews();
        },
    },
    methods: {
        getDropFiles(e) {
            e.preventDefault();
            if (e.dataTransfer.items) {
                for (let i = 0; i < e.dataTransfer.items.length; i++) {
                    if (e.dataTransfer.items[i].kind === 'file') {
                        this.setFile(e.dataTransfer.items[i].getAsFile());
                    }
                }
            } else {
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    this.setFile(e.dataTransfer.files[i]);
                }
            }
        },
        getFiles(e) {
            for (let i = 0; i < e.target.files.length; i++) {
                this.setFile(e.target.files[i]);
            }
            e.target.value = null;
        },
        setFile(file) {
            if (!this.filesTypes.includes('*')){
                if (this.filesTypes.indexOf('image/*') > -1) {
                    if (file.type.indexOf('image') < 0) {
                        if (!_.isEmpty(this.filesTypes) && !this.filesTypes.includes(file.type)) {
                            return;
                        }
                    }
                } else {
                    if (!_.isEmpty(this.filesTypes) && !this.filesTypes.includes(file.type)) {
                        return;
                    }
                }
            }

            if (this.filesMaxMb && file.size > this.filesMaxMb * 1000000) {
                return;
            }

            let index = this.val.length;
            if (this.multiple === false) {
                this.val = [];
                this.previews = [];
            }
            this.val.push(file);
            this.previews.push({
                index: index,
                name: file.name,
                uri: URL.createObjectURL(file),
            });
        },
        setPreviews() {
            this.previewFiles.forEach(item => {
                this.previews.push({
                    id: item.id,
                    object: 'file',
                    name: item.name,
                    download_link: item.download_link ?? null,
                });
            });
            this.previewDocuments.forEach(item => {
                this.previews.push({
                    id: item.id,
                    object: 'document',
                    name: item.name,
                });
            });
        },
        removeFile(file, previewIndex) {
            if (file.id) {
                if (file.object === 'file') {
                    if (!this.deletes.includes(file.id)) this.deletes.push(file.id);
                } else if (file.object === 'document') {
                    this.$emit('deleteDocument', file.id);
                }
                this.previews.splice(previewIndex, 1);
            } else {
                if (this.$refs.chipModeInput) {
                    this.$refs.chipModeInput.value = '';
                }
                this.val.splice(file.index, 1);
                this.previews.splice(previewIndex, 1);
            }
        },
        openFileDialog() {
            this.$refs.fileUploadInput.click();
        },
    },
    created() {
        this.setPreviews();
    },
};
</script>
<style>
.custom-upload-area-download-link {
    display: inline-block;
    cursor: pointer;
}
</style>
