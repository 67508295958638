export default {
    props: {
        confirmation: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        confirm() {
            if (this.confirmation) {
                this.$store.dispatch("confirmation/run", { callback: () => {
                    this.$emit('confirmed');
                }});
            } else {
                this.$emit('confirmed');
            }
        },
    },
};