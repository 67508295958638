// initial state
const state = {
    items: [],
    europeanItems: [],
};

// getters
const getters = {
    items: (state) => state.items,
    europeanItems: (state) => state.europeanItems,
}

// mutations
const mutations = {
    items(state, payload) {
        state.items = payload;
    },
    europeanItems(state, payload) {
        state.europeanItems = payload;
    },
}

// actions
const actions = {
    getItems({ commit, rootState }) {
        commit('items', rootState.allCountries);
        return rootState.allCountries;
    },
    getEuropeanItems({ commit, dispatch }) {
        return axios
        .get('/european-countries')
        .then(response => {
            commit('europeanItems', response.data.data);
            return response.data;
        })
        .catch(error => {
            commit('setAlert', {type: 'error', message: error.message}, { root: true });
            throw error;
        });
    },
    onceGetItems: _.once(function ({ dispatch }) {
        return dispatch('getItems');
    }),
    onceGetEuropeanItems: _.once(function ({ dispatch }) {
        return dispatch('getEuropeanItems');
    }),
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
