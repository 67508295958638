<template>
  <div class="field-group">
    <div class="d-flex">
      <label
        v-if="$slots.label || label"
        class="text-sm color-black custom-label"
        :for="label"
      >
        <slot v-if="$slots.label" name="label"></slot>
        <span v-else>{{ label }}</span>
      </label>
      <label
        v-if="$slots.requiredText || requiredText"
        class="custom-label ml-1 text-sm color-feedback-error"
      >
        <slot v-if="$slots.requiredText" name="requiredText"></slot>
        <span>{{ requiredText }}</span>
      </label>
    </div>
    <p
      v-if="$slots.subLabel || subLabel"
      class="text-sm color-grey-3 leading-130 mb-1"
    >
      <slot v-if="$slots.subLabel" name="subLabel"></slot>
      <span v-else>{{ subLabel }}</span>
    </p>
    <v-textarea
      :hide-details="true"
      :name="label"
      :id="label"
      class="textarea"
      v-on="$listeners"
      :class="[{ 'error': errorMessages.length }, customClasses]"
      :placeholder="placeholder"
      v-model="val"
    ></v-textarea>

    <BaseErrorMessage :messages="errorMessages" />
  </div>
</template>

<script>
export default {
  name: "WlBaseTextarea",

  props: {
    label: {
      type: String,
      default: "",
    },
    requiredText: {
      type: [String, null],
      default: null,
    },
    subLabel: {
      type: [String, null],
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      default: null,
    },
    errorMessages: {
      default: () => [],
    },
    customClasses: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      val: this.value,
    };
  },

  watch: {
    val() {
      this.$emit("input", this.val);
    },
    value() {
      this.val = this.value;
    },
  },
};
</script>
