<template>
  <v-btn
    :ripple="false"
    :class="{ active: isActive }"
    v-on="$listeners"
    class="btn-module"
    @mouseover="handleMouseover"
  >
      <div class="d-flex align-center w-full">
      <div class="btn-image">
        <slot></slot>
      </div>
      <BaseIcon name="chevron-right-2" class="ml-auto" />
    </div>
  </v-btn>
</template>

<script>
export default {
  name: "ModuleButton",

  props: {
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      val: this.value,
    };
  },

  computed: {
    isActive() {
      return this.val === this.name;
    },
  },

  methods: {
    handleMouseover() {
      this.val = this.name;
      this.$emit("input", this.name);
    },
  },

  watch: {
    value(val) {
      this.val = val;
    },
  },
};
</script>

<style scoped>
.w-full{
    width: 100%;
}
.v-btn:not(.v-btn--round).v-size--default.btn-module {
  padding: 1.5rem 2rem !important;
  height: 6.25rem !important;
  border-radius: 22px !important;
  background: #f2f5fa !important;
  width: 100%;
  justify-content: space-between !important;

  &:hover {
    &::before {
      opacity: 0;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &.active {
    i {
      opacity: 1;
    }
  }

  i {
    color: #5152fb;
    width: 1rem;
    height: 1rem;
    align-items: center !important;
    justify-content: center;
    display: flex;
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

      @media (max-width: 640px) {
          opacity: 0 !important;
      }

  }
}
</style>
