import Middleware from "~/routes/middleware/Middleware";
import store from "~/store";

export default class init extends Middleware{
    constructor(...args) {
        super(...args);
    }

    async resolve(){
        return !store.state.initialization.initialized && !store.state.initialization.loading?
            await store.dispatch('initialization/__initState').then(() => this.responseNext()):
            this.responseNext();
    }
}
