<template>
    <div class="d-flex flex-column" :style="{'min-width': width? isNaN(width)? width: (width +'px'): '0.75rem'}"

         :class="[bottomOffset ? 'mb-4' : 'mb-0', boxClass]">
        <template v-if="$slots.label">
            <slot name="label"></slot>
        </template>
        <template v-else-if="$scopedSlots['selectLabel']">
            <slot name="selectLabel"></slot>
        </template>
        <label
            :for="id"
            v-else-if="label"
            class="font-weight-medium"
            :class="[disabled? 'tm-color-dark-grey': 'black--text', labelClassVal, labelBottomOffset? 'mb-1': '']"
        >
            {{ label }}
            <span v-if="asterisk" class="color-red ml-1">*</span>
        </label>
        <div style="position: relative" class="dropdown "
        :class=" attach? attacheClass+ ' position-relative' :''">
            <v-menu
                :disabled="disabled"
                z-index="9999"
                style="border-radius: 0.3125rem;"
                :nudge-bottom="nudgeBottom"
                v-model="menu"
                :min-width="minWidth"
                :left="left"
                :bottom="bottom"
                :attach="attach? `.${attacheClass}` : false"
                offset-y
                :close-on-content-click="!multiple && !search"
            >
                <template v-slot:activator="{ attrs }">
                    <button
                        :disabled="disabledVal"
                        v-bind="attrs"
                        @click.prevent="menu = !menu"
                        style="position: relative;"
                        :style="inlineStyle"
                        :class="[
                            defaultClass,
                            errorMessages.length? 'tm-err': null,
                            clearable? 'pr-8': 'pr-5',
                            disabled? 'tm-color-dark-grey': 'black--text',
                            paddingLeft? 'pl-2': 'pl-0',
                            addClass
                        ]"
                    >
                        <template v-if="$scopedSlots['selection']">
                            <slot v-if="selectedItem" name="selection" v-bind:item="selectedItem"></slot>
                            <div v-if="selectedItems.length" class="d-flex align-center flex-wrap">
                                <slot name="selection" v-bind:items="selectedItems"></slot>
                            </div>
                        </template>
                        <template v-else-if="chips">
                            <div v-if="selectedItems.length" class="d-flex align-center flex-wrap w-100" :style="styleChips" @click="resetMenuPositionAction">
                                <v-chip class="ma-1 px-1 ml-0" v-for="item in selectedItems" :key="item.id" label small :color="chipsColor">
                                    <v-btn v-if="chipsClear && chipsClearLeft" @click.prevent.stop="chipClear(item[itemValue])" x-small icon :class="chipsClearLeftClass">
                                        <icon class="icon-times" :rem="true" size="0.625"></icon>
                                    </v-btn>
                                    <avatar v-if="chipsIcon"  size="18" :name="item[itemText]" :avatar="item[chipsIcon]"></avatar>
                                    <span class="font-weight-medium advanced-chip-style" style="font-size: 0.8125rem">{{ item[itemText] }}</span>
                                    <v-btn v-if="chipsClear && !chipsClearLeft" @click.prevent.stop="chipClear(item[itemValue])" x-small icon class="ml-1">
                                        <icon class="icon-times" :rem="true" size="0.625"></icon>
                                    </v-btn>
                                </v-chip>
                            </div>
                        </template>
                        <span v-if="showText" :class="[!text? 'tm-color-dark-grey': null, placeholderClass || null]" style="line-height: initial">{{ text || __selectPlaceholder }}</span>
                        <icon
                            v-if="showChevron"
                            :rotate="chevronReverse? !menu: menu"
                            :size="chevronSize"
                            :color="disabled? '#dadfe6': chevronColor"
                            :name="chevronIcon"
                            style="position: absolute; top: 50%; transform: translate(0, -50%); right: 6px"
                            :style="{'margin-top': chevronNudgeTop? chevronNudgeTop: ''}"
                        ></icon>
                        <v-btn v-if="clearable && val" @click.stop="val = null" x-small icon style="position: absolute; top: 50%; right: 20px; transform: translate(0, -50%);">
                            <icon size="0.563" :rem="true" name="icon-times"></icon>
                        </v-btn>
                    </button>
                </template>
                <v-list :class="listP? 'pa-2': 'pa-0'">
                    <v-list-item v-if="search" :class="listItemP? 'px-0': 'px-2'" style="min-height: auto">
                        <v-list-item-content class="py-0">
                            <div>
                                <div :class="[!listP? 'mt-2': null, widthSelectSearch ? widthSelectSearch : null]" class="mb-2 tm-input d-flex align-center justify-space-between">
                                    <input
                                        class="w-100"
                                        :placeholder="searchPlaceholder"
                                        v-model="searchValue"
                                        @keyup="debounce([searchValueAction, $event])"
                                        v-on:keyup.enter="onSearchEnter()"
                                    >
                                    <div v-if="searchLoading" class="pr-1">
                                        <v-progress-circular indeterminate size="20" width="2" color="#5152fb"></v-progress-circular>
                                    </div>
                                </div>
                                <v-divider class="mb-1"></v-divider>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                    <slot v-if="$scopedSlots['itemsPrepend']" name="itemsPrepend"/>
                    <div style="max-height: 12.375rem;" @scroll="scrollAction" ref="content" class="tm-scroll-container" :class="[listItemGroupClass, listScroll ? 'list-scroll' : '']">
                        <v-list-item-group v-if="showItems.length" :active-class="activeClass" color="#ddf3ff" :multiple="multiple" v-model="val" @change="$emit('change', $event)">
                            <v-list-item
                                :disabled="disableSelected && (!multiple && item[itemValue] === val) || (disabledKey && item[disabledKey]) || (item.locked && !item.placeholder)"
                                style="
                                border: none !important;
                                min-height: 20px;
                                color: var(--typography-black-color);"
                                :class="[listItemP? 'pa-1 mt-1 mb-1': 'pa-0', item && item.nohover? '': 'tm-sm-select-item', listItemClass]"
                                v-for="(item, index) in localItems"
                                :key='searchRoute ? getKey(item[itemValue], itemText) : index'
                                :value="item[itemValue]"
                                v-show="(!multiple || !val.includes(item[itemValue]) || !chips || !hideSelected) && checkVisible(item)"
                                @click="pushMenuDownAction"
                                :inactive="item.locked"
                            >
                                <slot v-if="$scopedSlots['item']" name="item" :item="item" :index="index"></slot>
                                <v-list-item-title v-else style="font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1rem;" :class="itemAddClass || 'text-capitalize'" v-text="item[itemText]"></v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                        <template v-else-if="showEmpty">
                            <slot v-if="$slots.empty" name="empty"></slot>
                            <v-list-item v-else>
                                <v-list-item-subtitle>
                                    <div class="text-center">no item to display</div>
                                </v-list-item-subtitle>
                            </v-list-item>
                        </template>
                    </div>
                </v-list>
            </v-menu>
            <template v-if="$slots.afterText">
              <slot name="afterText"></slot>
            </template>
            <error-message :messages="errorMessages"></error-message>
        </div>
    </div>
</template>
<style scoped>
.v-chip .v-avatar {
    height: 1.125rem!important;
    min-width: 1.125rem!important;
    width:1.125rem!important;
}
</style>
<script>
import ErrorMessage from "./ErrorMessage";
import TmInput from "./TmInput";
import Avatar from "../Avatar";
import Helpers from "~/shared/mixins/Helpers";
import Form from "~/shared/mixins/Form";
export default {
    name: 'TmAdvancedSelect',
    components: {Avatar, TmInput, ErrorMessage},
    props: {
        bottom: {
            default: true
        },
        attach: {
            default: false
        },
        left: {
            default: false
        },
        bottomOffset: {
            default: true
        },
        labelBottomOffset: {
            default: true
        },
        showEmpty: {
            default: true
        },
        labelClassVal: {
            default: 'tm-small-text '
        },
        fontSize: {
            default: '0.8125rem;'
        },
        chips: {
            default: false
        },
        chipsClear: {
            default: false
        },
        chipsClearLeft: {
            default: false
        },
        chipsClearLeftClass: {
            default: ''
        },
        chipsIcon: {
            default: false
        },
        isSort: {
            default: true
        },
        minWidth: {
            default: 150
        },
        pushMenuDownFor: {
            default: null
        },
        chipsColor: {
            default: '#ddf3ff'
        },
        id: {
            default: null
        },
        placeholder: {
            default: 'Select'
        },
        placeholderClass:{
          default: null
        },
        label: {
            default: null
        },
        items: {
            default(){
                return []
            }
        },
        itemValue: {
            default: 'id'
        },
        itemText: {
            default: 'name'
        },
        value: {
            default: null
        },
        multiple: {
            default: false
        },
        clearable: {
            default: false
        },
        search: {
            default: false
        },
        searchPlaceholder: {
            default: 'Search'
        },
        searchRoute: {
            default: null
        },
        searchAutoload: {
            default: true
        },
        height: {
            default: null
        },
        width: {
            default: null
        },
        chevronColor: {
            default: '#5152fb'
        },
        chevronIcon: {
            default: 'icon-chevron-down'
        },
        chevronSize: {
            default: '13'
        },
        chevronReverse: {
            default: false
        },
        chevronNudgeTop: {
            default: '1px'
        },
        showChevron: {
            default: true
        },
        boxClass: {
            default: null
        },
        borderRadius: {
            default: '0.1875rem'
        },
        bordered: {
            default: true
        },
        xSmall: {
            default: false
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            }
        },
        disabled: {
          default: null
        },
        disabledKey: {
            default: null
        },
        listItemP: {
            default: true
        },
        listP: {
            default: true
        },
        widthSelectSearch: {
            default: null
        },
        itemAddClass: {
            default: null
        },
        closeAfterClick: {
            default: true
        },
        searchAdditionalParams: {
            type: Object,
            default: () => {}
        },
        addParamsToEveryRequest: {
            default: false,
        },
        addClass: {
            default: null
        },
        defaultClass: {
            default: 'tm-input d-flex justify-space-between align-center'
        },
        activeClass: {
            default: 'tm-sm-select-active'
        },
        paddingLeft: {
            default: true
        },
        listItemClass: {
            default: ''
        },
        listItemGroupClass: {
            default: ''
        },

        createRoute: {
            default: null
        },
        createLength: {
            default: 3
        },

        getRoute: {
            default: null
        },

        applyInlineStyle: {
            default: true
        },

        menuOpen: {
            default: false
        },
        disableSelected: {
            default: true
        },
        hideSelected: {
            default: true
        },
        selectFirst: {
            default: false
        },
        selectCustom: {
            default: false
        },
        prependData: {
            type: Array,
            default: () => []
        },
        multipleStyle: {
            default: ''
        },
        asterisk: {
            type: Boolean,
            default: false
        },
        listScroll: {
          default: true
        }
    },
    mixins: [Helpers, Form],
    data(){
        return {
            val: this.value,
            menu: this.menuOpen,
            searchValue: null,
            localItems: this.items,
            searchLoading: false,
            offset: 0,
            nudgeBottom: 10,
            viewItems: [],
            searching: false,
        }
    },
    watch: {
        items: {
            deep: true,
            handler(){
                this.localItems = this.isSort && !this.multiple ? this._orderByData(this.items, this.itemText) : this.items;
                this.searchLocal();
            }
        },
        val(n, o){
            if(n === undefined) {
                this.menu = true;
                this.val = o;
            }
            this.$emit('input', this.val);
            if(this.closeAfterClick && !this.searching) {
                this.menu = false;
            }

            if (!n && this.selectFirst){
                this.selectFirstItem(this.items);
            }
        },
        value(){
            this.val = this.value;
        },
        menu(v){
            this.$emit('openMenu',v);
            if(v && this.searchValue) {
                if (this.multiple){
                    this.searchValue = null;
                    this.searchValueAction();
                }
            }
        },
        selectedItem(){
            this.$emit('inputObject', this.selectedItem);
        },
        selectedItems(){
            this.$emit('inputObject', this.selectedItems);
        },
        menuOpen() {
          this.menu = this.menuOpen;
        }
    },
    computed: {
        attacheClass () {
          return this.attach ? btoa(+new Date).slice(-7, -2) : '';
        },
        styleChips () {
          return this.multipleStyle && this.selectedItems.length > 2 ? this.multipleStyle : '';
        },
        disabledVal() {
            if(this.disabled) {
                return this.disabled;
            }
            return !this.showEmpty && !this.showItems.length
        },
        inlineStyle(){
            return this.applyInlineStyle ? {'font-size': this.fontSize, 'min-height': this.height? isNaN(this.height)? this.height: this.height+'px': '2.1875rem', 'border-radius': this.borderRadius, width: this.width? this.width+'px': null, border: !this.bordered? 'none': null} : null;
        },
        showText(){
            if (this.$scopedSlots['selection'] || this.chips){
                return !this.selectedItems.length && !this.selectedItem
            }else {
                return true
            }
        },
        text(){
            if (this.selectedItem || this.selectedItems.length){
                if (this.multiple){
                    let texts = [];
                    this.selectedItems.forEach(item => texts.push(item[this.itemText]));
                    return texts.join(', ')
                }else {
                    return this.selectedItem[this.itemText];
                }
            }
        },
        selectedItems(){
            return this.multiple? this.localItems.filter(item => this.val.includes(item[this.itemValue])): [];
        },
        selectedItem(){
            return !this.multiple? this.localItems.find(item => item[this.itemValue] === this.val): null
        },
        showItems(){
            return this.hideSelected && this.multiple && this.chips && this.chipsClear? this.localItems.filter(item => !this.val.includes(item[this.itemValue])): this.localItems;
        }
    },
    methods: {
        checkVisible(item){
            return !this.search || this.searchRoute || this.viewItems.includes(item[this.itemValue])
        },

        getKey(text, t) {
            if(typeof text == 'object') {
                text = Math.floor(Math.random() * 12);
            }
            text  = text.toString().replace(/\+/g, '_');
            t  = t.toString().replace(/\+/g, '_');
            return  `select_key_${text}_${t}_${this.itemValue}_${this.$attrs.name || 'el_name'} `;
        },
        searchValueAction(){
            this.offset = 0;
            this.searching = true;
            this.searchRoute? this.searchByRoute(): this.searchLocal();
        },
        chipClear(key){
            let index = this.val.indexOf(key);
            this.val.splice(index, 1);

            if(this.pushMenuDownFor){
                let value = this.nudgeBottom - this.pushMenuDownFor;
                if(value > 10){
                    this.nudgeBottom = value;
                }
            }
        },
        searchByRoute(first = false, offset = false){
            if (this.search){
                this.searchLoading = true;
                let params = !offset? {...this.searchAdditionalParams}: {};
                if(offset && this.addParamsToEveryRequest){
                    params = {...this.searchAdditionalParams};
                    delete params.id;
                }
                params.value = this.searchValue;
                params.offset = offset? this.offset: 0;
                axios.get(this.searchRoute, {params: params})
                    .then(response => {
                        const {data} = response;

                        if (this.offset && offset){
                            let currentData = response.data;
                            const ids = this.val ?? [];
                            if(!_.isEmpty(this.searchAdditionalParams)) {
                                currentData = this.multiple?
                                    currentData.filter(i => !ids.includes(i.id)):
                                    currentData.filter(i => ids !== i.id);
                            }
                            this.localItems = this.localItems.concat(currentData);
                        }else{
                            this.$refs.content? this.$refs.content.scrollTop = 0: null;
                            let findItemsOne = this.multiple?
                                data.filter(item => this.val.includes(item[this.itemValue])):
                                data.filter(item => this.val === item[this.itemValue]);

                            let findItems = this.multiple?
                                data.filter(item => !this.val.includes(item[this.itemValue])):
                                data.filter(item => this.val !== item[this.itemValue]);
                            findItems = this.isSort && !this.multiple ? this._orderByData(findItems, this.itemText): findItems;
                            if(Object.keys(this.prependData).length) {
                                findItemsOne = findItemsOne.concat(this.prependData);
                            }
                            this.localItems = findItemsOne.concat(findItems);
                        }
                        this.offset = response.data.length? this.offset + 20: false;
                        this.searchLoading = false;

                        if (first && this.selectFirst){
                            this.selectFirstItem(this.localItems);
                        }

                        if (first && this.selectCustom) {
                            if (this.val === this.selectCustom[this.itemValue] && !this.localItems.find(item => item.id === this.selectCustom[this.itemValue])) {
                                this.localItems.unshift(this.selectCustom);
                             } else {
                                this.localItems.sort();
                             }
                        }
                    })
                    .catch(error => {
                        this.searchLoading = false;
                        this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                    })
                    .finally(() => this.searching = false)
            }
        },
        getData(){
            this.searchLoading = true;
            axios.get(this.getRoute)
                .then(response => {
                    this.searchLoading = false;
                    const {data} = response;
                    this.localItems = this.isSort && !this.multiple? this._orderByData(data, this.itemText): data;
                })
                .catch(error => {
                    this.searchLoading = false;
                    this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                })
        },
        onSearchEnter(){
            this.$emit('searchEnter');
            if (
                this.createRoute &&
                this.searchValue &&
                this.searchValue.trim() &&
                this.searchValue.trim().length >= this.createLength
            ){
                this.searchLoading = true;
                axios.post(this.createRoute, {value: this.searchValue})
                    .then(response => {
                        this.searchLoading = false;
                        this.localItems.unshift(response.data);
                        this.searchValue = null;
                        this.multiple? this.val.unshift(response.data.id): this.val = response.data.id;
                    })
                    .catch(error => {
                        this.searchLoading  = false;
                        this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                    })
            }
        },
        scrollAction (e) {
            if(this.offset === false || !this.search || !this.searchRoute) {
                return;
            }
            const parent = e.target.closest('.tm-scroll-container');
            const maxHeight = Math.ceil(parent.offsetHeight + parent.scrollTop);
            if (maxHeight >= parent.scrollHeight && !this.searchLoading) {
                this.searchByRoute(false, true)
            }
        },
        pushMenuDownAction(){
            if(this.pushMenuDownFor){
                let value = this.nudgeBottom + this.pushMenuDownFor;
                if(value > 10 && value < 250){
                    this.nudgeBottom = value;
                }
            }
        },
        resetMenuPositionAction(){
            if(this.pushMenuDownFor) {
                this.nudgeBottom = 10;
            }
        },
        searchLocal() {
            const items = this.searchValue? this.localItems.filter(i =>
                (i[this.itemText] && this.val && this.val === i[this.itemText]) ||
                (i[this.itemText].toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1 &&
                    i[this.itemText.toLowerCase()].indexOf(this.searchValue.toLowerCase()) < 2)
            ): this.localItems;

            this.viewItems = items.map(i => i[this.itemValue]);
            this.searching = false;
        },

        selectFirstItem(items){
            if (items.length){
                this.val = items[0][this.itemValue];
            }
        },

    },
    mounted() {
        if (this.selectFirst) this.selectFirstItem(this.items);
        if (this.selectCustom) {
            if (!this.items.find(item => item.id === this.selectCustom[this.itemValue])) this.items.unshift(this.selectCustom);
            this.val = this.selectCustom[this.itemValue];
        }

        this.$emit('mounted');
    },
    created() {
        this.viewItems = this.items.map(i => i[this.itemValue]);
        if (this.searchAutoload){
           this.searchRoute? this.searchByRoute(true): this.searchLocal()
        }else if (this.getRoute){
            this.getData()
        }
    }
}
</script>
<style>
.tm-sm-select-item:hover{
    background-color: var(--bg-light-grey-color-2);
}
.tm-sm-select-active{
    background-color: var(--bg-light-grey-color-2);
}
.tm-sm-select-active::before{
    opacity: 0!important;
}
.theme--light.v-list-item--active:before, .theme--light.v-list-item--active:hover:before, .theme--light.v-list-item:focus:before{
    opacity: 0!important;
}
</style>
<style scoped>
.theme--light.v-list-item:hover:before {
    opacity: 0!important;
}
.alert {
    border: 1px solid red !important;
}
.list-scroll {
    overflow: scroll !important;
}
</style>
