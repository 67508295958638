<template>
    <div class="profile-menu-main">
        <!-- USER CONTAINER -->
        <div class="user-container text-ellipsis mw-100 mb-5">
            <BaseAvatar
                :name="worker?.name"
                size="3rem"
                class="user-avatar"
            />
            <div class="d-flex flex-column text-ellipsis">
                <p class="text-base font-medium welcome-text">Welcome</p>
                <p class="user-name text-ellipsis">
                    {{ worker?.name }}
                </p>
            </div>
            <v-btn
                v-if="canOpenMenu"
                small
                icon
                class="collapse-btn"
                @click="organisationsOpened = !organisationsOpened"
            >
                <BaseIcon
                    :class="{ rotated: organisationsOpened }"
                    name="chevron-collapsed"
                    class="animation-rotate"
                />
            </v-btn>
        </div>
        <div class="organisation-switcher-container selected-organisation">
            <a
                v-if="currentOrganization"
                class="organisation-item active"
            >
                <BaseAvatar
                    avatar="users/user-1.svg"
                    size="1.5rem"
                    class="organisation-avatar"
                    :object="currentOrganization"
                />
                <p class="organisation-name text-base">
                    {{ currentOrganization.name }}
                </p>
            </a>
        </div>
        <!-- ORGANISATION SWITCHER -->
        <v-expand-transition v-if="canOpenMenu && ($store.state.organizations.length || canAddOrg)">
            <div v-if="organisationsOpened" class="organisation-switcher-wrapper">
                <div
                    class="organisation-switcher-container"
                >
                    <p class="text-title text-sm font-medium mb-3">Switch account</p>
                    <a
                        v-if="organization.id !== currentOrganization?.id"
                        v-for="organization in organizations"
                        :key="organization.id"
                        class="organisation-item"
                        @click="changeOrganization(organization.id)"
                    >
                        <BaseAvatar
                            avatar="users/user-1.svg"
                            size="1.5rem"
                            class="organisation-avatar"
                            :object="organization"
                        />
                        <p class="organisation-name text-base color-white">
                            {{ organization.name }}
                        </p>
                    </a>

                    <WlBaseButton v-if="canAddOrg" @click="showAddOrg()"
                                  type="secondary" size="big" class="w-full">
                        <BaseIcon name="plus-circle-outline-bold" class="mr-2 text-xl"/>
                        Add organisation
                    </WlBaseButton>
                </div>
            </div>
        </v-expand-transition>

        <template v-if="showSwitchView">
            <div class="switch-wallet-view">
                <p>Switch wallet view</p>
                <WlBaseButton
                    class="w-full text-base btn-personal-wallet mb-2"
                    @click="setActiveWallet('personal')"
                    :type="walletSwitcher == 'personal' ? 'primary' : 'secondary'"
                >
                    <WlBaseIcon name="user-outline-circle-thin mr-2" class="text-xl mr-2"/>
                    Personal wallet
                </WlBaseButton>

                <WlBaseButton
                    class="w-full text-base"
                    :type="walletSwitcher == 'legalEntity' ? 'primary' : 'secondary'"
                    v-if="!nameSlice"
                    @click="setActiveWallet('legalEntity')"
                >
                    <BaseIcon name="briefcase mr-2 text-xl"/>
                    {{ companyName }}
                </WlBaseButton
                >

                <WlBaseTooltip
                    v-else
                    bottom
                    content-class="new-wallet-tooltip-content legal-entity-tooltip"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <WlBaseButton
                            class="w-full text-base btn-company-name"
                            @click="setActiveWallet('legalEntity')"
                            v-on="on"
                            v-bind="attrs"
                            :type="walletSwitcher == 'legalEntity' ? 'primary' : 'secondary'"
                        >
                            <BaseIcon name="briefcase mr-2 text-xl"/>
                            {{ nameSlice }}
                        </WlBaseButton
                        >
                    </template>
                    <p class="color-grey-1">
                        {{ companyName }}
                    </p>
                </WlBaseTooltip>
            </div>

            <!--          <div v-else class="switch-wallet-view-mobile">
                          <WlBaseSelect
                              item-value="value"
                              v-model="entitySwitch"
                              contentClass="switch-wallet-view-dropdown"
                              :items="legalEntityOptions"
                          >
                              <template v-slot:selection="{ item }">
                                  <div class="switch-wallet-view-item">
                                      <WlBaseIcon
                                          :name="
                            item.value === 'personal-wallet'
                              ? 'user-outline-circle-thin'
                              : 'briefcase'
                          "
                                          class="text-xl"
                                      />
                                      <p>{{ item.name }}</p>
                                  </div>
                              </template>
                              <template v-slot:item="{ item }">
                                  <div class="switch-wallet-view-item">
                                      <WlBaseIcon
                                          :name="
                            item === 'personal-wallet'
                              ? 'user-outline-circle-thin'
                              : 'briefcase'
                          "
                                          class="mr-2 text-xl"
                                      />
                                      <p>{{ item.name }}</p>
                                  </div>
                              </template>
                          </WlBaseSelect>
                      </div>-->
        </template>

        <v-divider :style="{top: organisationsOpened ? '1.3rem' : '0.55rem'}"></v-divider>

        <!-- ITEMS -->
        <v-list>
            <NavListItem :href="'/settings?tab=profile'" icon="user-outline-circle-thin"> Profile</NavListItem>

            <NavListItem :href="'/add-ons/my-plan'" icon="grill-vertical"> My plan</NavListItem>

            <NavListItem :href="'/documents'" icon="nav-documents"> My documents</NavListItem>

            <NavListItem :href="'/knowledge-base'" icon="knowledge-base"> Knowledge base</NavListItem>

            <NavListGroup icon="add-ons" title="Add-ons">
                <div class="list-sub-item">
                    <NavListItem :href="'/add-ons/services'" icon="rectangle-group"> Services</NavListItem>
                </div>

                <div class="list-sub-item">
                    <NavListItem :href="'/add-ons/apps'" icon="square-rounded-plus"> Apps</NavListItem>
                </div>
            </NavListGroup>

            <v-divider></v-divider>

            <NavListItem
                v-if="legalEntityEnabled && legalEntityIsActive"
                icon="star-outline"
                :href="accessAffiliates ? '/affiliates' : '/referrals'"
            >
                {{ accessAffiliates ? 'Affiliates' : 'Referrals' }}
            </NavListItem>

            <NavListItem icon="settings-2" @click="$emit('settings')">
                Settings
            </NavListItem>

            <NavListGroup icon="help-support" title="Help & support">
                <div class="list-sub-item">
                    <NavListItem :href="'/tickets'" icon="file-seach-2"> My tickets</NavListItem>
                </div>

                <div class="list-sub-item">
                    <NavListItem :href="'https://help.nativeteams.com/en'" icon="question-mark-circle"> FAQ
                    </NavListItem>
                </div>
            </NavListGroup>

            <v-divider class="mb-0"></v-divider>

            <div class="logout-button-container mt-3">
                <a
                    class="text-base"
                    @click="logout()"
                >
                    <BaseIcon name="logout-2"/>
                    Log out
                </a>
            </div>
        </v-list>
    </div>
</template>

<script>
import NavListItem from "~/components/NewNavigation/NavListItem.vue";
import NavListGroup from "~/components/NewNavigation/NavListGroup.vue";
import {mapState} from "vuex";
import Helpers from "~/shared/mixins/Helpers";
import {commonHelpersMixin} from "~/modules/wallet/mixins/commonHelpersMixin";
import SoloEntitySwitcher from "~/shared/mixins/SoloEntity/SoloEntitySwitcher";


export default {
    name: "Main",
    mixins: [commonHelpersMixin, Helpers, SoloEntitySwitcher],
    data() {
        return {
            organisationsOpened: false,
            legalEntityOptions: [
                {
                    name: "Personal wallet",
                    value: "personal-wallet",
                },
                {
                    name: "Teresa Limited Co.",
                    value: "company-name",
                },
            ],

        };
    },

    components: {NavListGroup, NavListItem},
    props: {
        worker: {
            default: null,
        },
    },
    computed: {
        ...mapState('globalMenuModule', [
            'canOpenMenu',
            'legalEntityEnabled',
            'legalEntityIsActive',
            'legalEntityIsActive',
            'legalEntityData'
        ]),//only one initialization is needed - done in App.vue
        ...mapState('dashboard', ['walletSwitcher']),
        accessAffiliates() {
            return this.$store.state.accessAffiliates;
        },
        currentWorker() {
            return this.$store.state.worker;
        },
        currentOrganization() {
            return this.$store.state.organization;
        },
        organizations() {
            return this.$store.state.organizations;
        },
        canAddOrg() {
            return this.__employmentPlans.includes(this.currentWorker.plan.value);
        },
        // canOpenOrgDropdown() {
        //     return this.__employmentPlans.includes(this.currentWorker.plan.value) ||
        //         this.__clientSeatPlans.includes(this.currentWorker.plan.value) ||
        //         this.__clientProSeatPlans.includes(this.currentWorker.plan.value) ||
        //         this.__employerOfRecordSeatPlans.includes(this.currentWorker.plan.value);
        // },
        companyName() {
            return this.legalEntityData.legal_entity_details?.company_name;
        },
        entitySwitch: {
            get() {
                return this.$store.getters["global/entitySwitch"];
            },
            set(value) {
                this.$store.dispatch("global/setEntitySwitch", value);
            },
        },

        nameSlice() {
            if (this.companyName.length > 22 && this.isScreenBiggerThan1024) {
                return this.companyName.slice(0, 19) + "...";
            }

            if (this.companyName.length > 22 && !this.isSmallTablet) {
                return this.companyName.slice(0, 19) + "...";
            }

            if (
                this.companyName.length > 19 &&
                this.isSmallTablet &&
                !this.isLargeMobile
            ) {
                return this.companyName.slice(0, 17) + "...";
            }

            if (this.companyName.length > 17 && this.isLargeMobile) {
                return this.companyName.slice(0, 14) + "...";
            }

            return false;
        },
        showSwitchView() {
            if (!["/wallet", "/"].includes(this.$route.path)) return false;
            return this.legalEntityIsActive;
        }
    },
    methods: {
        showAddOrg() {
            this.__changeRoute('/create-organization');
        },
        logout(){
            this.$store.dispatch('auth/__logout');
            this.$store.commit('globalMenuModule/TOGGLE_MENU', false);
            this.$store.commit('globalMenuModule/TOGGLE_PROFILE_MENU', false);
        },
        changeOrganization(id){
            this.__changeOrganization(id)
            this.$store.commit('globalMenuModule/TOGGLE_PROFILE_MENU', false)
        }
    },
    created() {
        this.$store.dispatch("global/setLegalEntity", true);
    },
};
</script>

<style scoped lang="scss">
.user-container {
    display: flex;
    align-items: flex-end;

    .welcome-text {
        margin-bottom: 0.5rem;
        color: #4a4c4e;
    }

    .user-name {
        font-size: 1.375rem;
        font-weight: 600;
        line-height: 100%;
    }

    .user-avatar {
        border-radius: 18px;
    }

    .collapse-btn {
        margin-left: auto;

        i {
            font-size: 0.8125rem;

            &.rotated {
                transform: rotate(-180deg);
            }

        }
    }
}

.v-divider {
    border-color: #d6d9dd !important;
    position: relative;
}

.v-list.v-sheet {
    margin-top: 2.5rem;
}

.organisation-item.active {
    margin-bottom: 0 !important;
}

.logout-button-container {
    padding: 0.75rem 1rem;

    i {
        font-size: 1.5rem;
        color: #4a4c4e;
        margin-right: 1rem;
    }

    a {
        display: flex;
        align-items: center;
        color: #4a4c4e;
        line-height: 140%;
        text-decoration: none;
    }
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.switch-wallet-view {
    padding: 16px;
    background: white;
    border-radius: 16px;
    display: flex;
    margin-top: 24px;
    flex-direction: column;
    gap: 16px;

    p {
        color: #3d4062;
        font-size: 14px;
        font-weight: 500;
    }

    button {
        justify-content: start !important;
        border-radius: 16px !important;
        min-height: 48px;

        &.btn-primary {
            background: #2f2f9c !important;
            color: white !important;
            border: 1px solid #2f2f9c !important;
        }

        &.btn-secondary {
            background: white !important;
            color: #2f2f9c !important;
            border: 1px solid #2f2f9c !important;

            i {
                color: #2f2f9c !important;
            }

            &:hover {
                background: #2f2f9c !important;
                color: white !important;

                i {
                    color: white !important;
                }
            }
        }
    }
}

.organisation-switcher-container {
    padding: 1rem 1rem 1.375rem;
    border-radius: 18px;
    background: #fff;

    .text-title {
        color: #5a5a77;
    }

    .organisation-item {
        padding: 0.8125rem 1rem;
        border-radius: 18px;
        background: #2f3094;
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        cursor: pointer;
        transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) !important;

        &:hover {
            &:not(.active) {
                background: #27276c !important;
            }
        }


        .organisation-avatar {
            border-radius: 0.375rem;
            margin-right: 0.75rem;
        }

        .organisation-name {
            line-height: 140%;
        }
    }
}

.organisation-item.active {
    background: #D7E0FF;
    cursor: default;

    .organisation-name {
        color: #000000;
        font-weight: 400;
    }
}

.selected-organisation {
    background: unset !important;
    padding: unset !important;
    margin-bottom: 1.5rem !important;
}

.profile-menu .v-list .v-list-item .list-sub-item .v-list-item:hover .v-list-item__icon .icon-container i:before {
    color: #fff !important;
}

</style>

<style lang="scss" scoped>
.switch-wallet-view-mobile {
    margin-top: 24px;

    .v-input {
        background: #2f2f9c;
        padding: 0;

        .v-select__slot {
            padding: 0 16px;
        }

        .switch-wallet-view-item {
            color: white;
            display: flex;
            align-items: center;
            gap: 15px;
        }

        .icon-chevron-down,
        .icon-chevron-up {
            color: white !important;
            font-size: 20px !important;
        }
    }
}

.switch-wallet-view-dropdown {
    border-radius: 16px !important;

    .v-list {
        background: #2f2f9c;
        border-radius: 16px;

        .switch-wallet-view-item {
            display: flex;
            gap: 15px;
            align-items: center;
            color: white;
            min-height: 48px;
            width: 100%;
        }

        .v-list-item {
            border-radius: 16px;
            padding: 0 8px;

            &:hover {
                p {
                    color: white !important;
                }
            }
        }

        .v-list-item--active p {
            color: white !important;
        }
    }
}

//BUTTONS
.profile-menu-main::v-deep {
    /* BUTTONS */

    .v-btn {
        /* CLEAR */

        text-transform: unset;
        letter-spacing: unset;
        text-indent: unset;
        border-radius: 50px !important;
        outline: unset !important;
        box-shadow: unset !important;
        // background-color: inherit !important;

        &.theme--light {
            color: black;
        }

        /* TYPES */

        &.v-btn--icon {
            border-radius: 12px !important;
        }

        /* SIZES */

        &.v-size--default {
            height: 2.5rem !important;
            min-width: unset;
            padding: 0 1rem;

            &.v-btn--icon {
                width: 2.375rem !important;
                height: 2.375rem !important;
                padding: 0.25rem !important;
                font-size: 1.875rem !important;
            }
        }

        &.v-size--small {
            &.v-btn--icon {
                width: 1.75rem !important;
                height: 1.75rem !important;
                padding: 0.25rem !important;
                font-size: 1.875rem !important;
            }
        }
    }

    .btn-primary {
        background-color: var(--main-purple-color) !important;
        color: white !important;
        border: 1px solid var(--main-purple-color) !important;

        i {
            color: white !important;
        }

        &:hover {
            background-color: #7c8dff !important;
            color: white !important;
            border: 1px solid #7c8dff !important;

            &:before {
                content: none;
            }

            i {
                color: white !important;
            }
        }

        &:active,
        &:focus {
            background-color: #2f2f9c !important;
            color: white !important;
            border: 1px solid #2f2f9c !important;

            &:before {
                content: none;
            }

            i {
                color: white !important;
            }
        }
    }

    .btn-secondary {
        background-color: transparent !important;
        color: var(--main-purple-color) !important;
        border: 1px solid var(--main-purple-color) !important;

        &:hover {
            background-color: var(--main-purple-color) !important;
            color: white !important;

            &:before {
                content: none;
            }

            i {
                color: white !important;
            }
        }

        &:active,
        &:focus {
            background-color: #d7e0ff !important;
            color: var(--main-purple-color) !important;

            &:before {
                content: none;
            }

            i {
                color: var(--main-purple-color) !important;
            }
        }
    }

    .btn-ghost {
        background-color: transparent !important;
        color: #414243 !important;

        i {
            color: var(--main-purple-color) !important;
        }

        &:hover {
            background-color: transparent !important;
            color: var(--main-purple-color) !important;

            &:before {
                content: none;
            }
        }

        &:active,
        &:focus {
            background-color: transparent !important;
            color: #2f2f9c !important;

            &:before {
                content: none;
            }

            i {
                color: #2f2f9c !important;
            }
        }
    }

    .btn-detractive {
        background-color: transparent !important;
        color: #e0061f !important;
        border: 1px solid #e0061f !important;

        i {
            color: #c30419 !important;
        }

        &:hover {
            background-color: #c30419 !important;
            color: white !important;
            border: 1px solid #c30419 !important;

            &:before {
                content: none;
            }

            i {
                color: white !important;
            }
        }

        &:active,
        &:focus {
            background-color: #c30419 !important;
            color: white !important;
            border: 1px solid #c30419 !important;

            &:before {
                content: none;
            }

            i {
                color: white !important;
            }
        }
    }

    .btn-white {
        background-color: transparent !important;
        color: white !important;
        border: 1px solid white !important;

        &:hover {
            background-color: var(--main-purple-color) !important;
            color: white !important;
            border: none !important;

            &:before {
                content: none;
            }

            i {
                color: white !important;
            }
        }

        &:active,
        &:focus {
            background-color: transparent !important;
            color: white !important;
            border: 1px solid white !important;

            &:before {
                content: "";
                background-color: white !important;
                transition: opacity 0.2s;
            }

            i {
                color: white !important;
            }
        }
    }

    .btn-black {
        background-color: transparent !important;
        color: black !important;

        i {
            color: black !important;
        }

        &:hover {
            background-color: transparent !important;
            color: var(--main-purple-color) !important;

            i {
                color: var(--main-purple-color) !important;
            }

            &:before {
                content: none;
            }
        }

        &:active,
        &:focus {
            background-color: transparent !important;
            color: #2f2f9c !important;

            &:before {
                content: none;
            }

            i {
                color: #2f2f9c !important;
            }
        }
    }

    .btn-header {
        background-color: transparent !important;
        color: var(--main-purple-color) !important;
        padding: 0.25rem !important;
        border-radius: 12px !important;

        i {
            color: var(--main-purple-color) !important;
            font-size: 1.875rem !important;
        }

        &:hover {
            color: #1a1a61 !important;

            &:before {
                content: none;
            }

            i {
                color: #1a1a61 !important;
            }
        }

        &:active,
        &:focus {
            background-color: #d7e0ff !important;
            color: var(--main-purple-color) !important;
            border-radius: 12px;

            &:before {
                content: none;
            }

            i {
                color: var(--main-purple-color) !important;
            }
        }
    }

    .theme--light.v-btn.btn-primary.v-btn--disabled {
        background-color: #cdcfd2 !important;
        color: white !important;
        border: 1px solid #cdcfd2 !important;

        &:before {
            content: none;
        }

        i {
            color: white !important;
        }
    }

    .theme--light.v-btn.btn-secondary.v-btn--disabled,
    .theme--light.v-btn.btn-detractive.v-btn--disabled {
        background-color: transparent !important;
        color: #cdcfd2 !important;
        border: 1px solid #cdcfd2 !important;

        &:before {
            content: none;
        }

        i {
            color: #cdcfd2 !important;
        }
    }

    .theme--light.v-btn.btn-ghost.v-btn--disabled {
        background-color: transparent !important;
        color: #cdcfd2 !important;
        border: 1px solid transparent !important;

        &:before {
            content: none;
        }

        i {
            color: #cdcfd2 !important;
        }
    }

    .w-full {
        width: 100% !important;
    }
}
</style>
