import Model from '~/shared/Model';

export default class EmployerWelcome extends Model{

    constructor(obj = {}) {
        super();
        this.values = {
            onboarding: obj.onboarding || 0,
            organization: {
                name: obj.org_name || '',
                logo_file: '',
                company_number: obj.company_number || '',
                brand_client_email: obj.brand_client_email || '',
            },
            seat: {
                first_name: '',
                last_name: '',
                email: '',
                country_id: '',
                net_salary: '',
                salary: ''
            },
            terms: {
                type: 'general',
                accept: null
            }
        }

        this.rules = {
            organization: {
                name: 'required',
                logo_file: '',
                company_number: '',
                brand_client_email: 'email',
            },
            seat: {
                first_name: 'required',
                last_name: 'required',
                email: 'required|email',
                country_id: 'required',
                eor: '',
                currency_id: 'required',
                net_salary: '',
                salary: ''
            },
            terms: {
                type: 'required',
                representative_id: 'required',
                accept: 'required'
            }
        };
    }
}
