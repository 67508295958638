<!-- @format -->
<template>
    <section>
      <v-dialog
          v-if="$store.state.styleSystem === 'wallet'"
          :value="dialog"
          content-class="custom-modal modal-md"
          @input="$emit('input', $event)"
          @click:outside="closeModal"
      >
        <ModalInfo :modal-data="modalData" @on-confirm="closeModal">
          <template v-slot:default>
            <p  class="text-sm font-medium color-grey-2 leading-150">
              Looks like something went wrong. Please try again in a minute.

            </p>
          </template>
        </ModalInfo>
      </v-dialog>
      <TooManyRequest
          v-else
          v-model="dialog"
          @close="closeModal"
      />


    </section>
</template>

<script>
import ModalInfo from '~/modules/wallet/components/Modals/ModalInfo.vue';
import { modalsMixinWithProps } from '~/modules/wallet/mixins/modalsMixinWithProps';
import TooManyRequest from "@/modules/dashboard/components/modals/TooManyRequest.vue";

export default {
    name: 'ModalTooManyRequest',

    components: {
      TooManyRequest,
        ModalInfo,
    },
    mixins: [modalsMixinWithProps],

    data() {
        return {
            modalData: {
                icon: {
                    name: 'info-thin-circle-outline',
                    class: 'color-purple',
                },
                title: 'Temporary issue',
                text:
                    'Kindly be aware that due to local holidays, there may be a slight delay in processing your\n' +
                    'transfers and in the responses from our support teams.',
                btnText: 'Close',
            },
        };
    },
  methods: {
    closeModal() {
      this.$store.dispatch('__setTooManyRequestsModal', false, {root: true});
    }
  }
};
</script>
