<template>
    <modal
        v-model="dialog"
        :class-names="classNames"
        :width="width"
        :position="position"
        :close-icon-show="closeIconShow"
        :close-small="true"
        close-color="#8F8F8F"
        :padding="false"
        :close-top="closeTop"
        :content-class="newDesign ? 'custom-modal' : 'iban-notify tm-bg-white rounded modal-dialog center modal-sm modal-updated-iban enable-2fa-mobile'"
    >
        <template slot="content">
            <ModalInfo v-if="newDesign" :modal-data="modalData" :text-center="false" @on-confirm="onEnable2faLinkClick">
                <p>In order to {{ actionText }}, please enable 2FA.</p>
                <template v-slot:underButton>
                    <WlBaseButton
                        type="secondary"
                        @click="dialog = false"
                        class="w-full mt-3"
                    >
                        Close
                    </WlBaseButton>
                </template>
            </ModalInfo>
            <div v-else class="modal-content">
                    <div class="modal-body">
                        <i class="icon-info-circle color-feedback-info"></i>
                        <h1 class="heading-1 bold-text color-black text-center mb-3">
                            Enable 2FA
                        </h1>
                        <p class="normal-text color-dark-grey text-center mb-3">
                            In order to {{ actionText }}, please enable 2FA <a @click.stop="onEnable2faLinkClick" class="color-feedback-info">here.</a>
                        </p>
                        <button
                            type="button"
                            @click="dialog=false"
                            class="btn btn-big custom-btn-primary normal-text bold-text color-white w-100 mb-3"
                        >
                            Close
                        </button>
                    </div>
                </div>
        </template>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from "./Modal";
import TmInput from "./form/TmInput";
import Btn from "./Btn";
import ModalInfo from '~/modules/wallet/components/Modals/ModalInfo';

export default {
    name: 'Confirmation2fa',
    components: { Btn, Modal, TmInput, ModalInfo },
    props: {
        position: {
            default: 'center',//start/end
        },
        classNames: '',
        deleteButtonColor: {
            default: 'red', //orange
        },
        confirmText: {
            default: 'Confirm',
        },
        cancelText: {
            default: 'Cancel',
        },
        showCancelButton: {
            default: false,
        },
        closeIconShow: {
            default: true,
        },
        btnSpace: {
            default: false,
        },
        width: {
            default: 400,
        },
        archive: {
            default: false,
        },
        closeTop: {
            default: 19,
        },
        newDesign: {
            default: false,
        },
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters("security2fa", {
            enable2fa: "enable",
        }),
        dialog: {
            get() {
                return this.$store.state.security2fa.confirmation2faDialog;
            },
            set(value) {
                return value ? this.show() : this.hide();
            },
        },
        actionText() {
            return this.$store.state.security2fa.confirmation2faActionText;
        },
        modalData() {
            return {
                icon: {
                    name: 'info-circle',
                    class: 'color-feedback-info',
                },
                title: 'Enable 2FA',
                btnText: 'Proceed',
            };
        },
    },
    watch: {
        enable2fa(value) {
            if (value) {
                this.hide();
            }
        },
    },
    methods: {
        show() {
            return this.$store.dispatch('security2fa/showConfirmation2fa');
        },
        hide() {
            return this.$store.dispatch('security2fa/hideConfirmation2fa');
        },
        onEnable2faLinkClick(e) {
            // e.preventDefault();
            // if (window.location.pathname && window.location.pathname !== '/') {
            //     window.sessionStorage.setItem('previousPath', window.location.pathname);
            // }
            // window.location.href = '/settings?tab=security#show-2fa-dialog';
            this.dialog = false;
            this.$store.dispatch('security2fa/showForm2faDialog');
            this.$store.dispatch('wlSecurity2fa/__setForm2faDialog', true);
        },
    }
};
</script>
