<template>
    <div class="info-card">
        <p class="text-small color-dark-grey mb-1">
            {{ label }}
        </p>
        <h1 class="heading-1 font-medium color-black">
            {{ value }}
        </h1>
    </div>
</template>

<script>
export default {
    name: "BaseInfoCard",

    props: {
        label: {
            type: String,
            default: "",
        },
        value: {
            type: String,
            default: "",
        },
    },
};
</script>
