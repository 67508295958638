<template>
    <div class="modal-add-request">
        <BaseButton
            v-if="showButton"
            :disabled="disableButton"
            @click="paymentRequest"
            type="button"
            class="btn-md btn-purple text-sm font-medium color-white ml-auto"
        >
            Request payment
        </BaseButton>
        <BaseModal
            v-model="dialog"
            title="Request payment"
            attach=".vue-repo-app"
            :retain-focus="false"
            :hideable="false"
        >
            <template v-slot:body>
                <div class="form-modal-request-payment">
                    <div class="mb-20" v-if="!csvUpload">
                        <tm-input-group
                            v-model="salaryRequestObj.values.request_type"
                            v-validate="salaryRequestObj.rules.request_type"
                            :bottom-offset="false"
                            :content-bottom-offset="false"
                            type="radio"
                            :content-class="__smallDesktop ? 'col-12 radio-container ml-0' : 'col-6 radio-container'"
                            :classification="!__smallDesktop ? 'flex-row-radio' : ''"
                            :items="requestTypesLoc"
                            item-text="name"
                            item-value="id"
                            name="request_type"
                            :error-messages="errors.collect('request_type')"
                            data-vv-as="Request Type"
                            :value="setFirstElementFromList(requestTypesLoc, 'request_type', salaryRequestObj)"
                        ></tm-input-group>
                    </div>

                    <div v-show="salaryRequestObj.values.request_type === 'my_recipient'" class="form-group mb-20">
                        <tm-select-contact
                            label="Payer’s name or email"
                            :key="key"
                            v-model="salaryRequestObj.values.contact_id"
                            v-validate="requestType === 'my_recipient' ? salaryRequestObj.rules.contact_id : ''"
                            contact-type="salary_request"
                            :bottom-offset="false"
                            name="contact_id"
                            :placeholderClass="''"
                            :attach="true"
                            :min-width="'100%'"
                            :chevron-size="19"
                            :new-contact-modal="true"
                            :error-messages="errors.collect('contact_id')"
                            data-vv-as="Payer"
                            :seat="__isAnySeat"
                        ></tm-select-contact>
                    </div>

                    <div v-show="salaryRequestObj.values.request_type === 'invite_by_email'">
                        <div class="form-group mb-20">
                            <BaseInput
                                label="Payer's name"
                                v-model="salaryRequestObj.values.payer_name"
                                v-validate="requestType !== 'my_recipient' ? salaryRequestObj.rules.payer_name : ''"
                                labelClassVal="fs-16"
                                :bottom-offset="false"
                                name="payer_name"
                                :error-messages="errors.collect('payer_name')"
                                data-vv-as="payer name"
                            ></BaseInput>
                        </div>
                        <div class="form-group mb-20">
                            <BaseInput
                                label="Payer's email"
                                v-model="salaryRequestObj.values.payer_email"
                                v-validate="requestType !== 'my_recipient' ? emailRules : ''"
                                labelClassVal="fs-16"
                                :bottom-offset="false"
                                name="payer_email"
                                :error-messages="emailErrorMsg"
                                data-vv-as="payer email"
                            ></BaseInput>
                        </div>
                    </div>

                    <div class="form-group salary-form-group mb-20"
                         v-if="!$store.state.organization.default">
                        <BaseSelect
                            label="Choose to send from the organisation or yourself"
                            :items="fromNames"
                            item-text="name"
                            item-value="id"
                            labelClassVal="fs-16"
                            class="global-base-select-menu"
                            customClassActiveItem="custom-active"
                            v-model="salaryRequestObj.values.from_request"
                            name="from"
                            :select-first="!salaryRequestObj.values.from_request"
                            v-validate="salaryRequestObj.rules.from_request"
                            :error-messages="errors.collect('from_request')"
                            data-vv-as="from"
                            absolute
                        />
                    </div>

                    <div class="form-group salary-form-group mb-20"
                         v-if="!$store.state.organization.default">
                        <BaseSelect
                            label="Request Category"
                            :bottom-offset="false"
                            name="request_category_id"
                            v-model="salaryRequestObj.values.request_category_id"
                            :items="allowedCategories"
                            class="global-base-select-menu"
                            customClassActiveItem="custom-active"
                            item-value="id"
                            item-text="name"
                            :avatar="false"
                            data-vv-as="category"
                            v-validate="salaryRequestObj.rules.request_category_id"
                            :value="setFirstElementFromList(allowedCategories, 'request_category_id', salaryRequestObj)"
                            :error-messages="errors.collect('request_category_id')"
                            absolute
                        />
                    </div>

                    <div class="d-flex justify-content-between row mb-20">
                        <div class="col-12 pb-0">
                            <BaseSelect
                                :autocomplete="true"
                                :items="currenciesInvoice"
                                item-text="code"
                                item-value="id"
                                labelClassVal="fs-16"
                                label="Currency"
                                name="currency_id"
                                :avatar="true"
                                v-model="salaryRequestObj.values.currency_id"
                                v-validate="salaryRequestObj.rules.currency_id"
                                :errorMessages="errors.collect('currency_id')"
                                data-vv-as="currency"
                                :value="valueForCurrency"
                                absolute
                            >
                            </BaseSelect>
                        </div>
                        <div class="col-12 pb-0">
                            <BaseFormattedInput
                                type="number"
                                label="Amount"
                                name="net_amount"

                                labelClassVal="fs-16"
                                v-model="salaryRequestObj.values.net_amount"
                                placeholder="0.00"
                                v-validate="netAmountRules"
                                data-vv-validate-on="change"
                                :errorMessages="errors.collect('net_amount')"
                                data-vv-as="amount"
                            />
                        </div>
                    </div>

                    <div class="col-12 mb-4 p-0" v-if="ibanNotRequested">
                        <div class="alert-container">
                            <BaseIcon name="warning" class="text-base"/>
                            <template v-if="walletIban.iban_request">
                                <p class="text-sm color-grey-1">
                                    Please note, you have not yet received an IBAN.
                                    <a :href="`mailto:`+$store.state.systemEmails.support"
                                       class="color-purple text-decoration-none"
                                    >
                                        Contact support.
                                    </a>
                                </p>
                            </template>
                            <template v-else>
                                <p class="text-sm color-grey-1">
                                    Please note, you have not yet requested an IBAN for receiving bank transfers.
                                    <span @click="ibanDialog = true" class="font-bold cursor-pointer color-purple">
                                        Request now
                                    </span>
                                </p>
                            </template>
                        </div>
                    </div>

                    <div v-if="'NGN' !== currency && showCurrencyBanner" class="banner info">
                        <i class="icon-info-circle-fill mr-1"></i>
                        <p class="tiny-text color-black">
                            While we are in the process of supporting direct card payments for this
                            currency, there will be a fee deducted if your recipient pays by card. To
                            avoid
                            this fee, please ask your recipient to pay via bank transfer.
                        </p>
                    </div>

                    <div class="form-group">
                        <div class="field-group mb-4">
                            <BaseTextarea
                                label="Notes"
                                name="description"
                                labelClassVal="fs-16"
                                v-model="salaryRequestObj.values.description"
                                v-validate="salaryRequestObj.rules.description"
                                :errorMessages="errors.collect('description')"
                                placeholder="Please add notes here"
                            />
                        </div>
                    </div>
                    <!-- Select frequency section-->
                    <div class="form-group">
                        <div class="mb-20">
                            <tm-input-group
                                v-model="salaryRequestObj.values.frequency"
                                v-validate="salaryRequestObj.rules.frequency"
                                :bottom-offset="false"
                                type="radio"
                                :content-class="__smallDesktop ? 'col-12 radio-container ml-0' : 'col-4 radio-container'"
                                :classification="!__smallDesktop ? 'flex-row-radio' : ''"
                                :items="frequenciesLoc"
                                item-text="name"
                                item-value="id"
                                name="frequency"
                                :error-messages="errors.collect('frequency')"
                                data-vv-as="Request Type"
                                :value="setFirstElementFromList(frequenciesLoc, 'frequency', salaryRequestObj)"
                            ></tm-input-group>
                        </div>
                        <div class="group">
                            <div class="d-flex align-center row" :class="__smallDesktop ? 'flex-column' : ''">
                                <div v-show="frequency === 'weekly'"
                                     :class="!__smallDesktop ? 'col-12 pr-1 pb-0' : 'col-12 pb-0'"
                                >
                                    <BaseSelect
                                        v-model="salaryRequestObj.values.exact_day"
                                        v-validate="frequency === 'weekly' ? salaryRequestObj.rules.exact_day : ''"
                                        class="exact-day mb-1-rem"
                                        label="Day of the week"
                                        labelClassVal="fs-16"
                                        :bordered="true"
                                        :bottom-offset="false"
                                        :items="weeklyDaysLoc"
                                        item-text="name"
                                        :is-sort="false"
                                        item-value="id"
                                        name="exact_day"
                                        :error-messages="errors.collect('exact_day')"
                                        data-vv-as="start day"
                                        :value="
                                                setFirstElementFromList(
                                                    weeklyDaysLoc,
                                                    'exact_day',
                                                    salaryRequestObj,
                                                )
                                            "
                                    ></BaseSelect>
                                </div>

                                <div v-show="frequency === 'monthly'"
                                     :class="!__smallDesktop ? 'col-12 pr-1 pb-0' : 'col-12 pb-0'"
                                >
                                    <tm-select-date
                                        v-model="salaryRequestObj.values.exact_date"
                                        v-validate="frequency === 'monthly' ? salaryRequestObj.rules.exact_date : ''"
                                        label="Date"
                                        :menu-top="true"
                                        :label-class-val="'fs-16'"
                                        name="exact_date"
                                        :min="'today'"
                                        :max="maxDate()"
                                        :clearable="true"
                                        :error-messages="errors.collect('exact_date')"
                                        data-vv-as="start date"
                                    >
                                    </tm-select-date>
                                </div>

                                <div v-show="frequency === 'weekly' || frequency === 'monthly'"
                                     :class="!__smallDesktop ? 'col-12 pr-1 pb-0' : 'col-12 pt-0'"
                                >
                                    <tm-select-date
                                        v-model="salaryRequestObj.values.end_date"
                                        v-validate="salaryRequestObj.rules.end_date"
                                        label="End date"
                                        name="end_date"
                                        :menu-top="true"
                                        :clearable="true"
                                        :label-class-val="'fs-15'"
                                        :min="minDateExact()"
                                        :error-messages="errors.collect('end_date')"
                                        data-vv-as="end date"
                                    >
                                    </tm-select-date>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <BaseButton
                    v-if="csvUpload"
                    :disabled="!isValid"
                    :loading="loading"
                    type="button"
                    class="btn-big custom-btn-primary text-base font-medium color-white w-100"
                    @click="csvUpdate()"
                >
                    Save
                </BaseButton>
                <BaseButton
                    v-else
                    type="button"
                    class="btn-big custom-btn-primary text-base font-medium color-white w-100"
                    @click="create()"
                    :disabled="!isValid"
                    :loading="loading"
                >
                    Request payment
                </BaseButton>
            </template>
        </BaseModal>
        <success-modal
            v-model="requestedDialog"
            :success-dialog-data="successDialogData"
            :show="true"
            :size="12"
        ></success-modal>
        <iban-request-form
            v-model="ibanDialog"
            :wallet="walletIban"
        ></iban-request-form>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

import IbanRequestForm from "../../../components/modals/IbanRequestForm";
import TmSlideCheckbox from "~/shared/components/form/TmSlideCheckbox";
import TmSelectContact from "~/shared/components/form/TmSelectContact";
import TmTextArea from "~/shared/components/form/TmTextArea";
import TmSelectDate from "~/shared/components/form/TmSelectDate";
import TmInput from "~/shared/components/form/TmInput";
import TmAdvancedSelect from "~/shared/components/form/TmAdvancedSelect";
import Btn from "~/shared/components/Btn";
import TmInputGroup from "~/shared/components/form/TmInputGroup";
import SalaryRequest from "../../../models/SalaryRequest";
import InvoiceContact from "../../../models/InvoiceContact";
import Helpers from "~/shared/mixins/Helpers";
import WalletIban from '~/shared/mixins/Wallet/WalletIban';
import Screen from "~/shared/mixins/Screen";
import SuccessModal from './SuccessModal';

export default {
    name: "ModalAddRequestPayment",
    components: {
        IbanRequestForm,
        TmSlideCheckbox,
        TmSelectContact,
        TmTextArea,
        TmSelectDate,
        TmInput,
        TmAdvancedSelect,
        Btn, SuccessModal,
        TmInputGroup,
    },

    mixins: [Helpers, Screen, WalletIban],

    props: {
        value: {
            required: true,
            type: Boolean,
        },
        showButton:{
            default: true,
        },
        disableButton:{
            default: false,
        },
        requestTypes: {
            default: null,
        },
        frequencies: {
            default: null,
        },
        fromNames: {
            default: null,
        },
        csvUpload: {
            default: false,
        },
        currency_id: {
            default: null,
        },
        weeklyDays: {
            default: null,
        },
        allowedCategories: {
          required: true,
          type: Array
        },
        salaryObjProp: {
            type: Object,
            required: false,
        },
    },

    data() {
        if (this.currency_id) {
            this.salaryObjProp['currency_id'] = this.currency_id
        }

        return {
            key: 1,
            ibanDialog: false,
            successDialogData: {
                title: 'Request sent',
                description: 'Your payment request has been successfully submitted.'
            },
            showCurrencyBanner: false,
            requestedDialog: false,
            stripeMinCurrencyAmount: this.$store.state.minCurrencyAmountForStripe,

            contactDialog: false,
            contactObj: {},

            successAdded: false,
            salaryObj: this.salaryObjProp,

            dialog: this.value,
            salaryRequestObj: new SalaryRequest(this.salaryObjProp),
            loading: false,
            hideAndShowSwitch: true,

            deleteContactDialog: false,
            deleteContactLoading: false,
            deleteContactObj: new InvoiceContact(),

            requestTypesLoc: this.requestTypes || [],
            weeklyDaysLoc: this.weeklyDays || [],
            frequenciesLoc: this.frequencies || [],
            currencyMin: 1,
        };
    },

    computed: {
        ...mapGetters(['currenciesInvoice', 'wallets']),
        valueForCurrency() {
            return this.setFirstElementFromList(
                this.currenciesInvoice,
                'currency_id',
                this.salaryRequestObj,
            )
        },
        isValid() {
            return !Object.keys(this.fields).some(
                key =>
                    (this.fields[key].required &&
                        !this.salaryRequestObj.values[key] &&
                        this.salaryRequestObj.values[key] !== 0) ||
                    this.fields[key].invalid,
            );
        },
        netAmountRules() {
            this.changeCurrencyTxt();
            return 'required|min_value:' + this.currencyMin;
        },
        currency() {
            const currencyCode = this.salaryRequestObj.values.currency_id
                ? this.currenciesInvoice.find(item => item.id === this.salaryRequestObj.values.currency_id).code
                : null;

            this.showCurrencyBanner =
                currencyCode &&
                this.$store.state.stripeRegisteredCurrencies &&
                this.$store.state.stripeRegisteredCurrencies.indexOf(currencyCode) <= -1 &&
                this.showBankInfo(currencyCode);

            this.ibanTab = currencyCode

            return currencyCode;
        },
        requestType() {
            return this.salaryRequestObj.values.request_type;
        },
        frequency() {
            return this.salaryRequestObj.values.frequency;
        },
        includeBonus() {
            return this.salaryRequestObj.values.include_bonus;
        },
        emailRules() {
            return this.$store.state.info
                ? this.salaryRequestObj.rules.payer_email + '|excluded:' + this.$store.state.info.email
                : this.salaryRequestObj.rules.payer_email;
        },
        emailErrorMsg() {
            return this.errors.items.find(i => i.field === 'payer_email' && i.rule === 'excluded')
                ? [`Recipient's email must be different from yours`]
                : this.errors.collect('payer_email');
        },
    },

    watch: {
        ['salaryRequestObj.values.net_amount']() {
            this.getMinAdmissibleAmount();
        },
        ['salaryRequestObj.values.from_request']() {
            if (this.salaryRequestObj.values.from_request === 0) {
                this.hideAndShowSwitch = false;
                this.salaryRequestObj.values.show_sender_name = 0;
            } else {
                this.hideAndShowSwitch = true;
                this.salaryRequestObj.values.show_sender_name = 1;
            }

        },
        ['salaryRequestObj.values.currency_id']() {
            this.getMinAdmissibleAmount();
            this.changeCurrencyTxt();
        },
        value() {
            this.dialog = this.value;
            this.$nextTick(function () {
                this.keypressForNumber();
            })

            if (this.value) {
                if (!this.weeklyDays || !this.requestTypes || !this.frequencies) {
                    this.__getData('get-salary-request-data');
                }
            }
        },
        dialog() {
            if (this.currency_id) {
                this.salaryObj['currency_id'] = this.currency_id
            }

            this.salaryRequestObj = new SalaryRequest(this.salaryObj);
            this.$emit('input', this.dialog);

            this.$validator.reset();

            if (this.dialog) {
                this.key = ++this.key;
                this.$validator.resume();
            } else {
                this.$validator.pause();
                this.errors.clear();
            }
        },
    },

    methods: {
        maxDate(){
            if (this.frequency === 'monthly' && this.salaryRequestObj.values.end_date){
                return this.salaryRequestObj.values.end_date;
            }
        },
        minDateExact(){
            if (this.frequency === 'monthly' && this.salaryRequestObj.values.exact_date){
                return this.salaryRequestObj.values.exact_date;
            }
            return 'today';
        },
        requestFormatAmount(){
            if (this.salaryRequestObj.values.net_amount) {
                this.salaryRequestObj.values.net_amount = parseFloat(this.salaryRequestObj.values.net_amount).toFixed(2);
            }
        },
        paymentRequest() {
            this.__checkVerified({
                permission: this.$store.state.kyc.KYCPermissionMoneyIn,
                level: this.$store.state.kyc.KYCLevel2
            }).then(verified => {
                if (verified){
                    this.dialog = true;
                }
            })
        },
        getMinAdmissibleAmount() {
            this.currencyMin = (this.currency in this.stripeMinCurrencyAmount) ? this.stripeMinCurrencyAmount[this.currency] : this.stripeMinCurrencyAmount['default'];
        },
        changeCurrencyTxt() {
            if (this.currency) {
                const dict = {
                    custom: {
                        net_amount: {
                            min_value: `Minimum amount is ${this.currencyMin} ${this.currency}`,
                        },
                    },
                };

                this.$validator.localize('en', dict);
            }
        },
        create() {
            this.$validator.validateAll().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.salaryRequestObj
                        .create(this.$store.state.path + 'payment-request/store')
                        .then(response => {
                            this.loading = false;
                            this.dialog = false;
                            this.requestedDialog = true;
                            this.successAdded = true;
                            this.$emit('sendRequest', true);
                        })
                        .catch(error => {
                            this.loading = false;
                            this.$store.commit('setAlert', {type: 'error', message: error.response.data.message});
                        });
                }
            });
        },
        csvUpdate() {
            this.$validator.validateAll().then(valid => {
                if (valid) {
                    this.dialog = false;
                    this.requestedDialog = true;
                    this.$emit('csvUpdate', this.salaryRequestObj.values);
                }
            });
        },
    },
};
</script>
