<template>
    <div>
        <div class="row">
            <div class="col-12">
                <BaseWizardNavbar2
                    v-show="showNavigation"
                    :steps="steps"
                    :current-step="currentStep"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <template v-for="(step, index) in steps">
                    <slot
                        :name="step.toLowerCase()"
                        v-if="currentStep === index + 1"
                    ></slot>
                </template>

                <slot></slot>
            </div>
        </div>

        <div class="row" v-if="showButtons">
            <div class="col-12 nav-wizard-2-actions mt-auto">
                <template v-if="!$scopedSlots['actions']">
                    <BaseButton
                        class="btn-big btn-purple color-white w-100"
                        v-if="currentStep < steps.length"
                        :disabled="localValidSteps && !localValidSteps.includes(currentStep)"
                        @click="next"
                    >
                        {{ nextButtonName }}
                    </BaseButton>

                    <BaseButton
                        class="btn-big btn-purple color-white w-100 mt-2"
                        v-if="currentStep === steps.length"
                        :disabled="validSteps && !validSteps.includes(currentStep)"
                        @click="finish"
                        :loading="loading"
                    >
                        {{ finishButtonName }}
                    </BaseButton>
                    <BaseButton
                        v-if="currentStep > backButtonAfterStep"
                        @click="back"
                        class="btn-big btn-purple-outline w-100 mt-2"
                    >
                        Back
                    </BaseButton>
                </template>
                <slot v-else name="actions" :next="next" :back="back"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import BaseWizardNavbar2 from "./BaseWizardNavbar2.vue";

export default {
    name: "BaseWizardStepper2",

    components: {
        BaseWizardNavbar2,
    },

    props: {
        showNavigation: {
            type: Boolean,
            default: true,
        },
        steps: {
            type: Array,
            required: true,
        },
        validSteps: {
            type: Array,
            required: false,
            default: () => []
        },
        value: {
            type: Number,
            required: true,
        },
        showButtons: {
            type: Boolean,
            required: false,
            default: true,
        },
        backButtonAfterStep: {
            type: Number,
            required: false,
            default: 1,
        },
        finishButtonName: {
            type: String,
            required: false,
            default: "Finish",
        },
        nextButtonName: {
            type: String,
            required: false,
            default: "Next",
        },
        currentStepFromParent: {
            type: Number,
            required: false,
        },
        loading: {
            required: true,
            type: Boolean
        }
    },

    data() {
        return {
            currentStep: this.value,
            localValidSteps: []
        };
    },

    watch: {
        value(val) {
            this.currentStep = val;
        },
        currentStep(val) {
            this.$emit("input", val);
        },
        currentStepFromParent(val) {
            this.currentStep = val;
        },
        validSteps (val){
            this.localValidSteps = val;
        }
    },

    methods: {
        next() {
            this.$emit("next");
            this.currentStep++;
        },
        back() {
            this.$emit("back", this.currentStep);
            if (this.currentStep > 1) {
                this.currentStep--;
            }
        },
        finish() {
            this.$emit("finish");
        },
    },
};
</script>
