<template>
    <modal
        v-model="dialog"
        :class-names="classNames"
        :width="width"
        :position="position"
        :close-icon-show="closeIconShow"
        :close-small="true"
        close-color="#8F8F8F"
        :padding="false"
        :close-top="closeTop"
        :content-class="newDesign ? 'custom-modal' : 'iban-notify tm-bg-white rounded modal-dialog center modal-sm modal-updated-iban enable-2fa-mobile'"
    >
        <template slot="content">
            <ModalInfo v-if="newDesign" :modal-data="modalData" :text-center="false" @on-confirm="dialog = false">
                <p>In order to {{ actionText }} this card, please <a :href="$store.state.path + 'settings?tab=plan'" class="color-feedback-info">upgrade</a> your plan.</p>
            </ModalInfo>
            <div v-else class="modal-content">
                    <div class="modal-body">
                        <i class="icon-info-circle color-feedback-info"></i>
                        <h1 class="heading-1 bold-text color-black text-center mb-3">
                            Upgrade account
                        </h1>
                        <p class="normal-text color-dark-grey text-center mb-3">
                            In order to {{ actionText }} this card, please <a :href="$store.state.path + 'settings?tab=plan'" class="color-feedback-info">upgrade</a> your plan.
                        </p>
                        <button
                            type="button"
                            @click="dialog=false"
                            class="btn btn-big custom-btn-primary normal-text bold-text color-white w-100 mb-3"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </template>
    </modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from "./Modal";
import TmInput from "./form/TmInput";
import Btn from "./Btn";
import ModalInfo from '~/modules/wallet/components/Modals/ModalInfo';

export default {
    name: 'ConfirmationUpgrade',
    components: { Btn, Modal, TmInput, ModalInfo },
    props: {
        position: {
            default: 'center',//start/end
        },
        classNames: '',
        deleteButtonColor: {
            default: 'red', //orange
        },
        confirmText: {
            default: 'Confirm',
        },
        cancelText: {
            default: 'Cancel',
        },
        showCancelButton: {
            default: false,
        },
        closeIconShow: {
            default: true,
        },
        btnSpace: {
            default: false,
        },
        width: {
            default: 400,
        },
        archive: {
            default: false,
        },
        closeTop: {
            default: 19,
        },
        newDesign: {
            default: false,
        },
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapState({
            confirmationUpgradeDialog: 'confirmationUpgradeDialog',
            actionText: 'confirmationUpgradeDialogActionText',
        }),
        dialog: {
            get() {
                return this.confirmationUpgradeDialog;
            },
            set(value) {
                return value ? this.show() : this.hide();
            },
        },
        modalData() {
            return {
                icon: {
                    name: 'info-circle',
                    class: 'color-feedback-info',
                },
                title: 'Upgrade account',
                btnText: 'Close',
            };
        },
    },
    methods: {
        show() {
            return this.$store.dispatch('showConfirmationUpgrade', this.actionText);
        },
        hide() {
            return this.$store.dispatch('hideConfirmationUpgrade');
        },
    },
};
</script>
