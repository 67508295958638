/** @format */

import Vue from 'vue';
import VueRouter from 'vue-router';
import Kernel from "~/routes/Kernel";
import auth from "@/routes/namespaces/auth";
import guest from "@/routes/namespaces/guest";
import system from "@/routes/namespaces/system";
import wallet from "@/routes/namespaces/wallet";
import dashboard from "@/routes/namespaces/dashboard";

Vue.use(VueRouter);

const router = new VueRouter({
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    mode: 'history',
    routes: [...auth, ...guest, ...system, ...wallet, ...dashboard],
});

router.beforeEach(async (to, from, next) => {
    const result = await new Kernel({from, to}).run();
    return !result || result.success? next(): next(result.redirect);
})

router.afterEach((to, from) => {
    new Kernel({from, to}).runAfterEnter();
});

export default router;
