<template>
  <v-expansion-panels
    v-bind="$attrs"
    class="custom-panels"
    v-model="isExpanded"
  >
    <template v-for="(item, index) in items">
      <v-expansion-panel :key="index">
        <v-expansion-panel-header> {{ item.text }} </v-expansion-panel-header>
        <v-expansion-panel-content>
          <slot :name="item.value"></slot>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </template>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "BasePanels",

  inheritAttrs: false,

  props: {
    items: {
      type: Array,
      default: () => {},
    },
  },

  data() {
    return {
      expanded: [0],
    };
  },
    computed: {
        isExpanded: {
            get: function() {
                return this.expanded;
            },
            set: function(newValue) {
                this.expanded = newValue;
            }
        }
    },
};
</script>
