// initial state
const state = {
    dialog: false,
    loading: false,
    callback: null,
};

// getters
const getters = {
    dialog: (state) => state.dialog,
    loading: (state) => state.loading,
    callback: (state) => state.callback,
};

// mutations
const mutations = {
    dialog(state, payload) {
        state.dialog = payload;
    },
    loading(state, payload) {
        state.loading = payload;
    },
    callback(state, payload) {
        state.callback = payload;
    },
};

// actions
const actions = {
    run({ commit, dispatch }, { callback }) {
        commit('callback', callback);
        dispatch('show');
    },
    show({ commit }) {
        commit('dialog', true);
    },
    hide({ commit }) {
        commit('dialog', false);
    },
    verify({ commit }, { type }) {
        return axios
            .post(`/confirmation/verify`, { type })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                commit('setAlert', { type: 'error', message: error.message }, { root: true });
                throw error;
            })
            .finally(() => {
                commit('loading', false);
            });
    },
    confirm({ commit, getters }, { type, password }) {
        commit('loading', true);
        return axios
            .post(`/confirmation/confirm`, { type, password })
            .then(response => {
                if (typeof getters.callback === 'function') {
                    getters.callback();
                }
                return response.data;
            })
            .finally(() => {
                commit('loading', false);
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
