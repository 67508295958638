<!-- @format -->

<template>
    <div @drop="getDropFiles" @dragenter.prevent @dragover.prevent class="position-relative">
        <slot
            v-if="$scopedSlots['activator']"
            :on-click="activate"
            name="activator"
        />
        <div
            v-else
            @click="activate"
            :class="{ link: isLink }"
            class="file-upload-wrapper justify-center"
        >
            <slot v-if="$scopedSlots['default'] || $slots.default" name="default"></slot>
            <label v-else class="btn btn-small btn-text text-sm font-medium color-purple">
                <BaseIcon name="attachment" class="mr-2" />
                Add an attachment
            </label>
        </div>

        <v-file-input
            class="d-none"
            ref="Input"
            :value="val"
            @change="input"
            :accept="accept"
            :prepend-icon="null"
            hide-input
            :multiple="multiple"
        />

        <base-error-message left :messages="errorMessages" />
    </div>
</template>

<script>
export default {
    name: 'BaseFileDropArea',

    props: {
        value: {
            default: null,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        isLink: {
            type: Boolean,
            default: false,
        },
        accept: {
            type: [String, Array],
            default: '*',
        },
        errorMessages: {
            default: () => [],
        },
    },

    data() {
        return {
            val: this.multiple && !this.value ? [] : this.value,
        };
    },

    methods: {
        input(val){
            if (val){
                this.$emit('input', val);
                this.val = null;
                this.$refs.Input.$refs.input.value = '';
            }
        },
        activate(){
            this.$refs.Input.$refs.input.click();
        },
        getDropFiles(e) {
            e.preventDefault();
            if (e.dataTransfer.items) {
                for (const element of e.dataTransfer.items) {
                    if (element.kind === 'file') {
                        this.input(element.getAsFile());
                    }
                }
            } else {
                for (const element of e.dataTransfer.files) {
                    this.input(element);
                }
            }
        },
    }
};
</script>
