<!-- @format -->

<template>
    <div class="filter-tabs-container">
        <ul class="nav-tabs" :class="ulCustomClasses">
            <li v-for="tab in tabs" :key="getTabValue(tab)" class="nav-item position-relative">
                <BaseButton
                    style="font-size: 1rem; padding: 0 0.5rem 0.5rem;"
                    class="btn-tab text-base"
                    :class="
                        getTabValue(selectedTab) === getTabValue(tab)
                            ? 'color-purple selected fw-500'
                            : 'color-grey-3 fw-400'
                    "
                    @click="selectTab(tab)"
                >
                    {{ getTabValue(tab) }}
                </BaseButton>
                <slot :name="tab.filter+'.header'"></slot>
            </li>
        </ul>
    </div>
</template>


<script>
export default {
    name: 'BaseFilterTabs',

    props: {
        tabs: {
            type: Array,
            required: true,
        },
        value: {
            default: false,
        },
        titleProperty: {
            type: String,
            default: "title",
        },
        ulCustomClasses: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            selectedTab: this.tabs[0],
        };
    },
    watch: {
        value(v) {
           this.getSelectTab(v)
        },
    },
    methods: {
        getTabValue(tab ) {
            return this.titleProperty in tab ? tab[this.titleProperty] : "";
        },
        getSelectTab(v){
            const tab = this.tabs.find(i => i.filter === v);
            if(tab) {
                this.selectedTab = tab;
            }
        },
        selectTab(tab) {
            this.selectedTab = tab;
            this.$emit('input', tab.filter);
        },
    },
    created() {
        this.getSelectTab(this.value)
    }
};
</script>
