import Middleware from "~/routes/middleware/Middleware";
import store from "@/store";

export default class paymentRequestGuestPay extends Middleware{
    constructor(...args) {
        super(...args);
    }

    async checkPayment(id) {
        return await axios.post('payment-request/pay-check/' + id)
            .then(response => response.data)
    }

    async resolve() {
        if (store.getters['auth/__isAuth']){
            return this.responseRedirect('payment-request')
        }

        const id = this.to.params.id;
        const response = await this.checkPayment(id)

        return response && response.valid ?
            this.responseNext(response.data) :
            this.responseAbort('PaymentExpired')
    }
}
