<!-- @format -->

<template>
    <v-dialog
        v-if="addMoneyObj"
        :transition="showModal ? 'dialog-transition' : false"
        v-model="dialog"
        fullscreen
        persistent
        content-class="tm-bg-white"
        no-click-animation
        :focus-retain="false"
    >
        <div class="card add-send-page mb-0 p-0 rounded-0">
            <div
                :class="__smallDesktop ? 'px-0' : 'pl-30 pr-40'"
                class="py-1 nav-header d-flex justify-content-between align-items-center flex-wrap"
            >
                <img
                    :src="$store.state.wlstyle.st_logo_black"
                    class="ml-3 ml-sm-0"
                    alt="logo black"
                    style="width: 9.75rem"
                />
                <div class="navbar-steps-circles position-relative">
                    <ul class="d-flex justify-content-between">
                        <li
                            v-for="(item, index) in steps"
                            :key="index"
                            class="steps-circle"
                            :class="[step === item.value ? 'active' : '', availableStep(item) ? 'pointer' : '']"
                            @click="!received && availableStep(item) ? (step = item.value) : null"
                        >
                            <div class="text-center secondary-grey step-circle-item">
                                <i
                                    class="icon-circle-filled fs-10 cursor-pointer d-block"
                                    style="margin-bottom: 0.375rem"
                                ></i>
                                <p class="medium-text bold-text secondary-grey">
                                    {{ item.name }}
                                </p>
                                <span v-if="__smallDesktop" class="color-dark-grey medium-text my-2">
                                    {{ index + 1 }}/{{ steps.length }}
                                </span>
                            </div>
                        </li>
                    </ul>
                    <template v-if="__smallDesktop">
                        <button v-if="stepIndex" class="circle-item-steps-left cursor-pointer" @click="back()">
                            <i class="icon-lhs-arrow-left fs-12 color-dark-grey mr-1"></i>
                            <span class="medium-text bold-text color-dark-grey">Back</span>
                        </button>
                    </template>
                </div>
                <div class="d-flex justify-end align-center add-money-close-button" style="width: 9.75rem">
                    <button
                        :class="__smallDesktop ? 'mr-3' : ''"
                        class="order-md-last order-second mr-sm-0"
                        @click="closeModal"
                    >
                        <i class="icon-times color-black" />
                    </button>
                </div>
            </div>
            <div class="card-body add-money-card-body pt-0 pb-30">
                <div class="mw-440 mt-57 mx-auto add-money-card-main">
                    <!--Payment Received-->
                    <payment-received
                        v-if="received"
                        :obj="addMoneyObj"
                        :wallet="wallet"
                        :option="option"
                        :result="result"
                    />

                    <template v-else>
                        <!--Select Wallet And Currency-->
                        <v-fade-transition hide-on-leave>
                            <select-wallet
                                v-if="'wallet' === step"
                                ref="wallet"
                                :obj="addMoneyObj"
                                :wallet="wallet"
                                @disabled="walletDisabled = $event"
                            />
                        </v-fade-transition>

                        <!--Select Payment Option-->
                        <v-fade-transition hide-on-leave>
                            <select-option v-if="'option' === step" ref="option" :obj="addMoneyObj" :wallet="wallet" />
                        </v-fade-transition>

                        <!--Payment Details-->
                        <template v-if="'details' === step">
                            <!--Bank Account-->
                            <v-fade-transition hide-on-leave>
                                <add-by-bank
                                    v-if="'bank' === option"
                                    ref="bank"
                                    :obj.sync="addMoneyObj"
                                    :wallet="wallet"
                                />
                            </v-fade-transition>

                            <!--CREDIT CARD-->
                            <v-fade-transition v-if="'card' === option" hide-on-leave>
                                <add-by-card v-if="'NGN' !== wallet.currency.code" ref="card" :obj.sync="addMoneyObj" />
                            </v-fade-transition>

                            <!--Paypal-->
                            <v-fade-transition hide-on-leave>
                                <add-by-paypal v-if="'paypal' === option" ref="paypal" :obj="addMoneyObj" />
                            </v-fade-transition>

                            <!--Payoneer-->
                            <v-fade-transition hide-on-leave>
                                <add-by-payoneer
                                    v-if="'payoneer' === option"
                                    ref="payoneer"
                                    :wallet="wallet"
                                    :obj="addMoneyObj"
                                />
                            </v-fade-transition>

                            <!--Request Payment-->
                            <v-fade-transition hide-on-leave>
                                <add-by-payment-request
                                    v-if="'payment_request' === option"
                                    ref="payment_request"
                                    :obj="addMoneyObj"
                                />
                            </v-fade-transition>
                        </template>

                        <!--Submit Payment-->
                        <v-fade-transition v-if="'payment' === step" hide-on-leave>
                            <submit-payment
                                ref="option"
                                :obj="addMoneyObj"
                                :option="option"
                                :wallet="wallet"
                                :fee="fee"
                                :result="result"
                            />
                        </v-fade-transition>

                        <!--Send Request-->
                        <btn
                            v-if="'payment' === step && 'NGN' !== wallet.currency.code"
                            :disabled="disabled"
                            :loading="loading"
                            height="2.5rem"
                            class="w-100 position-relative custom-btn-primary"
                            @click="addMoneyByProvider()"
                        >
                            <span class="heading-6 bold-text"> Add Money </span>
                            <span
                                v-if="addMoneyObj.values.amount && wallet"
                                class="pay-price bold-text medium-text o-7 color-white tm-initial d-flex align-center"
                                style="right: 0"
                            >
                                {{ 'card' === option ? 'Pay' : '' }}
                                {{ formatAmount(addMoneyObj.values.amount, wallet.currency.code, 2, 2) }}
                            </span>
                        </btn>
                        <flutter-form
                            v-if="'payment' === step && 'NGN' === wallet.currency.code"
                            ref="flutterForm"
                            :amount="result"
                            :amount-with-percent="addMoneyObj.values.amount"
                            :customer="user"
                            :wallet="wallet.id"
                            @paymentIntent="flutterPaymentIntent($event.payload)"
                        />

                        <!--Continue-->
                        <btn
                            v-if="'payment' !== step && ('payment' !== step || 'NGN' !== wallet.currency.code)"
                            :disabled="disabled"
                            height="2.5rem"
                            class="w-100 continue-chunk-btn custom-btn-primary"
                            :loading="nextLoading"
                            @click="next()"
                        >
                            <span class="heading-6 bold-text">Continue</span>
                        </btn>
                    </template>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import Btn from '~/shared/components/Btn';
import Helpers from '~/shared/mixins/Helpers';
import Screen from '~/shared/mixins/Screen';

import AddMoney from '../../models/AddMoney';
import AddByBank from './AddMoneySteps/AddByBank';
import AddByCard from './AddMoneySteps/AddByCard';
import AddByPaymentRequest from './AddMoneySteps/AddByPaymentRequest';
import AddByPayoneer from './AddMoneySteps/AddByPayoneer';
import AddByPaypal from './AddMoneySteps/AddByPaypal';
import FlutterForm from './AddMoneySteps/FlutterForm.vue';
import PaymentReceived from './AddMoneySteps/PaymentReceived';
import SelectOption from './AddMoneySteps/SelectOption';
import SelectWallet from './AddMoneySteps/SelectWallet';
import SubmitPayment from './AddMoneySteps/SubmitPayment';
import { mapGetters } from 'vuex';

import Vue from 'vue';
import Flutterwave from 'flutterwave-vue-v3';

// noinspection JSUnusedGlobalSymbols
export default {
    name: 'AddMoney',

    components: {
        FlutterForm,
        Btn,
        PaymentReceived,
        SubmitPayment,
        AddByPaymentRequest,
        AddByPaypal,
        AddByPayoneer,
        AddByCard,
        AddByBank,
        SelectOption,
        SelectWallet,
    },

    mixins: [Screen, Helpers],

    props: {
        value: {
            default: false,
        },
        showModal: {
            default: true,
        },
        isModal: {
            default: false,
        },
    },

    data() {
        return {
            walletReset: false,
            dialog: this.value,
            received: false,
            step: 'wallet',
            removedState: {},
            steps: [
                { name: 'Currency/Amount', value: 'wallet', completed: false },
                { name: 'Payment Option', value: 'option', completed: false },
                { name: 'Details', value: 'details', completed: false },
                { name: 'Payment', value: 'payment', completed: false },
            ],
            addMoneyObj: null,
            loading: false,
            nextLoading: false,
            walletDisabled: false,
            percentByCard: null,
        };
    },

    computed: {
        user() {
            return this.$store.state.info;
        },
        transferPercent() {
            let currency = null;
            if (this.wallet) {
                currency = this.wallet.currency.code;
            }

            return this.transferPercentData(currency);
        },
        isUpgrade() {
            return !this.__checkPermission('money_in');
        },
        option() {
            return this.addMoneyObj ? this.addMoneyObj.values.option : null;
        },
        wallet() {
            return this.addMoneyObj
                ? this.__allWallets.find(w => w.id === this.addMoneyObj.values.wallet_id)
                : null;
        },
        stepIndex() {
            return this.steps.findIndex(s => s.value === this.step);
        },
        disabled() {
            return (
                this.walletDisabled ||
                ('details' === this.step &&
                    'card' === this.option &&
                    (!this.addMoneyObj.values.card.card_id || !this.$store.state.cards.length)) ||
                (this.step === 'option' && this.wallet &&  this.option === 'card' && this.wallet.is_modulr === 1) ||
                ('payoneer' === this.option &&
                    'details' === this.step &&
                    !this.addMoneyObj.values.payoneer.balance_id) ||
                this.nextLoading
            );
        },
        fee() {
            if ('card' === this.option) {
                if (this.percentByCard) {
                    return (parseFloat(this.addMoneyObj.values.amount) * this.percentByCard) / 100;
                }

                return (parseFloat(this.addMoneyObj.values.amount) * this.transferPercent.card) / 100;
            }

            if ('payoneer' === this.option) {
                return parseFloat(this.addMoneyObj.values.payoneer.charge_fee);
            }

            return 0;
        },
        result() {
            return parseFloat(this.addMoneyObj.values.amount) - this.fee;
        },
        activeWalletId() {
          return this.$store.state.activeWalletId;
        },
        ...mapGetters({
            'cards': 'stripe/cards',
        }),
        selectedCard() {
            return 'card' === this.option && this.addMoneyObj.values.card.card_id ? this.cards?.find(item => item.id === this.addMoneyObj.values.card.card_id) : null;
        },
    },

    watch: {
        dialog() {
            if (this.dialog){
                this.__checkVerified({
                    permission: this.$store.state.kyc.KYCPermissionMoneyIn,
                    level: this.$store.state.kyc.KYCLevel2
                }).then(verified => {
                    if (!verified){
                        this.dialog = false;
                    }
                })
            }

            this.$emit('input', this.dialog);
            if (this.dialog && this.isUpgrade) {
                this.$store.state.upgradeDialog = true;
                this.dialog = false;
            }
        },
        value() {
            this.dialog = this.value;
            if (this.value) {
                this.setValues();
            }
        },
        'wallet.currency.code'(val, oldVal) {
            if ('NGN' === val) {
                Vue.use(Flutterwave, { publicKey: process.env.VUE_APP_FLUTTERWAVE_PUBLIC_KEY });
                this.steps.splice(
                    this.steps.findIndex(item => {
                        if ('details' === item.value) {
                            this.removedState = item;
                            return true;
                        }

                        return false;
                    }),
                    1,
                );
            } else if (oldVal && 'NGN' === oldVal) {
                this.steps.splice(2, 0, this.removedState);
            }
            this.steps.map(item => item.completed = false);
        },
        option(){
            const step = this.steps.find(s => s.value === 'option');
            if (step){
                step.completed = false;
            }
        },
        activeWalletId() {
          this.setValues();
        }
    },

    created() {
        this.setValues();
    },

    methods: {
        closeModal($e){
          if (this.isModal) {
            this.$store.dispatch('updateWallets')
            this.$store.commit('addMoney', false)
          } else {
            let route = this.$store.state.path;
            const backRoute = this.$store.state?.backRoute;
            if (backRoute?.path) {
              route = backRoute?.path;
              if (backRoute?.query) {
                route += '?' + new URLSearchParams(backRoute.query).toString();
              }
            }
            this.__changeRoute(route);
          }

            // if(this.showModal)
            // {
            //     this.dialog = false;
            // }else{
            //     $e.preventDefault()
            //     window.location.href = this.$store.state.path + 'wallet';
            // }
        },
        setValues() {
            this.received = false;
            this.step = 'wallet';
            this.steps = this.steps.map(s => {
                s.completed = false;
                return s;
            });

            let wallet = this.__allWallets.length
                ? this.$store.state.activeWalletId
                    ? this.__allWallets.find(w => w.id === this.$store.state.activeWalletId)
                    : this.__allWallets[0]
                : null;

            if (wallet?.is_modulr) {
                let wlts = this.__allWallets.filter(w => !w.is_modulr);
                wallet = wlts[0];
            }
            const data = {
                wallet_id: wallet ? wallet.id : null,
                wallet_type: this.$store.state.walletType,
            };

            this.addMoneyObj = new AddMoney(data);
        },
        availableStep(item) {
            const index = this.steps.findIndex(s => s.value === item.value);
            return (
                !this.walletDisabled && (item.completed || (this.steps[index - 1] && this.steps[index - 1].completed))
            );
        },
        next() {
            if ('card' === this.option && this.addMoneyObj.values.card.card_id) {
                window.axios.get(`get/card-percent/${this.addMoneyObj.values.card.card_id}`).then(result => {
                    this.percentByCard = result.data.percent;
                });
            }

            const ref = 'details' === this.step ? this.$refs[this.option] : this.$refs[this.step];
            if (ref) {
                ref.$validator.validateAll().then(valid => {
                    if (valid) {
                        const validFunc = () => {
                            const index = this.steps.findIndex(s => s.value === this.step);
                            this.steps[index] ? (this.steps[index].completed = true) : null;
                            this.step =
                                ~index && this.steps[index + 1] ? this.steps[index + 1].value : this.setValues();
                        };
                        if ('payoneer' === this.option && 'details' === this.step) {
                            this.nextLoading = true;
                            this.$store
                                .dispatch('payoneer/createPaymentAddMoney', {
                                    ...this.addMoneyObj.values.payoneer,
                                    amount: this.addMoneyObj.values.amount,
                                })
                                .then(data => {
                                    this.nextLoading = false;

                                    this.addMoneyObj.values.payoneer.charge_fee =
                                        (data.fees.find(i => 'charge_fee' === i.type) || {}).amount || 0;
                                    this.addMoneyObj.values.payoneer.target_amount = data.amounts.target.amount;
                                    this.addMoneyObj.values.payoneer.commit_id = data.commit_id;

                                    validFunc();
                                })
                                .catch(error => {
                                    this.nextLoading = false;
                                    const message = error.message || 'Payoneer error';
                                    ref.$validator.errors.add({
                                        field: 'balance_id',
                                        msg: message,
                                    });
                                });
                        } else {
                            validFunc();
                        }
                    }
                });
            }
        },
        back() {
            this.step = this.steps[this.stepIndex - 1] ? this.steps[this.stepIndex - 1].value : this.setValues();
        },
        addMoneyByProvider() {
            switch (this.addMoneyObj.values.option) {
                case 'bank':
                    this.addMoneyStripeClient();
                    break;
                case 'card':
                    this.addMoneyStripeClient();
                    // this.addMoney(); // old stripe
                    break;
                default:
                    this.addMoney();
            }
        },
        addMoneyStripeClient() {
            this.loading = true;
            const callbackSucceed = () => {
                window.axios.get(this.$store.state.getPath + 'wallets').then(response => {
                    this.$store.commit('setData', { key: 'wallets', data: response.data });
                    this.loading = false;
                    this.received = true;
                });
            };
            const callbackFailed = () => {
                this.loading = false;
            };
            this.$store.dispatch('stripe/createPay', {
                "provider": this.addMoneyObj.values.option,
                "amount": this.addMoneyObj.values.amount,
                "currency": this.wallet.currency.code,
                "paymentType": "DB",
                "card_id": this.addMoneyObj.values.card.card_id,
                "wallet_id": this.addMoneyObj.values.wallet_id,
                "reason": this.addMoneyObj.values.reason,
                "user_note": this.addMoneyObj.values.note,
                callbackSucceed,
                callbackFailed,
            })
                .catch(error => this.__checkNetwork(error, () => this.addMoneyStripeClient()))
                .finally(() => this.loading = false);
        },
        addMoney() {
            this.loading = true;
            this.addMoneyObj
                .create(`${this.$store.state.path}wallet/add-money`)
                .then(response => {
                    this.loading = false;
                    this.$store.commit('setData', { key: 'wallets', data: response.data.wallets });
                    this.$store.commit('setData', { key: 'soloEntityWallet', data: response.data.soloEntityWallet });

                    if (response.data.worker_balance) {
                        this.$store.commit('updateData', { key: 'workerBalance', data: response.data.worker_balance });
                    }

                    if (response.data.redirect) {
                        const data = {
                            url: response.data.redirect,
                            callback: () => (this.dialog = false),
                        };
                        this.$store.commit('showSecureCardDialog', data);
                    } else {
                        this.received = true;
                    }
                })
                .catch(error => {
                    this.__checkNetwork(error, () => this.addMoney());
                    this.loading = false;
                    if(error.response?.status !== 429){
                      this.$store.commit('setAlert', { type: 'error', message: error.response.data.message });
                    }
                });
        },
        flutterPaymentIntent(response) {},
    },
};
</script>
