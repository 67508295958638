<template>
    <div>
        <template v-if="cardUpgrade">
            <card-upgrade v-model="dialog" contentModal="iban"></card-upgrade>
        </template>

        <template v-else>
            <modal v-model="dialog" permission="request_iban" width="28.125rem" :close-size="11" close-color="#8F8F8F" :padding="false" class-names="iban-modal">
                <template slot="content">
                    <div class="center modal-sm-2 modal-request-iban">
                        <div class="modal-content">
                            <div class="modal-body">
                                <i class="icon-bank color-dark-grey"></i>
                                <h1 class="heading-2 bold-text color-dark-blue text-center mb-10" >
                                    Do you want to request <br />
                                    your IBAN?
                                </h1>
                                <p class="paragraph color-dark-blue mb-30 text-center">
                                    Any payments sent to this IBAN will be shown in {{walletText}} wallet.
                                </p>

                                <button class="btn btn-big btn-request-iban btn-purple normal-text bold-text color-white" @click="request()">
                                    Request my IBAN
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </modal>

            <modal v-model="dialogCongratulation" :close-size="11" close-color="#8F8F8F" width="28.125rem" class-names="iban_congratulations">
                <template slot="content">

                    <div class="center modal-sm-2 modal-requested-iban" >
                        <div class="modal-content">
                            <div class="modal-body">
                                <img
                                    src="/images/bank-check-circle.svg"
                                    alt="bank-check-circle"
                                />
                                <h1 class="heading-2 bold-text color-dark-blue text-center mb-10">
                                    Done! IBAN Number <br />
                                    Requested.
                                </h1>
                                <p class="paragraph color-dark-blue text-center mb-30">
                                    You will receive an email confirming <br />
                                    your IBAN Number within 48 Hours.
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
            </modal>
        </template>
    </div>
</template>
<script>
import Modal from "~/shared/components/Modal";
import Helpers from '~/shared/mixins/Helpers';
import 'vue-tel-input/dist/vue-tel-input.css'
import CardUpgrade from "../../components/modals/CardUpgrade";

export default {
    name: 'IbanRequestForm',
    components: {
        CardUpgrade,
        Modal
    },
    mixins: [Helpers],
    props: {
        value: {
            required: true,
            type: Boolean
        },
        nativeCardObj: {
            default: null
        },
        wallet: {
            required: true,
        },
    },
    data() {
        return {
            cardUpgrade: false,
            load: false,
            dialog: this.value,
            dialogCongratulation: false,
            loading: false
        }
    },
    computed: {
        walletText() {
            return this.wallet && this.wallet.currency.code ? "EURO" : "this";
        }
    },
    watch: {
        value() {
            this.$store.state.worker.plan.value === 'free' ? this.cardUpgrade = true : null

            this.dialog = this.value
            if (this.value) {
                this.$validator.resume();
            } else {
                this.$validator.pause();
                this.errors.clear();
            }
        },
        dialog(v) {
            if (v) {
                this.__checkVerified({
                    permission: this.$store.state.kyc.KYCPermissionMoneyOut,
                    level: this.$store.state.kyc.KYCLevel2
                }).then(verified => {
                    if (!verified){
                        this.dialog = false;
                    }
                })
                /* if (!this.__checkUnlVerified()) {
                    this.dialog = false;
                    //request iban anyway
                    // this.requestForced();
                } */
            }
            this.$emit('input', this.dialog);
            this.errors.clear();
        },
        dialogCongratulation() {
            if (!this.dialogCongratulation) {
                this.$emit('setTab', 'pending')
            }
        },
    },
    mounted() {
        if (this.dialog) {
            this.__checkVerified({
                permission: this.$store.state.kyc.KYCPermissionMoneyOut,
                level: this.$store.state.kyc.KYCLevel2
            }).then(verified => {
                if (!verified){
                    this.dialog = false;
                }
            })
            /* if (!this.__checkUnlVerified()) {
                    this.dialog = false;
                    //request iban anyway
                    // this.requestForced();
            } */
        }
    },
    methods: {
        request() {
            axios.post(this.$store.state.path+'request-iban-number',{
                wallet_id:this.wallet.id
            }).then(response => {
                this.dialog = false;
                this.dialogCongratulation = true;
                this.wallet.iban_request= response.data.wallet.iban_request
                this.$emit('created')
            }).catch(error => {
                this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
            })
        },

        requestForced() {
            axios.post(this.$store.state.path+'request-iban-number',{
                wallet_id:this.wallet.id
            }).then(response => {
                this.wallet.iban_request= response.data.wallet.iban_request
                this.$emit('created')
            }).catch(error => {
                this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
            })
        }
    }
}
</script>
