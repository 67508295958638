var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-toggle-switch row"},[_c('BaseButton',{staticClass:"btn-small col base-toggle-switch-button",class:[
      _vm.customButton1Classes,
      _vm.activeCard === 1 ? 'btn-purple font-medium color-white' : 'color-grey-2'
    ],on:{"click":function($event){return _vm.toggle(1)}}},[_vm._v(" "+_vm._s(_vm.label1)+" ")]),_c('BaseButton',{staticClass:"btn-small col bg-white base-toggle-switch-button",class:[
      _vm.customButton2Classes,
      _vm.activeCard === 2
        ? 'btn-purple font-medium color-white'
        : 'color-grey-2',
    ],on:{"click":function($event){return _vm.toggle(2)}}},[_vm._v(" "+_vm._s(_vm.label2)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }