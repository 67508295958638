<template>
    <div class="modal-container modal-info" :class="heightClass">
        <div class="modal-header" :class="customHeaderClasses">
            <BaseIcon :name="modalData.icon.name" :class="modalData.icon.class" />
        </div>
        <div class="modal-body" :class="customBodyClasses">
            <h2
                class="heading-2 font-medium color-black mb-3"
                :class="leftAligned ? '' : 'text-center'"
            >
                {{ modalData.title }}
            </h2>
            <template v-if="!$slots['default']">
                <p
                    v-if="modalData.asHtml"
                    v-html="__sanitizeHtml(modalData.text)"
                    class="paragraph text-base color-grey-2 fs-18"
                    :class="leftAligned ? '' : 'text-center'"
                >
                </p>
                <p v-else-if="isBdmUrl" class="paragraph text-base color-purple text-center fs-18">
                    <a :href="modalData.text" class="color-purple" target="_blank">{{ modalData.text }}</a>
                </p>
                <p
                    v-else
                    class="paragraph text-base color-grey-2 fs-18"
                    :class="leftAligned ? '' : 'text-center'"
                >
                    {{ modalData.text }}
                </p>
            </template>
            <template v-else>
                <slot></slot>
            </template>
        </div>
        <div class="modal-footer justify-content-center w-100" :class="customFooterClasses">
            <BaseButton
                v-if="modalData.btnText"
                type="button"
                :disabled="invalid"
                @click="$emit('on-confirm'), $emit('close-modal')"
                class="btn-big btn-purple text-base font-medium color-white w-100"
            >
                {{ modalData.btnText }}
            </BaseButton>
            <slot name="underButton"></slot>
        </div>
    </div>
</template>

<script>
import Helpers from "~/shared/mixins/Helpers";

export default {
    name: "ModalInfo",

    mixins: [Helpers],

    props: {
        modalData: {
            type: Object,
            default: () => {},
        },
        invalid: {
            type: Boolean,
            default: false,
        },
        heightClass: {
            type: String,
            default: "",
        },
        customHeaderClasses: {
            type: String,
            default: '',
        },
        customBodyClasses: {
            type: String,
            default: '',
        },
        customFooterClasses: {
            type: String,
            default: '',
        },
        leftAligned : {
            type: Boolean,
            default: false,
        },
        isBdmUrl: {
            type: Boolean,
            default: false,
        }
    },
};
</script>
