import Middleware from "~/routes/middleware/Middleware";
import store from "~/store";

export default class changeOrganization extends Middleware{
    constructor(...args) {
        super(...args);
    }

    async resolve() {
        const id = this.to.params.id;
        const route = this.to.params.route;
        return id === store.state?.organization?.id?
            this.responseRedirect(route):
            await store.dispatch('initialization/__changeOrganization', {id, route});
    }
}
