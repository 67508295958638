<template>
    <div class="setup-profile-step w-100 h-100 mb-0 d-flex flex-column justify-space-between">
        <div>
            <h1 class="tm-transform-none tm-heading-1 font-weight-medium tm-color-black text-center mb-9">
                Tell us more about your current position
            </h1>
            <p class="tm-normal-text tm-color-dark-grey text-center mb-50">
                You can add workdates and other details later.
            </p>
            <v-row no-gutters>
                <v-col cols="12" md="6" class="col-right">
                    <tm-input
                        name="title"
                        v-validate="greetingObj.rules.work_info.title"
                        label="Job Title"
                        v-model="greetingObj.values.work_info.title"
                        :error-messages="errors.collect('title')"
                    ></tm-input>
                </v-col>
                <v-col cols="12" md="6" class=" col-left">
                    <tm-advanced-select
                        :is-sort="false"
                        label="Experience Level"
                        name="experience"
                        v-validate="greetingObj.rules.work_info.experience_id"
                        v-model="greetingObj.values.work_info.experience_id"
                        item-value="id"
                        :items="experienceLevels"
                        :error-messages="errors.collect('experience')"
                    >
                        <template v-slot:selection="{ item }">
                            <span class="tm-color-black mr-1">{{ item.name }} ({{item.right_text}})</span>
                        </template>
                        <template v-slot:item="{ item }">
                            <span class="tm-color-black mr-1">{{ item.name }} ({{item.right_text}})</span>
                        </template>
                    </tm-advanced-select>
                </v-col>
            </v-row>
            <tm-autocomplete
                placeholder="e.g. Google"
                label="Company"
                v-model="greetingObj.values.work_info.company_id"
                search-key="name"
                item-value="id"
                :search-route="$store.state.getPath+'search-companies'"
                :search-additional-params="{id: greetingObj.values.work_info.company_id}"
                @inputText="greetingObj.values.work_info.company_name = $event"
                name="company"
                v-validate="!greetingObj.values.work_info.company_name? greetingObj.rules.work_info.company_id: ''"
                data-vv-as="company"
                :error-messages="errors.collect('company')"
            >
                <template v-slot:selection="{ item }">
                    <div class="d-flex align-center">
                        <avatar size="20" :avatar="item.logo" :name="item.name"></avatar>
                        <span class="tm-color-black mr-1">{{ item.name }}</span>
                    </div>
                </template>
                <template v-slot:item="{ item }">
                    <div class="d-flex align-center">
                        <avatar size="20" :avatar="item.logo" :name="item.name"></avatar>
                        <span class="tm-color-black mr-1">{{ item.name }}</span>
                    </div>
                </template>
            </tm-autocomplete>
            <v-row no-gutters>
                <v-col cols="12" md="6" class="col-right">
                    <tm-advanced-select
                        label="Employment Type"
                        name="employment_type"
                        v-validate="greetingObj.rules.work_info.employment_type"
                        v-model="greetingObj.values.work_info.employment_type"
                        item-value="id"
                        item-text="name"
                        :items="employmentTypes"
                        :error-messages="errors.collect('employment_type')"
                        data-vv-as="Employment Type"
                    >
                    </tm-advanced-select>
                </v-col>
                <v-col cols="12" md="6" class="col-left">
                    <tm-autocomplete
                        label="Industry"
                        v-model="greetingObj.values.work_info.industry_id"
                        search-key="name"
                        item-value="id"
                        item-text="name"
                        :search-route="$store.state.getPath+'search-industries'"
                        :search-additional-params="{id: greetingObj.values.work_info.industry_id}"
                        name="industry"
                        v-validate="greetingObj.rules.work_info.industry_id"
                        data-vv-as="industry"
                        :error-messages="errors.collect('industry')"
                    >
                    </tm-autocomplete>
                </v-col>
            </v-row>
        </div>
        <div class="d-flex justify-space-between align-center welcome-page-footer">
            <button @click="$emit('back')" class="tm-btn h-auto p-0 tm-medium-text tm-color-dark-grey">
                <i class="icon-long-arrow-left pr-2"></i> Back
            </button>
            <btn :loading="loading" :disabled="!isValid" @click="$emit('done')" height="2.625rem">
                <span class="font-weight-regular tm-medium-text px-6 py-2 tm-color-white">Done</span>
            </btn>
        </div>
    </div>
</template>
<script>
import TmAvatarInput from "~/shared/components/form/TmAvatarInput";
import TmInput from "~/shared/components/form/TmInput";
import TmSelectDate from "~/shared/components/form/TmSelectDate";
import Btn from "~/shared/components/Btn";
import TmAdvancedSelect from "~/shared/components/form/TmAdvancedSelect";
import TmAutocomplete from "~/shared/components/form/TmAutocomplete";
import Avatar from "~/shared/components/Avatar";
export default {
    name: 'Work',
    components: {Avatar, TmAutocomplete, TmAdvancedSelect, Btn, TmSelectDate, TmInput, TmAvatarInput},
    props: {
        greetingObj: {
            required: true,
            type: Object
        },
        experienceLevels: {
            required: true,
            type: Array
        },
        employmentTypes: {
            required: true,
            type: Array
        },
        loading: {
            default: false
        }
    },
    data(){
        return {}
    },
    computed: {
        isValid() {
            return !Object.keys(this.fields).some(key => this.fields[key].invalid)
        }
    },
}
</script>
