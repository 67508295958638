<!-- @format -->

<template lang="html">
    <div id="flutter-pay-from" class="mt-64" @click="openFlutterFrame">
        <flutter-pay-btn v-bind="paymentData" v-text="'Add Money'" />
    </div>
</template>

<script lang="js">
import { FlutterwavePayButton } from 'flutterwave-vue-v3';

import Helpers from '~/shared/mixins/Helpers';

export default {
    name: 'FlutterForm',

    components: { 'flutter-pay-btn': FlutterwavePayButton },

    mixins: [Helpers],

    props: {
        amount: {
            required: true,
            type: [String, Number],
        },
        amountWithPercent: {
            required: true,
            type: [String, Number],
        },
        customer: {
            required: true,
            type: Object,
        },
        wallet: {
            required: true,
            type: Number,
        },
        title: {
            required: false,
            default: 'Add Money',
            type: String,
        },
        currency: {
            required: false,
            default: 'NGN',
            type: String,
        },
        description: {
            required: false,
            default: 'Top Up money',
            type: String,
        },
    },

    data() {
        return {
            successful: false,
            paymentData: {
                class: 'flutter-btn',
                payment_options: 'card',
                amount: this.getAmountWithPercent(),
                currency: this.currency,
                redirect_url: this.getRedirectUrl(),
                tx_ref: this.getTxRef(),
                customer: {
                    name: this.customer.name,
                    email: this.customer.email,
                    phone_number: this.customer.phone,
                },
                customizations: {
                    title: this.title,
                    description: this.description,
                    logo: this.$store.state.wlstyle.st_logo_mon,
                },
                meta: {
                    counsumer_id: this.customer.id,
                    consumer_mac: this.uidv4(),
                },
                callback: this.makePaymentCallback,
                onclose: this.closedPaymentModal,
            },
        };
    },

    watch: {
        successful: {
            deep: true,
            immediate: true,
            handler(val) {
                if (true === val) {
                    window.removeEventListener('onbeforeunload', this.modalSuccessClose);
                }
            },
        },
    },

    mounted() {
        window.addEventListener('onbeforeunload', this.modalSuccessClose);
    },

    created() {
    },

    methods: {
        /**
         * @param event Window listener event
         * @returns string
         */
        modalSuccessClose(event) {
            event.preventDefault();
            return 'Please, wait until the end of the transaction';
        },
        /**
         * Call event
         *
         * @param response FLW transaction response data
         * @returns Promise
         */
        setTransaction(response) {
            if ('successful' === response.status) {
                this.successfull = true;
            }

            return window.axios.post(`${this.$store.state.path}flutterwave/transaction`, {
                wallet_id: this.wallet,
                amount: this.getAmountWithPercent(),
                tx_ref: response.tx_ref,
                flw_ref: response.flw_ref,
                currency: response.currency,
                successful: response.status,
                created: response.created_at,
                transaction_id: response.transaction_id,
                charged_amount: response.charged_amount,
                status: response.status,
                message: response.charge_response_message,
                code: response.charge_response_code,
            });
        },
        openFlutterFrame() {
            window.axios.post(`${this.$store.state.path}flutterwave/init-ref`, {
                tx_ref: this.paymentData.tx_ref,
                wallet: this.wallet,
            }).then();
        },
        /**
         * @param response flw response
         */
        makePaymentCallback(response) {
            this.setTransaction(response).then(() => {
                this.$emit('paymentIntent', { payload: response });
				this.__changeRoute(this.getRedirectUrl())
            });
        },
        /**
         * Close dialog after operation and emit result boolean
         */
        closedPaymentModal() {
            this.$emit('closeTopUpFlutter', { successful: this.successfull });
        },
        /**
         * @returns string
         */
        getTxRef() {
            const txLength = 32;
            const txRef = this.getAmount() + this.randomString(32);
            return txLength < txRef.length ? txRef.slice(0, txLength) : txRef;
        },
        /**
         * @returns string
         */
        getRedirectUrl() {
            return 'wallet?cur=ngn&';
        },
        /**
         * @description use this method for get amountWithPercentage value
         * @returns string
         */
        getAmountWithPercent() {
            return String(this.amountWithPercent).replace(',', '');
        },
        /**
         * @description use this method for get amount value
         * @returns string
         */
        getAmount() {
            return String(this.amount).replace(',', '');
        },
    },
};
</script>

<style lang="scss" scoped>
.flutter-btn {
    width: 100%;
    background-color: #1e60eb;
    border: none;
    border-radius: 0.4rem;
    text-align: center;
    font-weight: 500;
    color: #f8f8f8;
    font-size: 14px;
    line-height: 0.2rem;
    padding: 1.4rem 0;
}
</style>
