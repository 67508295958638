var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[(_vm.fab)?_c('div',{staticClass:"tm-icon-fab-box",class:_vm.colorFromClass? _vm.color: null,style:({
            background: !_vm.colorFromClass? _vm.fabColor || _vm.color: null,
            height: _vm.getSize,
            width: _vm.getSize,
            opacity: _vm.fabColor? 1: null
        })}):_vm._e(),_c('div',{class:[_vm.fab? 'tm-icon-fab': null, !_vm.isSvg? _vm.name: null , _vm.colorFromClass? _vm.color: null],staticStyle:{"display":"flex","transition":".3s"},style:({
            color: !_vm.colorFromClass? _vm.color: null,
            'font-size': _vm.getFontSize,
            'transform': _vm.rotate? `rotate(${_vm.rotateRound}deg)`: 'rotate(0deg)'
        })},[(_vm.isSvg)?_c('tm-svg',{attrs:{"height":_vm.height,"width":_vm.width,"color":_vm.color,"name":_vm.name,"size":_vm.size}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }