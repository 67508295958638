import store from "@/store";
import walletChildren from "@/routes/namespaces/children/walletChildren";

export default [
    {
        path: '/documents',
        name: 'document',
        component: () => import("~/modules/wallet/views/Documents.vue"),
        meta: {
            title: 'Documents',
            data: false,
            app: 'wallet',
            middleware: 'auth',
        },
    },
    {
        path: '/tax-allowances',
        name: 'taxAllowances',
        beforeEnter: (to, from, next) => {
            store.dispatch('allowances/getTaxAllowances')
                .then(r => {
                    next();
                })
                .catch((error) => {
                    next(false);
                });
        },
        component: () => import("~/modules/wallet/views/TaxAllowances/TaxAllowances.vue"),
        meta: {
            title: 'Tax allowances',
            app: 'wallet',
            middleware: ['auth', 'taxAllowanceAccess']
        },
    },
    {
        path: "/tax-allowances-onboarding-wizard",
        name: "TaxAllowancesOnboardingWizard",
        component: () => import("~/modules/wallet/views/TaxAllowances/TaxAllowancesWizard.vue"),
        meta: {
            title: "Tax Allowances",
            app: "wizard",
            middleware: 'auth'
        },
    },
    {
        path: store.state.path,
        alias: ['/wallet'],
        name: 'Main',
        component: () => import("~/modules/wallet/views/WalletDashboard.vue"),
        meta: {
            title: "Wallet Dashboard",
            app: 'wallet',
            middleware: 'auth'
        },
        children: walletChildren
    },
    {
        path: "/upgrade-flow-modals",
        component: () => import("~/modules/wallet/views/UpgradeFlowModals.vue"),
        meta: {
            title: "Upgrade flow modals",
            app: 'wallet',
            middleware: 'auth'
        },
    },
];
