<template>
    <div class="table-no-data">
        <slot name="icon"></slot>
        <p class="text-lg font-medium color-black mb-2 mt-3">{{ title }}</p>
        <p class="text-sm leading-130 text-grey-700 mb-3">
            {{ description }}
        </p>
        <slot name="button"></slot>
    </div>
</template>

<script>
export default {
    name: "TableEmptyState",
    props: {
        title: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            required: true,
        },

        btnText: {
            type: String,
            required: false,
        },
    },
};
</script>

<style lang="scss">
.table-no-data {
    padding: 32px 0;
    display: flex;
    width: 265px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    i {
        width: 48px;
        height: 48px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 48px;
        margin-bottom: 1rem;
    }

    .v-image {
        width: 232px;
        height: 232px;
        max-width: 232px !important;
    }

    .empty-state-button {
        width: 237px !important;
    }
}

@media (max-width: 992px) {
    .table-no-data {
        width: 100%;
    }
}

@media (max-width: 640px) {
    .table-no-data {
        max-width: 265px;
        width: 100%;
        min-width: 0;

        .text-sm {
            white-space: normal;
        }
    }
}
</style>
