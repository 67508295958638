<template>
    <div class="kyc-banner-card" v-if="isVisible" style="z-index: 9;">
        <div class="kyc-banner-icon">
            <img :src="'/images/kyc/icon-warning.svg'" alt="KYC"/>
        </div>
        <div class="kyc-banner-card-content">
            <h2
                class="text-lg font-medium leading-130 color-white mb-3"
                v-if="__KYCStatus === 'not_started'"
            >
                Unlock features with KYC verification
            </h2>
            <h2
                class="text-lg font-medium leading-130 color-white mb-3"
                v-if="__KYCStatus === 'rejected'"
            >
                KYC verification rejection notice
            </h2>
            <p
                class="text-base color-light-grey-3 mb-3"
                v-if="__KYCStatus === 'not_started'"
            >
                Complete our KYC verification to access advanced features. Enter your
                information below to unlock your account's full potential.
            </p>
            <p
                class="text-base color-light-grey-3 mb-3"
                v-if="__KYCStatus === 'rejected'"
            >
                Unfortunately, your KYC submission was rejected. Please review the
                provided information and resubmit your KYC.
            </p>
            <WlBaseButton type="white"   @click="__checkVerified()">
                Start verification
            </WlBaseButton>
        </div>
        <WlBaseButton
            class="btn-icon-sm text-xl color-light-grey-3"
            @click="hideBanner()"
        >
            <WlBaseIcon name="times"/>
        </WlBaseButton>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
    name: "KYCBanner",

    data(){
        return {
            visibleStatuses: ['not_started', 'rejected'],
            hide: false,
        }
    },

    computed: {
        ...mapGetters("kyc", ["__KYCStatus"]),

        isVisible(){
            return this.visibleStatuses.includes(this.__KYCStatus) && !this.isHidden;
        },
        isFinalRejected(){
            return this.$store.state.kyc.restrictionType &&
                (this.$store.state.kyc.restrictionType === 'final' || this.$store.state.kyc.restrictionType === 'block');
        },
        isHidden(){
            return this.hide || this.isFinalRejected || this.closedToday;
        },
        closedToday(){
            return this.closedAt && this.closedAt > Date.now() - 86400000;
        },
        closedAt(){
            return this.$store.state.authUser.options.KYCAlertClosedAt;
        },
    },
    methods: {
        ...mapActions("kyc", ["__hideBanner", "__checkVerified"]),
        hideBanner(){
            this.hide = true;
            this.$store.dispatch('updateUserOptions', {KYCAlertClosedAt: Date.now()});
        },
    }
};
</script>

<style lang="scss" scoped>
.kyc-banner-card {
    position: fixed;
    left: 32px;
    bottom: 32px;
    display: flex;
    width: 638px;
    padding: 24px;
    align-items: flex-start;
    border-radius: 32px;
    background: #725ae6;
    box-shadow: 0px 10px 17px 0px rgba(0, 0, 0, 0.12);
    gap: 24px;

    .btn-icon-sm {
        position: absolute;
        top: 20px;
        right: 20px;

        &:hover {
            background-color: transparent !important;
        }
    }
}

@media screen and (max-width: 768px) {
    .kyc-banner-card {
        width: calc(100dvw - 40px);
        left: 20px;
        bottom: 20px;
    }
}

@media screen and (max-width: 640px) {
    .kyc-banner-card {
        padding: 24px;
        flex-direction: column;
        gap: 16px;
    }
}
</style>
