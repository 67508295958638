const state = {
    permissions: [],
    isInvitee: null,
    memberId: null,
    billingPagination: null,
};

const getters = {
    permissions: (state) => state.permissions,
    memberId: (state) => state.memberId,
    isInvitee: (state) => state.isInvitee,
    billingPagination: (state) => state.billingPagination,
}

const mutations = {
    permissions(state, payload) {
        state.permissions = payload;
    },
    memberId(state, payload) {
        state.memberId = payload;
    },
    isInvitee(state, payload) {
        state.isInvitee = payload;
    },
    billingPagination(state, payload) {
        state.billingPagination = payload;
    },
    initData(state, payload){
        state.isInvitee = payload ? payload.isInvitee : 0;
        state.memberId = payload ? payload.id : null;
        state.permissions = [];
        state.billingPagination = null;
    },
    addPermission(state, value){
        const index = state.permissions.findIndex(item => item === value);
        if (index === -1) {
            state.permissions.push(value);
        }
    },
    deletePermission(state, value) {
        const index = state.permissions.findIndex(item => item === value);
        if (index !== -1) {
            state.permissions.splice(index, 1);
        }
    },
}

const actions = {
    getPermissions({ commit, dispatch }, memberId) {
        if(memberId){
            commit('memberId', memberId);
        }
        return axios
            .get('/worker-permissions', {params: {
                id: state.memberId,
                is_invitee: state.isInvitee ? 1 : 0,
            }})
            .then(response => {
                commit('permissions', response.data.permissions);
                return response.data;
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.message}, { root: true });
                throw error;
            });
    },
    async updateMemberPermission({ commit }, permission) {
        if(state.memberId){
            await axios.post('/update-worker-permissions', {
                id: state.memberId,
                is_invitee: state.isInvitee ? 1 : 0,
                permission: permission.value,
                enable: permission.enabled ? 1 : 0,
            })
            .then(response => {
                commit(permission.enabled ? 'addPermission' : 'deletePermission', permission.value)
            })
            .catch(error => {
                commit("setAlert", {type: 'error', message: error.response.data.message});
            });
        }
    },
    async getBillingData({ commit, dispatch }, q = {}) {
        q.id = state.memberId;
        await axios.get('/billing-paginate', {params: {...q}})
        .then(response => {
            commit('billingPagination', response.data.billingPagination);
        })
        .catch(error => {
            this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
        });
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}