var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"transaction-status-tag",class:[
  _vm.statusTypeClass,
  _vm.cardStatusClass,
  { 'hide-icon': _vm.hideIcon, 'bg-tag': _vm.hasBg },
]},[(_vm.type === 'error')?_c('WlBaseIcon',{attrs:{"name":"transaction-status-cancelled"}}):_vm._e(),(_vm.type === 'locked')?_c('WlBaseIcon',{attrs:{"name":"locked"}}):_vm._e(),(
    _vm.type === 'declined' ||
    _vm.type === 'inactive' ||
    _vm.type === 'blocked' ||
    _vm.type === 'deactivated' ||
    _vm.type === 'suspend' ||
    _vm.type === 'rejected' ||
    _vm.type === 'pick-up'
  )?_c('WlBaseIcon',{attrs:{"name":"transaction-status-declined"}}):_vm._e(),(_vm.type === 'overdue'  || _vm.type === 'terminated')?_c('WlBaseIcon',{attrs:{"name":"transaction-status-overdue"}}):_vm._e(),(_vm.type === 'pending')?_c('WlBaseIcon',{attrs:{"name":"transaction-status-pending"}}):_vm._e(),(_vm.type === 'cancelled')?_c('WlBaseIcon',{attrs:{"name":"transaction-status-cancelled"}}):_vm._e(),(_vm.type === 'reserved')?_c('WlBaseIcon',{attrs:{"name":"transaction-status-reserved"}}):_vm._e(),(_vm.type === 'refunded')?_c('WlBaseIcon',{attrs:{"name":"transaction-status-refunded"}}):_vm._e(),(
    _vm.type === 'completed' ||
    _vm.type === 'active' ||
    _vm.type === 'available' ||
    _vm.type === 'success'
  )?_c('WlBaseIcon',{attrs:{"name":"transaction-status-completed"}}):_vm._e(),_c('p',{staticClass:"text-sm font-medium"},[_vm._v(" "+_vm._s(_vm.formatStatusText(_vm.text))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }