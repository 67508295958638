<template>
  <WlBaseMenu
    :left="false"
    :close-on-content-click="false"
    offset-y
    z-index="101"
    content-class="select-filtering-container"
    class="menu-select-filtering"
    nudge-bottom="4px"
    v-model="menu"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-on="on" v-bind="attrs">
        <WlBaseInput
          ref="input"
          :label="label"
          :class="classNames"
          :placeholder="placeholder"
          :disabled="disabled"
          readonly
          :value="selectedYear"
          v-bind="attrs"
          v-on="on"
          @blur="$emit('blur')"
          :error-messages="[...errorMessages]"
        >
          <template v-slot:prepend>
            <WlBaseIcon name="calendar-2" class="color-purple" />
          </template>
        </WlBaseInput>
      </div>
    </template>

    <div class="menu-content-container w-full">
      <v-list class="filter-list">
        <v-list-item class="filter-item" @click="clearYear()">
          <span class="color-feedback-error">Delete year</span></v-list-item
        >

        <v-list-item
          class="filter-item"
          v-for="year in yearRange.slice().reverse()"
          :key="year"
          @click="selectYear(year)"
        >
          <v-list-item-title>{{ year }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
  </WlBaseMenu>
</template>

<script>
export default {
  name: "WlBaseYearPicker",

  props: {
    label: {
      type: String || null,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String || null,
        default: "Year",
    },
    errorMessages: {
      default: () => [],
    },
    menuProps: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classNames: {
      type: [Object, String, Array],
      default: () => [],
    },
    width: {
      type: [String, Number],
      default: "auto",
    },
    yearFrom: {
      default: 2010,
    }
  },

  data() {
    return {
      menu: false,
      yearRange: [], // Add this line to initialize the year range
      currentYear: new Date().getFullYear(), // Add this line to get the current year
      selectedYear: this.value,
    };
  },

  created() {
    this.generateYearRange(this.yearFrom, this.currentYear); // Adjust the range as needed
  },

  computed: {
    menuClasses() {
      return `${this.menuProps?.contentClass} vue-repo-app`;
    },
  },

  methods: {
    // Existing methods...

    generateYearRange(start, end) {
      for (let year = start; year <= end; year++) {
        this.yearRange.push(year.toString());
      }
    },

    selectYear(year) {
      this.selectedYear = year; // Update the selectedYear when a year is clicked
      this.menu = false; // Close the menu after selection
      this.$emit("input", year); // Emit the selected year
    },

    clearYear() {
      this.selectedYear = null; // Clear the selected year
      this.menu = false; // Close the menu after clearing
      this.$emit("input", null); // Emit the cleared year
    },
  },
};
</script>
