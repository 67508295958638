import SoloEntityModel from '~/modules/dashboard/models/SoloEntity';

const state = {
    item: null,
    items: [],
    agreementCallDetails: null,
    soloEntityModel: {},
    type: null,
};

const getters = {
    item: (state) => state.item,
    items: (state) => state.items,
    agreementCallDetails: (state) => state.agreementCallDetails,
    soloEntityModel: (state) => state.soloEntityModel,
    type: (state) => state.type,
}

const mutations = {
    soloEntityItem(state, payload) {
        state.item = payload;
        if (payload && payload.hasOwnProperty('agreement_call_details')) {
            state.agreementCallDetails = payload.agreement_call_details;
        }
    },
    soloEntityItems(state, payload) {
        state.items = payload;
    },
    docs(state, payload) {
        state.item.docs = payload;
    },
    agreementCallDetails(state, payload) {
        if(state.item){
            state.item.agreement_call_details = payload;
        }
        state.agreementCallDetails = payload;
    },
    delete(state) {
        state.item = null;
        state.agreementCallDetails = null;
    },
    startEditing(state, prefilledValues){
        const item = {...state.item};
        if(item){
            if(prefilledValues){
                const keys = Object.keys(prefilledValues);
                keys.forEach(key => {
                    if(!item[key]){
                        item[key] = prefilledValues[key];
                    }
                });
            }
            state.soloEntityModel = new SoloEntityModel(item);
        }else{
            state.soloEntityModel = new SoloEntityModel(prefilledValues);
        }
        state.soloEntityModel.removeBooleans();
    },
    createSoloEntityModel(state, payload){
        state.soloEntityModel = new SoloEntityModel(payload);
    },
    SET_TYPE(state,payload){
        state.type = payload;
    }
}

const actions = {
    getItem({ commit, dispatch }) {
        return axios
            .get('/legal-entity-status/item')
            .then(response => {
                commit('soloEntityItem', response.data.item);
                return response.data;
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.message}, { root: true });
                throw error;
            });
    },
    getItems({ commit }) {
        return axios
            .get('/legal-entity-status/items')
            .then(response => {
                commit('soloEntityItems', response.data.items);
                return response.data;
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.message}, { root: true });
                throw error;
            });
    },
    async save({ commit, state }, withoutStatus = false) {
        if(withoutStatus){
            state.soloEntityModel.removeStatus();
        }
        await state.soloEntityModel.create('/legal-entity-status/store')
            .then(response => {
                commit('soloEntityItem', response.data.item);
                return response.data;
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.response.data.message}, { root: true });
                throw error;
            });
    },
    async saveAgreementCall({commit, state}, uri) {
        const data = {
            uri:uri,
            task:Boolean(state.type),
        }
        const params = new URLSearchParams(data).toString();
        await axios.get('calendly/solo-entity-agreement-call?' + params)
            .then(response => {
                commit('agreementCallDetails', response.data.item);
            });
    },
    delete({ commit }) {
        return axios.delete('legal-entity-status/solo-entity')
            .then(response => {
                commit('delete');
                return response.data;
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.message}, { root: true });
                throw error;
            });
    },
    async updateCompanyNumber({commit,state}) {
        return await axios.post('/legal-entity-status/update-company-number', {
            company_number: state.soloEntityModel.values.company_number,
        })
    },
    setType({commit}, type) {
        commit('SET_TYPE', type);
    },
    onceGetItem: _.once(function ({ dispatch }) {
        return dispatch('getItem');
    }),
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
