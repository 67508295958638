<template>
	<v-app class="new-wallet-app">
		<v-main class="tm-bg-body">
			<div class="container-fluid new-wallet-layout">
				
				<!--Content Box-->
				<div class="vue-repo-app w-100 mt-4">
					<div class="row main-page-row">
						<router-view v-bind="pageData"></router-view>
					</div>
				</div>
			</div>
			<v-snackbar :color="alert.type" v-model="alert.value" left shaped bottom>
				<div class="d-flex justify-space-between align-center">
					<span>{{ alert.message }}</span>
					<v-btn icon small @click="$store.commit('closeAlert')">
						<v-icon small>mdi-close</v-icon>
					</v-btn>
				</div>
			</v-snackbar>
		</v-main>
	</v-app>
</template>
<script>

export default {
	name: "EmptyApp",
	props: {
		pageData: {
			default: () => {}
		}
	},
	computed: {
		alert() {
			return this.$store.state.alert;
		},
	}
}
</script>
