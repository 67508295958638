import Middleware from "~/routes/middleware/Middleware";
import store from "~/store";

export default class taxAllowanceAccess extends Middleware{
    constructor(...args) {
        super(...args);
    }

    async resolve() {
        return await axios.get(store.state.path + 'tax-allowances/check-access-by-org')
            .then(response => {
                return response.data.access?
                    this.responseNext():
                    this.responseRedirect()
            })
            .catch(() => this.responseRedirect());
    }
}
