export default {
    data(){
        return {
            __defaultFontSize: 16,
            __currentFontSize: 16
        }
    },
    watch: {
        __width(){
            this.__getCurrentFontSize()
        }
    },
    computed: {
        __smallDesktop(){
            return this.$store && this.$store.state.window && this.$store.state.window.width <= 768
        },
        __mediumDesktop(){
            return this.$store && this.$store.state.window && this.$store.state.window.width < 1264 && this.$store.state.window.width > 768
        },
        __largeDesktop(){
            return this.$store && this.$store.state.window && this.$store.state.window.width < 1904 && this.$store.state.window.width >= 1264
        },
        __xLargeDesktop(){
            return this.$store && this.$store.state.window && this.$store.state.window.width > 1904
        },
        __width(){
            return this.$store && this.$store.state.window? this.$store.state.window.width: 0
        },
        __height(){
            return this.$store && this.$store.state.window? this.$store.state.window.height: 0
        },
        __isDesktop(){
            return this.$store && this.$store.state.window? this.$store.state.window.width > 992: false
        },
        __isLargeMobile() {
            return this.$store && this.$store.state.window? this.$store.state.window.width <= 425: false
        },
    },
    methods: {
        __getCurrentFontSize(){
            return parseFloat(window.getComputedStyle(document.getElementById("app"), null).getPropertyValue('font-size'));
        },
        __pixelsToRem(pixels){
            return pixels/this.$data.__defaultFontSize;
        },
        __getCurrentPixels(pixels){
            return pixels * (this.__getCurrentFontSize() / this.$data.__defaultFontSize)
        },
        __maximizeFs(){
            const el = document.querySelector('html');
            this.__smallDesktop?
                el.style.cssText += 'font-size:16px!important':
                el.style.fontSize = null;
        }
    }
}
