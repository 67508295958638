<template>
    <div class="col-12 onboarding-step-container terms-step step-" :class="__smallDesktop? 'mb-8': ''">
        <div class="d-flex flex-column align-items-center mt-57">
            <div>
                <h2 class="heading-1 bold-text color-black text-center mb-2">
                    Terms of service
                </h2>
                <p class="paragraph color-dark-grey text-center mw-550">
                    Please accept the terms of service to create an organisation.
                </p>
            </div>

            <div v-if="type === 'general'" class="payment-payoneer-container self-serve mw-550 mt-3 custom-employer-terms">
                <div class="payment-receipt-wrapper p-32 border-0 shadow rounded-8 mw-550">
                    <div class="payment-receipt-body self-serve p-0">
                        <div v-if="terms" class="mb-8 overflow-y-auto" style="max-height: 25rem">
                            <terms-box :terms="terms"></terms-box>
                        </div>

                        <!-- Accept Terms & Conditions  -->
                        <div class="flex-form-group mb-0">
                            <tm-checkbox
                                v-model="obj.values.terms.accept"
                                :item-value="1"
                                :nullable="true"
                                :bottom-offset="false"
                                name="accept"
                                v-validate="obj.rules.terms.accept"
                                :error-messages="error"
                                data-vv-as="Accept Terms & Conditions"
                                style="width: 14rem"
                            >
                                <template v-slot:label>
                                    <p class="normal-text tm-initial">
                                        Accept
                                        <a :href="$store.state.brochureUrl + 'terms'"
                                           target="_blank"
                                           class="color-purple bold-text text-decoration-underline"
                                        >
                                            terms of service
                                        </a>
                                    </p>
                                </template>
                            </tm-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TmInputGroup from "~/shared/components/form/TmInputGroup";
import TmCheckbox from "~/shared/components/form/TmCheckbox";
import pdfvuer from 'pdfvuer'
import Screen from "~/shared/mixins/Screen";
import TermsBox from "../../../../TermsBox";
export default {
    name: 'EmployerTerms',
    components: {TermsBox, TmCheckbox, TmInputGroup, pdfvuer},
    mixins: [Screen],
    props: {
        obj: {
            required: true,
            type: Object
        }
    },
    data(){
        return {
            termsFile: '/T&C/230103_Terms of Service_web_NEW.pdf',
            termsPage: 1,
            terms: this.$store.state.terms ?? null,
        }
    },
    computed: {
        type(){
            return this.obj.values.terms.type;
        },
        error(){
            return this.errors.collect('accept') && this.errors.collect('accept').length?
                ['Please accept Terms of Service']: [];
        }
    }
}
</script>
