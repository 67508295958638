<template>
    <div class="actions-container features-container">
        <div class="legal-entity-not-set">
            <img
                src="/images/navigation/legal-entity-not-set.svg"
                alt="Legal entity not set image"
                class="legal-entity-not-set-image mb-1"
            />

            <p class="text-lg font-bold mt-2">Set up legal entity</p>

            <p class="text-sm color-grey-1 mt-2">
                To access all Native Company features, please set up your legal entity.
            </p>

            <BaseButton type="primary" class="mt-3" @click="handleStartButtonClick">Start now</BaseButton>
        </div>
    </div>
</template>

<script>
export default {
    name: "LegalEntityNotSet",
    methods:{
        handleStartButtonClick(){
            return window.location.href = '/legal-entity-status'
        }
    }
};
</script>

<style lang="scss" scoped>
.features-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.v-list {
    padding: 0 !important;
    background: unset;
}

.btn-upgrade {
    margin-top: 2rem;
}

.legal-entity-not-set {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 265px;
    margin: 0 auto;

    p {
        line-height: 125% !important;
    }

    button {
        width: 237px !important;
    }
    .color-grey-1 {
        color: #5b5c5e !important;
    }
    .legal-entity-not-set-image {
        max-width: 148px;
    }
}

@media (max-width: 768px) {
    .legal-entity-not-set {
        width: 100%;

        p {
            max-width: 199px;
        }

        button {
            max-width: 199px;
        }
    }
}
</style>
