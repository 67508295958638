<template>
    <modal
        v-if="successDialogData"
        :persistent="false"
        v-model="dialog"
        :close-icon-show="false"
        :padding="false"
        width="25rem"
        class-names="modal-payment-request-sent pr-success-button vue-repo-app"
    >
        <template slot="content">
            <div class="modal-content modal-info">
                <div class="modal-body">
                    <i class="icon-check-circle fs-96 color-green mb-2-5-rem"></i>
                    <h2
                        class="heading-2 bold-text color-dark-blue text-center mb-20"
                    >
                        {{successDialogData.title}}
                    </h2>
                    <p class="paragraph text-center color-dark-blue">
                        {{successDialogData.description}}
                    </p>
                </div>
                <div class="modal-footer mt-0 justify-center justify-center">
                    <button
                        class="btn btn-success-modal normal-text-2 color-white confirm btn-big w-100"
                        @click="dialog = false"
                    >
                        Close
                    </button>
                </div>
            </div>
        </template>
    </modal>
</template>
<script>
import Modal from "~/shared/components/Modal";

export default {
    name: 'SuccessModal',
    components: {Modal},
    props: {
        value: {
            required: true,
            type: Boolean
        },
        successDialogData: {
            required: true,
        }
    },
    data() {
        return {
            dialog: this.value
        }
    },
    watch: {
        value() {
            this.dialog = this.value
        },
        dialog (val) {
            this.$emit('input', val)
            if (!val){
                this.$emit('closeAndGet')
            }
        },
    }
}
</script>
