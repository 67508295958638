<template>
    <div class="col-12 onboarding-step-container add-first-seat-step step-2">
        <div class="d-flex flex-column align-items-center mt-57">
            <div>
                <h2 class="heading-1 bold-text color-black text-center mb-2">
                    Invite your first team member
                </h2>
                <p class="paragraph color-dark-grey text-center">
                    Please note that the details of your first team member are mandatory
                    in order to create an organisation
                </p>
            </div>
            <div class="payment-payoneer-container mw-550">
                <div class="payment-receipt-wrapper border-0 shadow rounded-8 mb-120">
                    <div class="payment-receipt-body self-serve">
                        <form>
                            <div class="form-group mb-20">
                                <div class="application-status-tag fulfilled p-12">
                                    <i class="icon-info-circle color-feedback-info mr-9"></i>
                                    <p class="medium-text color-pale-black-color">
                                        You can add more team members later
                                    </p>
                                </div>
                            </div>
                            <div class="form-group mb-20">
                                <tm-input
                                    label="First name"
                                    v-model="obj.values.seat.first_name"
                                    v-validate="{ required: true, min: 3, regex: /^[A-Za-z\s\-']*$/ }"
                                    name="first_name"
                                    data-vv-as="First name"
                                    :error-messages="errors.collect('first_name')"
                                ></tm-input>
                            </div>
                            <div class="form-group mb-20">
                                <tm-input
                                    label="Last name"
                                    v-model="obj.values.seat.last_name"
                                    v-validate="{ required: true, min: 3, regex: /^[A-Za-z\s\-']*$/ }"
                                    name="last_name"
                                    data-vv-as="Last name"
                                    :error-messages="errors.collect('last_name')"
                                ></tm-input>
                            </div>
                            <div class="form-group mb-20">
                                <tm-input
                                    label="Email"
                                    v-model="obj.values.seat.email"
                                    v-validate="obj.rules.seat.email + '|is_not:' + $store.state?.primaryWorker?.trial_employment_account?.admin_email
                                    + '|is_not:' + $store.state.info.email"
                                    name="email"
                                    data-vv-as="Email"
                                    :error-messages="errors.collect('email')"
                                    @blur="checkEmail"
                                    @input="handleInput('email')"
                                    @keyup="emailExistsMsg = false"
                                ></tm-input>
                                <p v-if="emailExistsMsg" class="fs-13 color-dark-pink custom-email-error-msg">
                                    <span>{{ emailExistsMsgText }}</span>
                                </p>
                            </div>
                            <div class="form-group" :class="countryError? 'mb-0': 'mb-20'">

                                <!-- Eor support countries select -->
                                <tm-advanced-select
                                    v-if="isEorable"
                                    label="Member's country"
                                    item-text="name"
                                    item-value="id"
                                    name="country"
                                    v-model="obj.values.seat.country_id"
                                    :items="$store.state.supportedCountries"
                                    @inputObject="country = $event"
                                    v-validate="obj.rules.seat.country_id"
                                    :error-messages="errors.collect('country')"
                                    data-vv-as="Members Country"
                                >
                                    <template v-slot:selection="{item}">
                                        <div class="d-flex align-center">
                                            <avatar size="1.3rem" :object="item"></avatar>
                                            <span class="tm-medium-text tm-initial text-black">
                                                {{ item.name }}
                                            </span>
                                        </div>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <div class="d-flex align-center">
                                            <avatar size="1.3rem" :object="item"></avatar>
                                            <span class="tm-medium-text tm-initial text-black">
                                                {{ item.name }}
                                            </span>
                                        </div>
                                    </template>
                                </tm-advanced-select>

                                <!-- All countries select -->
                                <tm-advanced-select
                                    v-else
                                    label="Member's country"
                                    item-text="name"
                                    item-value="id"
                                    name="country"
                                    v-model="obj.values.seat.country_id"
                                    @inputObject="country = $event"
                                    v-validate="obj.rules.seat.country_id"
                                    :error-messages="errors.collect('country')"
                                    data-vv-as="Members Country"

                                    search-key="name"
                                    :search="true"
                                    :search-autoload="true"
                                    :search-route="$store.state.getPath+'search-countries'"
                                    :search-additional-params="{id: obj.values.seat.country_id}"
                                >
                                    <template v-slot:selection="{item}">
                                        <div class="d-flex align-center">
                                            <avatar size="1.3rem" :object="item"></avatar>
                                            <span class="tm-medium-text tm-initial text-black">
                                                {{ item.name }}
                                            </span>
                                        </div>
                                    </template>
                                    <template v-slot:item="{item}">
                                        <div class="d-flex align-center">
                                            <avatar size="1.3rem" :object="item"></avatar>
                                            <span class="tm-medium-text tm-initial text-black">
                                                {{ item.name }}
                                            </span>
                                        </div>
                                    </template>
                                </tm-advanced-select>

                                <div v-if="countryError" class="no-support-romania">
                                    <p class="small-text color-black bold-text">
                                        <i class="icon-exclamation-solid color-red mr-1"></i>
                                        We don't support EOR in {{ country.name }}, but you still can invite new members.
                                    </p>
                                </div>
                            </div>
                            <div v-if="workerPlanValue !== 'client'" class="form-group mb-0">
                                <tm-input
                                    label="Gross salary (monthly)"
                                    type="number"
                                    v-model="obj.values.seat.salary"
                                    name="salary"
                                    v-validate="isEorable? salaryRules: ''"
                                    data-vv-as="Gross Salary"
                                    :error-messages="errors.collect('salary')"
                                >
                                </tm-input>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import TmInput from "~/shared/components/form/TmInput";
import TmAdvancedSelect from "~/shared/components/form/TmAdvancedSelect";
import Avatar from "~/shared/components/Avatar";
export default {
    name: 'EmployerFirstSeat',
    components: {Avatar, TmAdvancedSelect, TmInput},
    props: {
        obj: {
            required: true,
            type: Object
        }
    },
    data(){
        return {
            eorItems: [
                {name: 'Yes', value: 1},
                {name: 'No', value: 0},
            ],
            allPossibleCurrencies: this.$store.state.allPossibleCurrencies,
            country: null,
            supportedCountriesCodes: this.$store.state.supportedCountries,
            supportedPayrollCountriesCodes: this.$store.state.payrollCountries,
            emailExistsMsg: false,
            emailExistsMsgText: '',
        }
    },
    methods: {
        handleInput(value) {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(this.checkEmail, 300);
        },
        async checkEmail() {
            if (!this.obj.values.seat.email) {
                return;
            }
            if(this.$store.state?.primaryWorker?.trial_employment_account?.admin_email === this.obj.values.seat.email) {
                this.emailExistsMsg = true;
                this.emailExistsMsgText = 'The email is already used for the admin account.';
            }
            this.$emit('existingEmail', this.emailExistsMsg);
        },
    },
    computed: {
        console: () => console,
        isEorable(){
            return this.$store.state.worker.plan.value === 'employer';
        },
        isEorCountry(){
            return !!(this.$store.state.supportedCountries.find(c => c.id === this.obj.values.seat.country_id));
        },
        countryError(){
            return this.isEorable && !this.isEorCountry && this.country;
        },
        salaryRules(){
            return this.country? `min_value:${ parseInt(this.country.minimum_salary)}`: '';
        },
        supportedPayrollCountry() {
            if (this.supportedPayrollCountriesCodes) {
               return this.supportedPayrollCountriesCodes.find(i => i.id === this.obj.values.seat.country_id);
            } else return false;
        },
        checkNetToGross() {
            if (this.supportedCountriesCodes) {
                const findMKId = this.supportedCountriesCodes.find(i => i.code === 'MK');
                const findRSId = this.supportedCountriesCodes.find(i => i.code === 'RS');

                return this.obj.values.seat.country_id === findMKId.id || this.obj.values.seat.country_id === findRSId.id;
            }

        },
        workerPlanValue() {
            return this.$store.state.worker.plan.value;
        }
    }
}
</script>
