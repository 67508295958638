<template>
     <div
         v-if="!_.isEmpty(items)"
            class="col-12 review-csv-list-step-container">
            <h1 class="heading-1 bold-text color-black text-center mb-2">
                Review requests
            </h1>
            <p class="medium-text color-dark-grey text-center"
               :class="'mb-60'"
            >
                You are sending payment request to {{items.length}} {{items.length > 1? 'users' : 'user'}}
            </p>


            <div class="review-csv-list-table-card">
                <div class="review-csv-list-table-content flexible-height">
                    <table class="flex-table no-paddings">
                        <thead>
                        <tr>
                            <th>
                                <p class="medium-text bold-text color-dark-grey text-start">Name</p>
                            </th>
                            <th>
                                <p class="medium-text bold-text color-dark-grey text-start">
                                    Amount
                                </p>
                            </th>
                            <th>
                                <p class="medium-text bold-text color-dark-grey text-start">Frequency</p>
                            </th>
                            <th>
                                <p class="medium-text bold-text color-dark-grey text-start">Exact day</p>
                            </th>
                            <th>
                                <p class="medium-text bold-text color-dark-grey text-start">Exact date</p>
                            </th>
                            <th>
                                <p class="medium-text bold-text color-dark-grey text-start">End date</p>
                            </th>
                            <th v-if="!$store.state.organization.default">
                                <p class="medium-text bold-text color-dark-grey text-start">Sender name</p>
                            </th>
                            <th>
                                <p class="medium-text bold-text color-dark-grey text-start">Notes</p>
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(item, index) in items"
                            :key="`items_${index}`"
                            >
                            <td>
                                <div class="media-box">
                                    <avatar-name
                                        size="2.5rem"
                                        :name="item.payer_name"
                                        :show-name="false"
                                        class="mr-2"
                                    >
                                    </avatar-name>
                                    <div class="media-box-content">
                                        <p class="medium-text color-black">{{item.payer_name || '--'}}</p>
                                        <p class="medium-text color-dark-grey">
                                            {{item.payer_email || '--'}}
                                        </p>
                                    </div>
                                </div>
                            </td>

                            <td>
                                <p class="medium-text color-dark-grey  d-flex align-center">
                                    {{ formatAmount(item.net_amount, item.currencyCode) }}
                                </p>
                            </td>

                            <td>
                                <p class="medium-text bold-text color-dark-grey">
                                    {{item.frequencyTxt}}
                                </p>
                            </td>

                            <td>
                                <p class="medium-text bold-text color-dark-grey">
                                    {{ item.frequency === 'weekly' ? item.exact_day_txt : '-'}}
                                </p>
                            </td>
                            <td>
                                <p class="medium-text bold-text color-dark-grey">
                                    {{ item.frequency === 'monthly' ? item.exact_date : '-'}}
                                </p>
                            </td>
                            <td>
                                <p class="medium-text bold-text color-dark-grey">
                                    {{ item.frequency !== 'one-time' ? item.end_date : '-'}}
                                </p>
                            </td>

                            <td v-if="!$store.state.organization.default">
                                <p class="medium-text bold-text color-dark-grey">
                                    {{ showSenderOrOrgName(item) }}
                                </p>
                            </td>

                            <td>
                                <p class="medium-text bold-text color-dark-grey">
                                    {{item.description }}
                                </p>
                            </td>

                            <td class="pr-1">
                                <button
                                   @click="showEditDialog(index)"
                                    class="btn btn-icon btn-p-0 color-dark-grey "
                                >
                                    <i class="icon-edit-curved"></i>
                                </button>
                            </td>
                            <td>
                                <button v-if="items.length > 1" @click="removeItem(index)" class="btn btn-icon btn-p-0 color-dark-grey">
                                    <i class="icon-times"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

         <modal-add-request-payment
             v-if="paymentRequestDialog"
             v-model="paymentRequestDialog"
             @csvUpdate="changedItem"
             :salary-obj-prop="salaryObj"
             :csv-upload="true"
             :show-button="false"
             :requestTypes="requestTypes"
             :weeklyDays="weeklyDays"
             :fromNames="fromNames"
             :frequencies="frequencies"
         >
         </modal-add-request-payment>
        </div>
</template>
<script>

import AvatarName from "../AvatarName";
import Avatar from "../Avatar";
import Helpers from '~/shared/mixins/Helpers';
import Btn from "~/shared/components/Btn";
import {mapGetters} from "vuex";

import Modal from "../Modal";
import SalaryRequestModal from "~/modules/dashboard/components/modals/SalaryRequestModal";
import ModalAddRequestPayment from "~/modules/dashboard/views/PaymentRequest/Modals/ModalAddRequestPayment";
export default {
    name: 'ReviewCsv',
    components: {
        ModalAddRequestPayment,
        SalaryRequestModal,
        Modal,
        Avatar,
        AvatarName,
        Btn
    },
    mixins: [Helpers],
    props: {
        allData: {
            required: true
        }
    },
    data(){
        return {
            salaryObj: {},

            requestTypes: [],
            weeklyDays: [],
            frequencies: [],
            fromNames: [],

            items: [],
            paymentRequestDialog: false,
            editItemIndex: -1,
            types: [],
        }
    },
    watch: {

    },
    computed: {
        editItem () {
            if(this.editItemIndex < 0) {
                return null;
            }
            return  this.items[this.editItemIndex] || null;
        }
    },
    methods: {
        showSenderOrOrgName(item){
            return item.show_sender_name == 1 ? this.$store.state.organization.name : this.$store.state.worker.name;
        },
        setSalaryObj(e){
            for(var k in e)  this.salaryObj[k]=e[k];

            this.salaryObj.show_sender_name = e.show_sender_name ? '1' : '0'
            this.salaryObj.frequencyTxt = this.frequencies.find(i => i.id === e.frequency).name
            this.salaryObj.exact_day_txt = this.weeklyDays.find(i => i.id === e.exact_day).name
            this.salaryObj.currencyCode = this.$store.state.allPossibleCurrencies.find(i => i.id === e.currency_id).code
        },
        changedItem(e) {
          this.setSalaryObj(e)

          this.items.splice(this.editItemIndex, 1, this.salaryObj);
          this.editItemIndex = -1;
          this.paymentRequestDialog = false;
        },
        removeItem(index) {
            if(this.items.length < 1) {
                return false;
            }
            this.items.splice(index, 1);
        },
        showEditDialog(ind) {
            this.paymentRequestDialog = true;
            const item = this.items[ind]|| null;

            if(!item) {
                this.salaryObj = {};
                return;
            }

            this.editItemIndex = ind;

            item.show_sender_name = item.show_sender_name == '1' ? 1 : 0

            this.salaryObj = {...item};
        },
    },
    mounted() {
        this.items = this.allData.items || [];
        this.requestTypes = this.allData.requestTypes || [];
        this.frequencies = this.allData.frequencies || [];
        this.weeklyDays = this.allData.weeklyDays || [];
        this.fromNames = this.allData.fromNames || [];
    }
}
</script>
