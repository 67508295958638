<template>
    <div>
        <modal
            v-model="dialog"
            :close-icon-show="false"
            max-width="26.75rem"
            :padding="false"
            content-class="iban-notify tm-bg-white rounded   modal-dialog center modal-sm modal-updated-iban"
        >
            <template slot="content">
                <div class="modal-content">
                    <div class="modal-body">
                        <i class="icon-info-circle color-feedback-info"></i>
                        <h1 class="heading-1 bold-text color-black text-center mb-3">
                            Delays due to local holidays
                        </h1>
                        <p class="normal-text color-dark-grey text-center mb-3">
                            Kindly be aware that due to local holidays, there may be a slight delay in processing your
                            transfers and in the responses from our support teams.

                        </p>
                        <p class="normal-text color-dark-grey text-center mb-40">
                            We apologise for any inconvenience
                            this may cause.
                        </p>
                        <button
                            type="button"
                            @click="dialog=false"
                            class="btn btn-big btn-purple normal-text bold-text color-white w-100 mb-3"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </template>
        </modal>
    </div>
</template>
<script>
import Modal from "~/shared/components/Modal";
import Helpers from '~/shared/mixins/Helpers';

export default {
    name: 'HolidayNotify',
    components: {Modal},
    props: {
        value: {
            required: true,
            type: Boolean
        },
    },
    mixins: [Helpers],
    data() {
        return {
            dialog: this.value,
        }
    },
    watch: {
        value() {
            this.dialog = this.value
        },
        dialog() {
            this.$emit('input', this.dialog);
        },
    },
    computed: {},
    methods: {}
}
</script>
