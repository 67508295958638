<template>
  <div class="uploaded-files pb-1">
    <ul class="uploaded-files-list" v-if="!$scopedSlots['item']">
      <li class="uploaded-file" v-for="(file, index) in files" :key="index">
        <WlBaseIcon
          name="file-outline"
          class="text-base color-black font-medium mr-2"
          v-if="iconFile"
        />
        <p class="uploaded-file-name text-ellipsis text-base color-black">
          {{ file.name }}
        </p>
        <WlBaseIcon
          v-if="checkMark"
          name="check-circle-outline"
          class="color-feedback-success ml-auto mr-2"
        />
        <WlBaseButton
          v-if="iconEye"
          class="btn-icon-sm text-xs color-grey-2 mr-2"
          :class="{ 'ml-auto': !checkMark }"
          @click="previewFile(file)"
        >
          <WlBaseIcon name="eye" class="text-base" />
        </WlBaseButton>
        <WlBaseButton
          v-if="iconDownload"
          class="btn-icon-sm text-xs color-grey-2"
          :class="{ 'ml-auto': !checkMark && !iconEye }"
        >
          <WlBaseIcon name="download" />
        </WlBaseButton>
        <WlBaseButton
          v-if="iconTimes"
          class="btn-icon-sm text-lg btn-remove-file color-black"
          :class="checkMark || iconDownload || iconEye ? '' : 'ml-auto'"
          @click="$emit('delete', index)"
        >
          <WlBaseIcon name="times" />
        </WlBaseButton>
      </li>
    </ul>
    <template v-else>
      <slot v-for="file in files" name="item" :item="file"></slot>
    </template>
    <slot></slot>

    <ModalPreviewFile
      :file="selectedFile"
      :dialog="isModalPreviewFileVisible"
      @close-modal="isModalPreviewFileVisible = false"
    />
  </div>
</template>

<script>
import ModalPreviewFile from "~/modules/wallet/components/Modals/ModalPreviewFile.vue";

export default {
  name: "WlBaseUploadedFileShower",

  components: {
    ModalPreviewFile,
  },

  props: {
    files: {
      type: Array,
      default: () => [],
    },
    checkMark: {
      type: Boolean,
      default: false,
    },
    iconFile: {
      type: Boolean,
      default: true,
    },
    iconDownload: {
      type: Boolean,
      default: false,
    },
    iconTimes: {
      type: Boolean,
      default: true,
    },
    iconEye: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedFile: {},

      isModalPreviewFileVisible: false,
    };
  },

  methods: {
    previewFile(file) {
      this.selectedFile = file;
      this.isModalPreviewFileVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.uploaded-files-list .uploaded-file {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  padding: 12px 8px;
  height: 40px;

  .btn-remove-file.v-btn:not(.v-btn--round).v-size--default.btn-icon {
    width: 24px;
    max-width: 24px;
    min-width: 24px;
    height: 24px;
  }
}

.uploaded-files-list .uploaded-file > i {
  font-size: 1rem;
}
</style>
