<template>
  <div class="table-pagination" v-if="calcPageCount > 1">
    <slot></slot>
    <span class="pages-text">
      {{ showedItems.from }} - {{ showedItems.to }} of
      {{ length }}
    </span>
    <v-pagination
      v-model="pageValue"
      :length="calcPageCount"
      :total-visible="totalVisible"
      :disabled="disabled"
      next-icon="icon-chevron-right-2"
      prev-icon="icon-chevron-left-2"
    />
  </div>
</template>

<script>
export default {
  name: "WlBaseTablePaginator",

  props: {
    value: {
      type: [Number, String],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pageCount: {
      type: [Number, String],
    },
    length: {
      type: [Number, String],
    },
    itemsPerPage: {
      type: [Number, String],
    },
    totalVisible: {
      type: [Number, String],
      default: 7,
    },
  },

  data() {
    return {
      pageValue: this.value,
    };
  },

  computed: {
    showedItems() {
      let from =
        this.pageValue - 1 !== 0
          ? this.itemsPerPage * (this.pageValue - 1) + 1
          : 1;
      let to =
        this.pageValue !== this.calcPageCount
          ? this.itemsPerPage * this.pageValue
          : this.length;
      return {
        from: from,
        to: to,
      };
    },

    calcPageCount() {
      return this.pageCount || Math.ceil(this.length / this.itemsPerPage);
    },
  },

  watch: {
    value(val) {
      this.pageValue = val;
    },
    pageValue(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style lang="scss">
.table-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 24px;
  width: 100%;
  border-top: 1px solid #c1c4c8;
  margin-top: 32px;

  .v-pagination {
    gap: 8px;

    .v-pagination__more {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 16px */
      color: #7c8dff;
    }
  }
}

.table-pagination .pages-text {
  margin-right: 14px;
  color: #7c8dff;
}

.table-pagination .v-pagination li .v-pagination__navigation i {
  font-size: 1rem;
}

.table-pagination .v-pagination li .v-pagination__navigation,
.v-pagination li .v-pagination__item {
  background-color: transparent !important;
  box-shadow: unset;
  color: #7c8dff !important;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
  border: 1px solid #7c8dff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
}
.table-pagination .v-pagination li .v-pagination__navigation:hover {
    background: #d7e0ff !important;
    color: var(--main-purple-color) !important;
}

.table-pagination .v-pagination li {
  .v-pagination__item:hover,
  .v-pagination__item.v-pagination__item--active {
    background: #d7e0ff !important;
    color: var(--main-purple-color) !important;
  }
}

.page-select-container,
.table-pagination-actions {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .table-pagination {
    justify-content: center;
    flex-direction: column;

    .pages-text {
      margin-bottom: 16px;
    }
  }
}
</style>
