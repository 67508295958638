<template>
    <div :class="{ unread: !notification.isRead }" class="notification-item">
        <div :class="notification.status.type" class="icon-wrapper">
            <BaseIcon :name="icon"/>
        </div>
        <div class="d-flex flex-column flex-grow-1">
            <p
                v-html="__titleDateFormat(notification.message)"
                class="text-base leading-130 color-grey-1 notification-message mb-2"
                :class="notification.value === 'verification_required'? 'font-bold font-weight-bold': ''"
            >
            </p>
            <p v-if="notification.created_at" class="text-xs color-dark-grey">
                {{ __dateFormat(notification.created_at, "dd/MM/Y") }}
            </p>
        </div>
        <div class="unread-dot"></div>
    </div>
</template>

<script>
import Helpers from "~/shared/mixins/Helpers";

export default {
    name: "NotificationItem",

    mixins: [Helpers],

    props: {
        notification: {
            required: true,
        },
    },

    computed: {
        icon() {
            switch (this.notification.status.type) {
                case "approved":
                    return "check-mark";
                case "info":
                    return "info-circle-thin";
                case "rejected":
                    return "minus-2";
                default:
                    return "";
            }
        },
    },
    methods: {
        __titleDateFormat(title) {
            return this.__sanitizeHtml(title)
                .replace(
                    /on ((\d{2}\/\d{2}\/\d{4})|(\d{4}-\d{2}-\d{2})) (\d{2}:\d{2}:\d{2})\.?$/gi,
                    '<span style="white-space: nowrap">$&</span>',
                );
        },
    },
};
</script>

<style scoped lang="scss">
.notification-item {
    display: flex;
    align-items: flex-start;
    padding: 0.75rem;
    border-radius: 18px;
    transition: color, background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    cursor: pointer;

    &.unread {
        .unread-dot {
            visibility: visible;
        }
    }

    &:not(:last-child) {
        margin-bottom: 2rem;
    }

    .icon-wrapper {
        width: 3rem;
        min-width: 3rem;
        height: 3rem;
        border-radius: 18px;
        border: 1px solid transparent;
        background-color: unset !important;
        margin-right: 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;

        &.approved {
            border-color: var(--feedback-success-color) !important;
            color: var(--feedback-success-color);
        }

        &.rejected {
            border-color: var(--feedback-error-color) !important;
            color: var(--feedback-error-color);
        }

        &.info {
            border-color: var(--feedback-info-color) !important;
            color: var(--feedback-info-color);
        }
    }

    &:hover {
        background-color: var(--brand-dark-purple-color);

        .notification-message,
        i {
            color: white !important;
        }

        .icon-wrapper {
            border-color: white !important;
        }
    }

    .unread-dot {
        width: 8px;
        min-width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--feedback-error-color);
        margin-left: 1.4375rem;
        visibility: hidden;
    }
}
</style>

