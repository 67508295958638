<template>
    <div class="overlay">
        <div class="loading-container">
            <div class="logo-container">
                <svg
                    width="80"
                    height="80"
                    viewBox="0 0 108 108"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M59.332 46.3708C59.332 45.3132 59.0168 44.2664 58.4299 43.3815C57.8431 42.4965 57.0171 41.8058 56.039 41.3957C55.0717 40.9964 53.9958 40.8993 52.9525 41.1044C51.92 41.3202 50.9636 41.8382 50.2246 42.5936C49.6595 43.1548 49.2356 43.8347 48.9639 44.5794C48.6922 45.324 48.5944 46.1226 48.6705 46.9212C48.7465 47.709 48.9965 48.4752 49.4095 49.1551C49.8225 49.835 50.3659 50.4178 51.0288 50.8603C51.757 51.3459 52.5938 51.6481 53.4633 51.7344C54.3327 51.8207 55.213 51.6912 56.0281 51.3567C56.6802 51.0761 57.2671 50.6768 57.7561 50.1588C58.7669 49.1551 59.332 47.7846 59.332 46.3708Z"
                        fill="#5152FB"
                    />
                    <path
                        d="M42.2793 54.9073L51.7888 71.4837C52.9843 73.6097 55.0057 73.6097 56.2121 71.4837L60.3854 64.1883C54.4406 65.6453 45.6484 60.7781 42.2793 54.9073Z"
                        fill="#FDBC42"
                    />
                    <path
                        d="M66.918 46.3709C64.8857 46.3709 62.4077 48.2055 61.4079 50.4502C61.4079 50.4502 59.093 55.6627 53.9959 55.6627C52.2462 55.6627 50.5508 55.026 49.2249 53.8713C50.8985 55.9109 56.6042 60.3248 65.0378 56.0836L68.3852 50.256C69.6132 48.1084 68.9394 46.3709 66.918 46.3709Z"
                        fill="#5152FB"
                    />
                    <path
                        d="M49.2242 53.8604C48.1266 52.9107 47.2245 51.7452 46.5942 50.4394C45.5943 48.1947 43.1164 46.36 41.095 46.36C39.0518 46.3708 38.3888 48.1083 39.6169 50.2451L42.2796 54.8965C45.6487 60.7673 54.4409 65.6452 60.4074 64.1775L65.0481 56.0836C56.5928 60.3248 50.8979 55.9109 49.2242 53.8604Z"
                        fill="#FA4769"
                    />
                    <defs>
                        <linearGradient id="gradientCircle" x1="0" y1="0" x2="1" y2="1">
                            <stop offset="0%" stop-color="#FDBC42" />
                            <stop offset="50%" stop-color="#FA4769" />
                            <stop offset="100%" stop-color="#5152FB" />
                        </linearGradient>
                    </defs>
                    <circle
                        cx="54"
                        cy="54"
                        r="50"
                        stroke="url(#gradientCircle)"
                        stroke-width="7"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="outer-circle"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "BaseScreenLoading",
};
</script>

<style lang="scss" scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(235, 238, 242, 0.9); // Low black opacity
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; // Ensure it is on top of everything
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-container {
    width: 80px;
    height: 80px;
    position: relative;

    .outer-circle {
        stroke-dasharray: 240, 220;
        stroke-dashoffset: 0;
        stroke-linecap: round;
        transform-origin: center;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
}
</style>
