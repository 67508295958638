import Middleware from "~/routes/middleware/Middleware";
import store from "~/store";

export default class guest extends Middleware{
    constructor(...args) {
        super(...args);
    }
    resolve() {
        return store.getters['auth/__isAuth'] ?
            this.responseRedirect(store.state.auth.afterLoginPath) :
            this.responseNext();
    }
}
