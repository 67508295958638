<template>
    <modal v-model="dialog" width="25rem" :padding="false" :close-icon-show="false">
        <template slot="content">
            <div class="tm-bg-white alert-error-modal" style="padding: 3rem">
                <div class="d-flex flex-column align-center" style="margin-bottom: 3rem">
                    <icon name="icon-exclamation-outline" size="5rem" color="red" style="margin-bottom: 2.5rem"></icon>
                    <p class="heading-1 font-weight-medium" style="margin-bottom: 1rem;">
                        {{ title }}
                    </p>
                    <p class="normal-text text-center" style="color: #838A90; padding: 0 1.75rem">
                        {{ message }}
                    </p>
                </div>
                <btn @click="$emit('action')" height="3rem" class="w-100 elevation-0">
                    <span class="tm-text-normal color-white">
                        {{ btnText }}
                    </span>
                </btn>
            </div>
        </template>
    </modal>
</template>
<script>
import Modal from "./Modal";
import Btn from "./Btn";
export default {
    name: 'ErrorAlert',
    components: {Btn, Modal},
    props: {
        value: {
            default: false
        },
        title: {
            default: null
        },
        message: {
            default: null
        },
        btnText: {
            default: null
        },
    },
    data(){
        return {
            dialog: !!this.value
        }
    },
    watch: {
        dialog(){
            this.$emit('input', this.dialog);
        },
        value(){
            this.dialog = !!this.value;
        }
    }
}
</script>
