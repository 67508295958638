<!-- @format -->

<template>
    <div class="credit-card">
        <div class="payment-option-details-credit-card">
            <div class="mb-30">
                <h5 class="text-center heading-1 bold-text"> Add money via credit card </h5>
                <p class="text-center medium-text color-dark-grey-6 lh-120"> Select your card </p>
            </div>
            <div class="form-group mb-30 credit-cards-list">
                <div
                    v-for="item in cards"
                    :key="item.id"
                    @click="onSelectCard(item)"
                    :class="obj.values.card.card_id === item.id ? 'active' : ''"
                    class="credit-cards-list-item cursor-pointer d-flex justify-content-between align-items-center border-grey bg-white rounded-5 px-20 py-3 mb-9 position-relative"
                >
                    <div class="w-100 medium-text d-flex align-center">
                        **** **** **** {{ item.last4 }}
                        <img
                            :src="$store.state.imagePath + item.brand_logo"
                            :alt="item.brand"
                            class="selected-card-brand-img"
                        />
                    </div>
                    <div class="flex-end-center align-center mw-160">
                        <button @click.stop="cardForm(item)" class="color-purple fs-13 mr-20 bold-text tm-initial"
                            >Edit</button
                        >
                        <tm-radio
                            v-model="selectedItem"
                            :item-value="item.id"
                            :bottom-offset="false"
                            name="card"
                            data-vv-as="Card"
                            v-validate="obj.rules.card.card_id"
                        ></tm-radio>
                    </div>
                </div>
                <button
                    @click="cardForm()"
                    class="btn btn-icon btn-add-new-card btn-p-0 color-purple small-text bold-text"
                >
                    <i class="icon-plus-circle-outline mr-1"></i>
                    Add new card
                </button>
            </div>

            <div class="top-up-reason-container mb-5">
                <div class="form-group mb-3">
                    <div class="dropdown mb-0 purple-arrow-drop">
                        <tm-advanced-select
                            v-model="obj.values.reason"
                            label=" Choose top up reason *"
                            :items="$store.state.transferTypes"
                            item-value="value"
                            item-text="name"
                            v-validate="obj.rules.reason"
                            name="reason"
                            data-vv-as="Reason"
                            :error-messages="errors.collect('reason')"
                        ></tm-advanced-select>
                    </div>
                </div>
                <div class="form-group mb-5" v-if="obj.values.reason === 'other'">
                    <tm-text-area
                        label="Describe the reason of top up *"
                        placeholder="Please add notes here."
                        v-model="obj.values.note"
                        v-validate="obj.rules.note"
                        name="note"
                        data-vv-as="note"
                        :bottom-offset="false"
                        :error-messages="errors.collect('note')"
                    >
                    </tm-text-area>
                </div>
            </div>
        </div>

        <!--Card Form-->
        <card-form v-model="cardDialog" :default-org="defaultOrg" :card="cardItem" :paymentType="'stripe_client'" @updateCard="onUpdateCard" />
    </div>
</template>
<script>
import TmRadio from '~/shared/components/form/TmRadio';
import CardForm from '../../modals/CardForm';
import TmAdvancedSelect from "~/shared/components/form/TmAdvancedSelect";
import TmTextArea from "~/shared/components/form/TmTextArea";
import Helpers from '~/shared/mixins/Helpers';
import { mapGetters } from 'vuex';
export default {
    name: 'AddByCard',

    components: {TmTextArea, TmAdvancedSelect, CardForm, TmRadio },

    mixins: [Helpers],

    props: {
        obj: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {
            cardDialog: false,
            cardItem: null,
            selectedItem: this.obj.values.card.card_id,
        };
    },

    watch: {
        'obj.values.card.card_id'() {
            this.selectedItem = this.obj.values.card.card_id;
        },
    },

    created() {
        this.$store.dispatch('stripe/getCards');
    },

    computed: {
        ...mapGetters({
            'cards': 'stripe/cards',
        }),
        selectedCardItem() {
            return this.selectedItem ? this.cards?.find(item => item.id === this.selectedItem) : null;
        },
        defaultOrg() {
            return this.$store.state.organization.id === this.$store.state.primaryWorker.organization_id;
        },
    },

    methods: {
        cardForm(item = null) {
            this.cardItem = item;
            this.cardDialog = true;
        },
        onSelectCard(item) {
            this.selectedItem = item.id;
            this.obj.values.card.card_id = this.selectedItem;
        },
        onUpdateCard(cards) {
            let id = this.cardItem?.id;
            if (!id) {
                const ids = cards.map(item => item.id);
                id = Math.max.apply(null, ids);
            }
            const card = this.cards.find(card => card.id === id);
            if (card) {
                this.onSelectCard(card);
            }
        }
    },
};
</script>
