<template>
    <v-progress-linear
        :value="value"
        :buffer-value="bufferValue"
        :background-color="backgroundColor"
        :color="color"
        :height="height"
    >
        <template v-slot:progress>
            <span class="text-base color-black">{{ value }}%</span>
        </template>

        <template v-slot:days></template>
    </v-progress-linear>
</template>

<script>
export default {
    name: "BaseProgressBar",

    props: {
        value: {
            type: Number,
            default: 0,
        },
        bufferValue: {
            type: Number,
            default: 0,
        },
        backgroundColor: {
            type: String,
            default: "#F5F5F5",
        },
        color: {
            type: String,
            default: "#FFC107",
        },
        height: {
            type: String,
            default: "4px",
        },
    },
};
</script>
