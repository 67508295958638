/** @format */

import { Validator } from 'vee-validate';

import Model from '~/shared/Model';

export default class Case extends Model {
    constructor(obj = {}) {
        super();
        console.log(obj,222)

        this.path = '/cases/';

        this.hidden = ['id'];

        this.commonDocFiles = [];
        this.receiptFiles = [];
        this.diplomaFiles = [];
        this.cvFiles = [];
        this.passportFiles = [];
        this.WORK_PERMIT_VISA_TYPE = 0;
        this.CASES_FOR_BBENEFITS = 4;
        this.EXPENSES_TYPE = 5;
        this.FEE_CHANGE_TYPE = 7;
        this.TRAVEL_BOOKING_TYPE = 13;
        this.ACCEPT_MIMES = 'image/jpeg,image/jpg,image/png,application/pdf';
        this.DEFAUL_FILE_RULLES = 'size:10485760|mimes:' + this.ACCEPT_MIMES;
        this.TRAVEL_NEED_FLIGHT = 'flight';
        this.TRAVEL_NEED_ACCOMODATION = 'accommodation';


        this.values = {
            id: obj.id || null,
            type: Number.isInteger(obj.type) ? obj.type : null,
            title: obj.title || '',
            description: obj.description || '',
            resident_country_id: obj.resident_country_id || null,
            destination_country_id: obj.destination_country_id || null,
            nationality_country_id: obj.nationality_country_id || null,
            contact_number: obj.contact_number || '',
            full_name: obj.full_name || '',
            optional_email: obj.optional_email || '',
            due_on: obj.due_on || '',
            owner_id: obj.owner_id || null,
            tax: obj.tax || null,

            recruit_countries: obj.recruit_countries || [],
            work_experience: obj.work_experience || null,
            company_link: obj.company_link || null,
            gross_salary: obj.gross_salary || null,
            offering_employment: obj.offering_employment || null,
            work_location: obj.work_location || null,
            office_location: obj.office_location || null,


            discount_code: obj.discount_code || null,
            benefits_id: obj.benefits_id || null,
            transaction_id: obj.transaction_id || null,
            benefits_owner_id: obj.benefits_owner_id || null,
            line_of_work: obj.line_of_work || null,
            work_country_id: obj.work_country_id || null,
            other_information: obj.other_information || null,
            day_off_start: obj.day_off_start || null,
            day_off_end: obj.day_off_end || null,
            current_salary: obj.current_salary || '',
            updated_salary: obj.updated_salary || '',
            files: [],
            owner: obj.owner || [],
            owners: obj.owners || [],
            kind_of_insurance: obj.kind_of_insurance || null,
            expenses_amount: obj.expenses_amount || '',
            fee_received_amount: obj.fee_received_amount || '',
            fee_expected_amount: obj.fee_expected_amount || '',
            assessment_fee: obj.assessment_fee || '100€',
            fees_option: obj.fees_option || '0',
            wallet_id: obj.wallet_id || null,
            card_id: obj.card_id || null,
            currency_id: obj.currency_id || null,
            receipt_files: [],
            diploma_files: [],
            cv_files: [],
            passport_files: [],
            self_as_owner: Number.isInteger(obj.self_as_owner) ? obj.self_as_owner : 1,
            removed_attachment_ids: obj.removed_attachment_ids || [],
            is_admin: obj.is_admin || 0,
            job_id: obj.job_id || obj.job?.id || null,
            other_job_position: obj.other_job_position || '',
            other_job_description: obj.other_job_description || '',
            coworking_city: obj.coworking_city || '',
            budget: obj.budget || '',
            active_eor_country: obj.active_eor_country || 'DE',
            period: obj.period || [],
            plan: obj.plan || '',
            device_type: obj.device_type || '',
            delivery_address: obj.delivery_address || '',
            benefit_type: obj.benefit_type || '',
            bonus_type: obj.bonus_type || 'gross',
            additional_info: obj.additional_info ||
                {
                travel_needs : ["Accommodation"],
                country_id : null,
                city : null,
                check_in : null,
                check_out : null,
                rooms_count : null,
                property_type : null,
                breakfast : null,
                trip_type: null,
                guests : [],
                main_guest : {
                    first_name: null,
                    last_name: null,
                    email: null,
                    address: null,
                    city: null,
                    country_id: null,
                    phone: null,
                },
                passengers : [],
                from_airport_id : null,
                to_airport_id : null,
                departure_date : null,
                return_date : null,
                flight_adults_count:null,
                flight_children_count:null,
                flight_infants_count:null,
                adults_count:null,
                children_count:null,
                infants_count:null,

                accommodation_maximum_budget: null,
                accommodation_budget_currency_id: null,
                flight_maximum_budget: null,
                flight_budget_currency_id: null,

                stop_type: null,
                travel_morning: null,
                travel_afternoon: null,
                travel_evening: null,
            },
        };

        this.rules = {
            tax: 'min:3|max:255',
            files: this.DEFAUL_FILE_RULLES,
            files_proof: 'required|' + this.DEFAUL_FILE_RULLES,
            description: 'required|min:3|max:3000',
            type: 'required|int',
            line_of_work: 'required',
            due_on: 'required',
            work_country_id: 'required',
            contact_number: 'required|min:3|max:255',
            currency_id: 'required',
            current_salary: 'required|min_value:1',
            updated_salary: 'required|min_value:1',
            coworking_city: 'required',
            recruit_countries: 'required',
            work_experience: 'required',
            company_link: 'required',
            gross_salary: 'required',
            offering_employment: 'required',
            work_location: 'required',
            office_location: 'required',
            other_job_position: 'required',
            other_job_description: 'required',
            budget: 'required|min_value:1',
            plan: 'required',
            device_type: 'required|min:3',
            delivery_address: 'required|min:3',
            benefit_type: 'required|min:3',

            passenger: {
                first_name: 'required|min:3',
                last_name: 'required|min:3',
                email: 'required|email',
                phone: 'required',
                date_of_birth: 'required',
                passport_number: 'required',
                passport_expiration_date: 'required',
            },
            guest: {
                first_name: 'required|min:3',
                last_name: 'required|min:3',
            },
            flight :{
                trip_type: 'required',
                from_airport_id: 'required',
                to_airport_id : 'required',
                check_out : 'required',
                rooms_count : 'required',
                property_type : 'required',
                flight_adults_count: 'required',
                flight_children_count: 'required',
                flight_infants_count: 'required',
            },
            accommodation :{
                country_id: 'required',
                city: 'required',
                check_in : 'required',
                check_out : 'required',
                rooms_count : 'required',
                property_type : 'required',
                adults_count: 'required',
                children_count: 'required',
                infants_count: 'required',
                main_guest : {
                    first_name: 'required|min:3',
                    last_name: 'required|min:3',
                    email: 'required|email',
                    address: 'required',
                    city: 'required',
                    country_id: 'required',
                    phone: 'required',
                },
            },
        };

        // const dict = {
        //     custom: {
        //         files: {
        //             // mimes: 'File size exceeds the maximum limit of 10MB',
        //             mimes: 'The file types for upload are pdf, jpg or png',
        //         },
        //     },
        // };

        // Validator.localize('en', dict);

        const attachments = obj.attachments || [];
        if (attachments) {
            this.commonDocFiles = attachments.filter(item => item.is_common_doc);
            this.receiptFiles = attachments.filter(item => item.is_receipt);
            this.diplomaFiles = attachments.filter(item => item.is_diploma);
            this.values.diploma_files = attachments.filter(item => item.is_diploma);
            this.cvFiles = attachments.filter(item => item.is_cv);
            this.values.cv_files = attachments.filter(item => item.is_diploma);
            this.passportFiles = attachments.filter(item => item.is_passport);
            this.values.passport_files = attachments.filter(item => item.is_diploma);
        }
    }

    getReturnDateRules() {
        if (this.values.additional_info.trip_type === 'round_trip') {
            return 'required';
        }
        return '';
    }
    getContactNumberRules() {
        if ([this.EXPENSES_TYPE, this.FEE_CHANGE_TYPE].includes(this.values.type)) {
            return '';
        }
        return this.rules.contact_number;
    }
    getOtherInfoRules() {
        if ([this.EXPENSES_TYPE].includes(this.values.type)) {
            return 'required|min:3|max:255';
        }
        return '';
    }

    getDescriptionRules() {
        if ([this.FEE_CHANGE_TYPE].includes(this.values.type)) {
            return '';
        }
        return this.rules.description;
    }

    getExpensesAmountRules() {
        if (this.values.type === this.EXPENSES_TYPE) {
            return 'required|min_value:1';
        }
        return '';
    }

    getFeeAmountRules() {
        if (this.values.type === this.FEE_CHANGE_TYPE) {
            return 'required|min_value:1';
        }
        return '';
    }
    getFeeExpectedAmountRules() {
        if (this.values.type === this.FEE_CHANGE_TYPE) {
            return 'required|min_value:1';
        }
        return '';
    }

    getReceiptFilesRules() {
        return [this.EXPENSES_TYPE, this.FEE_CHANGE_TYPE].includes(this.values.type)?
            'required|' + this.DEFAUL_FILE_RULLES:
            this.DEFAUL_FILE_RULLES;
    }

    getDiplomaFilesRules() {
        return [this.WORK_PERMIT_VISA_TYPE].includes(this.values.type)?
            'required|' + this.DEFAUL_FILE_RULLES:
            this.DEFAUL_FILE_RULLES;
    }

    getTitleRules() {
        if (![this.FEE_CHANGE_TYPE].includes(this.values.type)) {
            return 'required|min:3|max:255';
        }
        return '';
    }

    getFullNameRules() {
        if (![this.FEE_CHANGE_TYPE].includes(this.values.type)) {
            return 'min:3|max:255';
        }
        return '';
    }

    getRransactionIdRules() {
        if ([this.FEE_CHANGE_TYPE].includes(this.values.type)) {
            return 'required';
        }
        return '';
    }

    getJobIdRules() {
        if ([this.WORK_PERMIT_VISA_TYPE].includes(this.values.type)) {
            return 'required';
        }
        return '';
    }

    removeAttachment(id) {
        this.values.removed_attachment_ids.push(id);
        const commonDocsIndex = this.commonDocFiles.findIndex(item => item.id === id);
        if (~commonDocsIndex) {
            this.commonDocFiles.splice(commonDocsIndex, 1);
        }
        const receiptFilesIndex = this.receiptFiles.findIndex(item => item.id === id);
        if (~receiptFilesIndex) {
            this.receiptFiles.splice(receiptFilesIndex, 1);
        }
        const diplomaFilesIndex = this.diplomaFiles.findIndex(item => item.id === id);
        if (~diplomaFilesIndex) {
            this.diplomaFiles.splice(diplomaFilesIndex, 1);
            this.values.diploma_files.splice(diplomaFilesIndex, 1);
        }
        const cvFilesIndex = this.cvFiles.findIndex(item => item.id === id);
        if (~cvFilesIndex) {
            this.cvFiles.splice(cvFilesIndex, 1);
            this.values.cv_files.splice(cvFilesIndex, 1);
        }
        const passportFilesIndex = this.passportFiles.findIndex(item => item.id === id);
        if (~passportFilesIndex) {
            this.passportFiles.splice(passportFilesIndex, 1);
            this.values.passport_files.splice(passportFilesIndex, 1);
        }
    }

    clearRemovedAttachments() {
        this.values.removed_attachment_ids = [];
    }
}
