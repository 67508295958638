<template>
  <v-snackbar :value="snackbar" :left="true">
    <p class="text-base font-medium color-white">
      {{ text }}
    </p>

    <template v-slot:action="{ attrs }">
      <BaseButton
        class="btn-icon text-lg color-grey-2 ml-auto"
        v-bind="attrs"
        @click="closeToast"
      >
        <BaseIcon name="times" />
      </BaseButton>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "BaseToast",

  props: {
    snackbar: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
  },

  methods: {
    closeToast() {
      this.$emit("close-toast");
    },
  },
};
</script>
