<template>
    <div
        @click="!disabled? change(): null"
        class="d-flex align-center"
        :class="disabled? 'slide-disabled': 'pointer'"
        style="visibility: visible; width: auto; height: auto;"
    >
        <span
            v-if="leftLabel"
            class="text-medium mr-2"
            :class="val? 'color-dark-grey': 'color-black'"
        >
            {{ leftLabel }}
        </span>
        <div
            style="width: 35px; height: 16px; border-radius: 100px; position: relative; transition: 0.3s"
            :style="{border: val? '1px solid #5152FB': '1px solid #CDD0D2'}"
        >
            <div
                style="height: 12px; width: 12px; border-radius: 50%; position: absolute; top: 50%; transform: translateY(-50%); background-color: #5152FB; transition: all 0.3s linear;"
                :style="val? {'background-color': '#5152FB', left: '20px'}: {'background-color': '#CDD0D2', left: '2px'}"
            ></div>
        </div>
        <span
            v-if="label"
            class="text-medium ml-2"
            :class="!val? 'color-dark-grey': 'color-black'"
        >
            {{ label }}
        </span>
    </div>
</template>
<script>
export default {
    name: 'BaseSimpleSwitcher',
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            default: 0
        },
        trueValue: {
            default: 1
        },
        falseValue: {
            default: 0
        },
        label: {
            default: null
        },
        leftLabel: {
            default: null
        }
    },
    data() {
        return {
            val: this.value,
        }
    },
    methods: {
        change() {
            this.val = this.val ? this.falseValue : this.trueValue;
        }
    },
    watch: {
        val() {
            this.$emit('input', this.val);
        },
        value() {
            this.val = this.value;
        }
    }
}
</script>