<template>
    <modal v-model="dialog" :width="modalWidth" :padding="false" :close-icon-show="false" :content-class="'invite-welcome-modal'">
        <template slot="content">
            <div class="modal-content invite-welcome-modal-content">
                <div class="modal-header">
                    <button @click="close()" type="button" class="btn btn-close btn-icon medium-text bold-text color-white">
                        <i class="icon-times pr-1"></i>
                    </button>
                    <div>
                        <v-avatar size="98">
                            <v-img :src="organization.logo"></v-img>
                        </v-avatar>
                        <p class="welcome-title">Welcome to <br> {{ organization.name }}</p>
                        <p v-if="eor" class="subtitle">Gain official employment <br> status in minutes </p>
                    </div>
                </div>
                <div class="modal-body">
                    <div :class="eor ? 'mb-40' : ''">
                        <v-avatar size="30" class="mr-2 mb-2" v-if="country">
                            <v-img :src="country.flag"></v-img>
                        </v-avatar>
                        <p v-if="eor && country" class="body-text color-black">You are in {{ country.name }}</p>
                    </div>
                    <p v-if="eor" class="body-text color-black mb-40">Your employer {{ employerName }}has invited you to join the payroll. <br> We just need a few additional details.
                        <a :href="$store.state.brochureUrl+'global-payroll-services'" target="_blank" class="body-text color-purple">What is this?</a>
                    </p>
                    <p v-else class="body-text color-black">Hi {{ worker.name }}, what would you like to do first? </p>
                </div>
                <div class="modal-footer">
                    <template v-if="eor">
                        <button @click="goToPayroll()" type="button" class="btn btn-discard-draft btn-purple bold-text color-white mb-4">
                            Finish setting up payroll
                        </button>
                        <button @click="close()" type="button" class="btn btn-cancel color-dark-grey underlined">
                            Set up payroll later
                        </button>
                    </template>
                    <template v-else>
                        <button @click="requestHoliday()" type="button" class="btn btn-discard-draft btn-purple bold-text color-white mb-2">
                            Request Absence
                        </button>
                        <button @click="viewNoticeboard()" type="button" class="btn btn-discard-draft btn-purple bold-text color-white mb-2">
                            View Team Noticeboard
                        </button>
                        <button @click="goToWallet()" type="button" class="btn btn-discard-draft btn-purple bold-text color-white mb-4">
                            Go to Wallet
                        </button>
                    </template>
                </div>
            </div>
        </template>
    </modal>
</template>
<script>
import Modal from "~/shared/components/Modal";
export default {
    name: 'InviteeWelcome',
    components: {
        Modal,
    },
    props: {
        eor: {
            default: false
        },
    },
    data(){
        return {
            modalWidth: '34.4375rem',
            dialog: true,
            worker: this.$store.state.worker,
            organization: this.$store.state.organization,
            country: this.$store.state.info.country,
            employerName: this.$store.state.organization.creator
                && this.$store.state.organization.creator.name ? this.$store.state.organization.creator.name + ' ' : '',
        }
    },
    methods: {
        close(callback){
            axios.post(this.$store.state.path + 'profile/set-greeting-steps-viewed')
            .then(() => {
                this.dialog = false;
                if(typeof callback === 'function'){
                    callback();
                }
            });
        },
        goToPayroll(){
            this.closeAndGoToPath('payroll');
        },
        requestHoliday(){
            this.closeAndGoToPath('absence');
        },
        viewNoticeboard(){
            this.closeAndGoToPath('noticeboard');
        },
        goToWallet(){
            this.closeAndGoToPath('wallet');
        },
        closeAndGoToPath(path){
            this.close(() => {
                if(window.location.pathname !== '/' + path){
                    window.location.href = path;
                }
            });
        },
    }
}
</script>
<style lang="scss">
#app{
    .invite-welcome-modal{
        .invite-welcome-modal-content{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: normal;
            text-align: center;
            border-radius: 0.25rem;
        }
        .modal-content {
            background-color: #F2FAFF;
            padding: 0 0 1.875rem;
            overflow-y: auto;
        }
        .modal-header{
            background-image: linear-gradient(180deg, #725AE6 0%, #5152FB 100%);
            padding: 1.875rem 0;
            justify-content: center;
            position: relative;
            display: flex;
            -ms-flex-align: start;
            align-items: center;
            -ms-flex-pack: justify;
            justify-content: space-between;
            border-top-left-radius: calc(.3rem - 1px);
            border-top-right-radius: calc(.3rem - 1px);
            flex-direction: column;
            border: 0;
            margin-bottom: 2.125rem;
        }
        .modal-header .btn-close{
            position: absolute;
            top: 1rem;
            right: 0;
        }
        .modal-footer {
            flex-direction: column;
            display: flex;
            align-items: center;
        }
        .modal-footer .btn {
            width: 16.7rem;
            height: 2.7rem;
            padding: 0.75rem 0;
            font-size: 1rem;
        }
        .welcome-title {
            font-weight: 500;
            font-size: 2.9rem;
            line-height: 3.9rem;
            color: var(--white);
            margin-top: 0.7rem;
            margin-bottom: 0.9rem;
        }
        .subtitle {
            font-size: 1.125rem;
            line-height: 1.75rem;
            color: var(--white);
        }
        .body-text {
            font-size: 1.125rem;
            line-height: 1.75rem;
        }
    }
}
</style>
