import Case from "../../../models/Case";

const ROUTE = 'benefits/'
const CASES_TYPE = 4

const state = {

    // Page data
    sowEmptyBlock: false,
    alertInfo: {},
    tabs: [
        {
            title: "All benefits",
            filter: "",
        },
    ],

    dateData: [
        { name: `Past`, value: 0 },
        { name: `Available`, value: 1 },
        { name: `Upcoming`, value: 2 },
    ],

    tabsForAdmin: [
        {
            title: "Team benefits",
            filter: "team_benefits",
        },
        {
            title: "My benefits",
            filter: "completed",
        },
        {
            title: "Pending",
            filter: "pending",
        },
        {
            title: "Declined",
            filter: "declined",
        },
    ],

    tabsForEmployee: [
        {
            title: "My benefits",
            filter: "completed",
        },
        {
            title: "Pending",
            filter: "pending",
        },
        {
            title: "Declined",
            filter: "declined",
        },
    ],

    tableHeaders: [
        {
            text: "USER",
            align: "start",
            value: "user",
            sortable: false,
        },
        {
            text: "COUNTRY",
            align: "start",
            value: "country",
            sortable: false,
        },
        {
            text: "BENEFIT",
            align: "start",
            value: "benefit",
            sortable: false,
        },
        {
            text: "DATE",
            align: "start",
            value: "date",
            sortable: false,
        },
        {
            text: "STATUS",
            align: "start",
            value: "status",
            sortable: false,
        },
        {
            text: "",
            align: "end",
            value: "actions",
            sortable: false,
        },
    ],

    statusData: [
        { value: '', name: 'All' },
        { value: 'completed', name: 'Approved' },
        { value: 'pending', name: 'Pending' },
        { value: 'declined', name: 'Declined' },
    ],

    countries: [{id: 0, name: 'All countries', flag: '/images/icon-world-line.svg'}],
    categories: [],

    // Options
    axiosLoading: false,
    adminPanel: false,
    pageRole: null,

    // Filters
    queryData: {
        date_type: 1,
        country: null,
        category: null,
        search: null,
        type: '',
        page: 1,
    },

    // Filters For Request Table
    queryDataForTable: {
        status: '',
        country: null,
        search: null,
        page: 1,
    },


    // Needs options for create cases
    requestDataForCases: {
        type: CASES_TYPE,
        description: '',
        owners: []
    },

    // Active data to work with it
    activeBenefitsId: null,
    activeItem: {},
    activeOfferForView: {},


    // Paginate
    paginate: [],

    // Modals
    viewOfferDialog: false,

    newCaseFormDialog: false,

    requestFromTeamMemberDialog: false,

    getOfferDialog: false,
    getOfferDialogDescription: '',

};

const mutations = {
    setAlert(state, data) {state.alertInfo = data},
    setPageRole(state, data) {state.pageRole = data},
    setCountries(state, data) {state.countries = data},
    setCategories(state, data) {state.categories = data},
    setTabs(state, data) {state.tabs = state.tabs.concat(data)},
    setPaginate(state, data) {state.paginate = data},
    setQueryCategoryEmpty(state) {state.queryData.category = null},
    setViewOfferDialog(state,data) { state.viewOfferDialog = data },
    setRequestFromTeamMemberDialog(state) { state.requestFromTeamMemberDialog = !state.requestFromTeamMemberDialog },
    setActiveBenefitsId(state,data) { state.activeBenefitsId = data },
    setActiveItem(state,data) { state.activeItem = data },
    setGetOfferDialogDescription(state,data) { state.getOfferDialogDescription = data },
    setGetOfferDialog(state) { state.getOfferDialog = !state.getOfferDialog},
    setNewCaseFormDialog(state) {
        state.requestDataForCases.type = CASES_TYPE;
        state.requestDataForCases.description = '';
        state.requestDataForCases.owners = [];
        state.newCaseFormDialog = !state.newCaseFormDialog;
    },
    setActiveTab(state,data) { state.queryData.type = data ?? ''; state.paginate = []},
    setAdminPanel(state, data) { state.adminPanel = data },
    setActiveOfferForView(state, data) { state.activeOfferForView = data },
    setAxiosLoading(state) { state.axiosLoading = !state.axiosLoading },
    setSowEmptyBlock(state) { state.sowEmptyBlock = state.paginate && _.isEmpty(state.paginate.data) },
};

const actions = {
    clearAlert ({commit}) {
        commit('setAlert', {});
    },
    triggerActiveTab ({state,commit,dispatch}, v) {
        if (state.queryData.type === v || state.axiosLoading) return;
        commit('setActiveTab', v);
        commit('setAdminPanel', state.pageRole);
        if (v === 'team_benefits' && state.pageRole) {
            dispatch('getRequestsUsers');
        } else {
            commit('setAdminPanel');
            dispatch('getData');
        }
    },

    showSuccessDialog({state,commit,dispatch}) {
        let txt = state.activeItem.price_type ? 'Your request is submitted. Our team will contact you soon.' : 'The benefit is requested. You will be notified when it is approved.';
        commit('setGetOfferDialogDescription',txt);
        commit('setGetOfferDialog');
        if (!_.isEmpty(state.activeOfferForView)) {
            dispatch('getOfferData', state.activeOfferForView.id);
        } else {
            dispatch('getData');
        }
    },

    closeViewOfferDialog({commit}) {
        commit('setActiveOfferForView',{});
        commit('setViewOfferDialog', false); // close dialog
    },

    categoryFilter({state,commit,dispatch},ids) {
        dispatch('getData', { category: ids })
    },

    globalFilterPage({dispatch}) {
        dispatch('getData');
    },

    filtersForTable({dispatch}) {
        dispatch('getRequestsUsers');
    },

    showRequestFromTeamMemberDialog({commit},id) {
        commit('setActiveBenefitsId', id);
        commit('setRequestFromTeamMemberDialog');
    },

    getOfferDialogClose({commit,dispatch}) {
        commit('setActiveItem', {});
        commit('setActiveBenefitsId', null);
        commit('setGetOfferDialogDescription', '')
        commit('setGetOfferDialog');
        dispatch('getData')
    },

    getOfferValid({state, dispatch, commit},item) {
        if (_.isEmpty(item)) return;
        commit('setActiveItem',item);
        commit('setActiveBenefitsId', item.id);
        commit('setNewCaseFormDialog')
    },

    showViewOfferDialog({commit,dispatch}, id){
        if (!id) return;
        commit('setActiveBenefitsId', id);
        dispatch('getOfferData',id)
    },

    setInfoByRole({ dispatch, state, commit }, data) {
        let value = state.tabsForEmployee;
        if(data.pageRole) {
            value = (data.hasPayroll && state.tabsForAdmin) || {
                title: 'Team benefits',
                filter: 'team_benefits',
            };
        }
        commit('setTabs', value);
        commit('setPageRole', data.pageRole);
        commit('setCategories', data.categories);
        commit('setCountries', state.countries.concat(data.countries));
        dispatch('getData')
    },

    checkDataCases({state,commit,dispatch},object) {
        let data = {...state.requestDataForCases, ...object};
        data.benefits_id = state.activeItem.id;
        data.title = state.activeItem.title;
        data.benefit_type = state.activeItem?.category?.name || '';
        if (_.isEmpty(data.benefits_owner_id) && data.type !== CASES_TYPE && _.isEmpty(data.benefits_id)) return;
        dispatch('creatCasesWithGetOffer',data)
    },

    async getPageData({dispatch,commit}) {
        await window.axios
            .get(`${ROUTE}page-data`)
            .then(response => {
                dispatch('setInfoByRole', response.data)
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.response.data.message}, { root: true });
                throw error;
            });
    },

    async getData({state,commit},q = {}) {
        if (state.axiosLoading) return;
        for (const [key, value] of Object.entries(q)) {
            state.queryData[key] = value;
        }
        if (!q.page) {
            state.queryData.page = 1;
        }
        commit('setAxiosLoading');
        window.axios
            .get(`${ROUTE}paginate`, {
                params: state.queryData,
            })
            .then(response => {
                commit('setAxiosLoading');
                commit('setPaginate', response.data);
                commit('setSowEmptyBlock');
            })
            .catch(error => {
                commit('setAxiosLoading');
                commit('setAlert', {type: 'error', message: error.response.data.message}, {root: true});
            });
    },

    async getOfferData({commit},id) {
        window.axios
            .get(`${ROUTE}${id}`)
            .then(response => {
                commit('setActiveOfferForView', response.data);
                commit('setViewOfferDialog', true); // Always open dialog
            }).catch(error => {
             commit('setAlert', {type: 'error', message: error.response.data.message}, {root: true});
        });
    },

    async remindMe({state,commit,dispatch},item) {
        if (!item && item.id && state.axiosLoading) return;
        commit('setAxiosLoading');
        commit('setActiveItem',item);
        window.axios
            .post(`${ROUTE}remind-me`, { id: item.id })
            .then(response => {
                commit('setAxiosLoading');
                dispatch('showSuccessDialog');
                commit('setGetOfferDialogDescription', 'You will be notified when this benefit is available.');
            })
            .catch(error => {
                commit('setAxiosLoading');
                commit('setAlert', {type: 'error', message: error.response.data.message}, {root: true});
            });
    },

    async getRequestsUsers({commit}, q = {}) {
        if (state.axiosLoading) return;
        for (const [key, value] of Object.entries(q)) {
            state.queryDataForTable[key] = value;
        }
        if (!q.page) {
            state.queryDataForTable.page = 1;
        }
        commit('setAxiosLoading');
        window.axios
            .get(`${ROUTE}requests`, {
                params: state.queryDataForTable,
            }).then(response => {
                commit('setAxiosLoading');
                commit('setPaginate', response.data);
            })
            .catch(error => {
                commit('setAxiosLoading');
                commit('setAlert', {type: 'error', message: error.response.data.message}, {root: true});
            });
    },

    async creatCasesWithGetOffer({state,dispatch,commit},data) {
        // commit('cases/startEditingCase', data, { root: true })
        // dispatch('cases/save', data, { root: true })
        if (state.axiosLoading) return;
        commit('setAxiosLoading');
        let modal = new Case(data);
        modal.create('/benefits/request').then(response => {
            commit('setAxiosLoading');
            commit('setNewCaseFormDialog'); // close Dialog
            dispatch('showSuccessDialog');
        }).catch(error => {
            commit('setAxiosLoading');
            if(403 === error.response.status) {
                commit('setAlert', error.response.data);
                commit('setNewCaseFormDialog');
            } else  {
                commit('setAlert', {type: 'error', message: error.response.data.error || error.response.data.message}, {root: true});
            }
        });
    },
};

const getters = {
    alertInfo: state => state.alertInfo,
    tabs: (state) => state.tabs,
    pageRole: (state) => state.pageRole,
    countries: (state) => state.countries,
    categories: (state) => state.categories,
    queryData: (state) => state.queryData,
    queryDataForTable: (state) => state.queryDataForTable,
    dateData: (state) => state.dateData,
    paginate: (state) => state.paginate,
    viewOfferDialog: (state) => state.viewOfferDialog,
    activeBenefitsId: (state) => state.activeBenefitsId,
    newCaseFormDialog: (state) => state.newCaseFormDialog,
    getOfferDialog: (state) => state.getOfferDialog,
    getOfferDialogDescription: (state) => state.getOfferDialogDescription,
    requestFromTeamMemberDialog: (state) => state.requestFromTeamMemberDialog,
    tableHeaders: (state) => state.tableHeaders,
    adminPanel: (state) => state.adminPanel,
    requestDataForCases: (state) => state.requestDataForCases,
    activeItem: (state) => state.activeItem,
    activeOfferForView: (state) => state.activeOfferForView,
    statusData: (state) => state.statusData,
    sowEmptyBlock: (state) => state.sowEmptyBlock,
    axiosLoading: (state) => state.axiosLoading,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
