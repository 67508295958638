<template>
    <BaseModal
        :value="dialog"
        @input="!$event? close(): null"
        width="25rem"
    >
        <ModalInfo @on-confirm="confirm" :modal-data="modalData">
            <div v-html="__sanitizeHtml(modalData.content)"></div>
            <template v-slot:underButton>
                <BaseButton
                    type="button"
                    class="btn-big btn-purple-outline color-purple w-100 mt-2"
                    @click="close()"
                >
                    Close
                </BaseButton>
            </template>
        </ModalInfo>
    </BaseModal>
</template>

<script>
import ModalInfo from '~/shared/components/modals/ModalInfo.vue';
import Helpers from '~/shared/mixins/Helpers';
import {mapActions, mapState} from "vuex";
import Modal from "~/shared/components/Modal.vue";
export default {
    name: "UserRestriction",
    components: {Modal, ModalInfo},
    mixins: [Helpers],
    computed: {
        ...mapState('kyc', {
            dialog: 'restrictionDialog',
            options: 'options',
            type: 'restrictionType'
        }),
        modalData(){
            switch (this.type) {
                case 'aml':
                    return {
                        icon: {
                            name: "warning",
                            class: "color-feedback-warning",
                        },
                        title: "Temporary suspension on payment actions",
                        content: `<p class="mt-2 text-base color-grey-2 text-center">
                            Additional documents may be requested, potentially causing slight
                            transaction delays.
                        </p>
                        <p class="mt-2 text-base color-grey-2 text-center">
                            There will be a temporary suspension on your ability to proceed with any
                            transactions on our platform. We appreciate your patience and
                            cooperation.
                        </p>
                        <p class="mt-2 text-base color-grey-2 text-center">
                            For concerns, please contact our support team.Thank you for your
                            understanding.
                        </p>`,
                        btnText: "Contact us",
                        href: 'mailto:support@nteams.com'
                    }
                case 'block':
                case 'final':
                    return {
                        icon: {
                            name: "warning",
                            class: "color-feedback-error",
                        },
                        title: "Final rejection",
                        content: `<p class="mt-2 text-base color-grey-2 text-center">
                            We regret to inform you that your verification has been rejected.
                        </p>
                        <p class="mt-2 text-base color-grey-2 text-center">
                            For further details and assistance,
                            please contact our support team who will be able to provide you with
                            more information and guide you through the next steps.
                        </p>`,
                        btnText: "Contact us",
                        href: 'mailto:support@nteams.com'
                    }
                default:
                    return {};
            }
        }
    },
    methods: {
        ...mapActions('kyc', ['__closeRestrictionDialog']),
        confirm() {
            this.$emit("confirm");
            this.close();
        },
        close(){
            this.__closeRestrictionDialog();
        }
    },
};
</script>
