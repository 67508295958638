import store from "@/store";
import _ from "lodash";

export default class Middleware {
    constructor({from, to}) {
        this.from = from;
        this.to = to;
    }

    #responseObject = {
        success: true,
        redirect: null
    }

    #abortPages = {404: '404', PaymentExpired: 'payment-expired'}

    #pageData = (data) => {
        if (!_.isEmpty(data)){
            store.commit('initialization/PUSH_PAGE_DATA', data);
        }
    }

    responseRedirect(redirect = '/', pageData = {}){
        this.#pageData(pageData);
        return {...this.#responseObject, success: false, redirect};
    }

    responseAbort(error = 404, pageData = {}){
        const redirect = this.#abortPages[error] || error;
        this.#pageData(pageData);
        return {...this.#responseObject, success: false, redirect};
    }

    responseNext(pageData = {}){
        this.#pageData(pageData);
        return {...this.#responseObject, success: true};
    }
}
