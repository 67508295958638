import Middleware from "~/routes/middleware/Middleware";
import store from "~/store";

export default class clearPageData extends Middleware{
    constructor(...args) {
        super(...args);
    }

    resolve(){
        store.commit('initialization/CLEAR_PAGE_DATA');
        return this.responseNext();
    }
}
