import _ from "lodash";
import router from '~/routes/index';
export const namespaced = true;

export const state = {
    KYCLevel0: 'kyc_level_0',
    KYCLevel1: 'kyc_level_1',
    KYCLevel2: 'kyc_level_2',
    KYCLevel3: 'kyc_level_3',

    KYCPermissionMoneyIn: 'money_in',
    KYCPermissionMoneyOut: 'money_out',
    KYCPermissionLegalEqual: 'legal_entity_and_euqals',
    KYCPermissionUnl: 'unl',

    options: {},
    restrictionType: null,

    modalOptions: {},
    verified: false,
    dialog: false,
    restrictionDialog: false,
};

export const mutations = {
    TOGGLE_RESTRICTION_DIALOG(state, value){
        state.restrictionDialog = value;
    },
    TOGGLE_KYC_DIALOG(state, data){
        state.dialog = data.value;
        state.modalOptions = data.options || {}
    }
};

export const actions = {
    __setKYCData({state}, data = {}){
        for (const [key, value] of Object.entries(data)) {
            state[_.camelCase(key)] = value && 'object' === typeof value && !Array.isArray(value) ? {...value} : value
        }
    },
    __checkKYCPermission({state, commit}, data = {}){
        const temp = data.temp;
        const perm = data.permission;
        return temp?
            state.options?.temporary?.permissions?.includes(perm):
            state.options?.permissions?.includes(perm);
    },
    __checkRestriction({state, commit}){
        if (state.restrictionType && state.restrictionType !== 'not_restricted') {
            commit("TOGGLE_RESTRICTION_DIALOG", true);
            return false;
        }
        return true;
    },
    async __checkVerified({state, commit, dispatch}, data = {}) {
        /*Check restriction*/
        if (!await dispatch('__checkRestriction')){
            return false
        }

        /*UNL check 2FA*/
        if (data.permission === state.KYCPermissionUnl && !data.disabled2fa){
            const check2FA = await dispatch('wlSecurity2fa/__checkEnable', {}, {root: true});
            if (!check2FA){
                return false
            }
        }

        /*Check permission*/
        const permAccess = data.permission?
            await dispatch('__checkKYCPermission', {permission: data.permission}):
            true;

        /*Check verified*/
        if (state.verified !== true || !permAccess || data.force){
            const options = {
                level: data.level || state.KYCLevel2,
                card: data.card || null,
                option: data.option || data.card || null
            }

            commit('TOGGLE_KYC_DIALOG', {value: true, options: options})
            return false;
        }

        return true;
    },

    __middlewareKYC({dispatch}, {callback, data = {}}) {
        dispatch('__checkVerified', data).then(verified => verified? callback() : null);
    },
    __middlewareKYCRedirect({dispatch}, {url, data = {}}) {
        dispatch('__checkVerified', data).then(verified => verified ?
            router.push(url).then(r => {}) : null)
    },

    __closeDialog({commit}){
        commit('TOGGLE_KYC_DIALOG', {value: false, options: {}})
    },
    __closeRestrictionDialog({commit}){
        commit('TOGGLE_RESTRICTION_DIALOG', false)
    },
};

export const getters = {
    __KYCStatus: (state) => state.options?.status || null,
    __KYCOptions: (state) => state.options,
    __KYCLevel3: (state) => state.KYCLevel3,
    __KYCLevel2: (state) => state.KYCLevel2,
    __KYCLevel1: (state) => state.KYCLevel1,
    __KYCLevel0: (state) => state.KYCLevel0,
};
