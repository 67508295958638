<template>
  <div
    class="radio-group-container"
    :class="hasParagraph ? '' : 'simple-radio-group-container'"
  >
    <label
      class="radio-group-box"
      v-for="option in options"
      :key="option.value"
      :class="{
        selected: option.value === selectedValue,
      }"
    >
      <div class="radio-group-box-content">
        <div
          class="radio-group-box-header"
          :class="[hasParagraph ? '' : 'simple-radio-group']"
        >
          <WlBaseIcon :name="option.iconName" v-if="option.iconName" />
          <v-img
            v-if="option.iconSrc"
            contain
            :src="option.iconSrc"
            max-width="20px"
            max-height="20px"
          />
          <div class="radio-group-box-body">
            <p
              class="leading-130 color-black radio-group-heading-margin"
              :class="hasParagraph ? 'text-base' : 'text-sm'"
            >
              {{ option.heading }}
            </p>
            <div class="radio-label">
              <slot :option="option" name="paragraph">
                <p class="text-sm leading-130 color-grey-2 mt-1">
                  {{ option.paragraph }}
                </p>
              </slot>
              <img
                class="radio-image"
                :class="option.image ? 'mt-2' : ''"
                v-if="option.image"
                :src="`/images/${option.image}`"
              />
            </div>
            <slot
              v-if="hasParagraph"
              :name="`extra-content.${option.value}`"
            ></slot>
          </div>
          <input
            :disabled="option?.disabled"
            type="radio"
            :name="name"
            :value="option.value"
            @change="handleChange(option.value)"
            class="input-radio"
            :checked="option.value === selectedValue"
          />
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: "WlBaseRadioGroup",

  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      selectedValue: this.value || "", // Initialize with an empty string or default value
    };
  },

  methods: {
    handleChange(selectedOptionValue) {
      this.selectedValue = selectedOptionValue; // Update the selected value

      this.$emit("input", selectedOptionValue); // Emit the selected value
    },
  },

  computed: {
    hasParagraph() {
      return this.options.some((option) => option.paragraph);
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.selectedValue = newValue;
      },
    },

    options(options) {
      let disabledOption = options.find((item) => {
        return item.disabled && this.selectedValue === item.value;
      });

      if (disabledOption) {
        let firstActive = options.find((item) => !item.disabled);
        this.handleChange(firstActive ? firstActive.value : "");
      }
    },
  },
};
</script>
