<template>

    <!-- Pending -->
    <ModalInfo
        v-if="pending"
        :modalData="modalData"
        @close="$emit('close')"
        @on-confirm="$emit('close')"
    >
        <p class="mt-2 text-base color-grey-2 text-center mb-3">
            {{ description }}
        </p>
        <template #underButton>
            <BaseButton class="btn-big btn-purple-outline w-100 mt-2" @click="$emit('close')"> Ok, close this </BaseButton>
        </template>
    </ModalInfo>

    <!-- Empty -->
    <verification-empty v-else></verification-empty>
</template>
<script>
import ModalInfo from "~/shared/components/modals/ModalInfo";
import VerificationEmpty from "./VerificationEmpty.vue";
export default {
    name: 'VerificationPending',
    components: {VerificationEmpty, ModalInfo},
    data(){
        return {}
    },
    computed: {
        pending(){
            return this.$store.state.kyc.options.status === 'pending'?
                this.$store.state.kyc.options:
                this.$store.state.kyc.options.temporary?.status === 'pending'?
                    this.$store.state.kyc.options.temporary: null;
        },
        modalData(){
            return {
                icon: {
                    name: "pending-circle-outline",
                    class: "color-feedback-warning",
                },
                title: "KYC verification pending"
            }
        },
        description(){
            return `Your KYC verification is currently pending. Our team is
            reviewing your information, and we appreciate your patience.
            We'll notify you once the process is complete. Please contact
            our support team if you have any questions or need
            assistance.`
        }
    }
}
</script>
