<template>
    <div class="base-table-container" :id="localId">
        <div class="ml-auto" v-if="$slots['total-costs']">
            <slot name="total-costs" />
        </div>
        <div v-if="$slots['filters']" :class="[filterSlotCustomClasses, !forMobile ? 'filters-container base-table-filters' : 'table-filters']">
            <slot name="filters"></slot>
        </div>
        <v-data-table
            v-bind="$attrs"
            v-on="$listeners"
            v-model="val"
            :headers="headers"
            :items="items"
            :loading="loading"
            class="base-table"
            :class="classNames"
            @page-count="pageCount = $event"
            :page.sync="localPage"
            :items-per-page="itemsPerPage"
            :hide-default-header="isDesktop ? hideDefaultHeader : true"
            hide-default-footer
            :dense="dense"
            :item-key="itemKey"
            :mobile-breakpoint="mobileBreakpoint"
            :header-props="headerProps"
            :disable-pagination="!localPagination || disablePagination"
            @click:row="handleRowClicked"
            @contextmenu:row.prevent="contextmenuPrevent"
        >
            <template
                v-if="$scopedSlots['item.data-table-select']"
                v-slot:[`item.data-table-select`]="slotProps"
            >
                <slot name="item.data-table-select" v-bind="slotProps"></slot>
            </template>
            <template v-slot:top>
                <slot name="top"></slot>
            </template>
          <template
              v-if="$scopedSlots['body'] && items.length"
              v-slot:body="slotProps"
          >
            <slot name="body" v-bind="slotProps"></slot>
          </template>
            <template v-slot:body.prepend>
                <slot name="body.prepend"></slot>
            </template>
            <template v-slot:no-data>
                <div v-if="!$slots['no-data']" class="empty-container">
                    <BaseIcon name="list" />
                    <p class="text-xl color-black font-medium mb-1">Nothing here</p>
                </div>
                <slot v-else name="no-data"></slot>
            </template>
            <template
                v-if="!isDesktop && $scopedSlots[`item-mobile`]"
                v-slot:item="slotProps"
            >
                <BaseModal
                    :disabled="!$scopedSlots['mobile-modal']"
                    transition="expand"
                    :attach="modalAttach || `#${localId}`"
                    width="100%"
                    @input="$emit('modalToggle', $event)"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div class="mobile-table-row" v-on="on" v-bind="attrs">
                            <slot name="item-mobile" v-bind="slotProps"></slot>
                        </div>
                    </template>
                    <template v-slot:default="{ close }">
                        <div class="mobile-table-modal">
                            <slot
                                name="mobile-modal"
                                v-bind="slotProps"
                                :close="close"
                            ></slot>
                        </div>
                    </template>
                </BaseModal>
            </template>

            <!-- ITEM SLOT -->
            <template
                v-for="(item, index) in headers"
                v-slot:[`item.${item.value}`]="slotProps"
            >
                <slot
                    v-if="$scopedSlots[`item.${item.value}`]"
                    :name="`item.${item.value}`"
                    v-bind="slotProps"
                />
                <template v-else>
                    <p v-if="!slotProps.isMobile" :key="index" class="text-base">
                        {{ slotProps.item[item.value] }}
                    </p>
                </template>
            </template>

            <!-- HEADER SLOT -->
            <template
                v-for="(item, index) in headers"
                v-slot:[`header.${item.value}`]="slotProps"
            >
                <slot
                    v-if="$scopedSlots[`header.${item.value}`]"
                    :name="`header.${item.value}`"
                    v-bind="slotProps"
                />
                <template v-else>
          <span :key="index">
            {{ slotProps.header["text"] }}
          </span>
                </template>
            </template>
        </v-data-table>
        <BaseTablePaginator
            @input="(e) => $emit('page-change', e)"
            v-if="!hideDefaultFooter && !disablePagination"
            class="justify-content-end"
            v-model="localPage"
            :page-count="localPagination ? pageCount : null"
            :length="localPagination ? items.length : itemsLength"
            :items-per-page="itemsPerPage"
            :total-visible="paginationTotalVisible"
            :disabled="loading"
        >
            <slot name="paginator-default"></slot>
        </BaseTablePaginator>
        <!-- FOOTER SLOT -->
        <slot name="footer"></slot>
    </div>
</template>

<script>
import { commonHelpersMixin } from "~/shared/mixins/commonHelpersMixin";
import Helpers from "~/shared/mixins/Helpers";

const DEFAULT_HEADER_PROPS = {
    "sort-icon": "icon-chevron-down",
};

export default {
    name: "BaseTable",

    mixins: [commonHelpersMixin, Helpers],

    props: {
        /* VUETIFY PROPS */

        dense: {
            type: Boolean,
            default: true,
        },
        disablePagination: {
            type: Boolean,
            default: false,
        },
        headerProps: {
            type: Object,
            default: () => DEFAULT_HEADER_PROPS,
        },
        headers: {
            type: Array,
            default: () => [],
        },
        hideDefaultHeader: {
            type: Boolean,
            default: false,
        },
        hideDefaultFooter: {
            type: Boolean,
            default: false,
        },
        itemKey: {
            type: String,
            default: "id",
        },
        items: {
            type: Array,
            default: () => [],
        },
        itemsPerPage: {
            type: Number,
            default: 9,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        page: {
            default: 1,
        },
        mobileBreakpoint: {
            type: [Number, String],
            default: 993,
        },
        value: {
            type: Array,
            default: () => [],
        },

        /* CUSTOM PROPS */

        classNames: {
            default: "",
        },
        paginationTotalVisible: {
            type: Number,
            default: 7,
        },
        localPagination: {
            type: Boolean,
            default: true,
        },
        itemsLength: {
            default: null,
        },
        modalAttach: {
            default: null,
        },
        filterSlotCustomClasses: {
            type: String,
            default: '',
        },
        forMobile: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            pageCount: 0,
            localPage: this.page,

            val: this.value,
        };
    },

    computed: {
        localId() {
            return this.__makeId();
        },
    },

    methods: {
        handleRowClicked(value, table, event) {
            if (event.target.parentElement?.classList.contains('custom-click-event')) return;
            this.$emit('rowClicked', value);
        },
        contextmenuPrevent(e, {item}) {
            this.$emit('contextmenuPrevent', item);
        },
    },

    watch: {
        value(val) {
            this.val = val;
        },
        val(val) {
            this.$emit("input", val);
        },
        page(val) {
            this.localPage = val;
        },
    },
};
</script>
