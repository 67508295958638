<!-- @format -->

<template>
    <div class="col-12 wizard-step-content" :class="{ 'super-admin-wizard-step-content-class': isSuperAdmin }">
        <div v-if="isDesktop && showCSbox && manager" class="customer-success-window">
            <div class="d-flex flex-column align-items-center text-center">
                <account-manager-avatar
                    :image-size="56"
                    :image-key="manager.image_key"
                    :classes-val="'account-manager-avatar mb-2'"
                />
                <p class="text-lg font-medium mb-1">{{ manager.name }}</p>
                <p class="text-sm color-dark-grey">Customer success manager</p>
            </div>
            <div class="messenger-container mt-1">
                <div class="item pb-0">
                    <a :href="'mailto:' + manager.email" class="btn-text color-purple">
                        <BaseIcon class="text-base" name="email" />
                    </a>
                </div>
            </div>
        </div>
        <div class="wizard-step-header" :class="customWizardStepHeaderClasses">
            <h2 class="heading-2 font-medium leading-120 color-black text-center mb-1">
                {{ title }}
            </h2>
            <p v-if="description" class="description paragraph leading-150 color-dark-grey text-center">
                {{ description }}
            </p>
        </div>
        <div class="wizard-step-body" :class="customWizardStepBodyClasses">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

import AccountManagerAvatar from '~/shared/components/AccountManagerAvatar';
import { commonHelpersMixin } from '~/shared/mixins/commonHelpersMixin.js';

import BaseButton from '~/components/BaseElements/BaseButton.vue';

export default {
    name: 'BaseWizardStep',
    components: { AccountManagerAvatar, BaseButton },
    mixins: [commonHelpersMixin],
    props: {
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        eor: {
            type: Object,
            default: () => ({}),
        },
        showCSbox: {
            type: Boolean,
            default: false,
        },
        customWizardStepHeaderClasses: {
            type: String,
            default: '',
        },
        customWizardStepBodyClasses: {
            type: String,
            default: '',
        },
        isSuperAdmin: {
            default: false,
        },
    },
    data() {
        return {
            manager: null,
        };
    },
    watch: {
        'eor.values.country.country_id'() {
            this.getAccManager();
        },
    },
    created() {
        this.getAccManager();
    },
    methods: {
        getAccManager() {
            if (!this.eor || !this.eor.values) {
                this.manager = this.$store.state.accountManager;
            }
            if (this.showCSbox && this.eor && this.eor.values && this.eor.values.country) {
                axios
                    .get(`${this.$store.state.path}get/manager/${this.eor.values.country.country_id}`)
                    .then(response => {
                        this.manager = response.data.manager;
                    })
                    .catch(error => {
                        this.$store.commit('setAlert', { type: 'error', message: error.response.data.message });
                    });
            }
        },
    },
};
</script>
