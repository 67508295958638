import axios from "axios";

const state = {
    maxAllowedRequests: 0,
    alreadyApplied: 0,
};

const mutations = {
    SET_MAX_REQUESTS(state, payload) {
        state.maxAllowedRequests = payload.data.data.maxAllowedRequests;
        state.alreadyApplied = payload.data.data.todayPaymentRequestsCount;
    }
}

const getters = {
    maxAllowedRequests: state => state.maxAllowedRequests,
    alreadyApplied: state => state.alreadyApplied,
}

const actions = {
    async maxAllowedRequestsPost({state, commit, rootState}, q = {}) {

        let path = `/v2/permissions/daily-payment-request`;

        await axios
            .post(path)
            .then(response => {
                commit("SET_MAX_REQUESTS", response);
            })
            .catch(error => {
                commit('setAlert', {
                    type: 'error',
                    message: error.response.data.message,
                });
            })
            .finally(() => {
            });
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
