export const getUnlimintCardStatus = (card) => {
    if (card.status_id === 2) {
        return 'declined';
    } else if (card.status_id < 4 || card.status_id === 4 && !card.is_arrived) {
        return 'pending';
    } else if (card.status_id > 5 && card.status_id < 9) {
        return 'blocked';
    }

    return card.status;
};

export const getUnlimintCardRequestStatus = cardRequest => {
    if (cardRequest.status === 'arrived') {
        return 'inactive';
    }

    return 'pending';
};

export const getNativeCardRequestStatus = (cardRequest) => {
    return cardRequest.status !== 'declined' ? 'pending' : 'declined';
};