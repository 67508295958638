export default class StyleSystem {
    constructor() {
        this.allStyles = {
            wallet: [
                require('!raw-loader!_/build/wallet/styles/clear.css').default,
                require('!raw-loader!_/build/wallet/styles/custom.css').default,
                require('!raw-loader!_/build/wallet/styles/main.css').default,
                require('!raw-loader!_/build/wallet/styles/responsive.css').default,
                require('!raw-loader!_/build/wallet/styles/scrollbar.css').default,
                require('!raw-loader!_/build/wallet/fonts/style.css').default
            ],
            dashboard: [
                require('!raw-loader!_/build/dashboard/styles/style.css').default,
                require('!raw-loader!_/build/dashboard/styles/vue-repo-clear.css').default,
                require('!raw-loader!_/build/dashboard/styles/vue-repo-style.css').default,
                require('!raw-loader!_/build/dashboard/styles/vue-repo-responsive.css').default,
                require('!raw-loader!_/build/dashboard/styles/vue-repo-custom.css').default,
                require('!raw-loader!_/build/dashboard/styles/vue-repo-responsive-fix.css').default,
                require('!raw-loader!_/build/dashboard/styles/app.css').default,
                require('!raw-loader!_/build/dashboard/styles/custom.css').default,
                require('!raw-loader!_/build/dashboard/styles/main.css').default,
                require('!raw-loader!_/build/dashboard/styles/responsive.css').default,
                require('!raw-loader!_/build/dashboard/styles/responsive_fix.css').default,
                require('!raw-loader!_/build/dashboard/styles/new-wallet.css').default,
                require('!raw-loader!_/build/dashboard/fonts/fonts.css').default,
            ]
        }
    }

    #dashboardLayouts = ['dashboard', 'guest', 'empty']

    typeByLayout = (layout) => this.#dashboardLayouts.includes(layout)?
        'dashboard' :
        'wallet'

    import(layout){
        const type = this.typeByLayout(layout);

        /*Get styles*/
        const styles = this.allStyles[type] || [];
        const imported = document.querySelectorAll("style[data-type='style-system']");

        /*Remove old style system*/
        imported.forEach(s => s.remove());

        /*Import new style system*/
        styles.forEach(style => {
            const styleTag = document.createElement('style');
            styleTag.lang = 'css';
            styleTag.setAttribute('rel', 'stylesheet/css');
            styleTag.setAttribute('data-type', 'style-system');
            styleTag.innerHTML = style;
            document.head.appendChild(styleTag)
        })
    }
}
