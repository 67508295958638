<template>
    <div
        class="field-group field-input-group position-relative"
        :class="
      isMark ? 'd-flex align-start flex-row-reverse justify-content-end' : ''
    "
    >
        <div class="d-flex">
            <label
                v-if="$slots.label || label"
                class="text-sm color-black"
                :class="isMark ? 'custom-label-radio' : 'custom-label'"
                :for="localId"
            >
                <slot v-if="$slots.label" name="label"></slot>
                <span v-else>{{ label }}</span>
            </label>
            <label
                v-if="$slots.requiredText || requiredText"
                class="custom-label ml-1 text-sm color-feedback-error"
            >
                <slot v-if="$slots.requiredText" name="requiredText"></slot>
                <span>{{ requiredText }}</span>
            </label>
        </div>
        <p
            v-if="$slots.subLabel || subLabel"
            class="text-sm color-grey-2 leading-130 mb-1"
        >
            <slot v-if="$slots.subLabel" name="subLabel"></slot>
            <span v-else>{{ subLabel }}</span>
        </p>
        <input
            v-if="isMark"
            @change="$emit('change', val)"
            :id="localId"
            :name="name"
            v-model="val"
            :value="itemValue"
            :type="type"
            class="input input-mark"
            :class="errorMessages.length ? 'error' : ''"
            :disabled="!!disabled"
            :checked="checked"
        />
        <v-text-field
            v-else
            ref="input"
            :id="localId"
            :append-icon="
                    !search ? appendIcon : value.length !== 0 ? 'icon-times' : null
            "
            :append-outer-icon="appendOuterIcon"
            :prepend-icon="prependIcon"
            :prepend-inner-icon="!search ? prependInnerIcon : 'icon-search'"
            :disabled="disabled"
            :type="inputType"
            :placeholder="placeholder"
            :readonly="readonly"
            :autocomplete="autocomplete"
            v-on="$listeners"
            class="w-full"
            :value="value"
            hide-details
            dense
            :class="[
        bordered ? 'input' : '',
        errorMessages.length ? 'error' : '',
        search ? 'search' : '',
        hasError && ! errorMessages.length ? 'error' : '',
        textFieldContentClass,
        isValidCouponCode ? 'success' : '',
      ]"
            :style="{ 'max-width': maxWidth, height: height }"
            @click:append="handleClickAppendIcon"
            @change="$emit('change', val)"
        >
            <template slot="append">
                <WlBaseIcon
                    v-if="isPassword"
                    :name="passwordInputIcon"
                    class="text-xl color-grey-3 cursor-pointer"
                    @click="passwordInputIsShown = !passwordInputIsShown"
                />
                <p
                    v-if="isValidCouponCode"
                    class="coupon-code-valid-wrapper text-base font-medium text-green-400"
                >
                    Applied!
                    <BaseIcon
                        name="check-circle-outline"
                        class="text-lg text-green-400 ml-2"
                    />
                </p>
                <slot name="append"></slot>
            </template>
            <template slot="append-outer">
                <slot name="append-outer" class="no-pointer-events"></slot>
            </template>
            <template slot="prepend">
                <slot name="prepend"></slot>
            </template>
            <template slot="prepend-inner">
                <slot name="prepend-inner"></slot>
            </template>
            <template slot="progress">
                <slot name="progress"></slot>
            </template>
        </v-text-field>
        <WlBaseErrorMessage :messages="errorMessages" />
    </div>
</template>

<script>
import Helpers from "~/shared/mixins/Helpers";

export default {
    mixins: [Helpers],

    name: "WlBaseInput",

    props: {
        id: {
            type: String || null,
            default: null,
        },
        label: {
            type: String || null,
            default: null,
        },
        subLabel: {
            type: [String, null],
            default: null,
        },
        requiredText: {
            type: [String, null],
            default: null,
        },
        errorMessages: {
            default: () => [],
        },
        type: {
            type: String || null,
            default: "text",
        },
        appendIcon: {
            type: String || null,
            default: null,
        },
        appendOuterIcon: {
            type: String || null,
            default: null,
        },
        prependIcon: {
            type: String || null,
            default: null,
        },
        prependInnerIcon: {
            type: String || null,
            default: null,
        },
        placeholder: {
            type: String || null,
            default: null,
        },
        itemValue: {
            default: 1,
        },
        search: {
            type: Boolean,
            default: false,
        },
        value: {
            default: null,
        },
        disabled: {
            default: false,
        },
        readonly: {
            default: false,
        },
        bordered: {
            default: true,
        },
        checked: {
            default: false,
        },
        height: {
            default: "48px",
        },
        maxWidth: {
            type: String,
            default: "auto",
        },
        name: {
            type: String,
            default: "",
        },
        textFieldContentClass: {
            type: String,
            default: "",
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        hasError: {
            default: false,
        },
        isValidCouponCode: {
            type: Boolean,
            default: false,
        },
        autocomplete: {
            default : ''
        }
    },

    data() {
        return {
            val: this.value,
            localId: this.id || this.__makeId(),

            passwordInputIsShown: false,
        };
    },

    computed: {
        isMark() {
            return this.type === "checkbox" || this.type === "radio";
        },

        isPassword() {
            return this.type === "password";
        },

        inputType() {
            return this.isPassword && this.passwordInputIsShown ? "text" : this.type;
        },

        passwordInputIcon() {
            return this.passwordInputIsShown ? "no-eye" : "eye";
        },
    },

    methods: {
        handleClickAppendIcon() {
            if (this.search) {
                this.val = "";
            }
        },
    },

    watch: {
        val() {
            this.$emit("input", this.val);
        },
        value() {
            this.val = this.value;
        },
    },
    mounted() {
        if (this.autofocus) {
            this.$nextTick(() => {
                this.$refs.input.focus();
            });

        }
    }
};
</script>

<style lang="scss" scoped>
.coupon-code-valid-wrapper {
    display: flex;
    align-items: center;
}
</style>
