<!-- @format -->

<template>
    <v-dialog
        v-model="dialog"
        persistent
        no-click-animation
        content-class="custom-modal modal-md modal-convert-currency"
        @click:outside="dialog = false"
    >
        <!-- Dialog content -->
        <div class="modal-container">
            <div class="modal-header modal-convert-currency-header">
                <WlBaseButton class="btn-icon-sm btn-close-modal ml-auto" @click="dialog = false">
                    <WlBaseIcon name="times" class="text-lg" />
                </WlBaseButton>
                <h3 class="heading-3 font-bold color-black">How much do you want to convert?</h3>
            </div>
            <div class="modal-body">
                <div v-if="walletsList" class="convert-currency-content">
                    <div class="select-currency mb-2" :class="{ error: errors.collect('fromAmount').length }">
                        <WlBaseFormattedInput
                            v-on:keydown.native="checkInputVal"
                            v-model="formData.fromAmount"
                            v-validate="formAmountRules"
                            class="clean-input formatted__input"
                            custom-formatted-input-classes="modal-convert-currency-formatted-input"
                            type="input"
                            placeholder="0.00"
                            label="Amount"
                            name="fromAmount"
                            data-vv-as="amount"
                            @blur="calcTo"
                        />
                        <WlBaseSelect
                            v-model="formData.fromWalletId"
                            v-validate="`required`"
                            :items="walletsList"
                            select-first
                            content-class="select-currency-menu"
                            class="clean-input select-currency__input"
                            item-text="currency.code"
                            name="fromWalletId"
                            data-vv-as="from wallet"
                        >
                            <template #selection="{ item }">
                                <div class="custom-selected-item">
                                    <BaseAvatar :object="item.currency" content-img-class="avatar" size="32" />
                                    <p class="text-lg color-black">{{ item.currency.code }}</p>
                                </div>
                            </template>
                            <template #item="{ item }">
                                <div class="custom-selection-item">
                                    <BaseAvatar :object="item.currency" content-img-class="avatar" size="24" />
                                    <p class="text-base">{{ item.currency.code }}</p>
                                </div>
                            </template>
                        </WlBaseSelect>
                    </div>

                    <v-slide-x-reverse-transition>
                        <span
                            v-if="errors.collect('fromAmount').length"
                            class="text-sm color-feedback-error mb-2 mt-2"
                            >{{ errors.collect('fromAmount')[0] }}</span
                        >
                    </v-slide-x-reverse-transition>
                    <p v-if="fromWallet" class="text-sm color-grey-2 mb-28">
                        Available balance:
                        <span class="color-black">
                            {{ formatAmountNoCurrency(fromWallet.available_balance, 2, 2) }}
                            {{ fromWallet.currency.code }}
                        </span>
                    </p>
                    <div class="select-currency">
                        <WlBaseFormattedInput
                            v-on:keydown.native="checkInputVal"
                            v-model="formData.toAmount"
                            v-validate="`required`"
                            class="clean-input formatted__input"
                            type="input"
                            placeholder="0.00"
                            label="Amount"
                            name="toAmount"
                            data-vv-as="amount"
                            @blur="calcFrom"
                        />
                        <WlBaseSelect
                            v-model="formData.toWalletId"
                            v-validate="`required`"
                            :items="walletsList"
                            select-first
                            content-class="select-currency-menu"
                            class="clean-input select-currency__input"
                            item-text="currency.code"
                            name="toWalletId"
                            data-vv-as="to wallet"
                        >
                            <template #selection="{ item }">
                                <div class="custom-selected-item">
                                    <BaseAvatar :object="item.currency" content-img-class="avatar" size="32" />
                                    <p class="text-lg color-black">{{ item.currency.code }}</p>
                                </div>
                            </template>
                            <template #item="{ item }">
                                <div class="custom-selection-item">
                                    <BaseAvatar :object="item.currency" content-img-class="avatar" size="24" />
                                    <p class="text-base">{{ item.currency.code }}</p>
                                </div>
                            </template>
                        </WlBaseSelect>
                    </div>
                    <div class="currency-rate-value">
                        <p class="text-sm color-dark-grey">Rate</p>
                        <p class="text-base color-black">{{
                            conversion ? formatAmountNoCurrency(conversion, 2, 2) : ''
                        }}</p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <WlBaseButton
                    :loading="loading"
                    :disabled="disableButton"
                    type="primary"
                    class="w-full"
                    @click="transfer"
                >
                    Convert
                </WlBaseButton>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Helpers from '~/shared/mixins/Helpers';

import WalletToWalletTransfer from '../../../models/WalletToWalletTransfer';

export default {
    name: 'ModalConvertCurrency',
    mixins: [Helpers],

    props: {
        value: {
            default: false,
        },
    },

    data() {
        return {
            dialog: this.value,
            loading: false,
            conversionLoading: false,
            conversion: null,
            formData: {
                fromAmount: '',
                toAmount: '',
                fromWalletId: null,
                toWalletId: null,
            },
            formDataRules: {
                fromAmount: 'required',
            },
            transactionObj: new WalletToWalletTransfer(),
            findWalletCodes: ['EUR', 'USD'],
        };
    },
    computed: {
        ...mapGetters('dashboard', ['selectedWallet', 'allowedWallets', 'getMetricCurrency']),
        ...mapGetters('kyc', ['__KYCStatus']),
        isValid() {
            return !Object.keys(this.fields).some(key => this.fields[key].invalid);
        },
        formAmountRules() {
            let rule = 'required';
            if (this.fromWallet && 0 < this.fromWallet.available_balance) {
                rule += `|max_value:${this.fromWallet.available_balance}`;
            }
            return rule;
        },
        disableButton() {
            return (
                !this.formData.toAmount ||
                !this.formData.fromAmount ||
                !this.formData.fromWalletId ||
                !this.formData.toWalletId ||
                this.conversionLoading ||
                !this.isValid ||
                (this.fromWallet && 0 >= this.fromWallet.available_balance) ||
                this.checkIfWalletTheSame
            );
        },
        walletsList() {
            return this.allowedWallets;
        },
        toWallet() {
            return this.walletsList.find(item => item.id === this.formData.toWalletId);
        },
        fromWallet() {
            return this.walletsList.find(item => item.id === this.formData.fromWalletId);
        },
        checkIfWalletTheSame() {
            return (
                this.formData.fromWalletId &&
                this.formData.toWalletId &&
                this.formData.fromWalletId === this.formData.toWalletId
            );
        },
    },
    watch: {
        selectedWallet() {
            this.setSelectedWallet();
        },
        'formData.fromWalletId'() {
            this.handleFromWalletChange('from');
            this.conversion = null;
            this.formData.toAmount = '';
            this.getConversion();
        },
        'formData.toWalletId'() {
            this.handleFromWalletChange('to');
            this.conversion = null;
            this.formData.toAmount = '';
            this.getConversion();
        },
        conversion() {
            this.calcTo();
        },
        __KYCStatus() {
            this.detectQueryParam();
        },
        walletsList() {
            if (!this.formData.fromWalletId) {
                this.handleFromWalletChange();
            }
        },
        getMetricCurrency() {
            if (this.getMetricCurrency) {
                this.setSelectedWalletFromMetric();
            }
        },
        value() {
            this.dialog = this.value;
        },
        dialog() {
            this.$emit('input', this.dialog);
        },
    },
    methods: {
        ...mapActions(['setAlertModal']),
        ...mapActions('dashboard', ['setUpdateWalletsData']),
        ...mapActions('kyc', ['__middlewareKYC']),
        ...mapActions('initialization', ['__initialize']),
        ...mapActions('dashboard', ['setAllWallets']),
        calcTo() {
            this.formData.toAmount =
                this.conversion && this.formData.fromAmount
                    ? (this.formData.fromAmount * this.conversion).toFixed(2)
                    : '';
            if (this.formData.fromAmount) this.formData.fromAmount = parseFloat(this.formData.fromAmount).toFixed(2);
        },

        checkInputVal(e) {
            if (
                !(48 <= e.keyCode && 57 >= e.keyCode) &&
                !(96 <= e.keyCode && 105 >= e.keyCode) &&
                ![110, 8, 188, 190].includes(e.keyCode)
            )
                e.preventDefault();
        },

        calcFrom() {
            this.formData.fromAmount =
                this.conversion && this.formData.toAmount ? (this.formData.toAmount / this.conversion).toFixed(2) : '';
            if (this.formData.toAmount) this.formData.toAmount = parseFloat(this.formData.toAmount).toFixed(2);
        },
        getConversion() {
            if (this.fromWallet && this.toWallet) {
                const from = this.fromWallet.currency.code;
                const to = this.toWallet.currency.code;
                this.conversionLoading = true;
                window.axios
                    .get(`/payments/wallet-to-wallet/conversion`, {
                        params: { from, to },
                    })
                    .then(response => {
                        this.conversion = response.data;
                        this.conversionLoading = false;
                    })
                    .catch(error => {
                        this.conversionLoading = false;
                        this.$store.commit('setAlert', { type: 'error', message: error.response.data.message });
                    });
            }
        },
        transfer() {
            this.$validator.validateAll().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.transactionObj.values.from = this.formData.fromWalletId;
                    this.transactionObj.values.to = this.formData.toWalletId;
                    this.transactionObj.values.amount = this.formData.fromAmount;
                    this.transactionObj
                        .create(`/payments/wallet-to-wallet`)
                        .then(() => {
                            this.loading = false;
                            this.dialog = false;
                            this.$emit('converted');
                            this.$store.dispatch('dashboard/getWalletTransactions');
                            this.setUpdateWalletsData(true);
                        })
                        .catch(error => {
                          this.loading = false;
                          if (error?.response?.status !== 429) {

                            this.setAlertModal({
                              type: 'error',
                              data: {title: 'Error', text: error.response.data.message},
                            });
                          }
                        });
                }
            });
        },
        setSelectedWallet() {
            if (this.selectedWallet && this.walletsList) {
                const selectedWalletId = this.walletsList.find(w => w.id === this.selectedWallet.id)?.id;
                if (selectedWalletId) {
                    this.formData.fromWalletId = selectedWalletId;
                    this.formData.toWalletId =
                        this.walletsList.find(
                            w => w.id !== selectedWalletId && this.findWalletCodes.includes(w.currency.code),
                        )?.id || null;
                }
            }
        },
        setSelectedWalletFromMetric() {
            const selectedWallet = this.walletsList.find(w => w.currency.code === this.getMetricCurrency);
            if (selectedWallet) {
                this.formData.fromWalletId = selectedWallet.id;
            }

            this.formData.toWalletId =
                this.walletsList.find(
                    w => w.currency.code !== this.getMetricCurrency && this.findWalletCodes.includes(w.currency.code),
                )?.id || null;
        },
        detectQueryParam() {
            const url = new URL(window.location);
            const action = url.searchParams.get('quickAction');
            if ('convert' === action) {
                this.__middlewareKYC({
                    callback: () => {
                        this.dialog = true;
                        this.setSelectedWallet();
                        this.handleFromWalletChange();
                    },
                });
            }
        },
        handleFromWalletChange(walletType) {
            if (!this.formData.fromWalletId) {
                const eurWallet = this.walletsList.find(w => 'EUR' === w.currency.code);
                const usdWallet = this.walletsList.find(w => 'USD' === w.currency.code);
                this.formData.fromWalletId = eurWallet ? eurWallet.id : null;
                this.formData.toWalletId = usdWallet ? usdWallet.id : null;
            } else if (this.checkIfWalletTheSame) {
                const newWallet = this.walletsList.find(
                    w => w.id !== this.formData.fromWalletId && this.findWalletCodes.includes(w.currency.code),
                );
                setTimeout(() => {
                    if ('from' === walletType) {
                        this.formData.toWalletId = newWallet ? newWallet.id : null;
                    } else {
                        this.formData.fromWalletId = newWallet ? newWallet.id : null;
                    }
                }, 100);
            }
        },
        async getData() {
            if (!this.$store.state.dashboard.allWallets.length){
                return await this.__initialize({
                    url: `/initialization`,
                    resources: ['AllWallets'],
                    loader: false,
                }).then(response => {
                    this.setAllWallets(this.returnResourceByName(response.data, 'AllWallets'));
                });
            }
        },
    },
    mounted() {
        this.detectQueryParam();
        this.setSelectedWallet();
        if (!this.allowedWallets.length) this.getData();
    },
};
</script>
<style lang="scss">
.convert-currency-content {
    display: flex;
    flex-direction: column;
}

.currency-rate-value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    padding: 16px 8px;
}

.select-currency {
    display: flex;
    padding: 20px 20px 20px 32px;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    align-self: stretch;
    border-radius: 34px;
    border: 1px solid #d9d9d9;

    &.error {
        background-color: transparent !important;
        border-color: var(--feedback-error-color);
    }
}

.select-currency-menu {
    min-width: 100px !important;
}

.custom-selection-item,
.custom-selected-item {
    display: flex;
    align-items: center;
}

.formatted__input {
    width: 200px;
    .custom-label {
        color: #838a90;
    }

    input {
        font-size: 32px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    .formatted-input {
        font-size: 32px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        border: 0 !important;
        border-radius: 0 !important;
        padding: 0;
        &::placeholder {
            font-size: 32px !important;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            color: var(--typography-dark-grey-color) !important;
        }
    }
}

.clean-input.select-currency__input {
    width: 120px;
}

.v-menu__content.select-currency-menu {
    border-radius: 20px;
    background-color: white;
    padding: 12px;

    .v-list {
        padding: 22px 12px;
        border-radius: 24px;
        background: #f2f5fa;

        .v-list-item {
            color: #838a90 !important;

            span,
            p {
                transition: color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
            }

            &.v-list-item--active,
            &:hover {
                border-radius: 18px !important;
                background: #2f3094;
                color: white !important;

                &::before {
                    border-radius: 18px !important;
                }

                span,
                p {
                    color: white !important;
                }
            }
        }
    }
}

.new-wallet-app .modal-convert-currency {
    border-radius: 1.87rem;
    padding: 8px;

    .v-input--hide-details {
        border: none !important;

        &:hover,
        &:focus {
            border: none !important;
        }
    }

    .v-input__slot::before {
        border: none !important;
    }

    .modal-convert-currency-header {
        flex-direction: column;

        .heading-1 {
            font-size: 42px !important;
            line-height: 42px !important;
            font-weight: 700 !important;
        }
    }

    .btn-close-modal {
        border: none !important;
        box-shadow: none !important;
        min-width: 0 !important;
        width: 2.28rem;

        i {
            font-size: 16px !important;
        }
    }

    .convert-currency-content {
        .select-currency {
            .modal-convert-currency-formatted-input {
                .formatted-input {
                    border: 0 !important;
                    font-weight: 500;
                }
            }

            .color-black.custom-label {
                margin-bottom: 20px;
                font-weight: 500;
            }
        }
    }

    .mb-28 {
        margin-bottom: 28px !important;
    }

    .v-input__control {
        .text-lg.color-black {
            font-size: 18px !important;
        }

        .icon-chevron-down {
            font-size: 16px !important;
            line-height: 30px !important;
        }
    }

    .modal-footer {
        button {
            width: 100% !important;
            border-radius: 50px !important;
            text-transform: none !important;
            background-color: var(--main-purple-color) !important;
            color: white !important;
            box-shadow: none !important;
            letter-spacing: 1px !important;

            &:disabled {
                background-color: #cdcfd2 !important;
                color: white !important;
            }
        }
    }

    .select-currency.error {
        background-color: transparent !important;
    }
}
</style>
