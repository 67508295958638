<template>
    <div v-if="accountsConnectedInitedAndLoaded" class="d-block mt-2">
        <template v-if="accountsConnected.length">
            <div v-for="(item, index) in accountsConnected" :key="index">
                <div class="mb-6" :class="addAccoutnsClass">
                    <p class="normal-text-2 bold-text color-dark-grey">
                        Account ID:
                        <span class="normal-text-2">{{item.token_model.account_id}}</span>

                        <template v-if="needUpdateToken(item)">
                            <a @click="payneerRedirect({url: '/payoneer/update-token'})" class="btn btn-connect-payoneer btn-purple small-text bold color-white m-auto d-inline-flex">Update Token</a>
                        </template>
                    </p>
                    <p class="normal-text-2 bold-text color-dark-grey" v-for="(item_program, program_index) in item.programs" :key="program_index">
                        {{ item_program.program_model.currency }}: <span class="normal-text-2"><status-icon :payee-status="item_program.program"></status-icon> {{ item_program.program.status.description }}</span>
                    </p>
                </div>
            </div>
        </template>
        <template v-else>
            <slot name="not-connected-text">
                <span class="small-text color-dark-grey-3 lh-18 d-block">
                    You can connect your Payoneer account and send money
                    to your wallet.
                </span>
            </slot>
            <div class="pb-1">
                <a
                    :class="addConnectionBtnClass"
                    @click="payneerRedirect({url: '/payoneer/connect/existing'})" class="btn btn-outline-purple color-purple small-text bold-text mb-4 mt-4">
                    Connect Now
                </a>
                <p class="medium-text">
                    Don't have account?
                    <a @click="payneerRedirect({url: '/payoneer/connect/new', blank: true})" class="color-purple bold-text">Sign Up</a>
                </p>
            </div>
        </template>
    </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';
import StatusIcon from "~/modules/dashboard/components/payment/_payoneer/StatusIcon.vue";
export default {
    name: 'PayoneerNotReady',
    components: {StatusIcon},
    mixins: [],
    props: {
        addConnectionBtnClass : {
            type: String,
        },
        addAccoutnsClass: {
            type: String,
        },
    },
    computed: {
        ...mapGetters({
          enabled: "payoneer/enabled",

          currencies: "payoneer/currencies",

          accountsConnectedInitedAndLoaded: "payoneer/accountsConnectedInitedAndLoaded",

          accountsConnected: "payoneer/accountsConnected",
          needUpdateToken: "payoneer/needUpdateToken",
          getBalanceFlagUrl: "payoneer/getBalanceFlagUrl",
      }),
    },
    methods: {
        ...mapActions({
            payneerRedirect: "payoneer/payneerRedirect",
        }),
    },
    mounted(){

    }
}
</script>
