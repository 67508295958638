<template>
	<div>
		<div v-if="!__isDesktop" class="mobile-navigation-container">
			<div class="mobile-navigation-bar w-100 m-0 justify-content-end">
				<BaseButton @click.stop="toggle" class="btn-icon btn-mobile-menu-toggler color-grey-2">
					<BaseIcon name="hamburger"/>
				</BaseButton>
				<p class="mobile-page-title d-flex text-base font-medium color-black">
					{{ mobileTitle }}
				</p>
				<div>
					<BaseButton @click="$emit('close')" class="btn-icon btn-mobile-menu-toggler color-grey-2">
						<BaseIcon name="times"/>
					</BaseButton>
				</div>
			</div>
		</div>
		<nav class="col-12 nav-wizard-steps-container" :class="addClass">
			<div class="nav-wizard-logo-title-container" style="align-items: center;justify-content: flex-start;">
                <router-link :to="$store.state.path">
                    <img
                        :src="$store.state.wlstyle?.st_logo_black || '/images/logos/logo-black.svg'"
                        alt="logo-black.svg"
                        class="logo-img"
                    />
                </router-link>
				<p class="text-sm font-medium color-grey-2">{{ title }}</p>
			</div>
			<ul class="wizard-steps-list self-serve-wizard-steps-list">
				<NavbarWizardStep
					v-for="(step, index) in steps"
					:key="index"
					:step="step"
					:number="index"
					:currentStep="currentStep"
				/>
			</ul>
			<div class="wizard-navbar-action ml-auto mr-3">
				<template v-if="isLoggedIn && !$slots['default']">
					<button v-if="closeButton === 'x-button'" @click="$emit('close')"
							class="btn btn-icon btn-p-0 color-dark-grey ml-auto" type="button" aria-label="Close">
						<i class="icon-times"></i>
					</button>
					
					<BaseButton v-else @click="$emit('close')" class="btn-big custom-btn-secondary">
						Close
					</BaseButton>
				</template>
				<slot v-else></slot>
			</div>
		</nav>
	</div>
</template>

<script>
import NavbarWizardStep from "~/shared/components/NavbarWizard/NavbarWizardStep";
import Screen from '~/shared/mixins/Screen';
import {mapActions} from "vuex";

export default {
	name: "BaseWizardNavbar",
	
	components: {
		NavbarWizardStep,
	},
	mixins: [Screen],
	
	props: {
		mobileTitle: {
			type: String,
			default: 'EOR Application',
		},
		steps: {
			type: Array,
			required: true,
		},
		currentStep: {
			type: Number,
			required: true,
		},
		title: {
			type: String,
			required: "",
		},
		closeButton: {
			type: String,
			default: '',
		},
		buttonText: {
			type: String,
			default: "Close",
		},
		isLoggedIn: {
			type: Boolean,
			default: false,
		},
		addClass: {
			type: String,
			default: "",
		},
	},
	methods: {
		...mapActions('sidebar', ['toggle']),
	},
};
</script>
