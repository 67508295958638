<template>
  <v-expansion-panels v-bind="$attrs" class="custom-panels onboarding-panels">
    <template v-for="(item, index) in items">
      <v-expansion-panel :key="index">
        <v-expansion-panel-header>
          <div class="onboarding-panel-header">
            <div class="checked-icon-wrapper mr-2">
              <BaseIcon
                name="circle-outline"
                class="color-grey-3"
                @click="(item.checked = true), complete()"
                v-if="!item.checked"
              />
              <BaseIcon
                v-if="item.checked"
                name="check-circle-outline"
                class="color-feedback-success"
              />
            </div>
            <div class="onboarding-panel-title">
              <h2 class="heading-2 mb-1">{{ item.text }}</h2>
              <p class="text-base color-grey-2">{{ item.message }}</p>
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <slot :name="item.value"></slot>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </template>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "BasePanelsOnBoarding",

  props: {
    items: {
      type: Array,
      default: () => {},
    },
  },

  methods: {
    complete() {
      this.$emit("onboardingProgressCalculation");
    },
  },

  computed: {
    isExpanded() {
      return this.expanded;
    },
  },
};
</script>
