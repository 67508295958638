<template>
    <ul class="policy-container">
        <li>
            <a class="text-base color-dark-grey" target="_blank" :href="`${brochureUrl}cookie`"> Cookie policy </a>
        </li>
        <li>
            <a class="text-base color-dark-grey" target="_blank" :href="`${brochureUrl}privacy`">Privacy policy</a>
        </li>
        <li>
            <a class="text-base color-dark-grey" target="_blank" :href="`${brochureUrl}terms`">Terms of service</a>
        </li>
    </ul>
</template>

<script>
export default {
    name: "PolicyContainer",
    data() {
        return {
            brochureUrl: null,
        }
    },
    created() {
        this.brochureUrl = this.$store.state.brochureUrl;
    }
};
</script>

<style scoped lang="scss">
.policy-container {
    display: flex;
    margin: auto auto 4.25rem;

    li {
        position: relative;
        padding: 0.625rem;

        &:not(:last-child) {
            margin-right: 2.25rem;

            &::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 20px;
                background-color: var(--typography-dark-grey-color);
                top: 50%;
                right: -1.125rem;
                transform: translate(0, -50%);
            }
        }
    }
}

@media screen and (max-width: 992px) {

    li {
        padding: 0.625rem 0.5rem !important;

        &:not(:last-child) {
            margin-right: 1rem !important;

            &::after {
                right: -0.5rem !important;
            }
        }

        a {
            font-size: 0.75rem !important;
        }
    }
}
</style>
