import { render, staticRenderFns } from "./KYCSumsub.vue?vue&type=template&id=719d5d5d&scoped=true"
import script from "./KYCSumsub.vue?vue&type=script&lang=js"
export * from "./KYCSumsub.vue?vue&type=script&lang=js"
import style0 from "./KYCSumsub.vue?vue&type=style&index=0&id=719d5d5d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "719d5d5d",
  null
  
)

export default component.exports