<template>
    <v-menu
        :attach="true"
        :close-on-content-click="false"
        :nudge-bottom="18"
        offset-y
        :value="$store.state.globalMenuModule.profileMenuValue"
        @input="$store.commit('globalMenuModule/TOGGLE_PROFILE_MENU', $event)"
        activator=".btn-profile-menu"
        :min-width="isMobile ? '100%' : 0"
        content-class="profile-menu"
    >
        <div class="profile-menu-container">
            <div class="content-container">
                <v-slide-x-transition hide-on-leave>
                    <Main
                        :worker="worker"
                        v-if="currentView === 'main' || !currentViewComponent"
                        @settings="setCurrentView('settings')"
                    />
                </v-slide-x-transition>

                <!-- SELECTED VIEW -->
                <v-slide-x-reverse-transition hide-on-leave>
                    <component
                        :is="currentViewComponent"
                        @back="setCurrentView('main')"
                    ></component>
                </v-slide-x-reverse-transition>
            </div>
        </div>
    </v-menu>
</template>

<script>
import NavListItem from "~/components/NewNavigation/NavListItem.vue";
import NavListGroup from "~/components/NewNavigation/NavListGroup.vue";
import Settings from "~/components/NewNavigation/ProfileMenuViews/Settings.vue";
import Main from "~/components/NewNavigation/ProfileMenuViews/Main.vue";
import {commonHelpersMixin} from "~/shared/mixins/commonHelpersMixin";

export default {
    name: "ProfileMenu",

    components: {Main, Settings, NavListGroup, NavListItem},
    mixins: [commonHelpersMixin],
    props: {
        worker: {
            default: null,
        },
    },
    data() {
        return {
            currentView: "main",
        };
    },

    computed: {
        currentViewComponent() {
            switch (this.currentView) {
                case "settings":
                    return Settings;
                default:
                    return null;
            }
        },
        menu() {
            return this.$store.state.globalMenuModule.profileMenuValue;
        }
    },

    methods: {
        setCurrentView(view) {
            this.currentView = view;
        },
    },

    watch: {
        menu(val) {
            if (val) {
                this.$emit("profile-menu-toggled", true);
            } else {
                this.$emit("profile-menu-toggled", false);
            }

            if (val && this.currentView !== "main") this.currentView = "main";
        },
    },
};
</script>

<style scoped>
.profile-menu {
    background-color: unset !important;
    box-shadow: unset !important;
    overflow: unset !important;
    contain: unset !important;
    left: unset !important;
    right: 0 !important;
    max-width: 24.688rem;
    width: 100%;

    .profile-menu-container {
        margin-bottom: 1rem;
        border-radius: 30px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
        display: flex;
        padding: 1.875rem;
        background: white;

        .content-container {
            width: 100%;
            padding: 1.5rem;
            border-radius: 24px;
            background: #f2f5fa;
        }
    }
}
</style>
