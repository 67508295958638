<!-- @format -->

<template>
    <modal
        v-model="dialog"
        :class-names="classNames"
        :width="width"
        :position="position"
        :close-icon-show="false"
        :close-small="true"
        close-color="#8F8F8F"
        :padding="false"
        :close-top="closeTop"
    >
        <template slot="content">
            <div class="modal-header d-flex pt-3 align-center pa-5">
                <slot v-if="$slots.title" name="title"></slot>
                <span v-else class="tm-normal-text tm-color-black bold-text tm-initial fs-24">{{ title }}</span>
                <button class="btn btn-icon color-black ml-auto p-0" @click="dialog = false">
                    <i class="icon-times"></i>
                </button>
            </div>

            <div class="pa-5">
                <slot v-if="$slots.text" name="text"></slot>
                <p v-else class="tm-paragraph tm-color-black mb-22" style="white-space: pre-line">{{ textModal }}</p>
                <div class="d-flex justify-center">
                    <btn
                        :loading="loading"
                        :text="confirmText"
                        :color="archive ? 'white' : deleteButtonColor"
                        :class="[btnSpace ? btnSpace : '', archive ? 'btn-purple' : 'btn-outline-red']"
                        :btn-outlined="true"
                        class="btn w-100 p-0"
                        @click="$emit('confirmed')"
                    >
                    </btn>
                    <btn
                        v-if="showCancelButton"
                        :small="true"
                        :btn-text="true"
                        :text="cancelText"
                        color="dark-grey"
                        @click="dialog = false"
                    >
                    </btn>
                </div>
            </div>
        </template>
    </modal>
</template>
<script>
import Btn from '~/shared/components/Btn';
import Modal from '~/shared/components/Modal';
export default {
    name: 'BaseConfirmModal',
    components: { Btn, Modal },
    props: {
        value: {
            required: true,
        },
        title: {
            default: 'Delete?',
        },
        position: {
            default: 'center', //start/end
        },
        text: {
            default: 'Are you sure?',
        },
        classNames: '',
        deleteButtonColor: {
            default: 'red', //orange
        },
        confirmText: {
            default: 'delete',
        },
        cancelText: {
            default: 'cancel',
        },
        showCancelButton: {
            default: false,
        },
        loading: {
            default: false,
        },
        closeIconShow: {
            default: true,
        },
        btnSpace: {
            default: false,
        },
        width: {
            default: 400,
        },
        archive: {
            default: false,
        },
        closeTop: {
            default: 20,
        },
    },
    data() {
        return {
            dialog: !!this.value,
        };
    },
    computed: {
        textModal() {
            return this.text;
        },
    },
    watch: {
        value() {
            this.dialog = !!this.value;
        },
        dialog() {
            this.$emit('input', this.dialog);
        },
    },
};
</script>
