import Middleware from "~/routes/middleware/Middleware";
import store from "~/store";
import StyleSystem from "~/plugins/StyleSystem";

export default class importStyles extends Middleware{
    constructor(...args) {
        super(...args);
    }

    resolve() {
        const system = new StyleSystem();
        const currentType = store.state.styleSystem;
        const layout = this.to?.meta?.app;
        const newType = system.typeByLayout(layout);

        if (newType !== currentType) {
            store.commit('setData', {key: 'styleSystem', data: newType})
            system.import(layout);
        }
    }
}
