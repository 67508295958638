import Middleware from "~/routes/middleware/Middleware";
import store from "~/store";

export default class guest extends Middleware{
    constructor(...args) {
        super(...args);
    }

    async resolve() {
        const isAuthorized = store.getters['auth/__isAuthResolved'];
        const isTfaAuthorized = store.getters['auth/__isTfaResolved'];
        if (isAuthorized && isTfaAuthorized) {
            return this.responseNext();
        } else if (isAuthorized && !isTfaAuthorized && store.state.auth.tfaType) {
            return this.responseRedirect(await store.dispatch('auth/__tfaFullPath'));
        } else {
            await store.dispatch('auth/__unAuthorize')
            store.commit('auth/SET_REQUESTED_PATH', this.to.fullPath);
            return this.responseRedirect(store.state.auth.loginPath);
        }
    }
}
