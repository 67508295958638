<template>
    <v-row style="margin:0 !important">
        <v-col cols="12" lg="2" class="modal-onboarding-progress step-2-active">
            <img :src="$store.state.imagePath+'logo-tilted-o-5-big.png'" alt="logo-tilted-o-5-big.png"/>
        </v-col>
        <v-col cols="12" lg="10" class="modal-onboarding-content">
            <div class="modal-header">
                <h2 class="heading-2 bold-text color-black mb-10 mt-10">
                    Welcome to
                    <span class="strong-text color-purple">Native Teams!</span>
                </h2>
                <p class="normal-text color-dark-grey">
                    Let us know about yourself, and we’ll personalise your Native Teams experience to suit your needs perfectly.
                </p>
            </div>
            <div class="modal-body">
                <div class="why-step onboarding-modal-step step-2 active">
                    <form class="onboarding-form">
                        <div class="form-group">
                            <label class="medium-text color-dark-grey mb-3">2. WHY ARE YOU HERE?</label>
                            <div class="flex-form-group mt-1" style="align-items: start!important;">
                                <h2 class="heading-2 light-weight color-black" style="margin-top:5px;">
                                    I'd like to use Native Teams for
                                </h2>
                                <div class="welcome-step-2-fix">
                                    <tm-advanced-select
                                        ref="selectPurposes"
                                        name="purposes"
                                        v-model="greetingObj.values.purposes"
                                        item-value="id"
                                        item-text="name"
                                        :items="purposes"
                                        :multiple="true"
                                        :chips="true"
                                        :closeAfterClick="false"
                                        :chips-clear="true"
                                        :chips-clear-left="true"
                                        v-validate="greetingObj.rules.purposes_id"
                                        :error-messages="errors.collect('purposes')"
                                        class="transparent-checkbox-select"
                                        :bottomOffset="false"
                                        data-vv-as="purposes"
                                        :show-empty="false"
                                        :pushMenuDownFor="pushMenuDownValue"
                                        @input="onChange($event)"
                                    >
                                        <template v-slot:item="{ item }">
                                            <div class="d-flex align-center">
                                                <span class="tm-color-black mr-1">
                                                    {{ item.name }}
                                                </span>
                                            </div>
                                        </template>
                                    </tm-advanced-select>
                                    <tm-input
                                        v-if="otherInput"
                                        name="other_text"
                                        v-validate="greetingObj.rules.other_text"
                                        placeholder="Tell us more and press enter to embark on a tailored journey"
                                        v-model="greetingObj.values.other_text"
                                        :error-messages="errors.collect('other_text')"
                                        class="other-input"
                                    ></tm-input>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer d-flex justify-space-between pr-0 pl-0" style="margin-right:-10px;">
                <button @click="$emit('back')" class="btn btn-onboarding-back btn-outline-standard medium-text bold-text tm-color-dark-grey">
                    Go back
                </button>
                <div class="d-flex">
                    <button
                            @click="$emit('skip')"
                            class="btn btn-onboarding-skip btn-outline-purple medium-text bold-text color-purple mr-10">
                        Skip
                    </button>
                    <button
                            :disabled="!isValid"
                            @click="$emit('next')"
                            class="btn btn-onboarding-skip btn-purple medium-text bold-text color-white">
                        Next
                    </button>
                </div>
            </div>
        </v-col>
    </v-row>
</template>
<script>
import Modal from "~/shared/components/Modal";
import TmAdvancedSelect from "~/shared/components/form/TmAdvancedSelect";
import Btn from "~/shared/components/Btn";
import TmInput from "~/shared/components/form/TmInput";
export default {
    name: 'BasicInfo',
    components: {Btn, TmAdvancedSelect, TmInput, Modal},
    props: {
        purposes: {
            required: true,
            type: Array
        },
        greetingObj: {
            required: true,
            type: Object
        },
    },
    data(){
        return {
            otherInput: false,
            pushMenuDownValue: 25,
            onChange(e) {
                if(e.indexOf(8) !== -1){
                    this.otherInput = true;
                    this.pushMenuDownValue = 40;
                }else{
                    this.otherInput = false;
                    this.pushMenuDownValue = 25;
                }
            }
        }
    },
    watch : {
        'greetingObj.values.purposes' (v) {
            if(v.length === this.purposes.length) {
                this.$refs['selectPurposes'].menu = false;
            }
        }
    },
    computed: {
        isValid() {
            return !Object.keys(this.fields).some(key => this.fields[key].invalid)
        }
    },
}
</script>
