<!-- @format -->

<template>
    <div v-show="isActive" class="tab" :class="{ active: isActive }">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'BaseTab',

    props: {
        /* Can be added more settings */
        dataObject: {
            type: Object,
            default: () => {},
        },
        title: {
            type: String,
            default: null,
        },
        value: {
            type: String || Number || undefined,
            default: undefined,
        },
    },

    data() {
        return {
            isActive: true,
            disabled: this.dataObject && this.dataObject.disabled,
            localValue: 'undefined' === typeof this.value && this.dataObject ? this.dataObject.value : this.value,

        };
    },
    computed: {
        localTitle() {
            return this.title || this.dataObject?.title || 'Tab'
        },
    }
};
</script>
