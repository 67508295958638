<template>
  <v-dialog
    :value="dialog"
    @input="$emit('input', $event)"
    content-class="modal-xxl"
  >
    <div class="modal-container custom-modal modal-preview-file">
      <div class="modal-header mb-5">
        <h2 class="heading-2 text-ellipsis font-medium color-black">
          Preview {{ file.name }}
        </h2>
        <WlBaseButton class="btn-icon text-lg color-grey-2" @click="closeModal">
          <WlBaseIcon name="times" />
        </WlBaseButton>
      </div>
      <div class="modal-body">
        <div class="preview-file-box">
          <img
            :src="`/images/ui-elements/upload-image.png`"
            alt="test element"
          />
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalPreviewFile",

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    file: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
  },
};
</script>
