/** @format */

import { addDays, addMonths, eachDayOfInterval, endOfMonth, startOfMonth, subDays, subMonths } from 'date-fns';

// 0 = Sunday, 1 = Monday, 2 = Tuesday, 3 = Wednesday, 4 = Thursday, 5 = Friday, 6 = Saturday

// GET TODAY
export const getToday = () => new Date();

// GET CURRENT MONTH DATES
export const currentMonthDates = () => {
    const start = startOfMonth(new Date());
    // const firstDay = start.getDay();
    // console.log(firstDay);
    const end = endOfMonth(new Date());
    const days = eachDayOfInterval({ start, end });
    return days;
};

// GET SELECTED MONTH DATES
export const getSelectedMonthDates = month => {
    const start = startOfMonth(month);
    const firstDay = getFirstDay(month);
    // console.log("firstDay", firstDay);
    const end = endOfMonth(month);
    // console.log("month", month);
    const currentMonthDates = eachDayOfInterval({ start, end });
    // console.log("currentMonthDates", month, firstDay    );
    const previousMonthDates = getPreviousMonthDates(month, firstDay);
    // console.log("previousMonthDates", previousMonthDates);
    const totalSumDays = previousMonthDates.length + currentMonthDates.length;
    const nextMonthDates = getNextMonthDates(month, totalSumDays);
    // console.log("nextMonthDates", nextMonthDates);
    // console.log([...previousMonthDates, ...currentMonthDates, ...nextMonthDates]);
    return [...previousMonthDates, ...currentMonthDates, ...nextMonthDates];
};

// GET PREVIOUS MONTH DATES
export const getPreviousMonthDates = (month, firstDay) => {
    // console.log("getPreviousMonthDates");
    const end = endOfMonth(subMonths(month, 1));
    // console.log("end", end);
    if (2 > firstDay) {
        return [];
    }
    const start = subDays(end, firstDay - 2);
    // console.log("start", start);
    const days = eachDayOfInterval({ start, end });
    return days;
};

// GET NEXT MONTH DATES
export const getNextMonthDates = (month, totalDays) => {
    // console.log("getNextMonthDates");
    const start = startOfMonth(addMonths(month, 1));
    // console.log("start", start);
    // console.log("totalDays", totalDays);
    let end;
    if (35 > totalDays) {
        end = addDays(start, 35 - totalDays - 1);
    } else {
        end = addDays(start, 42 - totalDays - 1);
    }
    // console.log("end", end);
    const days = eachDayOfInterval({ start, end });
    return days;
};

export const getCalendarRows = dates => {
    if (35 === dates.length) {
        return 5;
    }

    return 6;
};

const getFirstDay = month => {
    const start = startOfMonth(month);
    const firstDay = start.getDay();

    if (0 === firstDay) {
        return 7;
    }

    return firstDay;
};
