<template>
    <v-dialog
        :max-width="maxWidth"
        :width="modalWidth"
        :hide-overlay="hide || hideOverlay"
        :content-class="classes"
        :transition="side ? 'expand' : transition"
        v-model="dialog"
        v-bind="$attrs"
        persistent
    >
        <template v-slot:activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs"></slot>
        </template>
        <template v-if="$slots['default'] || $scopedSlots['default']">
            <slot :close="close"></slot>
        </template>
        <template v-else>
            <div class="modal-container" :class="containerClass">
                <div class="modal-header">
                    <template v-if="!$slots['header']">
                        <p class="text-xl font-medium">{{ title }}</p>
                        <BaseButton
                            @click="dialog = false"
                            class="btn-icon text-sm color-grey-2"
                        >
                            <BaseIcon class="color-dark-grey" name="times" :style="{ fontSize: closeIconFontSize }"/>
                        </BaseButton>
                    </template>
                    <template v-else>
                        <slot name="header"></slot>
                    </template>
                </div>
                <div class="modal-body" :class="bodyClass">
                    <slot name="body"></slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </template>
    </v-dialog>
</template>

<script>
export default {
    name: "BaseModal",

    props: {
        classNames: {
            default: null,
        },
        transition: {
            default: "dialog-transition",
        },
        hideable: {
            type: Boolean,
            default: true,
        },
        hideOverlay: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Boolean,
            default: false,
        },
        width: {
            default: "31.25rem",
        },
        maxWidth: {
            default: null,
        },
        title: {
            type: String,
            default: "",
        },
        side: {
            type: Boolean,
            default: false,
        },
        bodyClass: {
            type: String,
            default: '',
        },
        containerClass: {
            type: String,
            default: '',
        },
        hideBackgroundModals: {
            type: Boolean,
            default: true,
        },
        closeIconFontSize: {
            type: String,
            default: '1.25rem',
        },
        persistent: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            dialog: this.value,
            closedModals: [],
            hide: false,
        };
    },

    computed: {
        classes() {
            return [
                "base-modal",
                this.classNames,
                this.hide ? "d-none" : null,
                this.side ? "side-modal" : null,
            ].join(" ");
        },

        modalWidth() {
            return this.side ? "66.25rem" : this.width;
        },
    },

    watch: {
        value(val) {
            this.dialog = val;
            this.value ? this.closeModals(this.$root) : this.openModals(this.$root);
        },
        dialog() {
            this.$emit("input", this.dialog);
            if (!this.dialog) {
                this.$emit("close");
            }
        },
    },

    methods: {
        close() {
            this.dialog = false;
        },

        closeModals(obj) {
            if (
                obj.$options.name === "BaseModal" &&
                obj._uid !== this._uid &&
                obj.dialog &&
                !obj.hide &&
                obj.$props.hideable
            ) {
                this.closedModals.push(obj._uid);

                if (this.hideBackgroundModals) obj.hide = true;
            }
            obj.$children.forEach((item) => this.closeModals(item));
        },

        openModals(obj) {
            if (
                obj.$options.name === "BaseModal" &&
                this.closedModals.includes(obj._uid)
            ) {
                obj.hide = false;
                this.closedModals = this.closedModals.filter(
                    (item) => item !== obj._uid
                );
            }
            obj.$children.forEach((item) => this.openModals(item));
        },
    },

    created() {
        if (this.dialog) {
            this.closeModals(this.$root);
        }
    },
};
</script>
