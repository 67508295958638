<template>
    <div v-if="currentWorker" class="col-12 new-navigation-bar-class">
        <div class="navigation-bar-wrapper position-relative">
            <div class="new-navigation-bar">
                <router-link to="/">
                    <v-img

                        contain
                        src="/images/logos/logo-black.svg"
                        max-width="10.688rem"
                        class="logo-image"
                    />
                </router-link>
                <div class="navigation-buttons-container">

                    <BaseButton v-if="!showWalletSwitcher && isDesktop"
                                class="btn-secondary text-base btn-referrals"
                                @click="linkToAffileReferal"
                    >
                        {{ titleToAffileReferal() }}
                    </BaseButton>

                    <div
                        class="legal-entity-switcher"
                        v-if="showWalletSwitcher && !isSmallTablet  && !isProfileMenuOpen"
                    >
                        <WlBaseButton
                            @click="setActiveWallet('personal')"
                            :class="walletSwitcher === 'personal' ? 'btn-primary' : 'btn-ghost'"
                        >Personal wallet
                        </WlBaseButton>

                        <WlBaseButton
                            v-if="!nameSlice"
                            @click="setActiveWallet('legalEntity')"
                            :class="walletSwitcher === 'legalEntity' ? 'btn-primary' : 'btn-ghost'"
                        >{{ legalEntityData?.legal_entity_details?.company_name }}
                        </WlBaseButton>

                        <WlBaseTooltip
                            v-else
                            bottom
                            content-class="new-wallet-tooltip-content legal-entity-tooltip"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <WlBaseButton
                                    @click="setActiveWallet('legalEntity')"
                                    v-on="on"
                                    v-bind="attrs"
                                    :class="walletSwitcher === 'legalEntity' ? 'btn-primary' : 'btn-ghost'"
                                >{{ nameSlice }}
                                </WlBaseButton
                                >
                            </template>
                            <p class="color-grey-1">
                                {{ legalEntityData?.legal_entity_details?.company_name }}
                            </p>
                        </WlBaseTooltip>
                    </div>
                    <BaseButton class="btn-header btn-profile-menu">
                        <BaseIcon name="user-outline-circle-thin"/>
                    </BaseButton>
                    <BaseButton class="btn-header btn-notifications-menu">
                        <!--            <i class="icon-notification" :class="{ 'color-feedback-warning': hasUnreadNotifications, 'animation-ringing-bell': hasUnreadNotifications }" />-->
                        <i class="icon-notification"/>
                        <div
                            class="notification-badge"
                            v-if="hasUnreadNotifications"
                        ></div>
                    </BaseButton>
                    <BaseButton class="btn-header btn-modules-menu">
                        <BaseIcon name="products-2"/>
                    </BaseButton>
                </div>
                <div v-if="legalEntityIsActive && isSmallTablet && !isProfileMenuOpen" class="empty-row"></div>
                <div
                    class="legal-entity-switcher"
                    v-if="legalEntityIsActive && isSmallTablet  && !isProfileMenuOpen"
                >
                    <BaseButton
                        @click="setActiveWallet('personal')"
                        :class="walletSwitcher === 'personal' ? 'btn-primary' : 'btn-ghost'"
                    >
                        Personal wallet
                    </BaseButton>

                    <BaseButton
                        v-if="!nameSlice"
                        @click="setActiveWallet('legalEntity')"
                        :class="walletSwitcher === 'legalEntity' ? 'btn-primary' : 'btn-ghost'"
                    >{{ legalEntityData?.legal_entity_details?.company_name }}
                    </BaseButton>

                    <BaseTooltip
                        v-else
                        bottom
                        content-class="new-wallet-tooltip-content legal-entity-tooltip"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <BaseButton
                                @click="setActiveWallet('legalEntity')"
                                v-on="on"
                                v-bind="attrs"
                                :class="walletSwitcher === 'legalEntity' ? 'btn-primary' : 'btn-ghost'"
                            >{{ nameSlice }}
                            </BaseButton>
                        </template>
                        <p class="color-grey-1">
                            {{ legalEntityData?.legal_entity_details?.company_name }}
                        </p>
                    </BaseTooltip>
                </div>
            </div>

            <ModulesMenu :worker="currentWorker"/>
            <ProfileMenu :worker="currentWorker" @profile-menu-toggled="profileMenuToggled"/>
            <NotificationsMenu :worker="currentWorker"/>
        </div>
    </div>
</template>

<script>
import ModulesMenu from "~/components/NewNavigation/ModulesMenu.vue";
import ProfileMenu from "~/components/NewNavigation/ProfileMenu.vue";
import NotificationsMenu from "~/components/NewNavigation/NotificationsMenu.vue";
import {mapActions, mapState} from "vuex";
import {mapGetters} from "vuex/dist/vuex.common.js";
import Helpers from '~/shared/mixins/Helpers';
import {commonHelpersMixin} from "~/shared/mixins/commonHelpersMixin";
import SoloEntitySwitcher from "~/shared/mixins/SoloEntity/SoloEntitySwitcher";

export default {
    name: "NewNavigation",
    mixins: [Helpers, commonHelpersMixin, SoloEntitySwitcher],
    components: {ProfileMenu, ModulesMenu, NotificationsMenu},

    data() {
        return {
            multiResources: [],
            isProfileMenuOpen: false,
        };
    },
    computed: {
        ...mapState('globalMenuModule', ['legalEntityIsActive', 'legalEntityEnabled', 'legalEntityData']),//only one initialization is needed - done in App.vue
        ...mapGetters('notifications', {hasUnreadNotifications: 'hasUnreadNotifications'}),
        ...mapGetters('dashboard', ['walletSwitcher']),
        ...mapGetters("global", ["isLegalEntity", "companyName"]),
	
		currentWorker(){
			return this.$store.state.worker;
		},
        accessAffiliates() {
            return this.$store.state.accessAffiliates;
        },

        entitySwitch: {
            get() {
                return this.$store.getters["global/entitySwitch"];
            },
            set(value) {
                this.$store.dispatch("global/setEntitySwitch", value);
            },
        },

        showWalletSwitcher() {
            if (!["/wallet", "/"].includes(this.$route.path)) return false;
            return this.legalEntityIsActive;
        }
    },
    methods: {
        profileMenuToggled(value) {
            this.isProfileMenuOpen = value;
        },

        linkToAffileReferal() {
            let url = this.accessAffiliates ? '/affiliates' : '/referrals';
            this.__changeRoute(url)
        },
        titleToAffileReferal() {
            return this.accessAffiliates ? 'Affiliates' : 'Referrals';
        },
    },
    created() {
        if (this.$route.query["legal-entity"] === "true") {
            this.$store.dispatch("global/setLegalEntity", true);
        }
    },

};
</script>

<style scoped lang="scss">
.navigation-bar-wrapper {
    //pointer-events: none !important;
}

.new-navigation-bar {
    position: relative;
    display: flex;
    align-items: center;

    button {
        display: inline-flex !important;
    }

    .btn-referrals {
        padding: 10px 16px !important;
    }

    .btn-referrals {
        margin-right: 0.75rem;
    }

    .navigation-buttons-container {
        margin-left: auto;

        & > *:not(:last-of-type) {
            margin-right: 0.75rem;
        }
    }

    @media (max-width: 640px) {
        .logo-image {
            max-width: 8.4375rem !important;
        }

        .btn-referrals {
            display: none;
        }
    }

    .legal-entity-switcher {
        display: inline-flex;
        align-self: center;
        gap: 4px;
        border: 1px solid #5152fb;
        border-radius: 16px;
        padding: 2px;
        margin-right: 22px;
        max-height: 40px;
        height: 40px;

        button {
            width: 180px;
            max-width: 180px;
            font-size: 16px !important;
            border-radius: 16px !important;
            height: auto !important;

            &.btn-ghost {
                font-weight: 400 !important;
                color: #5b5c5e !important;
            }
        }
    }
}

.animation-ringing-bell {
    -webkit-animation: ring 4s .7s ease-in-out infinite;
    -webkit-transform-origin: 50% 4px;
    -moz-animation: ring 4s .7s ease-in-out infinite;
    -moz-transform-origin: 50% 4px;
    animation: ring 4s .7s ease-in-out infinite;
    transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
    0% {
        -webkit-transform: rotateZ(0);
    }
    1% {
        -webkit-transform: rotateZ(30deg);
    }
    3% {
        -webkit-transform: rotateZ(-28deg);
    }
    5% {
        -webkit-transform: rotateZ(34deg);
    }
    7% {
        -webkit-transform: rotateZ(-32deg);
    }
    9% {
        -webkit-transform: rotateZ(30deg);
    }
    11% {
        -webkit-transform: rotateZ(-28deg);
    }
    13% {
        -webkit-transform: rotateZ(26deg);
    }
    15% {
        -webkit-transform: rotateZ(-24deg);
    }
    17% {
        -webkit-transform: rotateZ(22deg);
    }
    19% {
        -webkit-transform: rotateZ(-20deg);
    }
    21% {
        -webkit-transform: rotateZ(18deg);
    }
    23% {
        -webkit-transform: rotateZ(-16deg);
    }
    25% {
        -webkit-transform: rotateZ(14deg);
    }
    27% {
        -webkit-transform: rotateZ(-12deg);
    }
    29% {
        -webkit-transform: rotateZ(10deg);
    }
    31% {
        -webkit-transform: rotateZ(-8deg);
    }
    33% {
        -webkit-transform: rotateZ(6deg);
    }
    35% {
        -webkit-transform: rotateZ(-4deg);
    }
    37% {
        -webkit-transform: rotateZ(2deg);
    }
    39% {
        -webkit-transform: rotateZ(-1deg);
    }
    41% {
        -webkit-transform: rotateZ(1deg);
    }

    43% {
        -webkit-transform: rotateZ(0);
    }
    100% {
        -webkit-transform: rotateZ(0);
    }
}

@-moz-keyframes ring {
    0% {
        -moz-transform: rotate(0);
    }
    1% {
        -moz-transform: rotate(30deg);
    }
    3% {
        -moz-transform: rotate(-28deg);
    }
    5% {
        -moz-transform: rotate(34deg);
    }
    7% {
        -moz-transform: rotate(-32deg);
    }
    9% {
        -moz-transform: rotate(30deg);
    }
    11% {
        -moz-transform: rotate(-28deg);
    }
    13% {
        -moz-transform: rotate(26deg);
    }
    15% {
        -moz-transform: rotate(-24deg);
    }
    17% {
        -moz-transform: rotate(22deg);
    }
    19% {
        -moz-transform: rotate(-20deg);
    }
    21% {
        -moz-transform: rotate(18deg);
    }
    23% {
        -moz-transform: rotate(-16deg);
    }
    25% {
        -moz-transform: rotate(14deg);
    }
    27% {
        -moz-transform: rotate(-12deg);
    }
    29% {
        -moz-transform: rotate(10deg);
    }
    31% {
        -moz-transform: rotate(-8deg);
    }
    33% {
        -moz-transform: rotate(6deg);
    }
    35% {
        -moz-transform: rotate(-4deg);
    }
    37% {
        -moz-transform: rotate(2deg);
    }
    39% {
        -moz-transform: rotate(-1deg);
    }
    41% {
        -moz-transform: rotate(1deg);
    }

    43% {
        -moz-transform: rotate(0);
    }
    100% {
        -moz-transform: rotate(0);
    }
}

@keyframes ring {
    0% {
        transform: rotate(0);
    }
    1% {
        transform: rotate(30deg);
    }
    3% {
        transform: rotate(-28deg);
    }
    5% {
        transform: rotate(34deg);
    }
    7% {
        transform: rotate(-32deg);
    }
    9% {
        transform: rotate(30deg);
    }
    11% {
        transform: rotate(-28deg);
    }
    13% {
        transform: rotate(26deg);
    }
    15% {
        transform: rotate(-24deg);
    }
    17% {
        transform: rotate(22deg);
    }
    19% {
        transform: rotate(-20deg);
    }
    21% {
        transform: rotate(18deg);
    }
    23% {
        transform: rotate(-16deg);
    }
    25% {
        transform: rotate(14deg);
    }
    27% {
        transform: rotate(-12deg);
    }
    29% {
        transform: rotate(10deg);
    }
    31% {
        transform: rotate(-8deg);
    }
    33% {
        transform: rotate(6deg);
    }
    35% {
        transform: rotate(-4deg);
    }
    37% {
        transform: rotate(2deg);
    }
    39% {
        transform: rotate(-1deg);
    }
    41% {
        transform: rotate(1deg);
    }

    43% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

@media (max-width: 1024px) {
    .legal-entity-switcher {
        button {
            width: 166px !important;
            max-width: 166px !important;
            padding: 3px 10px !important;
        }
    }
}

@media (max-width: 767px) {
    .new-navigation-bar {
        flex-wrap: wrap;
    }

    .empty-row {
        width: 100%;
    }

    .legal-entity-switcher {
        width: 100%;
        margin: 24px 0 0 0 !important;

        button {
            width: auto !important;
            max-width: 100% !important;
            flex: 1;
        }
    }
}

@media (min-width: 1025px) {
    .legal-entity-switcher {
        button {
            min-width: 172px !important;
            max-width: 172px !important;
        }
    }
}


.notification-badge {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #e0061f;
}

</style>
<style lang="scss">
.legal-entity-tooltip {
    width: auto !important;
    padding: 16px !important;
    border-radius: 16px !important;
}
</style>
