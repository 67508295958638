export const namespaced = true;
export const state = {
  contractorsContracts: [],
  contractsStats: [],
  signNowContract: null,

  clients: [],

  filters: {
    search: "",
    statuses: [],
    clients: [],
  },

  statuses: [
    {
      type: "active",
      name: "Active",
    },
    {
      type: "pending",
      name: "Pending",
    },
    {
      type: "terminated",
      name: "Terminated",
    },
  ],
};

export const mutations = {
  SET_CONTRACTORS_CONTRACTS(state, contractorsContracts) {
    state.contractorsContracts = contractorsContracts;
  },

  SET_CONTRACTS_STATS(state, contractsStats) {
    state.contractsStats = contractsStats;
  },

  SET_FILTERS(state, filters) {
    state.filters = filters;
  },

  SET_SIGN_NOW_CONTRACT(state, signNowContract) {
    state.signNowContract = signNowContract;
  },
};

export const actions = {
  setFilters({ commit }, filters) {
    commit("SET_FILTERS", filters);
  },

  async getAllContractorsContracts({ commit }) {
    return axios
      .get('/v2/contracts/all')
      .then(response => {
          commit('SET_CONTRACTORS_CONTRACTS', response.data.data);
          return response.data.data;
      }).catch((error) => {
          commit("setAlert", { type: 'error', message: error?.response?.data?.message ?? error.message }, { root: true });
          throw error;
      });
  },

  async getContractsStats({ commit }) {
    return axios
      .get('/v2/contracts/contracts-stats')
      .then(response => {
          commit('SET_CONTRACTS_STATS', response.data.data);
          return response.data.data;
      }).catch((error) => {
          commit("setAlert", { type: 'error', message: error?.response?.data?.message ?? error.message }, { root: true });
          throw error;
      });
  },

  async setSignNowContract({ commit }, payload) {
    return axios
      .post('/v2/contracts/generate-sign-now-contract', payload)
      .then(response => {
        commit('SET_SIGN_NOW_CONTRACT', response.data.data);
        return response.data.data;
      }).catch((error) => {
        commit("setAlert", { type: 'error', message: error?.response?.data?.message ?? error.message }, { root: true });
        throw error;
      });
  },
};

export const getters = {
  getContractorsContracts: (state) => state.contractorsContracts,

  contractsStats: (state) => state.contractsStats,

  totalContracts: (state) => {
    return state.contractorsContracts.length;
  },

  getSignNowContract: (state) => state.signNowContract,

  totalActiveContracts: (state) => {
    return state.contractorsContracts.filter(
      (contract) => contract.status === "active"
    ).length;
  },

  totalPendingContracts: (state) => {
    return state.contractorsContracts.filter(
      (contract) => contract.status === "pending"
    ).length;
  },

  totalTerminatedContracts: (state) => {
    return state.contractorsContracts.filter(
      (contract) => contract.status === "terminated"
    ).length;
  },

  filteredContractorsContracts: (state) => {
    return state.contractorsContracts.filter((contract) => {
      const status = state.filters.statuses.includes(contract.status.charAt(0).toUpperCase() + contract.status.slice(1));

      const contractName = contract.contractName ? contract.contractName
        .toLowerCase()
        .includes(state.filters.search.toLowerCase())
        : '';

      return (
        (state.filters.statuses.length === 0 || status) &&
        (!state.filters.search || contractName)
      );
    });
  },

  contractStatuses: (state) => {
    return state.statuses;
  },
  clients: (state) => {
    return state.clients;
  },
};
