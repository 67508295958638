<template>
    <modal v-model="dialog" width="23.875rem" :padding="false" close-size="0.6875rem" close-color="#8F8F8F" close-top="5" close-right="5">
        <template slot="content">
            <div class="d-flex flex-column justify-center align-center"
                 :style="!__smallDesktop && 'padding: 2.5rem 4.0625rem' || ''"
                 style="height: 21.625rem;">
                <div style="margin-bottom: 1.25rem">
                    <avatar
                        :avatar="$store.state?.wlstyle?.st_logo_monblack"
                        :offset-right="false"
                        size="6.1875rem"
                    ></avatar>
                </div>
                <p style="font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 28px;
text-align: center;
color: #1D2C40;
margin-bottom: 0.625rem;
">Feature Locked </p>
                <p style="
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 150%;
text-align: center;
color: #1D2C40;
margin-bottom: 1.4375rem;
">Upgrade your plan to access this feature and many more!</p>
                <btn @click="__changeRoute('add-ons/my-plan')">
                    <span style="text-transform: none;">Go to Plans</span>
                </btn>
            </div>
        </template>
    </modal>
</template>
<script>
import Modal from "~/shared/components/Modal";
import Avatar from "~/shared/components/Avatar";
import Btn from "~/shared/components/Btn";
import Screen from "~/shared/mixins/Screen";
import Helpers from "~/shared/mixins/Helpers";
export default {
    name: 'UpgradeSSDashboard',
    components: {Btn, Avatar, Modal},
    mixins: [Screen, Helpers],
    props: {
        value: {
            required: true,
            type: Boolean
        },
    },
    data(){
        return {
            dialog: this.value
        }
    },
    watch: {
        value() {
            this.dialog = this.value
        },
        dialog() {
            this.$emit('input', this.dialog);
        },
    },
}
</script>
