export const namespaced = true;

export const state = {
  individualPlans: [
    {
      name: "Freeeee",
      price: 0,
      tiers: [],
    },
    {
      name: "Payments",
      price: 19,
      tiers: [
        {
          name: "Tier 1",
          limit: 1500,
          limitType: "upper",
          price: 19,
        },
        {
          name: "Tier 2",
          limit: 5500,
          limitType: "upper",
          price: 39,
        },
        {
          name: "Tier 3",
          limit: 5500,
          limitType: "lower",
          price: 59,
        },
      ],
    },
    {
      name: "Remoter",
      price: 49,
      tiers: [
        {
          name: "Tier 1",
          limit: 1500,
          limitType: "upper",
          price: 49,
        },
        {
          name: "Tier 2",
          limit: 5500,
          limitType: "upper",
          price: 99,
        },
        {
          name: "Tier 3",
          limit: 5500,
          limitType: "lower",
          price: 179,
        },
      ],
    },
    {
      name: "Native Company",
      price: 75,
      tiers: [
        {
          name: "Tier 1",
          limit: 1500,
          limitType: "upper",
          price: 75,
        },
        {
          name: "Tier 2",
          limit: 5500,
          limitType: "lower",
          price: 150,
        },
      ],
    },
  ],
  businessPlans: [
    {
      name: "Employer of Record",
      price: 79,
      tiers: [
        {
          name: "Tier 1",
          price: 79,
          limit: 1500,
          limitType: "upper",
        },
        {
          name: "Tier 2",
          price: 129,
          limitType: "upper",
        },
        {
          name: "Tier 3",
          limitType: "lower",
          limit: 5500,
          price: 219,
        },
      ],
    },
    {
      name: "Contractor",
      price: 19,
      tiers: [
        {
          name: "Tier 1",
          limitType: "upper",
          limit: 1500,
          price: 19,
        },
        {
          name: "Tier 2",
          limitType: "upper",
          limit: 5500,
          price: 39,
        },
        {
          name: "Tier 3",
          limitType: "lower",
          limit: 5500,
          price: 59,
        },
      ],
    },
    {
      name: "Payroll Services",
      price: 19,
      tiers: [],
    },
  ],
  couponCheck: {
    status: false
  }
};

export const mutations = {
  individualPlans(state, payload) {
    state.individualPlans = payload;
  },
  businessPlans(state, payload) {
    state.businessPlans = payload;
  },
  couponCheck(state, payload) {
    state.couponCheck = payload;
  },
};

export const actions = {
  async getIndividualPlans({ commit }, {currency = null, type = null}) {
    return axios
    .post('/get/individual/plans',{
      type: type,
      currency: currency,
    })
    .then(response => {
      commit('individualPlans', response.data);
      return response.data;
    })
    .catch(error => {
      commit('setAlert', {type: 'error', message: error.message}, { root: true });
      throw error;
    });
  },
  async getBusinessPlans({ commit },{currency = null, type = null, withLegacy = false}) {
    return axios
    .post('/get/business/plans',{
      type: type,
      currency: currency,
      withLegacy: withLegacy,
    })
    .then(response => {
      commit('businessPlans', response.data);
      return response.data;
    })
    .catch(error => {
      commit('setAlert', {type: 'error', message: error.message}, { root: true });
      throw error;
    });
  },
  checkCoupon({ commit }, {couponCode, planId}) {
      return axios
          .post('/get/check/coupon', {
              couponCode: couponCode,
              planId: planId
          })
          .then(response => {
              commit('couponCheck', response.data);
          });
  },
};

export const getters = {
  individualPlans: (state) => state.individualPlans,

  businessPlans: (state) => state.businessPlans,

  couponStatus: (state) => state.couponCheck.status,

  couponDiscount: (state) => state.couponCheck.discount,
};
