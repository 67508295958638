import {__config} from "@/utils/config";

export default class recaptchaConfig{
    async resolve() {
        if (__config('VUE_APP_RECAPTCHA_ENABLED')){
            const script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js?render=${__config('VUE_APP_RECAPTCHA_SITE_KEY')}`;
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
            script.onload = () => {};
            script.onerror = () => {
                console.error('Failed to load reCAPTCHA script');
            };
        }
    }
}
