<template>
    <div class="position-relative">
        <label
            v-if="$slots.label || label"
            class="text-sm color-black custom-label"
            :for="localId"
        >
            <slot v-if="$slots.label" name="label"></slot>
            <span :class="[labelClassVal]" v-else>
                {{ label }}
                <span v-if="asterisk" class="red--text">*</span>
            </span>
        </label>
        <p
            v-if="$slots.subLabel || subLabel"
            class="text-sm color-grey-3 leading-130 mb-1"
        >
            <slot v-if="$slots.subLabel" name="subLabel"></slot>
            <span v-else>{{ subLabel }}</span>
        </p>
        <textarea
            :id="localId"
            :disabled="disabled"
            :placeholder="__inputPlaceholder"
            v-on="$listeners"
            class="w-100 tm-textarea"
            :class="customInputClasses"
            @input="val = $event.target.value"
            :value="value"
            :rows="rows"
        >
        </textarea>
        <base-error-message :messages="errorMessages"></base-error-message>
    </div>
</template>

<script>

import Helpers from "~/shared/mixins/Helpers";
import Form from "~/shared/mixins/Form";
export default {
    name: "BaseTextarea",
    props: {
        id: {
            type: String|null,
            default: null
        },
        label: {
            type: String|null,
            default: null
        },
        subLabel: {
            type: [String, null],
            default: null,
        },
        errorMessages: {
            default: () => []
        },
        placeholder: {
            type: String|null,
            default: 'Type here'
        },
        value: {
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        labelClassVal: {
            type: String | null,
            default: null,
        },
        customInputClasses: {
            type: String,
            default: '',
        },
        asterisk: {
            default: false,
            type: Boolean,
        },
        rows: {
            default: null,
        }
    },
    mixins: [Helpers, Form],
    data(){
        return {
            val: this.value,
            localId: this.id || this.__makeId()
        }
    },
    watch: {
        val(){
            this.$emit('input', this.val);
        },
        value(){
            this.val = this.value;
        }
    },
    methods: {

    }
};
</script>
