import store from "~/store";

export default class setDefaultState{
    async resolve() {
        if (!store.state.defaultState || _.isEmpty(store.state.defaultState)){
            const {window, defaultState, auth, ...rest} = store.state;
            const defaultObj = JSON.parse(JSON.stringify(rest));
            store.commit('SET_DEFAULT_STATE', defaultObj);
        }
    }
}
