<template>
    <div class="profile-menu-settings">
        <div class="btn-back-wrapper" @click="$emit('back')">
            <v-btn icon small :ripple="false" class="btn-back">
                <BaseIcon name="chevron-left-2"/>
            </v-btn>
            <p class="back-text">Settings</p>
        </div>

        <v-divider></v-divider>

        <!-- ITEMS -->
        <v-list>
            <NavListItem v-if="canAddOrg" :href="'/settings?tab=organizations'" icon="global">
                {{ organizationMenuTitle }}
            </NavListItem>
            <NavListItem icon="bank-2" :href="'/settings?tab=payment_accounts'"> Bank accounts</NavListItem>
            <NavListItem icon="dollar" :href="'/settings?tab=billing'"> Billing</NavListItem>
            <NavListItem icon="shield" :href="'/settings?tab=security'"> Security</NavListItem>
            <NavListItem
                v-if="payoneerParams.enabled"
                :href="'/settings?tab=payoneer'"
            >
                <template v-slot:icon>
                    <v-img
                        :eager="true"
                        src="/images/add-money/payoneer.svg"
                        max-width="1.5rem"
                    />
                </template>
                Payoneer integration
            </NavListItem>
            <NavListItem
                v-if="currentWorker?.id == currentOrganization.creator_id"
                icon="add-user-2"
                :href="'/settings?tab=profile'"
            > Additional admins
            </NavListItem>
            <!--      <NavListItem icon="tiles" :disabled="true">White labelling</NavListItem>-->
        </v-list>
    </div>
</template>

<script>
import NavListItem from "~/components/NewNavigation/NavListItem.vue";
import {mapState} from "vuex";
import Helpers from "~/shared/mixins/Helpers";

export default {
    name: "Settings",

    components: {NavListItem},
    mixins: [Helpers],
    computed: {
        ...mapState('globalMenuModule', ['payoneerParams']),//only one initialization is needed - done in App.vue
	
		currentWorker(){
			return this.$store.state.worker;
		},
		currentOrganization(){
			return this.$store.state.organization;
		},
		organizations(){
			return this.$store.state.organizations;
		},
        canAddOrg() {
            return this.__employmentPlans.includes(this.currentWorker.plan.value);
        },
        organizationMenuTitle() {
            if (this.organizations?.length > 1) {
                return "Organisations"
            }
            return "Organisation"
        }
    },
};
</script>

<style scoped>
.profile-menu-settings {
    .btn-back-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .btn-back {
        &:hover::before {
            opacity: 0;
        }

        margin-right: 0.75rem;

        i {
            font-size: 0.859375rem;
        }
    }

    .back-text {
        font-size: 1.125rem;
        font-weight: 700;
    }
}

.v-divider {
    margin: 1.5rem 0;
    border-color: #d6d9dd !important;
}
</style>
