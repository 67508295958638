<template>
    <v-tooltip content-class="top" top :disabled="!tooltip">
        <template v-slot:activator="{ on, attrs }">
            <div
                :class="[!background ? 'p-0' : '', classNames]"
                :style="{
          color: activeStatus?.color,
          border: borderStyle, // Apply the border style here
        }"
                class="base-status-tag"
                v-on="on"
                v-bind="attrs"
            >
                <div
                    v-if="background"
                    class="status-tag-bg"
                    :style="{ 'background-color': bgColor, opacity: bgOpacity }"
                />
                <BaseIcon
                    v-if="activeStatus?.icon && background"
                    :name="activeStatus?.icon"
                    class="text-base"
                />
                <p
                    v-if="showText"
                    class="text-sm font-medium status-tag-text"
                    :class="{ 'ml-1': activeStatus?.icon && (activeStatus.name || status) }"
                >
                    {{ activeStatus.name || status }}
                </p>
            </div>
        </template>

        <!-- TOOLTIP CONTENT -->
        <p
            v-if="!$slots['tooltip-content']"
            class="text-sm color-white first-upper"
        >
            {{ activeStatus.name || status }}
        </p>
        <slot v-else name="tooltip-content"></slot>
    </v-tooltip>
</template>

<script>
import { isEmpty } from "lodash";

export default {
    name: "BaseStatusTag",

    props: {
        status: {
            type: String,
            default: "",
        },
        statuses: {
            type: Array,
            default: () => [],
        },
        options: {
            type: Object,
            default: () => ({
                icon: "check-circle-outline",
                color: "#30B362",
            }),
        },
        statusKey: {
            default: "name",
        },
        border: {
            type: String,
            default: "",
        },
        background: {
            type: Boolean,
            default: true,
        },
        bgOpacity: {
            type: String,
            default: "0.05",
        },
        tooltip: {
            type: Boolean,
            default: true,
        },
        classNames: {
            default: null,
        },
        showText: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        activeStatus() {
            return (
                this.statuses.find(
                    (status) => status[this.statusKey] && this.status && status[this.statusKey].toLowerCase() === this.status.toLowerCase()
                ) || this.options
            );
        },

        borderStyle() {
            return this.border;
        },

        bgColor() {
            return !isEmpty(this.activeStatus.bgColor)
                ? this.activeStatus.bgColor
                : this.activeStatus.color;
        },
    },
};
</script>
