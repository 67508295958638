import Middleware from "~/routes/middleware/Middleware";
import store from "~/store";

export default class resolveAuth extends Middleware{
    constructor(...args) {
        super(...args);
    }

    async resolve() {
        return await store.dispatch('auth/__resolveAuth').then(() => this.responseNext());
    }
}
