const state = {
    dialog: false,

    // Props
    contactRequestedPage: null, //send-money-someone,
    contactModel: null,
    requestedPage: null,
    contactType: null,
    bankDetails: false,
    selectedOutCurrency: false,
    fromMoney: false,

    // emits watcher
    createdEmit: null,
    updatedEmit: false,
};

const mutations = {
    setDialog(state,data) { state.dialog = data },

    setContactModel(state,data) { state.contactModel = data },
    setContactRequestedPage(state,data) { state.contactRequestedPage = data },
    setContactType(state,data) { state.contactType = data },
    setBankDetails(state, data) { state.bankDetails = data },
    setSelectedOutCurrency(state,data) { state.selectedOutCurrency = data },
    setFromMoney(state, data) { state.fromMoney = data },

    setCreatedEmitObj(state,data) { state.createdEmit = data },
    setUpdatedEmit(state) { state.updatedEmit = !state.updatedEmit },
};

const actions = {
    inPartWallet({commit}, params = null) {
        commit('setBankDetails', true);
        commit('setFromMoney', true);
        commit('setSelectedOutCurrency', params ? params : false);
    },
    triggerSetBankDetails({commit}) {
        commit('setBankDetails', true);
    },
    dialogContactShow({commit}, params = null) {
        commit('setContactModel', params ? params.contact_model : null);
        commit('setContactRequestedPage', params ? params.contactRequestedPage : null);
        commit('setContactType', params ? params.contact_type : null);
        commit('setDialog', true);
    },
    emitCreatedObj({commit},obj = null) {
        commit('setCreatedEmitObj',obj);
    },
    changerUpdatedEmit({commit}) {
        commit('setUpdatedEmit');
    },
    closeDialog({commit}) {
        commit('setBankDetails', false);
        commit('setFromMoney', false);
        commit('setSelectedOutCurrency', false);
        commit('setDialog', false);
        commit('setContactModel', null);
        commit('setContactRequestedPage', null);
        commit('setContactType', null);
    },
};

const getters = {
    showContactDialog: (state) => state.dialog,

    contactModel: (state) => state.contactModel,
    contactRequestedPage: (state) => state.contactRequestedPage,
    contactType: (state) => state.contactType,
    bankDetails: (state) => state.bankDetails,
    selectedOutCurrency: (state) => state.selectedOutCurrency,
    fromMoney: (state) => state.fromMoney,

    createdEmit: (state) => state.createdEmit,
    updatedEmit: (state) => state.updatedEmit,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
