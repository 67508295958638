<template>
  <div
    class="custom-status-tag"
    :class="[statusClass, hideText ? 'custom-status-tag--no-text' : '']"
  >
    <BaseIcon v-if="status?.icon" :name="status?.icon" />
    <p :class="{ 'ml-1': status?.icon && status.text }" v-if="!hideText">
      {{ status.text }}
    </p>
  </div>
</template>

<script>
export default {
  name: "BaseStatus",

  props: {
    status: {
      type: Object,
      required: true,
    },
    hideText: {
      type: Boolean,
      default: false,
    },
    isTypeClass: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    statusClass() {
      if (this.isTypeClass) {
        return `status-${this.status?.type.toLowerCase()}`;
      }
      return `status-${this.status?.text?.split(" ").join("-").toLowerCase()}`;
    },
  },
};
</script>
