<template>
  <div
    class="tabs-container position-relative"
    :class="{ 'vertical-tabs': vertical }"
  >
    <div class="nav-tabs-header">
        <div class="tabs-wrapper">
            <ul class="nav-tabs">
                <li
                    class="nav-item"
                    v-for="tab in tabs"
                    :key="tab.title"
                    @click="selectTab(tab.localValue)"
                >
                    <a
                        class="nav-link text-lg font-medium"
                        :class="tab.localValue === val ? 'tab__selected' : ''"
                    >
                        {{ tab.title }}
                    </a>
                </li>
            </ul>
        </div>

        <div class="top-slot ml-auto" v-if="$slots['top']">
            <slot name="top"></slot>
        </div>
     <div class="right-slot ml-auto" v-if="$slots['filters']">
         <slot v-if="!isSmallTablet" name="filters"></slot>
         <div v-else class="tabs-mobile-filters-container">
             <v-expansion-panels>
                 <v-expansion-panel>
                     <v-expansion-panel-header expand-icon="icon-chevron-down"
                     ><div>
                         <WlBaseIcon name="filter" class="color-purple"> </WlBaseIcon>
                         <span class="font-medium ml-2 text-base">Filter</span>
                     </div>
                     </v-expansion-panel-header>
                     <v-expansion-panel-content>
                         <slot name="filters"></slot>
                     </v-expansion-panel-content>
                 </v-expansion-panel>
             </v-expansion-panels>
         </div>
      </div>
    </div>
    <slot></slot>
    <v-progress-linear
      ref="loader"
      class="tabs-loader"
      absolute
      bottom
      height="3px"
      v-show="loading"
      indeterminate
      :color="loadingColor"
      :background-color="loadingBgColor"
      :background-opacity="loadingBgOpacity"
    ></v-progress-linear>
  </div>
</template>

<script>
import {commonHelpersMixin} from "../../mixins/commonHelpersMixin";

export default {
  name: "WlBaseTabs",
  mixins: [commonHelpersMixin],

  props: {
    value: {
      default: 0,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingColor: {
      type: String,
      default: "#5152fb",
    },
    loadingBgColor: {
      type: String || null,
      default: null,
    },
  },

  data() {
    return {
      val: this.value, // the index of the selected tab,
      tabs: [], // all the tabs
    };
  },

  computed: {
    activeIndex() {
      return this.tabs.findIndex((t) => t.localValue === this.val);
    },

    loadingBgOpacity() {
      if (!this.loadingBgColor) {
        return 0;
      }
      return 0.3;
    },

    tabsWithTitleLineBreak() {
      return this.tabs.map((tab) => {
        tab.titleWithLineBreak = tab.title.replace(/\n/g, "<br>");
        return tab;
      });
    },
  },

  watch: {
    value() {
      this.selectTab(this.value);
    },

    val() {
      this.$emit("input", this.val);
    },
  },

  methods: {
    setValues() {
      this.tabs.forEach((tab, index) => {
        if (typeof tab.localValue === "undefined") {
          tab.localValue = index;
        }
        tab.isActive = tab.localValue === this.val;
      });
    },

    selectTab(value) {
      const find = this.tabs.find((t) => t.localValue === value);

      if (find && !find.disabled) {
        this.val = value;
        this.tabs.forEach((t) => {
          t.isActive = t.localValue === find.localValue;
        });
      }
    },

    next(enable = false) {
      const index = this.activeIndex + 1;
      if (this.tabs[index] && (!this.tabs[index].disabled || enable)) {
        this.tabs[index].disabled = false;
        this.selectTab(this.tabs[index].localValue);
      }
    },

    back() {
      const index = this.activeIndex - 1;
      if (this.tabs[index] && !this.tabs[index].disabled) {
        this.selectTab(this.tabs[index].localValue);
      }
    },
  },

  mounted() {
    this.$children.forEach((el) => {
      if (el.$options.name === "WlBaseTab") {
        this.tabs.push(el);
      }
    });
    this.setValues();
    this.$emit("setTabs", this.tabs);
    this.tabsWithTitleLineBreak;
  },
};
</script>

<style lang="scss" scoped>
/* NAV TABS */

.nav-tabs-header {
  display: flex;
  align-items: center;
}
.tabs-wrapper {
    width: 100%;
    overflow-x: auto;
    background-color: #fff;
    border-radius: 14px;
    flex-grow: 1;
    margin-right: 20px;

    &::-webkit-scrollbar {
        display: none;
    }
}

.tabs-container {
    .tab.active {
        display: flex;
    }
}

.nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 0;
    list-style-type: none;
    padding: 4px;
}
.nav-tabs .nav-item {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex: 1;
    margin: 0 3px;
}

.nav-tabs .nav-link {
    display: block;
    color: #777c96;
    text-align: center;
    padding: 14px 20px;
    text-decoration: none;
    border-radius: 14px;
    cursor: pointer;
}

.nav-tabs .nav-link:hover {
    background-color: #5152fb;
    color: #fff;
}

.nav-tabs .nav-link.tab__selected {
    border: 0;
    background-color: #5152fb;
    color: #fff;
}

.vertical-tabs {
  display: flex;

  & > .nav-tabs {
    width: auto;
    flex-direction: column;
    padding: 0;

    .nav-item {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 768px) {
    .nav-tabs-header {
        flex-direction: column;
        gap: 24px;

        .right-slot {
            width: 100%;
            margin-left: 0 !important;

            .tabs-mobile-filters-container {
                background: #fff;
                padding: 32px 16px;
                width: 100%;
                border-radius: 16px;

                .v-expansion-panel {
                    border: none !important;

                    &::before {
                        content: none;
                    }

                    .v-expansion-panel-header {
                        padding: 10px 16px;
                    }

                    .v-expansion-panel-content {
                        margin-top: 16px;

                        .v-expansion-panel-content__wrap {
                            padding: 0 !important;
                        }
                    }
                }
            }
        }
    }

    .tabs-wrapper {
        margin-right: 0;
    }

    .nav-tabs .nav-item {
        flex: none;
    }

    .top-slot {
        margin-right: auto;
    }
}
</style>
