<template>
    <div class="d-flex flex-column" :class="bottomOffset? 'mb-4': 'mb-0'" style="position: relative">
        <div class="d-flex align-center">
            <label :for="getId" class="tm-small-text font-weight-medium tm-color-black" :class="leftOffset? 'mr-2': 'mb-0'">{{ label }}</label>
            <input :disabled="disabled" type="checkbox" v-model="val" class="tm-toggle-switch d-none" :class="active && 'allways-swich' || ''" :id="getId" @change="$emit('change', $event.target.checked)">
            <label :for="getId" class="tm-toggle-switch-label" :class="rightOffset? 'mr-10': 'mb-0'"></label>
        </div>
        <error-message :messages="errorMessages"></error-message>
    </div>
</template>
<script>
import ErrorMessage from "./ErrorMessage";
export default {
    name: 'TmSlideCheckbox',
    components: {ErrorMessage},
    props: {
        value: {
            default: false
        },
        label: {
            default: null
        },
        id: {
            default: null
        },
        active: {
            default: false
        },
        disabled: {
            default: false
        },
        bottomOffset: {
            default: true
        },
        rightOffset: {
            default: true
        },
        leftOffset: {
            default: true
        },
        returnInt: {
            default: true
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data(){
        return {
            val: this.value,
            getId: this.id
        }
    },
    watch: {
        val(){
            this.$emit('input',  this.returnInt? this.val? 1: 0: this.val)
        },
        value(){
            this.val = this.value;
        }
    },
    created() {
        if (!this.id){
            this.getId = Math.random().toString(36).substring(7)
        }
    }
}
</script>
