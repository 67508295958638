<template>
    <v-btn
        class="btn"
        :class="`btn-${this.type} btn-${this.size}`"
        v-on="$listeners"
        :disabled="disabled"
        :loading="loading"
        :style="{ height: height}"
        :href="href"
        :large="size === 'lg'"
    >
        <slot name="default"></slot>
    </v-btn>
</template>

<script>
export default {
    name: 'BaseButton',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        height: {
            type: String | null,
            default: null,
        },
        href: {
            default: null
        },
        type: {
            type: String,
            default: "",
        },
        size: {
            type: String,
            default: "md",
        },
    }
};
</script>
<style scoped>
.btn {
    font-style: normal;
    font-weight: 500;
    text-transform: none;
    letter-spacing: unset;
}
.btn.disabled {
    opacity: 0.5;
    cursor: default !important;
}
</style>
