<template>
  <v-slide-x-transition>
    <div
      v-if="messages.length"
      class="color-dark-pink text-sm mt-1 base-error-message"
    >
      <span :style="messageStyle" v-if="!multipleMessages && messages.length">{{
        messages[0]
      }}</span>
      <span
        :style="messageStyle"
        v-else
        v-for="message in messages"
        :key="message"
        >{{ message }}</span
      >
    </div>
  </v-slide-x-transition>
</template>

<script>
export default {
  name: "WlBaseErrorMessage",

  props: {
    messages: {
      default() {
        return [];
      },
    },
    left: {
      default: false,
    },
    center: {
      default: false,
    },
    multipleMessages: {
      default: false,
    },
    messageStyle: {
      default: "font-size: 0.75rem;",
    },
    absolute: {
      default: true,
    },
    rounded: {
      default: true,
    },
  },
};
</script>
