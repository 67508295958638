import OwnEntity from '~/modules/dashboard/models/OwnEntity';

const state = {
    PATH: '/entities/entities',

    entities: [],
    entitiesPagination: null,
    uiData: [],
    entitiesQuery: {},
    entity: null,
    entityModel: null,
    newEntityId: null,
    newEntity: null,

    addableMembers: [],

    infoModalVisible: false,
    infoModalActionLoading: false,
    infoModalData: {},
};

const getters = {
    entities: state => state.entities,
    entitiesPagination: state => state.entitiesPagination,
    uiData: state => state.uiData,
    entitiesQuery: state => state.entitiesQuery,
    entityModel: state => state.entityModel,
    newEntityId: state => state.newEntityId,
    newEntity: state => state.newEntity,
    entity: state => state.entity,

    addableMembers: state => state.addableMembers,

    infoModalVisible: state => state.infoModalVisible,
    infoModalActionLoading: state => state.infoModalActionLoading,
    infoModalData: state => state.infoModalData,
}

const mutations = {
    entities(state, payload) {
        state.entities = payload;
    },
    entitiesPagination(state, payload) {
        state.entitiesPagination = payload;
    },
    uiData(state, payload) {
        state.uiData = payload;
    },
    entitiesQuery(state, payload) {
        state.entitiesQuery = payload;
    },
    newEntityId(state, payload) {
        state.newEntityId = payload;
    },
    newEntity(state, payload) {
        state.newEntity = payload;
    },
    entity(state, payload) {
        state.entity = payload;
    },
    addableMembers(state, payload) {
        state.addableMembers = payload;
    },
    infoModalVisible(state, payload) {
        state.infoModalVisible = payload;
    },
    infoModalActionLoading(state, payload) {
        state.infoModalActionLoading = payload;
    },
    infoModalData(state, payload) {
        state.infoModalData = payload;
    },
    clearEntity() {
        state.entity = null;
    },
    createEntityModel(state, payload) {
        state.entityModel = new OwnEntity(payload);
    },
    discardEntityModel(state) {
        state.entityModel = null;
    },
    pushContracts(state, payload) {
        if (state.entity) {
            if (Array.isArray(payload)) {
                payload.forEach(item => state.entity.contracts.push(item));
            }
        }
    },
    addContract(state, payload) {
        if (state.entity) {
            state.entity.contracts.push(payload);
        }
    },
    addMembers(state, payload) {
        if (state.entity) {
            state.entity.workers = payload;
        }
    },
    removeMember(state, payload) {
        const index = state.entity.workers.findIndex(item => item.id === payload);
        if (index !== -1) {
            state.entity.workers.splice(index, 1);
        }
    },
    removeDraftStatus() {
        if (state.entity) {
            state.entity.is_draft = false;
        }
    }
}

const actions = {
    async getEntities({ state, commit, dispatch }, q = {}) {
        const query = Object.assign({}, state.entitiesQuery);
        query.page = q.page || 1;
            for (const [key, value] of Object.entries(q)) {
                if(key !== 'page'){
                query[key] = value;
            }
        }
        await axios
            .get(state.PATH, {
                params: query
            })
            .then(response => {
                commit('entities', response.data.paginatedData.data);
                commit('entitiesPagination', response.data.paginatedData.meta);
                return response.data;
            })
            .catch(error => {
                dispatch('setErrorAlert', error);
                throw error;
            });
    },
    async getEntity({ state, commit, dispatch }, id) {
        await axios
            .get(state.PATH + '/' + id)
            .then(response => {
                commit('entity', response.data);
                return response.data;
            })
            .catch(error => {
                dispatch('setErrorAlert', error);
                throw error;
            });
    },
    async save({ state, commit, dispatch }) {
        let path = state.PATH;
        let data;
        if (state.entityModel.values.id) {
            path += '/' + state.entityModel.values.id;
            data = state.entityModel.getData({ _method: 'PUT' });
        } else {
            data = state.entityModel.getData();
        }

        await axios.post(path, data)
            .then(response => {
                dispatch('getEntities');
                if (response.data.id) {
                    commit('newEntity', response.data);
                    commit('newEntityId', response.data.id);
                }
                return response;
            })
            .catch(error => {
                dispatch('setErrorAlert', error);
                throw error;
            });
    },
    async saveDraft({ state, dispatch }) {
        let path = state.PATH + '/draft';
        let data;
        if (state.entityModel.values.id) {
            data = state.entityModel.getData({ _method: 'PUT' });
        } else {
            data = state.entityModel.getData();
        }

        await axios.post(path, data)
            .then(response => {
                dispatch('getEntities');
                dispatch('showEntityDraftSavedModal');
                return response;
            })
            .catch(error => {
                dispatch('setErrorAlert', error);
                throw error;
            });
    },
    async undraft({ state, commit, dispatch }, id) {
        let path = state.PATH + '/undraft/' + id;
        let data = { _method: 'PATCH' };

        await axios.post(path, data)
            .then(response => {
                commit('removeDraftStatus');
                dispatch('setSuccessAlert', response.data.message);
                return response;
            })
            .catch(error => {
                dispatch('setErrorAlert', error);
                throw error;
            });
    },
    async saveDetails({ state, commit, dispatch }) {
        if (!state.entityModel.values.id) {
            return;
        }
        await axios.post(state.PATH + '/details/' + state.entityModel.values.id, state.entityModel.getDetails())
            .then(response => {
                commit('entity', response.data.data);
                dispatch('setSuccessAlert', response.data.message);
                return response;
            })
            .catch(error => {
                dispatch('setErrorAlert', error);
                throw error;
            });
    },
    async saveBankDetails({ state, commit, dispatch }) {
        if (!state.entityModel.values.id) {
            return;
        }
        await axios.post(state.PATH + '/bank-details/' + state.entityModel.values.id, state.entityModel.getBankDetails())
            .then(response => {
                commit('entity', response.data.data);
                dispatch('setSuccessAlert', response.data.message);
                return response;
            })
            .catch(error => {
                dispatch('setErrorAlert', error);
                throw error;
            });
    },
    async saveAddress({ state, commit, dispatch }) {
        if (!state.entityModel.values.id) {
            return;
        }
        await axios.post(state.PATH + '/address/' + state.entityModel.values.id, state.entityModel.getAddress())
            .then(response => {
                commit('entity', response.data.data);
                dispatch('setSuccessAlert', response.data.message);
                return response;
            })
            .catch(error => {
                dispatch('setErrorAlert', error);
                throw error;
            });
    },
    async addContracts({ state, commit, dispatch }, entityId = null) {
        if (!state.entityModel.hasContracts()) {
            return;
        }
        await axios.post(`${state.PATH}/${entityId ?? state.entityModel.values.id}/contracts`, state.entityModel.getContracts())
            .then(response => {
                commit('pushContracts', response.data);
                return response;
            })
            .catch(error => {
                dispatch('setErrorAlert', error);
                throw error;
            });
    },
    async deleteEntity({ state, commit, dispatch }, id) {
        await axios
            .delete(state.PATH + '/' + id)
            .then(response => {
                dispatch('getEntities');
                commit('clearEntity');
                dispatch('showEntityDeletedSuccessModal');
                return response.data;
            })
            .catch(error => {
                dispatch('setErrorAlert', error);
                throw error;
            });
    },
    async getAddableMembers({ state, commit, dispatch }, entityId) {
        await axios
            .get('/settings/entity/addable-members/' + entityId)
            .then(response => {
                commit('addableMembers', response.data);
                return response.data;
            })
            .catch(error => {
                dispatch('setErrorAlert', error);
                throw error;
            });
    },
    async addMembers({ state, commit, dispatch }, entityId = null) {
        if (!state.entityModel.hasMembers()) {
            return;
        }
        await axios.post(state.PATH + '/' + (entityId ?? state.entityModel.getId()) + '/members', { members: state.entityModel.getMembers() })
            .then(response => {
                commit('addMembers', response.data.data);
                dispatch('setSuccessAlert', response.data.message);
                return response;
            })
            .catch(error => {
                dispatch('setErrorAlert', error);
                throw error;
            });
    },
    async deleteTeamMember({ state, commit, dispatch }, { id, memberId }) {
        await axios
            .delete(state.PATH + '/' + id + '/members/' + memberId)
            .then(response => {
                commit('removeMember', memberId);
                return response.data;
            })
            .catch(error => {
                dispatch('setErrorAlert', error);
                throw error;
            });
    },
    onceGetItems: _.once(function ({ dispatch }) {
        return dispatch('getEntities');
    }),
    setSuccessAlert({commit}, text) {
        commit('setAlert', {type: 'success', message: text}, { root: true });
    },
    setErrorAlert({commit}, error) {
        let alertText = '';
        if (error.response && (error.response.data.message || error.response.data.error)) {
            alertText = error.response.data.error ?? error.response.data.message;
        } else {
            alertText = error.message;
        }
        commit('setAlert', {type: 'error', message: alertText}, { root: true });
    },
    showEntityDraftSavedModal({ dispatch }) {
        dispatch('showSuccessModal', 'Your entity has been successfully saved.');
    },
    showEntityDeletedSuccessModal({ dispatch }) {
        dispatch('showSuccessModal', 'Your entity has been successfully deleted.');
    },
    showSuccessModal({ commit }, text) {
        commit('infoModalVisible', true);
        commit('infoModalData', {
            icon: {
                name: 'check-circle-outline',
                class: 'check-circle-outline',
            },
            text: text,
            title: 'Success',
        });
    },
    showDeleteEntityConfirmDialog({ state, commit, dispatch }, entity) {
        commit('infoModalVisible', true);
        commit('infoModalData', {
            icon: {
                name: 'exclamation-outline',
                class: 'exclamation-outline',
            },
            text: `Are you sure you want to delete ${entity.is_draft ? 'the draft' : 'this entity'}? You cannot undo this action.`,
            title: 'Delete',
            confirmBtnText: 'Delete',
            lightBtnStyle: true,
            onConfirmAction: () => {
                commit('infoModalActionLoading', true);
                dispatch('deleteEntity', entity.id)
                    .finally(() => commit('infoModalActionLoading', false));
            }
        });
    },
    hideInfoModal({ commit }) {
        commit('infoModalVisible', false);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
