const state = {
    settingsData: '',
    loading: false,
    administrators: '',
    pageTitles: '',
    organizations: [],
};

const mutations = {
    // SETTINGS DATA
    SET_SETTINGS_DATA(state, settingsData) {
        state.settingsData = settingsData;
        state.organizations = settingsData.organizations;
        state.pageTitles = settingsData.pageTitles;
    },
    // ADMINISTRATORS DATA
    SET_ADMINISTRATORS_DATA(state, data) {
        state.administrators = data;
    },
    // LOADING
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
};

const actions = {
    setPageTitle({state}){
        let url = new URL(window.location);
        let tab = url.searchParams.get("tab");
        let title = document.title;
        if (state.pageTitles[tab]) {
            title = state.pageTitles[tab];
            document.title = title;
        }
        if (typeof gtag === 'function') {
            gtag('event', 'screen_view', {
                screen_name: title,
            });
        }
    },
    // SETTINGS DATA
     async fetchSettingsData({commit, dispatch}) {
        commit("SET_LOADING", true)
         await axios.get("/settings/get-settings-data")
            .then((res) => {
                commit("SET_SETTINGS_DATA", res.data);
                dispatch('setPageTitle');
            })
            .finally(() => commit("SET_LOADING", false));
    },
    // ADMINISTRATORS DATA
    async fetchAdminsData({commit}) {
        await axios.get('/settings/security/get-administrators')
            .then(response => {
                commit("SET_ADMINISTRATORS_DATA", response.data);
            })
    },
};

const getters = {
    // LOADING
    getLoading: (state) => state.loading,
    // SETTINGS DATA
    getSettingsData: (state) => state.settingsData,
    // ADMINISTRATORS DATA
    getAdministrators: (state) => state.administrators,
    // ORGANISATIONS DATA
    getOrganizations: (state) => state.organizations,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
