import axios from "axios";
export const commonActions = {
    setLoader({ commit }, value) {
        commit('LOADER', value);
    },
    setAlert({ commit }, alert) {
        commit('SET_ALERT', alert);
    },
    closeAlert({ commit }) {
        commit('CLOSE_ALERT');
    },
    setAlertModal({ commit }, alertModal) {
        commit('SET_ALERT_MODAL', alertModal);
    },
    closeAlertModal({ commit }) {
        commit('CLOSE_ALERT_MODAL');
    },
};
