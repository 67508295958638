<template>
    <BaseScreenLoading v-if="loader" />

<!--    <v-overlay :value="loader">
        <v-avatar size="12rem">
            <v-img :src="$store.state.imagePath+'logo-animation.svg'"></v-img>
        </v-avatar>
    </v-overlay>-->
</template>
<script>
export default {
    name: 'Loader',
    props: {
        value: {
            default: false
        }
    },
    computed: {
        loader(){
            return this.value || this.$store.state.loader;
        }
    }
}
</script>
