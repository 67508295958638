<template>
    <div class="col-12 onboarding-step-container organisation-details-step step-1">
        <div class="d-flex flex-column align-items-center mt-57">
            <div>
                <h2 class="heading-1 bold-text color-black text-center mb-1">
                    Enter organisation details
                </h2>
            </div>
            <div class="payment-payoneer-container mw-550 mt-3">
                <div class="payment-receipt-wrapper border-0 shadow rounded-8 mb-120">
                    <div class="payment-receipt-body self-serve">
                        <form>
                            <div class="form-group mb-15">
                                <div class="application-status-tag fulfilled p-12">
                                    <i class="icon-info-circle color-feedback-info mr-9"></i>
                                    <p class="medium-text color-pale-black-color">
                                        You can change this information later
                                    </p>
                                </div>
                            </div>
                            <div class="form-group mb-20">
                                <tm-input
                                    label="Organisation name"
                                    v-model="obj.values.organization.name"
                                    v-validate="{ required: true, min: 3, regex: /^[a-zA-Z\d\s]+$/ }"
                                    name="name"
                                    data-vv-as="Organisation name"
                                    :error-messages="errors.collect('name')"
                                ></tm-input>
                            </div>
                            <div class="form-group mb-20">
                                <tm-input
                                    label="Organisation email"
                                    :value="$store.state.info.email"
                                    name="email"
                                    :disabled="true"
                                    data-vv-as="Email"
                                    :error-messages="errors.collect('email')"
                                >
                                    <template slot="label">
                                        <label class="font-weight-medium black--text d-flex black--text tm-small-text mb-1">
                                            <span>Organisation email</span>
                                        </label>
                                    </template>
                                </tm-input>
                            </div>
                            <div class="form-group mb-0">
                                <tm-input
                                    v-model="obj.values.organization.company_number"
                                    v-validate="obj.rules.organization.company_number"
                                    label="Company number: (optional)"
                                    type="text"
                                    name="company_number"
                                    data-vv-as="Company Number"
                                    :error-messages="errors.collect('company_number')"
                                />
                            </div>
                            <template v-if="isBrandedEmployer">
                                <tm-input
                                    data-vv-scope="organization"
                                    v-model="obj.values.organization.brand_client_email"
                                    v-validate="obj.rules.organization.brand_client_email"
                                    label="Admin Email"
                                    :label-bottom-offset="false"
                                    label-class-val="tm-small-text mb-7"
                                    type="text"
                                    class="w-100 mb-3"
                                    :bottom-offset="false"
                                    name="brand_client_email"
                                    data-vv-as="Admin Email"
                                    :error-messages="errors.collect('organization.brand_client_email')"
                                />
                            </template>
                            <div class="form-group mb-0">
                                <tm-avatar-input
                                    ref="logoInput"
                                    v-model="obj.values.organization.logo_file"
                                    v-validate="obj.rules.organization.logo_file"
                                    :crop="true"
                                    :crop-rounded="true"
                                    :crop-height="160"
                                    :crop-width="160"
                                    :crop-dialog-width="400"
                                    name="logo"
                                    :error-messages="errors.collect('logo')"
                                    :size="96"
                                    data-vv-as="Organisation Logo"
                                >
                                    <template slot="preview">
                                        <img :src="$store.state.imagePath + 'org-logo.png'" alt="logo">
                                    </template>
                                </tm-avatar-input>
                                <p class="medium-text color-dark-grey mb-3">
                                    Please add your organisation logo
                                </p>
                                <button @click.prevent.stop="$refs.logoInput.update()" class="btn btn-outline-purple btn-upload medium-text bold-text color-purple">
                                    Upload
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TmInput from "~/shared/components/form/TmInput";
import TmAvatarInput from "~/shared/components/form/TmAvatarInput";
export default {
    name: 'EmployerDetails',
    components: {TmAvatarInput, TmInput},
    props: {
        obj: {
            required: true,
            type: Object
        }
    },
    data(){
        return {
            isBrandedEmployer: this.$store.state.isBrandedEmployer ?? false,
        }
    },
}
</script>
