<template>
    <v-date-picker
        v-model="val"
        v-bind="$attrs"
        v-on="$listeners"
        :min="calcMin"
        :max="calcMax"
        prev-icon="icon-chevron-left"
        next-icon="icon-chevron-right"
        :picker-date.sync="pickerDate"
        :events="computedEvents"
        :allowed-dates="allowedDates"
    ></v-date-picker>
</template>

<script>
import {format, parseISO} from "date-fns";

export default {
    name: "BaseDatePicker",

    props: {
        value: {
            type: Array,
            default: () => [],
        },
        events: {
            type: Array,
            default: () => [],
        },
        reservedDates: {
            type: Array,
            default: () => [],
        },
        isAbsenceManagement: {
            type: Boolean,
            default: false,
        },
        defaultToday: {
            type: Boolean,
            default: false,
        },
        min: {
            default: null,
        },
        max: {
            default: null,
        },
    },

    data() {
        return {
            val: this.value,
            today: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
        };
    },

    watch: {
        val() {
            this.$emit("input", this.val);
        },
        value() {
            this.val = this.value;
        },
    },

    methods: {
        setDateParams(value) {
            if (!value) return null;
            if (value.search("T")) {
                value = value.split("T")[0];
            }
            const [year, month, day] = value.split("-");
            return `${year ? year + "-" : "1970-"}${month ? month + "-" : "01-"}${
                day || "01"
            }`;
        },

        getBorderDate(value) {
            return value === "today"
                ? new Date().toISOString().split("T")[0]
                : this.setDateParams(value);
        },
        computedEvents(date) {
            const eventColors = [];

            if (this.isAbsenceManagement) {
                const event = this.events.find((event) => event.date === date);

                if (event) {
                    if (event.hasPublicDay)
                        eventColors.push("bg-feedback-success-normal");
                    if (event.hasSickDay) eventColors.push("bg-feedback-error-normal");
                    if (event.hasHolidayDay) eventColors.push("bg-purple");
                    return eventColors;
                }
            }

            return false;
        },

        allowedDates(date) {
            return !this.reservedDates.includes(date);
        },
    },

    computed: {
        calcMin() {
            return this.getBorderDate(this.min);
        },

        calcMax() {
            return this.getBorderDate(this.max);
        },
        pickerDate: {
            get() {
                return this.value[0];
            },
            set() {
                if (this.value.length > 1) return this.value[0];
                return undefined;
            },
        },
    },
};
</script>
