<template>
    <div @click="checkKYC()" class="btn-quick-action">
        <a class="d-flex flex-column align-center">
            <div class="icon-container">
                <BaseIcon :name="icon" class="mr-0"/>
            </div>
            <p class="text-sm font-medium text-center w-full">
                <slot></slot>
            </p>
        </a>

        <ModalWithdrawMoney
            v-if="action.action === 'send_money'"
            :dialog="isModalWithdrawMoneyVisible"
            @close-modal="closeModalWithdrawMoney"
            @withdrawal-now="actionRedirect"
        />

        <ModalConvertCurrency
            v-else-if="action.action === 'convert'"
            v-model="convertDialog"
        />
    </div>
</template>

<script>
import Helpers from "~/shared/mixins/Helpers";
import ModalWithdrawMoney from "~/modules/wallet/components/WalletDashboard/Modals/ModalWithdrawMoney.vue";
import ModalConvertCurrency from "~/modules/wallet/components/WalletDashboard/Modals/ModalConvertCurrency.vue";
export default {
    name: "QuickActionButton",
    mixins: [Helpers],
    components: {
        ModalConvertCurrency,
        ModalWithdrawMoney
    },
    props: {
        icon: {
            type: String,
            default: "",
        },
        href: {
            default: "",
        },
        kyc: {
            default: null,
        },
        action: {
            default: null,
        },
    },
    data() {
        return {
            isModalWithdrawMoneyVisible: false,
            convertDialog: false
        }
    },
    methods: {
        checkKYC(){
            if (!this.kyc){
                return this.doAction();
            }

            this.__checkVerified(this.kyc).then(verified => {
                if (verified){
                    this.doAction();
                }
            });
        },
        closeModalWithdrawMoney() {
            this.isModalWithdrawMoneyVisible = false;
        },
        actionRedirect(){
            this.__changeRoute(this.href);
            this.$store.commit('globalMenuModule/TOGGLE_MENU', false);
        },
        doAction(){
            /*Do action*/
            if (this.action.action === 'add_organization'){
                this.showAddOrg()
            }else if (this.action.action === 'send_money'){
                this.actionRedirect()
            }else if (this.action.action === 'convert'){
                this.convertDialog = true;
            }else{
                this.actionRedirect();
            }
        },
        withdrawModal(){
            this.isModalWithdrawMoneyVisible = true
        },
        showAddOrg() {
            this.__changeRoute('/create-organization');
            /*
            this.$store.dispatch('changeGreetingSteps', {firstOpenThenFetch: true}).then(() => {
                this.menu = false;
            });*/
        },
    },
};
</script>

<style scoped>
.btn-quick-action {
    height: auto !important;
    padding: 0 !important;
    max-width: 100%;
    cursor: pointer;

    & > div {
        width: 100%;
    }
    &:hover {
        .icon-container {
            border: 1px solid #7c8dff;
            background: #7c8dff;
        }

        p {
            color: #757779 !important;
        }
    }
}

p {
    color: #919396;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 115% !important;
    transition: color, background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
}

.icon-container {
    transition: color, background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: 0.5rem;
    border-radius: 18px;
    border: 1px solid #5152fb;
    background: #5152fb;
    width: 3.5rem !important;
    height: 3.5rem !important;
    padding: 0 !important;
    flex-wrap: wrap;

    i {
        font-size: 1.5rem;
        color: white;
    }
}
</style>
