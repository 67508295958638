/** @format */
/** @format */

import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';

import * as absence from '~/components/AbsenceManagement/store.js';
import * as knowledgeBase from '~/components/KnowledgeBase/store.js';
import * as documents from '~/components/Documents/store.js';
import * as taxManagement from "~/components/TaxManagement/store.js";
import members from '~/components/Members/store.js';
import globalPayrollCalculator from '~/components/GlobalPayrollCalculator/store/index';

import banks from './modules/banks';
import * as documentsNew from './modules/documents.js';
import navigation from './modules/navigation';
import benefits from './modules/benefits';
import benefitsNew from './modules/benefitsNew';
import cardService from './modules/card-service';
import cases from './modules/cases';
import countries from './modules/countries';
import employmentHub from './modules/employment-hub';
import entities from './modules/entities';
import hubspot from './modules/hubspot';
import networkError from './modules/networkError';
import * as screen from './modules/screen';
import payoneer from './modules/payoneer';
import payroll from './modules/payroll';
import planSettings from './modules/plan-settings';
import * as security2fa from './modules/security2fa';
import sessions from './modules/sessions';
import * as sidebar from './modules/sidebar';
import soloEntity from './modules/solo-entity';
import tellmoney from './modules/tellmoney';
import confirmation from "./modules/confirmation";
import wallet from "./modules/wallet";
import benefitsView from '~/modules/dashboard/views/Benefits/store';
import * as taxReporting from '~/components/TaxReporting/store'
import expenses from '~/modules/dashboard/views/ExpenseRequest/store';
import allowances from './modules/allowances';
import * as taxHub from '~/components/TaxOnboarding/store/taxHub';
import contactsDialog from '~/components/Contact/store/index';
import * as notifications from './modules/notifications';
import settingsPage from './modules/settings';
import bulk from './modules/bulk';
import * as teamMemberSettings from "./modules/settings.js";
import * as teamMemberDocuments from "./modules/documents.js";
import * as teamMemberTickets from "./modules/tickets.js";
import people from './modules/people';
import * as signUp from "./modules/signUp";
import * as globalMenuModule from './modules/globalMenu';
import * as auth from './modules/auth'
import * as initialization from './modules/initialization';
import * as contracts from './modules/contracts';
import * as stripe from './modules/stripe';
import contacts from './modules/contacts';
import * as contactsWallet from './modules/contactsWallet';

import { commonStates } from './mixins/commonStates';
import { commonMutations } from './mixins/commonMutations';
import { commonActions } from './mixins/commonActions';

/*Wallet imports*/
import * as dashboard from "./modules/dashboard.js";
import * as kyc from "./modules/kyc.js";
import * as wlSecurity2fa from "./modules/wlSecurity2fa";
import * as users from "./modules/users.js";
import paymentRequest from "./modules/paymentRequest.js";
import cardModule from "./modules/card.js";
import countriesModule from './modules/countries';
import * as upsellBanners from "./modules/upsellBanners.js";
import * as global from "./modules/global.js";

//named imports
import { actions, getters, mutations, namespaced, state } from './modules/users.js';
import _ from "lodash";
const usersModule = {
    namespaced,
    state,
    getters,
    mutations,
    actions
};

Vue.use(Vuex);

const DEFAULT_CONFIRMATION_UPGRADE_ACTION_TEXT = 'make this action';

export default new Vuex.Store({
    modules: {
        screen,
        navigation,
        documents,
        paymentRequest,
        documentsNew,
        absence,
        knowledgeBase,
        taxManagement,
        members,
        security2fa,
        sessions,
        globalPayrollCalculator,
        soloEntity,
        banks,
        benefits,
        benefitsNew,
        countries,
        cases,
        planSettings,
        payoneer,
        cardService,
        tellmoney,
        payroll,
        employmentHub,
        entities,
        hubspot,
        networkError,
        confirmation,
        sidebar,
        wallet,
        benefitsView,
        taxReporting,
        expenses,
        allowances,
        taxHub,
        contactsDialog,
        notifications,
        settingsPage,
        bulk,
        teamMemberSettings,
        teamMemberDocuments,
        teamMemberTickets,
        people,
        globalMenuModule,
        contracts,
        stripe,
        contacts,
        contactsWallet,

        cardModule,
        usersModule,
        countriesModule,
        kyc,
        users,
        upsellBanners,
        wlSecurity2fa,
        dashboard,
        signUp,
        auth,
        initialization,
        global
    },
    state: {
        defaultTitle: 'Native Teams',
        defaultState: {},
        supportEmail: 'support@nteams.com',
        styleSystem: null,
        isIndividual: false,
        isNativeTeamOrg: false,
        batchTransfer: false,
        paymentRequestTransfer: false,
        addMoneyDialog: false,
        sendMoneyDialog: false,
        activeWalletId: null,
        hasCardPermission: false,
        taxPath: '/data/tax-optimiser/',
        bookCall: '',
        loadImg: '/images/output-onlinegiftools.gif',
        refUrl: null,
        refEarningPercent: 0,
        monthNames: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        transferTypes: false,
        wlstyle: {},
        hasWithdrawAccess: false,
        stripeFee: false,
        isNigerianUser: false,
        accessAffiliates: false,
        accessReferrals: true,
        couponCodeNg: '',
        walletCurrencyDocVerified: {},
        walletCurrencyDoc: [],
        rewardBanks: [],
        allowedRewardCurrencies: [],
        walletCurrencyDocModal: false,
        signedModal: false,
        newDocuments: false,
        primaryWorker: null,
        employerWelcome: false,
        addOrg: false,
        employmentWelcomeRedirect: false,
        terms: null,
        newMembersPlans: null,
        defaultPlanTypeId: null,
        newOrganization: null,
        newMembersRole: null,
        comingSoonCountries: null,
        supportedCountries: [],
        accessJobs: false,
        worker: null,
        permissions: [],
        organizations: [],
        path: '/',
        pathApi: '/',
        getPath: '/get/',
        createPath: '/data/create/',
        appUrl: process.env.VUE_APP_ROOT_BASE,
        settings: {},
        allPlans: [],
        invitePlans: [],
        allRoles: [],
        minCurrencyAmountForStripe: [],
        editablePlanFeatures: [],
        allCountries: [],
        allPossibleCurrencies: [],
        stripeRegisteredCurrencies: [],
        transferPercent: [],
        workerCountries: [],
        planTypes: [],
        closeInvoices: [],
        localCurrency: {},
        defaultCards: [],
        cards: [],
        currencyCards: [],
        startReference: '',
        greetingSteps: false,
        onboardingProgress: 0,
        startedEorAsGuest: false,
        goalsReviews: [],
        window: {
            height: 0,
            width: 0,
        },
        brochureUrl: process.env.VUE_APP_BROCHURE_URL,
        wallets: [],
        notAvailableWallets: [],
        disabledWalletsWithAvailable: [],
        defaultWallet: null,
        selectedWallet: null,
        soloEntityWallet: null,
        payByInvoice: null,
        info: {},
        jobPreference: {},
        systemWallets: [],
        socialTypes: [],
        upgradeDialog: false,
        loader: false,
        holidays: [],
        settingsTeamQuery: {},
        payrollCountries: [],
        payrollCountriesCodesWithCalculator: [],
        accountManager: [],

        successDialog: false,
        successDialogData: {title: '', description: '', closeButtonText: '', descriptionClass: '', className: ''},

        secureCardObj: {
            form: null,
            done: false,
            dialog: false,
            succeed: undefined,
            url: null,
            callback: null,
            callbackSucceed: null,
            callbackFailed: null,
            closeWithReload: false
        },
        radarSessionId: null,
        workerBalance: {},

        walletType: 'default',
        getSpendData: false,
        getSpendSearchData: {},
        spendWalletId: null,
        taxOptimiserCountryId: null,
        euCountryCodes: [],
        eeaCountryCodes: [],
        nativeCountryCodes: [],
        sendMoney2Fa: true,
        invoiceCreateModal: false,
        saveInvoiceDraft: false,
        invoicesIsEmpty: false,
        payoneerParams: {},
        paymentRequestExist: false,
        confirmationUpgradeDialog: false,
        confirmationUpgradeDialogActionText: DEFAULT_CONFIRMATION_UPGRADE_ACTION_TEXT,
        netWorkError: false,
        isSoloEntityWalletSendMoney: false,
        hubspotTrackingCodeEnabled: false,
        ...commonStates,

        /*Wallet state*/
        cardsNewUiEnabled: false,
        staticStorageUrl: '',
        systemEmails: [],
        basePath: '/',
        tooManyRequestsModal: false,
        backRoute: {},
    },

    getters: {
        currencies: (state) => state.allPossibleCurrencies.filter(c => c.available),
        allCurrenciesTogether: (state) => state.allPossibleCurrencies.filter(c => c.allowed),
        withdrawCurrencies: (state) => state.allPossibleCurrencies.filter(c => c.withdraw),
        currenciesInvoice(state, getters) {
            return getters.currencies.filter(i => !state.closeInvoices.includes(i.code));
        },
        successDialogData: state => state.successDialogData,
        selectedWallet: state => {
            if (state.selectedWallet) {
                return state.selectedWallet;
            } else if (state.wallets.length) {
                return state.wallets[0];
            }
            return null;
        },

        wallets: state => state.wallets,
        allWallets: state => {
            return state.soloEntityWallet
                ? [...state.wallets, state.soloEntityWallet]
                : state.wallets;
        },
        modulrCardRQ: state => {
            if (state.authUser.current_worker_id === state.organization.creator_id) {
                return state.organization.modulr_card_request || state.primaryWorker.native_card_request; //backward comp
            }else{
                return state.worker.modulr_card_request || state.primaryWorker.native_card_request;
            }
        },
        euCountriesWithGB(state) {
            let euCountriesWithGB = state.euCountryCodes
            euCountriesWithGB.push('GB')
            return euCountriesWithGB.sort()
        },
        euAndEeaCountriesWithGB(state) {
            let euCountriesWithGB = state.euCountryCodes
            let eeaCountriesWithGB = state.eeaCountryCodes
            let combine = euCountriesWithGB.concat(eeaCountriesWithGB);
            combine.push('GB')

            return combine.sort()
        },
        nativeCountriesCodes(state) {
            let nativeCountryCodes = state.nativeCountryCodes;
            return nativeCountryCodes.sort();
        },
        primaryCard(state) {
            return state.cards.find(card => card.primary);
        },
    },

    mutations: {
        SET_DEFAULT_STATE(state, payload){
            state.defaultState = payload;
        },
        secureCardSetSucceed(state, succeed) {
            state.secureCardObj.succeed = succeed;
            state.secureCardObj.done = true;
        },
        setNetWorkError(state, errorValue) {
            state.netWorkError = errorValue;
        },
        showSecureCardDialog(state, data) {
            state.secureCardObj.form = data.form || null;
            state.secureCardObj.url = data.url;
            state.secureCardObj.callback = data.callback || null;
            state.secureCardObj.callbackSucceed = data.callbackSucceed || null;
            state.secureCardObj.callbackFailed = data.callbackFailed || null;
            state.secureCardObj.closeWithReload = data.closeWithReload || false;
            state.secureCardObj.dialog = true;
        },
        closeSecureCardDialog(state) {
            if ('undefined' !== typeof state.secureCardObj.succeed) {
                if (
                    state.secureCardObj.succeed &&
                    (state.secureCardObj.callbackSucceed || state.secureCardObj.callback)
                ) {
                    const callback = state.secureCardObj.callbackSucceed || state.secureCardObj.callback;
                    callback();
                }
                if (
                    !state.secureCardObj.succeed &&
                    (state.secureCardObj.callbackFailed || state.secureCardObj.callback)
                ) {
                    const callback = state.secureCardObj.callbackFailed || state.secureCardObj.callback;
                    callback();
                }
            }

            if (!state.secureCardObj.succeed && state.secureCardObj.closeWithReload) {
                window.location.reload();
            }

            state.secureCardObj = {
                form: null,
                done: false,
                dialog: false,
                succeed: undefined,
                url: null,
                callback: null,
                callbackSucceed: null,
                callbackFailed: null,
                closeWithReload: false
            };
        },

        hideSuccessDialog(state) {
            state.successDialog = false;
            state.successDialogData = {title: '', description: '', closeButtonText: '', descriptionClass: '', className: ''};
        },

        addMoney(state, val) {
            state.addMoneyDialog = val;
            if ('function' === typeof gtag) {
                gtag('event', 'click_cta', {
                    event_category: 'add_money',
                    event_label: 'Add Money',
                });
            }
        },
        openBatchTransfer(state, val) {
            state.batchTransfer = val;
            if ('function' === typeof gtag) {
                gtag('event', 'click_cta', {
                    event_category: 'batch_transfer',
                    event_label: 'Batch Transfer',
                });
            }
        },
        openPaymentRequestTransfer(state, val) {
            state.paymentRequestTransfer = val;
            if ('function' === typeof gtag) {
                gtag('event', 'click_cta', {
                    event_category: 'payment_request',
                    event_label: 'Payment Request Transfer',
                });
            }
        },
        sendMoney(state, val) {
            state.sendMoneyDialog = val;
            if ('function' === typeof gtag) {
                gtag('event', 'click_cta', {
                    event_category: 'send_money',
                    event_label: 'Send Money',
                });
            }
        },
        setData(state, values) {
            state[values.key] =
                values.data && 'object' === typeof values.data && !Array.isArray(values.data)
                    ? {...values.data}
                    : values.data;
        },
        updateData(state, values) {
            for (const [objKey, objValue] of Object.entries(values.data)) {
                state[values.key][objKey] =
                    objValue && 'object' === typeof objValue && !Array.isArray(objValue) ? {...objValue} : objValue;
            }
        },
        setAlert(state, alert) {
            if (!state.netWorkError){
                state.alert.value = true;
                state.alert.type = alert.type;
                state.alert.message = alert.message;
            }
        },
        closeAlert(state) {
            state.alert.value = false;
            state.alert.type = null;
            state.alert.data = {};
        },
        setAlertModal(state, alertModal) {
            state.alertModal = {
                value: true,
                type: alertModal.type,
                data: {title: '', text: '', btnText: 'Close', icon: {name: 'check-circle-outline', class: 'color-feedback-error'}},
                onClose: (() => {}),
            };

            if(state.alertModal.type === 'error'){
                state.alertModal.data = Object.assign(state.alertModal.data, {
                    icon: {name: 'exclamation-outline', class: 'color-feedback-error'}//https://app.asana.com/0/1201373713566097/1206292244155238
                });
            } else if ( state.alertModal.type === 'warning' ) {
                state.alertModal.data = Object.assign(state.alertModal.data, {
                    icon: {name: 'warning', class: 'color-feedback-warning'}
                });
            }else if ( state.alertModal.type === 'info' ) {
                state.alertModal.data = Object.assign(state.alertModal.data, {
                    icon: {name: 'info-circle-outline', class: 'color-feedback-info'}
                });
            }else{
                state.alertModal.data = Object.assign(state.alertModal.data, {
                    icon: {name: 'check-circle-outline', class: 'color-feedback-success'}
                });
            }

            state.alertModal.data = Object.assign(state.alertModal.data, {
                title: alertModal?.data?.title || state.alertModal.data.title,
                text: alertModal?.data?.text || state.alertModal.data.text,
                asHtml: alertModal?.data?.asHtml || state.alertModal.data.asHtml,
                btnText: alertModal?.data?.btnText || state.alertModal.data.btnText,
            });

            state.alertModal.onClose = alertModal.onClose || (() => {});
        },
        closeAlertModal(state) {
            state.alert.value = false;
            state.alert.type = null;
            state.alertModal.data = {title: '', text: '', btnText: '', icon: {name: 'check-circle-outline', class: 'color-feedback-error'}};
        },

        loader(state, value) {
            state.loader = value;
        },
        showOrgDialog(state) {
            state.addOrg = 1;
            state.employerWelcome = 1;
        },
        employmentWelcomeRedirectDialog(state) {
            state.employmentWelcomeRedirect = true;
        },
        hideOrgDialog(state) {
            state.addOrg = 0;
            state.employerWelcome = 0;
        },
        confirmationUpgradeDialog(state, payload) {
            state.confirmationUpgradeDialog = payload.show;
            if (payload.show) {
                state.confirmationUpgradeDialogActionText = payload?.text || DEFAULT_CONFIRMATION_UPGRADE_ACTION_TEXT;
            }
        },
        setTooManyRequestsModalModal(state,payload){
            state.tooManyRequestsModal = payload;
        },
        ...commonMutations,
    },

    actions: {
        __clearState({state}){
            const obj = JSON.parse(JSON.stringify(state.defaultState))
            for (const [objKey, objValue] of Object.entries(obj)) {
                state[objKey] = objValue;
            }
        },
        returnResourceByName(context, data) {
            let resource = data.resources.filter(resource => resource.type === data.name);
            if (!resource.length)
                return null;
            return resource[0].items;
        },
        sendMoneyWith2Fa({state, commit}, value) {
            return new Promise((resolve) => {
                commit('setData', {key: 'sendMoney2Fa', data: value});
                resolve({'sendMoney2Fa': state.sendMoney2Fa});
            });
        },
        updateUserOptions({commit, state}, payload) {
            axios.post(state.path + 'update-options', {...payload}).then(r => {
                commit('updateData', {key: 'authUser', data: r.data || []});
            });
        },
        getNewDocNode({commit, state}, e = {}) {
            axios.get(state.path + 'documents/get/new-node', {...e}).then(r => {
                commit('setData', {key: 'newDocuments', data: r.data || []});
            });
        },
        getHolidays({commit, state}, e = {}) {
            axios.get(state.path + 'documents/get/new-node', {...e}).then(r => {
                commit('setData', {key: 'holidays', data: r.data || []});
            });
        },
        getGoalsReviews({commit, state}, e = {}) {
            axios.post(state.path + 'goals-reviews/data', {...e}).then(r => {
                commit('setData', {key: 'goalsReviews', data: r.data || []});
            });
        },
        getManager({commit, state}, id) {
            axios.get(state.getPath + `manager/${id}`).then(r => {
                commit('setData', {key: 'accountManager', data: r.data.manager || []});
            });
        },
        changeGreetingSteps({commit, state}, params = {}) {
            if (state.createOrganizationDataReady && !params.storeData) return commit('showOrgDialog');
            if (params.firstOpenThenFetch) commit('showOrgDialog');

            axios.get(state.getPath + 'organization/info', {params}).then(({data}) => {
                const {
                    new_members_plans,
                    default_plan_type_id,
                    other_countries,
                    new_organization,
                    coming_soon_countries,
                    new_members_role,
                    all_countries,
                    pay_by_invoice,
                    terms,
                } = data;
                commit('setData', {key: 'terms', data: terms || {}});
                commit('setData', {key: 'newMembersPlans', data: new_members_plans || {}});
                commit('setData', {key: 'defaultPlanTypeId', data: default_plan_type_id || {}});
                commit('setData', {key: 'newOrganization', data: new_organization || {}});
                commit('setData', {key: 'newMembersRole', data: new_members_role || {}});
                commit('setData', {key: 'otherCountries', data: other_countries || {}});
                commit('setData', {key: 'comingSoonCountries', data: coming_soon_countries || {}});
                commit('setData', {key: 'allCountries', data: all_countries || {}});
                commit('setData', {key: 'payByInvoice', data: pay_by_invoice || {}});
                commit('setData', {key: 'createOrganizationDataReady', data: true});
                if (!params.storeData && !params.firstOpenThenFetch) commit('showOrgDialog');
            });
        },
        getMyOrganizations({commit, state}) {
            axios.get(state.getPath + 'get-my-organizations').then(({data}) => {
                commit('setData', {key: 'organizations', data});
            });
        },
        updateWallets({commit, state}) {
            axios
                .get(state.getPath + 'wallets')
                .then(response => {
                    commit('setData', {key: 'wallets', data: response.data});
                })
                .catch(error => {
                    commit('setAlert', {type: 'error', message: error.response.data.message});
                });
        },
        async sendMoney({commit, dispatch, state, rootGetters}, value) {
            let isEuro = state.selectedWallet?.currency?.code === 'EUR';
            if (!state.selectedWallet) {
                isEuro = state.activeWallet?.currency?.code === 'EUR';
            }

            if (!await dispatch('kyc/__checkVerified', {
                permission: state.KYCPermissionMoneyOut,
                level: state.KYCLevel2
            })){
                return;
            }

            //by request and only for EUR
            if (!rootGetters['security2fa/enable'] && state.sendMoney2Fa && value && isEuro) {
                return dispatch('security2fa/showConfirmation2fa', 'send money', {root: true});
            }
            commit('sendMoney', value);
        },
        showConfirmationUpgrade({ commit }, text) {
            commit('confirmationUpgradeDialog', {
                show: true,
                text,
            });
        },
        hideConfirmationUpgrade({ commit }) {
            commit('confirmationUpgradeDialog', { show: false });
        },
        __setTooManyRequestsModal({commit}, payload) {
            commit('setTooManyRequestsModalModal', payload)
        },
        ...commonActions,
    },
});
