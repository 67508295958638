import Model from '~/shared/Model';

export default class Card extends Model{

    constructor(obj = {}) {
        super();

        this.hidden = ['id']

        this.values = {
            id: obj.id || null,
            holder: obj.holder || '',
            number: obj.number || '',
            expiration_date: obj.expiration_date || '',
            cvv: obj.cvv || '',
            default_org: obj.default_org || '',
            primary: 1,
            type: obj.type || 'billings',
            currency_id: obj.currency_id,
            organization_id: obj.organization_id,
            radar_session: obj.radar_session || null,
            billing_address: obj.billing_address ||  {
                city: null,
                country: null,
                line1: null,
                line2: null,
                postal_code: null,
                state: null
            },
            for_worker:obj.for_worker || '',
            all_cards: 0,
        }

        this.rules = {
            currency_id: 'required',
            holder: 'required|regex:^[a-zA-Z\\s\\-\'\.]+$|min:3|max:100',
            number: 'required|max:24',
            expiration_date: 'required|date_format:mm/yy|date_between:'+('0' + (new Date().getMonth()+1)).slice(-2)+'/'+new Date().getFullYear().toString().slice(-2)+','+('0' + (new Date().getMonth()+1)).slice(-2)+'/40',
            cvv: 'required|min:3|max:5',
            primary: '',
            billing_address: {
                city: 'required|regex:^[a-zA-Z\\s\\-\'\.]+$|min:3|max:100',
                country: 'required',
                line1: 'required|min:3|max:100',
                line2: 'min:3|max:100',
                postal_code: 'required|min:3|max:100',
                state: 'required|regex:^[a-zA-Z\\s\\-\'\.]+$|min:2|max:100',
            }
        };
        this.messages = {
            custom: {
                expiration_date: {
                    date_format: 'The expiration date must be in the format MM/YY'
                },
            }
        }

        this.setValues(obj)
    }

    setValues(obj){
        if (obj.id){
            this.values.expiration_date = `${obj.exp_month}/${obj.exp_year.toString().slice(-2)}`
            this.values.holder = obj.holder;
        }
    }

    someEqualsFieldsAreEmpty() {
        return [
            'holder',
            'number',
            'expiration_date',
            'cvv',
        ].some(field => !this.values[field]);
    }

    setAllCardsParam() {
        this.values.all_cards = 1;
    }
}
