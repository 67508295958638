<template>
    <div
        class="d-flex align-center justify-center"
        :class="['px-'+px, 'py-'+py, contentClass, bottomOffset? 'mb-9': '']"
        style="width: fit-content;"
        :style="{'background-color': background, 'border-radius': rounded? '0.3125rem': 0, border: borderColor? '1px solid '+borderColor: null}"
    >
        <span class="tm-small-text" :class="'font-weight-'+fontWeight" :style="{color: color, 'font-size': fontSize? fontSize+ rem? 'rem' : 'px': null}">
            {{ text }}
            <slot name="default"></slot>
        </span>
    </div>
</template>
<script>
export default {
    name: 'Chip',
    props: {
        color: {
            default: '#838a90'
        },
        contentClass: {
            default: ''
        },
        text: {
            default: null
        },
        fontWeight: {
            default: 'medium'
        },
        rounded: {
            default: true
        },
        rem: {
            default: false
        },
        bottomOffset: {
            default: true
        },
        backgroundColor: {
            default: null
        },
        borderColor: {
            default: null
        },
        fontSize: {
            default: null
        },
        px: {
            default: 2
        },
        py: {
            default: 1
        }
    },
    computed: {
        background(){
            if (this.backgroundColor){
                return this.backgroundColor;
            }
            let rgb = this.hexToRgb(this.color);
            return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.2)`
        }
    },
    methods: {
        hexToRgb(hex) {
            let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        }
    }
}
</script>
