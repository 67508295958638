<template>
    <div>
        <h2 v-html="__cleanHtml(terms.title)" class="font-weight-bold mb-4 normal-text-5 text-center"></h2>
        <p v-html="__sanitizeHtml(terms.last_updated)" class="text-center mb-8"></p>
        <div v-for="(section, sIndex) in terms.sections" :key="sIndex" class="mb-6">
            <h4 v-html="__cleanHtml(section.title)"></h4>
            <template v-for="paragraph in section.paragraphs">
                <template v-if="typeof paragraph === 'object'">
                    <ol v-if="paragraph.type === 'numeric_list'" style="list-style-type: auto; padding-left: 1.4rem;">
                        <li
                            v-for="(item, iIndex) in paragraph.items"
                            :key="iIndex"
                            v-html="__sanitizeHtml(item)"
                            class="small-text"
                        ></li>
                    </ol>
                    <ul v-else-if="paragraph.type === 'list'" style="list-style-type: circle; padding-left: 1.4rem;">
                        <li
                            v-for="(item, iIndex) in paragraph.items"
                            :key="iIndex"
                            class="small-text"
                            v-html="__sanitizeHtml(item)"
                        ></li>
                    </ul>
                    <template v-else-if="paragraph.type === 'sub_items'">
                        <div v-for="(item, iIndex) in paragraph.items" :key="iIndex">
                            <p
                                v-html="__sanitizeHtml(item.title)"
                                class="small-text font-weight-bold mb-1"
                            ></p>
                            <p
                                v-html="__sanitizeHtml(item.text)"
                                class="small-text mb-2"
                            ></p>
                        </div>
                    </template>
                </template>
                <p
                    v-else
                    class="small-text mb-2"
                    v-html="__sanitizeHtml(paragraph)"
                ></p>
            </template>
        </div>
    </div>
</template>
<script>
import Helpers from '~/shared/mixins/Helpers';
export default {
    name: 'TermsBox',
    mixins: [Helpers],
    props: {
        terms: {
            required: true,
            type: Object
        }
    }
}
</script>
