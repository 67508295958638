import Middleware from "~/routes/middleware/Middleware";

export default class nextPage extends Middleware{
    constructor(...args) {
        super(...args);
    }

    resolve() {
        const nextPage = this.to.query.nextPage;
        return nextPage?
            this.responseRedirect(nextPage):
            this.responseNext();
    }
}
