import {mapGetters} from "vuex";

export default {
    data(){
        return {
            ibanTab: 'EUR',
            ibanDialog: false,
        }
    },
    computed: {
        ibanNotRequested(){
            let wallet = this.walletIban;
            return !(this.__isAnySeat || this.__isFreeNigeria) && this.__checkPermission('request_iban') && this.ibanTab==='EUR' && wallet && !wallet.iban
        },
        modulrEnabled: function () {
            return process.env.VUE_APP_MODULR_ENABLED == 1 ? true : false;
        },
        walletIban() {
            let found = this.wallets.find(item => item.currency.code === this.ibanTab);
            if (this.ibanTab === 'EUR') {
                //if modulr IBAN exist we will use it instead of EUR iban
                let wlt = this.wallets.find(item => item.is_modulr);
                if (wlt) {
                    if (wlt.iban) {
                        found['iban'] = wlt.iban;
                        return found;
                    }
                }
            }
            if (found) {
                return found;
            }

            if (this.modulr()) {
                if (process.env.VUE_APP_MODULR_INTEGRATION != 'COMPACT') {
                    return this.wallets.find(item => item.is_modulr);
                }
                return this.wallets.find(item => !item.is_modulr && item.iban && item.currency.code === "EUR");
            }
            return null;
        },
    },
    watch: {

    },
    methods: {
        modulr(){
            return this.modulrEnabled;// && this.modulrCardEnabled;//card could be disabled
        }
    }
}
