<template>
    <div class="actions-container features-container">
        <v-list class="mb-auto">
            <NavListItem
                v-for="(feature, index) in modifiedFeatures"
                :key="index"
                :disabled="isDisabled(feature)"
                :icon="feature.icon"
                :badge="feature.badge"
                :href="`/${feature.route}`"
                :kyc="feature.kyc"
            >
                {{ feature.title ? feature.title : feature.name}}
            </NavListItem>
        </v-list>
        <v-btn :href="myPlansUrl" v-if="showUpgradeBtn" class="btn-primary w-full btn-upgrade">
            Upgrade
        </v-btn>
        <v-btn href="https://nativeteams.com/legal-entity" v-else-if="showLearnBtn" target="_blank" class="btn-primary w-full btn-upgrade">
            Learn more
        </v-btn>
    </div>
</template>

<script>
import NavListItem from "~/components/NewNavigation/NavListItem.vue";
import Helpers from '~/shared/mixins/Helpers';
export default {
    name: "FeaturesSection",
    components: {NavListItem},
    mixins: [
        Helpers,
    ],
    props: {
        features: {
            type: Array,
            default: () => [],
        },
        worker: {
            default: null,
        },
        availableMenu: {
            default: null,
        },
        showUpgradeBtn: {
            default: false,
        },
        showLearnBtn: {
            default: false,
        },
    },
    data() {
        return {
            baseUrl: null,
        };
    },
    created() {
        this.setBaseUrl();
    },
    computed: {
		currentWorker(){
			return this.$store.state.worker;
		},
		currentOrganization(){
			return this.$store.state.organization;
		},
        modifiedFeatures() {
            const employerPlans = ['grow', 'employer', 'client', 'client_pro'];
            const modifFeatures = [...this.features];
            const index = modifFeatures.findIndex(feature => feature.value === "people");
            if (index !== -1) {
                modifFeatures[index] = {
                    ...modifFeatures[index],
                    title: this.getNewPeopleTitle()
                };
            }
            return modifFeatures.filter(item => {
                if (
                    (this.currentOrganization.default == 1 && employerPlans.includes(this.currentWorker?.plan?.value) && item.value == 'expenses' ) ||
                    (this.currentOrganization.default == 1 && item.value == 'pay_contacts')

                ){
                    return false;
                }
                return item;
            })
        },
        myPlansUrl() {
            const legalEntityLocked = this.features.find(feature => feature.name === "Legal entity") ? true : false;
             if (this.showUpgradeBtn && legalEntityLocked)
             {
                  return `/add-ons/my-plan?plan=NativeCompany`;
             }
             return `/add-ons/my-plan`;
        },
    },
    methods: {
        getNewPeopleTitle() {
            const teamPlans = ['grow', 'employer', 'client', 'client_pro'];
            const isDefaultOrg = this.currentOrganization.default
            if (teamPlans.includes(this.currentWorker.plan.value) && !isDefaultOrg) {
                return 'Team';
            }
            return 'Contacts';
        },
        setBaseUrl() {
            this.baseUrl = this.getNewPeopleTitle() === 'Team' ? 'team' : 'contacts';
            const peopleIndex = this.features.findIndex(feature => feature.route === 'people');
            if (peopleIndex !== -1) {
                this.features[peopleIndex].route = this.baseUrl;
            }
        },
        isDisabled(feature){
            if(feature.module === 'legalEntity') return false;
            if(this.showUpgradeBtn){
                const nativeCompanyItems = ["entity_status","expenses_categorisation","tax_onboarding"];
                if (nativeCompanyItems.includes(feature.value)) return true;
            }
            if(feature.module === 'nativeCompany' && feature.name === 'Setup') return false;
            return !this.availableMenu.includes(feature.value);
        }
    },
};
</script>

<style scoped>
.features-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.v-list {
    padding: 0 !important;
    background: unset;
}

.btn-upgrade {
    margin-top: 2rem !important;
}
</style>
