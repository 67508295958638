import {__changeRoute} from "~/utils/functions";
import store from "~/store";
import router from "@/routes";

export default class axiosConfig{
    resolve() {
        window.axios = require("axios");
        window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
        window.axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
        window.axios.interceptors.response.use(
            response => {
                if (response.status === 202 && response.data.redirect) {
                    if (response.data.force) {
                        store.commit('initialization/SET_FORCE_LOADING', true);
                    }
                    __changeRoute(router, response.data.redirect, response.data.force);
                }
                return response;
            },
            error => {
                if ([419, 401].includes(error.response?.status)) {
                    store.dispatch('auth/__logout').then(() => {});
                } else
                //     if (error.response?.status === 399 && error.response?.data.value === 'stripe_unregistered_card') {
                //     this.showCardUpdate(error.response.data.card_id);/*todo: fix layouts*/
                // } else
                if (error.response?.status === 403 && error.response?.data.value === 'unverified') {
                    store.commit('kyc/TOGGLE_KYC_DIALOG', {value: true});
                } else if (error.response?.status === 403 && error.response?.data.value === 'tfa') {
                    store.dispatch('auth/__resetTfa', {type: error.response?.data.type}).then(() => {});
                }else if(error.response?.status === 429){
                    store.dispatch('__setTooManyRequestsModal',true,{root: true}).then(() => {});
                }
                return Promise.reject(error);
            },
        );
    }
}
