<template>
    <div>
        <!-- Card ordering views -->
        <KYCIntroCard v-if="cardOrdering" :card="cardOrdering" @close="close()" @start="start()" />

        <!-- Default view -->
        <KYCIntroDefault v-else @close="close()" @start="start()" />
    </div>
</template>
<script>
import KYCIntroCard from "./intros/KYCIntroCard.vue";
import KYCIntroDefault from "./intros/KYCIntroDefault.vue";

export default {
    name: 'VerificationStart',
    components: {
        KYCIntroCard,
        KYCIntroDefault
    },
    computed: {
        isStarted(){
            return this.$store.state.kyc.options.status !== 'not_started';
        },
        cardOrdering(){
            return this.$store.state.kyc.options.card;
        }
    },
    methods: {
        start(){
            this.$emit('start');
        },
        close(){
            this.$emit('close');
        }
    }
}
</script>
