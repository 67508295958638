<template>
    <v-dialog
        :width="modalData.width || null"
        v-model="dialog"
        content-class="custom-modal modal-md kyc-modal"
    >
        <div class="modal-container position-relative">
            <div class="modal-header">
                <WlBaseButton
                    @click="dialog = false"
                    class="btn-icon-sm ml-auto btn-close-modal"
                >
                    <WlBaseIcon name="times" class="text-lg" />
                </WlBaseButton>

                <div v-if="modalData.image" class="d-flex img-container justify-center w-100">
                    <img :src="modalData.image"/>
                </div>
            </div>
            <div class="modal-body" style="position: unset">
                <h2 class="heading-2 font-bold color-black mb-4" :class="modalData.titleCenter? 'text-center': ''">
                    {{ modalData.title }}
                </h2>

                <p class="text-sm leading-150" style="color: #414243">
                    {{ modalData.text }}
                </p>
            </div>
            <div class="modal-footer">
                <WlBaseButton
                    v-if="modalData.btnText"
                    type="primary"
                    class="w-full"
                    @click="__changeRoute('add-ons/my-plan')"
                >
                    {{ modalData.btnText }}
                </WlBaseButton>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import Helpers from "~/shared/mixins/Helpers";

export default {
    name: "UpgradeSSWallet",
    mixins: [Helpers],
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    data(){
        return {
            dialog: this.value,
            modalData: {
                image: `/images/new/Cyber security.svg`,
                title: `Feature locked`,
                text: `Upgrade your plan to access this feature and many more!`,
                btnText: 'Upgrade'
            }
        }
    },
    watch: {
        value(){
            this.dialog = this.value;
        },
        dialog(){
            this.$emit('input', this.dialog);
        }
    },
};
</script>
