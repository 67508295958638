<template>
    <div
        class="transaction-status-tag"
        :class="[
      statusTypeClass,
      cardStatusClass,
      { 'hide-icon': hideIcon, 'bg-tag': hasBg },
    ]"
    >
        <WlBaseIcon name="transaction-status-cancelled" v-if="type === 'error'" />
        <WlBaseIcon name="locked" v-if="type === 'locked'" />
        <WlBaseIcon
            name="transaction-status-declined"
            v-if="
        type === 'declined' ||
        type === 'inactive' ||
        type === 'blocked' ||
        type === 'deactivated' ||
        type === 'suspend' ||
        type === 'rejected' ||
        type === 'pick-up'
      "
        />
        <WlBaseIcon name="transaction-status-overdue" v-if="type === 'overdue'  || type === 'terminated'" />
        <WlBaseIcon name="transaction-status-pending" v-if="type === 'pending'" />
        <WlBaseIcon name="transaction-status-cancelled" v-if="type === 'cancelled'" />
        <WlBaseIcon name="transaction-status-reserved" v-if="type === 'reserved'" />
        <WlBaseIcon name="transaction-status-refunded" v-if="type === 'refunded'" />
        <WlBaseIcon
            name="transaction-status-completed"
            v-if="
        type === 'completed' ||
        type === 'active' ||
        type === 'available' ||
        type === 'success'
      "
        />
        <p class="text-sm font-medium">
            {{ formatStatusText(text) }}
        </p>
    </div>
</template>

<script>
export default {
    name: "WlBaseStatusTag",

    props: {
        type: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        hideIcon: {
            type: Boolean,
            default: false
        },
        hasBg: {
            type: Boolean,
            default: false
        },
        isCardStatus: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        statusTypeClass() {
            return "transaction-status-tag--" + this.type;
        },

        cardStatusClass() {
            return this.isCardStatus ? "card-status-tag--" + this.type : "";
        }
    },

    methods: {
        formatStatusText(value) {
            return value.replace(/-/g, "");
        }
    }
};
</script>

<style lang="scss" scoped>
.transaction-status-tag {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-radius: 100px;
    width: fit-content;
    border: none;

    &.bg-tag {
        &.transaction-status-tag--deactivated,
        &.transaction-status-tag--declined {
            background-color: var(--feedback-error-color);
        }

        &.transaction-status-tag--terminated,
        &.transaction-status-tag--locked,
        &.transaction-status-tag--overdue {
            background-color: #ff8a00;
            color: white;
        }

        &.transaction-status-tag--pending {
            background-color: #ffcd1e;
        }

        &.transaction-status-tag--cancelled {
            background-color: #4d4d4d;
        }

        &.transaction-status-tag--inactive,
        &.transaction-status-tag--upcoming,
        &.transaction-status-tag--reserved {
            background-color: var(--feedback-info-color);
        }

        &.transaction-status-tag--refunded {
            background-color: var(--extra-jade-earring);
        }

        &.transaction-status-tag--active,
        &.transaction-status-tag--completed {
            background-color: var(--feedback-success-color);
        }
    }

    &.hide-icon {
        i {
            display: none;
        }

        &.transaction-status-tag--deactivated,
        &.transaction-status-tag--declined {
            background-color: var(--feedback-error-color);
        }

        &.transaction-status-tag--terminated,
        &.transaction-status-tag--overdue {
            background-color: #ff8a00;
        }

        &.transaction-status-tag--pending {
            background-color: #ffcd1e;
        }

        &.transaction-status-tag--inactive,
        &.transaction-status-tag--cancelled {
            background-color: #4d4d4d;
        }

        &.transaction-status-tag--upcoming,
        &.transaction-status-tag--reserved {
            background-color: var(--feedback-info-color);
        }

        &.transaction-status-tag--refunded {
            background-color: var(--extra-jade-earring);
        }

        &.transaction-status-tag--active,
        &.transaction-status-tag--completed {
            background-color: var(--feedback-success-color);
        }
    }

    i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        font-size: 16px;
        margin-right: 4px;
    }

    p.text-sm {
        position: relative;
        top: 1px;
        line-height: 15px !important;
        text-transform: capitalize;
    }

    &.card-status-tag--rejected,
    &.card-status-tag--pickup,
    &.card-status-tag--deactivated,
    &.card-status-tag--suspend,
    &.card-status-tag--blocked,
    &.card-status-tag--cancelled,
    &.card-status-tag--pick-up {
        background-color: #f84a5e;
        color: white;
    }

    &.card-status-tag--pending {
        background-color: #fdbc42;
        color: #4d4d4d;
    }

    &.card-status-tag--inactive {
        background-color: var(--feedback-info-color);
        color: white;
    }

    &.card-status-tag--active {
        background-color: var(--feedback-success-color);
        color: white;
    }

    &.transaction-status-tag--error,
    &.transaction-status-tag--declined {
        i {
            &::before {
                color: var(--feedback-error-color);
            }
        }
        color: var(--feedback-error-color);
    }

    &.transaction-status-tag--terminated,
    &.transaction-status-tag--locked,
    &.transaction-status-tag--overdue {
        color: #ff8a00;
    }

    &.transaction-status-tag--pending {
        color: #ffcd1e;
    }

    &.transaction-status-tag--cancelled {
        color: #4d4d4d;
    }

    &.transaction-status-tag--reserved {
        color: var(--feedback-info-color);
    }

    &.transaction-status-tag--refunded {
        color: var(--extra-jade-earring);
    }

    &.transaction-status-tag--success,
    &.transaction-status-tag--collected,
    &.transaction-status-tag--available,
    &.transaction-status-tag--active,
    &.transaction-status-tag--completed {
        color: var(--feedback-success-color);
    }

    &.transaction-status-tag--inactive {
        color: var(--feedback-info-color);
    }

    &.transaction-status-tag--cancelled,
    &.transaction-status-tag--blocked,
    &.transaction-status-tag--declined,
    &.transaction-status-tag--rejected,
    &.transaction-status-tag--pickup,
    &.transaction-status-tag--deactivated,
    &.transaction-status-tag--suspend,
    &.transaction-status-tag--pick-up {
        color: var(--feedback-danger-color);
    }
}
</style>

