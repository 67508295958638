<template>
    <div class="field-group field-input-group position-relative vue-numeric">
        <label
            v-if="$slots.label || label"
            class="color-black custom-label"
            :for="localId"
        >
            <slot v-if="$slots.label" name="label"></slot>
            <span v-else>{{ label }}</span>
        </label>

        <vue-numeric
            class="formatted-input"
            @change="$emit('change', val)"
            :class="[
                errorMessages.length ? 'error' : '',
                customInputClasses ? customInputClasses : ''
            ]"
            ref="vueNumeric"
            :currency="currency"
            separator=","
            :precision="2"
            :placeholder="placeholder"
            :value="val || ''"
            @input="val = $event"
            :read-only="readonly"
            :disabled="disabled"
            :style="{height: height}"
        ></vue-numeric>

        <BaseErrorMessage :messages="errorMessages" />
    </div>
</template>

<script>
import VueNumeric from "vue-numeric";

import Helpers from '~/shared/mixins/Helpers';

import debounce from "lodash/debounce";

export default {
    name: "BaseFormattedInput",

    mixins: [Helpers],

    props: {
        id: {
            type: String || null,
            default: null,
        },
        label: {
            type: String || null,
            default: null,
        },
        placeholder: {
            type: String || null,
            default: '0.00',
        },
        value: {
            default: '',
        },
        disabled: {
            default: false,
        },
        readonly: {
            default: false,
        },
        currency: {
            type: String,
            required: false,
            default: null,
        },
        precision: {
            type: Number,
            required: false,
            default: 2,
        },
        errorMessages: {
            default: () => [],
        },
        customInputClasses: {
            type: String,
            default: '',
        },
        height: {
            default: "2.375rem",
        },
    },

    components: {
        VueNumeric,
    },

    watch: {
        val: function (newValue) {
            if (newValue !== null || newValue !== undefined) {
                this.$emit("input", newValue);
            }
        },
        value: {
            handler() {
                if (this.value !== null || this.value !== undefined) {
                    this.val = this.value;
                } else {
                    this.val = "";
                }
            },
            immediate: true,
        },
    },

    data() {
        return {
            val: this.value,
            localId: this.id || this.__makeId(),
        };
    },

    methods: {
        blueVueNumeric() {
            // Access the vue-numeric component using the ref attribute
            const numericComponent = this.$refs.vueNumeric;

            // Check if the component exists and it has a blur method
            if (
                numericComponent &&
                numericComponent.$el &&
                numericComponent.$el.blur
            ) {
                numericComponent.$el.blur();
            }
        },
    },

    created() {
        this.debouncedValueFormatted = debounce(newValue => {
            if (newValue){
                this.blueVueNumeric();
                this.$emit(
                    "value-formatted",
                    newValue.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })
                )
            }
        }, 500);
    },
};
</script>
