import axios from "axios";
import router from '~/routes/index';
export const namespaced = true;

import currencies from "~/assets/data/currenciesSimpleData";
import { getTime, parseISO } from "date-fns";
import { faker } from "@faker-js/faker";

export const state = {
    path: '/',
    features: [],

    paymentStatuses: [],
    paymentTypes: [],

    wallets: [],
    availableWallets: [],
    allWallets: [],
    allowedVoultCurrencies: [],
    disabledWalletCurrencies: [],
    notAvailableWallets: [],
    walletCurrencyDetails: [],

    hasVaultAccess: false,

    walletTransactionItems: [],
    walletTransactionPagination: null,
    loadingWalletTransactions: false,

    voultTransactionItems: [],
    voultTransactionPagination: null,
    loadingVoultTransactions: false,

    cards: [],
    //activeWallet: null,
    currencies: currencies,
    //notUsedWallets: notUsedCurrencies,
    vaultWallets: [],
    selectedCard: null,
    selectedWallet: null,
    primaryCurrency: null,
    rewardTotalData: [],
    rewardStatuses: [],
    selectedVault: null,
    hbhWallet: {},
    vaultDefaultCurrency: null,

    depositsWallets: [],
    selectedDeposit: {},


    depositWallets: [],

    euCountryCodes: [],
    eeaCountryCodes: [],
    nativeCountryCodes: [],
    metrics: [
        {
            value: "totalBalance",
            label: "Total balance",
            amount: 0,
            icon: "coins-2",
        },
        {
            value: "primaryWalletBalance",
            label: "Primary wallet balance",
            amount: 0,
            icon: "coins-1",
        },
    ],

    filterData: {
        search: "",
        types: [],
        statuses: [],
        currencies: [],
        dates: [],
        customDateRange: null,
        pageSize: 6,
    },

    filterVaultData: {
        search: "",
        types: [],
        statuses: [],
        currencies: [],
        dates: [],
        customDateRange: null,
        pageSize: 6,
    },

    selectedMetric: {
        value: "primaryWalletBalance",
        label: "Primary wallet balance",
        amount: 0,
        icon: "coins-1",
    },


    filters: {
        calendarDateRange: [],
        date: "",
        search: "",
        statuses: [],
        types: [],
        currencies: [],
    },

    transactionTypes: [
        { name: "Payment request" },
        { name: "Top up" },
        { name: "Withdrawal" },
        { name: "Expense" },
        { name: "Payroll" },
        { name: "Admin" },
        { name: "Invoice" },
        { name: "Card transaction" },
        { name: "Wallet to wallet" },
        { name: "Referral" },
        { name: "Bill payments" },
        { name: "Transfer" },
        { name: "Subscription deduct" },
        { name: "Wage withdraw" },
        { name: "ATM fee" },
    ],

    transactionStatuses: [
        {
            name: "Completed",
        },
        {
            name: "Pending",
        },
        {
            name: "Reserved",
        },
        {
            name: "Refunded",
        },
        {
            name: "Declined",
        },
        {
            name: "Overdue",
        },
        {
            name: "Cancelled",
        },
    ],

    categoryTypes: [
        { name: "Travel" },
        { name: "Entertainment" },
        { name: "Office supplies" },
        { name: "Advertising" },
        { name: "Equipment" },
        { name: "Other" },
    ],

    depositStatues: [
        {
            name: "Locked",
        },
        {
            name: "Available",
        },
    ],

    walletStatement: [],
    walletExpenses: [],

    cardStatement: [],
    cardExpenses: [],

    statementFilters: {
        year: null,
    },
    cardOptions: [
        {
            id: 0,
            cardTypeName: "physical",
            image: "nt-physical-card",
        },
        {
            id: 1,
            cardTypeName: "virtual",
            image: "nt-virtual-card",
        },
        {
            id: 2,
            cardTypeName: "equals",
            image: "nt-equals-card",
        },
    ],

    businessCategoryTypes: [],

    updatedWalletTransactions: [],


    resourceTotalBalanceForCurrencies: 'GetTotalBalanceForCurrencies',
    totalBalance: 'totalBalance',
    walletStatementData: {
        incoming: 0,
        outgoing: 0,
        business_expenses: 0,
    },
    walletId: null,
    walletOverviewData: [],
    walletExpenseReportsData: [],
    legalEntityWallets: [],
    updateWalletsData: false,
    showLoader: true,
    walletSwitcher: 'personal',
    gettingPaginationData: false,
    metricCurrency: null,
    hbhStatuses: [
        {
            name: "Completed",
        },
        {
            name: "Pending",
        },
    ],

    canMarkTransactionsPlans: [
        'payments_pro',
        'remoter_pro',
        'contractor_pro',
        'contractor_pro_seats',
        'client_pro',
        'client_seat_pro',
        'Seat Pro',
    ],
    financialReportData: [],
};

export const mutations = {

    UPDATE_WALLET_TRANSACTIONS(state, walletTransactions) {
        state.updatedWalletTransactions = walletTransactions;
    },

    UPDATE_WALLET_TRANSACTIONS_AFTER(state) {
        return axios.post('/wallet-spend/add-expense-request-multiple', state.updatedWalletTransactions);
    },

    RESET_UPDATED_WALLET_TRANSACTIONS(state) {
        state.updatedWalletTransactions = [];
    },

    REMOVE_CURRENCY_WALLET(state) {
        const walletToRemove = state.wallets.findIndex(
            (wallet) => wallet.id === state.selectedWallet.id
        );

        state.wallets.splice(walletToRemove, 1);
    },

    CANCEL_REWARD(state, transactionId) {
        const transactionToCancelIndex = state.selectedVault.transactions.findIndex(
            (transaction) => transaction.id === transactionId
        );

        state.selectedVault.transactions[transactionToCancelIndex].status = {
            text: "Cancelled",
            type: "error",
            icon: "transaction-status-cancelled",
        };
    },

    TRANSFER_MONEY_TO_WALLET(state, transactionId) {
        const transactionToTransferIndex =
            state.selectedVault.transactions.findIndex(
                (transaction) => transaction.id === transactionId
            );

        state.selectedVault.transactions[transactionToTransferIndex].status = {
            text: "Collected",
            type: "success",
            icon: "transaction-status-collected",
        };
    },

    loadingWalletTransactions(state, payload) {
        state.loadingWalletTransactions = payload;
    },
    loadingVoultTransactions(state, payload) {
        state.loadingVoultTransactions = payload;
    },

    SET_FILTERS(state, filters) {
        state.filters = filters;
    },
    SET_WALLET_FILTERS(state, filters) {
        state.filterData = filters;
    },
    SET_VAULT_FILTERS(state, filters) {
        state.filterVaultData = filters;
    },
    SET_WALLET_TRANSACTIONS(state, payload) {
        state.walletTransactionItems = payload.data.data;
        state.walletTransactionPagination = payload.data;
    },

    SET_VOULT_TRANSACTIONS(state, payload) {
        state.voultTransactionItems = payload.data.data;
        state.voultTransactionPagination = payload.data;
    },

    RESET_WALLET_TRANSACTIONS(state) {
        state.walletTransactionItems = [];
        state.walletTransactionPagination = null;
    },

    RESET_VOULT_TRANSACTIONS(state) {
        state.voultTransactionItems = [];
        state.voultTransactionPagination = null;
    },

    SET_METRIC(state, metricLabel) {
        state.selectedMetric = state.metrics.find(
            (metric) => metric.label === metricLabel
        );
    },

    filterData(state, payload) {
        state.filterData = payload;
    },

    SET_SELECTED_WALLET_EMPTY(state) {
        state.selectedWallet = null
    },
    SET_SELECTED_WALLET(state, walletId) {
        state.selectedWallet = state.allWallets.find(
            w => w.id == walletId
        )
        state.walletId = walletId;
        /*let isModulr = currencyName === 'EUR-MODULR';

        state.selectedWallet = state.wallets.find(
            (wallet) => isModulr ?  wallet.currency.code === 'EUR' && wallet.is_modulr
                : wallet.currency.code === currencyName && !wallet.is_modulr
        )*/
    },

    SET_SELECTED_CARD(state, cardTypeName) {
        state.selectedCard = state.cards[cardTypeName];

        const linkedWallet = state.wallets.find(
            (wallet) =>
                wallet.currency.currency === state.selectedCard.currency.currency
        );

        state.selectedWallet = linkedWallet;
    },

    SET_WALLETS(state, wallets) {
        state.wallets = wallets;
    },

    SET_PAYMENT_STATUSES(state, statuses) {
        state.paymentStatuses = statuses;
    },
    SET_PAYMENT_TYPES(state, types) {
        state.paymentTypes = types;
    },
    SET_ALL_WALLETS(state, allWallets) {
        state.allWallets = allWallets;
    },
    SET_ALLOWED_VOULT_CURRENCIES(state, allowedCurrencies) {
        state.allowedVoultCurrencies = allowedCurrencies;
    },
    SET_DISABLED_WALLET_CURRENCIES(state, currencies) {
        state.disabledWalletCurrencies = currencies;
    },
    SET_NOT_AVAILABLE_WALLETS(state, wallets) {
        state.notAvailableWallets = wallets;
    },
    SET_WALLET_CURRENCY_DETAILS(state, currencies) {
        state.walletCurrencyDetails = currencies;
    },
    SET_VAULT_ACCESS(state, vaultAccess) {
        state.hasVaultAccess = vaultAccess;
    },

    SET_AVAILABLE_WALLETS(state, wallets) {
        state.availableWallets = wallets;
    },
    SET_USED_WALLETS(state, wallets) {
        // find the wallet with walletId and change the isUsed property to true
        wallets.forEach((walletToUpdate) => {
            // Find the corresponding wallet in the state by id
            const walletInState = state.wallets.find(
                (wallet) => wallet.id === walletToUpdate.id
            );
            // If found, set its isUsed property to true
            if (walletInState) {
                walletInState.available = true;
            }
        });
    },

    EMPTY_STATE(state) {
        const eurWallet = [];
        state.wallets = [eurWallet];
        state.cards = {};
        state.metrics.map((metric) => {
            metric.amount = 0;
        });
    },
    SET_STATEMENT_FILTERS(state, filters) {
        state.statementFilters = filters;
    },


    ACTIVATE_CARD(state, cardTypeName) {
        state.cards[cardTypeName].cardStatus = "active";
    },

    UPDATE_WALLET_TRANSACTION(state, newTransactionData) {
        const transactionToUpdate = state.selectedWallet.transactions.find(
            (transaction) => transaction.id === newTransactionData.id
        );

        if (transactionToUpdate) {
            transactionToUpdate.expenseType = newTransactionData.expenseType;
            transactionToUpdate.proofList = newTransactionData.proofList;
        }
    },

    SET_DEPOSIT_WALLET(state) {
        state.selectedDeposit = state.depositsWallets[0];
    },

    SET_DEPOSIT_WALLETS(state, payload) {
        state.depositWallets = payload;
    },

    DEPOSIT_FUNDS(state, formData) {
        const newTransaction = {
            amount: formData.amount,
            date: new Date(),
            status: {
                type: "success",
                text: "Completed",
            },
            id: faker.string.uuid().substring(0, 8),
            balance: formData.amount,
            supportReference: "#124124",
            reference: "#124124",
            currency: state.selectedDeposit.currency,
            fees: 0,
        };

        // push it on the first position
        state.selectedDeposit.transactions.unshift(newTransaction);
    },

    SET_TO_CONVERT_WALLET(state, currencyName) {
        const wallet = state.wallets.find(
            (wallet) => wallet.currency.currency === currencyName
        );
        state.convertableWallet = wallet;
    },

    RESERVE_FUNDS(state, formData) {
        const newTransaction = [];
        // push it on the first position
        state.selectedVault.transactions.unshift(newTransaction);
    },

    SET_EQUALS_CARD_STATUS(state, status) {
        state.cards["equals"].cardStatus = status;
    },

    SET_VAULT_DEFAULT_CURRENCY(state, currencyName) {
        state.vaultDefaultCurrency = currencyName;
    },

    SET_VAULT_WALLET(state, currencyName) {
        state.selectedVault = state.vaultWallets.find(
            (vaultWallet) => vaultWallet.currency.code === currencyName
        );
    },

    SET_VAULT_WALLETS(state, wallets) {
        state.vaultWallets = wallets
    },
    SET_REWARD_TOTAL_DATA(state, rewardTotalData) {
        state.rewardTotalData = rewardTotalData
    },
    SET_REWARD_STATUSES(state, statuses) {
        state.rewardStatuses = statuses
    },
    SET_METRIC_VALUE(state, payload) {
        const metric = state.metrics.find(item => item.label === payload.value);
        metric.amount = payload.amount;

    },
    SET_CURRENCIES(state, payload) {
        state.currencies = payload;
    },
    SET_STATEMENT_DATA(state,payload){
        state.walletStatementData = payload;
    },
    SET_WALLET_ID(state, payload) {
        state.walletId = payload;
    },
    SET_WALLET_OVERVIEW_DATA(state,payload){
        state.walletOverviewData = payload;
    },
    SET_WALLET_EXPENSE_REPORT_DATA(state,payload){
        state.walletExpenseReportsData = payload;
    },
    SET_LEGAL_ENTITY_WALLETS(state, payload) {
        state.legalEntityWallets = payload;
    },
    SET_UPDATE_WALLETS_DATA(state,payload){
        state.updateWalletsData = payload;
    },
    SET_BUSINESS_CATEGORIES(state, payload) {
        state.businessCategoryTypes = payload;
    },
    SET_WALLET_SWITCHER(state, payload) {
        state.walletSwitcher = payload;
    },
    SET_SHOW_LOADER(state,payload){
        state.showLoader = payload;
    },
    SET_GETTING_PAGINATION_DATA(state,payload){
        state.gettingPaginationData = payload;
    },
    SET_METRIC_CURRENCY(state,payload){
        state.metricCurrency = payload;
    },
    SET_SELECTED_WALLET_HBH(state) {
        state.selectedWallet = state.hbhWallet;
    },
    SET_FINANCIAL_REPORT_DATA(state,payload) {
        state.financialReportData = payload;
    },

};

export const actions = {
    async getWalletTransactions({ state, commit, rootState }, q = {}) {

        commit('loadingWalletTransactions', true);

        const filterData = Object.assign({}, state.filterData);
        filterData.page = q.page || 1;

        for (const [key, value] of Object.entries(q)) {
            if ('page' !== key) {
                filterData[key] = value;
            }
        }

        let path = rootState.path + `wallet/paginate`;

        if (state.selectedWallet) {
            path += '/' + state.selectedWallet.id
        }

        await axios
            .get(path, {
                params: filterData,
            })
            .then(response => {
                commit("SET_WALLET_TRANSACTIONS", response);
            })
            .catch(error => {
                commit('SET_ALERT_MODAL',{type: 'error', data: {title: "Error", text:  error.response.data.message}}, { root: true });
            })
            .finally(() => {
                commit('loadingWalletTransactions', false);
                commit("SET_SHOW_LOADER",false);

            });
    },

    async getVoultTransactions({state, commit, rootState}, q = {}) {

        commit('loadingVoultTransactions', true);

        const filterVaultData = Object.assign({}, state.filterVaultData);
        filterVaultData.page = q.page || 1;

        for (const [key, value] of Object.entries(q)) {
            if ('page' !== key) {
                filterVaultData[key] = value;
            }
        }

        let path = `/reward/paginate/`+state.selectedVault.id;

        await axios
            .get(path, {
                params: filterVaultData,
            })
            .then(response => {
                commit("SET_VOULT_TRANSACTIONS", response);
            })
            .catch(error => {
                commit('SET_ALERT_MODAL',{type: 'error', data: {title: "Error", text:  error.response.data.message}}, { root: true });
            })
            .finally(() => {
                commit('loadingVoultTransactions', false);
            });
    },
    async getMetricData({state, commit, rootState}, q = {}) {
        if(q?.metric === 'primaryWalletBalance'){
            return [];
        }
        commit("SET_METRIC_VALUE", { value: "Total balance", amount: 0 });
        commit("SET_SHOW_LOADER",true);
        const params = {
            resources: state.resourceTotalBalanceForCurrencies,
            currency: q.currency,
            type: state.walletSwitcher,
        }
        const query = new URLSearchParams(params).toString();
       return await axios.get(`/initialization?${query}`).then(res => {
            return res.data.data;
        }).then(res => {
            const data = res.find(item => item.type === state.resourceTotalBalanceForCurrencies);
            commit("SET_METRIC_VALUE", { value: "Total balance", amount: data.items.total_balance });
            commit("SET_SHOW_LOADER",false);


        }).catch(err =>{
            commit("SET_SHOW_LOADER",false);
        });
    },
    async getWalletStatementData({state, commit, rootState}, q = {}) {
        if ((q && !q.id) || q.id === undefined) return;
        let walletId = q.id;
        commit("SET_WALLET_ID", walletId)
        commit("loadingWalletTransactions",true);
        return await axios.get(`/wallet-statement/data/${walletId}`).then(res => {
            return res.data;
        }).then(res => {
            commit("SET_STATEMENT_DATA", res.totals)
            commit("loadingWalletTransactions", false);
        }).catch(err => {
            commit("loadingWalletTransactions", false);
        })
    },
    async getWalletOverviewData({state, commit, rootState}) {
        if (!state.walletId) return
        if (!state.statementFilters.year) state.statementFilters.year = new Date().getFullYear()
        const query = new URLSearchParams(state.statementFilters).toString();
        commit("loadingWalletTransactions",true);
        return await axios.get(`/wallet-statement/wallet-spend/${state.walletId}?${query}`).then(res => {
            return res.data;
        }).then(res => {
            commit("SET_WALLET_OVERVIEW_DATA", res)
            commit("loadingWalletTransactions", false);
        }).catch(err => {
            commit("loadingWalletTransactions", false);
        })
    },
    async getWalletExpenseReportsData({state, commit}) {
        if (!state.walletId) return;
        if(!state.statementFilters.year) state.statementFilters.year = new Date().getFullYear();
        const query = new URLSearchParams(state.statementFilters).toString();
        return await axios.get(`/wallet-statement/business-expenses-reports/${state.walletId}?${query}`).then(res => {
            return res.data;
        }).then(res => {
            commit("SET_WALLET_EXPENSE_REPORT_DATA", res)
        })
    },
    async markTransaction({state,commit},data){
        return await axios.post(`/${data.route}`, data.form);
    },

    onOrderCardOption({state}) {
        const wallet = state.allWallets.find(w => w.currency.code === 'EUR' && !w.is_modulr);
        if (wallet) {
            [
                'walletPageSection',
                'walletId',
                'cardId',
            ].forEach(item => window.sessionStorage.removeItem(item));
            router.push('/cards-preview?walletId=' + wallet.id).then(r => {});
        }
    },

    //onceGetWalletTransactions: _.once(({ dispatch }) => dispatch('getWalletTransactions')),
    setFilters({ commit }, filters) {
        commit("SET_FILTERS", filters);
    },

    setWalletFilters({ commit }, filters) {
        commit("SET_WALLET_FILTERS", filters);
    },

    setVoultFilters({commit}, filters) {
        commit("SET_WALLET_FILTERS", filters);
    },

    setMetric({ commit }, metricLabel) {
        commit("SET_METRIC", metricLabel);
    },

    setSelectedWallet({commit}, walletId) {
        commit("SET_SELECTED_WALLET", walletId);
    },

    setResetWalletTransaction({ commit }) {
        commit("RESET_WALLET_TRANSACTIONS");
    },

    setResetVoultTransaction({commit}) {
        commit("RESET_VOULT_TRANSACTIONS");
    },
    setVoultTransaction({commit}) {
        commit("SET_VOULT_TRANSACTIONS");
    },

    setSelectedWalletEmpty({ commit }) {
        commit("SET_SELECTED_WALLET_EMPTY");
    },

    setWallets({ commit }, wallets) {
        commit("SET_WALLETS", wallets);
    },
    setPaymentStatuses({ commit }, paymentStatuses) {
        commit("SET_PAYMENT_STATUSES", paymentStatuses);
    },
    setPaymentTypes({ commit }, paymentTypes) {
        commit("SET_PAYMENT_TYPES", paymentTypes);
    },
    setAllWallets({ commit }, allWallets) {
        commit("SET_ALL_WALLETS", allWallets);
    },
    setAllowedVoultCurrencies({commit}, allowedCurrencies) {
        commit("SET_ALLOWED_VOULT_CURRENCIES", allowedCurrencies);
    },
    setDisabledWalletCurrencies({commit}, currencies) {
        commit("SET_DISABLED_WALLET_CURRENCIES", currencies);
    },
    setNotAvailableWallets({ commit }, wallets) {
        commit("SET_NOT_AVAILABLE_WALLETS", wallets);
    },
    setAvailableWallets({ commit }, wallets) {
        commit("SET_AVAILABLE_WALLETS", wallets);
    },
    setWalletCurrencyDetails({ commit }, walletCurrencyDetails) {
        commit("SET_WALLET_CURRENCY_DETAILS", walletCurrencyDetails);
    },
    setVaultAccess({commit}, vaultAccess) {
        commit("SET_VAULT_ACCESS", vaultAccess);
    },
    setSelectedCard({ commit }, cardTypeName) {
        commit("SET_SELECTED_CARD", cardTypeName);
    },

    setPrimaryWallet({ commit }, wallets) {
        commit("SET_PRIMARY_WALLET", wallets);
    },

    setUsedWallets({ commit }, wallets) {
        commit("SET_USED_WALLETS", wallets);
    },

    requestWallet({ commit }, requestedWallet) {
        commit("SET_AVAILABLE_WALLET", requestedWallet);
    },
    setLegalEntitySelectedWallet({ commit }) {
        commit("SET_LEGAL_ENTITY_SELECTED_WALLET");
    },

    updateWalletTransactions({commit}, transactions) {
        commit("UPDATE_WALLET_TRANSACTIONS", transactions);
    },

    async updateWalletTransactionsAfter({state, commit}) {
        return await axios.post('/wallet-spend/add-expense-request-multiple', state.updatedWalletTransactions);
    },

    removeCurrencyWallet({ commit }, walletId) {
        commit("REMOVE_CURRENCY_WALLET", walletId);
    },
    cancelReward({ commit }, transactionId) {
        commit("CANCEL_REWARD", transactionId);
    },

    transferMoneyToWallet({ commit }, transactionId) {
        commit("TRANSFER_MONEY_TO_WALLET", transactionId);
    },
    activateCard({ commit }, cardTypeName) {
        commit("ACTIVATE_CARD", cardTypeName);
    },

    addEqualsCard({ commit }, currencyCode) {
        commit('ACTIVATE_CARD', 'equals' + (currencyCode ? ('-' + currencyCode) : ''));
    },

    updateWalletTransactionExpenseType({ commit }, newTransactionData) {
        commit("UPDATE_WALLET_TRANSACTION", newTransactionData);
    },
    setDepositWallet({ commit }) {
        commit("SET_DEPOSIT_WALLET");
    },

    depositFunds({ commit }, formData) {
        commit("DEPOSIT_FUNDS", formData);
    },
    resetUpdatedWalletTransactions({ commit }) {
        commit("RESET_UPDATED_WALLET_TRANSACTIONS");
    },

    clearFilters({ commit }) {
        const filters = {
            pageSize: 6,
            calendarDateRange: [],
            date: "",
            search: "",
            statuses: [],
            types: [],
            currencies: [],
        };
        commit("SET_FILTERS", filters);
    },

    clearWalletFilters({ commit }) {
        const filterData = {
            search: "",
            types: [],
            statuses: [],
            currencies: [],
            dates: [],
            customDateRange: null,
            pageSize: 6,
        };
        commit("SET_WALLET_FILTERS", filterData);
    },

    clearVaultFilters({commit}) {
        const filterData = {
            search: "",
            types: [],
            statuses: [],
            currencies: [],
            dates: [],
            customDateRange: null,
        };
        commit("SET_VAULT_FILTERS", filterData);
    },

    setVaultWallet({ commit }, currencyName) {
        commit("SET_VAULT_WALLET", currencyName);
    },
    setVaultWallets({ commit }, wallets) {
        commit("SET_VAULT_WALLETS", wallets);
    },
    setVaultDefaultCurrency({ commit }, wallets) {
        commit("SET_VAULT_DEFAULT_CURRENCY", wallets);
    },
    setRewardTotalData({ commit }, totalData) {
        commit("SET_REWARD_TOTAL_DATA", totalData);
    },
    setRewardStatuses({ commit }, statuses) {
        commit("SET_REWARD_STATUSES", statuses);
    },
    emptyState({ commit }) {
        commit("EMPTY_STATE");
    },
    setStatementFilters({ commit }, filters) {
        commit("SET_STATEMENT_FILTERS", filters);
    },
    setToConvertWallet({ commit }, currencyName) {
        commit("SET_TO_CONVERT_WALLET", currencyName);
    },
    reserveFunds({ commit }, formData) {
        commit("RESERVE_FUNDS", formData);
    },
    setEqualsCardStatus({ commit }, status) {
        commit("SET_EQUALS_CARD_STATUS", status);
    },
    setMetricData({commit}, payload) {
        commit("SET_METRIC_VALUE", {value: payload.type, amount: payload.amount});
        commit("SET_METRIC", payload.type);
    },
    setLegalEntityWallets({commit},payload){
        commit("SET_LEGAL_ENTITY_WALLETS", payload);
    },
    setDepositWallets({commit},payload){
        commit("SET_DEPOSIT_WALLETS", payload);
    },
    setUpdateWalletsData({commit}, payload) {
        commit('SET_UPDATE_WALLETS_DATA', payload);
    },
    setBusinessCategories({commit}, payload) {
        commit("SET_BUSINESS_CATEGORIES", payload);
    },
    // setLegalEntityIsActive({commit}, payload) {
    //     commit("SET_LEGAL_ENTITY_IS_ACTIVE", payload[0]?.legal_entity_is_active);
    //     commit("SET_LEGAL_ENTITY_ENABLED", payload[0]?.legal_entity_enabled);
    //     commit("SET_WALLET_LEGAL_ENTITY_DATA", payload[0]?.legal_entity_details);
    // },
    setWalletSwitcher({commit}, payload) {
        commit("SET_WALLET_SWITCHER", payload);
    },
    setGettingPaginationData({commit}, payload) {
        commit("SET_GETTING_PAGINATION_DATA", payload);
    },
    setMetricCurrency({commit}, payload) {
        commit("SET_METRIC_CURRENCY", payload);
    },
    setSelectedWalletHbh({ commit }) {
        commit("SET_SELECTED_WALLET_HBH");
    },
    async getFinancialReportData ({ state, commit }, data = {}) {
        if (!data.year) {
            data.year = new Date().getFullYear()
        }
        data.wallet_id = state.walletId;
        return await axios.get('/v2/financial-report', { params: data }).then(res => {
            return res.data.data;
        }).then(res =>{
            commit('SET_FINANCIAL_REPORT_DATA',res);
        })
    },
    async generateFinancialReport ({ state, commit }, data) {
        data.wallet_id = state.walletId
        return await axios.get('/v2/financial-report/generate', { params: data }).then(res => {
            return res.data.data
        }).then(res => {
            setTimeout(() => {
                window.open(res.url, '_blank')
            })
        })
    }

};

export const getters = {
    filterData: state => state.filterData,

    filterVaultData: state => state.filterVaultData,

    walletTransactionItems: state => state.walletTransactionItems,

    walletTransactionPagination: state => state.walletTransactionPagination,

    voultTransactionItems: state => state.voultTransactionItems,

    voultTransactionPagination: state => state.voultTransactionPagination,

    loadingWalletTransactions: state => state.loadingWalletTransactions,

    loadingVoultTransactions: state => state.loadingVoultTransactions,

    wallets: (state) => state.wallets,

    paymentStatuses: (state) => state.paymentStatuses,

    paymentTypes: (state) => state.paymentTypes,

    allWallets: (state) => state.allWallets,

    allowedVoultCurrencies: (state) => state.allowedVoultCurrencies,

    disabledWalletCurrencies: (state) => state.disabledWalletCurrencies,

    notAvailableWallets: (state) => state.notAvailableWallets,

    walletCurrencyDetails: (state) => state.walletCurrencyDetails,

    vaultTypes: (state) => state.vaultTypes,

    availableWallets: (state) => state.availableWallets,

    usedWallets: (state) => state.allWallets.filter((wallet) => wallet.available),

    notUsedWallets: (state) =>
        state.allWallets.filter((wallet) => !wallet.available),

    allowedWallets: (state) => state.allWallets.filter(
        w => !w.is_modulr && !state.disabledWalletCurrencies.includes(w.currency.code) && w.wallet_type !== 'deposit'
    ),

    cards: (state) => state.cards,

    cardStatement: (state) => state.cardStatement,

    cardExpenses: (state) => state.cardExpenses,


    euroWallet: (state) =>
        state.wallets.find((wallet) => wallet.currency.currency === "EUR"),

    getBusinessCategoryTypes: (state) => state.businessCategoryTypes,

    filteredCardStatement: (state) => {
        return state.cardStatement.filter((statement) => {
            const year = state.statementFilters.year
                ? statement.year === state.statementFilters.year
                : true;

            return year;
        });
    },

    filteredCardExpenses: (state) => {
        return state.cardExpenses.filter((expense) => {
            const year = state.statementFilters.year
                ? expense.year === state.statementFilters.year
                : true;

            return year;
        });
    },

    selectedWallet: (state) => state.selectedWallet,

    selectedCard: (state) => state.selectedCard,

    selectedCurrency: (state) => state.selectedCurrency,

    primaryCurrency: (state) => state.primaryCurrency,

    path: (state) => state.path,

    metrics: (state) => state.metrics,

    selectedMetric: (state) => state.selectedMetric,

    allWalletTransactions: (state) => {
        const walletsTransactions = state.wallets.reduce((acc, wallet) => {
            return [...acc, ...wallet.transactions];
        }, []);

        return [...walletsTransactions];
    },

    selectedWalletTransactions: (state) => state.selectedWallet.transactions,

    transactionTypes: (state) => state.transactionTypes,

    transactionStatuses: (state) => state.transactionStatuses,

    categoryTypes: (state) => state.categoryTypes,

    showLoader: (state) => state.showLoader,

    filteredWalletTransactions: (state) => {

        const allTransactions = state.wallets.reduce((acc, wallet) => {
            return [...acc, ...wallet.transactions];
        }, []);

        return allTransactions.filter((transaction) => {
            const status = state.filters.statuses.includes(transaction.status);
            const type = state.filters.types.includes(transaction.type);
            const currency = state.filters.currencies.includes(
                transaction.currency.currency
            );

            const transactionName = transaction.from
                .toLowerCase()
                .includes(state.filters.search.toLowerCase());

            const transactionDateDatestamp = getTime(transaction.date);
            let isDateRangeMatch;

            if (state.filters.calendarDateRange.length <= 1) {
                isDateRangeMatch = true;
            } else {
                const dateRangeDatestamps = state.filters.calendarDateRange.map(
                    (date) => getTime(parseISO(date))
                );

                isDateRangeMatch =
                    transactionDateDatestamp >= dateRangeDatestamps[0] &&
                    transactionDateDatestamp <= dateRangeDatestamps[1];
            }
            return (
                (state.filters.statuses.length === 0 || status) &&
                (state.filters.currencies.length === 0 || currency) &&
                (state.filters.types.length === 0 || type) &&
                isDateRangeMatch &&
                (!state.filters.search || transactionName)
            );
        });
    },

    cardTransactions: (state) => state.selectedCard.cardTransactions,

    filteredCardTransactions: (state) => {
        return state.selectedCard.cardTransactions.filter((transaction) => {
            const status = state.filters.statuses.includes(transaction.status);
            const type = state.filters.types.includes(transaction.type);

            const transactionName = transaction.from
                .toLowerCase()
                .includes(state.filters.search.toLowerCase());

            const transactionDateDatestamp = getTime(transaction.date);
            let isDateRangeMatch;

            if (state.filters.calendarDateRange.length <= 1) {
                isDateRangeMatch = true;
            } else {
                const dateRangeDatestamps = state.filters.calendarDateRange.map(
                    (date) => getTime(parseISO(date))
                );

                isDateRangeMatch =
                    transactionDateDatestamp >= dateRangeDatestamps[0] &&
                    transactionDateDatestamp <= dateRangeDatestamps[1];
            }
            return (
                (state.filters.statuses.length === 0 || status) &&
                (state.filters.types.length === 0 || type) &&
                isDateRangeMatch &&
                (!state.filters.search || transactionName)
            );
        });
    },


    filteredSelectedWalletTransactions: (state) => {
        return state.selectedWallet.transactions.filter((transaction) => {
            const status = state.filters.statuses.includes(transaction.status);
            const type = state.filters.types.includes(transaction.type);

            const transactionName = transaction.from
                .toLowerCase()
                .includes(state.filters.search.toLowerCase());

            const transactionDateDatestamp = getTime(transaction.date);
            let isDateRangeMatch;

            if (state.filters.calendarDateRange.length <= 1) {
                isDateRangeMatch = true;
            } else {
                const dateRangeDatestamps = state.filters.calendarDateRange.map(
                    (date) => getTime(parseISO(date))
                );

                isDateRangeMatch =
                    transactionDateDatestamp >= dateRangeDatestamps[0] &&
                    transactionDateDatestamp <= dateRangeDatestamps[1];
            }
            return (
                (state.filters.statuses.length === 0 || status) &&
                (state.filters.types.length === 0 || type) &&
                isDateRangeMatch &&
                (!state.filters.search || transactionName)
            );
        });
    },

    vaultWallets: (state) => state.vaultWallets,

    vaultDefaultCurrency: (state) => state.vaultDefaultCurrency,

    rewardTotalData: (state) => state.rewardTotalData,

    currentWorker: (state) => state.currentWorker,

    primaryWorker: (state) => state.primaryWorker,

    rewardStatuses: (state) => state.rewardStatuses,

    hasVaultWallets: (state) => state.hasVaultAccess,

    selectedVault: (state) => state.selectedVault,

    vaultWalletsCurrencies: (state) =>
        state.vaultWallets.map((vaultWallet) => vaultWallet.currency),

    depositsWallets: (state) => state.depositsWallets,

    selectedDeposit: (state) => state.selectedDeposit,

    depositsWalletsCurrencies: (state) =>
        state.depositsWallets.map((depositsWallet) => depositsWallet.currency),


    depositStatuses: (state) => state.depositStatuses,

    selectedDepositTransactions: (state) => state.selectedDeposit.transactions,

    filteredSelectedDepositTransactions: (state) => {
        return state.selectedDeposit.transactions.filter((transaction) => {
            const transactionDateDatestamp = getTime(transaction.date);
            let isDateRangeMatch;

            if (state.filters.calendarDateRange.length <= 1) {
                isDateRangeMatch = true;
            } else {
                const dateRangeDatestamps = state.filters.calendarDateRange.map(
                    (date) => getTime(parseISO(date))
                );

                isDateRangeMatch =
                    transactionDateDatestamp >= dateRangeDatestamps[0] &&
                    transactionDateDatestamp <= dateRangeDatestamps[1];
            }
            return isDateRangeMatch;
        });
    },

    walletsCurrencies: (state) => state.wallets.map((wallet) => wallet.currency),

    walletStatement: (state) => state.walletStatement,
    walletStatementData: (state) => state.walletStatementData,
    walletOverviewData: (state) => state.walletOverviewData,
    walletExpenseReportDataData: (state) => state.walletExpenseReportsData,

    walletExpenses: (state) => state.walletExpenses,

    filteredWalletStatement: (state) => {
        return state.walletStatement.filter((statement) => {
            const year = state.statementFilters.year
                ? statement.year === state.statementFilters.year
                : true;

            return year;
        });
    },

    filteredWalletExpenses: (state) => {
        return state.walletExpenses.filter((expense) => {
            const year = state.statementFilters.year
                ? expense.year === state.statementFilters.year
                : true;

            return year;
        });
    },

    statementFilters: (state) => state.statementFilters,

    // availableBalanceSelectedConvertWallet: (state) => {
    //     return state.wallets.find(
    //         (wallet) => wallet.currency.currency === state.toConvertCurrency
    //     ).availableBalance;
    // },

    convertableWallet: (state) => state.convertableWallet,

    cardOptions: (state) => state.cardOptions,

    selectedVaultTransactions: (state) => state.selectedVault.transactions,

    filteredSelectedVaultTransactions: (state) => {
        return state.selectedVault.transactions.filter((transaction) => {
            const status = state.filters.statuses.includes(transaction.status.text);
            const type = state.filters.types.includes(transaction.money);

            const transactionName = transaction.title
                .toLowerCase()
                .includes(state.filters.search.toLowerCase());

            const transactionDateDatestamp = getTime(transaction.date);
            let isDateRangeMatch;

            if (state.filters.calendarDateRange.length <= 1) {
                isDateRangeMatch = true;
            } else {
                const dateRangeDatestamps = state.filters.calendarDateRange.map(
                    (date) => getTime(parseISO(date))
                );

                isDateRangeMatch =
                    transactionDateDatestamp >= dateRangeDatestamps[0] &&
                    transactionDateDatestamp <= dateRangeDatestamps[1];
            }
            return (
                (state.filters.statuses.length === 0 || status) &&
                (state.filters.types.length === 0 || type) &&
                isDateRangeMatch &&
                (!state.filters.search || transactionName)
            );
        });
    },
    getSelectedCurrency: (state) => {
        return state.availableWallets.find(item => item.id === state.walletId)?.currency?.code
    },
    legalEntityWallets:(state) => state.legalEntityWallets,

    depositWallets:(state) => state.depositWallets,

    updateWalletsData: (state) => state.updateWalletsData,

    walletSwitcher: (state) => state.walletSwitcher,

    legalEntityData: (state) => state.legalEntityData,

    gettingPaginationData: (state) => state.gettingPaginationData,

    getMetricCurrency: (state) => state.metricCurrency,

    hbhStatuses: (state) => state.hbhStatuses,

    hbhWallet: (state) => state.hbhWallet,

    filteredHbhTransactions: (state) => {
        return state.hbhWallet.transactions.filter((transaction) => {
            const transactionStatusText =
                transaction.completedHours === 8 ? "Completed" : "Pending";
            const status = state.filters.statuses.includes(transactionStatusText);
            const transactionDateDatestamp = getTime(transaction.date);
            let isDateRangeMatch;

            if (state.filters.calendarDateRange.length <= 1) {
                isDateRangeMatch = true;
            } else {
                const dateRangeDatestamps = state.filters.calendarDateRange.map(
                    (date) => getTime(parseISO(date))
                );

                isDateRangeMatch =
                    transactionDateDatestamp >= dateRangeDatestamps[0] &&
                    transactionDateDatestamp <= dateRangeDatestamps[1];
            }
            return (
                (state.filters.statuses.length === 0 || status) && isDateRangeMatch
            );
        });
    },




    canMarkTransactionsPlans: (state) => state.canMarkTransactionsPlans,
    getFinancialReportData: (state) => state.financialReportData,
};
