<template>
    <div
        class="field-group field-input-group position-relative"
        :class="
      isMark ? 'd-flex align-center flex-row-reverse justify-content-end' : ''
    "
        :style="inlineStyle"
    >
        <div class="d-flex">
            <label
                v-if="$slots.label || label"
                class="custom-label text-small color-black"
                :class="[isMark ? 'custom-label-radio' : '', customLabelParentClasses]"
                :for="localId"
                :style="inlineStyle"
            >
                <slot v-if="$slots.label" name="label"></slot>
                <span :class="[labelClassVal]" v-else>
                {{ label }}
                <span v-if="asterisk" class="red--text">*</span>
            </span>
            </label>
            <label
                v-if="$slots.requiredText || requiredText"
                class="custom-label ml-1 text-sm color-feedback-error"
            >
                <slot v-if="$slots.requiredText" name="requiredText"></slot>
                <span>{{ requiredText }}</span>
            </label>
        </div>
        <input
            v-if="isMark"
            @change="$emit('change', val)"
            :id="localId"
            :name="name"
            v-model="val"
            :value="itemValue"
            :type="type"
            class="input input-mark"
            :class="[errorMessages.length ? 'error' : '', customInputClasses]"
            :disabled="!!disabled"
            :autocomplete="inputAutocomplete"
            @wheel="handleWheelEvent($event)"
        />
        <v-text-field
            v-else
            :id="localId"
            :append-icon="appendIcon"
            :append-outer-icon="appendOuterIcon"
            :prepend-icon="prependIcon"
            :prepend-inner-icon="!search ? prependInnerIcon : 'icon-search'"
            :disabled="disabled"
            :type="type"
            :placeholder="__inputPlaceholder"
            :readonly="readonly"
            v-on="$listeners"
            class="w-100"
            :value="value"
            hide-details
            dense
            :class="[
                bordered ? 'input' : '',
                errorMessages.length ? 'error' : '',
                search ? 'search' : '',
                customInputClasses ? customInputClasses : '',
                ellipsis ? 'input-ellipsis' : '',
            ]"
            :style="{ 'max-width': maxWidth, height: height }"
            :maxlength="maxLength"
            @keypress="$emit('keypress',$event)"
            @keyup="$emit('keyup',$event)"
            :autocomplete="inputAutocomplete"
            @wheel="handleWheelEvent($event)"
            @change="$emit('change', val)"
        >
            <template slot="append">
                <slot name="append"></slot>
            </template>
            <template slot="append-outer">
                <slot name="append-outer"></slot>
            </template>
            <template slot="prepend">
                <slot name="prepend"></slot>
            </template>
            <template slot="prepend-inner">
                <!-- <div
                  v-if="$slots['prepend-inner']"
                  class="position-relative d-flex align-center"
                  style="
                    padding-right: 0.6875rem !important;
                    line-height: initial;
                    height: 2.1875rem;
                  "
                >
                  <slot name="prepend-inner"></slot>
                  <div
                    style="
                      position: absolute;
                      height: 2.1875rem;
                      border-right: 0.0625rem solid #dadfe6;
                      top: 0;
                      right: 0;
                    "
                  ></div>
                </div> -->
                <slot name="prepend-inner"></slot>
            </template>
            <template slot="progress">
                <slot name="progress"></slot>
            </template>
        </v-text-field>
        <BaseErrorMessage :messages="errorMessages" />
    </div>
</template>

<script>
import Helpers from '~/shared/mixins/Helpers';
import Form from "~/shared/mixins/Form";
export default {
    mixins: [Helpers, Form],

    name: "BaseInput",

    props: {
        id: {
            type: String || null,
            default: null,
        },
        label: {
            type: String || null,
            default: null,
        },
        labelClassVal: {
            type: String | null,
            default: null,
        },
        asterisk: {
            default: false,
            type: Boolean,
        },
        errorMessages: {
            default: () => [],
        },
        type: {
            type: String || null,
            default: "text",
        },
        appendIcon: {
            type: String || null,
            default: null,
        },
        appendOuterIcon: {
            type: String || null,
            default: null,
        },
        prependIcon: {
            type: String || null,
            default: null,
        },
        prependInnerIcon: {
            type: String || null,
            default: null,
        },
        placeholder: {
            default: 'Type here',
        },
        forcePlaceholder: {
            default: null,
        },
        itemValue: {
            default: 1,
        },
        search: {
            type: Boolean,
            default: false,
        },
        value: {
            default: null,
        },
        disabled: {
            default: false,
        },
        readonly: {
            default: false,
        },
        bordered: {
            default: true,
        },
        height: {
            default: "2.1875rem",
        },
        maxWidth: {
            type: String,
            default: "auto",
        },
        name: {
            type: String,
            default: "",
        },
        maxLength:{
            default:"",
        },
        addStyles: {
            type: Object,
            default: () => {}
        },
        requiredText: {
            type: [String, null],
            default: null,
        },
        customInputClasses: {
            type: String,
            default: '',
        },
        customLabelParentClasses: {
            type: String,
            default: '',
        },
        inputAutocomplete: {
            type: String,
            default: 'on',
        },
        ellipsis: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            val: this.value,
            localId: this.id || this.__makeId(),
        };
    },

    computed: {
        isMark() {
            return 'checkbox' === this.type || 'radio' === this.type;
        },
        inlineStyle(){
            return this.addStyles;
        },
    },
    methods:{
        handleWheelEvent($event) {
            return this.type === 'number' ? $event.target.blur() : '';
        }
    },

    watch: {
        val() {
            this.$emit("input", this.val);
        },
        value() {
            this.val = this.value;
        },
    },
};
</script>
<style scoped lang="scss">
#app {
    .input.error.no-content::after {
        content: unset;
    }
}
#app .input-ellipsis::v-deep input {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
#app .input-ellipsis::v-deep:has(.v-input__append-outer) {
    padding-right: 25px !important;
}
</style>
