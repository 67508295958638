<!-- @format -->

<template>
    <div class="salary-request-form">
        <modal
            v-model="dialog"
            permission="payment_requests"
            class-names="request-payment"
            :padding="false"
            width="25.0625rem"
            :close-icon-show="false"
        >
            <template slot="content">
                <div class="center modal-request-payment">
                    <div>
                        <div class="modal-header mb-40 d-flex align-center justify-space-between pt-6">
                            <h2 class="modal-title heading-2 bold-text"> Request payment </h2>
                            <button @click="dialog = false">
                                <i class="icon-times color-dark-grey"></i>
                            </button>
                        </div>
                        <!--custom-scrollbar email-modal-body-->
                        <div class="modal-body">
                            <div class="modal-form form-request-payment">
                                <div class="flex-form-group mb-20" v-if="!csvUpload">
                                    <tm-input-group
                                        v-model="salaryRequestObj.values.request_type"
                                        v-validate="salaryRequestObj.rules.request_type"
                                        :bottom-offset="false"
                                        type="radio"
                                        content-class="checkbox-invoice d-flex align-center mr-4"
                                        :horizontal="true"
                                        :items="requestTypesLoc"
                                        item-text="name"
                                        item-value="id"
                                        name="request_type"
                                        :error-messages="errors.collect('request_type')"
                                        data-vv-as="Request Type"
                                        :value="
                                            setFirstElementFromList(requestTypesLoc, 'request_type', salaryRequestObj)
                                        "
                                    ></tm-input-group>
                                </div>

                                <div v-show="salaryRequestObj.values.request_type === 'my_recipient'">
                                    <div class="form-group mb-20">
                                        <tm-select-contact
                                            v-model="salaryRequestObj.values.contact_id"
                                            v-validate="
                                                requestType === 'my_recipient' ? salaryRequestObj.rules.contact_id : ''
                                            "
                                            contact-type="salary_request"
                                            :bottom-offset="false"
                                            name="contact_id"
                                            :error-messages="errors.collect('contact_id')"
                                            data-vv-as="Payer"
                                            :seat="__isAnySeat"
                                        ></tm-select-contact>
                                    </div>
                                </div>
                                <div v-show="salaryRequestObj.values.request_type === 'invite_by_email'">
                                    <div class="form-group mb-20">
                                        <tm-input
                                            id="dropdownEmployeesMenu"
                                            v-model="salaryRequestObj.values.payer_name"
                                            v-validate="
                                                requestType !== 'my_recipient' ? salaryRequestObj.rules.payer_name : ''
                                            "
                                            label="Payer's name"
                                            :bottom-offset="false"
                                            name="payer_name"
                                            :error-messages="errors.collect('payer_name')"
                                            data-vv-as="payer name"
                                        ></tm-input>
                                    </div>

                                    <div class="form-group mb-20">
                                        <tm-input
                                            id="dropdownEmployeesMenu"
                                            v-model="salaryRequestObj.values.payer_email"
                                            v-validate="requestType !== 'my_recipient' ? emailRules : ''"
                                            label="Payer's email"
                                            :bottom-offset="false"
                                            name="payer_email"
                                            :error-messages="emailErrorMsg"
                                            data-vv-as="payer email"
                                        ></tm-input>
                                    </div>
                                </div>

                                <div class="form-group salary-form-group mb-20" v-if="!$store.state.organization.default">
                                    <tm-advanced-select
                                        :bottom-offset="false"
                                        label="Choose to send form the organisation or yourself"
                                        :items="fromNames"
                                        :item-add-class="' '"
                                        item-text="name"
                                        item-value="id"
                                        v-model="salaryRequestObj.values.from_request"
                                        name="from"
                                        :select-first="salaryRequestObj.values.from_request ? false : true"
                                        v-validate="salaryRequestObj.rules.from_request"
                                        :error-messages="errors.collect('from_request')"
                                        data-vv-as="from"
                                    ></tm-advanced-select>
                                </div>

                                <div class="form-group salary-form-group mb-20">
                                    <tm-input
                                        v-model="salaryRequestObj.values.net_amount"
                                        v-validate="netAmountRules"
                                        label="Amount"
                                        class="w-100 amount-salary-request"
                                        :bottom-offset="false"
                                        name="net_amount"
                                        placeholder="0.00"
                                        :error-messages="errors.collect('net_amount')"
                                        data-vv-as="amount"
                                        type="number"
                                    >
                                        <template slot="prepend">
                                            <tm-advanced-select
                                                v-model="salaryRequestObj.values.currency_id"
                                                v-validate="salaryRequestObj.rules.currency_id"
                                                class="salary-currency"
                                                :bordered="false"
                                                :bottom-offset="false"
                                                :items="currenciesInvoice"
                                                item-text="code"
                                                item-value="id"
                                                name="currency_id"
                                                :error-messages="errors.collect('currency_id')"
                                                data-vv-as="currency"
                                                :value="
                                                    setFirstElementFromList(
                                                        currenciesInvoice,
                                                        'currency_id',
                                                        salaryRequestObj,
                                                    )
                                                "
                                                :multiple="false"
                                            />
                                            <v-divider vertical />
                                        </template>
                                    </tm-input>

<!--                                    <div class="tm-err-box rounded mt-1 vue-repo-app" style="" v-if="ibanNotRequested">
                                        <span style="font-size: 0.75rem;">
                                             <template v-if="walletIban.iban_request">
                                                  Please note, you have not yet received an IBAN.
                                                  <a :href="`mailto:`+$store.state.systemEmails.support" class="color-white font-bold">Contact Support</a>
                                             </template>
                                             <template v-else>
                                                Please note: You have not yet requested an IBAN for receiving bank transfers.
                                                  <button
                                                      @click="$event.preventDefault(); ibanDialog = true"
                                                      class=""
                                                      :class="'color-white font-bold'"
                                                  >
                                                    <div>
                                                        <span class="tm-initial">Request now</span>
                                                    </div>
                                                </button>
                                             </template>

                                        </span>
                                    </div>-->

                                    <!--                                    <div class="flex-form-group mt-20 include-bonus">

                                        <tm-checkbox
                                                id="bonus"
                                                class="include-bonus-input"
                                                name="include_bonus"
                                                v-validate="salaryRequestObj.rules.include_bonus"
                                                label="Include Bonus"
                                                v-model="salaryRequestObj.values.include_bonus"
                                                :error-messages="errors.collect('include_bonus')"
                                        ></tm-checkbox>

                                    </div>-->
                                </div>

                                <div v-if="'NGN' !== currency && showCurrencyBanner" class="banner info">
                                    <i class="icon-info-circle-fill mr-1"></i>
                                    <p class="tiny-text color-black">
                                        While we are in the process of supporting direct card payments for this
                                        currency, there will be a fee deducted if your recipient pays by card. To avoid
                                        this fee, please ask your recipient to pay via bank transfer.
                                    </p>
                                </div>

                                <!--                                <div class="form-group mb-20" v-if="salaryRequestObj.values.include_bonus">
                                    <tm-input
                                            label="Bonus Amount"
                                            class="w-100 bonus_amount"
                                            :bottom-offset="false"
                                            name="bonus_amount"
                                            placeholder="0.00"
                                            v-model="salaryRequestObj.values.bonus_amount"
                                            :error-messages="errors.collect('bonus_amount')"
                                            v-validate="salaryRequestObj.rules.bonus_amount"
                                            data-vv-as="bonus amount">
                                    </tm-input>
                                </div>-->
                                <div class="form-group">
                                    <tm-text-area
                                        id="description"
                                        v-model="salaryRequestObj.values.description"
                                        v-validate="salaryRequestObj.rules.description"
                                        name="description"
                                        label="Notes"
                                        :error-messages="errors.collect('description')"
                                    ></tm-text-area>
                                </div>
                                <div class="form-group payment-frequency-form-group">
                                    <p class="normal-text bold-text color-black mb-1"> Payment frequency </p>
                                    <p class="paragraph color-dark-grey mb-20">
                                        Is this one-time payment or want to schedule automatic payments?
                                    </p>

                                    <div class="flex-form-group mb-20">
                                        <tm-input-group
                                            v-validate="salaryRequestObj.rules.frequency"
                                            v-model="salaryRequestObj.values.frequency"
                                            :bottom-offset="false"
                                            type="radio"
                                            content-class="checkbox-invoice d-flex align-center mr-4"
                                            :horizontal="true"
                                            :items="frequenciesLoc"
                                            item-text="name"
                                            item-value="id"
                                            value="weekly"
                                            name="frequency"
                                            :error-messages="errors.collect('frequency')"
                                            data-vv-as="frequency"
                                            :value="
                                                setFirstElementFromList(frequenciesLoc, 'frequency', salaryRequestObj)
                                            "
                                        ></tm-input-group>
                                    </div>

                                    <div class="group">
                                        <div class="form-group-content">
                                            <div v-show="frequency === 'weekly'">
                                                <tm-advanced-select
                                                    v-model="salaryRequestObj.values.exact_day"
                                                    v-validate="
                                                        frequency === 'weekly' ? salaryRequestObj.rules.exact_day : ''
                                                    "
                                                    class="exact-day mb-4"
                                                    label="Exact day"
                                                    :bordered="true"
                                                    :bottom-offset="false"
                                                    :items="weeklyDaysLoc"
                                                    item-text="name"
                                                    :is-sort="false"
                                                    item-value="id"
                                                    name="exact_day"
                                                    :error-messages="errors.collect('exact_day')"
                                                    data-vv-as="start day"
                                                    :value="
                                                        setFirstElementFromList(
                                                            weeklyDaysLoc,
                                                            'exact_day',
                                                            salaryRequestObj,
                                                        )
                                                    "
                                                ></tm-advanced-select>
                                            </div>

                                            <div v-show="frequency === 'monthly'">
                                                <tm-select-date
                                                    v-model="salaryRequestObj.values.exact_date"
                                                    v-validate="
                                                        frequency === 'monthly' ? salaryRequestObj.rules.exact_date : ''
                                                    "
                                                    label="Exact date"
                                                    name="exact_date"
                                                    :error-messages="errors.collect('exact_date')"
                                                    data-vv-as="start date"
                                                >
                                                </tm-select-date>
                                            </div>

                                            <div v-show="frequency === 'weekly' || frequency === 'monthly'" >
                                                <tm-select-date
                                                    v-model="salaryRequestObj.values.end_date"
                                                    v-validate="salaryRequestObj.rules.end_date"
                                                    label="End date"
                                                    name="end_date"
                                                    :min="moment().toISOString()"
                                                    :error-messages="errors.collect('end_date')"
                                                    data-vv-as="end date"
                                                >
                                                </tm-select-date>
                                            </div>
                                        </div>
                                    </div>
                                </div>

<!--                                <div class="form-group" v-if="hideAndShowSwitch">
                                    <div class="flex-form-group justify-space-between mb-4">
                                        <label for="includeMyName"
                                               style="color: black; padding-top: 20px; font-size: small; font-weight: bold;" >
                                            Include my name in the payment request
                                        </label>
                                        <tm-slide-checkbox
                                            style="padding-top: 20px"
                                            id="includeMyName"
                                            :return-int="true"
                                            :bottom-offset="false"
                                            v-model="salaryRequestObj.values.show_sender_name"
                                        ></tm-slide-checkbox>
                                    </div>
                                </div>-->
                            </div>
                        </div>
                        <div class="modal-footer pb-20">
                            <btn
                                :disabled="!isValid"
                                :loading="loading"
                                class="btn btn-request-payment btn-purple normal-text bold-text color-white w-100"
                                text="Save"
                                :small="false"
                                @click="csvUpdate()"
                                v-if="csvUpload"
                            ></btn>

                            <btn
                                :disabled="!isValid"
                                :loading="loading"
                                class="btn btn-request-payment custom-btn-primary normal-text bold-text color-white w-100"
                                text="Request payment"
                                :small="false"
                                :text-transform="false"
                                @click="create()"
                                v-else
                            ></btn>
                        </div>
                    </div>
                </div>
            </template>
        </modal>

        <modal
            v-if="successAdded"
            v-model="requestedDialog"
            class-names="request-payment-success"
            width="430px"
            close-color="#8F8F8F"
        >
            <template slot="content">
                <div class="center text-center modal-request-payment-sent">
                    <div>
                        <div class="modal-body">
                            <h2 class="heading-2 bold-text color-black mb-10">All Good!</h2>
                            <p class="normal-text color-black mb-40"> You have sent your payment request </p>
                            <img :src="$store.state.imagePath + 'baloon-guy.svg'" alt="baloon-guy.svg" />
                            <a
                                :href="$store.state.path + 'payment-request'"
                                class="go-to-request-button btn custom-btn-primary normal-text bold-text color-white w-100"
                            >
                                Go to my Requests
                            </a>
                        </div>
                    </div>
                </div>
                <!--<div class="center modal-request-info">
                <div>
                    <div class="modal-body">
                        <div class="payer-profile mb-35">
                            &lt;!&ndash;<img
                                    src="images/avatar-high-res.png"
                                    alt="avatar-high-res.png"
                            />
&ndash;&gt;
                            <avatar :name="salaryObj.recipient.name || salaryObj.recipient.email" size="70"></avatar>

                            <h1 class="heading-1 bold-text color-black mb-0">
                                {{salaryObj.recipient.name}}
                            </h1>
                            <p class="medium-text color-dark-grey">Payer</p>
                        </div>
                        <ul class="payer-request-info-list mb-20">
                            <li>
                                <p class="medium-text color-dark-grey">NET Amount</p>
                                <p class="small-text bold-text color-black">{{formatAmount(salaryObj.net_amount, salaryObj.currency.code)}}</p>
                            </li>
                            <li v-if="salaryObj.include_bonus">
                                <p class="medium-text color-dark-grey">Bonus</p>
                                <p class="small-text bold-text color-black">{{formatAmount(salaryObj.bonus_amount, salaryObj.currency.code)}}</p>
                            </li>
                            <li>
                                <p class="medium-text color-dark-grey">Frequency</p>
                                <div class="status blue-status">
                                    <p class="bonus-cell small-text bold-text uppercase-text">
                                        {{salaryObj.frequency}}
                                    </p>
                                </div>
                            </li>
                            <li>
                                <p class="medium-text color-dark-grey">Description</p>
                                <p
                                        class="small-text text-right bold-text color-black pl-40"
                                >
                                    {{cutText(salaryObj.description, 50)}}
                                </p>
                            </li>
                        </ul>
                        <button
                                class=" btn btn-chat-payer btn-dark-pink normal-text bold-text color-white  w-100 mb-50"
                        >
                            <i class="icon-chat pr-2"></i>
                            Chat with payer
                        </button>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-edit-request btn-p-0 btn-h-auto medium-text  bold-text color-purple ">
                            Edit Request
                        </button>
                        <button class=" btn btn-delete-request btn-cancel btn-p-0 btn-h-auto medium-text  bold-text color-dark-grey">
                            Delete
                        </button>
                    </div>
                </div>
                </div>-->
            </template>
        </modal>


        <iban-request-form
            v-model="ibanDialog"
            :wallet="walletIban"
        ></iban-request-form>
    </div>
</template>
<script>
import Modal from '~/shared/components/Modal';
import Btn from '~/shared/components/Btn';
import Helpers from '~/shared/mixins/Helpers';
import WalletIban from '~/shared/mixins/Wallet/WalletIban';
import TmAdvancedSelect from '~/shared/components/form/TmAdvancedSelect';
import TmInput from '~/shared/components/form/TmInput';
import TmInputGroup from '~/shared/components/form/TmInputGroup';
import TmTextArea from '~/shared/components/form/TmTextArea';
import SalaryRequest from '../../models/SalaryRequest';
import TmSelectDate from '~/shared/components/form/TmSelectDate';
import InvoiceContact from '../../models/InvoiceContact';
import TmSelectContact from '~/shared/components/form/TmSelectContact';
import { mapGetters } from 'vuex';
import TmSlideCheckbox from "~/shared/components/form/TmSlideCheckbox.vue";
import moment from "moment-timezone";
import IbanRequestForm from "./IbanRequestForm";

export default {
    name: 'SalaryRequestModal',

    components: {
        IbanRequestForm,
        TmSlideCheckbox,
        TmSelectContact,
        TmTextArea,
        TmSelectDate,
        TmInput,
        TmAdvancedSelect,
        Btn,
        Modal,
        TmInputGroup,
    },

    mixins: [Helpers, WalletIban],

    props: {
        value: {
            required: true,
            type: Boolean,
        },
        requestTypes: {
            default: null,
        },
        frequencies: {
            default: null,
        },
        fromNames: {
            default: null,
        },
        csvUpload: {
            default: false,
        },
        currency_id: {
            default: null,
        },
        weeklyDays: {
            default: null,
        },
        salaryObjProp: {
            type: Object,
            required: false,
        },
    },

    data()
    {
        if(this.currency_id)
        {
            this.salaryObjProp['currency_id'] = this.currency_id
        }

        return {
            showCurrencyBanner: false,
            requestedDialog: false,
            stripeMinCurrencyAmount: this.$store.state.minCurrencyAmountForStripe,

            contactDialog: false,
            contactObj: {},

            successAdded: false,
            salaryObj: this.salaryObjProp,

            dialog: this.value,
            salaryRequestObj: new SalaryRequest(this.salaryObjProp),
            loading: false,
            hideAndShowSwitch: true,

            deleteContactDialog: false,
            deleteContactLoading: false,
            deleteContactObj: new InvoiceContact(),

            requestTypesLoc: this.requestTypes || [],
            weeklyDaysLoc: this.weeklyDays || [],
            frequenciesLoc: this.frequencies || [],
            currencyMin: 1,
        };
    },

    computed: {
        ...mapGetters(['currenciesInvoice', 'wallets']),
        isValid() {
            return !Object.keys(this.fields).some(
                key =>
                    (this.fields[key].required &&
                        !this.salaryRequestObj.values[key] &&
                        this.salaryRequestObj.values[key] !== 0) ||
                    this.fields[key].invalid,
            );
        },
        netAmountRules() {
            this.changeCurrencyTxt();
            return 'required|min_value:' + this.currencyMin;
        },
        currency() {
            const currencyCode = this.salaryRequestObj.values.currency_id
                ? this.currenciesInvoice.find(item => item.id === this.salaryRequestObj.values.currency_id).code
                : null;

            this.showCurrencyBanner =
                currencyCode &&
                this.$store.state.stripeRegisteredCurrencies &&
                this.$store.state.stripeRegisteredCurrencies.indexOf(currencyCode) <= -1 &&
                this.showBankInfo(currencyCode);

            this.ibanTab = currencyCode

            return currencyCode;
        },
        requestType() {
            return this.salaryRequestObj.values.request_type;
        },
        frequency() {
            return this.salaryRequestObj.values.frequency;
        },
        includeBonus() {
            return this.salaryRequestObj.values.include_bonus;
        },
        emailRules() {
            return this.$store.state.info
                ? this.salaryRequestObj.rules.payer_email + '|excluded:' + this.$store.state.info.email
                : this.salaryRequestObj.rules.payer_email;
        },
        emailErrorMsg() {
            return this.errors.items.find(i => i.field === 'payer_email' && i.rule === 'excluded')
                ? [`Recipient's email must be different from yours`]
                : this.errors.collect('payer_email');
        },
    },

    watch: {
        ['salaryRequestObj.values.net_amount']() {
            this.getMinAdmissibleAmount();
        },
        ['salaryRequestObj.values.from_request']() {
            if (this.salaryRequestObj.values.from_request === 0){
                this.hideAndShowSwitch = false;
                this.salaryRequestObj.values.show_sender_name = 0;
            }
            else {
                this.hideAndShowSwitch = true;
                this.salaryRequestObj.values.show_sender_name = 1;
            }

        },
        ['salaryRequestObj.values.currency_id']() {
            this.getMinAdmissibleAmount();
            this.changeCurrencyTxt();
        },
        value() {
            this.dialog = this.value;
            if (this.value) {
                if (!this.weeklyDays || !this.requestTypes || !this.frequencies) {
                    this.__getData('get-salary-request-data');
                }
            }
        },
        dialog()
        {
            if(this.currency_id)
            {
                this.salaryObj['currency_id'] = this.currency_id
            }

            this.salaryRequestObj = new SalaryRequest(this.salaryObj);
            this.$emit('input', this.dialog);

            this.$validator.reset();

            if (this.dialog) {
                this.$validator.resume();
            } else {
                this.$validator.pause();
                this.errors.clear();
            }
        },
    },

    methods: {
        getMinAdmissibleAmount() {
          this.currencyMin = (this.currency in this.stripeMinCurrencyAmount) ? this.stripeMinCurrencyAmount[this.currency] : this.stripeMinCurrencyAmount['default'];
        },
        changeCurrencyTxt() {
            if (this.currency) {
                const dict = {
                    custom: {
                        net_amount: {
                            min_value: `Minimum amount is ${this.currencyMin} ${this.currency}`,
                        },
                    },
                };

                this.$validator.localize('en', dict);
            }
        },
        create() {
            this.$validator.validateAll().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.salaryRequestObj
                        .create(this.$store.state.path + 'payment-request/store')
                        .then(response => {
                            this.loading = false;
                            this.dialog = false;
                            this.requestedDialog = true;
                            //this.salaryObj = response.data.data;
                            this.successAdded = true;
                            this.$emit('sendRequest', true);
                            //this.$store.commit("setAlert", {type: 'info', message: response.data.message});
                        })
                        .catch(error => {
                            this.loading = false;
                            this.$store.commit('setAlert', { type: 'error', message: error.response.data.message });
                        });
                }
            });
        },
        csvUpdate() {
            this.$validator.validateAll().then(valid => {
                if (valid) {
                    this.dialog = false;
                    this.requestedDialog = true;
                    this.$emit('csvUpdate', this.salaryRequestObj.values);
                }
            });
        },
    },
};
</script>
