import axiosConfig from "~/providers/configs/axiosConfig";
import chatWidgetConfig from "~/providers/configs/chatWidgetConfig";
import validatorConfig from "~/providers/configs/validatorConfig";
import definitions from "~/providers/configs/definitions";
import devtoolsConfig from "~/providers/configs/devtoolsConfig";
import datadogConfig from "~/providers/configs/datadogConfig";
import componentsConfig from "~/providers/configs/componentsConfig";
import stripeConfig from "~/providers/configs/stripeConfig";
import setDefaultState from "~/providers/configs/setDefaultState";
import recaptchaConfig from "@/providers/configs/recaptchaConfig";

export default class Core {
    #config = [
        definitions,
        devtoolsConfig,
        axiosConfig,
        chatWidgetConfig,
        validatorConfig,
        datadogConfig,
        componentsConfig,
        stripeConfig,
        setDefaultState,
        recaptchaConfig
    ];

    run(){
        this.#config.forEach(c => new c().resolve());
    }
}
