import Middleware from "~/routes/middleware/Middleware";
import store from "~/store";

export default class trackVisitor extends Middleware{
    constructor(...args) {
        super(...args);
    }

    resolve() {
        store.dispatch('hubspot/trackVisitor', {path: this.to.fullPath}).then(() => {});
    }
}
