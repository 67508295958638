<template>
	<v-app class="container-fluid wizard-layout">
		<router-view/>
		<BaseScreenLoading v-if="isLoading"/>
		<!--        <WlBaseModal v-model="alertModal.value" class-names="base-modal-alert-wallet" width="25rem">-->
		<!--            <ModalInfo :modal-data="alertModal.data"-->
		<!--                       @on-confirm="alertModal.value = false, alertModal.onClose()" />-->
		<!--        </WlBaseModal>-->
		<v-dialog
			:value="alertModal.value"
			content-class="custom-modal modal-md"
		>
			<ModalInfo
				:leftAligned="true"
				:modalData="alertModal.data"
				@on-confirm="alertModal.value = false, alertModal.onClose()"
			>
			</ModalInfo>
		</v-dialog>
	</v-app>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ModalInfo from "~/components/Modals/ModalInfo.vue";

export default {
	components: {ModalInfo},
	name: "WizardApp",
	props: {
		pageData: {
			default: () => {}
		}
	},
	computed: {
		...mapGetters("global", ["isLoading"]),
		alert() {
			return this.$store.state.alert;
		},
		alertModal() {
			return this.$store.state.alertModal;
		},
	},
};
</script>

<style scoped lang="scss">
.wizard-layout {
	padding: 0;
	min-height: 100 dvh;
}
</style>
