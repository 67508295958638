import DOMPurify from "dompurify";

export const getTextWidth = (text, defaultMaxWidth, fontStyle) => { 
    const widths = text.split(' ').map(word => getWordWidth(word, fontStyle));
    let spaceWidth = getWordWidth(' ', fontStyle);
    let maxWidth = 0;
    let currWidth;
    for (let i = 0; i < widths.length; i++) {
        if (widths[i] > defaultMaxWidth) {
            return defaultMaxWidth;
        } else {
            currWidth = widths[i];
        }
        for (let j = i + 1; j < widths.length; j++) {
            if (widths[j] > defaultMaxWidth) {
                return defaultMaxWidth;
            } else {
                if (widths[j] + currWidth + spaceWidth < defaultMaxWidth) {
                    currWidth += spaceWidth + widths[j];
                } else {
                    i += j;
                    break;
                }
            }
        }
        if (currWidth > maxWidth) {
            maxWidth = currWidth;
        }
    }
    return maxWidth;
};
export const getWordWidth = (text, fontStyle) => {
    const canvas = document.createElement('canvas'); 
    const context = canvas.getContext('2d'); 
    context.font = fontStyle; 
    return Math.ceil(context.measureText(text).width); 
};

/**
 * Sanitize html, safety html
 */
export const sanitizeHtml = str => DOMPurify.sanitize(str);

/**
 * Remove all html tags
 */
export const cleanHtml = str => DOMPurify.sanitize(str, { ALLOWED_TAGS: [] });

/**
 * Transform urls to links
 */
export const transformUrlsToLinks = str => {
    // Use a regular expression to find URLs in the text and replace them with clickable links
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return cleanHtml(str).replace(urlRegex, url => `<a href="${url}" target="_blank">${url}</a>`);
};