import Model from '~/shared/Model';

export default class SalaryRequest extends Model {

    constructor(obj = {}) {
        super();

        this.hidden = ['id', 'payer_uid'];

        let show_sender = (obj.show_sender_name || obj.show_sender_name === 0) ?  obj.show_sender_name : 1
        let from_request = (obj.from_request || obj.from_request === 0) ?  obj.from_request : 0

        this.values = {
            request_type: obj.request_type || '',
            payer_name: obj.payer_name || '',
            payer_email: obj.payer_email || '',
            contact_id: obj.contact_id || '',
            currency_id: obj.currency_id || '',
            request_category_id: obj.request_category_id || '',
            net_amount: obj.net_amount || '',
            from_request: from_request,
            include_bonus: obj.include_bonus || false,
            bonus_amount: obj.bonus_amount || '',
            description: obj.description || '',
            frequency: obj.frequency || '',
            exact_day: obj.exact_day || '',
            end_date: obj.end_date || '',
            exact_date: obj.exact_date || '',
            show_sender_name: show_sender
        }

        this.rules = {
            contact_id: 'required',
            payer_name: 'required',
            payer_email: 'required|email',
            request_type: 'required',
            currency_id: 'required',
            request_category_id: 'required',
            net_amount: 'required|min_value:0.5|max_value:999999',
            description: 'required',
            frequency: 'required',

            exact_day: 'required',
            exact_date: 'required'
        };
    }
}
