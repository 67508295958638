<template>
    <base-select
        :items="items"
        single-line
        menu-props="auto"
        v-bind="props"
        v-on="$listeners"
    >
        <template slot="prepend">
            <i class="icon-calendar" style="margin-bottom: 2px"></i>
        </template>
    </base-select>
</template>
<script>
export default {
    name: 'BaseSelectYear',
    props: {
        value: {
            default: null
        },
        placeholder: {
            default: null
        },
        label: {
            default: null
        },
        min: {
            default: 2000,
        },
        max: {
            default: 2050,
        }
    },
    data(){
        return {
            exceptProps: ['items', 'min', 'max'],
            items: []
        }
    },
    computed: {
        props(){
            return Object.fromEntries(Object.entries(this.$props).filter(([key]) => !this.exceptProps.includes(key)));
        }
    },
    methods: {
        setItems(){
            if (this.max < this.min){
                console.error('max smaller than min');
            }

            for (let i = this.min; i <= this.max; i++) {
                this.items.push({name: i.toString(), value: i.toString()});
            }
        }
    },
    created() {
        this.setItems();
    }
}
</script>
