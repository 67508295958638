require('~/shared/components/BaseElements/index');

import Vue from 'vue';
import Icon from"~/shared/components/Icon.vue";

export default class componentsConfig{
    resolve() {
        /*Register wallet base components*/
        const requireComponent = require.context(
            "~/modules/wallet/components/BaseComponents",
            false,
            /Base[A-Z]\w+\.(vue|js)$/
        );
        requireComponent.keys().forEach((fileName) => {
            const componentConfig = requireComponent(fileName);

            const componentName = _.upperFirst(
                _.camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
            );

            Vue.component(componentName, componentConfig.default || componentConfig);
        });

        Vue.component('Icon', Icon);
    }
}
