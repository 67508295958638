import Model from '~/shared/Model'
export default class GreetingInfo extends  Model{

    constructor(obj = {}, path = null) {
        super();
        this.path = path;
        this.hidden = ['id', 'avatar'];

        this.values = {
            id: obj.id || null,
            name: obj.name || '',
            birthday: obj.birthday || '',
            country_id: obj.country_id && obj.country_id !== 1 ? obj.country_id : '',
            city: obj.city || '',
            type_id: obj.type_id || '',
            purposes: obj.purposes || [],
            invite_email: obj.invite_email || '',
            other_text: obj.other_text || '',

            avatar: obj.avatar || null,
            avatar_file: obj.avatar_file || null,
            clear_avatar: obj.clear_avatar || 0,

            freelance_info: {
                title: obj.freelance_info? obj.freelance_info.title: '',
                experience_id: obj.freelance_info? obj.freelance_info.experience_id: '',
                company: obj.freelance_info? obj.freelance_info.company: '',
                industry_id: obj.freelance_info? obj.freelance_info.industry_id: '',
            },
            work_info: {
                title: obj.work_info? obj.work_info.title: '',
                experience_id: obj.work_info? obj.work_info.experience_id: '',
                company_id: obj.work_info? obj.work_info.company_id: '',
                company_name: obj.work_info? obj.work_info.company_name: '',
                industry_id: obj.work_info? obj.work_info.industry_id: '',
                employment_type: obj.work_info? obj.work_info.employment_type: ''
            },
            interests_info: {
                occupation: obj.interests_info? obj.interests_info.occupation: '',
                experience_id: obj.interests_info? obj.interests_info.experience_id: '',
                role_ids: obj.interests_info? obj.interests_info.role_ids: []
            }
        }

        this.rules = {
            name: 'min:3|max:100',
            country_id: '',
            city: 'max:100',
            birthday: '',
            type_id: '',
            purpose_id: '',
            avatar_file: '',
            other_text: 'max:255',

            freelance_info: {
                title: 'required|min:3|max:100',
                experience_id: 'required',
                company: 'required|max:100',
                industry_id: 'required',
            },
            work_info: {
                title: 'required|min:3|max:100',
                company_id: 'required',
                experience_id: 'required',
                industry_id: 'required',
                employment_type: 'required'
            },
            interests_info: {
                occupation: 'required|min:3|max:100',
                experience_id: 'required',
                role_ids: 'required'
            }
        };
    }
}
