<template>
    <div
        v-if="!isHidden"
        class="kyc-verification-dialog vue-repo-app"
        style="left: 1rem !important"
    >
        <div class="kyc-verification-header">
            <BaseIcon name="error-thin-circle-outline" class="color-white" />
            <BaseButton
                class="btn-icon-sm text-xs color-white"
                type="button"
                @click="hideKYC"
            >
                <BaseIcon name="times" class="color-white" />
            </BaseButton>
        </div>
        <div class="kyc-verification-body">
            <p class="text-sm leading-150 color-white">
                {{
                    !isStarted
                        ? "Unlock features and enhance your app experience by completing the verification process today!"
                        : "You still need to complete your KYC ID verification in order to access some of the main features."
                }}
            </p>
        </div>
        <div class="kyc-verification-footer">
            <BaseButton
                class="btn-md btn-white font-medium text-sm color-purple w-100"
                type="button"
                @click="__checkVerified"
            >
                {{ !isStarted ? "Start verification" : "Complete verification" }}
            </BaseButton>
        </div>
    </div>
</template>

<script>

import Helpers from "~/shared/mixins/Helpers";

export default {
    name: "KYCStatusDialog",
    mixins: [Helpers],
    data(){
        return {
            hide: true,
        }
    },
    computed: {
        isStarted(){
            return this.$store.state.kyc.options.status !== 'not_started';
        },
        isHidden(){
            return this.hide || this.isVerified || this.isPending || this.isFinalRejected || this.closedToday;
        },
        isVerified(){
            return this.$store.state.kyc.verified === true;
        },
        isPending(){
            return this.$store.state.kyc.verified && this.$store.state.kyc.verified !== true;
        },
        isFinalRejected(){
            return this.$store.state.kyc.restrictionType &&
                (this.$store.state.kyc.restrictionType === 'final' || this.$store.state.kyc.restrictionType === 'block');
        },
        closedAt(){
            return this.$store.state.authUser.options.KYCAlertClosedAt;
        },
        closedToday(){
            return this.closedAt && this.closedAt > Date.now() - 86400000;
        }
    },
    methods: {
        hideKYC(){
            this.hide = true;
            this.$store.dispatch('updateUserOptions', {KYCAlertClosedAt: Date.now()});
        },
    },
    mounted(){
        this.hide = (this.isVerified || this.isPending);
    }
};
</script>
