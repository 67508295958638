import Vue from "vue";
import {__isProdMode} from "@/utils/config";

export default class devtoolsConfig{
    resolve() {
        if (__isProdMode()) {
            Vue.config.devtools = false;
            Vue.config.debug = false;
            Vue.config.silent = true;
        }
    }
}
