export const namespaced = true;
import contacts from "~/assets/data/contacts.json";
import Vue from "vue";
const { format } = require("date-fns");

export const state = {
    contacts,
    filters: {
        page: 1,
        perPage: 9,
        search: "",
        country: null,
        limit: 6,
    },
    totalContacts: 0,
    countries: [],
    lastInvoice: null,
    lastPaymentRequest: null,
    contactItems: [],
    getStatsData: {},
    contactPagination: null,
    loadingContacts: false,
    gettingPaginationData: false,
    isContactDeleteModalOpen: false,
};

export const mutations = {
    SET_FILTERS(state, filters) {
        state.filters = { ...state.filters, ...filters };
    },

    SET_GETTING_PAGINATION_DATA(state,payload){
        state.gettingPaginationData = payload;
    },

    loadingContacts(state, payload) {
        state.loadingContacts = payload;
    },

    SET_CONTACTS(state, payload) {
        state.contactItems = payload.data.data._payload;
        state.contactPagination = payload.data.data;
    },

    SET_COUNTRIES(state, payload) {
        state.countries = payload.data.data;
    },

    SET_TOTAL_CONTACTS(state, payload) {
        state.totalContacts = payload.data.data.contactsCount;
    },

    SET_STATS(state, payload) {
        const countsByMonth = payload.data.data.countsByMonth
        const percentageChange = payload.data.data.percentageChange

        state.getStatsData = {
            countsByMonth,
            percentageChange
        }
    },

    SET_LAST_INVOICE(state, payload) {
        state.lastInvoice = payload.data.data;
    },

    SET_LAST_PAYMENT_REQUEST(state, payload) {
        state.lastPaymentRequest = payload.data.data;
    },

    ADD_CONTACT(state, payload) {
        let country;
        if (typeof payload.address.country === "object") {
            country = state.countries.find(
                (x) => x.code === payload.address.country.code
            );
        } else {
            country = state.countries.find((x) => x.code === payload.address.country);
        }

        contacts.push({
            id: contacts.length + 1,
            dateAdded: format(new Date(), "dd/MM/yyyy"),
            ...payload.generalInformation,
            ...payload.address,
            ...payload.bankDetails,
            country,
        });
    },

    EDIT_CONTACT(state, { id, payload }) {
        const index = contacts.findIndex((contact) => contact.id === id);

        if (index !== -1) {
            let country;
            if (typeof payload.address.country === "object") {
                country = state.countries.find(
                    (x) => x.code === payload.address.country.code
                );
            } else {
                country = state.countries.find((x) => x.code === payload.address.country);
            }

            const updatedContact = {
                ...contacts[index],
                ...payload.generalInformation,
                ...payload.address,
                ...payload.bankDetails,
                country,
                avatar: contacts[index].avatar,
            };

            Vue.set(contacts, index, updatedContact);
        }
    },

    DELETE_CONTACT_MODAL(state, boolValue) {
        state.isContactDeleteModalOpen = boolValue
    },
};

export const actions = {

    setGettingPaginationData({commit}, payload) {
        commit("SET_GETTING_PAGINATION_DATA", payload);
    },

    async contactDeleteModal({ state, commit, rootState }, boolValue) {
        commit("DELETE_CONTACT_MODAL", boolValue);
    },

    async getContactList({ state, commit, rootState }, q = {}) {
        commit('loadingContacts', true);
        const filterData = Object.assign({}, state.filters);
        filterData.page = q.page || 1;

        for (const [key, value] of Object.entries(q)) {
            if ('page' !== key) {
                filterData[key] = value;
            }

        }
        let path = rootState.path + `v2/contacts/paginate`;

        await axios
            .get(path, {
                params: filterData,
            })
            .then(response => {
                commit("SET_CONTACTS", response);
            })
            .catch(error => {
                commit('SET_ALERT_MODAL',{type: 'error', data: {title: "Error", text:  error.response.data.message}}, { root: true });
            })
            .finally(() => {
                commit('loadingContacts', false);
            });
    },

    async getCountries({ state, commit, rootState }, q = {}) {

        let path = rootState.path + `v2/contacts/contact-countries`;

        await axios
            .get(path)
            .then(response => {
                commit("SET_COUNTRIES", response);
            })
            .catch(error => {
                commit('SET_ALERT_MODAL',{type: 'error', data: {title: "Error", text:  error.response.data.message}}, { root: true });
            })
            .finally(() => {
            });
    },

    async getTotalCount({ state, commit, rootState }, q = {}) {

        let path = rootState.path + `v2/contacts/total-contacts`;

        await axios
            .get(path)
            .then(response => {
                commit("SET_TOTAL_CONTACTS", response);
            })
            .catch(error => {
                commit('SET_ALERT_MODAL',{type: 'error', data: {title: "Error", text:  error.response.data.message}}, { root: true });
            })
            .finally(() => {
            });
    },

    async getStats({ state, commit, rootState }, q = {}) {

        let path = rootState.path + `v2/contacts/stats`;

        await axios
            .post(path)
            .then(response => {
                commit("SET_STATS", response);
            })
            .catch(error => {
                commit('SET_ALERT_MODAL',{type: 'error', data: {title: "Error", text:  error.response.data.message}}, { root: true });
            })
            .finally(() => {
            });
    },

    async getLastContact({ state, commit, rootState }, data) {

        let path = rootState.path + `v2/contacts/last-contact-by-type`;

        await axios
            .post(path, data)
            .then(response => {
                if(data.type === 'invoice'){
                    commit("SET_LAST_INVOICE", response);
                }else{
                    commit("SET_LAST_PAYMENT_REQUEST", response);
                }
            })
            .catch(error => {
            })
            .finally(() => {
            });
    },

    setFilters({ commit }, filters) {
        commit("SET_FILTERS", filters);
    },

    addContact({ commit }, payload) {
        commit("ADD_CONTACT", payload);
    },

    editContact({ commit }, { id, payload }) {
        commit("EDIT_CONTACT", { id, payload });
    },

    async refreshData({ commit, state, dispatch, rootState }) {
        dispatch('getContactList');
        dispatch('getTotalCount');
    },
    async deleteContact({ commit, state, dispatch, rootState }, id) {

        let path = rootState.path + `v2/contacts/destroy/`+id;

        await axios
            .delete(path)
            .then(response => {
                dispatch('refreshData');
                dispatch('contactDeleteModal', true);
            })
            .catch(error => {
            })
            .finally(() => {
            });
    },
};

export const getters = {

    loadingContacts: state => state.loadingContacts,

    filters: state => state.filters,

    contactItems: state => state.contactItems,

    getStatsData: state => state.getStatsData,

    lastInvoice: state => state.lastInvoice,

    lastPaymentRequest: state => state.lastPaymentRequest,

    countries: state => state.countries,

    totalContacts: state => state.totalContacts,

    contactPagination: state => state.contactPagination,

    isContactDeleteModalOpen: state => state.isContactDeleteModalOpen,

    gettingPaginationData: (state) => state.gettingPaginationData,

    getContacts: (state) => {

        if (state.filters && state.filters.country) {
            contacts = contacts.filter(
                (contact) => contact.country.code === state.filters.country
            );
        }

        if (state.filters && state.filters.search) {
            contacts = contacts.filter(
                (contact) =>
                    contact.name
                        .toLowerCase()
                        .includes(state.filters.search.toLowerCase()) ||
                    contact.email
                        .toLowerCase()
                        .includes(state.filters.search.toLowerCase())
            );
        }

        const total = contacts.length;

        const startIndex = state.filters ? (state.filters.page - 1) * state.filters.perPage : 6;
        const endIndex = startIndex + state.filters ? state.filters.perPage : 6;

        return {
            total,
            contacts: contacts.slice(startIndex, endIndex),
        };
    },

    getDashboardData: (state) => {
        const total = state.contactItems.length;

        if(!total){
            return false;
        }

        const lastTwoContacts = state.contactItems.slice(-2);

        const now = new Date();

        // Function to get the month name
        const getMonthName = (monthIndex) => {
            const monthNames = [
                "JAN",
                "FEB",
                "MAR",
                "APR",
                "MAY",
                "JUN",
                "JUL",
                "AUG",
                "SEP",
                "OCT",
                "NOV",
                "DEC",
            ];
            return monthNames[monthIndex];
        };

        // Initialize an object to store the counts per month for the last 6 months
        const countsByMonth = {};

        // Create an array of the last 6 months in reverse order
        for (let i = 5; i >= 0; i--) {
            const monthDate = new Date(now.getFullYear(), now.getMonth() - i, 1);
            const monthName = getMonthName(monthDate.getMonth());
            countsByMonth[monthName] = 0;
        }

        let last6MonthsCount = 0;
        let previous6MonthsCount = 0;

        // Iterate through the contacts
        state.contactItems.forEach((contact) => {
            const dateAdded = new Date(
                contact.created
            );

            // Check if the dateAdded is within the last 12 months
            const diffInMonths =
                (now.getFullYear() - dateAdded.getFullYear()) * 12 +
                (now.getMonth() - dateAdded.getMonth());

            if (diffInMonths >= 0 && diffInMonths < 6) {
                const monthName = getMonthName(dateAdded.getMonth());
                if (countsByMonth[monthName] !== undefined) {
                    countsByMonth[monthName]++;
                }
                last6MonthsCount++;
            } else if (diffInMonths >= 6 && diffInMonths < 12) {
                previous6MonthsCount++;
            }
        });

        // Calculate percentage change
        let percentageChange = 0;
        if (previous6MonthsCount !== 0) {
            percentageChange =
                ((last6MonthsCount - previous6MonthsCount) / previous6MonthsCount) *
                100;
        } else if (last6MonthsCount > 0) {
            percentageChange = 100; // 100% increase if there were no contacts in the previous period but there are some now
        }

        return {
            total,
            lastTwoContacts,
            countsByMonth,
            percentageChange,
        };
    },
};
