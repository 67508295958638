<template>
  <div class="table-pagination" v-if="calcPageCount > 1">
    <slot></slot>
    <span class="pages-text">
      {{ showedItems.from }} - {{ showedItems.to }} of
      {{ length }}
    </span>
    <v-pagination
        v-model="pageValue"
        :length="calcPageCount"
        :total-visible="totalVisible"
        :disabled="disabled"
        next-icon="icon-chevron-right-2"
        prev-icon="icon-chevron-left-2"
    />
  </div>
</template>

<script>
export default {
  name: "BaseTablePaginator",

  props: {
    value: {
      type: [Number, String],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pageCount: {
      type: [Number, String],
    },
    length: {
      type: [Number, String],
    },
    itemsPerPage: {
      type: [Number, String],
    },
    totalVisible: {
      type: [Number, String],
      default: 7,
    },
  },

  data() {
    return {
      pageValue: this.value,
    };
  },

  computed: {
    showedItems() {
      let from =
          this.pageValue - 1 !== 0
              ? this.itemsPerPage * (this.pageValue - 1) + 1
              : 1;
      let to =
          this.pageValue !== this.calcPageCount
              ? this.itemsPerPage * this.pageValue
              : this.length;
      return {
        from: from,
        to: to,
      };
    },

    calcPageCount() {
      return this.pageCount || Math.ceil(this.length / this.itemsPerPage);
    },
  },

  watch: {
    value(val) {
      this.pageValue = val;
    },
    pageValue(val) {
      this.$emit("input", val);
    },
  },
};
</script>

