import currencies from "~/assets/data/currenciesSimpleData.json";
import { getUnlimintCardStatus, getUnlimintCardRequestStatus, getNativeCardRequestStatus } from '~/shared/utils/wallet-utils';
import Card from '~/modules/dashboard/models/Card';

const currenciesData = currencies;
currenciesData.forEach(currency => currency.image = currency.img);

const generateCard = (
    cardTypeName,
    cardProperties = {},
    currency,
    isOnlyEquals = false,
    loadedCard = {}
) => {
    let image;
    if (cardProperties.provider === 'unlimint') {
        if (cardProperties.isVirtual) {
            image = "nt-virtual-card";
        } else {
            image = "nt-physical-card";
        }
    } else if (cardProperties.provider === 'native') {
        image = "nt-equals-card";
    } else if (cardTypeName === "modulr") {
        image = "regular-nt-atm-card";
    } else if (
        cardTypeName === "premium" ||
        cardTypeName === "virtualComingSoon"
    ) {
        image = "nt-premium-card";
    } else {
        image = "nt-equals-card";
    }
    return {
        id: loadedCard?.id,
        currencyId: loadedCard?.currency_id,
        stored: cardTypeName.includes("stored"),
        cardTypeName: cardTypeName,
        fullCardTypeName: cardProperties.typeWithCurrency ?? cardTypeName,
        image: image,
        currency: currency,
        creditCardNumber: loadedCard?.creditCardNumber ?? null,
        last4: loadedCard?.last4 ?? null,
        expirationDate: loadedCard?.expirationDate ?? (loadedCard?.exp_month && loadedCard?.exp_year ? `${loadedCard.exp_month}/${loadedCard.exp_year.toString().slice(-2)}` : null) ?? null,
        CVV: loadedCard?.cvv ?? null,
        PIN: loadedCard?.pin ?? null,
        cardHolderName: loadedCard?.holder ?? null,
        currentBalance: loadedCard?.balance ?? 0,
        availableBalance: loadedCard?.availableBalance ?? 0,
        reservedBalance: loadedCard?.reservedBalance ?? 0,
        cardIssuer: loadedCard?.brand ?? null,
        isNonEU: isOnlyEquals,
        cardStatus: cardProperties.status ?? loadedCard?.status ?? null,
        virtual: loadedCard?.virtual ?? null,
        blocked: loadedCard?.blocked ?? null,
        linkedWallet: currency,
        provider: cardProperties.provider ?? loadedCard?.api_type ?? null,
        cardTransactions: [],//generateTransactions(currency),
        cardRequestId: loadedCard?.card_request_id ?? null,
        trackingLink: loadedCard?.tracking_link ?? null,
        trackingNumber: loadedCard?.tracking_number ?? null,
    };
};

function getCurrency(currencyCode) {
    return currenciesData.find(currency => currency.currency === currencyCode);
}

const state = {
    unlimintCards: [],
    unlimintCardRequests: [],
    nativeCards: [],
    nativeCardRequests: [],
    storedCardRequests: [],
    currencies: [],
    availableCurrencies: [],
    cards: {},
    activeCard: {},
    activeCardId: null,
    filters: {
        calendarDateRange: [],
        date: "",
        search: "",
        statuses: [],
        types: [],
        currencies: [],
    },
    newCardCurrencyId: null,
    cardModel: {},
    modalCardTopUpOpened: false,
};

const mutations = {
    SET_FILTERS(state, filters) {
        state.filters = filters;
    },
    SET_UNL_CARDS(state, cards) {
        state.unlimintCards = cards;
    },
    ADD_UNLIMINT_CARD(state, card) {
        state.unlimintCards.push(card);
    },
    ADD_UNLIMINT_CARD_REQUEST(state, cardRequest) {
        state.unlimintCardRequests.push(cardRequest);
    },
    SET_UNL_CARD_REQUESTS(state, requests) {
        state.unlimintCardRequests = requests;
    },
    SET_NATIVE_CARDS(state, cards) {
        state.nativeCards = cards;
    },
    ADD_NATIVE_CARD_REQUEST(state, cardRequest) {
        state.nativeCardRequests.push(cardRequest);
    },
    SET_NATIVE_CARD_REQUESTS(state, requests) {
        state.nativeCardRequests = requests;
    },
    SET_STORED_CARD_REQUESTS(state, requests) {
        state.storedCardRequests = requests;
    },
    SET_AVAILABLE_CURRENCIES(state, currencies) {
        state.availableCurrencies = currencies;
    },
    SET_CARDS(state, cards) {
        state.cards = cards;
    },
    SET_ACTIVE_CARD(state, cardTypeName) {
        state.activeCard = state.cards[cardTypeName];
        state.activeCardId = cardTypeName;
    },
    UNSET_ACTIVE_CARD(state) {
        state.activeCard = {};
        state.activeCardId = null;
    },
    SET_ACTIVE_CARD_ID(state, cardTypeName) {
        state.activeCardId = cardTypeName;
    },
    SET_ACTIVE_CARD_TRANSACTIONS(state, transactions) {
        state.activeCard.cardTransactions = transactions;
    },
    SET_NEW_CARD_CURRENCY_ID(state, currencyId) {
        state.newCardCurrencyId = currencyId;
    },
    SET_CARD_MODEL(state, cardModel) {
        state.cardModel = cardModel;
    },
    SET_MODAL_CARD_TOPUP_OPENED(state, payload) {
        state.modalCardTopUpOpened = payload;
    },
};

const actions = {
    setFilters({commit}, filters) {
        commit("SET_FILTERS", filters);
    },
    setTransactions({commit}, transactions) {
        return new Promise((resolve) => {
            commit("SET_ACTIVE_CARD_TRANSACTIONS", transactions);
            resolve(true);
        });
    },
    clearFilters({commit}) {
        const filters = {
            calendarDateRange: [],
            date: "",
            search: "",
            statuses: [],
            types: [],
            currencies: [],
        };
        commit("SET_FILTERS", filters);
    },
    setUnlimintCards({commit}, cards) {
        commit("SET_UNL_CARDS", cards);
    },
    addUnlimintCard({commit}, card) {
        commit('ADD_UNLIMINT_CARD', card);
    },
    addUnlimintCardRequest({commit}, cardRequest) {
        commit('ADD_UNLIMINT_CARD_REQUEST', cardRequest);
    },
    setUnlimintCardRequests({commit}, requests) {
        commit("SET_UNL_CARD_REQUESTS", requests);
    },
    setNativeCards({commit}, cards) {
        commit("SET_NATIVE_CARDS", cards);
    },
    addNativeCardRequest({commit}, cardRequest) {
        commit('ADD_NATIVE_CARD_REQUEST', cardRequest);
    },
    setNativeCardRequests({commit}, requests) {
        commit("SET_NATIVE_CARD_REQUESTS", requests);
    },
    setStoredCardRequests({commit}, requests) {
        commit("SET_STORED_CARD_REQUESTS", requests);
    },
    setAvailableCurrencies({commit}, currencies) {
        commit("SET_AVAILABLE_CURRENCIES", currencies);
    },
    setActiveCard({state, commit, dispatch}, fullCardTypeName) {
        if (Object.keys(state.cards).length === 0) {
            return;
        }
        if (!state.activeCard && !state.activeCard.id || fullCardTypeName !== state.activeCard.fullCardTypeName) {
            commit("SET_ACTIVE_CARD", fullCardTypeName);
            if (state.activeCard.provider === 'unlimint') {
                dispatch('cardService/getBalance', {
                    service: state.activeCard.provider,
                    id: state.activeCard.id,
                    type: state.activeCard.cardTypeName
                }, { root: true });
            }
        }
    },
    setActiveCardId({commit, dispatch}, fullCardTypeName) {
        commit("SET_ACTIVE_CARD_ID", fullCardTypeName);
        dispatch('setActiveCard', fullCardTypeName);
    },
    setNewCardCurrencyId({commit}, currencyId) {
        commit('SET_NEW_CARD_CURRENCY_ID', currencyId);
    },
    openModalCardTopUp({commit}) {
        commit('SET_MODAL_CARD_TOPUP_OPENED', true);
    },
    closeModalCardTopUp({commit}) {
        commit('SET_MODAL_CARD_TOPUP_OPENED', false);
    },
    topUp({state, dispatch, rootState}) {
        if (state.activeCard.provider === 'native') {
            dispatch('kyc/__middlewareKYCRedirect', { url: '/wallet/send-money', data: {
                permission: rootState.kyc.KYCPermissionMoneyIn,
                level: rootState.kyc.KYCLevel2,
            }}, { root: true });
        } else {
            dispatch('openModalCardTopUp');
        }
    },
    generateCards({state, commit, rootState}, isOnlyEquals = false) {
        //TODO to check if isOnlyEquals is needed here
        if (isOnlyEquals) {
            cardObjects[type] = generateCard('equals', {
                status: 'inactive',
                provider: 'native',
            }, getCurrency('EUR'), isOnlyEquals);
        }

        const cardObjects = {};
        state.storedCardRequests.forEach(card => {
            if (card.is_executed || !card.request_data || !card.request_data.card_type || !card.request_data.currency_id) {
                return;
            }
            const type = `${card.request_data.card_type}-stored`;
            const typeWithCurrency = `${type}-${card.request_data.currency_id}`;
            cardObjects[typeWithCurrency] = generateCard(type, {
                isVirtual: card.request_data.card_type === 'virtual',
                provider: 'unlimint',
                typeWithCurrency,
                status: 'stored',
            }, null, false, card);
        });
        state.unlimintCardRequests.forEach(card => {
            if (!card.currency_id || !card.currency_code) {
                return;
            }
            if (state.unlimintCards.find(anyCard => anyCard.api_type === 'unlimint' && anyCard.currency_id === card.currency_id && !card.blocked)) {
                return;
            }
            const type = card.is_virtual ? 'virtual' : 'physical';
            const typeWithCurrency = `${type}-${card.currency_code}`;
            cardObjects[typeWithCurrency] = generateCard(type, {
                typeWithCurrency,
                status: getUnlimintCardRequestStatus(card.status),
                isVirtual: card.is_virtual,
                provider: 'unlimint',
            }, getCurrency(card.currency_code), false, card);
        });
        state.nativeCardRequests.forEach(card => {
            if (!card.currency_id || !card.currency_code || card.status === 'declined') {
                return;
            }
            if (state.unlimintCards.find(anyCard => anyCard.api_type === 'native' && anyCard.currency_id === card.currency_id && !card.blocked)) {
                return;
            }
            const type = `native-${card.is_virtual ? 'virtual' : 'physical'}`;
            const typeWithCurrency = `${type}-${card.currency_code}`;
            cardObjects[typeWithCurrency] = generateCard(type, {
                typeWithCurrency,
                status: getNativeCardRequestStatus(card),
                isVirtual: card.is_virtual,
                provider: 'native',
            }, getCurrency(card.currency_code), false, card);
        });
        state.unlimintCards.forEach(card => {
            if (!card.currency_code) {
                return;
            }
            let type, status, provider;
            let isVirtual = false;
            if (card.api_type === 'unlimint') {
                if (state.unlimintCardRequests.find(unlCardRequest => unlCardRequest.currency_id === card.currency_id && card.blocked)) {
                    return;
                }
                if (card.status_id !== 5) {
                    const sameTypeAndCurrencyCards = state.unlimintCards.filter(anyCard => anyCard.currency_id === card.currency_id
                        && anyCard.api_type === 'unlimint'
                        && anyCard.virtual === card.virtual
                        && anyCard.id !== card.id
                    );
                    if (sameTypeAndCurrencyCards.length > 0) {
                        for (const sameCard of sameTypeAndCurrencyCards) {
                            if (sameCard.status_id === 5) {
                                return;
                            }
                        }
                    }
                }
                status = getUnlimintCardStatus(card);
                type = card.virtual ? 'virtual' : 'physical';
                provider = 'unlimint';
                isVirtual = card.virtual;
            } else if (card.api_type === 'native') {
                if (state.nativeCardRequests.find(equalsCardRequest => equalsCardRequest.currency_id === card.currency_id && card.blocked)) {
                    return;
                }
                card.status = 'active';
                provider = 'native';
                type = card.status == 'pending' ? "equals" : "equalsActive";
            } else {
                return;
            }
            const typeWithCurrency = `${type}-${card.currency_code}`;
            cardObjects[typeWithCurrency] = generateCard(type, {
                typeWithCurrency,
                status,
                isVirtual,
                provider
            }, getCurrency(card.currency_code), false, card);
        });
        commit("SET_CARDS", cardObjects);
    },

    async createEqualsCard({state, dispatch, rootState}) {
        state.cardModel.setAllCardsParam();
        await state.cardModel
            .create(rootState.basePath + 'settings/billing/card-create')
            .then(response => {
                dispatch('setUnlimintCards', response.data.all_cards);
                dispatch('setNativeCards', response.data.native_cards);
                dispatch('generateCards');
            })
            .catch(error => {
                console.log(error.response?.data?.message ? error.response.data.message : error.message);
                throw error;
            });
    },
    createEqualsCardModel({state, dispatch}, currencyId) {
        dispatch('createEmptyEqualsCardModel', { currency_id: currencyId ?? state.newCardCurrencyId });
    },
    createEmptyEqualsCardModel({commit}, card) {
        const cardModel = new Card(card || {});
        cardModel.values.type = 'native';
        commit('SET_CARD_MODEL', cardModel);
    },
    async createVirtualUnlCard({dispatch}, currencyId) {
        return await dispatch('cardService/create', {
            service: 'unlimint',
            card_type: 'virtual',
            currency_id: currencyId,
        }, { root: true }).then(async function() {
            Promise.all([
                dispatch('cardService/getStoredRequests', 'unlimint', { root: true }),
                dispatch('cardService/getCardRequests', 'unlimint', { root: true }),
            ]).then(() => dispatch('generateCards'));
        })
        .catch(error => {
            console.log(error.response.data.message);
            throw error;
        });
    },
    async markMultipleTransactionsForActiveCard({state, dispatch}, payload) {
        const data = new FormData();
        data.append('share_type', 'only_me');
        data.append('type', 'business');
        payload.proofList.forEach(file => {
            data.append('upload_files[]', file);
        });
        payload.transactions.forEach((item, index) => {
            Object.keys(item).forEach(key => {
                data.append(`items[${index}][${key}]`, key === 'category' ? payload.expenseCategoryType : item[key]);
            });
            data.append(`items[${index}][transaction_object]`, JSON.stringify(item));
        });

        return await dispatch('cardService/addExpenseRequestToMultipleTransactions', {
            service: state.activeCard.provider,
            id: state.activeCard.id,
            data: data,
        }, { root:true });
    },
    async createOrUpdateTransactionForActiveCard({state, dispatch}, {
        transaction,
        selectedExpenseType,
        deletedFiles,
        addedFiles,
        businessExpenseData,
    }) {
        const data = new FormData();
        const transactionId = state.activeCard.provider === 'unlimint' ? transaction.id : transaction?.card_transaction_id;
        data.append('expenseType', selectedExpenseType);
        data.append('card_transaction_id', transactionId);
        data.append('transaction_object', JSON.stringify(transaction));
        if (selectedExpenseType === 'personal') {
            deletedFiles.push(
                ...(transaction.expenses?.documents || [])
                    .filter(file => file.document)
                    .map(file => file.document.id)
            );
        }
        if (transaction.expenses) {
            deletedFiles.forEach(fileId => {
                data.append('deleted_files[]', fileId)
            });
        }
        addedFiles.forEach(file => {
            data.append('upload_files[]', file);
        });
        Object.keys(businessExpenseData).forEach(key => {
            if (key !== 'proofList') {
                data.append(key, businessExpenseData[key]);
            }
        });
        data.append('id', transaction.expenses?.id);
        data.append('type', businessExpenseData.type.toLowerCase());
        data.append('share_type', 'only_me');

        return await dispatch(`cardService/${transaction.expenses ? 'updateTransaction' : 'createTransaction'}`, {
            service: state.activeCard.provider,
            id: state.activeCard.id,
            card_transaction_id: transactionId,
            data: data,
        }, { root:true });
    },
    addOrUpdateUnlCard({state, dispatch}, card) {
        const index = state.unlimintCards.findIndex(c => c.id === card.id);
        if (index) {
            state.unlimintCards.splice(index, 1, card);
        } else {
            state.unlimintCards.push(card);
        }
        dispatch('generateCards');
    },
    unsetActiveCard({commit}) {
        commit('UNSET_ACTIVE_CARD');
    },
};

const getters = {
    activeCard: (state) => state.activeCard,
    activeCardId: (state) => state.activeCardId,
    cardTransactions: (state) => {
        if (!state.activeCard || !state.activeCard.id) {
            return [];
        }
        return state.activeCard.cardTransactions;
    },
    newCardCurrencyId: (state) => state.newCardCurrencyId,
    cardModel: (state) => state.cardModel,
    requestedNativeCardCurrencyIds: (state) => {
        if (!state.nativeCardRequests) {
            return [];
        }
        return state.nativeCardRequests.filter(cardRequest => cardRequest.status !== 'declined')
            .map(cardRequest => cardRequest.currency_id);
    },
    nativeCardCurrencyIds: (state) => {
        if (!state.nativeCards) {
            return [];
        }
        return state.nativeCards.map(card => card.currency_id);
    },
    equalsCardCurrencies(state, getters) {
        if (!getters.requestedNativeCardCurrencyIds || !getters.nativeCardCurrencyIds || !state.availableCurrencies) {
            return [];
        }
        return state.availableCurrencies.filter(currency => ['EUR', 'USD', 'GBP'].includes(currency.code)
            && !getters.requestedNativeCardCurrencyIds.includes(currency.id)
            && !getters.nativeCardCurrencyIds.includes(currency.id)
        );
    },
    unlPhysicalCard: (state) => {
        const key = Object.keys(state.cards).find(key => key.startsWith('physical'));
        return key ? state.cards[key] : null;
    },
    unlVirtualCard: (state) => {
        const key = Object.keys(state.cards).find(key => key.startsWith('virtual'));
        return key ? state.cards[key] : null;
    },
    equalsBrochureLink: (state, getters, rootState) => {
        return rootState.staticStorageUrl + '/pdf/Equals Brochure.pdf';
    },
    unlimintBrochureLink: (state, getters, rootState) => {
        return rootState.staticStorageUrl + '/pdf/All you need to know about Unlimit BaaS1.pdf';
    },
    equalsMinDate: (state) => {
        if(state.activeCard && state.activeCard.created_at) {
            const newDate = new Date(state.activeCard.created_at);

            return {
                year: newDate.getFullYear(),
                formatedCreatedAt: newDate,
            };
        }

        return null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
