import Model from '~/shared/Model'

export default class WalletCurrencyDocVerified extends  Model{

    constructor(obj = {}) {
        super();

        this.hidden = ['id'];

        this.oldDocuments = {};
        this.employmentStatusTypes = [
            {name: 'Full-time', value: 'full_time'},
            {name: 'Part-time', value: 'part_time'},
            {name: 'Unemployed', value: 'unemployed'},
            {name: "Other", value: 'other'},
        ];
        this.values = {
            id: obj.id || '',
            currency_id: obj.currency_id || 0 ,
            employment_status: obj.employment_status || '',
            employment_status_other: obj.employment_status_other || '',
            bank_number: obj.bank_number || '',
            draft: obj.draft ? 1 : 0,
            removedFiles: []
        };
        if(obj.employment_status) {
            if(this.employmentStatusTypes.find(i => i.name === obj.employment_status)) {
                this.values.employment_status_other = '';
                this.values.employment_status = obj.employment_status;
            } else {
                this.values.employment_status_other =  obj.employment_status;
                this.values.employment_status = 'other';
            }
        }



        if(!_.isEmpty(obj.documents)) {
            this.oldDocuments = obj.documents;
            let docNames = {};
            Object.keys(this.oldDocuments).forEach(name =>  {
                docNames[name] = this.oldDocuments[name]['name'];
            });
            this.values = {...this.values, ...docNames};
        }

        this.rules = {
            employment_status: 'required',
            bank_number: 'required',
        };

    }
}
