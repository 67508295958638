import axios from "axios";

const state = {
    accountsConnectedInited: false,
    accountsConnectedLoading: false,
    accountsConnected: [],
};

const getters = {
    enabled: (state, getter, rootState) => rootState.payoneerParams.enabled,
    enabled_withdraw: (state, getter, rootState) => rootState.payoneerParams.enabled_withdraw,
    withdraw_testing: (state, getter, rootState) => rootState.payoneerParams.withdraw_testing,
    programs: (state, getter, rootState) => rootState.payoneerParams.programs,
    programCurrency: (state, getter, rootState) => (currency) => {
        return getter.programs ? getter.programs.find(i=> i.currency === currency) : null;
    },

    dailyUsedTopupCurrency: (state, getter, rootState) => (currency) => {
        return parseFloat(rootState.payoneerParams.daily_used_topups[currency]?.total_amount || 0);
    },
    todayTransactions: (state, getter, rootState) => (currency) => {
        return parseFloat(rootState.payoneerParams.daily_used_topups[currency]?.col_transactions || 0);
    },
    dailyLimitTopupCurrency: (state, getter, rootState) => (currency) => {
        const programCurrency = getter.programCurrency(currency) || {};
        return parseFloat(programCurrency.maxDailyTopup) || null;
    },
    maxDailyTransactions: (state, getter, rootState) => (currency) => {
        const programCurrency = getter.programCurrency(currency) || {};
        return parseFloat(programCurrency.maxDailyTransactions) || null;
    },
    monthlyUsedTopupCurrency: (state, getter, rootState) => (currency) => {
        return parseFloat(rootState.payoneerParams.monthly_used_topups[currency]?.total_amount || 0);
    },
    tomonthTransactions: (state, getter, rootState) => (currency) => {
        return parseFloat(rootState.payoneerParams.monthly_used_topups[currency]?.col_transactions || 0);
    },
    monthlyLimitTopupCurrency: (state, getter, rootState) => (currency) => {
        const programCurrency = getter.programCurrency(currency) || {};
        return parseFloat(programCurrency.maxMonthlyTopup) || null;
    },
    maxMonthlyTransactions: (state, getter, rootState) => (currency) => {
        const programCurrency = getter.programCurrency(currency) || {};
        return parseFloat(programCurrency.maxMonthlyTransactions) || null;
    },
    minLimitTopupCurrency: (state, getter, rootState) => (currency) => {
        const programCurrency = getter.programCurrency(currency) || {};
        return parseFloat(programCurrency.minTopup || 0);
    },

    currencies: (state, getter, rootState) => getter.programs? getter.programs.map(i=> i.currency): [],

    accountsConnectedInited: (state, getter, rootState) => state.accountsConnectedInited,
    accountsConnected: (state, getter, rootState) => state.accountsConnected,
    accountsConnectedInitedAndLoaded: (state, getter, rootState) => state.accountsConnectedInited && !state.accountsConnectedLoading,

    accountsConnectedActiveReady: (state, getter, rootState) => state.accountsConnected.filter(i=>getter.isAccountReadyToken(i)),
    accountConnectedActiveReadyPrograms: (state, getter, rootState) => (tokenModel) => {
        const readyToken = getter.accountsConnectedActiveReady.find(i=>i.token_model.id==tokenModel.id);
        if(readyToken){
            const programs = readyToken.programs;
            if(programs){
                return programs.filter(i=>i.program.status.type==1);
            }
        }
        return [];
    },
    balancesReadyToken: (state, getter, rootState) => {
        let balances = [];
        getter.accountsConnectedActiveReady.forEach(element => {
            if(element.balances){
                balances = balances.concat(element.balances);
            }
        });
        return balances;
    },
    findAccountByBalanceId: (state, getter, rootState) => (balanceId) => getter.accountsConnected.find(el=>(el.balances || []).find(i=>i.id==balanceId)),

    isAccountActive: (state, getter, rootState) => (item) => {
        return item.programs.find(i=>i.program.status.type !== 1) ? false : true;
    },

    isAccountReadyToken: (state, getter, rootState) => (item) => {
        return getter.isAccountActive(item) && item.balances;
    },

    needUpdateToken: (state, getter, rootState) => (item) => {
        return getter.isAccountActive(item) && !item.balances;
    },

    getBalanceFlagUrl: (state, getter, rootState) => (payoneerBalance) => {
        return getter.getCurrencyFlag(payoneerBalance.currency);
    },

    getCurrencyFlag: (state, getter, rootState) => (currency) => {
        const balance = rootState.allPossibleCurrencies.find(i => i.code == currency);
        if(balance){
            return balance.flag;
        }

        return "#";
    },
    alertMessage: (state, getter, rootState) => rootState.payoneerParams.alert_message,
}

const mutations = {
    accountsConnectedInitedSet(state){
        state.accountsConnectedInited = true;
    },

    accountsConnectedLoading(state, loading){
        state.accountsConnectedLoading = loading;
    },
    accountsConnected(state, payload){
        state.accountsConnected = payload.accountsConnected;
    },

    clearFlashError(rootState){
        rootState.alertMessage = null;
    },
}

const actions = {
    showFlashError({getters, commit}){
        if(getters.alertMessage){
            commit("setAlert", {type: getters.alertMessage[0].type, message: getters.alertMessage[0].message}, { root: true });
            commit('clearFlashError');
        }
    },

    async initAll({getters, commit}){
        if(getters.enabled){
            commit('accountsConnectedLoading',true);
            commit('accountsConnectedInitedSet');
            try{
                const response = await axios.get('/payoneer/init-all');
                commit('accountsConnected', {
                    accountsConnected: response.data,
                });
                commit('accountsConnectedLoading',false);
            }catch(error){
                commit('accountsConnectedLoading',false);
                console.error(error.message);
                throw error;
            }
        }else{
            commit('accountsConnectedInitedSet');
        }
    },

    async createPaymentAddMoney({getters, commit}, payload){
        try{
            const response = await axios.post(`/payoneer/create-payment/${payload.token_model_id}/${payload.balance_id}`, payload);
            return response.data;
        }catch(error){
            console.error(error.response.data);
            throw(error.response.data);
        }
    },

    async payneerRedirect({getters, commit}, payload){
        try{
            const response = await axios.get(payload.url);
            if(payload.blank){
                window.open(response.data.url);
            }else{
                window.location.href = response.data.url;
            }
        }catch(error){
            console.error(error.response.data);
            commit("setAlert", {type: 'error', message: error.response.data.message}, { root: true });
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
