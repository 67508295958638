import Middleware from "~/routes/middleware/Middleware";
import store from "~/store";

export default class verifyEmail extends Middleware{
    constructor(...args) {
        super(...args);
    }

    async resolve() {
        const token = this.to.query.token;
        const valid = await store.dispatch('auth/__verifyEmail', {token});

        if (!valid){
            return this.responseRedirect('404');
        }
    }
}
