const loginView = () => import('~/modules/auth/views/Login.vue');
const registerView = () => import('~/modules/auth/views/Register.vue');
const forgotPasswordView = () => import('~/modules/auth/views/ForgotPassword.vue');
const resetPasswordView = () => import('~/modules/auth/views/ResetPassword.vue');
const createPassword = () => import('~/modules/auth/views/CreatePassword.vue');
const twoFactorView = () => import('~/modules/auth/views/TwoFactorAuth.vue');

export default [
    {
        path: '/login',
        name: 'Login',
        auth: false,
        component: loginView,
        meta: {
            title: 'Login | Native Teams',
            app: 'auth',
            middleware: ['guest', 'tracking']
        },
    },
    {
        path: '/register',
        name: 'Register',
        auth: false,
        component: registerView,
        meta: {
            title: 'Register | Native Teams',
            app: 'wizard',
            middleware: ['guest', 'tracking']
        },
    },
    {
        path: '/password/reset/:token',
        name: 'password.reset.token',
        component: resetPasswordView,
        meta: {
            title: 'Reset Password | Native Teams',
            app: 'auth',
            middleware: ['guest', 'checkResetPasswordToken']
        },
    },
    {
        path: '/password/reset',
        name: 'password.reset',
        component: forgotPasswordView,
        meta: {
            title: 'Forgot Password | Native Teams',
            app: 'auth',
            middleware: 'guest'
        },
    },
    {
        path: '/invite',
        name: 'invite',
        component: createPassword,
        meta: {
            title: 'Create Password | Native Teams',
            app: 'auth',
            middleware: ['guest', 'tracking', 'checkInviteToken']
        },
    },
    {
        path: '/auth/two-factor',
        name: 'twoFactor',
        component: twoFactorView,
        meta: {
            title: 'Two-Factor Authentication | Native Teams',
            app: 'auth',
            middleware: 'tfaGuest'
        },
    },
];
