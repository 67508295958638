 <template>
    <div style="position: relative">
        <div
            v-if="fab"
            class="tm-icon-fab-box"
            :class="colorFromClass? color: null"
            :style="{
                background: !colorFromClass? fabColor || color: null,
                height: getSize,
                width: getSize,
                opacity: fabColor? 1: null
            }"
        ></div>
        <div
            :class="[fab? 'tm-icon-fab': null, !isSvg? name: null , colorFromClass? color: null]"
            :style="{
                color: !colorFromClass? color: null,
                'font-size': getFontSize,
                'transform': rotate? `rotate(${rotateRound}deg)`: 'rotate(0deg)'
            }"
            style="display: flex; transition: .3s"
        >
            <tm-svg v-if="isSvg" :height="height" :width="width" :color="color" :name="name" :size="size"></tm-svg>
        </div>
    </div>

</template>

<script>
import TmSvg from "./TmSvg";
export default {
    components: {TmSvg},
    props: {
        name: {
            default: null
        },
        fab: {
            default: false
        },
        fabSpace: {
            default: 25
        },
        fabColor: {
            default: null
        },
        color: {
            default: '#838a90'
        },
        size: {
            default: 18
        },
        height: {
            default: null
        },
        width: {
            default: null
        },
        rotate: {
            default: false
        },
        rotateRound: {
            default: 180
        },
        rem: {
            default: false
        }
    },
    computed: {
        isSvg(){
            return this.name && this.name.search('svg-') === 0;
        },
        colorFromClass(){
            return this.color && ~this.color.search('color-')
        },
        getSize(){
            return this.size && !Number.isInteger(this.size) && ~this.size.search('rem')? this.size: Number(this.size)+Number(this.fabSpace)+'px'
        },
        getFontSize(){
            return this.rem? this.size+'rem': this.size && !Number.isInteger(this.size) && ~this.size.search('rem')? this.size: ((this.size || 16) /16)+'rem'
        }
    }
}
</script>
<style scoped>
.tm-icon-fab-box{
    opacity: 0.1;
    position: relative;
    border-radius: 50%;
}
.tm-icon-fab{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)!important;
}
</style>
