<!-- @format -->

<template>
    <div class="d-flex flex-column" :class="bottomOffset ? 'mb-1-rem' : 'mb-0'">
        <label :for="id" class="d-flex" :class="[labelClass, 'align-' + align]">
            <template v-if="val === itemValue">
                <svg
                    v-if="$store.state.window.width < 1200"
                    :class="radioClass"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z"
                        fill="white"
                        stroke="#5152FB"
                    />
                    <path
                        d="M5 7.33333C6.28866 7.33333 7.33333 6.28866 7.33333 5C7.33333 3.71134 6.28866 2.66667 5 2.66667C3.71134 2.66667 2.66667 3.71134 2.66667 5C2.66667 6.28866 3.71134 7.33333 5 7.33333Z"
                        fill="#5152FB"
                    />
                </svg>
                <svg
                    v-else
                    :class="radioClass"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.5 14.5C11.366 14.5 14.5 11.366 14.5 7.5C14.5 3.63401 11.366 0.5 7.5 0.5C3.63401 0.5 0.5 3.63401 0.5 7.5C0.5 11.366 3.63401 14.5 7.5 14.5Z"
                        fill="white"
                        stroke="#5152FB"
                    />
                    <path
                        d="M7.5 11C9.433 11 11 9.433 11 7.5C11 5.567 9.433 4 7.5 4C5.567 4 4 5.567 4 7.5C4 9.433 5.567 11 7.5 11Z"
                        fill="#5152FB"
                    />
                </svg>
            </template>
            <template v-else>
                <svg
                    v-if="$store.state.window.width < 1200"
                    :class="radioClass"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z"
                        fill="white"
                        stroke="#C0C0C0"
                    />
                </svg>
                <svg
                    v-else
                    :class="radioClass"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.5 14.5C11.366 14.5 14.5 11.366 14.5 7.5C14.5 3.63401 11.366 0.5 7.5 0.5C3.63401 0.5 0.5 3.63401 0.5 7.5C0.5 11.366 3.63401 14.5 7.5 14.5Z"
                        fill="white"
                        stroke="#C0C0C0"
                    />
                </svg>
            </template>
            <input
                v-show="false"
                :id="id"
                v-model="val"
                :disabled="disabled"
                :value="itemValue"
                value="1"
                type="radio"
                class="tm-input mr-2"
            />
            <template v-if="$slots.label">
                <slot name="label"></slot>
            </template>
            <span v-else class="tm-small-text tm-color-black">{{ label }}</span>
        </label>
        <error-message :messages="errorMessages"></error-message>
    </div>
</template>
<script>
import ErrorMessage from './ErrorMessage';
export default {
    name: 'TmRadio',
    components: { ErrorMessage },
    props: {
        id: {
            default: null,
        },
        bottomOffset: {
            default: true,
        },
        label: {
            default: null,
        },
        value: {
            default: null,
        },
        labelClass: {
            default: null,
        },
        radioClass: {
            default: 'mr-2',
        },
        itemValue: {
            default: 0,
        },
        align: {
            default: 'center',
        },
        errorMessages: {
            type: Array,
            default() {
                return [];
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            val: this.value,
        };
    },
    watch: {
        val() {
            this.$emit('input', this.val);
        },
        value() {
            this.val = this.value;
        },
    },
};
</script>
