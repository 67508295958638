<template>
  <div class="wizard-stepper-container">
    <div
      v-for="(step, index) in steps"
      :key="index"
      class="wizard-stepper-item"
      :class="{ active: index === activeStep, completed: index < activeStep }"
    >
      <div class="wizard-stepper-item-circle">
        <span v-if="activeStep <= index">{{ index + 1 }}</span>
        <BaseIcon name="check-extra-thin" v-else />
      </div>
      <p class="wizard-stepper-item-title">{{ step.title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "WlBaseWizardStepper",

  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    activeStep: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.wizard-stepper-container {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .wizard-stepper-item {
    display: flex;
    align-items: center;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: calc(16px - 0.5px);
      width: 1px;
      background: #ffffff;
      height: 32px;
      gap: 24px;
      top: 100%;
    }

    &:not(:last-child)::after {
      display: block;
    }

    &:last-child::after {
      display: none;
    }

    .wizard-stepper-item-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 1px solid white;
      color: white;
      position: relative;
      margin-right: 24px;
      font-family: "Roboto", sans-serif;

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
      }
    }

    .wizard-stepper-item-title {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      color: #cdcfd2;
    }

    &.active {
      .wizard-stepper-item-title {
        color: white;
      }

      .wizard-stepper-item-circle {
        border: 1px dashed white;
      }
    }

    &.completed {
      .wizard-stepper-item-title {
        color: #fdfdfe;
      }

      .wizard-stepper-item-circle {
        background: white;

        i {
          color: #2f2f9c;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .wizard-stepper-container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    .wizard-stepper-item {
      &::after {
        background: #cdcfd2;
        left: 100%;
        top: calc(50% - 0.5px);
        height: 1px;
        width: 24px;
      }

      .wizard-stepper-item-circle {
        margin-right: 0;
        border: 1px solid #cdcfd2;
        background: #fff;
        color: #cdcfd2;
      }

      &.active {
        .wizard-stepper-item-circle {
          color: var(--main-purple-color);
          border: 1px dashed var(--main-purple-color);
        }
      }

      &.completed {
        .wizard-stepper-item-circle {
          background: var(--main-purple-color);
          color: var(--main-purple-color);
          border: 1px solid var(--main-purple-color);

          i {
            color: white;
          }
        }
      }

      .wizard-stepper-item-title {
        display: none;
      }
    }
  }
}
</style>
