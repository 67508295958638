<!-- @format -->

<template>
    <v-menu ref="menu" v-model="val" v-bind="{ ...$props, ...$attrs }">
        <template #activator="{ on, attrs }">
            <slot v-if="$scopedSlots['activator']" name="activator" :on="on" :attrs="attrs" />
            <BaseButton class="btn-icon" v-bind="attrs" v-on="on" v-else>
                <BaseIcon :class="classes" name="vertical-dots" color="#838a90" />
            </BaseButton>
        </template>
        <slot></slot>
    </v-menu>
</template>

<script>
import { commonHelpersMixin } from '~/shared/mixins/commonHelpersMixin';
import Form from '~/shared/mixins/Form';
import Helpers from '~/shared/mixins/Helpers';
import menuMixin from '~/shared/mixins/menuMixin';

export default {
    name: 'BaseMenu',

    mixins: [Form, Helpers, commonHelpersMixin, menuMixin],

    inheritAttrs: false,

    props: {
        // VUETIFY PROPS

        attach: {
            default: false,
        },
        eager: {
            type: Boolean,
            default: false,
        },
        offsetY: {
            type: Boolean,
            default: true,
        },
        offsetOverflow: {
            type: Boolean,
            default: true,
        },
        left: {
            type: Boolean,
            default: true,
        },
        value: {
            type: Boolean,
            default: false,
        },

        // CUSTOM PROPS

        loading: {
            type: Boolean,
            default: false,
        },

        classNames: {
            default: "",
        },
    },

    data() {
        return {
            val: this.value,
        };
    },

    computed: {
        classes() {
            return [this.classNames, "position-relative"].join(" ");
        },
    },
    watch: {
        value(val) {
            this.val = val;
        },
        val(value) {
            this.$emit('input', value);
        },
        loading() {
            this.$refs?.menu?.callActivate();
        },
    },
};
</script>
