<template>
    <div class="module-actions-container">
        <div v-if="isMobile" class="btn-back-wrapper" @click="$emit('back')">
            <v-btn icon small :ripple="false" class="btn-back">
                <BaseIcon name="chevron-left-2"/>
            </v-btn>
            <p class="back-text">Native Pay</p>
        </div>

        <!-- QUICK ACTIONS -->
        <QuickActionsSection
            :quick-actions="getQuickActions('nativePay', worker.plan.value)"
        />

        <!-- FEATURES -->
        <FeaturesSection :showUpgradeBtn="showUpgradeBtn" :availableMenu="availableMenu" :worker="worker" :features="features.pay"/>
    </div>
</template>

<script>
import FeaturesSection from "~/components/NewNavigation/FeaturesSection.vue";
import QuickActionsSection from "~/components/NewNavigation/QuickActionsSection.vue";

import {mapGetters} from "vuex";
import {commonHelpersMixin} from "~/shared/mixins/commonHelpersMixin";

export default {
    name: "NativePay",

    mixins: [commonHelpersMixin],

    components: {QuickActionsSection, FeaturesSection},
    props: {
        worker: {
            default: null,
        },
        features: {
            default: null,
        },
        availableMenu: {
            default: null,
        },
    },

    data() {
        return {};
    },

    computed: {
        ...mapGetters({
            getQuickActions: "navigation/getQuickActions"
        }),
		currentWorker(){
			return this.$store.state.worker;
		},
        showUpgradeBtn() {
            const upgradePlans = [
                'free', 'free_nigeria','free_payments'
            ];
            return upgradePlans.includes(this.currentWorker.plan.value);
        },
    },
    methods: {},
};
</script>

<style scoped>
.module-actions-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;

    .actions-container {
        padding: 1.5rem;
        border-radius: 22px;
        background: #f2f5fa;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        .title-text {
            color: #5a5a77;
            line-height: 1.4375rem;
            margin-bottom: 1.75rem;
        }

    }

    .btn-back-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 1rem;
    }

    .btn-back {
        color: #27276c;

        &
        :hover::before {
            opacity: 0;
        }

        margin-right: 0.75rem;

        i {
            font-size: 1.25rem;
        }

    }

    .back-text {
        font-size: 1.125rem;
        font-weight: 700;
        color: #27276c;
    }

}
</style>
