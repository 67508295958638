<template>
    <div class="d-flex flex-column" style="position: relative" :class="bottomOffset? 'mb-4': ''">
        <label
            v-if="$slots.label || label"
            :for="id"
            class="d-flex  font-weight-medium black--text align-items-center"
            :class="[labelClassVal, labelBottomOffset? 'mb-1': '']"
        >
            <slot v-if="$slots.label" name="label"></slot>
            <template v-else>
                <icon v-if="labelIcon" :name="labelIcon" size="13" style="margin-right: 3px"></icon>
                {{label}}
            </template>
        </label>
        <textarea
            :disabled="disabled"
            @focus="$emit('focus')"
            @focusout="$emit('focusout')"
            :style="[addStyles, activated && !active? {'min-height': '2.5rem', 'max-height': '2.5rem'}: null]"
            :id="id"
            :rows="rows"
            v-model="val"
            :placeholder="__inputPlaceholder"
            class="tm-textarea"
            :class="[contentClass, errorMessages.length? 'tm-err': null]"
        ></textarea>
        <error-message :messages="errorMessages"></error-message>
    </div>
</template>

<script>
import ErrorMessage from "./ErrorMessage";
import Form from "../../mixins/Form";
export default {
    name: 'TmTextArea',
    mixins: [Form],
    components: {ErrorMessage},
    props: {
        id: {
            default: null
        },
        bottomOffset: {
            default: true
        },
        labelBottomOffset: {
            default: true
        },
        labelClassVal: {
            default: 'tm-small-text'
        },
        disabled: {
            default: false
        },
        placeholder: {
            default: 'Type here'
        },
        contentClass: {
            default: null
        },
        label: {
            default: null
        },
        labelIcon: {
            default: null
        },
        value: {
            default: null
        },
        addStyles: {
            type: Object,
            default() {
                return {}
            }
        },
        rows: {
            default: 4
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            }
        },
        activated: {
            default: false
        },
        active: {
            default: false
        },
    },
    data(){
        return {
            val: this.value,
        }
    },
    watch: {
        val(){
            this.$emit('input', this.val)
        },
        value(){
            this.val = this.value;
        },
    }
}
</script>
