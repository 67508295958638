import axios from "axios";

const state = {
    isLoadingConcents: false,
    isLoadedConcents: false,
    concents: [],
};

const getters = {
    enabled: (state, getter, rootState) => rootState.tellmoneyParams.enabled,
    showSettingsRevokeTab: (state, getter, rootState) => rootState.tellmoneyParams.show_settings_revoke_tab,
    isLoadingConcents: (state, getter, rootState) => state.isLoadingConcents,
    concents: (state, getter, rootState) => state.concents,
}

const mutations = {
    isLoadingConcents(state, status){
        state.isLoadingConcents = status;
    },

    setConcents(state, data){
        state.isLoadedConcents = true;
        state.concents = data;
    },

    eraseConcents(state){
        state.isLoadingConcents = false;
        state.isLoadedConcents = false;
        state.concents = [];
    },
}

const actions = {
    async init({getters, commit}){
        if(getters.enabled){
            commit('eraseConcents');
            commit('isLoadingConcents',true);
            try{
                const response = await axios.get('/tellmoney-auth/consents-list');
                commit('isLoadingConcents',false);
                commit('setConcents', response.data.data);
            }catch(error){
                commit('isLoadingConcents',false);
                console.error(error.message);
                throw error;
            }
        }else{
            commit('isLoadingConcents',false);
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
