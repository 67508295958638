<template>
    <modal
        v-if="dialog"
        v-model="dialog"
        :width="thanksNotify || pendingStatus ? 400 : 620"
        class-names=" modal-required-document"
        :padding="false"
        :close-icon-show="!pendingStatus"
    >
        <template slot="content">
          <div
              v-if="pendingStatus"
              class="modal-content pt-0 pb-0">
            <div class="modal-header border-bottom-0 pt-3 mb-0 pb-20">
              <div class="mb-10 border-bottom-0 d-flex justify-between align-center">
                <div class="status yellow-status">
                  <p class="color-yellow tiny-text-2">Panding</p>
                </div>
                <button  @click="dialog = false" type="button" class="btn btn-icon btn-p-0 color-dark-grey small-text ml-0">
                  <i class="icon-times"></i>
                </button>
              </div>
              <div class="mb-15 text-center">
                <img :src="$store.state.wlstyle.st_logo_mon" alt="" class="img-fluid-fix mb-20">
                <p class="heading-1 mb-20 bold-text">
                  We’re checking your <br>
                  documents!
                </p>
                <p class="tiny-text bold-text color-red mb-10">
                  It takes 24-48 hours to verify.
                </p>
                <p class="small-text color-dark-blue">
                  We’ll let you know by Email once your ID documents are approved.
                </p>
              </div>
              <a href="/" class="btn btn-purple normal-text w-100">
                Go to Dashboard
              </a>
            </div>
          </div>

            <div v-else-if="thanksNotify"
                class="modal-header border-bottom-0 pt-3 mb-0 pb-20"
                >
              <div class="mb-15 text-center ">
                <i class="icon-check-circle-filled color-green verification-icon-size"></i>
                <p class="heading-1 mb-20 mt-20 bold-text">
                  We recieved all your documents!
                </p>
                <p class="tiny-text bold-text color-red mb-10">
                  It takes 24-48 hours to verify.
                </p>
                <p class="small-text color-dark-blue">
                  Thanks! We will notify you when your documentation is
                  verified.
                </p>
              </div>
              <a
                  :href="$store.state.path"
                  class="btn btn-purple normal-text w-100">
                Go to Dashboard
              </a>
            </div>
            <template v-else>
                <div class="modal-header border-bottom-0 pt-30 ml-3 mb-2">
                    <h5 class="modal-title heading-1 bold-text color-black">
                        Required Documents
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="h-16">
                        <p class="employee-name small-text-2 bold-text color-black ml-3 p-0">
                            You can’t withdraw without verifying the documents.
                        </p>
                    </div>
                    <div class="dropdown vat-input purple-arrow-drop ml-3 mr-3 mt-30">
                        <tm-advanced-select
                            label="Employment status*"
                            v-model="model.values.employment_status"
                            v-validate="model.rules.employment_status"
                            :error-messages="errors.collect('employment_status')"
                            :items="model.employmentStatusTypes"
                            :select-first="true"
                            name="employment_status"
                            item-value="value"
                            item-text="name"
                            data-vv-as="Employment status"
                        ></tm-advanced-select>
                    </div>
                    <div
                        v-if="model.values.employment_status === 'other'"
                        class="form-group col-12">
                        <tm-input
                            label="Other"
                            name="employment_status_other"
                            v-model="model.values.employment_status_other"
                            v-validate="model.rules.employment_status"
                            :error-messages="errors.collect('employment_status_other')"
                            data-vv-as="Employment status"
                        ></tm-input>
                    </div>
                    <div class="form-group col-12">
                        <tm-input
                            label="Bank Number*"
                            name="bank_number"
                            v-model="model.values.bank_number"
                            v-validate="model.rules.bank_number"
                            :error-messages="errors.collect('bank_number')"
                        ></tm-input>
                    </div>
                    <div
                        v-for="(item, index) in modalData"
                        :key="`modal-upload-info-${index}`"
                        class="form-group col-12"
                    >
                        <h4
                            v-if="item.label"
                            class="small-text bold-text color-black mb-7"
                        >
                            {{ item.label }}
                        </h4>
                        <p class="employee-name medium-text color-dark-grey mb-1-rem">
                            {{item.description}}
                        </p>
                        <tm-single-upload-area
                            :preview-file="model.values.id ? model.values['old_'+item.name] : null"
                            :bottom-offset="false"
                            :key="`modal-upload-file-${index}`"
                            v-model="model.values[item.name]"
                            v-validate="getValidation(item.validation, item.name)"
                            :data-vv-as="item.label || item.name"
                            :name="item.name"
                            height="4.375rem"
                            :error-messages="errors.collect(item.name)"
                            :accept="item.validation_vue"
                            :files-types="[item.validation_vue]"
                            @delete="removeFile('old_'+item.name)"
                        ></tm-single-upload-area>
                    </div>
                </div>
                <div class="modal-footer d-flex justify-end pb-30 mr-3 mt-4">
                    <button
                        @click="save(true)"
                        class="btn btn-save-draft btn-white small-text bold-text color-dark-grey btn-outline-grey mr-2">
                        Save as Draft
                    </button>
                    <btn
                        @click="save()"
                        :loading="loading"
                        class="btn btn-publish btn-purple small-text bold-text color-white"
                    >
                        <a class="color-white">Send</a>
                    </btn>
                </div>
            </template>
        </template>
    </modal>
</template>
<script>
import Helpers from "~/shared/mixins/Helpers";
import Modal from "~/shared/components/Modal";
import TmAdvancedSelect from "~/shared/components/form/TmAdvancedSelect";
import Avatar from "~/shared/components/Avatar";
import WalletCurrencyDocVerified from "../models/WalletCurrencyDocVerified";
import TmInput from "~/shared/components/form/TmInput";
import TmSingleUploadArea from "~/shared/components/form/TmSingleUploadArea";
import Btn from "~/shared/components/Btn";
import axios from "axios";

export default {
    name: 'WalletCurrencyDocVerified',
    components: {
        Btn,
        Avatar,
        Modal,
        TmAdvancedSelect,
        TmInput,
        TmSingleUploadArea
    },
    mixins: [Helpers],
    data() {
        return {
            loading: false,
            dialog: this.$store.state.walletCurrencyDocModal,
            model: new WalletCurrencyDocVerified(),
            modalData: null,
            thanksNotify: false,
            pendingStatus: false
        }
    },
    computed: {
        value() {
            return this.$store.state.walletCurrencyDocModal;
        },
        currency() {
            return (this.$store.state.selectedWallet && this.$store.state.selectedWallet.currency) ? this.$store.state.selectedWallet.currency : null;
        }
    },
    watch: {
      currency() {
        this.getData()
      },
        value() {
            this.dialog = this.value;
        },
        dialog(v) {
            this._openWalletVerifyModal(this.dialog);
            if(v) {
              this.getData()
            } else {
              this.model =  new WalletCurrencyDocVerified();
              this.modalData = null;
              this. thanksNotify =  false;
              this.pendingStatus = false;
            }
        }
    },
    methods: {
      removeFile(key) {
         if(!_.isEmpty(this.model.oldDocuments[key])) {
           this.model.values.removedFiles.push(this.model.oldDocuments[key].id);
         }
      },
        getValidation (v, key) {
           const req  = 'required|';
           const obj  = this.model.oldDocuments['old_'+key];
          if(!_.isEmpty(obj) && !this.model.values.removedFiles.includes(obj.id)) {

            v =  v.replace('|file', '');
            return v.replace(req, '');
          }
            return v.replace('|file', '');
        },
        getData() {
          if(!this.__checkWalletCurrencyDocModal(this.currency)) {
            if(this.dialog) {
              this._openWalletVerifyModal(false);
            }
            return false;
          }

            axios.get(this.$store.state.path + 'wallet-currency/getData', {
                params:{currency_id: this.currency.id}
            })
                .then(response => {
                  this.pendingStatus =  response.data.status && response.data.status === 'pending';
                    if (response.data.modalData) {
                        this.modalData = response.data.modalData;
                    }
                    if (response.data.model) {
                        this.model = new WalletCurrencyDocVerified(response.data.model)
                    }
                })
                .catch(error => {
                    this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                })
        },
        save(draft = false) {
          this.model.values.draft = draft? 1 : 0;
          if(  this.model.values.draft ) {
            this.$validator.pause();
            this.errors.clear();
            if(!this.model.values.employment_status || !this.model.values.bank_number) {
              return;
            }
          } else {
            this.$validator.resume();
          }

          this.$validator.validateAll().then(valid => {
                if (valid) {
                    this.model.values.currency_id = this.currency.id
                    this.loading = true;
                    this.model.create(this.$store.state.path + 'wallet-currency/verify')
                        .then(response => {
                            this.loading = false;
                            this.thanksNotify = !this.model.values.draft;
                          if (this.model.values.draft) {
                            this.dialog = false;
                          }
                        })
                        .catch(error => {
                            this.loading = false;
                            if (error.response.data.message) {
                                this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                            }
                        })
                }
            })
        }
    }
}
</script>
