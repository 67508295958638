
export const namespaced = true;

export const state = {
    documents: {},
    types: ['folder', 'file'],
    uploadFileDialog: false,
    folderTabs: [],
    selectedFolder: null,
    loading: false,
    updated: false,
    selectedItem: null,
    restoreLoading: false,
    path: 'documents/',
    tab_type: 'all_documents',
};

export const mutations = {
    SET_DATA(state, params) {
        state[params.key] = params.value;
    },

    setDocuments(state, documents) {
        state.documents = documents;
    },
    setFolderTabs(state, items) {
        state.folderTabs = Object.values(items);
    },
    setLoading(state, loading) {
        state.loading = loading;
    },

    setUploadFileDialog(state, value) {
        state.uploadFileDialog = value;
    },
};

export const actions = {
    setDocumentsData ({ commit }, data = []) {
        commit('setDocuments', data);
    },
    setSelectedItem ({  commit }, selectedItem = null) {
        commit('SET_DATA', { key: 'selectedItem', value: selectedItem });
    },
    setUpdated ({  commit }, updated = false) {
        commit('SET_DATA', { key: 'updated', value: updated });
    },
    setSelectedFolder ({  commit, dispatch }, id) {
        commit('SET_DATA', { key: 'selectedFolder', value: id });
        dispatch('setFolderTabs', id );
    },
    setTabType ({  commit }, value) {
        commit('SET_DATA', { key: 'tab_type', value });
    },

    setFolderTabs({ state, commit }){
        const allData = [];
        allData[0] = {
            text: '',
            disabled: false,
            value: null,
        };
        const titles = {
            employees_folders: 'Employees',
            organizations_not_access: 'Company',
        };
        if(titles[state.tab_type]) {
            allData[0].text = `${titles[state.tab_type]} ${state.selectedItem ? 'Folders' : ''}`;
            if(state.selectedItem) {
                allData.push({
                    text: state.selectedItem?.name,
                    disabled: false,
                    value: state.selectedItem?.id,
                    not_folder: 1,
                });
            }
        }

        if(state.selectedFolder) {
            axios.post(`${state.path}folder-tabs/${state.selectedFolder}`).then(({ data }) => {
                Object.keys(data).forEach(i => {
                    allData.push({
                        text: data[i],
                        disabled: false,
                        value: i,
                    });
                });
                allData[0].text = allData[0].text || 'Folders';
                allData[allData.length - 1].disabled = true;
                commit('setFolderTabs', allData);
            });
        } else {
            allData[allData.length - 1].disabled = true;
            commit('setFolderTabs', allData);
        }
    },
    setDocuments({ state, commit, dispatch }, params = {}) {
        commit('setLoading', true);
        dispatch('setSelectedFolder', params?.parentId || null);
        axios.get(`${state.path}paginate${params?.parentId ? '/' + params?.parentId : ''}`, {params})
            .then(response => {
                commit('setDocuments', response.data);
                if(params.parentValue) {
                    const items = response.data.data || [];
                    dispatch('setSelectedFolder', items[0]?.parent_id || items[0]?.folder_id || null);
                }
            })
            .catch(error => {
                commit('setAlert', { type: 'error', message: error.response.data.message }, { root: true });
            }).finally(() => {
            commit('setLoading', false);
            commit('SET_DATA', { key: 'updated', value: false });
        });
    },

    //done

    restore({ state, commit, dispatch}, itemId) {
        commit('SET_DATA', { key: 'restoreLoading', value: true});
        this.restoreLoading = true;
        axios.get(`${state.path}folder-restore/${itemId}`)
            .then(() => {
                dispatch('setDocuments');
                // this.trashSnackbar = false;
            })
            .catch(error => {
                // this.restoreLoading = false;
                commit(
                    'setAlert',
                    {type: 'error', message: error.response.data.message},
                    {root: true},
                );
            }).finally(() => {
            commit('SET_DATA', {key: 'restoreLoading', value: false});
        })
    },

    toggleUploadFileDialog({state, commit}, value = null) {
        let val = value ? value : !state.uploadFileDialog;
        commit("setUploadFileDialog", val);
    },
};

export const getters = {
    documents: state => state.documents,
    loading: state => state.loading,
    updated: state => state.updated,
    selectedItem: state => state.selectedItem,
    selectedFolder: state => state.selectedFolder,
    folderTabs: state => state.folderTabs,
    tab_type: state => state.tab_type,
    getUploadFileDialog: state => state.uploadFileDialog,
};
