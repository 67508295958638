<template>

    <v-dialog
        v-model="dialog"
        fullscreen
        persistent
        content-class="bg-grey-body"
        no-click-animation
        :transition="showModal ? 'dialog-transition' : false"
        :focus-retain="false"
    >
        <template v-if="obj">

            <employer-finish-step v-if="completed" :obj="obj" @resetFlow="handleResetFlow()"></employer-finish-step>

            <div v-else class="organization-onboarding-container mb-0 p-0">
                <div class="container-fluid h-100vh pa-0">
                    <div class="row no-gutters justify-content-center custom-scrollbar">
                        <!-- HEADER -->

                        <div v-if="isOnboading" class="col-12 add-send-money-header justify-content-between position-relative px-1-rem">
                            <div class="self-serve-contracts-wrapper d-flex align-items-center">
                                <img :src="$store.state.wlstyle.st_logo_black" alt="logo black" class="nt-logo w-156">
                                <p class="medium-text bold-text color-dark -grey ml-3" style="margin-top: 0.1875rem;">
                                    Onboarding
                                </p>
                            </div>
                            <div v-if="!__smallDesktop">
                                <ul class="wizard-steps-list self-serve onboarding">
                                    <li
                                        v-for="(item, index) in steps"
                                        :key="index"
                                        :class="item.value === step? 'active': ''"
                                        class="wizard-step mb-0"
                                    >
                                    <span class="wizard-step-number">
                                        <span class="number">{{ index + 1 }}</span>
                                        <i class="icon-check fs-7 color-white"></i>
                                    </span>
                                        <p
                                            class="medium-text bold-text text-no-wrap"
                                            :class="item.value === step? 'color-purple': 'color-light-grey-3'"
                                        >
                                            {{ item.title }}
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            v-else
                            class="col-12 add-send-money-header bg-transparent shadow-0 justify-end"
                            style="background: transparent; height: auto; padding-top: 1.5rem"
                        >
                            <button @click="closeModal" class="btn btn-close btn-big normal-text bold-text bg-transparent w-90 mt-3 mr-3">
                                 <i class="icon-times color-black" />
                            </button>
                        </div>

                        <!-- CONTENT -->

                        <!-- STEP 1 -->
                        <!--ORGANISATION DETAILS STEP-->
                        <v-fade-transition hide-on-leave>
                            <employer-details
                                v-if="step === 'details'"
                                ref="Details"
                                :obj="obj"
                            ></employer-details>
                        </v-fade-transition>

                        <!--STEP 2 -->
                        <!--ADD FIRST SEAT STEP-->
                        <v-fade-transition hide-on-leave>
                            <employer-first-seat
                                v-if="step === 'first_seat'"
                                ref="FirstSeat"
                                :obj="obj"
                                @existingEmail="checkExistingEmail"
                            ></employer-first-seat>
                        </v-fade-transition>

                        <!-- STEP 3 -->
                        <!--TERMS STEP-->
                        <v-fade-transition hide-on-leave>
                            <employer-terms
                                v-if="step === 'terms'"
                                ref="Terms"
                                :obj="obj"
                            ></employer-terms>
                        </v-fade-transition>

                        <!--SELF SERVE ACTIONS-->
                        <div
                            class="self-serve-wizard-actions onboardign justify-content-center justify-content-md-end"
                            :class="__smallDesktop? 'mt-0': ''"
                            :style="!__smallDesktop? {right: '7rem'}: {}">
                            <button
                                v-if="!isFirst"
                                @click="prev()"
                                class="btn btn-big btn-self-serve-wizard-back-step custom-btn-secondary normal-text bold-text color-purple h-48 w-110 mr-3"
                            >
                                Back
                            </button>
                            <btn
                                @click="next()"
                                :loading="loading"
                                height="3rem"
                                class="btn btn-big btn-next-step custom-btn-primary normal-text elevation-0"
                                :class="isLast? '': 'w-110'"
                                style="text-transform: unset"
                                :disabled="existingEmail"
                            >
                                <span class="normal-text color-white bold-text">{{ isLast? 'Create organisation': 'Next' }}</span>
                            </btn>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </v-dialog>
</template>
<script>
import EmployerDetails from "./steps/EmployerDetails";
import EmployerFirstSeat from "./steps/EmployerFirstSeat";
import EmployerTerms from "./steps/EmployerTerms";
import EmployerWelcome from "../../../../models/EmployerWelcome";
import Btn from "~/shared/components/Btn";
import EmployerFinishStep from "./steps/EmployerFinishStep";
import Screen from "~/shared/mixins/Screen";
export default {
    name: 'EmployerWelcome',
    components: {EmployerFinishStep, Btn, EmployerTerms, EmployerFirstSeat, EmployerDetails},
    mixins: [Screen],
    props: {
        value: {
            default: false
        },
        showModal: {
            default: true,
        },
    },
    data() {
        return {
            dialog: !!this.value,
            completed: false,
            loading: false,
            step: 'details',
            obj: null,
            displayCloseButton: false,
            existingEmail: false,
        }
    },
    computed: {
        activeStep(){
            return this.steps.find(s => s.value === this.step);
        },
        stepIndex(){
            return this.steps.findIndex(s => s.value === this.step);
        },
        isFirst(){
            return this.stepIndex === 0;
        },
        isLast(){
            return this.steps.length - 1 === this.stepIndex;
        },
        isOnboading(){
            return !!(this.$store.state.primaryWorker.first_seat_worker_id)? 1: 0;
        },
        orgName(){
            return this.$store.state.primaryWorker.trial_employment_account
                ? this.$store.state.primaryWorker.trial_employment_account.organization
                : '';
        },
        companyNumber(){
            return this.$store.state.primaryWorker.trial_employment_account
                ? this.$store.state.primaryWorker.trial_employment_account.company_number
                : '';
        },
        steps(){
            return this.isOnboading?
                [
                    {
                        title: 'Organisation details',
                        completed: false,
                        value: 'details',
                        ref: 'Details'
                    },
                    {
                        title: 'Invite team member',
                        completed: false,
                        value: 'first_seat',
                        ref: 'FirstSeat'
                    },
                    {
                        title: 'Terms of service',
                        completed: false,
                        value: 'terms',
                        ref: 'Terms'
                    }
                ]:
                [
                    {
                        title: 'Organisation details',
                        completed: false,
                        value: 'details',
                        ref: 'Details'
                    }
                ];
        },
    },
    watch: {
        value() {
            this.setValues();
            this.dialog = !!this.value;
        },
        dialog() {
            this.$emit('input', this.dialog);this.$store.dispatch('getMyOrganizations');
        }
    },
    methods: {
        closeModal($e){
            if(this.showModal)
            {
                this.$store.commit('hideOrgDialog')
            }else{
                $e.preventDefault()
                window.location.href = this.$store.state.path + 'wallet';
            }
        },


        setValues(){
            this.obj = new EmployerWelcome({
                onboarding: this.isOnboading,
                org_name: this.orgName,
                company_number: this.companyNumber
            });
        },
        next(){
            this.$store.dispatch('getMyOrganizations').then(() => {
                if (this.activeStep.ref)
                this.$refs[this.activeStep.ref].$validator.validateAll().then(valid => {
                    if (valid){
                        this.activeStep.completed = true;
                        this.isLast?
                            this.submit():
                            this.step = this.steps[this.stepIndex + 1].value;
                    }
                });
            });
        },
        prev(){
            this.step = this.steps[this.stepIndex - 1]?
                this.steps[this.stepIndex - 1].value:
                'details'
        },
        submit(){
            this.loading = true;
            this.obj.create(this.$store.state.path + 'create-organization-with-members')
                .then(response => {
                    this.completed = true;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                })
        },
        handleResetFlow() {
            this.completed = false;
            this.obj.values.organization.name = '';
            this.obj.values.organization.company_number = '';
            this.obj.values.organization.logo_file = '';
        },
        checkExistingEmail(value) {
            this.existingEmail = value;
        }
    },
    mounted() {
        this.setValues();
    }
}
</script>
