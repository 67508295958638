<template>
    <v-slide-x-transition>
        {{ messages }}
        <div
            v-if="messages.length"
            :style="left? {left: 0+'!important'}: absolute? center? {left: '50%!important', transform: 'translate(-50%, 100%)'}: {transform: 'translate(0%, 100%)'}: {}"
            class="tm-err-box"
            :class="[absolute? 'position-absolute': '', rounded? 'rounded': '']"
        >
            <span :style="messageStyle" v-if="!multipleMessages && messages.length">{{ messages[0] }}</span>
            <span :style="messageStyle" v-else v-for="message in messages" :key="message">{{ message }}</span>
        </div>
    </v-slide-x-transition>
</template>
<script>
export default {
    name: 'ErrorMessage',
    props: {
        messages: {
            default(){
                return [];
            }
        },
        left: {
            default: false
        },
        center: {
            default: false
        },
        multipleMessages: {
            default: false
        },
        messageStyle: {
            default: 'font-size: 0.75rem;'
        },
        absolute: {
            default: true
        },
        rounded: {
            default: true
        }
    }
}
</script>
