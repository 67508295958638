<template>
  <i
      :class="iconClassName"
      v-on="$listeners"
      :style="{
          'font-size': size
      }"
  />
</template>

<script>
export default {
  name: "BaseIcon",
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
        type: String,
        default: ''
    },
  },
  data() {
    return {};
  },
  computed: {
      iconClassName() {
          return this.name && this.name.startsWith('icon')? this.name : `icon-${this.name}`;
      },
  },
};
</script>
