import axios from "axios";
import {
    format,
    addDays,
    differenceInDays,
    isAfter
} from "date-fns";

const state = {
    extraCurrencies: [],
    activeCard: {},

    activeWallet: {},
    wallets: [],
    walletTransactionItems: [],
    walletTransactionPagination: null,
    loadingWalletTransactions: false,

    notAvailableWallets: [],
    disabledWalletsWithAvailable: [],
    systemWallets: [],

    walletQuery: {
        search: "",
        customDateRange: "",
        dates: [],
        statuses: [],
        types: [],
    },
    cardFilters: {
        date: "",
        statuses: [],
        types: [],
        search: "",
    },
    cardStatementFilters: {
        date: "",
        statuses: [],
        types: [],
        search: "",
        expenseType: "all",
    },
    walletOverviewFilters: {
        dateFrom: null,
        dateTo: null,
        search: "",
    },
    expenseReportsFilters: {
        year: new Date().getFullYear(),
        search: "",
    },
    rewardsFilters: {
        date: "",
        status: "",
        type: "",
        search: "",
    },
    statuses: [
        {
            name: "Completed",
        },
        {
            name: "Pending",
        },
        {
            name: "Reserved",
        },
        {
            name: "Declined",
        },
        {
            name: "Overdue",
        },
        {
            name: "Canceled",
        },
    ],
    categoryTypes: [],
    currencies: [],
    tempCurrencies: [],
    isWalletLayout: true,
    isCardLayout: false,
    isWalletStatementLayout: false,
    isCardStatementLayout: false,
    isRewardsLayout: false,
    isEntityLayout: false,
    transactionsFullScreen: false,
    rewardsStatuses: [
        {
            name: "All",
            value: 'all'
        },
        {
            name: "Locked",
            value: 'locked'
        },
        {
            name: "Available",
            value: 'available'
        },
        {
            name: "Cancelled",
            value: 'canceled'
        },
        {
            name: "Collected",
            value: 'paid'
        },
    ],
    rewardsMoney: [
        {
            name: "Pending",
        },
        {
            name: "Collected",
        },
    ],
    walletOverview: [],
    expenseReports: [],
    updatedWalletTransactions: [],

    rewardsCreated: false,
    toUpdateRewardsWallet: false,
    walletSpendOverviewQuery: {
        year: new Date().getFullYear(),
        page: 1,
    },
    monthsNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ],
    walletOverviewItems: [],
    walletMonthlySpendData: [],
    walletExpenseReportData: [],
    markedTransactionsCount: 0,
    walletSpendExpenseType: null,
    learnMoreModalShown: false,

    layouts: {
        walletTransactions: 'toggleWalletLayout',
        walletStatement: 'toggleWalletStatementLayout',
        cardTransactions: 'toggleCardLayout',
        cardStatement: 'toggleCardStatementLayout',
        rewards: 'toggleRewardsLayout',
    },
};

const mutations = {

    SET_ACTIVE_WALLET(state, wallet) {
        state.activeWallet = wallet;
    },

    SET_WALLET_TRANSACTIONS(state, payload) {
        state.walletTransactionItems = payload.data.data;
        state.walletTransactionPagination = payload.data;
    },

    RESET_WALLET_TRANSACTIONS(state) {
        state.walletTransactionItems = [];
        state.walletTransactionPagination = null;
    },

    SHOW_LEARN_MORE_MODAL(state) {
        state.learnMoreModalShown = true;
    },

    HIDE_LEARN_MORE_MODAL(state) {
        state.learnMoreModalShown = false;
    },

    loadingWalletTransactions(state, payload) {
        state.loadingWalletTransactions = payload;
    },

    walletQuery(state, payload) {
        state.walletQuery = payload;
    },

    SET_ACTIVE_CARD(state, card) {
        state.activeCard = card;
    },

    SET_CARD_FILTERS(state, filters) {
        state.cardFilters = filters;
    },

    SET_CARD_STATEMENT_FILTERS(state, filters) {
        state.cardStatementFilters = filters;
    },

    SET_WALLET_OVERVIEW_FILTERS(state, filters) {
        state.walletOverviewFilters = filters;
    },

    SET_EXPENSE_REPORTS_FILTERS(state, filters) {
        state.expenseReportsFilters = filters;
    },

    SET_REWARDS_FILTERS(state, filters) {
        state.rewardsFilters = filters;
    },

    UPDATE_AVAILABLE_CURRENCIES(state, currencies) {
        state.availableCurrencies.push([...currencies]);
    },

    UPDATE_HIDDEN_CURRENCIES(state, currencies) {
        state.hiddenCurrencies.push([...currencies]);
    },

    TOGGLE_CARD_LAYOUT(state) {
        state.isWalletLayout = false;
        state.isWalletStatementLayout = false;
        state.isCardStatementLayout = false;
        state.isRewardsLayout = false;
        state.isCardLayout = true;
    },

    TOGGLE_WALLET_LAYOUT(state) {
        state.isCardLayout = false;
        state.isCardStatementLayout = false;
        state.isWalletStatementLayout = false;
        state.isRewardsLayout = false;
        state.isWalletLayout = true;
        state.isEntityLayout = false;
    },

    TOGGLE_WALLET_STATEMENT_LAYOUT(state) {
        state.isCardLayout = false;
        state.isCardStatementLayout = false;
        state.isWalletLayout = false;
        state.isRewardsLayout = false;
        state.isWalletStatementLayout = true;
    },

    TOGGLE_CARD_STATEMENT_LAYOUT(state) {
        state.isCardLayout = false;
        state.isWalletLayout = false;
        state.isWalletStatementLayout = false;
        state.isRewardsLayout = false;
        state.isCardStatementLayout = true;
    },

    TOGGLE_REWARDS_LAYOUT(state) {
        state.isCardLayout = false;
        state.isWalletLayout = false;
        state.isWalletStatementLayout = false;
        state.isCardStatementLayout = false;
        state.isRewardsLayout = true;
    },

    TOGGLE_ENTITY_LAYOUT(state) {
        state.isCardLayout = false;
        state.isWalletLayout = true;
        state.isWalletStatementLayout = false;
        state.isCardStatementLayout = false;
        state.isRewardsLayout = false;
        state.isEntityLayout = true;
    },

    SET_TRANSACTIONS_FULL_SCREEN(state, on = true) {
        state.transactionsFullScreen = on;
    },

    REQUEST_CARDS(state) {
        state.activeWallet.cards = state.cards;
    },

    REQUEST_REWARDS(state, rewards) {
        state.activeWallet.rewards = rewards.data;
    },

    ADD_RESERVE_REWARD(state, rewardTransaction) {
        state.activeWallet.rewards.transactions.push(rewardTransaction);
        state.activeWallet.rewards.transactions.sort((a, b) => {
            return b.indexStartDate - a.indexStartDate;
        });
    },

    SET_CURRENCIES(state, currencies) {
        state.currencies = currencies;
    },

    SET_REWARDS_CREATED(state, status) {
        state.rewardsCreated = status;
    },

    UPDATE_REWARDS_WALLET(state, status) {
        state.toUpdateRewardsWallet = status;
    },

    SET_TEMP_CURRENCIES(state, currencies) {
        state.tempCurrencies = currencies;
    },

    UPDATE_REWARD_TRANSACTION(state, rewardTransaction) {
        const index = state.activeWallet.rewards.transactions.findIndex(
            (transaction) => transaction.id === rewardTransaction.id
        );
        state.activeWallet.rewards.transactions[index] = rewardTransaction;
    },

    UPDATE_WALLET_TRANSACTION(state, walletTransaction) {
        const index = state.activeWallet.transactions.findIndex(
            (transaction) => transaction.id === walletTransaction.id
        );
        state.activeWallet.transactions[index] = walletTransaction;
    },

    UPDATE_WALLET_TRANSACTIONS(state, walletTransactions) {
        state.updatedWalletTransactions = walletTransactions;
    },

    UPDATE_WALLET_TRANSACTIONS_AFTER(state) {
        state.activeWallet.transactions = state.activeWallet.transactions.map(
            (transaction) => {
                const updatedTransaction = state.updatedWalletTransactions.find(
                    (updated) => updated.id === transaction.id
                );
                if (updatedTransaction) {
                    return {...transaction, ...updatedTransaction};
                }
                return transaction;
            }
        );
    },

    RESET_UPDATED_WALLET_TRANSACTIONS(state) {
        state.updatedWalletTransactions = [];
    },
    SET_WALLET_SPEND_CATEGORIES(state, payload) {
        state.categoryTypes = payload.data;
    },
    SET_WALLET_OVERVIEW_DATA(state, payload) {
        state.walletOverviewItems = payload;
    },
    SET_WALLET_MONTHLY_SPEND_DATA(state, payload) {
        state.walletMonthlySpendData = payload.data;
    },
    SET_WALLET_EXPENSE_REPORT_DATA(state, payload) {
        state.walletExpenseReportData = payload.data;
    },
    SET_WALLET_SPEND_TRANSACTIONS_MARKED_COUNT(state, payload) {
        state.markedTransactionsCount = payload;
    },
    SET_WALLET_SPEND_EXPENSE_TYPE(state, payload) {
        state.walletSpendExpenseType = payload;
    }

};

const actions = {
    setActiveWalletInitial({commit, state}) {
        const activeWallet = state.wallets[0];
        commit("SET_ACTIVE_WALLET", activeWallet);
    },

    setActiveWallet({commit, dispatch}, wallet) {
        commit("SET_ACTIVE_WALLET", wallet);
        dispatch('saveSelectedWalletToStorage', wallet.id);
    },

    async getWalletTransactions({state, commit, rootState}, q = {}) {

        if (!rootState.activeWalletId) {
            return;
        }

        commit('loadingWalletTransactions', true);

        const walletQuery = Object.assign({}, state.walletQuery);
        walletQuery.page = q.page || 1;

        for (const [key, value] of Object.entries(q)) {
            if ('page' !== key) {
                walletQuery[key] = value;
            }
        }

        let path = `${rootState.path}payments/paginate/${rootState.activeWalletId}`;

        await axios
            .get(path, {
                params: walletQuery,
            })
            .then(response => {
                commit("SET_WALLET_TRANSACTIONS", response);
            })
            .catch(error => {
                commit('setAlert', {
                    type: 'error',
                    message: error.response.data.message,
                });
            })
            .finally(() => {
                commit('loadingWalletTransactions', false);
            });
    },

    onceGetWalletTransactions: _.once(({dispatch}) => dispatch('getWalletTransactions')),

    requestCards({commit}) {
        commit("REQUEST_CARDS");
    },

    async requestRewards({commit, rootState}) {
        return await axios
            .get(rootState.path + `/reward/paginate/${rootState.activeWalletId}`, {
                params: {
                    status: 'all',
                    type: 'all',
                    date_from: '',
                    date_to: '',
                    page: 1
                },
            })
            .then(response => {
                this.loading = false;
                commit("REQUEST_REWARDS", response.data);
            })
            .catch(error => {
                this.loading = false;
                this.$store.commit('setAlert', {
                    type: 'error',
                    message: error.response.data.message,
                });
            });
    },

    /* updateWallets({ commit }) {
        axios
            .get('get/wallets')
            .then(response => {
                commit('setData', { key: 'wallets', data: response.data });
            })
            .catch(error => {
                commit('setAlert', { type: 'error', message: error.response.data.message });
            });
    }, */

    setCardFilters({commit}, filters) {
        commit("SET_CARD_FILTERS", filters);
    },

    setCardStatementFilters({commit}, filters) {
        commit("SET_CARD_STATEMENT_FILTERS", filters);
    },

    setWalletOverviewFilters({commit}, filters) {
        commit("SET_WALLET_OVERVIEW_FILTERS", filters);
    },

    setExpenseReportsFilters({commit}, filters) {
        commit("SET_EXPENSE_REPORTS_FILTERS", filters);
    },

    setRewardsFilters({commit}, filters) {
        commit("SET_REWARDS_FILTERS", filters);
    },

    updateAvailableCurrencies({commit}, currencies) {
        commit("UPDATE_AVAILABLE_CURRENCIES", currencies);
    },

    updateHiddenCurrencies({commit}, currencies) {
        commit("UPDATE_HIDDEN_CURRENCIES", currencies);
    },

    toggleCardLayout({commit, dispatch}) {
        commit("TOGGLE_CARD_LAYOUT");
        dispatch('saveCurrentLayoutToStorage', 'cardTransactions');
    },

    toggleWalletLayout({commit, dispatch}) {
        commit("TOGGLE_WALLET_LAYOUT");
        dispatch('saveCurrentLayoutToStorage', 'walletTransactions');
    },

    toggleWalletStatementLayout({commit, dispatch}) {
        commit("TOGGLE_WALLET_STATEMENT_LAYOUT");
        dispatch('saveCurrentLayoutToStorage', 'walletStatement');
    },

    toggleCardStatementLayout({commit, dispatch}) {
        commit("TOGGLE_CARD_STATEMENT_LAYOUT");
        dispatch('saveCurrentLayoutToStorage', 'cardStatement');
    },

    toggleRewardsLayout({commit, dispatch}) {
        commit("TOGGLE_REWARDS_LAYOUT");
        dispatch('saveCurrentLayoutToStorage', 'rewards');
    },

    toggleEntityLayout({commit}) {
        commit("TOGGLE_ENTITY_LAYOUT");
    },

    setTransactionsFullScreen({commit}) {
        commit('SET_TRANSACTIONS_FULL_SCREEN');
    },

    unsetTransactionsFullScreen({commit}) {
        commit('SET_TRANSACTIONS_FULL_SCREEN', false);
    },

    saveCurrentLayoutToStorage({}, sectionId) {
        window.sessionStorage.setItem('walletPageSection', sectionId);
    },

    saveSelectedWalletToStorage({}, walletId) {
        window.sessionStorage.setItem('walletId', walletId);
    },

    saveSelectedCardToStorage({}, cardId) {
        window.sessionStorage.setItem('cardId', cardId);
    },

    saveOrgToStorage({}, orgId) {
        window.sessionStorage.setItem('walletOrgId', orgId);
    },

    clearSessionStorage() {
        [
            'walletPageSection',
            'walletId',
            'cardId',
        ].forEach(item => window.sessionStorage.removeItem(item));
    },

    setLayoutFromStorage({state, dispatch}) {
        const sectionId = window.sessionStorage.getItem('walletPageSection');
        if (sectionId === 'walletStatement' || sectionId === 'rewards') {
            dispatch(state.layouts[sectionId]);
        }
    },

    setActiveCard({commit, dispatch}, card) {
        commit("SET_ACTIVE_CARD", card);
        dispatch('saveSelectedCardToStorage', card.id);
    },

    addReserveReward({commit}, reward) {

        const rewardTransaction = {
            // id: faker.string.uuid(),
            // title: faker.lorem.sentence(3),
            startDate: new Date(),
            // linkedWallet: faker.finance.accountNumber(),
            reservedTime: reward.reservedTime,
            reservedAmount: reward.reservedAmount,
            rewardAmount: reward.rewardAmount,
        };

        rewardTransaction.formattedStartDate = format(
            rewardTransaction.startDate,
            "dd/MM/yyyy"
        );
        rewardTransaction.indexStartDate = rewardTransaction.startDate.getTime();
        rewardTransaction.finishDate = addDays(
            new Date(rewardTransaction.startDate),
            rewardTransaction.reservedTime
        );

        if (isAfter(new Date(), rewardTransaction.finishDate)) {
            rewardTransaction.remainingDays = 0;
        } else {
            rewardTransaction.remainingDays =
                rewardTransaction.reservedTime -
                differenceInDays(new Date(), rewardTransaction.startDate);
        }

        if (rewardTransaction.remainingDays === 0) {
            rewardTransaction.progress = 100;
            rewardTransaction.status = "Available";
        } else {
            rewardTransaction.progress =
                100 -
                Math.round(
                    (rewardTransaction.remainingDays / rewardTransaction.reservedTime) *
                    100
                );
            rewardTransaction.status = "Locked";
        }

        rewardTransaction.money = "Pending";

        rewardTransaction.formattedFinishDate = format(
            rewardTransaction.finishDate,
            "dd/MM/yyyy"
        );

        commit("ADD_RESERVE_REWARD", rewardTransaction);
    },

    setRewardsCreated({commit}, status) {
        commit("SET_REWARDS_CREATED", status);
    },

    updateRewardsWallet({commit}, status) {
        commit("UPDATE_REWARDS_WALLET", status);
    },

    setCurrencies({commit}, currencies) {
        commit("SET_CURRENCIES", currencies);
    },

    setTempCurrencies({commit}, currencies) {
        commit("SET_TEMP_CURRENCIES", currencies);
    },

    updateRewardTransaction({commit}, rewardTransaction) {
        commit("UPDATE_REWARD_TRANSACTION", rewardTransaction);
    },

    updateWalletTransaction({commit}, transaction) {
        commit("UPDATE_WALLET_TRANSACTION", transaction);
    },

    updateWalletTransactions({commit}, transactions) {
        transactions.forEach((transaction) => {
            transaction.expenseType = "Business";
        });
        commit("UPDATE_WALLET_TRANSACTIONS", transactions);
    },

    updateWalletTransactionsAfter({commit}) {
        commit("UPDATE_WALLET_TRANSACTIONS_AFTER");
    },

    resetUpdatedWalletTransactions({commit}) {
        commit("RESET_UPDATED_WALLET_TRANSACTIONS");
    },

    resetWalletTransactions({commit}) {
        commit('RESET_WALLET_TRANSACTIONS');
    },

    showLearnMoreModal({commit}) {
        commit('SHOW_LEARN_MORE_MODAL');
    },

    hideLearMoreModal({commit}) {
        commit('HIDE_LEARN_MORE_MODAL');
    },

    async getWalletSpendCategories({state, commit, rootState}, q = {}) {

        let path = `${rootState.path}wallet-spend/get-categories`;

        await axios
            .get(path)
            .then(response => {
                commit("SET_WALLET_SPEND_CATEGORIES", response);
            })
            .catch(error => {
                commit('setAlert', {
                    type: 'error',
                    message: error.response.data.message,
                });
            });
    },

    async getWalletStatementOverviewData({state, commit, rootState}, q = {}) {
        if (!rootState.activeWalletId) return;
        let url = `${rootState.path}wallet-statement/wallet-spend/${rootState.activeWalletId}`;
        const walletQuery = Object.assign({}, state.walletSpendOverviewQuery, q);
        walletQuery.page = q.page || 1;

        for (const [key, value] of Object.entries(q)) {
            if ('page' !== key) {
                walletQuery[key] = value;
            }
        }

        await axios.get(url, {
            params: walletQuery,
        })
            .then(response => {
                let items = response.data;
                items.forEach((item, index) => {
                    items[index].monthName = state.monthsNames[item.month - 1];
                })
                commit("SET_WALLET_OVERVIEW_DATA", items);

            })
            .catch(error => {
                commit('setAlert', {
                    type: 'error',
                    message: error.response.data.message,
                });
            });
    },

    async getWalletStatementMonthlyData({state, commit, rootState}) {
        if (!rootState.activeWalletId) {
            return;
        }
        let url = `${rootState.path}wallet-statement/data/${rootState.activeWalletId}`;
        await axios.get(url)
            .then(response => {
                commit("SET_WALLET_MONTHLY_SPEND_DATA", response);
            })
            .catch(error => {
                commit('setAlert', {
                    type: 'error',
                    message: error.response.data.message,
                });
            });
    },

    async getWalletExpenseReportsData({state, commit, rootState}, q = {}) {
        if (!rootState.activeWalletId) {
            return;
        }
        let url = `${rootState.path}wallet-statement/business-expenses-new/${rootState.activeWalletId}`;

        await axios.get(url, {
            params: q,
        })
            .then(response => {
                commit("SET_WALLET_EXPENSE_REPORT_DATA", response);
            })
            .catch(error => {
                commit('setAlert', {
                    type: 'error',
                    message: error.response.data.message,
                }, {root: true});
            });
    },

    async markTransaction({state, commit, rootState}, data) {
        if (!rootState.activeWalletId) {
            return;
        }
        await axios.post(`${rootState.path}${data.route}`, data.form)
            .then(response => {
                commit('SET_WALLET_SPEND_EXPENSE_TYPE', data.form.get('type'));
            })
            .catch(error => {
                commit("setAlert", {type: 'error', message: error.response.data.message}, {root: true});
            });
    },

    async switchWallet({state, commit, rootState}, data) {
        await axios.post(`${rootState.path}${data.route}`, {
            'isNewWallet': data.isNewWallet,
        })
            .then(response => {

            })
            .catch(error => {
                commit("setAlert", {type: 'error', message: error.response.data.message}, {root: true});
            });
    },

    async markTransactionMultiple({state, commit, rootState}, data) {

        await axios.post(`${rootState.path}wallet-spend/add-expense-request-multiple`, data)
            .then(response => {
                commit('SET_WALLET_SPEND_EXPENSE_TYPE', 'business');
            })
            .catch(error => {
                commit("setAlert", {type: 'error', message: error.response.data.message}, {root: true});
            });
    },
};

const getters = {

    walletTransactionItems: state => state.walletTransactionItems,

    walletTransactionPagination: state => state.walletTransactionPagination,

    loadingWalletTransactions: state => state.loadingWalletTransactions,

    walletQuery: state => state.walletQuery,

    getWallets: (state) => {
        return state.wallets;
    },

    getActiveWallet: state => {
        return state.activeWallet;
    },

    getActiveWalletTransactions: (state) => {
        return state.activeWallet.transactions;
    },

    getActiveWalletRewards: (state) => {
        return state.activeWallet.rewards;
    },

    getActiveWalletCards: (state) => {
        return state.activeWallet.cards;
    },

    getWalletsExceptActive: (state) => {
        return state.wallets.filter(
            (wallet) =>
                wallet.currency.currency !== state.activeWallet.currency.currency
        );
    },

    getStatuses: (state) => {
        return state.statuses;
    },

    getRewardsStatuses: (state) => {
        return state.rewardsStatuses;
    },

    getRewardsMoney: (state) => {
        return state.rewardsMoney;
    },

    isWalletLayout: (state) => {
        return state.isWalletLayout;
    },

    isCardLayout: (state) => {
        return state.isCardLayout;
    },

    isWalletStatementLayout: (state) => {
        return state.isWalletStatementLayout;
    },

    isCardStatementLayout: (state) => {
        return state.isCardStatementLayout;
    },

    isRewardsLayout: (state) => {
        return state.isRewardsLayout;
    },

    isEntityLayout: (state) => {
        return state.isEntityLayout;
    },

    getActiveCard: (state) => {
        return state.activeCard;
    },

    isActiveCardTypeEquals(state) {
        return state.activeCard.type === 'native';
    },

    getActiveCardTransactions: (state) => {
        return state.activeCard.cardTransactions;
    },

    getFilteredCardTransactions: (state) => {
        return state.activeCard.cardTransactions.filter((transaction) => {
            const status = state.cardFilters.statuses.includes(transaction.status);
            const type = state.cardFilters.types.includes(transaction.type);
            const transactionName = transaction.from
                .toLowerCase()
                .includes(state.cardFilters.search.toLowerCase());

            return (
                (state.cardFilters.statuses.length === 0 || status) &&
                (state.cardFilters.types.length === 0 || type) &&
                (!state.cardFilters.search || transactionName)
            );
        });
    },

    getFilteredCardStatementTransactions: (state) => {
        return state.activeCard.cardTransactions.filter((transaction) => {
            let transactionExpenseType = true;
            if (state.cardStatementFilters.expenseType !== "all") {
                transactionExpenseType =
                    transaction.expenseType.toLowerCase() ===
                    state.cardStatementFilters.expenseType.toLowerCase();
            }

            const status = state.cardStatementFilters.statuses.includes(
                transaction.status
            );
            const type = state.cardStatementFilters.types.includes(transaction.type);

            const transactionName = transaction.from
                .toLowerCase()
                .includes(state.cardStatementFilters.search.toLowerCase());

            return (
                (!state.cardStatementFilters.expenseType || transactionExpenseType) &&
                (state.cardStatementFilters.statuses.length === 0 || status) &&
                (state.cardStatementFilters.types.length === 0 || type) &&
                (!state.cardStatementFilters.transactionName || transactionName)
            );
        });
    },

    getRewardsTransactions: (state) => {
        return state.activeWallet.rewards.transactions;
    },

    getFilteredRewardsTransactions: (state) => {
        return state.activeWallet.rewards.transactions.filter((transaction) => {
            const status = transaction?.status === state.rewardsFilters.status;
            const type = transaction?.money === state.rewardsFilters.money;
            const transactionName = transaction.title
                .toLowerCase()
                .includes(state.rewardsFilters.search.toLowerCase());
            return (
                (!state.rewardsFilters.status || status) &&
                (!state.rewardsFilters.type || type) &&
                (!state.rewardsFilters.search || transactionName)
            );
        });
    },

    getActiveWalletCurrency: (state) => {
        return state.activeWallet.currency;
    },

    getCategoryTypes: (state) => {
        return state.categoryTypes;
    },

    getWalletOverview: (state) => {
        return state.walletOverview;
    },

    getFilteredWalletOverview: (state) => {
        return state.walletOverview.filter((statement) => {
            const statementMonth =
                statement.month.toLowerCase() ===
                state.walletOverviewFilters.search.toLowerCase();

            const statementFrom =
                state.walletOverviewFilters.dateFrom === statement.year;

            const statementTo = state.walletOverviewFilters.dateTo === statement.year;

            return (
                (!state.walletOverviewFilters.search || statementMonth) &&
                (!state.walletOverviewFilters.dateFrom ||
                    statementFrom ||
                    !state.walletOverviewFilters.dateTo ||
                    statementTo)
            );
        });
    },

    getTotalIncomeWalletOverview: (state) => {
        return state.walletOverview.reduce((acc, cur) => {
            return acc + cur.income * 1;
        }, 0);
    },

    getTotalExpenseWalletOverview: (state) => {
        return state.walletOverview.reduce((acc, cur) => {
            return acc + cur.expense * 1;
        }, 0);
    },

    getExpenseReports: (state) => {
        let expenseReports;
        state.expenseReports.forEach((reports) => {
            if (
                parseInt(state.expenseReportsFilters.year) === parseInt(reports.year)
            ) {
                expenseReports = {...reports};
            }
        });

        return expenseReports;
    },

    getWalletOverviewFiltersDates: (state) => {
        if (
            !state.walletOverviewFilters.dateFrom &&
            !state.walletOverviewFilters.dateTo
        ) {
            return undefined;
        }

        const dateFrom = state.walletOverviewFilters.dateFrom
            ? state.walletOverviewFilters.dateFrom
            : "";

        const dateTo = state.walletOverviewFilters.dateTo
            ? `- ${state.walletOverviewFilters.dateTo}`
            : "";

        return `${dateFrom} ${dateTo}`;
    },

    getExpensesReportsFiltersDates: (state) => {
        return state.expenseReportsFilters.year;
    },

    getUpdatedWalletTransactions: (state) => {
        return state.updatedWalletTransactions;
    },

    getRewardsCreated: (state) => {
        return state.rewardsCreated;
    },

    getWalletOverviewData: (state) => {
        return state.walletOverviewItems;
    },
    getWalletMonthlySpendData: (state) => {
        return state.walletMonthlySpendData;
    },
    getWalletExpenseReportData: (state) => {
        return state.walletExpenseReportData;
    },
    getWalletSpendTransactionsMarkedCount: (state) => {
        return state.markedTransactionsCount;
    },
    getWalletSpendExpenseType: (state) => {
        return state.walletSpendExpenseType;
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
