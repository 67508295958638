<template>
    <div class="base-image-upload">
        <template v-if="content === 'first'">
            <p class="input-value mt-3 text-base font-medium color-black">
                {{ title }}
            </p>
            <p class="text-sm text-center color-grey-2 mt-2 mb-3">
                {{ subtitle }}
            </p>

            <div class="image-upload-file-view text-center mb-3">
                <BaseIcon v-if="!logoImage" name="img-placeholder"/>
                <img v-if="logoImage" :src="logoImage"/>
            </div>
        </template>
        <template v-else-if="content === 'last'">
            <div class="image-upload-file-view text-center">
                <BaseIcon v-if="!logoImage" name="img-placeholder"/>
                <img v-if="logoImage" :src="logoImage"/>
            </div>
            <p class="input-value mt-3 text-base font-medium color-black">
                {{ title }}
            </p>
            <p class="text-sm text-center color-grey-2 mt-2 mb-3">
                {{ subtitle }}
            </p>
        </template>
        <template v-if="content === 'side'">
            <div class="base-image-upload-wrapper">
                <div class="base-image-upload-content">
                    <p class="input-value mt-3 text-base font-medium color-black">
                        {{ title }}
                    </p>
                    <p class="text-sm color-grey-2 mt-2 mb-3">
                        {{ subtitle }}
                    </p>
                </div>
                <div class="base-image-upload-form">
                    <div class="image-upload-file-view text-center mb-3">
                        <BaseIcon v-if="!logoImage" name="img-placeholder"/>
                        <img v-if="logoImage" :src="logoImage"/>
                    </div>
                    <label
                        class="btn btn-small btn-purple-outline text-sm font-medium color-purple"
                        v-if="logoImage === '' || logoImage === null"
                    >
                        Upload
                        <v-file-input
                            @change="onFileInput($event)"
                            :accept="allowedDocumentTypes"
                            hide-input
                            :prepend-icon="null"
                            multiple
                        />
                    </label>
                    <div class="d-flex px-2" v-else>
                        <BaseButton
                            class="btn btn-small btn-purple-outline text-sm font-medium color-purple button-edit"
                            @click="$refs.file.$refs.input.click()"
                        >
                            Edit
                        </BaseButton>
                        <BaseButton
                            class="btn btn-small btn-purple-outline text-sm font-medium color-purple ml-2 button-delete"
                            @click="deleteImage"
                        >
                            Delete
                        </BaseButton>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <label
                class="btn btn-small btn-purple-outline text-sm font-medium color-purple"
                v-if="logoImage === '' || logoImage === null"
            >
                Upload
                <v-file-input
                    @change="onFileInput($event)"
                    :accept="allowedDocumentTypes"
                    hide-input
                    :prepend-icon="null"
                    multiple
                />
            </label>
            <div class="d-flex px-2" v-else>
                <BaseButton
                    class="btn btn-small btn-purple-outline text-sm font-medium color-purple button-edit"
                    @click="$refs.file.$refs.input.click()"
                >
                    Edit
                </BaseButton>
                <BaseButton
                    class="btn btn-small btn-purple-outline text-sm font-medium color-purple ml-2 button-delete"
                    @click="deleteImage"
                >
                    Delete
                </BaseButton>
            </div>
        </template>

        <v-file-input
            ref="file"
            @change="onFileInput($event)"
            :accept="allowedDocumentTypes"
            hide-input
            :prepend-icon="null"
            multiple
        />
    </div>
</template>

<script>
export default {
    name: "BaseImageUpload",

    props: {
        value: {
            type: File,
            default: null,
        },
        image: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: "Profile image",
        },
        subtitle: {
            type: String,
            default: "This image will be used in your invoices.",
        },
        showSuccessDialog: {
            type: Boolean,
            default: false,
        },
        content: {
            type: String,
            default: "default",
        },
    },

    watch: {
        image: {
            immediate: true,
            handler(value) {
                this.logoImage = value;
            },
        },
    },

    data() {
        return {
            logoImage: "",
            allowedDocumentTypes: ["image/png"],

            successDialog: false,
            successModalData: {
                icon: {
                    name: "check-circle-outline",
                    class: "color-feedback-success",
                },
                title: "Image uploaded",
                text: "You have successfully uploaded and image for your logo.",
                btnText: "Close",
            },

            editDialog: false,
            editModalData: {
                icon: {
                    name: "warning",
                    class: "color-feedback-warning",
                },
                title: "Image too large",
                text: "The image needs to be 35x35px max. Would you like to try a different image?",
                btnText: "Close",
            },
        };
    },

    methods: {
        onFileInput(event) {
            if (!event[0]) return;

            const data = URL.createObjectURL(event[0]);
            this.logoImage = data;

            this.$emit("input", event[0]);

            if (this.showSuccessDialog) {
                this.successDialog = true;
            }
        },

        deleteImage() {
            this.logoImage = "";
            this.$emit("input", null);
        },
    },

    created() {
        if (this.value) {
            const data = URL.createObjectURL(this.value);
            this.logoImage = data;
        }
    },
};
</script>
