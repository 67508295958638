import {mapGetters} from "vuex";

export const commonHelpersMixin = {
    props: {
        desktopViewAfterWidth: {
            default: 992,
        },
    }, computed: {
        ...mapGetters("screen", ["window"]),

        isDesktop() {
            return (this.$store && this.$store.state.window && this.$store.state.window.width > this.desktopViewAfterWidth) && this.$store.state.window.width > 992;
        },
        isTabletOrDesktop() {
            return this.$store && this.$store.state.window && this.$store.state.window.width > 767;
        },
        isScreen1550To992() {
            return (this.$store && this.$store.state.window.width <= 1550 && this.$store.state.window.width > 992) && (this.window.width <= 1550 && this.window.width >= 992);
        },
        isScreenBiggerThan1550() {
            return (this.$store && this.$store.state.window && this.$store.state.window.width > 1550) && this.window.width > 1550;
        },
        isScreen1550To992AndHeightLessThan840() {
            return (this.$store && this.$store.state.window.width <= 1550 && this.$store.state.window.width > 992 && this.$store.state.window.height < 840) && (
                this.window.width <= 1550 &&
                this.window.width > 992 &&
                this.window.height < 840
            );
        },
        isScreen768To992() {
            return (this.$store && this.$store.state.window.width <= 991 && this.$store.state.window.width > 767) && (this.window.width <= 991 && this.window.width > 767);
        },
        isSmallTablet() {
            return (this.$store && this.$store.state.window && this.$store.state.window.width <= 768) && this.window.width < 768;
        },
        isLargeMobile() {
            return (this.$store && this.$store.state.window && this.$store.state.window.width <= 425) && this.window.width <= 425;
        },
        isScreen768To1199() {
            return (this.$store && this.$store.state.window.width <= 1199 && this.$store.state.window.width >= 767) && (this.window.width <= 1199 && this.window.width > 767);
        },
        isScreenBiggerThan1199() {
            return (this.$store && this.$store.state.window.width > 1199)  && this.window.width > 1199
        },
        isScreenBiggerThan1024() {
            return (this.$store && this.$store.state.window.width > 1024)  && this.window.width > 1024;
        },
        isBigTablet() {
            return  (this.$store && this.$store.state.window.width <= 992) &&  this.window.width <= 992;
        },
        isMobile() {
            return  (this.$store && this.$store.state.window.width <= 575) &&  this.window.width <= 640;
        },

    },
};
