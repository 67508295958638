/** @format */

const state = {
    path: '/members',
    getMembersData: false,
    countries: [],
};
const getters = {
    path: state => state.path,
    countries: state => state.countries,
    getMembersData: state => state.getMembersData,
};

const mutations = {
    SET_DATA(state, params) {
        state[params.key] = params.value;
    },
};
const actions = {
    setCountries({ commit }, params = {}) {
        axios
            .post(`${state.path}/get/countries`, params)
            .then(({ data }) => {
                commit('SET_DATA', { key: 'countries', value: Object.values(data)});
            });
    },
    getMembers({ commit }, value = false) {
        commit('SET_DATA', { key: 'getMembersData', value});
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
