import Middleware from "~/routes/middleware/Middleware";

export default class invoiceGuestPay extends Middleware{
    constructor(...args) {
        super(...args);
    }

    async checkPayment(id) {
        return await axios.post('invoice/pay-check/' + id)
            .then(response => response.data)
    }

    async resolve() {
        const id = this.to.params.id;
        const response = await this.checkPayment(id)

        return response && response.valid ?
            this.responseNext(response.data) :
            this.responseAbort('PaymentExpired')
    }
}
