<!-- @format -->

<template>
    <BaseModal
        v-if="!isUpgrade"
        v-model="dialog"
        :title="actualTitle"
        :hideBackgroundModals="hideBackgroundModals"
        class-names="select-bank-cards add-card-dialog"
        persistent
        attach=".vue-repo-app"
    >
        <template v-slot:body>

            <template v-if="'stripe_client' === paymentType">
                <stripe-form
                    v-if="dialog"
                    :card="card"
                    :scenario="'card'"
                    @process="onStripeProcess"
                ></stripe-form>
            </template>
            <template v-else-if="'stripe' === paymentType">
            <template v-if="isNewLayout">
                <div class="w-100 mb-4">
                    <img class="mb-2" width="105px" :src="cardImage" />
                </div>

                <div class="row mb-5">
                    <div class="col-12 mb-4">
                        <BaseInput
                            name="holder"
                            custom-label-parent-classes="text-sm"
                            label="Name on the card"
                            @keypress="keypressForLetter($event)"
                            force-placeholder="Christopher Wallace"
                            v-model="cardObj.values.holder"
                            v-validate="cardObj.rules.holder"
                            :error-messages="errors.collect('holder')"
                        />
                    </div>

                    <div class="col-12 mb-4">
                        <BaseInput
                            label="Card number"
                            name="number"
                            v-mask="'#### #### #### ####'"
                            force-placeholder="1111 2222 3333 4444"
                            customLabelParentClasses="text-sm mb-1"

                            v-model="cardObj.values.number"
                            v-validate="cardObj.rules.number"
                            :error-messages="errors.collect('number')"
                        />
                    </div>

                    <div class="col-12 col-md-6 py-0">
                        <BaseInput
                            custom-label-parent-classes="text-sm"
                            class="mb-small-4"
                            v-mask="'##/##'"
                            label="Expiration date"
                            force-placeholder="01/25"
                            name="expiration_date"
                            v-model="cardObj.values.expiration_date"
                            v-validate="cardObj.rules.expiration_date"
                            :error-messages="errors.collect('expiration_date')"
                            data-vv-as="expiration date"
                            @error="errors.add($event)"
                        />
                    </div>

                    <div class="col-12 col-md-6 py-0">
                        <BaseInput
                            custom-label-parent-classes="text-sm"
                            name="cvv"
                            v-model="cardObj.values.cvv"
                            v-validate="cardObj.rules.cvv+`|cvv:${cardObj.values.number}`"
                            :error-messages="errors.collect('cvv')"
                            v-mask="'####'"
                            label="CVV"
                        />
                    </div>
                </div>
            </template>

            <template v-if="card && type !== 'soldo' && type !== 'native'">
                <div class="mb-4">
                    <div class="d-flex align-start">
                        <img width="40" :src="$store.state.imagePath + card.brand_logo" :alt="card.brand" class="mr-3" />
                        <div class="card-info">
                            <p class="tm-small-text font-weight-medium tm-color-black mb-1">
                                {{ card.holder }}
                            </p>
                            <p class="tm-medium-text tm-color-dark-grey mb-1"> **** {{ card.last4 }} </p>
                            <chip
                                v-if="card.primary"
                                text="Primary"
                                class="px-2"
                                font-weight="regular"
                                color="#5152fb"
                            ></chip>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="!isNewLayout">
                <div class="row">
                    <template v-if="!currencyId && type !== 'billings' && !card">
                        <div class="col-12 mb-4">
                            <BaseSelect
                                avatar
                                autocomplete

                                label="Currency"
                                name="currency_id"
                                class="global-base-select-menu"
                                customClassActiveItem="custom-active"
                                labelClassVal="color-black custom-label mb-0 text-sm"
                                data-vv-as="currency"
                                item-text="code"
                                item-value="id"

                                :select-first="true"
                                v-model="cardObj.values.currency_id"
                                v-validate="cardObj.rules.currency_id"
                                :error-messages="errors.collect('currency_id')"
                                :items="cardCurrencies"
                            />
                        </div>
                    </template>

                    <div class="col-12 mb-4">
                        <BaseInput
                            label="Card holder"
                            force-placeholder="Type here"
                            name="holder"
                            customLabelParentClasses="text-sm mb-1"

                            v-model="cardObj.values.holder"
                            v-validate="cardObj.rules.holder"
                            :error-messages="errors.collect('holder')"
                        />
                    </div>
                    <div class="col-12 mb-4">
                        <BaseInput
                            label="Card number"
                            name="number"
                            v-mask="'#### #### #### ####'"
                            force-placeholder="1111 2222 3333 4444"
                            customLabelParentClasses="text-sm mb-1"

                            v-model="cardObj.values.number"
                            v-validate="cardObj.rules.number"
                            :error-messages="errors.collect('number')"
                        />
                    </div>
                    <div class="col-6 mb-4">
                        <BaseInput
                            v-mask="'##/##'"
                            label="Expiration date"
                            name="expiration_date"
                            force-placeholder="01/25"
                            customLabelParentClasses="text-sm mb-1"
                            data-vv-as="expiration date"

                            v-model="cardObj.values.expiration_date"
                            v-validate="cardObj.rules.expiration_date"
                            :error-messages="errors.collect('expiration_date')"
                            @error="errors.add($event)"
                        />
                    </div>
                    <div class="col-6 mb-4">
                        <BaseInput
                            v-mask="'####'"
                            label="CVV"
                            name="cvv"
                            force-placeholder="123"
                            customLabelParentClasses="text-sm mb-1"

                            v-model="cardObj.values.cvv"
                            v-validate="cardObj.rules.cvv+`|cvv:${cardObj.values.number}`"
                            :error-messages="errors.collect('cvv')"
                        />
                    </div>

                    <template v-if="type !== 'soldo' && type !== 'native'">
                        <div class="col-12">
                            <v-divider />
                        </div>

                        <div class="col-12 mb-4">
                            <BaseSelect
                                avatar
                                autocomplete

                                label="Country"
                                name="country"
                                placeholder="Select"
                                class="global-base-select-menu"
                                customClassActiveItem="custom-active"
                                labelClassVal="color-black custom-label mb-0 text-sm"
                                data-vv-as="Country"
                                item-value="code"

                                v-model="cardObj.values.billing_address.country"
                                v-validate="cardObj.rules.billing_address.country"
                                :error-messages="errors.collect('country')"

                                :search-autoload="true"
                                :search-route="$store.state.getPath+'search-countries'"
                                :search-params="{ code: cardObj.values.billing_address.country }"
                                search-key="value"
                            />
                        </div>
                        <div class="col-12 mb-4">
                            <BaseInput
                                label="City"
                                name="city"
                                customLabelParentClasses="text-sm mb-1"

                                v-model="cardObj.values.billing_address.city"
                                v-validate="cardObj.rules.billing_address.city"
                                :error-messages="errors.collect('city')"
                                data-vv-as="City"
                            />
                        </div>
                        <div class="col-12 mb-4">
                            <BaseInput
                                label="Address line 1"
                                name="line1"
                                customLabelParentClasses="text-sm mb-1"

                                v-model="cardObj.values.billing_address.line1"
                                v-validate="cardObj.rules.billing_address.line1"
                                :error-messages="errors.collect('line1')"
                                data-vv-as="Address Line 1"
                            />
                        </div>
                        <div class="col-12 mb-4">
                            <BaseInput
                                label="Address line 2 (optional)"
                                name="line2"
                                customLabelParentClasses="text-sm mb-1"

                                v-model="cardObj.values.billing_address.line2"
                                v-validate="cardObj.rules.billing_address.line2"
                                :error-messages="errors.collect('line2')"
                                data-vv-as="Address Line 2"
                            />
                        </div>
                        <div class="col-6 mb-4">
                            <BaseInput
                                label="Postal code"
                                name="postal_code"
                                customLabelParentClasses="text-sm mb-1"

                                v-model="cardObj.values.billing_address.postal_code"
                                v-validate="cardObj.rules.billing_address.postal_code"
                                :error-messages="errors.collect('postal_code')"
                                data-vv-as="Postal Code"
                            />
                        </div>
                        <div class="col-6 mb-4">
                            <BaseInput
                                label="State"
                                name="state"
                                customLabelParentClasses="text-sm mb-1"

                                v-model="cardObj.values.billing_address.state"
                                v-validate="cardObj.rules.billing_address.state"
                                :error-messages="errors.collect('state')"
                                data-vv-as="State"
                            />
                        </div>
                    </template>
                </div>


                <div
                    v-if="showPrimary && (!card || ($store.state.primaryWorker && card.worker_id === $store.state.primaryWorker.id)) && type === 'billings'"
                    class="col-12 pl-0 pr-0 pt-0"
                >
                    <label class="grey-container">
                        <BaseInput
                            type="checkbox"
                            name="primary"
                            customLabelParentClasses="m-0"

                            v-model="cardObj.values.primary"
                            v-validate="cardObj.rules.primary"
                            :error-messages="errors.collect('primary')"
                        >
                            <template v-slot:label>
                                <span class="text-sm color-black custom-label-radio ">
                                    Make primary?
                                </span>
                            </template>
                        </BaseInput>
                    </label>
                </div>
            </template>
            </template>
        </template>

        <template v-slot:footer>
            <template v-if="'stripe_client' === paymentType"></template>
            <template v-else>
            <template v-if="isNewLayout">
                <BaseButton
                    class="btn-big btn-purple color-white w-100"
                    :loading="loading"
                    :disabled="submitBtnDisabled"
                    @click="card ? update() : create()"
                >
                    {{ submitBtnTitle }}
                </BaseButton>
            </template>

            <BaseButton
                v-else
                type="button"
                class="btn-big btn-purple color-white w-100"
                :loading="loading"
                :disabled="!isValid"
                @click="card ? update() : create()"
            >
                {{card ? 'Update card' : 'Add card'}}
            </BaseButton>
            </template>
        </template>
    </BaseModal>



<!--    <modal
        v-model="dialog"
        :width="isNewLayout ? '' : '28.125rem'"
        :max-width="isNewLayout ? '500px' : ''"
        :content-class="isNewLayout ? 'vue-repo-app custom-card-form' : ''"
        :close-icon-show="false"
        :permission="!card ? 'add_payment_method' : null"
    >
        <template v-if="isNewLayout">
            <div class="modal-container custom-modal">
                <div class="modal-header">
                    <h2 class="heading-2 font-medium color-black">
                        {{ actualTitle }}
                    </h2>
                    <BaseButton class="btn-icon text-lg color-grey-2" @click="closeModal">
                        <BaseIcon name="times" />
                    </BaseButton>
                </div>
                <div class="modal-body">
                    <div class="w-100 mb-4">
                        <img class="mb-2" width="105px" :src="cardImage" />
                    </div>

                    <div class="row mb-5 my-0">
                        <div class="col-12 py-0">
                            <BaseInput
                                name="holder"
                                custom-label-parent-classes="text-sm"
                                class="mb-4"
                                label="Name on the card"
                                force-placeholder="Christopher Wallace"
                                v-model="cardObj.values.holder"
                                v-validate="cardObj.rules.holder"
                                :error-messages="errors.collect('holder')"
                            />
                        </div>

                        <div class="col-12 py-0">
                            <BaseInput
                                custom-label-parent-classes="text-sm"
                                class="mb-4"
                                name="number"
                                v-mask="'#### #### #### ####'"
                                label="Card number"
                                force-placeholder="1111 2222 3333 4444"
                                v-model="cardObj.values.number"
                                v-validate="cardObj.rules.number"
                                :error-messages="errors.collect('number')"
                            />
                        </div>

                        <div class="col-12 col-md-6 py-0">
                            <BaseInput
                                custom-label-parent-classes="text-sm"
                                class="mb-small-4"
                                v-mask="'##/##'"
                                label="Expiration date"
                                force-placeholder="01/25"
                                name="expiration_date"
                                v-model="cardObj.values.expiration_date"
                                v-validate="cardObj.rules.expiration_date"
                                :error-messages="errors.collect('expiration_date')"
                                data-vv-as="expiration date"
                                @error="errors.add($event)"
                            />
                        </div>

                        <div class="col-12 col-md-6 py-0">
                            <BaseInput
                                custom-label-parent-classes="text-sm"
                                name="cvv"
                                v-model="cardObj.values.cvv"
                                v-validate="cardObj.rules.cvv"
                                :error-messages="errors.collect('cvv')"
                                v-mask="'###'"
                                label="CVV"
                            />
                        </div>
                    </div>

                    <BaseButton
                        class="btn-big btn-purple color-white w-100"
                        :loading="loading"
                        :disabled="submitBtnDisabled"
                        @click="card ? update() : create()"
                    >
                        {{ submitBtnTitle }}
                    </BaseButton>
                </div>
            </div>
        </template>

        <template v-if="card && type !== 'soldo' && type !== 'native'" slot="title">
            <div class="mb-4">
                <div class="d-flex align-start">
                    <img width="40" :src="$store.state.imagePath + 'mastercard.svg'" alt="mastercard" class="mr-3" />
                    <div class="card-info">
                        <p class="tm-small-text font-weight-medium tm-color-black mb-1">
                            {{ card.holder }}
                        </p>
                        <p class="tm-medium-text tm-color-dark-grey mb-1"> **** {{ card.last4 }} </p>
                        <chip
                            v-if="card.primary"
                            text="Primary"
                            class="px-2"
                            font-weight="regular"
                            color="#5152fb"
                        ></chip>
                    </div>
                </div>
            </div>
        </template>
        <template slot="content" v-if="!isNewLayout">
            <div class="modal-header border-bottom-0 px-0 d-flex mb-6-rem align-center">
                <h3 class="modal-title heading-2 bold-text color-black"> {{ !card ? 'Add New' : 'Update' }} Card </h3>
                <button @click="dialog = false" type="button" class="btn btn-icon btn-p-0 color-black ml-auto">
                    <i class="icon-times"></i>
                </button>
            </div>

            <template v-if="type === 'soldo'">
                <img src="/images/settings/soldo/soldo-card.svg" alt="native-card.svg" class="img-card-add mb-3" />
                <p class="small-text color-dark-grey mb-30">{{ textTopUp }}</p>
            </template>

            <template v-else-if="type === 'native'">
                <img src="/images/virtual-cards/nt-equals-card.svg" alt="native-card.svg" class="img-card-add mb-3" />
                <p class="small-text color-dark-grey mb-30">{{ textTopUp }}</p>
            </template>

            <template v-else>
                <p class="small-text color-dark-grey mb-10">{{ textAddNewCard }}</p>

                <div class="d-flex align-center mb-30" v-if="type !== 'soldo' && type !== 'native'">
                    <img :src="$store.state.imagePath + 'visa.svg'" alt="visa" class="mr-1" />
                    <img :src="$store.state.imagePath + 'mastercard.svg'" alt="mastercard" class="mr-1" />
                    <img :src="$store.state.imagePath + 'discover.svg'" alt="mastercard" class="mr-1" />
                    <img :src="$store.state.imagePath + 'american-express.svg'" alt="mastercard" class="mr-1" />
                </div>
            </template>

            <v-row no-gutters>
                <v-col v-if="!currencyId && type !== 'billings' && !card" cols="12">
                    <tm-advanced-select
                        label="Currency"
                        name="currency"
                        v-model="cardObj.values.currency_id"
                        v-validate="cardObj.rules.currency_id"
                        :error-messages="errors.collect('currency')"
                        :items="cardCurrencies"
                        item-value="id"
                    >
                        <template v-slot:selection="{ item }">
                            <div class="d-flex align-center mr-2">
                                <avatar size="1.5rem" :object="item"></avatar>
                                <span class="normal-text-2 color-black">
                                    {{ item.code }}
                                </span>
                            </div>
                        </template>

                        <template v-slot:item="{ item }">
                            <div class="d-flex align-center mr-2">
                                <avatar size="1.5rem" :object="item"></avatar>
                                <span class="normal-text-2 color-black">
                                    {{ item.code }}
                                </span>
                            </div>
                        </template>
                    </tm-advanced-select>
                </v-col>
                <v-col cols="12">
                    <tm-input
                        label="Card holder"
                        name="holder"
                        v-model="cardObj.values.holder"
                        v-validate="cardObj.rules.holder"
                        :error-messages="errors.collect('holder')"
                    ></tm-input>
                </v-col>
                <v-col cols="12">
                    <tm-input
                        data-name="card_number"
                        label="Card number"
                        name="number"
                        v-mask="'#### #### #### ####'"
                        v-model="cardObj.values.number"
                        v-validate="cardObj.rules.number"
                        :error-messages="errors.collect('number')"
                    />
                </v-col>
                <v-col cols="12" md="6" class="pr-md-1">
                    <tm-select-card-date
                        placeholder="01/25"
                        label="Expiration date"
                        name="expiration_date"
                        v-model="cardObj.values.expiration_date"
                        v-validate="cardObj.rules.expiration_date"
                        :error-messages="errors.collect('expiration_date')"
                        data-vv-as="expiration date"
                        :show-icon="false"
                        @error="errors.add($event)"
                    ></tm-select-card-date>
                </v-col>
                <v-col cols="12" md="6" class="pl-md-1">
                    <tm-input
                        v-mask="'####'"
                        label="CVV"
                        name="cvv"
                        v-model="cardObj.values.cvv"
                        v-validate="cardObj.rules.cvv"
                        :error-messages="errors.collect('cvv')"
                    ></tm-input>
                </v-col>
                <template v-if="type !== 'soldo' && type !== 'native'">
                    <v-col cols="12">
                        <v-divider class="my-4"></v-divider>
                    </v-col>
                    <v-col cols="6" class="pr-md-1">
                        <tm-advanced-select
                            autofocus
                            :is-sort="false"
                            label="Country"
                            name="billing_country"
                            search-key="name"
                            :search="true"
                            :select-first="false"
                            :search-autoload="true"
                            :search-route="$store.state.getPath + 'search-countries'"
                            :search-additional-params="{ code: cardObj.values.billing_address.country }"
                            v-validate="cardObj.rules.billing_address.country"
                            v-model="cardObj.values.billing_address.country"
                            placeholder-class="fs-13"
                            item-value="code"
                            :error-messages="errors.collect('billing_country')"
                            data-vv-as="Country"
                        >
                            <template v-slot:selection="{ item }">
                                <div class="d-flex align-center">
                                    <avatar size="1.25rem" :object="item"></avatar>
                                    <span class="tm-medium-text tm-color-black mr-1">{{ item.name }}</span>
                                </div>
                            </template>
                            <template v-slot:item="{ item }">
                                <div class="d-flex align-center">
                                    <avatar size="1.25rem" :object="item"></avatar>
                                    <span class="tm-medium-text tm-color-black mr-1">{{ item.name }}</span>
                                </div>
                            </template>
                        </tm-advanced-select>
                    </v-col>
                    <v-col cols="6" class="pl-md-1">
                        <tm-input
                            label="City"
                            name="billing_city"
                            v-model="cardObj.values.billing_address.city"
                            v-validate="cardObj.rules.billing_address.city"
                            :error-messages="errors.collect('billing_city')"
                            data-vv-as="City"
                        ></tm-input>
                    </v-col>
                    <v-col cols="12">
                        <tm-input
                            label="Address line 1"
                            name="billing_line1"
                            v-model="cardObj.values.billing_address.line1"
                            v-validate="cardObj.rules.billing_address.line1"
                            :error-messages="errors.collect('billing_line1')"
                            data-vv-as="Address Line 1"
                        ></tm-input>
                    </v-col>
                    <v-col cols="12">
                        <tm-input
                            label="Address Line 2"
                            name="billing_line2"
                            v-model="cardObj.values.billing_address.line2"
                            v-validate="cardObj.rules.billing_address.line2"
                            :error-messages="errors.collect('billing_line2')"
                            data-vv-as="Address Line 2"
                        ></tm-input>
                    </v-col>
                    <v-col cols="6" class="pr-md-1">
                        <tm-input
                            label="Postal code"
                            name="billing_postal_code"
                            v-model="cardObj.values.billing_address.postal_code"
                            v-validate="cardObj.rules.billing_address.postal_code"
                            :error-messages="errors.collect('billing_postal_code')"
                            data-vv-as="Postal Code"
                        ></tm-input>
                    </v-col>
                    <v-col cols="6" class="pl-md-1">
                        <tm-input
                            label="State"
                            name="billing_state"
                            v-model="cardObj.values.billing_address.state"
                            v-validate="cardObj.rules.billing_address.state"
                            :error-messages="errors.collect('billing_state')"
                            data-vv-as="State"
                        ></tm-input>
                    </v-col>
                </template>
            </v-row>

            <tm-slide-checkbox
                v-if="
                    showPrimary &&
                    (!card || ($store.state.primaryWorker && card.worker_id === $store.state.primaryWorker.id)) &&
                    type === 'billings'
                "
                label="Make primary?"
                id="primary-checkbox"
                :bottom-offset="false"
                name="primary"
                v-model="cardObj.values.primary"
                v-validate="cardObj.rules.primary"
                :error-messages="errors.collect('primary')"
            ></tm-slide-checkbox>
            <div class="d-flex align-center justify-end new-card-footer-block">
                <btn class="custom-btn-primary" :text="card ? 'update card' : 'add card'" :loading="loading" @click="card ? update() : create()">
                </btn>
            </div>
        </template>
    </modal>-->

</template>
<script>
import Modal from '~/shared/components/Modal';
import TmInput from '~/shared/components/form/TmInput';
import TmSelectDate from '~/shared/components/form/TmSelectDate';
import TmSlideCheckbox from '~/shared/components/form/TmSlideCheckbox';
import Card from '../../models/Card';
import Btn from '~/shared/components/Btn';
import Chip from '~/shared/components/Chip';
import TmSelectCardDate from '~/shared/components/form/TmSelectCardDate';
import Helpers from '~/shared/mixins/Helpers';
import TmAdvancedSelect from '~/shared/components/form/TmAdvancedSelect';
import Avatar from '~/shared/components/Avatar';
import StripeForm from '~/shared/components/StripeForm';
export default {
    name: 'CardForm',
    components: {
        Avatar,
        TmAdvancedSelect,
        TmSelectCardDate,
        Chip,
        Btn,
        TmSlideCheckbox,
        TmSelectDate,
        TmInput,
        Modal,
        StripeForm,
    },
    props: {
        value: {
            default: false,
        },
        card: {
            default: null,
        },
        type: {
            default: 'billings',
        },
        defaultOrg: {
            default: false,
        },
        currencyId: {
            default: null,
        },
        organizationId: {
            default: null,
        },
        showPrimary: {
            default: true,
        },
        workerObject:{
            required:false,
            default:null,
        },
        hideBackgroundModals: {
            type: Boolean,
            default: true,
        },
        paymentType: {
            type: String,
            default: 'stripe',
            validator: value => ['stripe', 'stripe_client'].includes(value),
        },
    },
    mixins: [Helpers],
    data() {
        return {
            textTopUp: 'Add your card details that you want to withdraw money to',
            textAddNewCard: 'Please enter the details to add new card',
            dialog: this.value,
            cardObj: new Card({}),
            loading: false,
        };
    },
    computed: {
        isValid() {
            let fildes = this.fields;
            let cardObj = this.cardObj;

            return !Object.keys(this.fields).some(
                 key => (fildes[key].required &&
                     (
                         (!cardObj.values[key] && cardObj.values[key] !== 0) &&
                         (!cardObj.values.billing_address[key] && cardObj.values.billing_address[key] !== 0)
                     )
                 ) ||  fildes[key].invalid
            )
        },
        cardCurrencies() {
            return this.$store.getters.withdrawCurrencies || [];
        },
        isNewLayout() {
            return ['native', 'soldo'].includes(this.type) && this.currencyId;
        },
        cardName() {
            return {
                native: 'Equals',
                soldo: 'Soldo',
            }[this.type] ?? '';
        },
        cardImage() {
            return {
                native: '/images/virtual-cards/nt-equals-card.svg',
                soldo: '/images/settings/soldo/soldo-card.svg',
            }[this.type] ?? '';
        },
        submitBtnDisabled() {
            return this.errors.all().length > 0 || this.cardObj.someEqualsFieldsAreEmpty();
        },
        submitBtnTitle() {
            switch (this.type) {
                case 'native':
                    return 'Save';
                default:
                    return 'Activate';
            }
        },
        isUpgrade() {
            return !this.card ? !this.__checkPermission('add_payment_method') : false;
        },
        actualTitle() {
            if (this.isNewLayout) {
                switch (this.type) {
                    case 'native':
                        return 'Add your Equals card';
                    default:
                        return `Active ${this.cardName} card`;
                }
            } else {
                return !this.card ? 'Add new card' : 'Update card';
            }
        },
    },
    created() {
        if (this.dialog && this.isUpgrade) {
            this.$store.state.upgradeDialog = true;
        }
    },
    watch: {
        value() {
            this.dialog = this.value;
            this.$validator.reset();
            if (this.value) {
                this.setObject();
            }
            if (this.dialog && this.isUpgrade){
                this.$store.state.upgradeDialog = true;
            }
        },
        dialog: {
            handler() {
                this.$emit('input', this.dialog);
            },
            immediate: true
        },
        // 'cardObj.values.primary': function (){
        //     this.cardObj.values.primary = this.cardObj.values.primary? 1: 0;
        // }
    },
    mounted() {
        this.setObject();
        this.$validator.localize('en', this.cardObj.messages);
    },
    methods: {
        setObject() {
            const defaultCurrency = this.cardCurrencies.length ? this.cardCurrencies[0].id : null;
            const obj = this.card || {
                currency_id: this.type !== 'billing' ? this.currencyId || defaultCurrency : null,
            };
            this.cardObj = new Card(obj);
            this.cardObj.values.radar_session = this.$store.state.radarSessionId;
        },
        create() {
            this.$validator.validateAll().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.cardObj.values.type = this.type
                        ? (this.cardObj.values.type = this.type)
                        : this.cardObj.values.type;
                    this.cardObj.values.default_org = this.defaultOrg ? 1 : 0;
                    this.cardObj.values.organization_id = this.organizationId;
                    this.cardObj.values.for_worker = this.workerObject!=null ? this.workerObject.id: null;
                    this.cardObj.values.primary = this.cardObj.values.primary ? 1 : 0;
                    this.cardObj
                        .create(this.$store.state.path + 'settings/billing/card-create')
                        .then(response => {
                            this.loading = false;
                            this.dialog = false;
                            // this.$emit('updateCard', response.data.cards);
                            this.$store.dispatch('stripe/getCards').then(cards => {
                                this.$emit('updateCard', cards);
                                this.$store.commit('setData', { key: 'cards', data: cards });
                                if (this.defaultOrg) {
                                    this.$store.commit('setData', { key: 'defaultCards', data: cards });
                                }
                            });
                            this.$store.commit('setAlertModal', {type: 'success', data: {title: "Card added", text: response.data.message}});
                        })
                        .catch(error => {
                            this.loading = false;
                            this.$store.commit('setAlertModal', {type: 'error', data: {title: "Card not added", text: error.response.data.message}});
                        });
                }
            });
        },
        update() {
            this.$validator.validateAll().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.cardObj.values.default_org = this.defaultOrg ? 1 : 0;
                    this.cardObj.values.type = this.type
                        ? (this.cardObj.values.type = this.type)
                        : this.cardObj.values.type;
                    this.cardObj.values.primary = this.cardObj.values.primary ? 1 : 0;
                    this.cardObj
                        .update(this.$store.state.path + 'settings/billing/card-update/' + this.card.id)
                        .then(response => {
                            this.loading = false;
                            this.dialog = false;
                            // this.$emit('updateCard', response.data.cards);
                            this.$store.dispatch('stripe/getCards').then(cards => {
                                this.$emit('updateCard', cards);
                                this.$store.commit('setData', { key: 'cards', data: cards });
                            });
                            this.$store.commit('setAlertModal', {type: 'success', data: {title: "Card added",text: response.data.message}});
                        })
                        .catch(error => {
                            this.loading = false;
                            this.$store.commit('setAlertModal', {type: 'error', data: {title: "Card not added", text: error.response.data.message}});
                        });
                }
            });
        },
        closeModal() {
            this.dialog = false;
        },
        onStripeProcess(data) {
            this.$store.dispatch('stripe/getCards').then(cards => {
                this.$emit('updateCard', cards);
                this.$store.commit('setData', { key: 'cards', data: cards });
            });
            const title = this.card?.id ? 'Card updated' : 'Card added';
            const text = this.card?.id ? 'Your card was updated successfully.' : 'Your card was added successfully.';
            this.$store.commit('setAlertModal', { type: 'success', data: { title, text } });
            this.dialog = false;
        },
    },
};
</script>
