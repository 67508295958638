/** @format */

import Vue from 'vue';

/**
 *global for HR/dashboard/admin
 */
import NewBaseSelect from '~/components/BaseElements/NewBaseSelect';
import NewBaseDatePicker from '~/components/BaseElements/NewBaseDatePicker';
import BaseFileUpload from '~/components/BaseElements/BaseFileUpload';
import BaseInput from '~/components/BaseElements/BaseInput';
import BaseYearPicker from '~/components/BaseElements/BaseYearPicker';
import BaseInputDateNew from '~/components/BaseElements/BaseInputDateNew';
import BaseMultipleUpload from '~/components/BaseElements/BaseMultipleUpload';
import BaseSelect from '~/components/BaseElements/BaseSelect';
import BaseTable from '~/components/BaseElements/BaseTable';
import BaseTab from '~/components/BaseElements/BaseTab';
import BaseTabs from '~/components/BaseElements/BaseTabs';
import BaseTablePaginator from '~/components/BaseElements/BaseTablePaginator';
import BaseUploadFiles from '~/components/BaseElements/BaseUploadFiles';
import BaseFilterTabs from '~/components/BaseElements/BaseFilterTabs';
import BaseAvatar from '~/components/BaseElements/BaseAvatar';
import BaseSwitcher from '~/components/BaseElements/BaseSwitcher';
import BaseSimpleSwitcher from '~/components/BaseElements/BaseSimpleSwitcher';
import BasePhoneInput from '~/components/BaseElements/BasePhoneInput';
import BaseStatusTag from '~/components/BaseElements/BaseStatusTag';
import BaseStatus from '~/components/BaseElements/BaseStatus';
import BaseTooltip from '~/components/BaseElements/BaseTooltip';
import BaseProgressBar from '~/components/BaseElements/BaseProgressBar';
import BaseWizardNavbar from '~/components/BaseElements/BaseWizardNavbar';
import BaseWizardNavbarResponsive from '~/components/BaseElements/BaseWizardNavbarResponsive';
import BaseWizardStep from '~/components/BaseElements/BaseWizardStep';
import BaseWizardStepper from '~/components/BaseElements/BaseWizardStepper';
import BaseWizardStepper2 from '~/components/BaseElements/BaseWizardStepper2.vue';
import BaseCarousel from '~/components/BaseElements/BaseCarousel';
import BaseFilesList from '~/components/BaseElements/BaseFilesList';
import BaseTextarea from '~/components/BaseElements/BaseTextarea';

import BaseAlert from './BaseAlert';
import BaseButton from './BaseButton';
import BaseConfirmModal from './BaseConfirmModal';
import BaseDatePicker from './BaseDatePicker';
import BaseDateRangePicker from './BaseDateRangePicker';
import BaseErrorMessage from './BaseErrorMessage';
import BaseFileInput from './BaseFileInput';
import BaseFilter from './BaseFilter';
import BaseIcon from './BaseIcon';
import BaseInfoCard from './BaseInfoCard';
import BaseInputDate from './BaseInputDate';
import BaseLazyInput from './BaseLazyInput';
import BaseImageUpload from './BaseImageUpload';
import BaseMenu from './BaseMenu';
import BaseModal from './BaseModal';
import BasePanels from './BasePanels.vue';
import BaseRadioGroup from '~/components/BaseElements/BaseRadioGroup';
import BaseSelectYear from './BaseSelectYear';
import BaseToast from './BaseToast';
import BaseToggleSwitch from './BaseToggleSwitch';
import BaseUploadedFileShower from './BaseUploadedFileShower';
import BasePanelsOnBoarding from "./BasePanelsOnBoarding";
import BaseSelectPhone from '~/components/BaseElements/BaseSelectPhone';
import BaseSelectIbanAccountNumber from '~/components/BaseElements/BaseSelectIbanAccountNumber';
import BaseFormattedInput from '~/components/BaseElements/BaseFormattedInput';
import BaseIntroScreen from "~/components/BaseElements/BaseIntroScreen";
// Register components
Vue.component('NewBaseSelect', NewBaseSelect);
Vue.component('NewBaseDatePicker', NewBaseDatePicker);
Vue.component('BaseAlert', BaseAlert);
Vue.component('BaseAvatar', BaseAvatar);
Vue.component('BaseButton', BaseButton);
Vue.component('BaseDateRangePicker', BaseDateRangePicker);
Vue.component('BaseErrorMessage', BaseErrorMessage);
Vue.component('BaseFileInput', BaseFileInput);
Vue.component('BaseFilter', BaseFilter);
Vue.component('BaseIcon', BaseIcon);
Vue.component('BaseInfoCard', BaseInfoCard);
Vue.component('BaseInput', BaseInput);
Vue.component('BaseYearPicker', BaseYearPicker);
Vue.component('BaseInputDate', BaseInputDate);
Vue.component('BaseDatePicker', BaseDatePicker);
Vue.component('BaseInputDateNew', BaseInputDateNew);
Vue.component('BaseMenu', BaseMenu);
Vue.component('BaseConfirmModal', BaseConfirmModal);
Vue.component('BaseFileUpload', BaseFileUpload);
Vue.component('BaseFilterTabs', BaseFilterTabs);
Vue.component('BaseLazyInput', BaseLazyInput);
Vue.component('BaseModal', BaseModal);
Vue.component('BaseMultipleUpload', BaseMultipleUpload);
Vue.component('BasePanels', BasePanels);
Vue.component('BaseRadioGroup', BaseRadioGroup);
Vue.component('BaseUploadFiles', BaseUploadFiles);
Vue.component('BaseFilesList', BaseFilesList);
Vue.component('BaseToast', BaseToast);
Vue.component('BaseSelect', BaseSelect);
Vue.component('BaseSelectYear', BaseSelectYear);
Vue.component('BaseStatusTag', BaseStatusTag);
Vue.component('BaseStatus', BaseStatus);
Vue.component('BaseSwitcher', BaseSwitcher);
Vue.component('BaseTab', BaseTab);
Vue.component('BaseTabs', BaseTabs);
Vue.component('BaseTablePaginator', BaseTablePaginator);
Vue.component('BaseTable', BaseTable);
Vue.component('BaseTextarea', BaseTextarea);
Vue.component('BaseToggleSwitch', BaseToggleSwitch);
Vue.component('BaseUploadedFileShower', BaseUploadedFileShower);
Vue.component('BaseWizardStepper', BaseWizardStepper);
Vue.component('BaseWizardStepper2', BaseWizardStepper2);
Vue.component('BaseWizardNavbar', BaseWizardNavbar);
Vue.component('BaseWizardNavbarResponsive', BaseWizardNavbarResponsive);
Vue.component('BaseWizardStep', BaseWizardStep);
Vue.component('BasePanelsOnBoarding', BasePanelsOnBoarding);
Vue.component('BasePhoneInput', BasePhoneInput);
Vue.component('BaseSelectPhone', BaseSelectPhone);
Vue.component('BaseSelectIbanAccountNumber', BaseSelectIbanAccountNumber);
Vue.component('BaseCarousel', BaseCarousel);
Vue.component('BaseSimpleSwitcher', BaseSimpleSwitcher);
Vue.component('BaseImageUpload', BaseImageUpload);
Vue.component('BaseTooltip', BaseTooltip);
Vue.component('BaseProgressBar', BaseProgressBar);
Vue.component('BaseFormattedInput', BaseFormattedInput);
Vue.component('BaseIntroScreen', BaseIntroScreen);
