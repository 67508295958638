<template>
    <div class="module-actions-container">
        <!-- QUICK ACTIONS -->
        <QuickActionsSection
            :quick-actions="quickActionsLegalEntity"
        />
        <!-- FEATURES -->
        <FeaturesSection
            :features="getFeatures('legalEntity', worker.plan.value)"
            :availableMenu="availableMenu"
        />
    </div>
</template>

<script>
import FeaturesSection from "~/components/NewNavigation/FeaturesSection.vue";
import QuickActionsSection from "~/components/NewNavigation/QuickActionsSection.vue";

import {mapGetters, mapState} from "vuex";
import {commonHelpersMixin} from "~/modules/wallet/mixins/commonHelpersMixin";

export default {
    name: "LegalEntity",

    mixins: [commonHelpersMixin],

    components: {QuickActionsSection, FeaturesSection},
    props:{
      worker:{
          default: null,
      },
        availableMenu:{
          default: null,
      }
    },
    computed: {
      ...mapState('globalMenuModule', ['legalEntityData']),
     ...mapGetters('dashboard',['selectedWallet']),
        ...mapGetters({
            getQuickActions: "navigation/getQuickActions",
            getFeatures: "navigation/getFeatures",
            user: "users/getUser",
        }),
      quickActionsLegalEntity(){

        let quickActions = this.getQuickActions('legalEntity',this.worker.plan.value);
        const walletID = this.selectedWallet?.id;

        return quickActions.map(item => {
          const walletActions = ['wallet/add-money', 'wallet/send-money'];
          if (walletActions.includes(item.link)) {
            item.link = `${item.link}?walletID=${walletID}`;
          }
          return item;
        });
      },
    },
};
</script>

<style lang="scss" scoped>
.module-actions-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;

    .actions-container {
        padding: 1.5rem;
        border-radius: 22px;
        background: #f2f5fa;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        .title-text {
            color: #5a5a77;
            line-height: 1.4375rem;
            margin-bottom: 1.75rem;
        }
    }

    .btn-back-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 1rem;
    }

    .btn-back {
        color: #27276c;

        &:hover::before {
            opacity: 0;
        }

        margin-right: 0.75rem;

        i {
            font-size: 1.25rem;
        }
    }

    .back-text {
        font-size: 1.125rem;
        font-weight: 700;
        color: #27276c;
    }
}
</style>
