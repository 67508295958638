<template>
    <div
        class="d-flex align-center"
        :class="[justifyCenter?'justify-content-center':null, !disabled? 'pointer': null, opacity ? opacity : null, showName? 'w-100': '']"
        @click="!disabled? $emit('click'): null"
    >
        <avatar
            :object="object"
            :size="size? size: small? 20: 30"
            :avatar="avatar"
            :name="name"
            :offset-right="showName"
        ></avatar>
        <template v-if="showName">
            <slot v-if="$scopedSlots['name']" name="name" v-bind:item="text"></slot>
            <span
                v-else
                :class="small? 'tm-medium-text': 'tm-subheader font-weight-medium'"
                style="line-height: initial;"
                :style="{color: colorTitle}"
                class="text-no-wrap black--text mb-0 mr-2"
            >{{ text }}</span>

            <v-tooltip v-if="restrictionObj" top>
                <template v-slot:activator="{ on, attrs }">
                    <div
                        v-on="on"
                        class="d-flex align-center justify-center px-2 py-1 ml-auto"
                        style="width: fit-content; border-radius: 0.3125rem;"
                        :style="{'background-color': `rgba(${restrictionObj.color}, 0.12)`, border: `1px solid rgb(${restrictionObj.color})`}"
                    >
                        <span class="tm-small-text font-weight-medium" :style="{color: `rgb(${restrictionObj.color})`}">
                            {{ restrictionObj.name }}
                        </span>
                    </div>
                </template>
                <span>{{ restrictionObj.text }}</span>
            </v-tooltip>
        </template>
    </div>
</template>
<script>
import Helpers from '~/shared/mixins/Helpers';
import Avatar from "./Avatar";
export default {
    name: 'AvatarName',
    components: {Avatar},
    props: {
        colorTitle: {
            default: '#000'
        },
        avatar: {
            default: null
        },
        name: {
            default: null
        },
        disabled: {
            default: false
        },
        opacity: {
            default: false
        },
        small: {
            default: false
        },
        size: {
            default: null
        },
        object: {
            default: null
        },
        showName: {
            default: true
        },
        justifyCenter: {
            default: false
        },
        restriction: {
            default: null
        }
    },
    data(){
        return {
            showInfo: false,
            restrictions: {
                aml: {
                    name: 'AML',
                    color: '251, 140, 0',
                    text: 'User is under AML checkup'
                },
                block:{
                    name: 'Blocklist',
                    color: '255, 82, 82',
                    text: 'User added to blocklist'
                },
                final: {
                    name: 'Final',
                    color: '255, 82, 82',
                    text: 'User is permanently rejected'
                }
            }
        }
    },
    mixins: [Helpers],
    computed: {
        text(){
            if (this.object){
                return this.object.name || this.object.email || this.object.title || this.name
            }else{
                return this.name;
            }
        },
        restrictionObj(){
            return this.restrictions[this.restriction]
        }
    }
}
</script>
