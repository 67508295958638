<template>
  <v-dialog
    :value="dialog"
    @click:outside="closeModal"
    content-class="custom-modal modal-md welcome-new-modal"
  >
    <div class="modal-container navigation-bar-wrapper" style="background: white!important;">
      <div class="modal-header">
        <h1 class="heading-1 leading-120 font-bold color-black">
          Welcome to your brand new Native Teams experience!
        </h1>
      </div>
      <div class="modal-body">
        <div class="video-container mb-28">
            <video  ref="videoPlayer" width="100%" height="100%" controls autoplay>
                <source src="https://i.nativeteams.com/assets/new-dashboard/video/welcome.mp4" type="video/mp4" >
            </video>

<!--          <iframe
            width="100%"
            height="100%"
            src="https://app.nativeteams.com/storage/video/welcome-video.mp4"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>-->
        </div>
        <p class="text-base color-dark-grey">
          Dive in and find out what our team has been working on to make your
          Native Teams experience as smooth as it can be.
        </p>
      </div>
      <div class="modal-footer">
          <WlBaseButton @click="closeModal" class="btn-primary w-full mb-3">
          Check it out
        </WlBaseButton>
      </div>
    </div>
  </v-dialog>
</template>

<script>

export default {
  name: "ModalNewNativeTeams",

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    closeModal() {
        this.$refs.videoPlayer.pause();
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.video-container {
  height: 200px;
  width: 100%;
}
#app .new-wallet-app  {

    .v-dialog .mb-28 {
        margin-bottom: 28px !important;
    }
    .v-dialog.custom-modal.modal-md {
        max-width: 500px !important;
    }

    .v-dialog.custom-modal.modal-xl {
        max-width: 1124px !important;
    }

    .modal-info {
        .modal-header {
            padding-top: 0 !important;

            i {
                width: 96px;
                height: 96px;
                display: flex;
                align-items: center;
                font-size: 80px;
            }
        }
    }

    .custom-modal.v-dialog {
        height: fit-content;
        margin: 0;
        background-color: white;
        border-radius: 30px;
        box-shadow: 0px 429px 120px 0px rgba(42, 43, 65, 0),
        0px 17px 38px 0px rgba(42, 43, 65, 0.05);
        padding: 0;
        max-height: 95% !important;
    }

    .custom-modal .modal-header {
        padding: 0;
        margin-bottom: 28px;
        display: flex;
        flex-direction: column;
        position: relative;

        .btn-icon-sm {
            // position: absolute;
            // top: 0;
            // right: 0;
            color: #0b0b34;
            margin-bottom: 12px;
        }
    }

    .custom-modal .modal-container {
        padding: 44px;
    }

    .custom-modal .modal-body {
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    .custom-modal .modal-footer {
        padding: 0;
        margin-top: 32px;
    }

    .modal-header {
        border-bottom: 0;
        display: flex;
    }

    .modal-footer {
        display: flex;
        align-items: center;
        border-top: 0;
        width: 100%;
    }

    .modal-footer > * {
        margin: 0;
    }

    .v-dialog h1, .v-dialog h2, .v-dialog h3, .v-dialog h4, .v-dialog h5, .v-dialog h6, .v-dialog p, .v-dialog p, .v-dialog span, .v-dialog button {
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        font-style: normal;
        font-variation-settings: "slnt" 0;
        padding: 0;
        margin: 0;
    }
    .leading-120{
        line-height: 120%;
    }
    .heading-1{
        font-style: normal;
        font-weight: 700;
        font-size: 2.5rem;
    }
    .btn.btn-purple{
        background-color: var(--main-purple-color) !important;
    }

    .v-btn.btn-close-modal {
        color: #000 !important;
    }

    .welcoming-container {
        .title-text {
            font-size: 20px !important;
            margin-bottom: 20px !important;
        }
    }

    .btn-close-modal {
        border: none !important;
    }

    .btn-icon-sm.btn-close-modal,
    .v-btn.btn-icon-sm.btn-close-modal,
    .v-btn:not(.v-btn--round).v-size--default.btn-icon-sm.btn-close-modal {
        width: 32px !important;
        height: 32px !important;
        padding: 0 !important;
        min-width: 32px !important;
        max-width: 32px !important;
    }

    .btn-icon-sm.btn-close-modal i,
    .v-btn.btn-icon-sm.btn-close-modal i,
    .v-btn:not(.v-btn--round).v-size--default.btn-icon-sm.btn-close-modal i {
        font-size: 24px !important;
    }

    .btn-new,
    .btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px !important;
        outline: 0;
        border: 0;
        box-shadow: none;
        background: transparent;
        cursor: pointer;
        min-width: fit-content;
        width: fit-content;

        &.btn-purple {
            background-color: var(--main-purple-color) !important;
            color: white !important;
        }

        &.v-btn--disabled {
            background: #838a90 !important;
            color: #d9d9d9 !important;
        }

        &.btn-white {
            background-color: white !important;
            color: black !important;
        }
    }

    .btn.focus,
    .btn:focus,
    .btn:hover {
        box-shadow: none;
        outline: 0 !important;
        opacity: 0.8;
    }

    .v-btn:not(.v-btn--round).v-size--default.btn-md,
    .v-btn.btn-md,
    .btn-md {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        padding: 12px 24px;
        height: 40px;
        width: 100%;
    }

    .v-btn:not(.v-btn--round).v-size--default.btn-icon-sm,
    .v-btn.btn-icon-sm,
    .btn-icon-sm {
        height: 24px !important;
        width: 24px !important;
        padding: 6px !important;
        min-width: 24px;
        max-width: 24px;
        background-color: transparent;

        &.color-white {
            &:hover {
                background-color: transparent !important;
                opacity: 1;
            }

            &:focus {
                background-color: transparent !important;
                opacity: 1;
            }

            &.color-grey-2:focus,
            &.color-grey-2:hover {
                color: black !important;
            }
        }

        &:hover {
            background-color: #f5f5f5 !important;
            opacity: 1;
        }

        &:focus {
            background-color: #e6e6e6 !important;
            opacity: 1;
        }

        &.color-grey-2:focus,
        &.color-grey-2:hover {
            color: black !important;
        }

        &:disabled {
            color: var(--typography-dark-grey-color) !important;
        }

        &.btn-close-modal {
            width: 32px !important;
            height: 32px !important;
            padding: 0 !important;
            min-width: 32px;
            max-width: 32px;

            i {
                font-size: 24px !important;
            }
        }
    }

    .btn-outline-white {
        border: 1px solid #ffffff !important;
    }

    .btn-outline-purple {
        border: 1px solid var(--main-purple-color) !important;

        &.focus {
            &::before {
                opacity: 0.08;
            }
        }
    }

    .btn-purple {
        background-color: var(--main-purple-color) !important;
        color: white !important;
    }

    .btn-outline-red {
        border: 1px solid var(--feedback-error-color) !important;
    }
}

</style>
