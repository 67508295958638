<!-- @format -->

<template>
    <div>
        <div class="mb-30">
            <h5 class="text-center heading-1 bold-text">Payment</h5>
            <p class="text-center medium-text color-dark-grey-6 lh-120"> Review your payment and add money </p>
        </div>
        <div class="card-group rounded-5 mb-30">
            <div class="card p-0 mb-0">
                <div class="card-body pl-20 pr-20 pt-20 pb-10">
                    <ul class="px-3 pt-10">
                        <li class="d-flex justify-content-between mb-20">
                            <p class="medium-text color-dark-grey-2"> You request </p>
                            <p class="medium-text bold-text">
                                {{ formatAmount(obj.values.amount, wallet.currency.code, 2, 2) }}
                            </p>
                        </li>
                        <li class="d-flex justify-content-between mb-20">
                            <p class="medium-text color-dark-grey-2">Total fees</p>
                            <p class="medium-text color-dark-grey">
                                {{ formatAmount(fee, wallet.currency.code, 2, 2) }}
                            </p>
                        </li>
                        <li class="d-flex justify-content-between mb-20">
                            <p class="medium-text color-dark-grey-2">You get</p>
                            <p class="medium-text bold-text">
                                {{ formatAmount(result, wallet.currency.code, 2, 2) }}
                            </p>
                        </li>
                        <li v-if="option === 'bank'" class="d-flex justify-content-between align-items-center mb-20">
                            <div>
                                <p class="medium-text color-dark-grey-2"> Payment option </p>
                            </div>

                            <div class="medium-text d-flex align-center tm-initial">
                                Bank Account
                                <i class="icon-bank-outline color-dark-blue ml-1 fs-20"></i>
                            </div>
                        </li>
                        <li
                            v-else-if="option === 'card'"
                            class="d-flex justify-content-between align-items-center mb-20"
                        >
                            <div>
                                <p class="medium-text color-dark-grey-2"> Payment option </p>
                            </div>

                            <div v-if="card" class="medium-text d-flex align-center tm-initial">
                                **** **** **** {{ card.last4 }}
                                <img
                                    :src="$store.state.imagePath + card.brand_logo"
                                    :alt="card.brand"
                                    class="selected-card-brand-img"
                                />
                            </div>
                            <div v-else-if="'NGN' === wallet.currency.code">
                                <p>Card Payment</p>
                            </div>
                        </li>
                        <li
                            v-else-if="option === 'paypal'"
                            class="d-flex justify-content-between align-items-center mb-20"
                        >
                            <div>
                                <p class="medium-text color-dark-grey-2"> Payment option </p>
                            </div>

                            <div class="medium-text d-flex align-center tm-initial">
                                Paypal
                                <i class="icon-paypal fs-20 ml-2"></i>
                            </div>
                        </li>

                        <template v-else-if="option === 'payment_request'">
                            <li class="d-flex justify-content-between align-items-center mb-20">
                                <div>
                                    <p class="medium-text color-dark-grey-2">Payer</p>
                                </div>

                                <div class="small-text d-flex align-center tm-initial bold-text">
                                    <span class="mr-2">{{ payer.name }}</span>
                                    <avatar :object="payer" size="1.5625rem" :offset-right="false"></avatar>
                                </div>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-20">
                                <div>
                                    <p class="medium-text color-dark-grey-2"> Payer email </p>
                                </div>

                                <div class="small-text tm-initial bold-text">
                                    {{ payer.email }}
                                </div>
                            </li>
                        </template>

                        <li
                            v-else-if="'payoneer' === option"
                            class="d-flex justify-content-between align-items-center mb-20"
                        >
                            <div>
                                <p class="medium-text color-dark-grey-2">
                                    Payoneer will charge your current set fee at {{ payoneerFeePercent }}% for the
                                    transfer
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="'payment_request' === option" class="transfer-amount-footer bg-light-blue">
                    You will have
                    <span class="color-purple">
                        {{ formatAmountNoCurrency(parseFloat(wallet.available_balance) + result, 2, 2) }}
                        {{ wallet.currency.code }}
                    </span>
                    available in your wallet after your recipient confirms the payment.
                </div>
                <div v-else class="transfer-amount-footer bg-light-blue">
                    You will have
                    <span class="color-purple">
                        {{ formatAmountNoCurrency(parseFloat(wallet.available_balance) + result, 2, 2) }}
                        {{ wallet.currency.code }}
                    </span>
                    available in your wallet after the payment.
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Helpers from '~/shared/mixins/Helpers';
import Avatar from '~/shared/components/Avatar';
import { mapGetters } from 'vuex';

export default {
    name: 'SubmitPayment',

    components: { Avatar },

    mixins: [Helpers],

    props: {
        option: {
            required: true,
            type: String,
        },
        wallet: {
            required: true,
            type: Object,
        },
        obj: {
            required: true,
            type: Object,
        },
        fee: {
            required: true,
            type: Number,
        },
        result: {
            required: true,
            type: Number,
        },
    },

    computed: {
        ...mapGetters({
            'cards': 'stripe/cards',
        }),
        arrive() {
            switch (this.option) {
                case 'bank':
                    return 'within 72 hours';
                case 'card':
                    return 'In seconds';
                case 'paypal':
                    return 'within 72 hours';
                case 'payment_request':
                    return '1 day after the payment';
                case 'payoneer':
                    return 'Immediate transfer.';
                default:
                    return null;
            }
        },
        card() {
            return this.obj.values.card.card_id
                ? this.cards.find(c => c.id === this.obj.values.card.card_id)
                : null;
        },
        payer() {
            return (
                this.obj.values.payer || {
                    name: this.obj.values.payment_request.name,
                    email: this.obj.values.payment_request.email,
                }
            );
        },
        payoneerFeePercent() {
            if (!this.fee) {
                return 0;
            }

            return parseFloat(((this.fee / this.obj.values.amount) * 100).toFixed(1));
        },
    },
};
</script>
