import VueCookie from 'vue-cookie';

export default class Tracking {

    static cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN ?? false;

    static utmParamNames = [
        'utm_campaign',
        'utm_term',
        'utm_medium',
        'utm_source',
        'utm_content',
    ];

    static paramNames = [
        'url_first_visit',
        'first_referring_site',
    ];

    static getCurrentUrl() {
        return window.location.href;
    }

    static getUrlFirstVisitCookie() {
        return Tracking.getCookie('url_first_visit');
    }

    static setUrlFirstVisitToCurrentUrl() {
        Tracking.setCookie('url_first_visit', Tracking.getCurrentUrl());
    }

    static setCookie(name, value) {
        const params = { expires: '1M' };
        if (Tracking.cookieDomain) {
            params.domain = Tracking.cookieDomain;
        }
        VueCookie.set(name, value, params);
    }

    static deleteCookie(name) {
        if (Tracking.cookieDomain) {
            VueCookie.delete(name, { domain: Tracking.cookieDomain });
        } else {
            VueCookie.delete(name);
        }
    }

    static getCookie(name) {
        return VueCookie.get(name);
    }

    static getTrackingData() {
        const trackingData = {};
        Tracking.utmParamNames.forEach(name => trackingData[name] = this.getCookie(name));
        Tracking.paramNames.forEach(name => trackingData[name] = this.getCookie(name));
        trackingData.url_registered_from = Tracking.getCurrentUrl();

        return trackingData;
    }
}
