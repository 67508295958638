<template>
    <modal
        :value="dialog"
        @input="!$event? close(): null"
        :width="widthDialog"
        class-names="modal-id-verification vue-repo-app"
        :padding="false"
        :close-icon-show="false"
    >
        <template v-if="dialog" slot="content">

            <!-- Start -->
            <verification-start
                v-if="onStart"
                @start="start()"
                @close="close()"
            ></verification-start>

            <!-- Verifying -->
            <div v-else-if="onProgress" class="modal-content">
                <div class="modal-header">
                    <button @click="close()" class="btn btn-icon btn-p-0 color-dark-grey small-text ml-auto">
                        <i class="icon-times"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <!-- Steps 1 -->
                    <sum-sub
                        v-if="step === 1"
                        @continue="checkComplete()"
                        :loading-val="loading"
                        :recipient-model="recipientModel"
                    ></sum-sub>
                </div>
            </div>

            <!-- Info -->
            <template v-else-if="onInfo">

                <!-- Success -->
                <verification-success
                    v-if="success"
                    @toDashboard="goToDashboard"
                    @close="close()"
                ></verification-success>

<!--                &lt;!&ndash; Rejected &ndash;&gt;-->
<!--                <verification-rejected-->
<!--                    v-else-if="isRejected"-->
<!--                    @start="start()"-->
<!--                    @close="dialog = false"-->
<!--                ></verification-rejected>-->

                <!-- Pending -->
                <verification-pending
                    v-else-if="isPending"
                    @close="close()"
                ></verification-pending>

                <!-- Empty -->
                <verification-empty v-else></verification-empty>
            </template>

            <!-- Empty -->
            <verification-empty v-else></verification-empty>
        </template>
    </modal>
</template>
<script>

import Helpers from "~/shared/mixins/Helpers";
import Modal from "~/shared/components/Modal";
import VerificationPending from "./parts/VerificationPending";
import VerificationSuccess from "./parts/VerificationSuccess";
import VerificationStart from "./parts/VerificationStart";
import SumSub from "./steps/SumSub";
import VerificationRejected from "./parts/VerificationRejected.vue";
import VerificationEmpty from "./parts/VerificationEmpty.vue";
import {mapActions, mapState} from "vuex";
export default {
    name: 'UserVerified',
    components: {
        VerificationEmpty,
        SumSub,
        VerificationStart,
        VerificationSuccess,
        VerificationPending,
        VerificationRejected,
        Modal
    },
    mixins: [Helpers],
    props: {
        recipientModel: {
            type: Object,
            default: null,
        },
    },
    data(){
        return {
            loading: false,
            step: 0,
            success: false,
        }
    },
    computed: {
        ...mapState('kyc', {
            verified: 'verified',
            dialog: 'dialog',
            options: 'options',
            modalOptions: 'modalOptions',
        }),
        onStart(){
            return !this.isPending /*&& !this.isRejected*/ && !this.success && !this.step;
        },
        onProgress(){
            return !this.onStart && this.step;
        },
        onInfo(){
            return this.isPending /*|| this.isRejected*/ || this.success;
        },
        widthDialog() {
            if (this.onStart){
                return '31.25rem';
            }

            if (this.onProgress){
                return '61.25rem';
            }

            return '25.0625rem';
        },
        userVerified() {
            return this.state ? this.state.verified : this.$store.state.kyc.verified;
        },
        isPending() {
            return this.options.status === 'pending' ||
                this.options?.temporary?.status === 'pending' ||
                this.options?.sumsub_status === 'on_hold';
        },
        isDemo(){
            return this.options.type === 'demo' ||
                this.options.rejected?.type === 'demo'
        },
        // isRejected(){
        //     return !this.isDemo && this.options.rejected?.type !== 'demo';
        // },
    },
    watch: {
        dialog() {
            this.step = 0;
            this.success = false;
        },
    },
    methods: {
        ...mapActions('kyc', ['__closeDialog', '__setKYCData']),
        checkComplete(){
            this.loading = true;
            axios.get(this.$store.state.path + 'verification/check-complete')
                .then(response => {
                    this.makeCompleted(response.data);
                    this.loading = false;
                })
                .catch(error => {
                    this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                    this.loading = false;
                })
        },
        start() {
            this.step = 1;
        },
        makeCompleted(data){
            this.step = 0;
            this.success = true;
            const tData = {
                verified: data.verified,
                options: data.verificationOptions,
                restrictionType: data.restrictionType
            }
            this.__setKYCData(tData);
        },
        goToDashboard() {
            this.__changeRoute(this.$store.state.path)
        },
        close(){
            this.__closeDialog();
        }
    },
    mounted() {

    },
}
</script>
<style>
.preloader-verify {
    top: 0;
    left: 0;
    background-color: #333333;
    background-image: url(_/public/images/graphics/logo-animation.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 7rem!important;
    height: 100%;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    z-index: 99999;
}
.embed-height {
    width: 100%;
    height: 100%;
    min-height: 33.75rem;
}
</style>
