<template>
    <div v-if="customImg"
         :style="{
          'background-color': !avatarSrc? generateColor: null,
          'border-color': border? !avatarSrc? generateColor: null: null
          }"
         :class="[
             !avatarSrc ? 'v-avatar' : '',
             offsetRight? 'mr-2': null, elevation? 'elevation-4': null, contentImgClass && !avatarSrc ? contentImgClass : ''
             ]"
         @click="$emit('click')"
    >
        <img v-if="avatarSrc" :class="contentImgClass" :src="avatarSrc"/>
        <span v-else class="white--text text-uppercase" :style="{'font-size': fontSize}">{{ initials }}</span>
    </div>
    <v-avatar
        v-else
        @click="$emit('click')"
        :rounded="rounded"
        :color="color? color: !avatarSrc? generateColor: null"
        :size="!width && !height? size: null"
        :width="width? width: height? '100%': null"
        :height="height? height: width? '100%': null"
        :class="[offsetRight? 'mr-2': null, offsetBottom? 'mb-05': null, elevation? 'elevation-4': null, contentImgClass && !avatarSrc ? contentImgClass : '' ]"
    >
        <v-img v-if="avatarSrc" @error="imgError = true" :lazy-src="imgErrorSrc" :class="contentImgClass"
               :contain="contain" :src="avatarSrc"></v-img>
        <span v-else class="white--text text-uppercase" :style="{'font-size': fontSize}">{{ initials }}</span>
    </v-avatar>
</template>
<script>
export default {
    name: 'BaseAvatar',
    props: {
        customFontSize: {
            default: false
        },
        contentImgClass: {
            default: ''
        },
        avatar: {
            default: null
        },
        customImg: {
            default: false
        },
        name: {
            default: null
        },
        color: {
            default: null
        },
        elevation: {
            default: false
        },
        contain: {
            default: false
        },
        size: {
            default: 30
        },
        offsetRight: {
            default: true
        },
        offsetBottom: {
            default: false
        },
        rounded: {
            default: false
        },
        object: {
            default: null
        },
        width: {
            default: null
        },
        height: {
            default: null
        },
        border: {
            default: true
        }
    },
    data() {
        return {
            imgError: false,
            imgErrorSrc: this.$store.state.wlstyle?.st_logo_monwhite,
            defaultColor: '#7B68EE',
            colors: {
                a: '#154570',
                b: '#FF7F50',
                c: '#531f0d',
                d: '#2F4F4F',
                e: '#00BFFF',
                f: '#228B22',
                g: '#053705',
                h: '#B22222',

                i: '#154570',
                j: '#FF7F50',
                k: '#531f0d',
                l: '#2F4F4F',
                m: '#00BFFF',
                n: '#228B22',
                o: '#042804',
                p: '#B22222',

                q: '#154570',
                r: '#FF7F50',
                s: '#531f0d',
                t: '#2F4F4F',
                u: '#00BFFF',
                v: '#228B22',
                w: '#054e05',
                x: '#B22222',

                y: "#aea89a",
                z: "#708090"
            }
        }
    },
    computed: {
        initials() {
            let string = null;
            if (this.object) {
                string = this.object.name || this.object.email || this.object.title || null
            } else {
                string = this.name;
            }

            if (!string) {
                string = 'Team Member';
            }

            let split = string.split(' ');
            return split.length > 1 ? split[0].charAt(0) + split[1].charAt(0) : split[0].charAt(0) + split[0].charAt(1);
        },
        generateColor() {
            const key = this.initials && this.initials[0].toLowerCase() || false;
            if (key !== false) {
                return this.colors[key] || this.defaultColor;
            }
            return this.color || this.darkColor() /*'#'+Math.floor(Math.random()*16777215).toString(16);*/
        },
        fontSize() {
            if (this.customFontSize) {
                return this.customFontSize;
            }
            let size = !Number.isInteger(this.size) && ~this.size.search('rem') ? parseInt(this.size) * 16 : this.size;
            return Number(size > 100) ? '-webkit-xxx-large' : Number(size < 30) ? 'xx-small' : null;
        },
        avatarSrc() {
            if (this.imgError) {
                return this.imgErrorSrc;
            }
            if (this.object) {
                return this.object.avatar || this.object.logo || this.object.logo_path || this.object.flag || this.object.icon || null
            } else {
                return this.avatar
            }
        }
    },
    methods: {
        darkColor() {
            let hex = Math.floor(Math.random() * 16777215).toString(16);
            if (hex.length < 6) {
                hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
            }
            let lum = 0;

            // convert to decimal and change luminosity
            let rgb = "#", c, i;
            for (i = 0; i < 3; i++) {
                c = parseInt(hex.substr(i * 2, 2), 16);
                c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
                rgb += ("00" + c).substr(c.length);
            }

            return rgb;
        }
    }
}
</script>
