<template>
  <modal
    :value="dialog"
    @input="$emit('input', $event)"
    content-class="modal-xxl"
    class-names=" vue-repo-app"
    width="100%"
  >
    <div class="modal-container custom-modal modal-preview-file">
      <div class="modal-header d-flex mb-5">
        <h2 class="heading-2 font-medium color-black">
          Preview {{ file.name }}
        </h2>
        <BaseButton class="btn-icon text-lg color-grey-2" @click="closeModal">
          <BaseIcon name="times" />
        </BaseButton>
      </div>
      <div class="modal-body">
        <div class="preview-file-box">
          <img
            v-if="isImage"
            :src="src"
            :alt="file.name"
            style="width: 100%"
          />
          <object v-else-if="isPdf" :data="src"
            type="application/pdf"
            style="width: 100%; height: 90vh;"
            border="0"
          ></object>
          <template v-else>
            No preview for {{file.type ? file.type : file.name}}
          </template>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "~/shared/components/Modal";

export default {
  name: "ModalPreviewFile",

  components: {
    Modal,
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    file: {
      type: File | Object,
      default: () => {},
    },
  },

  computed: {
    src() {
      return this.file instanceof File ? URL.createObjectURL(this.file) : null;
    },
    isImage() {
      return this.file instanceof File ? this.file?.type.startsWith('image') : false;
    },
    isPdf() {
      return this.file instanceof File ? this.file?.type.startsWith('application/pdf') : false;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
  },
};
</script>
