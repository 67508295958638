/** @format */

import { format, parseISO } from 'date-fns';
import moment from 'moment-timezone';
import { serialize } from 'object-to-formdata';

import { getSelectedMonthDates } from './absenceHelper';

export const namespaced = true;

export const state = {
    path: '/absence',
    employee: null,
    settings_config: {
        public: false,
        annual: false,
    },
    errors_data: null,
    from_super_admin: false,
    loading: false,
    accrued: false,
    saved: false,
    is_admin: false,
    organization_id: null,
    minDate: '2022-01',
    maxDate: moment().add(1, 'year').format('YYYY-12'),
    calendarDates: [],
    selectedMonth: null,
    calendarDatesData: {},
    pendingItems: [],
    upcomingItems: [],
    absenceTypes: null,
    countries: null,
    calendarDayEvents: {},
    members: [],
    employees_members: [],
    absences: [],
    workingDays: 0,
    monthEvents: [],
    workerDetails: {},
    workerDetailsDate: {},
    preview_event: null,
    filteredAbsence: [],
    selectedCalendarEvent: {
        date: format(new Date(), 'yyyy-MM-dd'),
    },

    eventsPerDate: [],

    publicHolidaysOptions: [],

    today: new Date(),

    partDayOptions: [
        { text: 'Morning', value: 0 },
        { text: 'Afternoon', value: 1 },
    ],
    partEndDayOptions: [
        { text: 'All day', value: 0 },
        { text: 'Afternoon', value: 1 },
    ],
    dayNames: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    organization: {},
};

export const mutations = {
    SET_DATA(state, params) {
        state[params.key] = params.value;
    },

    SET_CALENDAR_DATES(state, dates) {
        state.calendarDates = dates;
    },

    SET_CALENDAR_DAY_EVENTS_DATES(state, data) {
        state.calendarDayEvents = { ...state.calendarDayEvents, [data.key]: data.value || null };
    },

    SET_CALENDAR_DATES_DATA(state, events) {
        state.calendarDatesData = events;
    },
};

export const actions = {
    setPath({ commit }, value) {
        commit('SET_DATA', { key: 'path', value: `${value}absence` });
    },
    setEmployee({ commit }, value) {
        commit('SET_DATA', { key: 'employee', value });
    },
    setPreviewEvent({ commit }, value) {
        if(!value.date) {
            value.date = value.start;
        }
        commit('SET_DATA', { key: 'preview_event', value });
    },
    setSelectedCalendar ({ commit }, value)  {
        commit('SET_DATA', { key: 'selectedCalendarEvent', value });
    },
    setSettings({ commit }) {
        axios
            .post(`${state.path}/get/settings`, {
                organization_id: state.organization_id,
            })
            .then(({ data }) => {
                if(false === data?.success) {
                    return;
                }
                commit('SET_DATA', { key: 'settings_config', value: data });
            });
    },
    setLoading({ commit }, value) {
        commit('SET_DATA', { key: 'loading', value });
    },
    setIsAdmin({ commit }, value = false) {
        commit('SET_DATA', { key: 'is_admin', value });
    },

    setFromSA({ commit }, value = false) {
        commit('SET_DATA', { key: 'from_super_admin', value });
    },

    setSelectedMonth({state, commit }, value) {
        commit('SET_DATA', { key: 'selectedMonth', value });
        if(state.absenceTypes) {
            const year = format(value, 'yyyy');
            const absenceTypes = Object.values(state.absenceTypes);
            absenceTypes.map(i => {
                if('public' === i.value) {
                    i.name= 'public holiday '+year;
                }
                return i;
            });
            commit('SET_DATA', { key: 'absenceTypes', value: absenceTypes });
        }
    },

    setOrganization({ state, commit }) {
        axios.post(`${state.path}/get/organization`, { organization_id: state.organization_id }).then(({ data }) => {
            if(false === data?.success) {
                return;
            }
            commit('SET_DATA', { key: 'organization', value:  data });
        });
    },

    setOrganizationId({ commit }, value) {
        commit('SET_DATA', { key: 'organization_id', value });
    },

    setAbsenceTypes({ state, commit }) {
        axios.post(`${state.path}/types`, { organization_id: state.organization_id }).then(({ data }) => {
            if(false === data?.success) {
                return;
            }
            const year = format(state.selectedMonth, 'yyyy');
            const value = Object.values(data);
            value.map(i => {
                if('public' === i.value) {
                    i.name+= ' '+year;
                }
                return i;
            });
            commit('SET_DATA', { key: 'absenceTypes', value });
        });
    },

    setWorkerDetails({ state, commit }, params = {}) {
        if (state.employee) {
            params.member = state.employee;
        }
        params.year = format(state.selectedMonth, 'yyyy');
        axios
            .post(`${state.path}/get/worker`, {
                organization_id: state.organization_id,
                ...params,
            })
            .then(({ data }) => {
                if(false === data?.success) {
                    return;
                }
                commit('SET_DATA', { key: 'workerDetails', value: data });
                commit('SET_DATA', { key: 'accrued', value: false });
            });
    },

    setWorkerDetailsDate({ state, commit }, params = {}) {
        if (state.employee) {
            params.member = state.employee;
        }
        params.start_date = format(parseISO(params.start || params.start_date), 'yyyy-01-01');
        params.end_date = format(parseISO(params.end || params.end_date), 'yyyy-12-31');
        axios
            .post(`${state.path}/get/worker`, {
                organization_id: state.organization_id,
                ...params,
            })
            .then(({ data }) => {
                if(false === data?.success) {
                    return;
                }
                commit('SET_DATA', { key: 'workerDetailsDate', value: data });
            });
    },

    setCountries({ state, commit }) {
        axios.post(`${state.path}/get/countries`, { organization_id: state.organization_id }).then(({ data }) => {
            if(false === data?.success) {
                return;
            }
            commit('SET_DATA', { key: 'countries', value: Object.values(data) });
        });
    },

    setMembers({ state, commit }, country_id = null) {
        axios
            .get(`${state.path}/members`, { params: { organization_id: state.organization_id, country_id } })
            .then(({ data }) => {
                if(false === data?.success) {
                    return;
                }
                commit('SET_DATA', { key: 'members', value: Object.values(data) });
            });
    },
    searchMembers({ state, commit }, params = {}) {
        params.organization_id = state.organization_id;

        axios
            .get(`${state.path}/members`, { params })
            .then(({ data }) => {
                if(false === data?.success) {
                    return;
                }
                commit('SET_DATA', { key: 'employees_members', value: Object.values(data) });
            });
    },
    setPublicHolidaysOptions({ state, commit }, params) {
        params.organization_id = state.organization_id;
        axios.post(`${state.path}/public/holidays`, params).then(({ data }) => {
            if(false === data?.success) {
                return;
            }
            commit('SET_DATA', { key: 'publicHolidaysOptions', value: Object.values(data) });
        });
    },

    setMonthEvents({ commit, state, getters }) {
        axios
            .post(`${state.path}/get/month-data/all`, {
                organization_id: state.organization_id,
                ...getters.getDateInterval,
                member: state.employee || null,
            })
            .then(({ data }) => {
                if(false === data?.success) {
                    return;
                }
                commit('SET_DATA', { key: 'monthEvents', value: Object.values(data) });
            });
    },

    updateAutoDeduct({ state }) {
        axios
            .post(`${state.path}/update-auto-deduct`)
            .then(() => {});
    },
    setCalendarDatesData({ commit, state, dispatch, getters }) {
        if (!state.is_admin) {
            dispatch('setWorkerDetails');
            dispatch('setMonthEvents');
        }
        axios
            .post(`${state.path}/get/month-data`, {
                organization_id: state.organization_id,
                ...getters.getDateInterval,
            })
            .then(({ data }) => {
                if(false === data?.success) {
                    return;
                }
                commit('SET_CALENDAR_DATES_DATA', data);
            });
    },

    setCalendarDayEvents({ commit, state, dispatch }, { type, date, key }) {
        dispatch('setLoading', true);
        const typeObj = state.absenceTypes.find(i => i.value === type);
        let path = '/get/events';
        if ('public' === type) {
            path = '/get/public/events';
        }
        commit('SET_DATA', { key: 'calendarDayEvents', value: null });
        axios
            .post(`${state.path}${path}`, {
                organization_id: state.organization_id,
                type: typeObj?.id,
                date,
            })
            .then(({ data }) => {
                if(false === data?.success) {
                    commit('SET_DATA', { key: 'calendarDayEvents', value: null });
                    commit(
                        'setAlert',
                        { type: 'error', message: data.error || data.message },
                        { root: true },
                    );
                    return;
                }
                commit('SET_DATA', { key: 'calendarDayEvents', value: Object.values(data) });
            })
            .catch(r => {
                commit('SET_DATA', { key: 'calendarDayEvents', value: null });
                commit(
                    'setAlert',
                    { type: 'error', message: r.response.data.error || r.response.data.message },
                    { root: true },
                );
            })
            .finally(() => {
                dispatch('setLoading', false);
            });
    },

    setCalendarDates({ commit, state }) {
        const dates = getSelectedMonthDates(state.selectedMonth);
        commit('SET_CALENDAR_DATES', dates);
    },

    saveSettings({ commit, state, dispatch }, params) {
        dispatch('setLoading', true);
        params.organization_id = state.organization_id;
        axios
            .post(`${state.path}/save/settings`, serialize(params))
            .then(response => {
                if(false === response.data?.success) {
                    commit(
                        'setAlert',
                        { type: 'error', message: response.data.error || response.data.message },
                        { root: true },
                    );
                    return;
                }
                dispatch('setSaved', true);
                dispatch('setOrganization');
                commit('setAlert', { type: 'info', message: response.data.message }, { root: true });
            })
            .catch(r => {
                commit(
                    'setAlert',
                    { type: 'error', message: r.response.data.error || r.response.data.message },
                    { root: true },
                );
            })
            .finally(() => {
                dispatch('setLoading', false);
            });
    },
    accept({ commit, state, dispatch }, params) {
        dispatch('setLoading', true);
        dispatch('setSaved', false);
        params.organization_id = state.organization_id;
        axios
            .post(`${state.path}/accept`, serialize(params))
            .then(response => {
                if(false === response.data?.success) {
                    commit(
                        'setAlert',
                        { type: 'error', message: response.data.error || response.data.message },
                        { root: true },
                    );
                    return;
                }
                dispatch('setSaved', true);
                commit('setAlert', { type: 'info', message: response.data.message }, { root: true });
                if (state.is_admin) {
                    dispatch('setCalendarDatesData');
                } else {
                    dispatch('setWorkerDetails');
                    dispatch('setMonthEvents');
                }
            })
            .catch(r => {
                commit(
                    'setAlert',
                    { type: 'error', message: r.response.data.error || r.response.data.message },
                    { root: true },
                );
            })
            .finally(() => {
                dispatch('setLoading', false);
            });
    },
    store({ commit, state, dispatch }, params) {
        return new Promise((resolve, reject) => {
            dispatch('setLoading', true);
            dispatch('setSaved', false);
            commit('SET_DATA', { key: 'errors_data', value: null });
            params.organization_id = state.organization_id;
            if (!params.member && state.employee) {
                params.member = state.employee;
            }

            axios
                .post(`${state.path}/store`, serialize(params))
                .then(response => {
                    if(false === response.data?.success) {
                        const errors = response?.data?.error || response?.data?.message;
                        if ('object' === typeof errors) {
                            commit('SET_DATA', { key: 'errors_data', value: errors });
                        } else {
                            commit(
                                'setAlert',
                                { type: 'error', message: response.data.error || response.data.message },
                                { root: true },
                            );
                        }
                        return;
                    }
                    dispatch('setSaved', true);
                    commit('setAlert', { type: 'info', message: response.data.message }, { root: true });
                    if (state.is_admin) {
                        dispatch('setCalendarDatesData');
                    } else {
                        dispatch('setWorkerDetails');
                        dispatch('setMonthEvents');
                    }
                    resolve(response);
                })
                .catch(r => {
                    const errors = r?.response?.data?.error || r?.response?.data?.message;
                    if ('object' === typeof errors) {
                        commit('SET_DATA', { key: 'errors_data', value: errors });
                    } else {
                        commit(
                            'setAlert',
                            { type: 'error', message: r.response.data.error || r.response.data.message },
                            { root: true },
                        );
                    }
                    reject(r);
                })
                .finally(() => {
                    dispatch('setLoading', false);
                });
        });
    },
    accrue({ commit, state, dispatch }, params) {
        dispatch('setLoading', true);
        commit('SET_DATA', { key: 'accrued', value: false });
        params.organization_id = state.organization_id;
        if (state.employee) {
            params.member = state.employee;
        }
        axios
            .post(`${state.path}/accrue`, serialize(params))
            .then(response => {
                if(false === response.data?.success) {
                    commit(
                        'setAlert',
                        { type: 'error', message: response.data.error || response.data.message },
                        { root: true },
                    );
                    return;
                }
                commit('SET_DATA', { key: 'accrued', value: true });
                commit('setAlert', { type: 'info', message: response.data.message }, { root: true });
                if (state.is_admin) {
                    dispatch('setCalendarDatesData');
                } else {
                    dispatch('setWorkerDetails');
                    dispatch('setMonthEvents');
                }
            })
            .catch(r => {
                commit(
                    'setAlert',
                    { type: 'error', message: r.response.data.error || r.response.data.message },
                    { root: true },
                );
            })
            .finally(() => {
                dispatch('setLoading', false);
            });
    },

    destroy({commit, state, dispatch}, id) {
        return new Promise((resolve, reject) => {
            dispatch('setLoading', true);
            dispatch('setSaved', false);
            const params = {};
            if (state.employee) {
                params.member = state.employee;
            }
            params.organization_id = state.organization_id;
            axios
                .delete(`${state.path}/destroy/${id}`, {params})
                .then(response => {
                    if(false === response.data?.success) {
                        commit(
                            'setAlert',
                            { type: 'error', message: response.data.error || response.data.message },
                            { root: true },
                        );
                        return;
                    }
                    dispatch('setSaved', true);
                    commit('setAlert', {type: 'info', message: response.data.message}, {root: true});
                    dispatch('setCalendarDatesData');
                    resolve(response);
                })
                .catch(r => {
                    commit(
                        'setAlert',
                        {type: 'error', message: r.response.data.error || r.response.data.message},
                        {root: true},
                    );
                    reject(r);

                })
                .finally(() => {
                    dispatch('setLoading', false);
                });
        });
    },

    setUpcomingItems({ commit, state, dispatch, getters }, val) {
        dispatch('setLoading', true);
        const types = ['annual', 'sick', 'accrue'];
        let type = types[val] || 'annual';
        let accrue = 0;
        if ('accrue' === type) {
            type = 'annual';
            accrue = 1;
        }
        const params = { accrue, type, organization_id: state.organization_id, ...getters.getDateInterval };
        commit('SET_DATA', { key: 'upcomingItems', value: [] });

        axios
            .post(`${state.path}/get/upcoming`, serialize(params))
            .then(response => {
                if(false === response.data?.success) {
                    return;
                }
                commit('SET_DATA', { key: 'upcomingItems', value: Object.values(response.data) });
            })
            .finally(() => {
                dispatch('setLoading', false);
            });
    },

    setWorkingDays({ commit, state, dispatch }, params) {
        axios
            .post(`${state.path}/get/working-days`, {
                member: params.worker || state.employee || null,
                start_date: params.start,
                end_date: params.end,
                organization_id: state.organization_id,
            })
            .then(response => {
                if(false === response.data?.success) {
                    return;
                }
                commit('SET_DATA', { key: 'workingDays', value: parseInt(response.data) || 0 });
            })
            .finally(() => {
                dispatch('setLoading', false);
            });
    },

    setAbsences({ commit, state, dispatch }, params) {
        dispatch('setLoading', true);
        commit('SET_DATA', { key: 'absences', value: [] });
        dispatch('setWorkerDetailsDate', params);
        dispatch('setWorkingDays', params);
        axios
            .post(`${state.path}/get/absences`, {
                member: params.member || null,
                start_date: params.start,
                end_date: params.end,
                organization_id: state.organization_id,
            })
            .then(response => {
                if(false === response.data?.success) {
                    return;
                }
                commit('SET_DATA', { key: 'absences', value: Object.values(response.data) });
            })
            .finally(() => {
                dispatch('setLoading', false);
            });
    },
    setFilteredAbsence({ commit, state, dispatch }, type) {
        dispatch('setLoading', true);
        const is_pending = 'pending' === type ? 1 : 0;

        commit('SET_DATA', {
            key: 'filteredAbsence',
            value: [{ title: null }, { type: null }, { title: null }, { title: null }],
        });
        axios
            .post(`${state.path}/get/absences/employee`, {
                organization_id: state.organization_id,
                is_pending,
                member: state.employee || null,
                type: ['upcoming', 'pending'].includes(type) ? '' : type,
            })
            .then(response => {
                if(false === response.data?.success) {
                    return;
                }
                if (is_pending) {
                    commit('SET_DATA', { key: 'monthEvents', value: Object.values(response.data) });
                    commit('SET_DATA', { key: 'filteredAbsence', value: Object.values(response.data) });
                } else {
                    commit('SET_DATA', { key: 'filteredAbsence', value: Object.values(response.data) });
                }
            })
            .finally(() => {
                dispatch('setLoading', false);
            });
    },

    setPendingItems({ commit, state, dispatch, getters }, val) {
        dispatch('setLoading', true);
        const types = ['annual', 'sick', 'accrue'];
        let type = types[val] || 'annual';
        let accrue = 0;
        if ('accrue' === type) {
            type = 'annual';
            accrue = 1;
        }
        const params = {
            ...getters.getDateInterval,
            accrue,
            type,
            organization_id: state.organization_id,
        };
        commit('SET_DATA', { key: 'pendingItems', value: [] });

        axios
            .post(`${state.path}/get/pending`, serialize(params))
            .then(response => {
                if(false === response.data?.success) {
                    return;
                }
                commit('SET_DATA', { key: 'pendingItems', value: Object.values(response.data) });
            })
            .finally(() => {
                dispatch('setLoading', false);
            });
    },

    setSaved({ commit }, value) {
        commit('SET_DATA', { key: 'saved', value });
    },
};

export const getters = {
    dayNames: state => state.dayNames,

    is_admin: state => state.is_admin,

    from_super_admin: state => state.from_super_admin,

    path: state => state.path,

    preview_event: state => state.preview_event,

    errors_data: state => state.errors_data,

    employees_members: state => state.employees_members,

    accrued: state => state.accrued,

    settings_config: state => state.settings_config,
    filterTabs() {
        if (1 === state.organization_id) {
            return [
                {
                    title: 'Overview',
                    filter: 'today',
                },
                {
                    title: 'Upcoming absences',
                    filter: 'upcoming',
                },
            ];
        }
        return [
            {
                title: 'Overview',
                filter: 'today',
            },
            {
                title: 'Upcoming absences',
                filter: 'upcoming',
            },
            {
                title: 'Pending requests',
                filter: 'pending',
            },
        ];
    },
    saved: state => state.saved,

    loading: state => state.loading,

    selectedCalendarEvent: state => state.selectedCalendarEvent,

    workerDetails: state => state.workerDetails,

    workerDetailsDate: state => state.workerDetailsDate,

    organization: state => state.organization,

    organization_id: state => state.organization_id,

    is_default_org: state => 1 === state.organization_id,

    partDayOptions: state => state.partDayOptions,

    partEndDayOptions: state => state.partEndDayOptions,

    minDate: state => state.minDate,

    maxDate: state => state.maxDate,

    selectedMonth: state => state.selectedMonth,

    absenceTypes: state => Object.values(state.absenceTypes || []),

    members: state => state.members,

    publicHolidaysOptions: state => state.publicHolidaysOptions,

    getCalendarRows: state => (42 === state.calendarDates.length ? 6 : 5),

    calendarDates: state => state.calendarDates,

    calendarDatesData: state => state.calendarDatesData,

    upcomingItems: state => state.upcomingItems,

    pendingItems: state => state.pendingItems,

    calendarDayEvents: state => state.calendarDayEvents,

    absences: state => state.absences,

    countries: state => state.countries,

    monthEvents: state => state.monthEvents,

    workingDays: state => state.workingDays,

    getDateInterval: state => {
        const startCalendarDate = state.calendarDates[0];
        const endCalendarDate = state.calendarDates[state.calendarDates.length - 1];
        const start_date = startCalendarDate ? format(startCalendarDate, 'yyyy-MM-dd') : '';
        const end_date = endCalendarDate ? format(endCalendarDate, 'yyyy-MM-dd') : '';
        return { start_date, end_date };
    },

    filteredAbsence: state => state.filteredAbsence,

    iconClass({ state }, type) {
        return 'sdfs';
        let typeVal = type;
        if ('number' === typeof type) {
            typeVal = state.absenceTypes.find(i => i.id === type)?.value;
        }

        switch (typeVal) {
            case 'sick':
            case 'maternity':
                return 'color-feedback-error';
            case 'public':
                return 'color-feedback-success';
            case 'annual':
            case 'individual_annual':
            case 'other':
                return 'color-purple';
            default:
                return 'color-black';
        }
    },
};
