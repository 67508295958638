export const namespaced = true;

export const state = {
    path: '/knowledge-base',
    loading: false,
    categories: [],
    statuses: [],
    countries: [],
    knowledgeAll: [],
    paginationParams: {
        per_page: 12,
        total: 0,
    },
    knowledgeAllFilter: {
        search: '',
        countries: '',
        category: '',
    },
};

export const mutations = {
    SET_KNOWLEDGE_ALL(state, values) {
        state.knowledgeAll = values.data;
        state.paginationParams = { last_page: values.last_page, total: values.total, per_page: values.per_page};
    },
    SET_KNOWLEDGE_ALL_FILTER(state, filters) {
        state.knowledgeAllFilter = filters;
    },
    SET_STATUES(state, values) {
        state.statuses = Object.values(values);
    },
    SET_LOADER(state, value) {
        state.loading = value;
    },
    SET_COUNTRIES(state, values) {
        state.countries = Object.values(values);
    },
    SET_CATEGORIES(state, values) {
        state.categories = Object.values(values);
    },
};

export const actions = {
    init({commit, state}) {
        axios.post(`${state.path }/page-data`).then(({ data }) => {
            commit('SET_STATUES', data.statuses);
            commit('SET_CATEGORIES', data.categories);
            commit('SET_COUNTRIES', data.countries);
        });
    },
    setKnowledgesAllFilters({ commit, dispatch }, filters) {
        commit('SET_KNOWLEDGE_ALL_FILTER', filters);
        dispatch('getKnowledgeArticles');
    },
    getKnowledgeArticles({ commit, state }, params = {}) {
        commit('SET_LOADER', true);
        params.limit = state.paginationParams.per_page;
        let countries = state.knowledgeAllFilter.countries ? [...state.knowledgeAllFilter.countries] : [];
        if(countries.indexOf('0') > -1) {
            countries.splice(countries.indexOf('0'), 1);
        }
        axios.post(`${state.path }/paginate`, {...params, ...state.knowledgeAllFilter,  countries }).then(({ data }) => {
            commit('SET_KNOWLEDGE_ALL', data);
        }).finally(() => {
            commit('SET_LOADER', false);
        });
    },
};

export const getters = {
    loading: state => state.loading,
    getCategories: state => state.categories,
    getStatuses: state => state.statuses,
    getCountries: state => state.countries,
    getAllKnowledge: state => state.knowledgeAll,
    paginationParams: state => state.paginationParams,
    getKnowledgeAllFilters: state => state.knowledgeAllFilter,
};
