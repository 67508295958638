<template>
    <button
        class="btn-quick-action"
        :class="customClasses"
        v-on="$listeners"
        :disabled="disabled"
        :loading="loading"
    >
        <div class="icon-box">
            <slot name="icon"></slot>
        </div>
        <p class="text-sm font-medium color-grey-3">
            <slot name="default"></slot>
        </p>
    </button>
</template>

<script>
export default {
    name: "WlBaseBtnQuickAction",

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        ripple: {
            type: Boolean,
            default: false,
        },
        to: {
            type: [String, Object],
        },
        customClasses: {
            type: String,
            default: '',
        }
    },
};
</script>

<style lang="scss" scoped>
.btn-quick-action.native-pay-menu:hover {
    p {
        color: #757779 !important;
    }
}

.btn-quick-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    outline: 0;
    border: 0;
    box-shadow: none;
    background: transparent;
    cursor: pointer;
    min-width: fit-content;
    width: fit-content;

    p {
        transition: all 0.1s ease-in-out;
    }

    &:hover {
        .icon-box {
            background: #7c8dff !important;
            border: 1px solid transparent;
        }

        p {
            color: #757779 !important;
        }
    }

    &:active {
        .icon-box {
            background: #2f2f9c !important;
        }

        p {
            color: #757779 !important;
        }
    }

    &:disabled {
        .icon-box {
            background: #cdcfd2 !important;
            border: 1px solid transparent;

        }

        p {
            color: #cdcfd2 !important;
        }
    }

    .icon-box {
        transition: all 0.3s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
        border-radius: 18px;
        border: 1px solid var(--main-purple-color);
        background: var(--main-purple-color);
        width: 56px;
        height: 56px;

        i {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            color: white;
            font-size: 24px;
        }
    }
}
</style>
