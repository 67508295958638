<template>
    <ModalKYCTemplate
        :modal-data="modalData"
        :dialog="dialog"
        :loading="loading"
        @confirm="confirm()"
        @close="close()"
    >
        <div v-if="activeForm === 'enable2fa'">
            <p class="text-base leading-130 color-grey-2 mb-6">
                Two-factor authentication (2FA) strengthens access security by
                requiring two methods (also referred to as factors) to verify your
                identity. Two-factor authentication protects against phishing,
                social engineering and password brute force attacks and secures your
                logins from attackers exploiting weak or stolen credentials.
            </p>
            <div>
                <div v-for="(item, index) in types"
                     :key="index"
                     class="d-flex"
                     :class="{ 'mb-3': !index }"
                >
                    <label class="radio-container radio-group-box">
                        <div class="ml-3-rem">
                            <h2 class="title leading-130 fs-15">
                                {{ item.title }}
                            </h2>
                            <p class="two-step-info leading-130 fs-15">
                                {{ item.description }}
                            </p>
                        </div>
                        <tm-radio :item-value="item.value" v-model="type"></tm-radio>
                    </label>
                </div>
            </div>
        </div>
        <div v-else-if="activeForm === 'enableGoogle2fa'">
            <p class="text-base leading-130 color-grey-2 mb-6">
                Google Authenticator is a free 2FA app. Download it on your mobile
                device by searching for ‘Google Authenticator’ on the Play Store or
                App Store.
            </p>
            <div>
                <div>
                    <p class="second-step-text mb-2 fs-20">
                        1. Scan QR
                    </p>
                    <p class="leading-130 color-grey-2 mb-2 fs-15">
                        Scan this QR code with your Google Authenticator App.
                        Alternatively, you can use this code:
                    </p>
                    <WlBaseButton
                        class="btn-small bg-transparent text-sm font-medium color-purple mb-3"
                        @click="_initEnableGoogle2fa"
                    >
                        Code generated
                    </WlBaseButton>
                </div>
                <div class="qr-code-box mb-3 p-0">
                    <img :src="google2faQRCodeUrl" class="w-100 h-100"/>
                </div>
                <div>
                    <p class="second-step-text mb-2 fs-20">
                        2. Enter PIN
                    </p>
                    <p class="leading-130 color-grey-2 mb-2 fs-15">
                        Enter the pin from Google Authenticator.
                    </p>
                </div>
                <div class="google-code-container mt-3">
                    <WlBaseInput
                        type="tel"
                        placeholder="Enter PIN"
                        :bottom-offset="false"
                        classVal="w-100"
                        v-model="google2faOTPEnable"
                    ></WlBaseInput>
                </div>
            </div>
        </div>
        <div v-else-if="activeForm === 'disableGoogle2fa'">
            <div class="alert w-100 mb-6 alert-info big-content alternative">
                <i class="mr-1 icon-info-circle-outline"></i>
                <p class="paragraph">2FA is currently enabled on your account.</p>
            </div>
            <p class="text-sm settings-text-paragraph color-grey-2 mb-6">
                If you are looking to disable Two Factor Authentication, please
                confirm your Google Pin and Click Disable.
            </p>
            <div>
                <p class="password-disable mb-2">Google PIN</p>
                <WlBaseInput
                    type="tel"
                    class="w-100"
                    placeholder="Enter PIN"
                    :bottom-offset="false"
                    v-model="google2faOTPDisable"
                ></WlBaseInput>
            </div>
        </div>
        <div v-else-if="activeForm === 'configSMS2fa'">
            <p class="two-step-info leading-130 mb-4 fs-15">
                Enter your phone number.
                <br>
                Make sure you are able to receive SMS.
            </p>
            <div class="phone-field">
                <WlBaseSelectPhone
                    customInputClasses="pl-1 pl-md-0"
                    class="w-100"
                    id="phone"
                    name="phone"
                    placeholder="77282828"
                    inputFieldPlaceholder="77282828"
                    label="Phone number"
                    v-model="smsPhoneNumber"
                    :error-messages="errors.collect('config.phone')"
                    data-vv-as="Phone number"
                >
                    <template>
                        <label class="text-sm font-medium mb-1"> Phone number</label>
                    </template>
                </WlBaseSelectPhone>
            </div>
        </div>
        <div v-else-if="activeForm === 'enableSMS2fa'">
            <p class="two-step-info mb-4 fs-15">
                We’ve sent you a code to:
                <span class="color-purple text-uppercase bold-text">{{ smsPhoneNumber }}</span>
            </p>
            <div class="w-100">
                <p class="password-disable mb-2 bold-text">
                    SMS code
                </p>
                <div class="google-code-container mb-4">
                    <WlBaseInput
                        classVal="w-100"
                        placeholder="Enter code"
                        type="tel"
                        :bottom-offset="false"
                        v-model="smsCodeEnable"
                    ></WlBaseInput>
                </div>
                <p class="color-grey-2 fs-15">
                    Didn’t get it?
                    <a
                        @click.prevent.stop="resend"
                        class="btn btn-small bg-transparent text-sm font-medium color-purple"
                    >
                        Resend
                    </a>
                </p>
            </div>
        </div>
        <div v-else-if="activeForm === 'disableSMS2fa'">
            <div class="alert w-100 mb-6 alert-info big-content alternative">
                <i class="mr-1 icon-info-circle-outline"></i>
                <p class="paragraph">2FA is currently enabled on your account.</p>
            </div>
            <p class="text-sm settings-text-paragraph color-grey-2 mb-6">
                If you are looking to disable Two-Factor Authentication, please
                confirm your SMS code and Click Disable.
            </p>
            <div>
                <p class="password-disable mb-2">SMS code</p>
                <WlBaseInput
                    type="tel"
                    class="w-100"
                    placeholder="Enter code"
                    :bottom-offset="false"
                    v-model="smsCodeDisable"
                ></WlBaseInput>
            </div>
        </div>

        <template v-if="!isSuccess && isBack" v-slot:underButton>
            <WlBaseButton
                @click="prev()"
                class="btn-md btn-outline-purple text-sm font-medium color-purple w-full mt-2"
            >
                Back
            </WlBaseButton>
        </template>
    </ModalKYCTemplate>
</template>
<script>
import TmInput from "~/shared/components/form/TmInput";
import TmRadio from "~/shared/components/form/TmRadio";
import Btn from "~/shared/components/Btn";
import ModalInfo from "~/components/Modals/ModalInfo";
import {mapActions, mapGetters} from 'vuex';
import ModalKYCTemplate from "./ModalKYCTemplate.vue";

export default {
    name: "Security2faForm",
    components: {ModalKYCTemplate, Btn, TmInput, TmRadio, ModalInfo},
    data() {
        return {
            loading: false,
            type: this.$store.getters['wlSecurity2fa/__type'],
            google2faQRCodeUrl: null,
            google2faOTPEnable: null,
            google2faOTPDisable: null,
            smsPhoneNumber: null,
            smsCodeEnable: null,
            smsCodeDisable: null,
            steps: [],
            activeForm: "enablePreview", /*options:
                                            enablePreview,
                                            enable2fa,
                                            enableGoogle2fa,
                                            disableGoogle2fa,
                                            configSMS2fa,
                                            enableSMS2fa,
                                            disableSMS2fa,
                                            enableSuccess2fa,
                                            disableSuccess2fa*/
            hash: this.$store.getters['wlSecurity2fa/__form2faDialogHash'],
        };
    },
    computed: {
        ...mapGetters("wlSecurity2fa", {
            enabled: "__enabled",
            form2faDialog: "__form2faDialog",
            types: "__types",
        }),
        dialog: {
            get() {
                return this.form2faDialog;
            },
            set(value) {
                this.__setForm2faDialog(value);
            },
        },
        isSuccess(){
            return ['enableSuccess2fa', 'disableSuccess2fa'].includes(this.activeForm)
        },
        isBack(){
            return !['enablePreview', 'enable2fa', 'enableSuccess2fa', 'disableSuccess2fa'].includes(this.activeForm);
        },
        modalData(){
            switch (this.activeForm) {
                case 'enablePreview':
                    return {
                        image: "graphic-2fa.png",
                        title: "Please enable 2 factor-authentication",
                        text: "Before proceeding with your verification process you must enable the 2 factor-authentication.",
                        btnText: "Start 2 factor-authentication",
                    }
                case 'enable2fa':
                    return {
                        title: `Two factor authentication (2FA)`,
                        btnText: "Continue",
                        invalid: !this.type
                    }
                case 'enableGoogle2fa':
                    return {
                        title: `Two factor authentication (2FA)`,
                        btnText: "Continue",
                        invalid: !this.google2faOTPEnable
                    }
                case 'configSMS2fa':
                    return {
                        title: `Two factor authentication (2FA)`,
                        btnText: "Continue",
                        invalid: !this.smsPhoneNumber
                    }
                case 'enableSMS2fa':
                    return {
                        title: `Two factor authentication (2FA)`,
                        btnText: "Continue",
                        invalid: !this.smsCodeEnable
                    }
                case 'enableSuccess2fa':
                    return {
                        icon: {
                            name: "check-circle-outline",
                            class: "color-feedback-success fs-96",
                            justify: "justify-start",
                        },
                        showBtnCloseModal: false,
                        title: "2FA is enabled successfully!",
                        text: "You chose to enable 2FA. If you change your mind, you can come back at any time in Settings/Security tab and disable it again.",
                        btnText: "Close",
                    }
                case 'disableSuccess2fa':
                    return {
                        icon: {
                            name: "check-circle-outline",
                            class: "color-feedback-success fs-96",
                        },
                        title: "2FA is disabled successfully!",
                        text: "You chose to disable 2FA. If you change your mind, you can come back at any time at Settings/Security tab and enable it again.",
                        // btnText: "Close",
                    }
                default:
                    return {
                        title: `Two factor authentication (2FA)`
                    }
            }
        },
    },
    watch: {
        form2faDialog(value) {
            this.dialog = value;
            this.google2faOTPEnable = null;
            this.google2faOTPDisable = null;
            this.smsPhoneNumber = null;
            this.smsCodeEnable = null;
            this.smsCodeDisable = null;
            this.steps = [];
            this.type = this.$store.getters['wlSecurity2fa/__type'];
            this.errors.clear();

            if (value) {
                if (!this.enabled) {
                    this.activeForm = "enablePreview";
                } else if (this.type === "google2fa") {
                    this.activeForm = "disableGoogle2fa";
                } else if (this.type === "sms") {
                    this.activeForm = "disableSMS2fa";
                    this.resend();
                }
            }
        },
        smsPhoneNumber() {
            this.errors.remove('config.phone');
        },
        '$route': {
            handler: function(to) {
                if(to.hash === this.hash) {
                    this.dialog = true;
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        ...mapActions('wlSecurity2fa', [
            '__enable',
            '__disable',
            '__resend',
            '__generate',
            '__setForm2faDialog'
        ]),
        confirm() {
            switch (this.activeForm) {
                case 'enablePreview':
                    this.next('enable2fa');
                    break;
                case 'enable2fa':
                    if (this.type === "google2fa") {
                        this._initEnableGoogle2fa();
                    } else if (this.type === "sms") {
                        this.next("configSMS2fa");
                    }
                    break;
                case 'enableGoogle2fa':
                    this.enable();
                    break;
                case 'configSMS2fa':
                    this.savePhone();
                    break;
                case 'enableSMS2fa':
                    this.enable();
                    break;
                default:
                    this.close();
                    break;
            }
        },
        _initEnableGoogle2fa() {
            this.google2faOTPEnable = null;
            this.loading = true;
            this.__generate({ type: this.type})
                .then((data) => {
                    this.loading = false;
                    this.google2faQRCodeUrl = data.google2faQRCodeUrl;
                    if (this.activeForm !== 'enableGoogle2fa'){
                        this.next("enableGoogle2fa");
                    }
                });
        },
        close() {
            this.dialog = false;
            this.$router.push({ hash: ''}).catch(error => {
                if (error.name != "NavigationDuplicated") {
                    throw error;
                }
            })
        },
        next(form) {
            if (this.steps.pop() !== this.activeForm){
                this.steps.push(this.activeForm);
            }
            this.activeForm = form;
        },
        prev() {
            this.activeForm = this.steps.pop();
            this.steps = this.steps.length? this.steps.splice(-1): [];
        },
        enable() {
            this.loading = true;
            const data = {
                type: this.type,
                password: this.type === "google2fa"? this.google2faOTPEnable: this.smsCodeEnable
            }
            this.__enable(data)
                .then(() => this.next("enableSuccess2fa"))
                .finally(() => this.loading = false );
        },
        disable() {
            const data = {
                type: this.type,
                password: this.type === "google2fa"? this.google2faOTPDisable: this.smsCodeDisable
            }
            this.__disable(data)
                .then(() => this.next("disableSuccess2fa"))
                .finally(() => this.loading = false );
        },
        savePhone() {
            const data = {
                type: this.type,
                config: {phone: this.smsPhoneNumber}
            }
            this.loading = true;
            return this.__generate(data)
                .then(() => this.next("enableSMS2fa"))
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        for (const [key, value] of Object.entries(error.response.data.errors)) {
                            this.errors.add({ field: key, msg: value[0] || value })
                        }
                    }
                })
                .finally(() => this.loading = false );
        },
        resend() {
            this.loading = true;
            return this.__resend({type: this.type}).finally(() => this.loading = false );
        },
    },
};
</script>
<style scoped>
.qr-code-box {
    height: 200px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 4px;
    padding: 16px 8px;
    border: 1px solid #e6e6e6;
}
</style>
