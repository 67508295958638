<template>
    <modal v-model="dialog" :value="true" :width="modalWidth" :padding="false"
           :retain-focus="false"
           close-size="1.5rem"
           close-top="0"
           close-right="0"
           close-color="var(--typography-black-color);"
           :close-icon-show="step === 'invite_people'" class-names="modal-onboarding-first-screens">
        <template slot="content">
            <welcome
                v-if="step === 'welcome'"
                :greeting-obj="greetingObj"
                :types="types"
                @next="step = 'basic_info'"
                @skip="saveInfoAndSkip()"
                @done="saveInfoAndDone()"
            ></welcome>
            <basic-info
                v-if="step === 'basic_info'"
                :greeting-obj="greetingObj"
                :purposes="purposes"
                @next="step = 'based_location'"
                @back="step = 'welcome'"
                @skip="saveInfoAndSkip()"
                @done="saveInfoAndDone()"
            ></basic-info>
            <based-location
                v-if="step === 'based_location'"
                :greeting-obj="greetingObj"
                @next="step = 'invite_people'"
                @back="step = 'basic_info'"
                @skip="saveInfoAndSkip()"
                @done="saveInfoAndDone()"
            ></based-location>
            <invite-people
                v-if="step === 'invite_people'"
                :greeting-obj="greetingObj"
                @back="step = 'based_location'"
                @done="saveInfoAndDone()"
            ></invite-people>

            <current-status
                v-if="step === 'current_status'"
                :greeting-obj="greetingObj"
                :types="types"
                @back="step = 'based_location'"
                @next="step = $event"
            ></current-status>
            <freelancer
                v-if="step === 'freelancer'"
                :experience-levels="experienceLevels"
                :greeting-obj="greetingObj"
                :loading="loading"
                @back="step = 'current_status'"
                @done="saveInfo()"
            ></freelancer>
            <work
                v-if="step === 'employee' || step === 'employer'"
                :experience-levels="experienceLevels"
                :employment-types="employmentTypes"
                :greeting-obj="greetingObj"
                :loading="loading"
                @back="step = 'current_status'"
                @done="saveInfo()"
            ></work>
            <interests
                v-if="step === 'unemployed'"
                :experience-levels="experienceLevels"
                :greeting-obj="greetingObj"
                :loading="loading"
                @back="step = 'current_status'"
                @done="saveInfo()"
            ></interests>

            <skip-modal
                v-if="step === 'skip'"
                @close="closeModal()"
            ></skip-modal>

<!--            <finished
                v-if="step === 'finished'"
                @done="openNextVideo(1)"
                @close="closeModal()"
            ></finished>-->

            <!--Onboarding videos-->
            <modal v-model="nextVideoDialog" width="37.5rem" :padding="false" :close-icon-show="false" class-names="modal-xl visible-overflow onboarding-videos-custom">
                <template slot="content">
                    <v-row style="margin:0 !important">
                        <v-col cols="12" lg="12" class="modal-onboarding modal-general-walkthrough visible-overflow" style="z-index:999;">
                            <div class="modal-content" style="background-color:#fff;">
                                <div class="modal-body">
                                    <div class="video-wrapper">
                                        <button @click="pause" class="btn btn-icon btn-close color-black">
                                            <i class="icon-times"></i>
                                        </button>
                                        <video :key="nextVideoSrc" @playing="updatePaused" @pause="updatePaused" class="video-js" autoplay controls controlsList="nodownload" style="width:100%">
                                            <source :src="nextVideoSrc" type='video/mp4'>
                                            <p class="vjs-no-js">
                                                To view this video please enable JavaScript, and consider upgrading to a web browser that
                                                <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                                            </p>
                                        </video>
                                    </div>
                                    <p class="medium-text strong-text color-dark-blue mb-1">
                                        {{nextVideoTitle}}
                                    </p>
                                    <div class="modal-onboarding-footer">
                                        <p class="medium-text color-dark-grey">
                                            {{nextVideoDesc}}
                                        </p>
                                        <div class="onboarding-steps-actions">
<!--                                            <button v-if="$store.state.onboardingProgress !== 1" @click="openNextVideo($store.state.onboardingProgress - 1)" class="btn btn-onboarding-step btn-purple btn-prev-step mr-2">-->
<!--                                                <i class="icon-chevron-left"></i>-->
<!--                                            </button>-->
<!--                                            <button v-if="$store.state.onboardingProgress !== 5" @click="openNextVideo($store.state.onboardingProgress + 1)" class="btn btn-onboarding-step btn-purple btn-next-step">-->
<!--                                                <i class="icon-chevron-right"></i>-->
<!--                                            </button>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </template>
            </modal>
        </template>
    </modal>
</template>
<script>
import Welcome from "./Welcome";
import BasicInfo from "./BasicInfo";
import Modal from "~/shared/components/Modal";
import CurrentStatus from "./CurrentStatus";
import BasedLocation from "./BasedLocation";
import GreetingInfo from "../../../models/GreetingInfo";
import Freelancer from "./Freelancer";
import Work from "./Work";
import Interests from "./Interests";
import Finished from "./Finished";
import InvitePeople from "./InvitePeople";
import SkipModal from "./SkipModal";
export default {
    name: 'Greeting',
    components: {
        SkipModal,
        InvitePeople,
        Finished,
        Interests,
        Work,
        Freelancer,
        BasedLocation,
        CurrentStatus,
        Modal,
        BasicInfo,
        Welcome},
    props: {
        jobToApply: {
            default: null
        },
    },
    data(){
        return {
            modalWidth: '63.71rem',
            loading: false,
            step: 'welcome',
            types: [],
            purposes: [],
            experienceLevels: [],
            employmentTypes: [
                {name: 'Full time', id: 1},
                {name: 'Part time', id: 0},
            ],
            greetingObj: new GreetingInfo(this.$store.state.info),
            dialog: true,
            nextVideoDialog: false,
            videoElement: null,
            nextVideoSrc: 'https://d121mxvqzcpef0.cloudfront.net/General Walkthrough .mp4',
            nextVideoTitle: 'General Walkthrough',
            nextVideoDesc: 'Let us walk you through the app and get the best experience for you.',
        }
    },
    computed: {
        playing() { return !this.paused; }
    },
    methods: {
        getTypes(){
            axios.get(this.$store.state.getPath+'info-types')
                .then(response => { this.types = response.data; })
                .catch(error => {
                    this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                })
        },
        getPurposes(){
            axios.get(this.$store.state.getPath+'purposes')
                .then(response => { this.purposes = response.data; })
                .catch(error => {
                    this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                })
        },
        getExperienceLevels(){
            axios.get(this.$store.state.getPath+'experience-levels')
                .then(response => {
                    this.experienceLevels = response.data;
                })
                .catch(error => {
                    this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                })
        },
        getEmploymentTypes(){
            axios.get(this.$store.state.getPath+'employment-types')
                .then(response => {
                    this.employmentTypes = response.data;
                })
                .catch(error => {
                    this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                })
        },
        saveInfoAndSkip(){
            this.step = 'skip';
            this.modalWidth = '50rem';
            this.loading = true;
            if(!this.greetingObj.values.country_id){
                this.greetingObj.values.country_id = 1;
            }
            this.greetingObj.create(this.$store.state.path+'profile/greeting-info')
                .then(response => {
                    this.loading = false;
                    this.$store.commit("updateData", {key: 'info', data: response.data.info});
                })
                .catch(error => {
                    this.loading = false;
                    this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                })
        },
        saveInfoAndDone(){
            this.loading = true;
            if(!this.greetingObj.values.country_id){
                this.greetingObj.values.country_id = 1;
            }
            this.greetingObj.create(this.$store.state.path+'profile/greeting-info')
                .then(response => {
                    this.loading = false;
                    this.dialog = false;
                    this.$store.commit("updateData", {key: 'info', data: response.data.info});
                })
                .catch(error => {
                    this.loading = false;
                    this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                })
        },
        closeModal() {
            this.dialog = false;
            if(this.videoElement) {
                this.pause();
            }
            if(this.jobToApply){
                window.open('recruitment/job/'+this.jobToApply, "_self");
            }
        },
        updatePaused(event) {
            this.videoElement = event.target;
            this.paused = event.target.paused;
        },
        pause(){
            this.videoElement.pause();
            this.nextVideoDialog = false;
        },
        openNextVideo(step){
            this.dialog = false;

            if(this.videoElement) {
                this.pause();
            }
            this.$store.state.onboardingProgress = step;

            if(step === 1){
                this.nextVideoSrc = 'https://d121mxvqzcpef0.cloudfront.net/Intro%201.mp4';
                this.nextVideoTitle = 'Intro to Native Teams';
                this.nextVideoDesc = '';
            } else if(step === 2){
                this.nextVideoSrc = 'https://d121mxvqzcpef0.cloudfront.net/General Walkthrough .mp4';
                this.nextVideoTitle = 'General Walkthrough';
                this.nextVideoDesc = 'Let us walk you through the app and get the best experience for you.';
            }
            else if(step === 3){
                this.nextVideoSrc = 'https://d121mxvqzcpef0.cloudfront.net/Payments & Invoicing.mp4';
                this.nextVideoTitle = 'Payments & Invoicing';
                this.nextVideoDesc = 'Learn how to use our payments and invoicing system.';
            }
            else if(step === 4){
                this.nextVideoSrc = 'https://d121mxvqzcpef0.cloudfront.net/EoR Final.mp4';
                this.nextVideoTitle = 'Employer of Record';
                this.nextVideoDesc = 'Have your taxes and social security costs covered through our EOR services.';
            }
            else {
                this.nextVideoSrc = 'https://d121mxvqzcpef0.cloudfront.net/Jobs.mp4';
                this.nextVideoTitle = 'Jobs';
                this.nextVideoDesc = 'Complete your profile and get recommended jobs for you.';
            }
            this.nextVideoDialog = true;
        }
    },
    created() {
        this.getTypes();
        this.getPurposes();
        this.getExperienceLevels();
        // this.getEmploymentTypes();
    }
}
</script>
