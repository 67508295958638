<template>
  <nav class="nav-wizard-2-steps-container vue-repo-app">
    <ul class="wizard-steps-list">
      <NavbarWizardStep
        v-for="(step, index) in steps"
        :key="index"
        :step="step"
        :number="index"
        :currentStep="currentStep"
      />
    </ul>
  </nav>
</template>

<script>
import NavbarWizardStep from "~/shared/components/NavbarWizard/NavbarWizardStep.vue";
export default {
  name: "BaseWizardNavbar2",

  components: {
    NavbarWizardStep,
  },

  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: "",
    },
  },
};
</script>
