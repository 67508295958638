<template>
    <div class="d-flex flex-column" :class="bottomOffset? 'mb-4': 'mb-0'">
        <label
            :for="id"
            v-if="label"
            class="tm-small-text font-weight-medium black--text mb-1 d-flex"
        >
            <span>{{label}}</span>
            <v-tooltip v-if="infoText" right>
                <template v-slot:activator="{ on, attrs }">
                    <button v-bind="attrs" v-on="on">
                        <icon
                            size="15"
                            :name="infoIcon"
                            color="#5152fb"
                            class="ml-2"
                        ></icon>
                    </button>
                </template>
                <p class="tm-color-white" style="max-width: 170px; font-size: 12px; line-height: 15px">{{ infoText }}</p>
            </v-tooltip>
        </label>
        <div v-if="multiple && slotSelectionItems.length" class="d-flex align-center flex-wrap">
            <template v-if="$scopedSlots['selection']">
                <slot v-for="item in slotSelectionItems" name="selection" v-bind:item="item"></slot>
            </template>
            <template v-else>
                <v-chip class="ma-1 px-1 ml-0" v-for="(item, index) in slotSelectionItems" :key="index" label small :color="chipsColor">
                    <avatar v-if="chipsIcon" size="18" :name="item[itemText]" :avatar="item[chipsIcon]"></avatar>
                    <span class="font-weight-medium" style="font-size: 11px">{{ item[itemText] }}</span>
                    <v-btn v-if="chipsClear" @click.prevent.stop="chipClear(item[itemValue])" x-small icon class="ml-1">
                        <icon class="icon-times" size="10"></icon>
                    </v-btn>
                </v-chip>
            </template>
        </div>
        <div class="d-flex align-center" style="position: relative">
            <div :style="addStyles" class="tm-input pl-0 pr-2 d-flex align-center" :class="errorMessages.length? 'tm-err': null">
                <div v-if="appendContent || $slots.prepped" class="d-flex">
                    <div v-if="appendContent" class="pa-2 d-flex align-center justify-center" v-html="__sanitizeHtml(appendContent)"></div>
                    <slot name="prepped"></slot>
                    <v-divider vertical></v-divider>
                </div>
                <v-btn v-if="prependIcon" @click="$emit('prependClick')" icon small :color="prependIconColor">
                    <icon :name="prependIcon" size="16" :color="prependIconColor"></icon>
                </v-btn>
                <v-menu
                    style="border-radius: 0.3125rem;"
                    nudge-bottom="10"
                    min-width="150"
                    bottom
                    v-model="menu"
                    offset-y
                    :close-on-content-click="false"
                    :close-on-click="true"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            class="w-100 d-flex align-center"
                            v-on="on"
                            v-bind="attrs"
                        >
                            <template v-if="!multiple">
                                <div class="pl-2" v-if="$scopedSlots['selection'] && selected">
                                    <slot v-for="item in slotSelectionItems" name="selection" v-bind:item="item"></slot>
                                </div>
                                <span v-else-if="selected" class="px-2">{{ selected[itemText]}}</span>
                            </template>
                            <input
                                v-show="!localDisabled"
                                ref="searchInput"
                                :name="inputName"
                                :type="type"
                                :disabled="disabled"
                                autocomplete="off"
                                :id="id"
                                v-model="searchVal"
                                :placeholder="placeholder"
                                class="px-2 w-100"
                                @focus="$emit('focus')"
                                @keyup="$emit('keyup')"
                                @keydown="$emit('keydown')"
                                v-on:keyup.enter="onEnter()"
                            >
                        </div>
                    </template>
                    <v-list class="pa-2" v-if="multiple || showItems.length">
                        <div v-if="$slots.prependItem" class="pb-2 border-bottom">
                            <slot name="prependItem"></slot>
                        </div>
                        <div @scroll="scrollAction"  ref="content" class="tm-scroll-container" style="max-height: 250px">
                            <v-list-item-group v-if="showItems.length" color="#ddf3ff" :multiple="multiple" v-model="val">
                                <v-list-item
                                    :disabled="!multiple && item[itemValue] === val"
                                    active-class="tm-sm-select-active"
                                    class="pa-1 tm-list-item tm-sm-select-item"
                                    :value="item[itemValue]"
                                    :key="index"
                                    v-for="(item, index) in localItems"
                                    v-show="!multiple || !val.includes(item[itemValue])"
                                >
                                    <div v-if="$scopedSlots['item']" class="w-100">
                                        <slot name="item" v-bind:item="item"></slot>
                                    </div>

                                    <v-list-item-title v-else class="tm-list-item-title">
                                        {{ item[itemText] }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                            <v-list-item v-else>
                                <v-list-item-subtitle>
                                    <div class="text-center">no item to display</div>
                                </v-list-item-subtitle>
                            </v-list-item>
                        </div>
                        <div v-if="$slots.appendItem" class="pt-2 border-top">
                            <slot name="appendItem"></slot>
                        </div>
                    </v-list>
                </v-menu>
                <v-spacer></v-spacer>
                <div v-if="loading" class="pr-1">
                    <v-progress-circular indeterminate size="20" width="2" color="#5152fb"></v-progress-circular>
                </div>
                <v-btn v-if="appendIcon" @click="$emit('appendClick')" icon small :color="appendIconColor">
                    <icon :name="appendIcon" size="16" :color="appendIconColor"></icon>
                </v-btn>
                <v-btn v-if="val && !multiple" @click.stop="clear()" icon x-small color="#838a90" class="ml-1">
                    <icon name="icon-times" color="#838a90" size="0.625" :rem="true"></icon>
                </v-btn>
                <error-message :messages="errorMessages"></error-message>
            </div>
        </div>
    </div>
</template>
<script>
import TmInput from "./TmInput";
import ErrorMessage from "./ErrorMessage";
import Avatar from "../Avatar";
import Helpers from '~/shared/mixins/Helpers';
export default {
    name: 'TmAutocomplete',
    components: {Avatar, ErrorMessage, TmInput},
    mixins: [Helpers],
    props: {
        id: {
            default: null
        },
        bottomOffset: {
            default: true
        },
        placeholder: {
            default: 'Select'
        },
        label: {
            default: null
        },
        searchRoute: {
            default: null
        },
        searchAdditionalParams: {
            type: Object,
            default() {
                return {}
            }
        },
        searchLike: {
            default: true
        },
        searchRemote: {
            default: true
        },
        items: {
            default() {
                return []
            }
        },
        itemValue: {
            default: 'id'
        },
        itemText: {
            default: 'name'
        },
        disabled:{
            default: false
        },
        value: {
            default: null
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            }
        },
        addStyles: {
            type: Object,
            default() {
                return {}
            }
        },
        type: {
            default: 'text'
        },
        appendIcon:{
            default: false
        },
        appendIconColor:{
            default: '#5152fb'
        },
        appendContent:{
            default: false
        },
        prependIcon: {
            default: false
        },
        prependIconColor: {
            default: '#5152fb'
        },
        infoText: {
            default: null
        },
        infoIcon: {
            default: 'icon-info'
        },
        inputName: {
            default: null
        },
        height: {
            default: null
        },
        autoload: {
            default: true
        },
        multiple: {
            default: false
        },

        chipsClear: {
            default: true
        },
        chipsIcon: {
            default: false
        },
        chipsColor: {
            default: '#ddf3ff'
        },

        createRoute: {
            default: null
        },
        createLength: {
            default: 3
        }
    },
    data(){
        return {
            val: this.value? this.value: this.multiple? []: null,
            localItems: this.items,
            loading: false,
            searchVal: this.search,
            menu: false,
            offset: 0
        }
    },
    watch: {
        searchVal(){
            this.offset = 0;
            this.$emit('inputText', this.searchVal)
            if (this.searchVal){
                this.getData(true)
            }else if (!this.selected){
                this.getData( false)
            }
        },
        val(){
            if (this.multiple){
                // this.$emit('inputObject', this.localItems.filter(item => this.val.includes(item[this.itemValue])))
            }else{
                this.searchVal = null;
                if (this.val){
                    this.menu = false;
                }
            }

            this.$emit('input', this.returnObject? this.multiple? this.selectedItems: this.selected: this.val)
        },
        value(){
            if (this.value !== this.val){
                this.getData(false, false)
            }
            this.val = this.value;
        },
        selected(){
            this.$emit('inputObject', this.selected);
        }
    },
    computed: {
        selectedItems(){
            return this.multiple? this.localItems.filter(item => this.val.includes(item[this.itemValue])): [];
        },
        selected(){
            return !this.multiple? this.localItems.find(item => this.val === item[this.itemValue]): null;
        },
        slotSelectionItems(){
            return this.multiple? this.selectedItems: this.selected? [this.selected]: [];
        },
        localDisabled(){
            return !this.multiple && this.selected;
        },
        showItems(){
            return this.multiple? this.localItems.filter(item => !this.val.includes(item[this.itemValue])): this.localItems;
        }
    },
    methods: {
        scrollAction (e) {
            if(this.offset  === false) {
                return;
            }
           const parent = e.target.closest('.tm-scroll-container');
           const maxWidth = Math.ceil(parent.offsetHeight + parent.scrollTop);
            if (maxWidth >= parent.scrollHeight && !this.loading) {
                this.remoteSearch()
            }

        },
        chipClear(key){
            let index = this.val.indexOf(key);
            this.val.splice(index, 1)
        },
        clear(){
            this.multiple? this.val = []: this.val = null;
            this.getData();
            this.$refs.searchInput.focus()
        },
        getData(openMenu = true){
            this.searchRemote? this.remoteSearch(openMenu): this.localSearch();
        },
        localSearch(){

        },
        remoteSearch(openMenu = false){
            let params = ! this.offset && this.searchAdditionalParams || {};
            params.offset = this.offset;
            this.loading = true;
            params.value = this.searchVal;
            axios.get(this.searchRoute, {params: params})
                .then(response => {

                    if(this.offset) {
                        this.localItems = [... this.localItems, ...response.data ] ;
                    } else {
                        this.$refs.content? this.$refs.content.scrollTop = 0: null;
                        this.localItems = response.data;
                        this.menu = openMenu;
                    }
                    this.loading = false;

                    // this.localItems = this.localItems.filter(item => this.val.includes(item[this.itemValue]));
                    // this.localItems = this.localItems.concat(response.data.filter(item => !this.val.includes(item[this.itemValue])));

                    this.$emit('findData', this.localItems);
                    if(response.data && Object.keys(response.data).length) {
                        this.offset+= 20;
                    } else {
                        this.offset = false;
                    }

                })
                .catch(error => {
                    this.loading = false;
                    this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                })
        },


        onEnter(){
            this.$emit('enter');
            if (
                this.createRoute &&
                this.searchVal &&
                this.searchVal.trim() &&
                this.searchVal.trim().length >= this.createLength
            ){
                axios.post(this.createRoute, {value: this.searchVal})
                    .then(response => {
                        this.loading = false;
                        this.localItems.unshift(response.data);
                        this.searchVal = null;
                        this.multiple? this.val.unshift(response.data.id): this.val = response.data.id;
                    })
                    .catch(error => {
                        this.loading = false;
                        this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                    })
            }
        }
    },
    created() {
        if (this.autoload){
            this.getData(false)
        }
    }
}
</script>
<style>
.tm-list-item{
    margin-bottom: 0.125rem;
    border-radius: 0.3125rem;
    min-height: 20px;
    color: var(--typography-black-color);
}
.tm-list-item-title{
    color: black;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1rem;
}
</style>
