import store from "~/store";

export default class setWLStyles{
    async resolve() {
        if (store.state.wlstyle && !_.isEmpty(store.state.wlstyle)) {
            const wlStyle = store.state.wlstyle;
            const css = `:root {
                --bg-lhs-sidebar: ${wlStyle.st_sb_bg_color}!important;
                --menu-active: ${wlStyle.st_pr_color}!important;
                --main-purple-color: ${wlStyle.st_sc_color}!important;
                --main-dark-pink-color: ${wlStyle.st_warn_color}!important;
                }`,

                head = document.head || document.getElementsByTagName('head')[0],
                style = document.createElement('style');
            head.appendChild(style);
            style.type = 'text/css';
            if (style.styleSheet) {
                // This is required for IE8 and below.
                style.styleSheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }

            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.head.appendChild(link);
            }
            link.href = wlStyle.st_icon;
        }
    }
}
