<template>
    <div class="actions-container features-container">
        <div class="legal-entity-not-set">
            <img
                :src="require('~/assets/images/navigation/legal-entity-active.svg')"
                alt="Legal entity not set image"
                class="legal-entity-not-set-image mb-1"
            />

            <p class="text-lg font-bold mt-2">Switch to your company view</p>

            <p class="text-sm color-grey-1 mt-2">
                To access all Native Company features, please switch to your company
                wallet view on the header or click on the button below
            </p>

            <BaseButton
                type="primary"
                class="mt-3"
                @click="setActiveWallet('legalEntity')"
            >Company wallet view
            </BaseButton>
        </div>
    </div>
</template>

<script>
import SoloEntitySwitcher from "~/shared/mixins/SoloEntity/SoloEntitySwitcher";

export default {
    name: "LegalEntityActive",
    mixins: [SoloEntitySwitcher],
};
</script>

<style lang="scss" scoped>
.features-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.v-list {
    padding: 0 !important;
    background: unset;
}

.btn-upgrade {
    margin-top: 2rem;
}

.legal-entity-not-set {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 288px;
    margin: 0 auto;

    p {
        line-height: 125% !important;
    }

    .color-grey-1 {
        color: #5b5c5e !important;
    }

    button {
        width: 237px !important;
    }

    .legal-entity-not-set-image {
        max-width: 232px;
    }
}

@media (max-width: 768px) {
    .legal-entity-not-set {
        width: 100%;

        p {
            max-width: 235px;
        }

        button {
            max-width: 235px;
        }
    }
}
</style>
<style lang="scss">
.module-actions-container{
    .features-container {
        .legal-entity-not-set{
            .v-btn:not(.v-btn--round) {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                padding: 12px 24px;
                height: 40px;
            }

            .btn{
                background-color: var(--main-purple-color) !important;
                color: white !important;
                border: 1px solid var(--main-purple-color) !important;
                &:hover{
                    background-color: #7c8dff !important;
                    color: white !important;
                    border: 1px solid #7c8dff !important;
                }
            }
        }
    }
}

</style>
