<template>
  <div class="amount-input-container">
    <div class="amount-input" :class="errorMessages.length ? 'error' : ''">
      <div class="field-group field-input-group position-relative vue-numeric">
        <div class="d-flex">
          <label
            v-if="label"
            class="text-sm text-grey-500 custom-label"
            :for="localId"
          >
            {{ label }}
          </label>

          <slot v-if="$slots.label" name="label"></slot>
          <label
            v-if="$slots.requiredText || requiredText"
            class="custom-label ml-1 text-sm color-feedback-error"
          >
            <slot v-if="$slots.requiredText" name="requiredText"></slot>
            <span>{{ requiredText }}</span>
          </label>
        </div>

        <div class="mt-1" v-if="$slots.subLabel || subLabel">
          <slot v-if="$slots.subLabel" name="subLabel"></slot>

          <label v-else class="text-sm color-grey-2">
            <span>{{ subLabel }}</span>
          </label>
        </div>

        <template v-if="hasExtraSlot">
          <div
            class="formatted-input formatted__input"
            :inputClass="inputClass"
          >
            <vue-numeric
              @blur="onBlur"
              ref="vueNumeric"
              :currency="currency"
              separator=","
              :precision="2"
              :placeholder="placeholder"
              v-model="val"
              :read-only="readonly"
              :disabled="disabled"
            >
            </vue-numeric>

            <slot name="append"></slot>
          </div>
        </template>
        <template v-else>
          <vue-numeric
            class="formatted-input formatted__input"
            :inputClass="inputClass"
            @blur="onBlur"
            ref="vueNumeric"
            :currency="currency"
            separator=","
            :precision="2"
            :placeholder="placeholder"
            v-model="val"
            :read-only="readonly"
            :disabled="disabled"
          >
          </vue-numeric>
        </template>
      </div>
      <div class="currency-wrapper">
        <BaseAvatar
          :object="previewCurrency"
          contentImgClass="avatar flag-avatar"
          size="32"
        />
        <p class="text-lg color-black">
          {{ previewCurrency.currency }}
        </p>
      </div>
    </div>

    <WlBaseErrorMessage :messages="errorMessages" />
  </div>
</template>

<script>
import VueNumeric from "vue-numeric";

import Helpers from "~/shared/mixins/Helpers";

import debounce from "lodash/debounce";

export default {
  name: "WlBaseFormattedInputWithCurrency",

  mixins: [Helpers],

  props: {
    id: {
      type: String || null,
      default: null,
    },
    label: {
      type: String || null,
      default: null,
    },
    subLabel: {
      type: String || null,
      default: null,
    },
    requiredText: {
      type: [String, null],
      default: null,
    },
    placeholder: {
      type: String || null,
      default: null,
    },
    value: {
      default: 0,
    },
    disabled: {
      default: false,
    },
    readonly: {
      default: false,
    },
    currency: {
      type: String,
      required: false,
      default: null,
    },
    precision: {
      type: Number,
      required: false,
      default: 2,
    },
    errorMessages: {
      default: () => [],
    },
    hasExtraSlot: {
      type: Boolean,
      default: false,
    },
    previewCurrency: {
      type: Object,
      required: true,
    },
    inputClass: {
      type: String,
      default: "",
    },
    formatContinuously: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    VueNumeric,
  },

  watch: {
    val: function (newValue) {
      if (newValue !== null || newValue !== undefined) {
        this.$emit("input", newValue);

        if (this.formatContinuously) {
          this.debouncedValueFormatted(newValue);
        }
      }
    },
    value: {
      handler() {
        if (this.value !== null || this.value !== undefined) {
          this.val = this.value;
        } else {
          this.val = "";
        }
      },
      immediate: true,
    },
  },

  data() {
    return {
      val: this.value,
      localId: this.id || this.__makeId(),
    };
  },

  methods: {
    blueVueNumeric() {
      // Access the vue-numeric component using the ref attribute
      const numericComponent = this.$refs.vueNumeric;

      // Check if the component exists and it has a blur method
      if (
        numericComponent &&
        numericComponent.$el &&
        numericComponent.$el.blur
      ) {
        numericComponent.$el.blur();
      }
    },
    onBlur() {
      if (!this.formatContinuously) {
        this.debouncedValueFormatted(this.val);
      }
      this.$emit('blur');
    }
  },

  created() {
    this.debouncedValueFormatted = debounce(function (newValue) {
      this.blueVueNumeric();

      this.$emit(
        "value-formatted",
        newValue.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    }, 500);
  },
};
</script>

<style lang="scss">
.amount-input-container {
  margin-bottom: 28px;
}

.amount-input {
  display: flex;
  align-items: center;
  border-radius: 32px;
  border: 1px solid #d9d9d9;
  display: flex;
  height: 102px;
  padding: 22px 32px;

  &.error {
    border-color: #ff213a !important;
    background-color: transparent !important;
  }

  .custom-label {
    color: #838a90;
  }

  .formatted-input {
    width: 200px;
    max-width: 300px;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  .currency-wrapper {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}
</style>
