// initial state
const state = {
    items: [],
};

// getters
const getters = {
    items: (state) => state.items,
}

// mutations
const mutations = {
    items(state, payload) {
        state.items = payload;
    },
    delete(state, id) {
        const index = state.items.findIndex(item => item.id === id);
        if (index !== -1) {
            state.items.splice(index, 1);
        }
    },
}

// actions
const actions = {
    getItems({ commit, dispatch }) {
        return axios
            .get('/settings/security/sessions')
            .then(response => {
                commit('items', response.data.data);
                return response.data;
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.message}, { root: true });
                throw error;
            });
    },
    delete({ commit }, id) {
        return axios.delete(`/settings/security/sessions/${id}`).then(response => {
            commit('delete', id);
            return response.data;
        }).catch(error => {
            commit('setAlert', {type: 'error', message: error.message}, { root: true });
            throw error;
        });
    },
    onceGetItems: _.once(function ({ dispatch }) {
        return dispatch('getItems');
    }),
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
