<template>
    <v-row style="margin:0px !important" class="modal-onboarding-finish">
        <v-col cols="12" style="padding: 0px !important;">
            <div class="modal-header"> </div>
            <div class="modal-body">
<!--                <button
                        @click="close"
                        data-toggle="modal"
                        data-target="#greetingsmodal"
                        data-dismiss="modal"
                        aria-label="Close"
                        class="btn btn-icon btn-close color-black"
                >
                    <i class="icon-times"></i>
                </button>-->
                <img :src="$store.state.imagePath+'logo-bg-white-circle.png'" alt="logo-bg-white-circle.png"/>
                <h1 class="heading-1 bold-text color-purple text-center mb-10">
                    You are all set up, {{$store.state.info.name}}!
                </h1>
                <h2 class="heading-2 color-dark-grey text-center mb-30 pl-1 pr-1">
                    Now you can sit back and watch our Intro to Native Teams video, which will give you a kick <br> start on what Native Teams is all about!
                </h2>
                <div class="video-list-wrapper">
                    <div class="w-100" style="overflow-x: auto">

                        <ul class="video-list">
                            <li>
                                <p class="medium-text color-dark-grey text-center mb-1">
                                    Intro to Native Teams
                                </p>
                                <video @playing="updatePaused" @pause="updatePaused" controls controlsList="nodownload" style="width:160px;height:90px;">
                                    <source src="https://d121mxvqzcpef0.cloudfront.net/Intro%201.mp4" type='video/mp4'>
                                    <p class="vjs-no-js">
                                        To view this video please enable JavaScript, and consider upgrading to a web browser that
                                        <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                                    </p>
                                </video>
                            </li>
<!--                            <li>
                                <p class="medium-text color-dark-grey text-center mb-1">
                                    General Walkthrough
                                </p>
                                <video @playing="updatePaused" @pause="updatePaused" controls controlsList="nodownload" style="width:160px;height:90px;">
                                    <source src="https://d121mxvqzcpef0.cloudfront.net/General Walkthrough .mp4" type='video/mp4'>
                                    <p class="vjs-no-js">
                                        To view this video please enable JavaScript, and consider upgrading to a web browser that
                                        <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                                    </p>
                                </video>
                            </li>
                            <li>
                                <p class="medium-text color-dark-grey text-center mb-1">
                                    Payments & Invoicing
                                </p>
                                <video @playing="updatePaused" @pause="updatePaused" controls controlsList="nodownload" style="width:160px;height:90px;">
                                    <source src="https://d121mxvqzcpef0.cloudfront.net/Payments & Invoicing.mp4" type='video/mp4'>
                                    <p class="vjs-no-js">
                                        To view this video please enable JavaScript, and consider upgrading to a web browser that
                                        <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                                    </p>
                                </video>
                            </li>
                            <li>
                                <p class="medium-text color-dark-grey text-center mb-1">
                                    Employer of Record
                                </p>
                                <video @playing="updatePaused" @pause="updatePaused" controls controlsList="nodownload" style="width:160px;height:90px;">
                                    <source src="https://d121mxvqzcpef0.cloudfront.net/EoR Final.mp4" type='video/mp4'>
                                    <p class="vjs-no-js">
                                        To view this video please enable JavaScript, and consider upgrading to a web browser that
                                        <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                                    </p>
                                </video>
                            </li>
                            <li>
                                <p class="medium-text color-dark-grey text-center mb-1">
                                    Jobs
                                </p>
                                <video @playing="updatePaused" @pause="updatePaused" controls controlsList="nodownload" style="width:160px;height:90px;">
                                    <source src="https://d121mxvqzcpef0.cloudfront.net/Jobs.mp4" type='video/mp4'>
                                    <p class="vjs-no-js">
                                        To view this video please enable JavaScript, and consider upgrading to a web browser that
                                        <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                                    </p>
                                </video>
                            </li>-->
                        </ul>
                    </div>
                    <btn @click="done" class="btn btn-start-intro-video btn-purple medium-text bold-text color-white">
                        Start Intro Video
                    </btn>
                </div>
            </div>
        </v-col>
    </v-row>
</template>
<script>
import Btn from "~/shared/components/Btn";
import Avatar from "~/shared/components/Avatar";
export default {
    name: 'Finished',
    components: {Avatar, Btn},
    data() {
        return {
            videoElement: null
        }
    },
    computed: {
        playing() { return !this.paused; }
    },
    methods: {
        updatePaused(event) {
            this.videoElement = event.target;
            this.paused = event.target.paused;
        },
        pause(){
            if(this.videoElement) {
                this.videoElement.pause();
            }
            this.nextVideoDialog = false;
        },
        done(){
            this.pause();
            this.$emit('done');
        },
        close(){
            this.pause();
            this.$emit('close');
        }
    }
}
</script>
