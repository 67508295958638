<template>
  <div class="custom-toggle-switch row">
    <BaseButton
      @click="toggle(1)"
      class="btn-small col base-toggle-switch-button"
      :class="[
        customButton1Classes,
        activeCard === 1 ? 'btn-purple font-medium color-white' : 'color-grey-2'
      ]"
    >
      {{ label1 }}
    </BaseButton>
    <BaseButton
      @click="toggle(2)"
      class="btn-small col bg-white base-toggle-switch-button"
      :class="[
        customButton2Classes,
        activeCard === 2
          ? 'btn-purple font-medium color-white'
          : 'color-grey-2',
      ]"
    >
      {{ label2 }}
    </BaseButton>
  </div>
</template>

<script>
export default {
  name: "BaseToggleSwitch",

  props: {
    label1: {
      type: String,
      default: "",
    },
    label2: {
      type: String,
      default: "",
    },
    activeCard: {
      type: Number,
      default: 1,
    },
    customButton1Classes: {
      type: String,
      default: '',
    },
    customButton2Classes: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      activeClass: "btn-small btn-purple text-sm font-medium color-white",
      inactiveClass: "btn-small text-sm color-grey-2",
    };
  },

  methods: {
    toggle(val) {
      this.$emit("input", val);
    },
  },
};
</script>
