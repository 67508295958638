<template>
  <div class="modal-container modal-info" :class="heightClass">
    <div class="modal-header">
      <WlBaseIcon
        :name="modalData.icon.name"
        :class="modalData.icon.class"
        class="p-2"
        v-if="modalData.icon"
      />
    </div>
    <div class="modal-body">
      <h2 class="heading-2 leading-120 font-bold color-black" >
        {{ modalData.title }}
      </h2>
      <template v-if="!$slots['default']">
        <p  class="text-sm font-medium color-grey-2 leading-150" v-html="__sanitizeHtml(modalData.text)">
        </p>
      </template>
      <template v-else>
        <slot></slot>
      </template>
    </div>
      <div
          class="modal-footer justify-content-center"
          v-if="modalData.btnText || $slots['underButton']"
      >
      <WlBaseButton
        v-if="modalData.btnText"
        type="primary"
        :disabled="invalid"
        :loading="modalData.loading"
        @click="$emit('on-confirm'), $emit('close-modal')"
        class="color-white w-full"
      >
        {{ modalData.btnText }}
      </WlBaseButton>
      <slot name="underButton"></slot>
    </div>
  </div>
</template>

<script>
import Helpers from "~/shared/mixins/Helpers";

export default {
  name: "ModalInfo",

  mixins: [Helpers],

  props: {
    modalData: {
      type: Object,
      default: () => {},
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    heightClass: {
      type: String,
      default: "",
    },
    textCenter:{
      default: true,
    }
  },
};
</script>

<style lang="scss">
.modal-info {
    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 28px;
    }
}
@media screen and (max-width: 768px) {
    .modal-info {
        .modal-body {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
}
</style>
