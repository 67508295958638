import Middleware from "~/routes/middleware/Middleware";

export default class businessAccount extends Middleware{
    constructor(...args) {
        super(...args);
    }

    async resolve() {
        const data = {
            isBusinessAcc: true,
            isInvoice: this.to.path.includes('invoice-payment')
        }

        return this.responseNext(data)
    }
}
