<!-- @format -->

<template>
    <v-app class="new-wallet-app">

        <v-main v-resize="onResize" class="tm-bg-body">
            <!--Content-->
            <div class="d-flex h-100">
                <div class="container-fluid login-layout">
                    <div class="row h-100">
                        <main class="col-12 h-100">
                            <div class="container-fluid h-100">
                                <div class="row justify-content-center justify-content-md-between h-100">
                                    <div class="auth-page col-12">
                                        <div class="logo-container">
                                            <router-link :to="{path: '/login'}">
                                                <v-img
                                                    contain
                                                    class="logo-image"
                                                    src="/images/logos/logo-black.svg"
                                                />
                                            </router-link>
                                        </div>

                                        <!-- CONTENT -->
                                        <router-view></router-view>

                                        <!-- POLICY CONTAINER -->
                                        <PolicyContainer/>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>

            <!-- Alert modal -->
            <WlBaseModal
                v-model="alertModal.value"
                class-names="base-modal-alert-wallet"
                width="25rem"
            >
                <ModalInfo
                    :modal-data="alertModal.data"
                    @on-confirm="alertModal.value = false, alertModal.onClose()"
                />
            </WlBaseModal>
        </v-main>
    </v-app>
</template>

<script>
import Modal from '~/shared/components/Modal';
import Screen from '~/shared/mixins/Screen';
import ModalInfo from "~/components/Modals/ModalInfo.vue";


import {mapGetters} from "vuex";
import PolicyContainer from "~/modules/auth/components/PolicyContainer.vue";
export default {
    name: 'AuthApp',
    components: {
        PolicyContainer,
        ModalInfo,
        Modal,
    },
	props: {
		pageData: {
			default: () => {}
		}
	},
    mixins: [Screen],
    data() {
        return {

        };
    },
    computed: {
        alert() {
            return this.$store.state.alert;
        },
        alertModal() {
            return this.$store.state.alertModal;
        },
    },
    methods: {
        onResize() {
            this.$store.commit('setData', {
                key: 'window',
                data: { width: window.innerWidth, height: window.innerHeight },
            });
        },
    },
    mounted() {
    },
    created() {
    },
};
</script>

<style scoped lang="scss">
.login-layout {
    padding: 44px 44px 0 44px;
    height: 100%;
    background-image: url("../assets/images/login/login-layout-background.svg");
    background-size: cover;
}
.new-wallet-app{
    background-color: #ebeef2!important;
}
@media screen and (max-width: 992px) {
    .login-layout {
        background-image: url("../assets/images/login/login-layout-background-tablet.svg");
        background-position: center;
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media screen and (max-width: 768px) {
    .login-layout {
        background-image: url("../assets/images/login/login-layout-background-mobile.svg");
    }
}
</style>
