import store from "~/store";
import Echo from "laravel-echo";

export default class initBroadcast{
    resolve() {
        if (store.state.auth){
            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: process.env.VUE_APP_PUSHER_APP_KEY,
                cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
                forceTLS: true,
                wsHost: process.env.VUE_APP_PUSHER_APP_HOST,
                encrypted: true,
                disableStats: true,
                authEndpoint: process.env.VUE_APP_PUSHER_APP_AUTH_ENDPOINT,
                auth: {
                    headers: {
                        Authorization: 'Bearer ' + store.state.auth.token
                    },
                },
            });
        }
    }
}
