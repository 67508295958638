<template>
    <v-row style="margin:0 !important">
        <v-col cols="12" lg="12">
            <div class="modal-header">
                <h2 class="heading-2 color-purple mb-10 mt-20">
                    Want to book a one-to-one onboarding call instead?
                </h2>
            </div>
            <div class="modal-footer d-flex pr-0" style="justify-content: flex-end;">
                <button
                        @click="$emit('close')"
                        class="btn btn-onboarding-skip custom-btn-secondary medium-text bold-text color-purple mr-10">
                    No, thanks
                </button>
                <a
                        href="https://nativeteams.com/book-a-demo"
                        target="_blank"
                        @click="$emit('close')"
                        class="btn btn-onboarding-skip custom-btn-primary medium-text bold-text color-white">
                    Yes, please
                </a>
            </div>
        </v-col>
    </v-row>
</template>
<script>
import Modal from "~/shared/components/Modal";
import Btn from "~/shared/components/Btn";
export default {
    name: 'SkipModal',
    components: {Btn, Modal},
    props: {},
    data(){
        return {}
    },
}
</script>
