<template>
    <div :class="bottomOffset? 'mb-4': 'mb-0'" style="position: relative">
        <label>
            <div v-if="label" class="tm-small-text font-weight-medium black--text mb-1" style="text-transform: inherit">
                {{ label }}
            </div>
            <div
                :class="layout && !borderRadius? 'rounded-0': null"
                class="tm-form-image-box"
                :style="{

                    height: boxHeight,
                    width: boxWidth,
                    'border-radius': borderRadius,
                    'background-color': previewImage || $slots.preview? 'transparent': null
                }"
            >
                <v-hover v-if="previewImage && !iconInput">
                    <template v-slot:default="{ hover }">
                        <div style="position: relative">
                            <v-img :contain="previewContain" :height="boxHeight" :width="layout? '100%': boxWidth" :src="previewImage"></v-img>
                            <v-fade-transition v-if="!disabled">
                                <v-overlay
                                    v-if="hover && buttons"
                                    absolute
                                    color="black"
                                >
                                    <div class="d-flex">
                                        <v-btn @click="update()" fab color="primary" x-small>
                                            <v-icon size="16">mdi-refresh</v-icon>
                                        </v-btn>
                                        <v-btn v-if="removable" @click.prevent="clear()" fab color="red" x-small class="ml-2">
                                            <icon size="16" color="white" name="icon-trash"></icon>
                                        </v-btn>
                                    </div>
                                </v-overlay>
                            </v-fade-transition>
                        </div>
                    </template>
                </v-hover>
                <slot v-else-if="$slots.preview" name="preview"></slot>
                <icon v-else name="icon-image" size="24" color="#1d2c40"></icon>
            </div>
            <input
                v-if="!disabled"
                ref="input"
                @change="setPreview"
                type="file"
                class="d-none"
                :id="id"
            >
        </label>

        <v-dialog v-if="crop" v-model="cropDialog" :width="cropDialogWidth" persistent :retain-focus="false">
            <v-card class="pa-4 d-flex justify-center">
                <div class="w-100">
                    <p v-if="cropResolution" class="tm-color-black font-weight-medium mb-2">{{ cropResolution }}</p>
                    <div
                        :style="{width: __getCurrentPixels(cropWidth)+'px', height: __getCurrentPixels(cropHeight)+'px', 'border-radius': cropRounded? '50%': 'none'}"
                        class="mb-4 mx-auto position-relative"
                        style="cursor: all-scroll; border: 1px dashed; overflow: hidden"
                    >
                        <croppa
                            :style="{height: cropHeight+'px'}"
                            v-model="cropped"
                            :width="__getCurrentPixels(cropWidth)"
                            :height="__getCurrentPixels(cropHeight)"
                            :disabled="false"
                            :prevent-white-space="preventWhiteSpace"
                            :show-remove-button="false"
                        >
                            <img crossOrigin="anonymous" :src="previewImage" slot="initial">
                        </croppa>
                    </div>
                    <ul class="mb-4">
                        <li class="tm-medium-text tm-color-black">mouse <v-icon>mdi-mouse</v-icon>: Drag image.</li>
                        <li class="tm-medium-text tm-color-black">mouse wheel<img style="height: 25px" :src="$store.state.imagePath+'wheel.png'">: Zoom image.</li>
                    </ul>
                    <div class="d-flex justify-end">
                        <btn class="mr-2" :small="true" :btn-text="true" text="cancel" color="dark-pink" @click="cancelCrop()"></btn>
                        <btn :small="true" text="crop" @click="getCropped()"></btn>
                    </div>
                </div>
            </v-card>
        </v-dialog>

        <error-message :left="errLeft" :center="true" :messages="errorMessages"></error-message>
    </div>
</template>
<script>
import ErrorMessage from "./ErrorMessage";
import Btn from "../Btn";
import Screen from "~/shared/mixins/Screen";
import heic2any from "heic2any";

export default {
    name: 'TmAvatarInput',
    mixins: [Screen],
    components: {Btn, ErrorMessage},
    props: {
        disabled: {
            default: false
        },
        preventWhiteSpace: {
            type: Boolean,
            default: true
        },
        value: {
            default: null
        },
        label: {
            default: null
        },
        preview: {
            default: null
        },
        size: {
            default: 120
        },
        rem: {
            type: Boolean,
            default: false
        },
        iconInput: {
            type: Boolean,
            default: false
        },
        sizeHeight: {
            default: null
        },
        sizeWidth: {
            default: null
        },
        removable: {
            default: true
        },
        buttons: {
            default: true
        },
        layout: {
            type: Boolean,
            default: false
        },
        bottomOffset: {
            default: true
        },
        crop: {
            type: Boolean,
            default: false
        },
        id: {
            default: ''
        },
        cropHeight: {
            default: 100
        },
        cropWidth: {
            default: 100
        },
        cropRounded: {
            type: Boolean,
            default: false
        },
        cropResolution: {
            default: null
        },
        cropDialogWidth: {
            default: 930
        },
        errLeft: {
            type: Boolean,
            default: false
        },
        previewContain: {
            type: Boolean,
            default: false
        },
        borderRadius: {
            default: null
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data(){
        return {
            val: this.value,
            previewImage: this.preview,

            cropDialog: false,
            valCropped: false,
            cropped: {}
        }
    },
    computed: {
        boxWidth(){
            return this.sizeWidth? this.getSize(this.sizeWidth): this.getSize(this.size);
        },
        boxHeight(){
            return this.sizeHeight? this.getSize(this.sizeHeight): this.getSize(this.size);
        },
    },
    methods: {
        getSize(val){
            return val && isNaN(val)? val: this.__getCurrentPixels(val)+'px'
        },

        isHeic(file) {
            let fileName = file.name;
            let fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
            return (fileNameExt === "heic" || fileNameExt === "heif");
        },

        setPreview(e, fileObj = false){
            const file = fileObj? e: e.target.files[0];
            if(this.isHeic(file)) {
                // this part of code for converting heic and heif images to jpeg
                heic2any({
                    blob: file,
                    toType: "image/jpeg",
                })
                    .then((resultBlob) => {
                        this.previewImage = URL.createObjectURL(resultBlob);
                        this.val = new File(
                            [resultBlob],
                            new Date().getTime() + ".jpeg",
                            {
                                type:"image/jpeg",
                                lastModified:new Date().getTime()
                            }
                        );
                    });
            } else {
                this.val = file;
                this.previewImage = URL.createObjectURL(file);
            }

            if (this.crop){
                this.cropDialog = true;
            }
        },

        getCropped(){
            this.cropped.generateBlob((blob) => {
                let url = URL.createObjectURL(blob)
                this.val = new File([blob], new Date().getTime() + ".jpeg", {
                    type: "jpeg",
                    lastModified: Date.now()
                })
                this.cropDialog = false;
                this.valCropped = true;
                this.previewImage = url;
                this.$emit('cropped', this.val);
                this.$emit('previewImage', this.previewImage);
            });
        },
        cancelCrop(){
            this.val = null;
            this.cropDialog = false;
        },

        clear(){
            this.$emit('clear')
            this.val = null;
            this.$refs.input.value = null
        },
        update(){
            this.$refs.input.value = null
            this.$refs.input.click()
        }
    },
    mounted() {
        this.$emit('mounted');
    },
    watch: {
        val(){
            this.$emit('input', this.val);
            this.$emit('cancelClear');
            if (this.val){
                if (!this.valCropped){
                    this.setPreview(this.val, true)
                }
            }else{
                this.previewImage = this.preview;
            }
        },
        value(){
            if (this.val !== this.value){
                this.valCropped = false;
            }
            this.val = this.value;
        },
        preview(){
            this.previewImage = this.preview;
        },
         previewImage(){
           this.$emit('previewImage',this.previewImage);
         }
    },
    created() {
        if (this.val){
            this.previewImage = URL.createObjectURL(this.val);
        }
    }
}
</script>
<style scoped>
>>> .v-dialog {
    overflow: hidden!important;
}
</style>
