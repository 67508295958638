<template>
    <v-dialog
        :value="dialog"
        @click:outside="closeModal"
        content-class="custom-modal modal-withdraw-money modal-md kyc-modal"
        scrollable
    >
        <div class="modal-container">
            <div class="modal-header">
                <WlBaseButton
                    @click="$emit('close-modal')"
                    class="btn-icon-sm ml-auto btn-close-modal"
                >
                    <WlBaseIcon name="times" class="text-lg" />
                </WlBaseButton>
                <div class="img-container">
                    <img
                        :src="`/images/wallet/withdrawal-graphic.svg`"
                        alt="KYC"
                    />
                </div>
            </div>
            <div class="modal-body">
                <h2 class="heading-2 font-bold color-black mb-4">
                    Are you sure you want to withdraw?
                </h2>
                <p class="text-sm leading-150 color-grey-1 mb-28">
                    Keeping your funds in your wallet has many benefits:
                </p>
                <p class="text-sm leading-150 color-grey-2 mb-3">
                    <WlBaseIcon class="color-purple" name="paycheck" />
                    Increase your savings with our balance rewards resulting in an impressive 4% annual return on your balance.
                </p>
                <p class="text-sm leading-150 color-grey-2 mb-3">
                    <WlBaseIcon class="color-purple" name="coins-2" />
                    Your savings will steadily grow over time, and you'll receive the reward based on the amount of balance you have with us each day.
                </p>
                <p class="text-sm leading-150 color-grey-2">
                    <WlBaseIcon class="color-purple" name="tax" />
                    The longer you keep funds in your wallets, the greater your rewards will grow!                </p>
            </div>
            <div class="modal-footer">
                <WlBaseButton type="primary" class="w-full mb-3" @click="withdrawalNow">
                    Withdraw now
                </WlBaseButton>
<!--                <WlBaseButton type="secondary" class="w-full" @click="withdrawalNow">
                    Withdrawal now
                </WlBaseButton>-->
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    name: "ModalWithdrawMoney",

    props: {
        dialog: {
            type: Boolean,
            required: true,
        },
    },

    methods: {
        closeModal() {
            this.$emit("close-modal");
        },
        withdrawalNow() {
            this.$emit("close-modal");
            this.$emit("withdrawal-now");
        },
    },
};
</script>

<style lang="scss" scoped>
.kyc-modal {
    .modal-header {
        .img-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }

    .heading-2 {
        font-size: 40px;
    }

    .modal-body {
        .text-sm {
            display: flex;
            // align-items: center;

            i {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 8px;
                font-size: 18px;
            }
        }
    }
}
</style>
