const state = {
    payroll: {},
    payrollContracts: [],
    payrollContractsQuery: {},
    payrollContractsPagination: {},
    payrollReports: [],
    payrollReportsQuery: {},
    payrollReportsPagination: {},
    breakdownStatus: 'unavailable',
    payslips: [],
    payslipsQuery: {},
    payslipsPagination: {},
};

const getters = {
    payroll: (state) => state.payroll,
    payrollContracts: (state) => state.payrollContracts,
    payrollContractsQuery: (state) => state.payrollContractsQuery,
    payrollContractsPagination: (state) => state.payrollContractsPagination,
    payrollReports: (state) => state.payrollReports,
    payrollReportsQuery: (state) => state.payrollReportsQuery,
    payrollReportsPagination: (state) => state.payrollReportsPagination,
    payslips: (state) => state.payslips,
    payslipsQuery: (state) => state.payslipsQuery,
    payslipsPagination: (state) => state.payslipsPagination,
    getBreakdownStatus: (state) => {
        return state.breakdownStatus;
        },
}

const mutations = {
    setPayroll(state, payload) {
        state.payroll = payload;
    },
    unsetPayroll(state){
        state.payroll = null;
    },
    payrollContracts(state, payload) {
        state.payrollContracts = payload;
    },
    payrollContractsQuery(state, payload) {
        state.payrollContractsQuery = payload;
    },
    payrollContractsPagination(state, payload) {
        state.payrollContractsPagination = payload;
    },
    payrollReports(state, payload) {
        state.payrollReports = payload;
    },
    payrollReportsQuery(state, payload) {
        state.payrollReportsQuery = payload;
    },
    payrollReportsPagination(state, payload) {
        state.payrollReportsPagination = payload;
    },
    payslips(state, payload) {
        state.payslips = payload;
    },
    payslipsQuery(state, payload) {
        state.payslipsQuery = payload;
    },
    payslipsPagination(state, payload) {
        state.payslipsPagination = payload;
    },
    SET_BREAKDOWN_STATUS(state, status) {
        state.breakdownStatus = status;
    },
}

const actions = {
    async getPayrollContracts({ state, commit }, q = {}) {
        const query = Object.assign({}, state.payrollContractsQuery);
        query.page = q.page || 1;

        for (const [key, value] of Object.entries(q)) {
            if(key !== 'page'){
                query[key] = value;
            }
        }
        await axios
            .get('/payroll/contracts/paginate', {
                params: query
            })
            .then(response => {
                commit('payrollContracts', response.data.data);
                commit('payrollContractsPagination', response.data.meta);
                return response.data;
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.message}, { root: true });
                throw error;
            });
    },
    async getPayslips({ state, commit }, q = {}) {
        const query = Object.assign({}, state.payslipsQuery);
        query.page = q.page || 1;

        for (const [key, value] of Object.entries(q)) {
            if(key !== 'page'){
                query[key] = value;
            }
        }
        await axios
            .get('/payroll/payslips/paginate', {
                params: query
            })
            .then(response => {
                commit('payslips', response.data.data);
                commit('payslipsPagination', response.data.meta);
                return response.data;
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.message}, { root: true });
                throw error;
            });
    },
    async getPayrollReports({ state, commit }, q = {}) {
        const query = Object.assign({}, state.payrollReportsQuery);
        query.page = q.page || 1;

        for (const [key, value] of Object.entries(q)) {
            if(key !== 'page'){
                query[key] = value;
            }
        }
        await axios
            .get('/payroll/reports/paginate', {
                params: query
            })
            .then(response => {
                commit('payrollReports', response.data.data);
                commit('payrollReportsPagination', response.data.meta);
                return response.data;
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.message}, { root: true });
                throw error;
            });
    },
    async discardPayroll({ commit }, payrollId){
        await axios.get('/payroll/discard/' + payrollId)
            .then(response => {
                commit('unsetPayroll');
                commit('setAlert', {type: 'info', message: response.data.message}, {root: true});
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.response.data.message}, {root: true});
                throw error;
            })
    },
    setBreakdownStatus({ commit }, status) {
        commit("SET_BREAKDOWN_STATUS", status);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
