// import { faker } from "@faker-js/faker";
// const { format } = require("date-fns");
export const namespaced = true;

// import { getTicketsData } from "~/modules/dashboard/utils/ticketsDataGeneration.js";

export const state = {
    tickets: []/*getTicketsData*/,
    selectedType: "all",
    selectedStatus: "pending",

    searchQuery: "",

    statuses: [
        {
            name: "Completed",
            icon: "check-circle-outline",
            color: "#30B362",
        },
        {
            name: "Pending",
            icon: "pending-circle-outline",
            color: "#F29D39",
        },
        {
            name: "Declined",
            icon: "info-circle-outline",
            color: "#F00",
        },
        {
            name: "Deleted",
            icon: "info-circle-outline",
            color: "#F00",
        },
    ],
};

export const mutations = {
    SET_STATUS(state, status) {
        state.selectedStatus = status;
    },

    SET_TYPE(state, type) {
        state.selectedType = type;
    },

    SET_SEARCH_QUERY(state, query) {
        state.searchQuery = query;
    },

    CREATE_TICKET(state, ticket) {
        state.tickets.push(ticket);
    },

    UPDATE_TICKET_STATUS(state, ticket) {
        state.tickets.forEach((item, index) => {
            if (item.id === ticket.id) {
                state.tickets[index].status = ticket.status;
            }
        });
    },
};

export const actions = {
    setStatus({commit}, status) {
        commit("SET_STATUS", status);
    },

    setType({commit}, type) {
        commit("SET_TYPE", type);
    },

    setSearchQuery({commit}, query) {
        commit("SET_SEARCH_QUERY", query);
    },

    createTicket({commit}, ticket) {
        const finalTicket = {
            id: faker.string.uuid(),
            submittedDate: format(new Date(), "dd/MM/yyyy"),
            status: "pending",
            ...ticket,
        };

        commit("CREATE_TICKET", finalTicket);
    },

    updateTicketStatus({commit}, ticket) {
        commit("UPDATE_TICKET_STATUS", ticket);
    },
};

export const getters = {
    getTickets: (state) => {
        if (state.searchQuery.length) {
            const filteredByStatus = state.tickets.filter(
                (item) =>
                    item.status === state.selectedStatus || state.selectedStatus === "all"
            );
            const filteredByType = filteredByStatus.filter(
                (item) =>
                    item.type === state.selectedType || state.selectedType === "all"
            );
            return filteredByType.filter(
                (item) =>
                    item.title.toLowerCase().includes(state.searchQuery.toLowerCase()) ||
                    item.submittedDate
                        .toLowerCase()
                        .includes(state.searchQuery.toLowerCase())
            );
        }
        const filteredByStatus = state.tickets.filter(
            (item) =>
                item.status === state.selectedStatus || state.selectedStatus === "all"
        );
        const filteredByType = filteredByStatus.filter(
            (item) => item.type === state.selectedType || state.selectedType === "all"
        );
        return filteredByType;
    },

    getTicketById: (state) => (id) => {
        return state.tickets.find((ticket) => ticket.id === id);
    },

    getStatuses: (state) => state.statuses,
};
