<!-- @format -->

<template>
    <v-dialog
        :value="dialog"
        content-class="custom-modal modal-md"
        @input="$emit('input', $event)"
        @click:outside="closeModal"
    >
        <ModalInfo :modal-data="modalData" @on-confirm="$emit('close-modal')">
        <template v-slot:default>
            <p  class="text-sm font-medium color-grey-2 leading-150">
                Kindly be aware that due to local holidays, there may be a slight delay in processing your
                transfers and in the responses from our support teams.

            </p>
            <p  class="text-sm font-medium color-grey-2 leading-150">
                We apologise for any inconvenience
                this may cause.
            </p>
        </template>
        </ModalInfo>
    </v-dialog>
</template>

<script>
import ModalInfo from '~/modules/wallet/components/Modals/ModalInfo.vue';
import { modalsMixinWithProps } from '~/modules/wallet/mixins/modalsMixinWithProps';

export default {
    name: 'ModalHolidayNotify',

    components: {
        ModalInfo,
    },
    mixins: [modalsMixinWithProps],

    data() {
        return {
            modalData: {
                icon: {
                    name: 'info-thin-circle-outline',
                    class: 'color-purple',
                },
                title: 'Delays due to local holidays',
                text:
                    'Kindly be aware that due to local holidays, there may be a slight delay in processing your\n' +
                    'transfers and in the responses from our support teams.',
                btnText: 'Close',
            },
        };
    },
};
</script>
