export const namespaced = true;

export const state = {
    currentBanner: null,
    depositOptedIn: false,
};

export const mutations = {
    SET_CURRENT_BANNER(state, banner) {
        state.currentBanner = banner;
    },
    SET_DEPOSIT_BANNER_OPTED_IN(state, optedIn) {
        state.depositOptedIn = optedIn;
    },
};

export const actions = {
    setCurrentBanner({commit}, banner) {
        commit("SET_CURRENT_BANNER", banner[0]?.dashboard_banner);
    },
    optInDeposit({commit}) {
        commit("SET_DEPOSIT_BANNER_OPTED_IN", true);
    },
};

export const getters = {
    currentBanner: (state) => state.currentBanner,
    depositOptedIn: (state) => state.depositOptedIn,
};
