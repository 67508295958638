<template>
    <div>
        <tm-advanced-select
            ref="select"
            :placeholder="placeholder"
            placeholder-class="placeholderClass"
            item-text="name"
            item-value="id"
            v-model="val"
            :search="exists"
            :search-route="$store.state.getPath+'search-contacts'"
            :search-additional-params="{id: val, type: contactType}"
            :search-autoload="true"
            search-key="name"
            :list-item-p="false"
            :list-p="false"
            :disable-selected="false"
            :list-item-class="listItemClass"
            :bottom-offset="bottomOffset"
            :label="label"
            :error-messages="errorMessages"
            :list-scroll="listScroll"
            :chevron-size="chevronSize"
            :applyInlineStyle="applyInlineStyle"
            :attach="attach"
            :min-width="minWidth"
            :select-custom="this.$store.state.contacts.preselectedContact"
            :menu-open="menuOpen"
            @inputObject="$emit('inputObject', $event)"
            @openMenu="menuOpen = $event"
        >
            <template v-if="exists" slot="itemsPrepend">
                <div class="group pl-2 pt-5 pr-2 mb-10">
                    <button @click="showDialog($event)" class="btn custom-btn-primary medium-text bold-text color-white">
                        Add contact
                    </button>
                </div>
                <p class="small-text-2 color-dark-grey pl-2">
                    Most recent contacts
                </p>
            </template>

            <template v-else slot="empty">
                <div class="no-contacts-wrapper mt-0 pa-4">
                    <i class="icon-card-contacts color-dark-grey mb-10"></i>
                    <p class="small-text-2 color-black text-center mb-2">
                        You don’t have any contacts yet.
                    </p>
                    <button
                        @click="showDialog($event)"
                        class="fs-13 btn btn-p-0 btn-h-auto  medium-text  bold-text color-purple "
                    >
                        <i class="icon-plus-circle-outline pr-1"></i>
                        Add Contacts
                    </button>
                </div>
            </template>

            <template v-if="viewSelectedAvatar" v-slot:selection="{item}">
                <div class="d-flex align-center">
                    <avatar :object="item" :size="avatarSize"></avatar>
                    <span class="employee-name small-text bold-text color-black tm-initial">
                        {{ item.name }}
                    </span>
                </div>
            </template>

            <template v-slot:item="{item}">
                <div class="tm-flex-dropdown-item">
                    <div class="worker-item">
                        <avatar :object="item" :size="avatarSize"></avatar>
                        <span class="employee-name small-text bold-text color-black tm-initial">
                            {{ item.name }}
                        </span>
                    </div>
                    <button
                        v-if="seatEmployerId !== item.id"
                        @click.stop="showDialog($event, item)"
                        class="edit-contact medium-text bold-text color-dark-grey btn btn-p-0 btn-h-auto btn-icon ml-auto" style="padding:0!important">
                        <i class="icon-edit fs-10"></i>
                    </button>
                    <button
                        v-if="seatEmployerId !== item.id"
                        @click.stop="remove($event, item.id)"
                        class="remove-contact-invoice btn btn-p-0 btn-h-auto btn-icon"
                        style="padding:0!important; padding-left: 7px !important; padding-right: 7px !important;"
                        :class="closeIconColorClass">
                        <i class="icon-times fs-10"></i>
                    </button>
                </div>
            </template>
        </tm-advanced-select>

        <!--Contact dialog-->
        <invoice-contact-form
            v-model="contactDialog"
            :contact-model="contactObj"
            :contact-type="contactType"
            @created="created($event)"
            @updated="updated()"
        ></invoice-contact-form>
    </div>
</template>
<script>
import TmAdvancedSelect from "./TmAdvancedSelect";
import InvoiceContactForm from "~/modules/dashboard/components/modals/InvoiceContactForm";

import Avatar from "../Avatar";
import axios from "axios";
const {mapActions, mapGetters} = require("vuex");
export default {
    name: 'TmSelectContact',
    components: {Avatar, InvoiceContactForm, TmAdvancedSelect},
    props: {
        contactType: {
            default: 'contact'
        },
        attach: {
            default: false
        },
        minWidth: {
            default: 150
        },
        bottomOffset: {
            default: true
        },
        label: {
            default: null
        },
        chevronSize: {
            default: '13'
        },
        value: {
            default: null
        },
        seat: {
            default: false
        },
        placeholder: {
            default: 'Who is it for?'
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            }
        },

        applyInlineStyle: {
            default: true
        },
        viewSelectedAvatar: {
            default: false
        },
        avatarSize: {
            default: '1.5625rem'
        },
        listScroll: {
            default: true
        },
        closeIconColorClass:{
            default: 'color-light-grey'
        },
        listItemClass: {
            default: ''
        },
        placeholderClass: {
            default: 'fs-13'
        },
        newContactModal: {
            default: false
        }
    },
    data(){
        return {
            contactDialog: false,
            contactObj: null,
            val: this.value,
            exists: true,
            seatEmployerId: null,
            dialog: this.value,
            menuOpen: null,

        }
    },
    computed: {
        ...mapGetters('contactsDialog', [
            'createdEmit',
            'updatedEmit',
        ]),
    },
    watch: {
        val(){
            this.$emit('input', this.val);
        },
        value(v){
            this.val = v;
            this.dialog = v;
        },

        dialog(){
            this.$emit('input', this.dialog);
        },

        createdEmit(v) {
            if (v) {
                this.created(v);
                this.emitCreatedObj();
            }
        },
        updatedEmit(v) {
            if (v) {
                this.updated();
                this.changerUpdatedEmit();
            }
        }
    },
    methods: {
        ...mapActions('contactsDialog', [
            'dialogContactShow',
            'emitCreatedObj',
            'changerUpdatedEmit',
        ]),
        showDialog($event, item = null){
            $event.preventDefault();
            this.contactObj = item;
            this.menuOpen = false;
            if (this.newContactModal) {
                let newObj = {
                    contact_model: item,
                    contact_type: this.contactType
                }
                this.dialogContactShow(newObj)
                return;
            }
            this.contactDialog = true;
            this.$refs.select.menu = false;
        },
        remove($event, id){
            $event.preventDefault();

            axios.delete(this.$store.state.path + 'people/contacts/delete/' + id, {data: {contactType: this.contactType}})
                .then(response => {
                    this.$store.commit("setAlert", {type: 'info', message: response.data.message});
                    let self = this;
                    setTimeout(function (){
                        self.$refs.select.searchByRoute()
                        self.getExists();
                    }, 100)
                })
                .catch(error => {
                    this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                })
        },
        created(item){
            let self = this;
            self.exists = true;
            setTimeout(function (){
                self.val = item.id;
                self.$refs.select.searchByRoute()
            }, 100)
        },
        updated(){
            let self = this;
            setTimeout(function (){
                self.$refs.select.searchByRoute()
            }, 100)
        },
        getExists(){
            let seat_contact = this.seat? 1 : 0;
            axios.get(this.$store.state.getPath + 'search-contacts', {params: { type: this.contactType, isSeat: seat_contact}})
                .then(response => {
                    if (seat_contact && response.data.length){
                        this.val = response.data[response.data.length - 1].id;
                        this.seatEmployerId = response.data[response.data.length - 1].id;
                        response.data.slice(0,-1);
                    }
                    this.exists = !!response.data.length;
                })
                .catch(error => {
                    this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                })
        },
    },
    created() {
        this.getExists()
    }
}
</script>
<style scoped>
.tm-flex-dropdown-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.4375rem;
    padding-left: 0.625rem;
    margin-bottom: 0;
}
.v-list-item:last-child{
    margin-bottom: 8px;
}

#app .vue-repo-app .remove-contact-invoice:hover{
    background-color: #ffffff00 !important;
}
</style>
