<!-- @format -->
<template>
    <div class="d-flex align-center">
        <base-avatar
            v-if="isAvatar(item)"
            :key="item[itemValue]"
            size="20"
            :object="avatarObj ? __findByPath(item, avatarObj) : item"
            :class="item.customClassesAvatar"
        ></base-avatar>
        <span class="color-black text-small text-no-wrap">
            {{ typeof itemText === 'function' ? itemText(item) : __findByPath(item, itemText) }}
        </span>
    </div>
</template>
<script>
import Form from '~/shared/mixins/Form';

export default {
    name: 'BaseSelectItem',
    mixins: [Form],
    props: {
        avatar: Boolean | Function,
        avatarObj: null,
        item: Object,
        itemText: String | Function,
        itemValue: String,
    },
    methods: {
        isAvatar(item) {
            return 'function' === typeof this.avatar ? this.avatar(item) : false !== this.avatar;
        },
    },
};
</script>