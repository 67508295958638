import Middleware from "~/routes/middleware/Middleware";
import store from "~/store";

export default class tfaGuest extends Middleware{
    constructor(...args) {
        super(...args);
    }

    async resolve() {
        const isAuthorized = store.getters['auth/__isAuthResolved'];
        const isTfaAuthorized = store.getters['auth/__isTfaResolved'];
        if (!isAuthorized) {
            await store.dispatch('auth/__unAuthorize')
            return this.responseRedirect(store.state.auth.loginPath);
        }
        if (isTfaAuthorized) {
            return this.responseRedirect(store.state.auth.afterLoginPath);
        }

        return this.responseNext();
    }
}
