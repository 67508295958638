
const ErrorPage = () => import('~/modules/wallet/views/ErrorPage.vue');
const PaymentExpired = () => import('~/modules/dashboard/views/errors/PaymentExpired.vue');
const ComingSoon = () => import('~/modules/dashboard/views/ComingSoon.vue');

export default [
    {
        path: '*',
        name: 'Error404',
        component: ErrorPage,
        meta: {
            title: 'Page not found',
            app: 'wizard',
        },
    },
    {
        path: '/404',
        name: 'Error404Page',
        component: ErrorPage,
        meta: {
            title: 'Page not found',
            app: 'wizard',
        },
    },
    {
        path: '/coming-soon',
        name: 'comingSoon',
        component: ComingSoon,
        meta: {
            title: 'Coming soon',
            app: 'wizard',
        },
    },
    {
        path: '/payment-expired',
        name: 'PaymentExpired',
        component: PaymentExpired,
        meta: {
            title: 'Expired payment link',
            app: 'empty',
        },
    },
    {
        path: '/eor-invoice-expired',
        name: 'EorInvoiceExpired',
        component:  () => import('~/modules/guest/views/EorInvoice/EorInvoiceExpired.vue'),
        meta: {
            title: 'Expired eor invoice link',
            app: 'empty',
        },
    },
    {
        path: '/change-organization/:id/:route',
        name: 'ChangeOrganization',
        meta: {
            middleware: ['auth', 'changeOrganization']
        }
    }
]
