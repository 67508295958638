export const namespaced = true;

export const state = {
  window: {
    width: 0,
    height: 0,
  },
};

export const mutations = {
  SET_DATA(state, payload) {
    state.window.width = payload.data.width;
    state.window.height = payload.data.height;
  },
};

export const actions = {
  setData({ commit }) {
    commit("SET_DATA", {
      width: window.innerWidth,
      height: window.innerHeight,
    });
  },
};

export const getters = {
  window: (state) => {
    return state.window;
  },
};
