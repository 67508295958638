<template>
    <div class="d-flex welcome-fix-res">
        <v-col cols="12" lg="2" class="modal-onboarding-progress step-1-active">
            <img :src="$store.state.imagePath+'logo-tilted-o-5-big.png'" alt="logo-tilted-o-5-big.png"/>
        </v-col>
        <v-col cols="12" lg="10" class="modal-onboarding-content">
            <div class="modal-header">
                <h2 class="heading-2 bold-text color-black mb-10 mt-10">
                    Welcome to
                    <span class="strong-text color-purple">Native Teams!</span>
                </h2>
                <p class="normal-text color-dark-grey">
                    Let us know about yourself, and we’ll personalise your Native Teams experience to suit your needs perfectly.
                </p>
            </div>
            <div class="modal-body">
                <div class="who-step onboarding-modal-step step-1 active">
                    <form class="onboarding-form">
                        <div class="form-group">
                            <label class="medium-text color-dark-grey mb-3">1. WHO ARE YOU?</label>
                            <div class="flex-form-group mt-1">
                                <h2 class="heading-2 light-weight color-black">
                                    I am
                                </h2>
                                <tm-advanced-select
                                    name="type"
                                    v-model="greetingObj.values.type_id"
                                    item-value="id"
                                    item-text="name"
                                    :items="types"
                                    v-validate="greetingObj.rules.type_id"
                                    :error-messages="errors.collect('type')"
                                    class="transparent-select"
                                    :bottomOffset="false"
                                    data-vv-as="type"
                                >
                                    <template v-slot:selection="{ item }">
                                        <div class="d-flex align-center">
                                            <span v-if="item.value === 'unemployed'" class="tm-color-black mr-1" style="text-decoration:underline;">
                                                {{ item.name }}
                                            </span>
                                            <span v-else class="tm-color-black mr-1" style="text-decoration:underline; text-transform: lowercase;">
                                                {{item.value === 'employee' || item.value === 'employer' ? 'An' : 'A'}} {{ item.name }}
                                            </span>
                                        </div>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <div class="d-flex align-center">
                                            <span v-if="item.value === 'unemployed'" class="tm-color-black mr-1">
                                                {{ item.name }}
                                            </span>
                                            <span v-else class="tm-color-black mr-1" style="text-transform: lowercase;">
                                                {{item.value === 'employee' || item.value === 'employer' ? 'An' : 'A'}} {{ item.name }}
                                            </span>
                                        </div>
                                    </template>
                                </tm-advanced-select>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer d-flex pr-0" style="justify-content: flex-end;margin-right:-10px;">
                <button
                        @click="$emit('skip')"
                        class="btn btn-onboarding-skip custom-btn-secondary medium-text bold-text color-purple mr-10">
                    Skip
                </button>
                <button
                        :disabled="!isValid || !selectedType"
                        @click="$emit('next')"
                        class="btn btn-onboarding-skip custom-btn-primary medium-text bold-text color-white">
                    Next
                </button>
            </div>
        </v-col>
    </div>
</template>
<script>
import Modal from "~/shared/components/Modal";
import Btn from "~/shared/components/Btn";
import TmAdvancedSelect from "~/shared/components/form/TmAdvancedSelect";
export default {
    name: 'Welcome',
    components: {TmAdvancedSelect, Btn, Modal},
    props: {
        types: {
            required: true,
            type: Array
        },
        greetingObj: {
            required: true,
            type: Object
        },
    },
    computed: {
        isValid() {
            return !Object.keys(this.fields).some(key => this.fields[key].invalid)
        },
        selectedType(){
            return this.greetingObj.values.type_id? this.types.find(item => item.id === this.greetingObj.values.type_id): null
        }
    }
}
</script>
