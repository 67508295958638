export function __config(key){
    const value = process.env[key]

    if (!value){
        return null;
    }

    switch (value) {
        case '0':
            return 0;
        case '1':
            return 1;
        case 'false':
        case 'FALSE':
        case 'n':
        case 'N':
            return false;
        case 'true':
        case 'TRUE':
        case 'y':
        case 'Y':
            return true;
        default:
            return value;
    }
}

/*@env: local, dev*/
export const __isDevMode = () => process.env.NODE_ENV === 'development';

/*@env: stage, prod*/
export const __isProdMode = () => !__isDevMode();

export const __isLocal = () => process.env.VUE_APP_ENV === 'local';
export const __isDev = () => process.env.VUE_APP_ENV === 'dev';
export const __isStage = () => process.env.VUE_APP_ENV === 'stage';
export const __isProd = () => process.env.VUE_APP_ENV === 'prod';
export const __isEnv = (val) => process.env.VUE_APP_ENV === val;
