<template>
    <div class="modal-container custom-modal kyc-intro-modal">
        <div class="modal-header" style="align-items: baseline !important;">
            <h2 class="heading-2 font-medium color-black" style="width:90%">
                {{ title }}
            </h2>
            <button class="btn-p-0 color-dark-grey small-text ml-0 mt-20" @click="$emit('close')">
                <i class="icon-times"></i>
            </button>
        </div>
        <div class="modal-body">
            <v-img
                src="/images/kyc-modals/kyc-modal-img2.svg"
                width="70%"
                class="mb-5 m-auto"
            />

            <p class="text-base leading-130 color-grey-2 mb-2">
                {{ description }}
            </p>
        </div>

        <div class="modal-footer mt-3">
            <BaseButton
                type="button"
                class="btn-big mt-4 btn-purple color-white w-100 v-btn-prevent-opacity-change"
                @click="$emit('start')"
            >
                Start verification
            </BaseButton>
        </div>
    </div>
</template>

<script>
export default {
    name: "KYCIntroCard",
    props: {
        card: {
            required: true,
            type: String
        }
    },
    computed: {
        KYCLevel(){
            return this.$store.state.kyc.options.KYC_level;
        },
        isBasic(){
            return this.KYCLevel === this.$store.state.kyc.KYCLevel2;
        },
        title(){
            return this.card === 'unl'?
                (this.isBasic? 'Additional information required': 'Complete KYC for your card order today'):
                'Verify your identity to order your card';
        },
        description(){
            return this.card === 'unl'?
                (this.isBasic?
                    `Please note that ordering this card requires additional
                    verification steps, even if you've completed our initial KYC
                    process. We appreciate your understanding as we ensure the
                    security and compliance of all card orders. Proceed with the
                    necessary steps to complete your order.`:
                    `You're eligible to order our Unlimit card! To proceed, we
                    require additional information from you. Once approved, your
                    card will be automatically requested. Let's gather the
                    necessary details to expedite your order!`):
                `Before proceeding with your card order, please complete our
                 quick and easy KYC verification process. This ensures
                 security and compliance. Once your KYC is successfully
                 passed, you can proceed to order your card.`;
        }
    }
};
</script>
