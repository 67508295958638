<template>
    <div class="d-flex flex-column position-relative" :class="bottomOffset? 'mb-1-rem': 'mb-0'">
        <label :for="id" class="d-flex" :class="[id? `label_`+id: null, 'align-'+align]">
            <input
                :id="id"
                @change="$emit('change', val)"
                v-model="val"
                :multiple="array"
                :value="itemValue"
                type="checkbox"
                class="tm-input"
                :disabled="!!disabled"
                :class="[rightOffset && 'mr-2' || 'mr-0', disabled? 'disabled': '']"
            >
            <slot v-if="$slots.label" name="label"></slot>
            <span v-else class="tm-small-text tm-initial tm-color-black" :class="labelClass">{{label}}</span>
        </label>
        <error-message :messages="errorMessages"></error-message>
    </div>
</template>
<script>
import ErrorMessage from "./ErrorMessage";
export default {
    name: 'TmCheckbox',
    components: {ErrorMessage},
    props: {
        id: {
            default: null
        },
        bottomOffset: {
            default: true
        },
        rightOffset: {
            default: true
        },
        label: {
            default: null
        },
        value: {
            default: null
        },
        itemValue: {
            default: 1
        },
        nullable: {
            default: false
        },
        array: {
            default: false
        },
        disabled: {
            default: false
        },
        align: {
            default: 'center'
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            }
        },
        labelClass: {
            default: ''
        }
    },
    data(){
        return {
            val: this.value
        }
    },
    watch: {
        val(){
            if (this.array){
                this.$emit('input', this.val)
            }else{
                this.$emit('input', this.val? 1: this.nullable? null: 0)
            }
        },
        value(){
            this.val = this.value;
        }
    },
}
</script>
