/** @format */

import Model from '~/shared/Model';

export default class WalletToWalletBatch extends Model {
    constructor(obj = {}) {
        super();
        this.hidden = ['conversion', 'subtotal'];

        this.values = {
            from: obj.from || '',
            to_currency: obj.to_currency || '',
            amount: obj.amount || '',
            type: obj.type || '',
            note: obj.note || '',
            transfer_bank: obj.transfer_bank || '',
            transfer_bank_id: obj.transfer_bank_id || '',
            members: obj.members || [],
            edited: {},

            conversion: 1,
            subtotal: 0,
            org_id: obj.org_id || '',
            user_id: obj.user_id || '',
            batch: true,
        };

        this.rules = {
            from: 'required',
            to_currency: 'required',
            amount: 'required|min_value:1',
            type: 'required',
            note: 'required',
            members: 'required',
        };
    }

    removeMember(id) {
        this.values.members = this.values.members.filter(i => i !== id);
        if (this.values.edited[id]) {
            delete this.values.edited[id];
        }
    }

    subtotal(fee = 0) {
        return this.values.conversion && this.values.amount
            ? parseFloat(this.values.amount) * this.values.conversion - fee
            : 0;
    }
}
