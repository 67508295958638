<template>
    <v-app class="wallet-repo-app" :class="mobileBodyClass">

        <div class="container-fluid new-wallet-layout">
            <div class="row">
                <keep-alive>
                    <NewNavigation></NewNavigation>
                </keep-alive>
                <main role="main" class="col-12 dashboard-main ml-auto mr-auto">
                    <router-view class="mt-32" />
                </main>
            </div>
        </div>

        <!--Snackbar-->
        <v-snackbar v-model="alert.value" :color="alert.type" left shaped bottom>
            <div class="d-flex justify-space-between align-center">
                <span>{{ alert.message }}</span>
                <v-btn icon small @click="$store.commit('closeAlert')">
                    <v-icon small>mdi-close</v-icon>
                </v-btn>
            </div>
        </v-snackbar>

        <WlBaseModal v-model="alertModal.value" class-names="base-modal-alert-wallet" width="25rem">
            <ModalInfo v-model="alertModal.value" :modal-data="alertModal.data"
                       @on-confirm="alertModal.value = false, alertModal.onClose()" />
        </WlBaseModal>

        <!--Page Loader-->
        <loader />

        <!-- KYC verification -->
        <KYCVerification></KYCVerification>

        <!-- KYC banner -->
        <KYCBanner></KYCBanner>

        <!--Confirmation alert-->
        <confirmation type="user_password" :new-design="true" />
        <confirmation2fa :closeIconShow="false" :new-design="true" />
        <confirmation-upgrade :closeIconShow="false" :new-design="true" />

        <!-- User restriction modal -->
        <UserRestriction></UserRestriction>

        <!-- Two factor authentication -->
        <Security2faForm></Security2faForm>

        <ModalPendingMobilityRequest v-if="getDraftItem" />
    </v-app>
</template>

<script>

import Confirmation from '~/shared/components/Confirmation';
import Confirmation2fa from '~/shared/components/Confirmation2fa';
import ConfirmationUpgrade from '~/shared/components/ConfirmationUpgrade';
import ModalPendingMobilityRequest from "~/components/Mobility/Modals/ModalPendingMobilityRequest";
import KYCVerification from "~/modules/wallet/components/KYC/KYCVerification.vue";
import Loader from '~/shared/components/Loader';
import { commonHelpersMixin } from "~/modules/wallet/mixins/commonHelpersMixin";
import ModalInfo from "~/modules/wallet/components/Modals/ModalInfo.vue";
import Helpers from '~/shared/mixins/Helpers';
import KYCBanner from "~/modules/wallet/components/KYC/KYCBanner.vue";
import UserRestriction from "~/modules/wallet/components/KYC/UserRestriction.vue";
import Security2faForm from "~/modules/wallet/components/KYC/Security2faForm.vue";
import NewNavigation from "~/components/NewNavigation/NewNavigation.vue";
import { mapActions, mapGetters } from "vuex";
export default {
    name: "WalletApp",
    mixins: [commonHelpersMixin, Helpers],
    components: {
        Confirmation,
        Confirmation2fa,
        ConfirmationUpgrade,
        ModalPendingMobilityRequest,
        Security2faForm,
        UserRestriction,
        KYCBanner,
        KYCVerification,
        ModalInfo,
        Loader,
        NewNavigation
    },
    props: {
        pageData: {
            default: () => {}
        }
    },
    data(){
        return {}
    },
    computed: {
        ...mapGetters('cases', ['getDraftItem']),
        mobileBodyClass() {
            return this.$route.meta.mobileBodyClass || "";
        },
        alert() {
            return this.$store.state.alert;
        },
        alertModal() {
            return this.$store.state.alertModal;
        },
    },

    methods: {
        ...mapActions('cases', ['checkDraftMobility']),
        // onResize(){
        //     this.$store.commit('setData', {
        //         key: 'window',
        //         data: {width: window.innerWidth, height: window.innerHeight},
        //     });
        //     this.$store.commit('screen/SET_DATA', {
        //         key: 'window',
        //         data: {width: window.innerWidth, height: window.innerHeight},
        //     });
        // },
        // async getData(){
        //     this.setLoader(true);
        //
        //     await this.initializePageData(
        //         {
        //             url: `/api/initialization`,//or add also ?organization_id=3
        //             resources: [
        //                 'AuthUser',
        //                 'CurrentWorker',
        //                 'NavigationMenuItems',
        //                 'AvailableMenuItems',
        //                 'Organizations',
        //                 'CurrentOrganization',
        //                 'PayoneerParams',
        //                 'AccessAffiliates',
        //                 'KYC',
        //                 'TFA',
        //                 'LegalEntityStatus',
        //                 'PrimaryWorker',
        //             ]
        //         }).then((response) => {
        //         this.setAuth(this.returnResourceByName(response.data, 'AuthUser')).then(user => {
        //             this.setDashboardAuth(this.returnResourceByName(response.data, 'AuthUser'));
        //             this.setAuthUser(this.returnResourceByName(response.data, 'AuthUser'));
        //             //check user status corelated with unlimint
        //             this.checkCustomerStatus({
        //                 service: 'unlimint',
        //                 organization_id: user.organization.id ?? null
        //             });
        //             this.setCurrentWorker(this.returnResourceByName(response.data, 'CurrentWorker'));
        //             this.setDashboardCurrentWorker(this.returnResourceByName(response.data, 'CurrentWorker'));
        //             this.setDashboardPrimaryWorker(this.returnResourceByName(response.data, 'PrimaryWorker'));
        //             this.setNavigationMenuItems(this.returnResourceByName(response.data, 'NavigationMenuItems'));
        //             this.setAvailableMenuItems(this.returnResourceByName(response.data, 'AvailableMenuItems'));
        //             this.setOrganizations(this.returnResourceByName(response.data, 'Organizations'));
        //             this.setCurrentOrganization(this.returnResourceByName(response.data, 'CurrentOrganization'));
        //             this.setPayoneerParams(this.returnResourceByName(response.data, 'PayoneerParams'));
        //             this.setAccessAffiliates(this.returnResourceByName(response.data, 'AccessAffiliates'));
        //             this.setLegalEntityData(this.returnResourceByName(response.data, 'LegalEntityStatus'));
        //             this.__setKYCData(this.returnResourceByName(response.data, 'KYC'));
        //             this.__setTFAData(this.returnResourceByName(response.data, 'TFA'));
        //         });
        //     }).catch((error) => {
        //         console.log(error);
        //     }).finally(() => {
        //         this.setLoader(false);
        //     });
        // }
    },
    mounted() {
        // this.getData();

        this.checkDraftMobility();
    },
    created() {
        let url = new URL(window.location);
        let status = url.searchParams.get('status');
        if(status){
            if(status === 'fail'){
                this.$store.commit('setAlertModal', {
                    type: 'error',
                    data: {
                        title: "Error",
                        text: "Booking failed.Please try later."
                    }
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.wallet-repo-app {
    background-color: #ebeef2 !important;
}

.new-wallet-layout {
    margin: 30px 0;
    padding: 0 44px;
}

.dashboard-main {
    padding: 0;
}

@media screen and (max-width: 1024px) {
    /*
    .container-fluid {
      padding-left: 0;
      padding-right: 0;
    }
    */

    .new-wallet-layout {
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media screen and (max-width: 640px) {
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }

    .new-wallet-layout {
        padding-left: 20px;
        padding-right: 20px;
    }

    .dashboard-main {
        padding-right: 15px;
        padding-left: 15px;
    }
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-right: -15px;
    margin-left: -15px;
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8,
.col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1,
.col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7,
.col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5,
.col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11,
.col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3,
.col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9,
.col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1,
.col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7,
.col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding: 0;
    padding-right: 15px;
    padding-left: 15px;
}
</style>
