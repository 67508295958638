<template>
  <div class="tab" v-show="isActive" :class="isActive ? 'active' : ''">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "WlBaseTab",

  props: {
    /* Can be added more settings */
    dataObject: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: null,
    },
    value: {
      type: String || Number || undefined,
      default: undefined,
    },
  },

  data() {
    return {
      isActive: true,
    };
  },
};
</script>
