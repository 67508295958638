<template>
    <div class="position-relative" :id="attach ? rndId : false">
        <div class="d-flex">
            <label
                v-if="$slots.label || label"
                class="custom-label text-sm color-black"
            >
                <slot v-if="$slots.label" name="label"></slot>
                <span v-else>{{ label }}</span>
            </label>
            <label
                v-if="$slots.requiredText || requiredText"
                class="custom-label ml-1 text-sm color-feedback-error"
            >
                <slot v-if="$slots.requiredText" name="requiredText"></slot>
                <span>{{ requiredText }}</span>
            </label>
        </div>
        <p
            v-if="$slots.subLabel || subLabel"
            class="text-sm color-grey-3 leading-130 mb-1"
        >
            <slot v-if="$slots.subLabel" name="subLabel"></slot>
            <span v-else>{{ subLabel }}</span>
        </p>

        <v-autocomplete
            v-if="autocomplete"
            v-model="val"
            v-bind="$props"
            :clearable="false"
            :menu-props="{ contentClass: contentClass, ...menuProps }"
            :attach="attach ? `#${rndId}` : false"
            v-on="$listeners"
            label=""
            :style="{
        'max-width': maxWidth,
        height: multiple ? 'auto' : height,
        'min-height': height,
      }"
            ref="select"
            class="input"
            :class="[
        !bordered ? 'non-border' : '',
        errorMessages.length ? 'error' : '',
      ]"
        >
            <template slot="append">
                <slot name="append">
                    <BaseIcon
                        name="chevron-down"
                        class="color-purple mr-2 cursor-pointer"
                    />
                    <BaseIcon
                        name="chevron-up"
                        class="color-purple mr-2 cursor-pointer"
                    />
                </slot>
            </template>
            <template slot="append-item">
                <slot name="append-item"> </slot>
            </template>
            <template slot="append-outer">
                <slot name="append-outer"> </slot>
            </template>
            <template v-slot:counter="{ dark, light, max, value }">
                <slot name="counter" v-bind="{ dark, light, max, value }"></slot>
            </template>
            <template v-slot:item="{ parent, item, on, attrs }">
                <div v-if="$scopedSlots['item']" class="d-flex align-center w-100">
                    <slot name="item" v-bind="{ parent, item, on, attrs }"></slot>
                    <BaseInput
                        :value="
              val?.includes(returnObject ? item : __findByPath(item, itemValue))
            "
                        v-if="multiple && checkboxes"
                        class="ml-auto"
                        type="checkbox"
                    />
                </div>
                <template v-else>
                    <div class="d-flex align-center w-100">
                        <BaseAvatar
                            v-if="avatar"
                            size="20"
                            :object="avatarObj ? __findByPath(item, avatarObj) : item"
                        ></BaseAvatar>
                        <span class="color-dark-grey text-sm">
              {{
                                typeof itemText === "function"
                                    ? itemText(item)
                                    : __findByPath(item, itemText)
                            }}
            </span>
                        <BaseInput
                            :value="
                val?.includes(
                  returnObject ? item : __findByPath(item, itemValue)
                )
              "
                            v-if="multiple && checkboxes"
                            class="ml-auto"
                            type="checkbox"
                        />
                    </div>
                </template>
            </template>
            <template v-slot:message="{ key, message }">
                <slot name="item" v-bind="{ key, message }"></slot>
            </template>
            <template slot="no-data">
                <slot v-if="!loading" name="no-data"></slot>
                <template v-else>
                    <v-list-item v-for="i in 7" :key="`loading-skeleton-${i}`" link>
                        <div class="d-flex align-center w-100">
                            <v-skeleton-loader
                                v-if="avatar"
                                :loading="loading"
                                class="mr-2"
                                type="avatar"
                            ></v-skeleton-loader>
                            <v-skeleton-loader
                                :loading="loading"
                                class="flex-grow-1"
                                type="text"
                            ></v-skeleton-loader>
                        </div>
                    </v-list-item>
                </template>
            </template>
            <template slot="prepend">
                <slot name="prepend"></slot>
            </template>
            <template slot="prepend-inner">
                <slot name="prepend-inner"></slot>
            </template>
            <template slot="prepend-item">
                <slot name="prepend-item"></slot>
                <div v-if="clearable" class="extra-button" @click="clearValue">
                    <p class="text-sm color-dark-grey">{{ clearText }}</p>
                </div>
                <div
                    v-if="selectAll && multiple"
                    :class="{ active: isAllSelected }"
                    class="extra-button d-flex align-center"
                    @click="selectAllItems"
                >
                    <p class="text-sm color-dark-grey">Select all</p>
                    <BaseInput
                        :value="isAllSelected"
                        v-if="multiple && checkboxes"
                        class="ml-auto"
                        type="checkbox"
                    />
                </div>
            </template>
            <template slot="progress">
                <slot name="progress"></slot>
            </template>
            <template
                v-slot:selection="{ parent, item, index, select, selected, disabled }"
            >
                <slot
                    v-if="$scopedSlots['selection']"
                    name="selection"
                    v-bind="{ parent, item, index, select, selected, disabled }"
                ></slot>
                <template v-else>
                    <div
                        class="selected-option-item"
                        :class="multiple ? 'my-1 mr-2' : ''"
                    >
                        <BaseAvatar
                            v-if="avatar"
                            avatar
                            :key="item[itemValue]"
                            :size="multiple ? 17 : 20"
                            :offset-right="!multiple"
                            :class="multiple ? 'mr-1' : ''"
                            :object="avatarObj ? __findByPath(item, avatarObj) : item"
                        ></BaseAvatar>
                        <span class="color-black" :class="multiple ? 'text-sm' : 'text-sm'">
              {{
                                typeof itemText === "function"
                                    ? itemText(item)
                                    : __findByPath(item, itemText)
                            }}
            </span>
                    </div>
                </template>
            </template>
        </v-autocomplete>
        <v-select
            v-else
            v-model="val"
            :menu-props="{ contentClass: contentClass, ...menuProps }"
            v-bind="$props"
            :clearable="false"
            :attach="attach ? `#${rndId}` : false"
            v-on="$listeners"
            :style="{
        'max-width': maxWidth,
        height: multiple ? 'auto' : height,
        'min-height': height,
      }"
            ref="select"
            class="input"
            label=""
            :class="[
        !bordered ? 'non-border' : '',
        errorMessages.length ? 'error' : '',
        inputClass,
      ]"
        >
            <template slot="append">
                <slot name="append">
                    <BaseIcon name="chevron-down" class="color-purple mr-2" />
                    <BaseIcon name="chevron-up" class="color-purple mr-2" />
                </slot>
            </template>
            <template slot="append-item">
                <slot name="append-item"> </slot>
            </template>
            <template slot="append-outer">
                <slot name="append-outer"></slot>
            </template>
            <template v-slot:counter="{ dark, light, max, value }">
                <slot name="counter" v-bind="{ dark, light, max, value }"></slot>
            </template>
            <template v-slot:item="{ parent, item, on, attrs }">
                <div v-if="$scopedSlots['item']" class="d-flex align-center w-100">
                    <slot name="item" v-bind="{ parent, item, on, attrs }"></slot>
                    <BaseInput
                        :value="
              val?.includes(returnObject ? item : __findByPath(item, itemValue))
            "
                        v-if="multiple && checkboxes"
                        class="ml-auto"
                        type="checkbox"
                    />
                </div>
                <template v-else>
                    <div class="d-flex align-center w-100">
                        <BaseAvatar
                            v-if="avatar"
                            size="20"
                            :object="avatarObj ? __findByPath(item, avatarObj) : item"
                        ></BaseAvatar>
                        <span class="color-dark-grey text-sm">
              {{
                                typeof itemText === "function"
                                    ? itemText(item)
                                    : __findByPath(item, itemText)
                            }}
            </span>
                        <BaseInput
                            :value="
                val?.includes(
                  returnObject ? item : __findByPath(item, itemValue)
                )
              "
                            v-if="multiple && checkboxes"
                            class="ml-auto"
                            type="checkbox"
                        />
                    </div>
                </template>
            </template>
            <template v-slot:message="{ key, message }">
                <slot name="item" v-bind="{ key, message }"></slot>
            </template>
            <template slot="no-data">
                <slot v-if="!loading" name="no-data"></slot>
                <template v-else>
                    <v-list-item v-for="i in 7" :key="`loading-skeleton-${i}`" link>
                        <div class="d-flex align-center w-100">
                            <v-skeleton-loader
                                v-if="avatar"
                                :loading="loading"
                                class="mr-2"
                                type="avatar"
                            ></v-skeleton-loader>
                            <v-skeleton-loader
                                :loading="loading"
                                class="flex-grow-1"
                                type="text"
                            ></v-skeleton-loader>
                        </div>
                    </v-list-item>
                </template>
            </template>
            <template slot="prepend">
                <slot name="prepend"></slot>
            </template>
            <template slot="prepend-inner" v-if="multiple && val.length > 0">
                <slot name="prepend-inner"></slot>
            </template>
            <template slot="prepend-item">
                <slot name="prepend-item"></slot>
                <div v-if="clearable" class="extra-button" @click="clearValue">
                    <p class="text-sm color-dark-grey">{{ clearText }}</p>
                </div>
                <div
                    v-if="selectAll && multiple"
                    :class="{ active: isAllSelected }"
                    class="extra-button d-flex align-center"
                    @click="selectAllItems"
                >
                    <p class="text-sm color-dark-grey">Select all</p>
                    <BaseInput
                        :value="isAllSelected"
                        v-if="multiple && checkboxes"
                        class="ml-auto"
                        type="checkbox"
                    />
                </div>
            </template>
            <template slot="progress">
                <slot name="progress"></slot>
            </template>
            <template
                v-slot:selection="{ parent, item, index, select, selected, disabled }"
            >
                <slot
                    v-if="$scopedSlots['selection']"
                    name="selection"
                    v-bind="{ parent, item, index, select, selected, disabled }"
                ></slot>
                <template v-else>
                    <div
                        class="selected-option-item"
                        :class="multiple ? 'my-1 mr-2' : ''"
                        v-if="!multiple"
                    >
                        <BaseAvatar
                            v-if="avatar"
                            avatar
                            :key="item[itemValue]"
                            :size="multiple ? 17 : 20"
                            :offset-right="!multiple"
                            :class="multiple ? 'mr-1' : ''"
                            :object="avatarObj ? __findByPath(item, avatarObj) : item"
                        ></BaseAvatar>
                        <span class="color-black" :class="multiple ? 'text-sm' : 'text-sm'">
              {{
                                typeof itemText === "function"
                                    ? itemText(item)
                                    : __findByPath(item, itemText)
                            }}
            </span>
                    </div>
                </template>
            </template>
        </v-select>
        <BaseErrorMessage :messages="errorMessages" />
    </div>
</template>

<script>
import Form from '~/shared/mixins/Form';
import Helpers from '~/shared/mixins/Helpers';
import menuMixin from '~/shared/mixins/menuMixin';

const DEFAULT_MENU_PROPS = {
    closeOnClick: true,
    closeOnContentClick: false,
    disableKeys: false,
    openOnClick: false,
    maxHeight: 304,
    offsetY: true,
    absolute: false,
    eager: false,
    offsetOverflow: true,
    nudgeBottom: '4px',
    contentClass: 'vue-repo-app',
};

export default {
    mixins: [Form, Helpers, menuMixin],

    name: 'NewBaseSelect',

    props: {
        /* Custom props */

        avatar: {
            default: false,
            type: Boolean,
        },
        avatarObj: {
            type: [String, null],
            default: null,
        },
        icon: {
            default: false,
            type: Boolean,
        },
        iconName: {
            type: [String, null],
            default: null,
        },
        bordered: {
            default: true,
        },
        maxWidth: {
            type: String,
            default: "auto",
        },
        autocomplete: {
            default: false,
            type: Boolean,
        },
        errorMessages: {
            default: () => [],
        },
        selectFirst: {
            type: Boolean,
            default: false,
        },
        selectAll: {
            type: Boolean,
            default: false,
        },
        checkboxes: {
            type: Boolean,
            default: false,
        },

        /* Vuetify input props */

        label: {
            type: [String, null],
            default: null,
        },
        subLabel: {
            type: [String, null],
            default: null,
        },
        requiredText: {
            type: [String, null],
            default: null,
        },
        value: {
            default: null,
        },
        dense: {
            default: true,
        },
        hideDetails: {
            default: true,
        },
        color: {
            type: String,
            default: "primary",
        },
        placeholder: {
            type: [String, null],
            default: null,
        },
        height: {
            default: "2.1875rem",
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        singleLine: {
            type: Boolean,
            default: false,
        },
        inputClass: {
            type: [String, Array, Object],
            default: null,
        },

        /* Vuetify select props */

        appendIcon: {
            type: String,
            default: "$dropdown",
        },
        attach: {
            type: [Boolean, String],
            default: false,
        },
        cacheItems: Boolean,
        chips: Boolean,
        clearable: Boolean,
        clearText: {
            type: String,
            default: "Clear",
        },
        deletableChips: Boolean,
        disableLookup: Boolean,
        eager: Boolean,
        hideSelected: Boolean,
        items: {
            type: Array,
            default: () => [],
        },
        itemColor: {
            type: String,
            default: "primary",
        },
        itemDisabled: {
            type: [String, Array, Function],
            default: "disabled",
        },
        itemText: {
            type: [String, Function],
            default: "name",
        },
        itemValue: {
            type: String,
            default: "id",
        },
        loading: {
            type: Boolean,
            default: false,
        },
        menuProps: {
            type: [String, Array, Object],
            default: () => DEFAULT_MENU_PROPS,
        },
        contentClass: {
            type: String,
            default: "",
        },
        multiple: Boolean,
        openOnClear: Boolean,
        returnObject: Boolean,
        smallChips: Boolean,
    },

    data() {
        return {
            val: this.value,
        };
    },

    watch: {
        value(val) {
            this.val = val;
        },
        val(val) {
            this.$emit("input", val);
            this.$emit("keypress", val);
        },
    },

    computed: {
        rndId() {
            return this.__makeId();
        },

        isAllSelected() {
            if (this.multiple && this.items.length) {
                if (this.returnObject) {
                    let key = Object.keys(this.items[0])[0];
                    return _.isEqual(
                        [...this.val].sort((a, b) => a[key] - b[key]),
                        [...this.items].sort((a, b) => a[key] - b[key])
                    );
                }
                return _.isEqual(
                    [...this.val].sort(),
                    [
                        ...this.items.map((item) =>
                            this.__findByPath(item, this.itemValue)
                        ),
                    ].sort()
                );
            }
            return false;
        },
    },

    methods: {
        closeMenu() {
            this.$refs.select.isMenuActive = false;
        },

        clearValue() {
            if (!this.multiple) {
                this.val = null;
            } else {
                this.val = [];
            }

            this.closeMenu();
        },

        selectAllItems() {
            if (this.isAllSelected) {
                this.val = [];
                return true;
            }

            if (this.returnObject) {
                this.val = [...this.items];
            } else {
                this.val = [
                    ...this.items.map((item) => this.__findByPath(item, this.itemValue)),
                ];
            }
        },

        selectFirstItem() {
            if (!this.items[0]) return;
            let firstItem = this.items[0];
            let value = this.returnObject
                ? firstItem
                : this.__findByPath(firstItem, this.itemValue) ||
                this.__findByPath(firstItem, this.itemText);
            if (!value) {
                value = firstItem;
            }
            this.val = this.multiple ? this.val.push(value) : value;
        },
    },

    created() {
        if (this.selectFirst && !this.value) {
            this.selectFirstItem();
        }
    },
};
</script>
