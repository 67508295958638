<template>
    <div>
        <BaseModal
            attach=".vue-repo-app"
            v-model="dialog"
            :title="contactObj.values.worker ? 'Edit profile' : (contactObj.values.id && 'Edit contact' || 'Add contact')"
            width="57.813rem"
            class-names="modal-add-contact"
            body-class="body"
        >
            <template v-slot:body>

                <div class="row">
                    <div class="col-6">
                        <BaseInput
                            :asterisk="checkingPositionField('name', true)"
                            :label="`Full name ${checkingPositionField('name') ? '' : ''}`"
                            placeholder="Type here"
                            customLabelParentClasses="text-sm mb-1"
                            class="mb-4"
                            name="name"
                            @keypress="keypressForLetter($event)"
                            data-vv-as="Full name"
                            v-model="contactObj.values.name"
                            v-validate="contactObj.rules.name"
                            :error-messages="errors.collect('name')"
                        />

                        <BaseInput
                            :asterisk="checkingPositionField('email', true)"
                            :label="`Email address ${checkingPositionField('email') ? '(optional)' : ''}`"
                            label="Email address"
                            :placeholder="`e.g. `+$store.state.systemEmails.support"
                            customLabelParentClasses="text-sm mb-1"
                            class="mb-4"
                            name="email"
                            data-vv-as="Email address"
                            v-model="contactObj.values.email"
                            v-validate="emailRules"
                            :error-messages="emailErrorMsg"
                        />

                        <BaseInput
                            :asterisk="checkingPositionField('organization_name', true)"
                            :label="`Company name ${checkingPositionField('organization_name') ? '(optional)' : ''}`"
                            customLabelParentClasses="text-sm mb-1"
                            class="mb-4"
                            name="organization_name"
                            data-vv-as="Company name"
                            v-model="contactObj.values.organization_name"
                            v-validate="contactObj.rules.organization_name"
                            :error-messages="errors.collect('organization_name')"
                        />

                        <tm-select-phone
                            class="w-100"
                            id="phone"
                            name="phone"
                            v-model="contactObj.values.phone"
                            :required="contactModel && contactModel.worker"
                            @valid="phoneValid"
                            @code="(e) => countryCode = e"
                            :error-messages="errors.collect('phone')"
                            :country-code="selectedCountry && (!contactObj.values.phone || countryCode === contactObj.values.phone) ? selectedCountry.code: false"
                        >
                            <template v-slot:label>
                                <p class="custom-label text-small color-black text-sm mb-1">
                                    Phone number {{showPhoneOptional ? '' : '(optional)'}}
                                </p>
                            </template>
                        </tm-select-phone>

                        <template v-if="!bankDetails">
                            <BaseInput
                                :label="`${vatNumberLabel} ${checkingPositionField('vat_number') ? '(optional)' : ''}`"
                                customLabelParentClasses="text-sm mb-1"
                                name="vat_number"
                                data-vv-as="VAT number"
                                class="mb-4"
                                v-model="contactObj.values.vat"
                                v-validate="contactObj.rules.vat"
                                :error-messages="errors.collect('vat_number')"
                            />
                        </template>

                        <template v-if="bankDetails">
                            <BaseSelect
                                avatar
                                autocomplete

                                :asterisk="checkingPositionField('country', true)"
                                :label="`Country ${checkingPositionField('country') ? '(optional)' : ''}`"

                                :placeholder="contactObj.values.country_id ? '' : 'Select'"
                                class="mb-4 global-base-select-menu"
                                custom-class-active-item="custom-active"
                                labelClassVal="color-black custom-label mb-0 text-sm"
                                name="country"
                                data-vv-as="Country"

                                @object="countrySelected"

                                v-model="contactObj.values.country_id"
                                v-validate="'required'"
                                :error-messages="errors.collect('country')"

                                :items="$store.state.allCountries"
                            />

                            <template v-if="!fromPeople">
                                <BaseInput
                                    :asterisk="checkingPositionField('address', true)"
                                    :label="`Address line 1 ${checkingPositionField('address') ? '(optional)' : ''}`"
                                    customLabelParentClasses="text-sm mb-1"
                                    name="address"
                                    data-vv-as="Address line 1"
                                    class="mb-4"
                                    v-model="contactObj.values.address"
                                    v-validate="contactObj.rules.address"
                                    :error-messages="errors.collect('address')"
                                />

                                <BaseInput
                                    label="Address line 2 (optional)"
                                    customLabelParentClasses="text-sm mb-1"
                                    name="address_two"
                                    data-vv-as="Address line 2"
                                    class="mb-4"
                                    v-model="contactObj.values.address_two"
                                    v-validate="contactObj.rules.address_two"
                                    :error-messages="errors.collect('address_two')"
                                />
                            </template>

                            <BaseInput
                                :asterisk="checkingPositionField('town_city', true)"
                                :label="`City ${checkingPositionField('town_city') ? '(optional)' : ''}`"
                                customLabelParentClasses="text-sm mb-1"
                                name="town_city"
                                data-vv-as="City"
                                class="mb-4"
                                v-model="contactObj.values.town_city"
                                v-validate="contactObj.rules.town_city"
                                :error-messages="errors.collect('town_city')"
                            />

                            <BaseInput
                                :asterisk="checkingPositionField('region', true)"
                                :label="`Region ${checkingPositionField('region') ? '(optional)' : ''}`"
                                customLabelParentClasses="text-sm mb-1"
                                name="region"
                                @keypress="keypressForLetter($event)"
                                data-vv-as="Region"
                                class="mb-4"
                                v-model="contactObj.values.region"
                                v-validate="contactObj.rules.region"
                                :error-messages="errors.collect('region')"
                            />

                            <BaseInput
                                :asterisk="checkingPositionField('zip', true)"
                                :label="`ZIP/Post code ${checkingPositionField('zip') ? '(optional)' : ''}`"
                                customLabelParentClasses="text-sm mb-1"
                                name="zip"
                                data-vv-as="ZIP/Post code"
                                class="mb-4"
                                v-model="contactObj.values.zip"
                                v-validate="fromMoney ? 'required' : contactObj.rules.zip"
                                :error-messages="errors.collect('zip')"
                            />
                        </template>

                        <template v-if="selectedCountry && selectedCountry.code === 'US' && contactRequestedPage === 'send-money-someone'">
                            <BaseInput
                                label="Routing number"
                                customLabelParentClasses="text-sm mb-1"
                                name="routing_number"
                                v-validate="routingNumber"
                                data-vv-as="Routing number"
                                class="mb-4"
                                v-model="contactObj.values.routing_number"
                                :error-messages="errors.collect('routing_number')"
                            />
                        </template>

                        <template v-if="selectedCountry && selectedCountry.code === 'RO'">
                            <BaseInput
                                label="CIF"
                                customLabelParentClasses="text-sm mb-1"
                                name="romania_cif"
                                data-vv-as="CIF"
                                class="mb-4"
                                v-model="contactObj.values.romania_cif"
                                v-validate="contactObj.rules.romania_cif"
                                :error-messages="errors.collect('romania_cif')"
                            />

                            <BaseInput
                                label="Registrul Comertului"
                                customLabelParentClasses="text-sm mb-1"
                                name="romania_registrul_comertului"
                                data-vv-as="Registrul Comertului"
                                class="mb-4"
                                v-model="contactObj.values.romania_registrul_comertului"
                                v-validate="contactObj.rules.romania_registrul_comertului"
                                :error-messages="errors.collect('romania_registrul_comertului')"
                            />
                        </template>

                        <template v-if="selectedOutCurrency && selectedOutCurrency.code === 'MKD' && selectedBankCountry && selectedBankCountry.code === 'MK'">
                            <BaseInput
                                label="Beneficiary's unique identifying number"
                                placeholder="Type here"
                                customLabelParentClasses="text-sm mb-1"
                                class="mb-4"
                                name="identifying_number"
                                data-vv-as="Beneficiary's unique identifying number"
                                v-model="contactObj.values.identifying_number"
                                v-validate="contactObj.rules.identifying_number"
                                :error-messages="errors.collect('identifying_number')"
                            />
                        </template>
                    </div>

                    <div class="col-6">
                        <template v-if="bankDetails">
                            <BaseSelect
                                avatar
                                autocomplete

                                :asterisk="checkingPositionField('country_bank_id', true)"
                                :label="`Country of bank ${checkingPositionField('country_bank_id') ? '(optional)' : ''}`"

                                subLabel="Please input the country of your bank account so we don’t charge unnecessary fees."
                                :placeholder="contactObj.values.country_bank_id ? '' : 'Select'"
                                class="mb-4 global-base-select-menu"
                                custom-class-active-item="custom-active"

                                labelClassVal="color-black custom-label mb-0 text-sm"
                                name="country_bank_id"
                                data-vv-as="Country of bank"

                                @object="selectCountry($event)"

                                v-model="contactObj.values.country_bank_id"
                                v-validate="mandatoryFieldsCheck ? contactObj.rules.country_bank_id : ''"
                                :error-messages="errors.collect('country_bank_id')"

                                :items="$store.state.allCountries"
                            />

                            <BaseInput
                                label="Bank name"
                                placeholder="Type here"
                                customLabelParentClasses="text-sm mb-1"
                                class="mb-4"
                                name="bank_name"
                                data-vv-as="Bank name"
                                v-model="contactObj.values.bank_name"
                                v-validate="mandatoryFieldsCheck ? contactObj.rules.bank_name : ''"
                                :error-messages="errors.collect('bank_name')"
                            />

                            <div>
                                <label class="color-black custom-label mb-1 text-sm">
                                    Account number or IBAN
                                </label>
                                <div class="d-flex w-100">
                                    <BaseSelect
                                        :select-first="true"
                                        class="mb-4 global-base-select-menu"
                                        custom-class-active-item="custom-active"
                                        labelClassVal="color-black custom-label mb-0 text-sm"
                                        v-model="bankType"
                                        :items="bankTypes"
                                        item-value="value"
                                        menuWidth="9.5rem"
                                    />
                                    <div class="w-100">
                                        <div class="skip-flex-wrapper">
                                            <BaseInput
                                                v-show="bankType === 'iban'"
                                                placeholder="AA1234567890"
                                                customLabelParentClasses="text-sm mb-1"
                                                class="mb-4"
                                                name="iban"
                                                data-vv-as="IBAN"
                                                v-model="contactObj.values.iban"
                                                v-validate="bankType === 'iban' ? mandatoryFieldsCheck ? contactObj.rules.iban: '' : ''"
                                                :error-messages="errors.collect('iban')"
                                            />
                                            <BaseInput
                                                v-show="bankType === 'account_number'"
                                                customLabelParentClasses="text-sm mb-1"
                                                class="mb-4"
                                                type="number"
                                                name="account_number"
                                                data-vv-as="Account number"
                                                v-model="contactObj.values.account_number"
                                                v-validate="bankType === 'account_number' ? mandatoryFieldsCheck ? contactObj.rules.account_number: '' : ''"
                                                :error-messages="errors.collect('account_number')"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <BaseInput
                                :asterisk="checkingPositionField('swift', true)"
                                :label="`SWIFT ${checkingPositionField('swift') && swiftOptional ? '(optional)' : ''}`"
                                placeholder="Type here"
                                customLabelParentClasses="text-sm mb-1"
                                class="mb-4"
                                name="swift"
                                data-vv-as="SWIFT"
                                v-model="contactObj.values.swift"
                                v-validate="swiftRules"
                                :error-messages="errors.collect('swift')"
                            />

                            <BaseInput
                                :label="`${vatNumberLabel} ${checkingPositionField('vat_number') ? '(optional)' : ''}`"
                                customLabelParentClasses="text-sm mb-1"
                                name="vat_number"
                                data-vv-as="VAT number"
                                class="mb-4"
                                v-model="contactObj.values.vat"
                                v-validate="contactObj.rules.vat"
                                :error-messages="errors.collect('vat_number')"
                            />
                        </template>

                        <template v-if="selectedBankCountry?.code ==='IN'">
                            <BaseInput
                                name="ifsc"
                                placeholder="HDFC0000001"
                                label="IFSC (optional)"
                                customLabelParentClasses="text-sm mb-1"
                                data-vv-as="Sort Code"
                                class="mb-4"
                                v-model="contactObj.values.ifsc"
                                v-validate="contactObj.rules.ifsc"
                                :error-messages="errors.collect('ifsc')"
                            />
                        </template>

                        <template v-if="selectedBankCountry?.code ==='GB' && bankType!=='iban'">
                            <BaseInput
                                label="Sort Code"
                                customLabelParentClasses="text-sm mb-1"
                                name="sort_code"
                                data-vv-as="Sort Code"
                                class="mb-4"
                                v-model="contactObj.values.sort_code"
                                v-validate="'required'"
                                :error-messages="errors.collect('sort_code')"
                            />
                        </template>


                        <template v-if="!bankDetails">

                            <BaseSelect
                                avatar
                                autocomplete

                                :asterisk="checkingPositionField('country', true)"
                                :label="`Country ${checkingPositionField('country') ? '(optional)' : ''}`"

                                placeholder="Select"
                                class="mb-4 global-base-select-menu"
                                custom-class-active-item="custom-active"
                                labelClassVal="color-black custom-label mb-0 text-sm"
                                name="country"
                                data-vv-as="Country"

                                @object="countrySelected"

                                v-model="contactObj.values.country_id"
                                v-validate="'required'"
                                :error-messages="errors.collect('country')"

                                :search-autoload="true"
                                :search-route="$store.state.getPath+'search-countries'"
                                :search-params="{id: contactObj.values.country_id}"
                                search-key="value"
                            />

                            <BaseInput
                                :asterisk="checkingPositionField('address', true)"
                                :label="`Address line 1 ${checkingPositionField('address') ? '(optional)' : ''}`"
                                customLabelParentClasses="text-sm mb-1"
                                name="address"
                                data-vv-as="Address line 1"
                                class="mb-4"
                                v-model="contactObj.values.address"
                                v-validate="contactObj.rules.address"
                                :error-messages="errors.collect('address')"
                            />

                            <BaseInput
                                :label="`Address line 2 ${checkingPositionField('address_two') ? '(optional)' : ''}`"
                                customLabelParentClasses="text-sm mb-1"
                                name="address_two"
                                data-vv-as="Address line 2"
                                class="mb-4"
                                v-model="contactObj.values.address_two"
                                v-validate="contactObj.rules.address_two"
                                :error-messages="errors.collect('address_two')"
                            />

                            <BaseInput
                                :asterisk="checkingPositionField('town_city', true)"
                                :label="`City ${checkingPositionField('town_city') ? '(optional)' : ''}`"
                                customLabelParentClasses="text-sm mb-1"
                                name="town_city"
                                @keypress="keypressForLetter($event)"
                                data-vv-as="City"
                                class="mb-4"
                                v-model="contactObj.values.town_city"
                                v-validate="fromMoney ? 'required' : contactObj.rules.town_city"
                                :error-messages="errors.collect('town_city')"
                            />

                            <BaseInput
                                :asterisk="checkingPositionField('region', true)"
                                :label="`Region ${checkingPositionField('region') ? '(optional)' : ''}`"
                                customLabelParentClasses="text-sm mb-1"
                                name="region"
                                @keypress="keypressForLetter($event)"
                                data-vv-as="Region"
                                class="mb-4"
                                v-model="contactObj.values.region"
                                v-validate="contactObj.rules.region"
                                :error-messages="errors.collect('region')"
                            />

                            <BaseInput
                                :asterisk="checkingPositionField('zip', true)"
                                :label="`ZIP/Post code ${checkingPositionField('zip') ? '(optional)' : ''}`"
                                customLabelParentClasses="text-sm mb-1"
                                name="zip"
                                data-vv-as="ZIP/Post code"
                                class="mb-4"
                                v-model="contactObj.values.zip"
                                v-validate="fromMoney ? 'required' : contactObj.rules.zip"
                                :error-messages="errors.collect('zip')"
                            />
                        </template>
                    </div>
                </div>

                <template v-if="accessShowShareBtn">
                    <label class="radio-container share-contact-radio-container" :class="!shareContactDisabled ? 'mb-4' : 'share-contact-disabled'">
                        <BaseInput
                            :disabled="shareContactDisabled"
                            type="checkbox"
                            label="Access this contact in all your organisations?"
                            :customLabelParentClasses="shareContactDisabled ? 'mb-0 fw-400 label-disabled' : 'mb-0 fw-400'"
                            :customInputClasses="disabledInputBoxClasses"
                            v-model="contactObj.values.share_contact_across_organizations"
                            name="share_contact_across_organizations"
                        />
                    </label>
                </template>

                <BaseAlert
                    v-if="!shareContactDisabled && canAddOrg"
                    is-big-content
                    type="info"
                    text="Once you decide on the contact’s accessibility, it cannot be changed."
                    class="w-100"
                    customLabelClasses="color-grey-1"
                    alertBoxCustomClasses="color-grey-1 mt-0"
                ></BaseAlert>

            </template>

            <template v-slot:footer>
                <BaseButton
                    :loading="loading"
                    :disabled="!isValidBtn"
                    class="btn-big btn-purple color-white w-100"
                    @click="create"
                >
                    {{ contactObj.values.worker ? 'Update profile' : ( contactObj.values.id && 'Update contact' || 'Create contact') }}
                </BaseButton>
            </template>
        </BaseModal>
    </div>
</template>
<script>

import TmInput from "~/shared/components/form/TmInput";
import TmAdvancedSelect from "~/shared/components/form/TmAdvancedSelect";
import TmAvatarInput from "~/shared/components/form/TmAvatarInput";
import Helpers from '~/shared/mixins/Helpers';
import InvoiceContact from '~/modules/dashboard/models/InvoiceContact';
import Avatar from "~/shared/components/Avatar";
import TmSelectPhone from "~/shared/components/form/TmSelectPhone";
import {mapGetters, mapActions} from "vuex";

export default {
    name: 'ContactDialog',
    components: {
        TmSelectPhone,
        Avatar,
        TmAvatarInput,
        TmAdvancedSelect,
        TmInput,
    },
    mixins: [Helpers],
    props: {
        value: {
            required: true,
            type: Boolean
        }
    },
    data() {
        return {
            dialog: this.value,
            isValid: false,
            countryCode: false,
            loading: false,
            contactObj: null,
            selectedCountry: null,
            selectedBankCountry: null,
            swiftOptional: true,

            bankType: 'iban',
            bankTypes: [
                {value: 'account_number', name: 'Account number'},
                {value: 'iban', name: 'IBAN'}
            ],

            fieldsLabel: []
        }
    },
    created() {
        this.contactObj = new InvoiceContact(this.contactModel || {bank_details: this.bankDetails});
    },
    computed: {
        routingNumber(){
            if ('US' === this.selectedCountry?.code) {
                return 'required|max:255';
            }
            return '';
        },
        ...mapGetters('contactsDialog', [
            'contactModel',
            'contactRequestedPage',
            'contactType',
            'bankDetails',
            'selectedOutCurrency',
            'fromMoney',
        ]),
        ...mapGetters(['euAndEeaCountriesWithGB']),
        isValidBtn() {
            return !Object.keys(this.fields).some(key => this.fields[key].invalid);
        },
        showPhoneOptional() {
            return !!(this.fromPeople || (this.contactModel && this.contactModel.someone_wallet));
        },
        fromPeople() {
            return !!(this.contactModel && this.contactModel.from_people)
        },
        accessShowShareBtn() {
            return this.$store.state?.organizations.length > 1 && this.$store.state?.organization.id !== 1;
        },
        swiftRules() {
            let notRequriedCountries = ['MK'];
            if (this.selectedBankCountry?.code && !notRequriedCountries.includes(this.selectedBankCountry?.code) && !this.euAndEeaCountriesWithGB.includes(this.selectedBankCountry.code)) {
                this.swiftOptional = false;
                if (this.fields && !this.contactObj.values.swift) {
                    this.fields['swift'].invalid = true;
                }
                return 'required|max:255';
            } else {
                if (this.fields && this.fields['swift']) {
                    this.fields['swift'].invalid = false;
                }
                this.swiftOptional = true;
                return 'max:255';
            }
        },
        emailRules() {
            return this.$store.state.info ?
                this.contactObj.rules.email + '|excluded:' + this.$store.state.info.email :
                this.contactObj.rules.email;
        },
        mandatoryFieldsCheck() {
            if (this.fromPeople) {
                return !!(this.contactObj.values.iban || this.contactObj.values.account_number);
            }
            return true
        },
        emailErrorMsg() {
            return this.errors.items.find(i => i.field === 'email' && i.rule === 'excluded') ?
                [`Recipient's email must be different from yours`] :
                this.errors.collect('email');
        },
        vatNumberLabel() {
            return this.selectedCountry && this.selectedCountry.code === 'PL' ? 'NIP' : 'VAT number'
        },
        shareContactDisabled() {
            return this.contactObj.values.id;
        },
        disabledInputBoxClasses() {
            if (!this.shareContactDisabled) return;
            return 'disabled';
        }
    },
    watch: {
        'selectedCountry.code'(v) {
            if (v !== 'US') {
                this.contactObj.values.routing_number = '';
            }
        },
        value(val) {
            this.dialog = val
        },
        dialog(val) {
            if (!val) {
                this.closeDialog()
            }
            this.$emit('input', this.dialog);
        },
        bankType(v) {
            if (v === 'iban') {
                this.fields['iban'].invalid = (this.bankDetails && !this.fromPeople);
                this.contactObj.values.account_number = "";
            } else {
                this.fields['account_number'].invalid = (this.bankDetails && !this.fromPeople);
                this.contactObj.values.iban = "";
            }
        }
    },
    methods: {
        ...mapActions('contactsDialog', [
            'emitCreatedObj',
            'changerUpdatedEmit',
            'closeDialog',
        ]),
        selectCountry(e) {
            this.selectedBankCountry = e
            this.countryOfBank = e
        },
        setBankType() {
            this.bankType = this.contactObj.values.iban || !this.contactObj.values.account_number ? 'iban' : 'account_number'
        },
        countrySelected(e) {
            if (e) {
                this.selectedCountry = e
            }
        },
        phoneValid(e) {
            this.errors.remove('phone');
            this.isValid = e.valid;
        },

        create() {
            this.$validator.resume();
            if (this.contactObj.values.phone && !this.isValid && this.countryCode !== this.contactObj.values.phone) {
                this.errors.add({
                    field: 'phone',
                    msg: 'The phone field is not correct'
                });
            }

            this.$validator.validateAll().then(valid => {
                let method;
                if (valid && this.isValidBtn) {
                    this.loading = true;

                    this.contactObj.values.contactType = this.contactType

                    if (this.contactObj.values.worker) {
                        method = this.contactObj.update(this.$store.state.path + 'invoice/update-user-specific-fields');
                    } else {
                        const isTeam = this.$route.path.indexOf('team') > -1;
                        const baseUrl = this.fromPeople ? this.$store.state.path + (isTeam && 'team' || 'contacts')+'/contacts/' : this.$store.state.path + 'invoice-contact/';
                        method = this.contactObj.values.id ?
                            this.contactObj.update(baseUrl + 'update/' + this.contactObj.values.id) :
                            this.contactObj.create(baseUrl + 'store');
                    }

                    method.then(response => {
                        this.loading = false;
                        this.dialog = false;
                        if (!this.contactObj.values.id) {
                            this.emitCreatedObj(response.data.contact);
                        } else {
                            this.changerUpdatedEmit();
                        }
                    }).catch(error => {
                        this.loading = false;
                        let erStr = [];
                        for (const [key, value] of Object.entries(error.response.data.errors)) {
                            erStr.push(`${key}: ${value}`);
                        }
                        this.$store.commit("setAlert", {
                            type: "error",
                            message: erStr.length > 0 ? error.response.data.message + ' (' + erStr.join(', ') + ')' : error.response.data.message,
                        });
                    })
                }
            })
        },

        checkingPositionField(fieldName, forAsterisk = false) {
            if (!fieldName) return false;
            return this.fieldsLabel.length > 0 && !!this.fieldsLabel.find(v => (v.name === fieldName && v.required === forAsterisk));
        },

        fieldsCheck() {
            let obj = [];
            let important = 0
            let optional = 0
            Object.keys(this.fields).map(key => {
                let required = this.fields[key].required
                if (required) {
                    important += 1;
                } else {
                    optional += 1;
                }
                obj.push({
                    required: required,
                    name: key,
                })
            });
            this.fieldsLabel = obj;
            if (optional > important) {
                this.fieldsLabel = this.fieldsLabel.filter(t => t.required === true);
            } else {
                this.fieldsLabel = this.fieldsLabel.filter(t => t.required === false);
            }
        }
    },
    mounted() {
        this.fieldsCheck();
        this.setBankType();
    }
}
</script>
<style>
.body {
    padding: 2rem 2rem !important;
}

.label-disabled {
    margin-left: 0.5rem;
    font-size: 1rem !important;
    line-height: 1.1rem;
    color: #a9a9a9 !important;
}

.share-contact-radio-container:not(.share-contact-disabled):hover {
    border-color: #f4f4f4 !important;
    background-color: #f4f4f4 !important;
}

.form-check-input[name="share_contact_across_organizations"]:hover {
    border-color: #9e9e9e !important;
}
</style>
