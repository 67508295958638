<!-- @format -->

<template>
    <div class="d-flex flex-column" style="position: relative">
        <label
            :for="id"
            v-if="label"
            class="text-small font-weight-medium black--text"
            :class="[labelClassVal, labelBottomOffset ? 'mb-1' : '']"
        >
            {{ label }}
        </label>
        <template v-if="chips">
            <template v-if="previews.length && showChips">
                <ul class="invoice-attachments-list mb-10" :class="chipsBlock ? 'mw-unset' : null">
                    <li v-for="(item, index) in previews" :key="index" :class="chipsBlock ? 'w-100' : null">
                        <i class="icon-attachments-v pr-2 color-dark-grey" />
                        <p class="small-text bold-text color-black">
                            {{ cutText(item.name, 30) }}
                        </p>
                        <v-spacer />
                        <v-btn x-small color="#fa4769" icon @click="removeFile(item, index)" />
                    </li>
                </ul>
            </template>
            <input
                ref="chipModeInput"
                @change="getFiles"
                :multiple="multiple"
                type="file"
                class="d-none"
                :accept="accept"
            />
            <a v-if="$slots.chipModeButton" @click="$refs.chipModeInput.click()">
                <slot name="chipModeButton"></slot>
            </a>
            <button
                v-else
                @click="$refs.chipModeInput.click()"
                class="btn btn-h-auto btn-p-0 btn-attach-file medium-text bold-text color-purple"
            >
                <i class="icon-attachments-v fs-24 pr-1"></i>
                Attach a file
            </button>
        </template>

        <template v-else>
            <template v-if="$scopedSlots['preview']">
                <div v-for="(item, index) in previews" :key="index">
                    <slot name="preview" v-bind="{ item }"></slot>
                </div>
            </template>

            <div @drop="getDropFiles" @dragenter.prevent @dragover.prevent>
                <slot v-if="$slots.customUploadArea" name="customUploadArea"></slot>
                <label
                    v-else
                    :style="height ? { height: isNaN(height) ? height : height + 'px' } : {}"
                    :class="smallSize ? 'h-65' : ''"
                    class="tm-file-input-label w-100"
                >
                    <div class="d-flex pa-6 align-center text-small tm-color-dark-grey not-text-transform">
                        <slot v-if="$slots.placeholder" name="placeholder"></slot>
                        <p v-else class="text-center">
                            <span class="color-purple font-weight-medium">Upload</span>
                            or drag and drop your files here.
                        </p>
                    </div>
                    <input @change="getFiles" :multiple="multiple" type="file" class="d-none" :accept="accept" />
                </label>
            </div>

            <template v-if="showPreviews && previews.length && !$scopedSlots['preview']">
                <div class="mt-4 tm-scroll-container" style="max-height: 100px">
                    <div
                        v-for="(item, index) in previews"
                        :key="index"
                        class="d-flex align-center justify-space-between mb-1"
                    >
                        <div class="d-flex align-center">
                            <span
                                class="text-small font-weight-medium black--text text-none cut-test"
                                style="max-width: 24rem"
                                >{{ item.name }}</span
                            >
                        </div>
                        <div>
                            <base-button
                                v-if="download && item.id"
                                class="color-purple"
                                @click.stop.prevent="$emit('download', item)"
                            >
                                <base-icon name="download" size="0.625rem" />
                            </base-button>
                            <span
                                class="color-dark-pink  pointer"
                                @click.stop.prevent="removeFile(item, index)"
                            >
                                <base-icon name="times" size="0.625rem" />
                            </span>
                        </div>
                    </div>
                </div>
                <v-divider class="mb-2 mt-0" />
            </template>
        </template>

        <error-message :messages="errorMessages" :left="chips" />
    </div>
</template>
<script>
import ErrorMessage from '~/shared/components/form/ErrorMessage';
import Helpers from '~/shared/mixins/Helpers';
export default {
    name: 'BaseMultipleUpload',

    components: { ErrorMessage },

    mixins: [Helpers],

    props: {
        accept: {
            default: '*',
        },
        download: {
            default: false,
        },
        showChips: {
            default: true,
        },
        showPreviews: {
            Type: Boolean,
            default: true,
        },
        smallSize: {
            default: false,
        },
        value: {
            default() {
                return [];
            },
        },
        label: {
            default: null,
        },
        classVal: {
            default: '',
        },
        labelBottomOffset: {
            default: true,
        },
        labelClassVal: {
            default: '',
        },
        previewFiles: {
            default() {
                return [];
            },
        },
        previewDocuments: {
            default() {
                return [];
            },
        },
        bottomOffset: {
            default: true,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        id: {
            default: null,
        },
        height: {
            default: null,
        },
        errorMessages: {
            type: Array,
            default() {
                return [];
            },
        },
        chips: {
            default: false,
        },
        chipsBlock: {
            default: false,
        },
        filesTypes: {
            default() {
                return [];
            },
        },
        filesMaxMb: {
            default() {
                return null;
            },
        },
    },

    data() {
        return {
            val: this.value,
            previews: [],
            deletes: [],
        };
    },

    watch: {
        val() {
            this.$emit('input', this.val);
            this.$emit('previewsObject', this.previews);
        },
        value() {
            this.val = this.value;
            if (!this.value.length) {
                this.previews = [];
            }
        },
        deletes() {
            this.$emit('delete', this.deletes);
        },
        /* previewFiles(){
            this.previews = this.previews.filter(item => !item.id);
            this.setPreviews();
        }, */
        previewDocuments() {
            this.previews = this.previews.filter(item => !item.id);
            this.setPreviews();
        },
    },

    methods: {
        getDropFiles(e) {
            e.preventDefault();
            if (e.dataTransfer.items) {
                for (const element of e.dataTransfer.items) {
                    if (element.kind === 'file') {
                        this.setFile(element.getAsFile());
                    }
                }
            } else {
                for (const element of e.dataTransfer.files) {
                    this.setFile(element);
                }
            }
        },
        getFiles(e) {
            for (const element of e.target.files) {
                this.setFile(element);
            }
            this.clearFileInput(e.target);
        },
        clearFileInput(ctrl) {
            try {
                ctrl.value = null;
            } catch(ex) { }
            if (ctrl.value) {
                ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
            }
        },
        setFile(file) {
            if (!_.isEmpty(this.filesTypes) && !this.filesTypes.includes(file.type)) {
                return;
            }
            if (this.filesMaxMb && file.size > this.filesMaxMb * 1000000) {
                return;
            }

            const index = this.val.length;
            if (this.multiple === false) {
                this.val = [];
                this.previews = [];
            }
            this.val.push(file);
            this.previews.push({
                index,
                name: file.name,
                uri: URL.createObjectURL(file),
            });
        },
        setPreviews() {
            this.previewFiles.forEach(item => {
                this.previews.push({
                    id: item.id,
                    object: 'file',
                    name: item.name,
                });
            });
            this.previewDocuments.forEach(item => {
                this.previews.push({
                    id: item.id,
                    object: 'document',
                    name: item.name,
                });
            });
        },
        removeFile(file, previewIndex) {
            if (file.id) {
                if (file.object === 'file') {
                    if (!this.deletes.includes(file.id)) this.deletes.push(file.id);
                } else if (file.object === 'document') {
                    this.$emit('deleteDocument', file.id);
                }
                this.previews.splice(previewIndex, 1);
            } else {
                if (this.$refs.chipModeInput) {
                    this.$refs.chipModeInput.value = '';
                }
                this.val.splice(file.index, 1);
                this.previews.splice(previewIndex, 1);
            }
        },
    },

    created() {
        this.setPreviews();
    },
};
</script>
