<!-- @format -->

<template>
    <BaseMenu
        v-bind="menuProps"
        v-model="menu"
        :close-on-content-click="false"
        :content-class="menuClasses"
        transition="scale-transition"
        offset-y
        :max-width="fullWidth ? width : 'auto'"
        :width="fullWidth ? '100%' : ''"
        :min-width="!fullWidth ? 'auto' : ''"
    >
        <template #activator="{ on, attrs }">
            <div v-if="isYearOnly" ref="input" @click="openMenu()">
                <BaseIcon name="calendar" class="mr-2" />
                <BaseInput
                    :label="label"
                    :class="classNames"
                    :placeholder="placeholder"
                    readonly
                    :value="formatYear"
                    v-bind="attrs"
                    :error-messages="[...localErrorMessages, ...errorMessages]"
                    v-on="on"
                    @blur="$emit('blur')"
                />
            </div>
            <div v-else-if="isMonthOnly" v-bind="attrs" v-on="on">
                <BaseInput
                    ref="input"
                    :label="label"
                    :class="classNames"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    readonly
                    :value="formatMonth"
                    :error-messages="[...localErrorMessages, ...errorMessages]"
                    @blur="$emit('blur')"
                >
                    <template #append-outer>
                        <BaseIcon v-if="menu" name="chevron-up" class="color-purple" />
                        <BaseIcon v-else name="chevron-down" class="color-purple" />
                    </template>
                </BaseInput>
            </div>
            <div v-else v-bind="attrs" v-on="on">
                <BaseInput
                    ref="input"
                    v-mask="'##/##/####'"
                    v-model="inputVal"
                    :label="label"
                    :class="classNames"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    readonly
                    :error-messages="[...localErrorMessages, ...errorMessages]"
                    @blur="$emit('blur')"
                    name="date"
                >
                    <template #append-outer>
                        <BaseIcon name="calendar" class="color-purple" />
                    </template>
                </BaseInput>
            </div>
        </template>
        <v-date-picker
            v-model="val"
            :class="!roundedButtons ? 'non-rounded' : ''"
            :type="type"
            :active-picker.sync="activePicker"
            no-title
            scrollable
            :min="calcMin"
            :max="calcMax"
            :reactive="isYearOnly"
            :max-width="fullWidth ? width : 'auto'"
            :width="fullWidth ? '100%' : '290'"
            v-bind="disabledDates.length ? { 'allowed-dates': getAllowedDates } : {}"
            @input="menu = false"
        ></v-date-picker>
    </BaseMenu>
</template>

<script>
import {
    format,
    parseISO,
    parse,
    isMatch,
    isValid,
    isEqual,
    isAfter,
    isBefore,
    subDays,
    addDays,
} from "date-fns";

export default {
    name: 'BaseInputDateNew',

    props: {
        label: {
            type: String || null,
            default: null,
        },
        value: {
            type: String || null,
            default: null,
        },
        placeholder: {
            type: String || null,
            default: 'Select date',
        },
        errorMessages: {
            default: () => [],
        },
        min: {
            default: null,
        },
        max: {
            default: null,
        },
        defaultToday: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'date',
        },
        isYearOnly: {
            type: Boolean,
            default: false,
        },
        classNames: {
            type: [Object, String, Array],
            default: () => [],
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        roundedButtons: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        menuProps: {
            type: Object,
            default: () => {},
        },
        disabledDates: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            activePicker: null,
            today: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menu: false,
            val: this.value,
            inputVal: this.value ? format(parseISO(this.value), 'dd/MM/yyyy') : this.value,
            width: 290,
            valid: true,
            messages: {
                valid: 'Please follow the format dd/mm/yyyy',
            },
        };
    },

    computed: {
        localErrorMessages() {
            return [!this.valid ? this.messages.valid : null].filter((i) => !!i);
        },
        formatYear() {
            if (this.isYearOnly) {
                if (4 < this.value.length) {
                    const tempYear = `${this.value.slice(0, -8)}-01-01`;
                    return format(parseISO(tempYear), 'yyyy');
                }
                const tempYear = `${this.value}-01-01`;
                return format(parseISO(tempYear), 'yyyy');
            }
            return 'Select Date';
        },
        menuClasses() {
            return `${this.menuProps?.contentClass} vue-repo-app`;
        },
        formatMonth() {
            const tempMonth = `${this.value}-01`;
            return format(parseISO(tempMonth), 'MMMM yyyy');
        },

        isMonthOnly() {
            return 'month' === this.type;
        },

        calcMin() {
            return this.getBorderDate(this.min);
        },

        calcMax() {
            return this.getBorderDate(this.max);
        },
    },

    watch: {
        menu(menuVal) {
            this.isYearOnly && menuVal && setTimeout(() => (this.activePicker = 'YEAR'));

            if ('object' === typeof this.$refs.input) {
                this.width = this.$refs.input.$el.clientWidth;
            } else {
                this.width = this.$refs.input.clientWidth;
            }
        },
        val() {
            if (
                this.val &&
                (!this.inputVal ||
                !isEqual(
                    parse(this.inputVal, 'dd/MM/yyyy', new Date()),
                    parseISO(this.val)
                ) ||
                !this.isInput)
            ) {
                this.inputVal = format(parseISO(this.val), 'dd/MM/yyyy');
            }
            this.$emit("input", this.val);
        },
        inputVal(val) {
            if (!this.isInput) return false;
            let valid = false;
            let parsedDate = parse(this.inputVal, 'dd/MM/yyyy', new Date());
            if (isMatch(this.inputVal, 'dd/MM/yyyy') && isValid(parsedDate)) {
                valid =
                !(this.min && !isAfter(parsedDate, subDays(parseISO(this.min), 1))) &&
                !(this.max && !isBefore(parsedDate, addDays(parseISO(this.max), 1)));
            }
            this.valid = val ? valid : true;
            this.val = valid ? format(parsedDate, 'yyyy-MM-dd') : null;
        },
        value() {
            if (this.isYearOnly) {
                this.val = this.formatYear;
            } else {
                this.val = this.value;
            }
        },
    },

    mounted() {
        this.val = !this.value && this.defaultToday ? this.today : this.value;
    },

    methods: {
        setDateParams(value) {
            if (!value) return null;
            if (value.search('T')) {
                value = value.split('T')[0];
            }
            const [year, month, day] = value.split('-');
            return `${year ? `${year}-` : '1970-'}${month ? `${month}-` : '01-'}${day || '01'}`;
        },

        getBorderDate(value) {
            return 'today' === value ? new Date().toISOString().split('T')[0] : this.setDateParams(value);
        },

        openMenu() {
            this.menu = true;
        },
        getAllowedDates(date) {
            return !this.disabledDates.includes(date);
        }
    },
};
</script>
