<template>
    <div class="setup-profile-step w-100 h-100 mb-0 d-flex flex-column justify-space-between">
        <div>
            <h1 class="tm-transform-none tm-heading-1 font-weight-medium tm-color-black text-center mb-9">
                Tell us more about your occupation & interests
            </h1>
            <p class="tm-normal-text tm-color-dark-grey text-center mb-50">
                This will help us find you relevant job openings.
            </p>
            <v-row no-gutters>
                <v-col cols="12" md="6" class="col-right">
                    <tm-input
                        name="occupation"
                        v-validate="greetingObj.rules.interests_info.occupation"
                        label="Occupation"
                        v-model="greetingObj.values.interests_info.occupation"
                        :error-messages="errors.collect('occupation')"
                    ></tm-input>
                </v-col>
                <v-col cols="12" md="6" class="col-left">
                    <tm-advanced-select
                        :is-sort="false"
                        label="Experience Level"
                        name="experience"
                        v-validate="greetingObj.rules.interests_info.experience_id"
                        v-model="greetingObj.values.interests_info.experience_id"
                        item-value="id"
                        :items="experienceLevels"
                        :error-messages="errors.collect('experience')"
                    >
                        <template v-slot:selection="{ item }">
                            <span class=" tm-color-black mr-1">{{ item.name }} ({{item.right_text}})</span>
                        </template>
                        <template v-slot:item="{ item }">
                            <span class=" tm-color-black mr-1">{{ item.name }} ({{item.right_text}})</span>
                        </template>
                    </tm-advanced-select>
                </v-col>
            </v-row>
            <tm-advanced-select
                label="Roles i’m interested in:"
                name="role_ids"
                v-model="greetingObj.values.interests_info.role_ids"
                item-text="name"
                item-value="id"
                :chips="true"
                :chips-clear="true"
                chips-color="#ddf3ff"
                :search="true"
                :search-autoload="true"
                search-key="name"
                :search-route="$store.state.getPath+'search-recruitment-categories'"
                :search-additional-params="{ids: greetingObj.values.interests_info.role_ids}"
                :multiple="true"
                :error-messages="errors.collect('role_ids')"
                data-vv-as="roles"
            >
            </tm-advanced-select>
        </div>
        <div class="d-flex justify-space-between align-center welcome-page-footer">
            <button @click="$emit('back')" class="tm-btn h-auto p-0 tm-medium-text tm-color-dark-grey">
                <i class="icon-long-arrow-left pr-2"></i> Back
            </button>
            <btn :loading="loading" :disabled="!isValid" @click="$emit('done')" height="2.625rem">
                <span class="font-weight-regular tm-medium-text px-6 py-2 tm-color-white">Done</span>
            </btn>
        </div>
    </div>
</template>
<script>
import TmAvatarInput from "~/shared/components/form/TmAvatarInput";
import TmInput from "~/shared/components/form/TmInput";
import TmSelectDate from "~/shared/components/form/TmSelectDate";
import Btn from "~/shared/components/Btn";
import TmAdvancedSelect from "~/shared/components/form/TmAdvancedSelect";
import TmAutocomplete from "~/shared/components/form/TmAutocomplete";
export default {
    name: 'Interests',
    components: {TmAutocomplete, TmAdvancedSelect, Btn, TmSelectDate, TmInput, TmAvatarInput},
    props: {
        greetingObj: {
            required: true,
            type: Object
        },
        experienceLevels: {
            required: true,
            type: Array
        },
        loading: {
            default: false
        }
    },
    data(){
        return {}
    },
    computed: {
        isValid() {
            return !Object.keys(this.fields).some(key => this.fields[key].invalid)
        }
    },
}
</script>
