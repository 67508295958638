<template>
  <BaseMenu
    v-bind="menuProps"
    :content-class="menuClasses"
    v-model="menu"
    :left="true"
    :close-on-content-click="true"
    offset-y
    :max-width="fullWidth ? width : 'auto'"
    :width="fullWidth ? '100%' : ''"
    :min-width="!fullWidth ? 'auto' : ''"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-on="on" v-bind="attrs">
        <BaseInput
          ref="input"
          :label="label"
          :class="classNames"
          class="bg-white"
          :placeholder="placeholder"
          :disabled="disabled"
          readonly
          :value="selectedYear"
          v-bind="attrs"
          v-on="on"
          @blur="$emit('blur')"
          :error-messages="[...errorMessages]"
        >
          <template v-slot:append-outer>
            <BaseIcon name="calendar" class="color-purple no-pointer-events custom-calendar-icon" />
          </template>
        </BaseInput>
      </div>
    </template>

    <v-date-picker
      reactive
      show-current
      ref="picker"
      v-model="dateModel"
      :min="min"
      :max="max"
      no-title
      :active-picker.sync="localActivePicker"
    ></v-date-picker>
  </BaseMenu>
</template>

<script>
import { format, parseISO } from "date-fns";

export default {
  name: "BaseYearPicker",

  props: {
    label: {
      type: String || null,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String || null,
      default: "Select date",
    },
    errorMessages: {
      default: () => [],
    },
    min: {
      type: String,
      default: "2015-01-01",
    },
    max: {
      type: String,
      default: "2024-01-01",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    menuProps: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classNames: {
      type: [Object, String, Array],
      default: () => [],
    },
  },

  data() {
    return {
      menu: false,
      localActivePicker: "YEAR", // Use a local data property to store the value
      yearInputValue: null, // Initialize yearInputValue to handle empty cases
    };
  },

  computed: {
    // Update yearInputValue based on dateModel
    dateModel: {
      get() {
        return this.value ? this.value : null; // Handle null or empty string value
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },

    // Update yearInputValue based on dateModel
    // formattedDate() {
    //   return this.dateModel ? format(parseISO(this.dateModel), "yyyy") : null; // Handle null or empty string value
    // },

    selectedYear: {
      get() {
        return this.value ? format(parseISO(this.value), "yyyy") : null;
      },
      set(newYear) {
        this.dateModel = newYear ? new Date(newYear).toISOString() : null;
      },
    },

    menuClasses() {
      return `${this.menuProps?.contentClass} vue-repo-app`;
    },
  },

  watch: {
    menu(val) {
      if (val) {
        this.$nextTick(() => {
          this.localActivePicker = "YEAR";
        });
      }
    },
  },
};
</script>
<style lang="scss">
@media only screen and (max-width: 992px) {
  //override vue-repo-responsive-fix.scss
  .field-group > .v-input > .v-input__append-outer > .custom-calendar-icon {
    bottom: 1px !important;
  }
}
</style>
