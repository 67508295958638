<template>
    <div class="d-flex flex-column" :class="bottomOffset? 'mb-1-rem': 'mb-0'" style="position: relative">
        <label v-if="label" :for="id" class="font-weight-medium black--text" :class="[labelClassVal, labelBottomOffset ? 'mb-1' : '']">{{label}}</label>
        <template v-else-if="$scopedSlots['customlabel']">
            <slot name="customlabel"></slot>
        </template>
        <v-menu
            :disabled="disabled"
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            :offset-y="menuOffsetY"
            :offset-x="menuOffsetX"
            :left="menuLeft"
            :right="menuRight"
            :bottom="menuBottom"
            :top="menuTop"
            :min-width="!fullWidth ? 'auto' : ''"
            :attach="keyAttache"
            :max-width="fullWidth ? inputWidth : 'auto'"
            :width="fullWidth ? '100%' : width"
        >
            <template v-slot:activator="{ on, attrs }">
                <div
                    ref="inputWrap"
                    v-on="on" v-bind="attrs"
                    class="d-flex align-center"
                    :class="tmClass"
                    :style="!border? {border: 'none!important'}: {}"
                >
                    <template v-if="!notShowInput">
                        <input
                            :disabled="disabled"
                            v-mask="'##/##/####'"
                            v-model="manualDate"
                            autocomplete="off"
                            :id="id"
                            class="w-100 tm-color-black mr-2"
                            :placeholder="placeholder"
                        >
                        <v-btn v-if="clearable && val" @click="val = null" x-small icon class="mr-2">
                            <icon size="12" name="icon-times"></icon>
                        </v-btn>
                        <icon size="0.8125" :rem="true" name="icon-date" :color="disabled? '#dadfe6': '#5152fb'" class="mr-2"></icon>
                    </template>
                    <template v-if="$slots.label">
                        <slot name="label"></slot>
                    </template>
                </div>
            </template>
            <v-date-picker
                :allowed-dates="allowedDatesFunction"
                v-if="calendar"
                :events="events"
                :event-color="eventColor"
                :disabled="disabled"
                v-model="calendarDate"
                no-title
                scrollable
                @input="menu = false"
                :min="calcMin"
                :max="calcMax"
                :width="fullWidth ? inputWidth : width"
                :class="[__smallDesktop || __mediumDesktop ? 'height-tr' : 'height-tr-3rem']"
                :max-width="fullWidth ? inputWidth : 'auto'"
            >
            </v-date-picker>
        </v-menu>
        <error-message :messages="errorMessages"></error-message>
    </div>
</template>

<script>
import ErrorMessage from "./ErrorMessage";
import moment from "moment-timezone";
import Screen from "../../mixins/Screen";

export default {
    name: 'TmSelectDate',
    components: {ErrorMessage},
    // inject: {
    //     $validator: '$validator'
    // },
    mixins:[Screen],
    props: {
        id: {
            default: null
        },
        notShowInput: {
            type: Boolean,
            default: false
        },
        menuOffsetY: {
            type: Boolean,
            default: true
        },
        menuOffsetX: {
            type: Boolean,
            default: false
        },
        menuLeft: {
            type: Boolean,
            default: false
        },
        menuRight: {
            type: Boolean,
            default: false
        },
        menuBottom: {
            type: Boolean,
            default: false
        },
        menuTop: {
            type: Boolean,
            default: false
        },
        placeholder: {
            default: 'Select date'
        },
        label: {
            default: null
        },
        labelBottomOffset: {
            default: true
        },
        labelClassVal: {
            default: 'tm-small-text ',
        },
        disabledDates: {
            type: Array,
            default() {
                return [];
            }
        },
        min: {
            default: null
        },
        max: {
            default: null
        },
        value: {
            default: null
        },
        bottomOffset: {
            default: true
        },
        border: {
            default: true
        },
        clearable: {
            default: false
        },
        disabled: {
            default: false
        },
        calendar: {
            default: true
        },
        events: {
            type: Array,
            default() {
                return []
            }
        },
        eventColor: {
            default: null
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            }
        },

        name: {
            default: null
        },
        keyAttache: {
            default: false
        },
        width: {
            default: '18.125rem'
        },
        disableWeekends: {
            default: false
        },
        notClearErrors: {
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {
            val: this.value,
            menu: false,
            manualDate: null,
            calendarDate: null,
            inputWidth: this.width,
        }
    },
    watch: {
        val(){
            this.$emit('input', this.val);
            this.manualDate = this.toShowFormat(this.val);
            this.calendarDate = this.validateFormat(this.val, false)? this.toIsoFormat(this.val): null;
        },
        value(){
            this.val = this.value;
        },
        manualDate(){
            this.val = this.toIsoFormat(this.manualDate);
            if (!this.val || (this.validateFormat(this.val) && this.validateMin(this.val) && this.validateMax(this.val))){
                this.clearErrors()
            }
        },
        calendarDate(){
            this.manualDate = this.toShowFormat(this.calendarDate)
        },
        menu() {
            if (typeof this.$refs.inputWrap === "object") {
                this.inputWidth = this.$refs.inputWrap?.offsetWidth;
            } else {
                this.inputWidth = this.$refs.inputWrap?.offsetWidth;
            }
        },
    },
    computed: {
        tmClass () {
            if(Object.keys(this.errorMessages).length) {
                return  this.notShowInput ? 'tm-err': 'tm-input tm-err';
            }
            return  this.notShowInput ? null: 'tm-input';
        },
        calcMin(){
            return this.getBorderDate(this.min)
        },
        calcMax(){
            return this.getBorderDate(this.max)
        }
    },
    methods: {
        allowedDatesFunction(date) {
            if(this.disableWeekends) {
                return this.allowWorkDates;
            }
            return  this.disabledDates.length? !~this.disabledDates.indexOf(date):true;
        },
        allowWorkDates: val => ![0, 6].includes(new Date(val).getDay()),
        allowAllDates: val => true,
        getParent(obj){
            if (this.name && obj && obj.$validator)
            return obj.$validator.fields.find({ name: this.name, scope:  this.$options.scope })? obj: this.getParent(obj.$parent);
        },
        getField(obj){
            return obj.$validator.fields.find({ name: this.name, scope:  this.$options.scope });
        },
        toShowFormat(value){
            if (!value) return null
            const [year, month, day] = value.split('-')
            return `${day? day+ '/': ''}${month? month+ '/': ''}${year||''}`
        },
        toIsoFormat(value){
            if (!value) return null
            const [day, month, year] = value.split('/')
            return `${year? year+ '-': ''}${month? month+ '-': ''}${day||''}`
        },
        setDateParams(value){
            if (!value) return null;
            if (value.search('T')){
                value = value.split('T')[0];
            }
            const [year, month, day] = value.split('-')
            return `${year? year+ '-': '1970-'}${month? month+ '-': '01-'}${day||'01'}`
        },
        getBorderDate(value){
            return value === 'today'? new Date().toISOString().split('T')[0]: this.setDateParams(value);
        },

        setError(message){
            const parent = this.getParent(this.$parent)
            const field = parent? this.getField(parent): null;
            if (!field) return;
            parent.$validator.pause()
            if (!this.notClearErrors){
                parent.$validator.errors.clear()
            }
            parent.$validator.errors.add({
                id: field.id,
                field: this.name,
                msg: message,
                scope: this.$attrs['data-vv-scope'] || this.$options.scope,
            });

            field.setFlags({
                invalid: true,
                valid: false,
                validated: true,
            });
        },
        clearErrors(){
            const parent = this.getParent(this.$parent)
            parent? parent.$validator.resume(): null;
        },
        validateMin(val){
            if (this.calcMin && new Date(this.calcMin) > new Date(val)){
                this.setError('Date must be more or equal to '+this.toShowFormat(this.calcMin))
                return false;
            }
            return true;
        },
        validateMax(val){
            if (this.calcMax && new Date(this.calcMax) < new Date(val)){
                this.setError('Date must be smaller or equal to '+this.toShowFormat(this.calcMax))
                return false;
            }
            return true;
        },
        validateFormat(value, setError = true){
            if (!value) return true
            const [year, month, day] = value.split('-')
            if (!day || !month || !year || year.length !== 4 || isNaN(moment(`${day}/${month}/${year}`, 'DD/MM/YYYY').unix())){
                this.setError('Date should be in dd/mm/yyyy format.')
                return false;
            }
            return true;
        },
    },
    created() {
        this.manualDate = this.toShowFormat(this.val);
        this.calendarDate = this.validateFormat(this.val, false)? this.toIsoFormat(this.val): null;
    }
}
</script>
