<template>
    <v-list-item
        @click="checkKYC()"
        :ripple="false"
        link
        v-bind="$attrs"
        v-on="$listeners"
    >
        <v-list-item-icon>
            <div class="icon-container">
                <slot name="icon">
                    <BaseIcon :name="icon"/>
                </slot>
            </div>
        </v-list-item-icon>

        <v-list-item-content class="mb-0">
            <v-list-item-title>
                <p class="text-base">
                    <slot></slot>
                </p>
            </v-list-item-title>
        </v-list-item-content>
        <BaseIcon v-if="!badge" name="lock-closed" class="lock-icon"/>
        <div v-else class="badge-wrapper" :class="`badge-${badge}`">
            <BaseIcon :name="iconName(badge)" />
            <p>{{ capitalizeName(badge) }}</p>
        </div>
    </v-list-item>
</template>

<script>
import helpers from "~/shared/mixins/Helpers";

export default {
    name: "NavListItem",
    mixins: [helpers],
    props: {
        icon: {
            default: "",
        },
        href: {
            default: null,
        },
        badge: {
            default: null,
        },
        kyc: {
            default: null,
        },
    },
    methods: {
        checkKYC(){
            if (!this.kyc){
                return this.changeRoute(this.href);
            }

            this.__checkVerified(this.kyc).then(verified => {
                if (verified){
                    this.changeRoute(this.href)
                }
            });
        },
        changeRoute(href){
            if (href){
                this.__changeRoute(href);
                this.$store.commit('globalMenuModule/TOGGLE_MENU', false)
                this.$store.commit('globalMenuModule/TOGGLE_PROFILE_MENU', false)
            }
        },
        capitalizeName(text) {
            return text.charAt(0).toUpperCase() + text.slice(1);
        },
        iconName() {
            switch (this.badge) {
                case "pending":
                    return "loading-dots-circle";
                case "approved":
                    return "check-thin-circle-outline";
                case "rejected":
                    return "exclamation-outline";
                default:
                    return "loading-dots-circle";
            }
        },
    }
};
</script>

<style scoped>
.v-list-item {
    padding: 0 !important;
    min-height: unset !important;
    border-radius: 18px;
    transition: color, background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) !important;

    &:hover {
        color: white !important;
        background-color: #2f3094;
    }

    &::before {
        opacity: 0 !important;
    }

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    &:not(.v-list-item--disabled) {
        .lock-icon {
            display: none;
        }
    }

    &.v-list-item--disabled {
        color: unset !important;
        opacity: 0.3;
    }

    .v-list-item__icon {
        margin: 0 0.75rem 0 0 !important;

        .icon-container {
            border-radius: 18px;
            background: #2f3094;
            width: 3rem;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                color: white;
                font-size: 1.5rem;

                &::before {
                    color: white;
                }
            }
        }
    }

    .v-list-item__content {
        padding: 0 !important;

        .v-list-item__title p {
            line-height: 125%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .lock-icon {
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.badge-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 80px;
    font-size: 14px;
    line-height: 120%;
    margin-left: auto;
    margin-right: 4px;

    &.badge-pending {
        background: #fdbc42;
        color: #414243;
    }

    i {
        margin-right: 4px;
        font-size: 16px;
    }
}
</style>
