export const namespaced = true;

export const state = {
    isOpen: false,
};

export const mutations = {

    TOGGLE(state) {
        state.isOpen = !state.isOpen;
    },
};

export const actions = {
    setData({commit}, container) {
        commit("SET_DATA", container);
    },

    toggle({commit}) {
        commit("TOGGLE");
    },
};

export const getters = {

    getIsOpen: (state) => {
        return state.isOpen;
    },
};
