const ROUTE = 'expenses/'
import Helpers from '~/shared/mixins/Helpers'

const state = {

    activeItem: {},

    // Clime Dialogs
    climeDialog: false,

    // Approve Dialog
    approveDialog: false,

    // Decline Dialog
    declineDialog: false,

    // Pay View Dialog
    expensesPayView: false,
    expensesPayViewData: {
        item: [],
        currency: {},
    },

    // Proof Dialog
    proofDialog: false,
    proofDeclineRout: false,
    proofDeclinedId: null,

    // success and confirm
    alertDialog: false,
    alertDialogRole: null,


    ////////////// *
    queryData: {
        tab: '',
        search: null,
        page: 1,
        currency_id: null
    },

    paginate: null,
    toPay: 0,
    axiosLoading: false
};

const mutations = {
    setClimeDialog(state) {state.climeDialog = !state.climeDialog},
    setAlertDialog(state) {state.alertDialog = !state.alertDialog},
    setApproveDialog(state) {state.approveDialog = !state.approveDialog},
    setDeclineDialog(state) {state.declineDialog = !state.declineDialog},
    setAxiosLoading(state) {state.axiosLoading = !state.axiosLoading},
    setAlertDialogRole(state,data) {state.alertDialogRole = data},
    setActiveItem(state,data) { state.activeItem = data },
    setExpensesPayView(state) { state.expensesPayView = !state.expensesPayView },
    setExpensesPayViewData(state,data) {
        if (data) {
            state.expensesPayViewData = data
        } else {
            state.expensesPayViewData.item = [];
            state.expensesPayViewData.currency = {};
        }
    },

    setPaginate(state, data) {
        state.paginate = data.paginate
        state.toPay = data.to_pay
    },
    setActiveTab(state,tab) { state.queryData.tab = tab ?? ''},
    setProofDialog(state) {state.proofDialog = !state.proofDialog},
    setProofDeclineRout(state, data) {state.proofDeclineRout = data},
    setProofDeclinedId(state, id) {state.proofDeclinedId = id},
};

const actions = {

    triggerActiveTab ({state,commit,dispatch}, v) {
        if (state.queryData.tab === v) return;
        commit('setActiveTab', v);
        // if (v === 'vat_refunds') {
        //     // dispatch('getRequestsUsers');
        // } else {
        // }
        dispatch('getData');
    },

    openClimeDialog({commit}) {
        commit('setClimeDialog');
    },

    setProofDeclineRout({state,commit},data) {
        if (!_.isEmpty(state.proofDeclinedId)) commit('setProofDeclinedId', null);
        commit('setProofDeclineRout',data);
    },

    setProofDeclinedId({commit}, id) {
        commit('setProofDeclinedId', id);
    },

    openAndCloseProofDialog({ state, commit }, item = null) {
        commit('setActiveItem', item ?? {});
        commit('setProofDialog');
    },

    downloadFile({}, {id, file_id}) {
        if (!id || !file_id) return;
        Helpers.methods.__downloadStream(`/${ROUTE}receipt/${id}/${file_id}`);
    },

    previewFile({}, {url}) {
        Helpers.methods.__downloadStream(url, {}, 'GET', true);
    },

    downloadReceiptDocOrZip({state,commit, rootState}, id) {
        if (!id || state.axiosLoading) return;
        commit('setAxiosLoading');
        axios.get(`expenses/get-receipt-zip/${id}`)
            .then(response => {
                commit('setAxiosLoading');
                window.open(response.data.uri, '_blank');
            }).catch(error => {
            commit('setAxiosLoading');
            commit('setAlert', {type: 'error', message: error.response.data.message}, {root: true});
        })
    },

    openDeclineDialog({state,commit},id = null) {
        let ids = [];
        if (!Array.isArray(id)) {
            ids.push(id);
        } else {
            ids = id;
        }
        commit('setActiveItem', state.declineDialog ? {} : {ids: ids});
        commit('setDeclineDialog');
    },

    openApproveDialogAsExpensesManager({state,commit}, {expenseRequestId = null, expensesManagerWorkerId = null}) {
        let ids = [];
        if (!Array.isArray(expenseRequestId)) {
            ids.push(expenseRequestId);
        } else {
            ids = expenseRequestId;
        }

        commit('setActiveItem', state.declineDialog ? {} : {ids, expensesManagerWorkerId});
        commit('setApproveDialog');
    },

    openDeclineDialogAsExpensesManager({state,commit}, {expenseRequestId = null, expensesManagerWorkerId = null}) {
        let ids = [];
        if (!Array.isArray(expenseRequestId)) {
            ids.push(expenseRequestId);
        } else {
            ids = expenseRequestId;
        }

        commit('setActiveItem', state.declineDialog ? {} : {ids, expensesManagerWorkerId});
        commit('setDeclineDialog');
    },

    openPayViewDialog({commit}, data) {
        commit('setExpensesPayViewData', data);
        commit('setExpensesPayView');
    },

    openAndCloseAlertDialog({state, commit, dispatch}, {id = null, role = null}) {
        if (role === 'declined' && state.expensesPayView) {
            dispatch('openPayViewDialog');
        }

        if (id) {
            commit('setActiveItem', {id: id});
            commit('setAlertDialogRole', role);
            commit('setAlertDialog');
            return;
        }
        if (state.alertDialog) {
            commit('setAlertDialog');
            commit('setAlertDialogRole', null);
            commit('setActiveItem', {});
            dispatch('getData');
        } else {
            commit('setAlertDialogRole', role);
            commit('setAlertDialog');
        }
    },

    async alertDialogAction({dispatch,commit,state}, {route,requestType,successType}) {
        if (state.axiosLoading) return;
        dispatch('openAndCloseAlertDialog', {})
        commit('setAxiosLoading');
        let method = null;
        let routeForMethod = `${ROUTE}${route}`;
        switch (requestType) {
            case 'delete' :
                method = window.axios.delete(routeForMethod)
                break;
            case 'post' :
                method = window.axios.post(routeForMethod)
                break;
            case 'put' :
                method = window.axios.put(routeForMethod)
                break;
            default:
                commit('setAxiosLoading');
                return;
        }
        await method.then(() => {
                commit('setAxiosLoading');
                dispatch('openAndCloseAlertDialog', {role: successType})
            }).catch(error => {
                commit('setAxiosLoading');
                commit('setAlert', {type: 'error', message: error.response.data.message}, { root: true });
                throw error;
            });
    },

   /* async cancelExpensesRequest({dispatch,commit}, id) {
        if (state.axiosLoading) return;
        dispatch('openAndCloseAlertDialog', {})
        commit('setAxiosLoading');
        await window.axios
            .delete(`${ROUTE}cancel/${id}`)
            .then(() => {
                commit('setAxiosLoading');
                dispatch('openAndCloseAlertDialog', {role: 'deleted_success'})
            })
            .catch(error => {
                commit('setAxiosLoading');
                commit('setAlert', {type: 'error', message: error.response.data.message}, { root: true });
                throw error;
            });
    },*/

    async getData({dispatch,state,commit},q = {}) {

        if (state.axiosLoading) return;
        for (const [key, value] of Object.entries(q)) {
            if ('dates' === key) {
                const [from, to] = value;
                state.queryData.date_from = from;
                state.queryData.date_to = to;
            } else {
                state.queryData[key] = value;
            }
        }
        if (!q.page) {
            state.queryData.page = 1;
        }
        commit('setAxiosLoading');
        window.axios
            .get(`${ROUTE}paginate`, {
                params: state.queryData,
            })
            .then(response => {
                commit('setAxiosLoading');
                commit('setPaginate', response.data.data);
                dispatch('setActiveWallet', state.queryData.currency_id);
            })
            .catch(error => {
                commit('setAxiosLoading');
                commit('setAlert', {type: 'error', message: error.response.data.message}, {root: true});
            });
    },

    setActiveWallet({ rootGetters, rootState }, id) {
        if (_.isEmpty(id)) id = rootGetters.currencies[0].id;
        const wallets = process.env.VUE_APP_MODULR_INTEGRATION === 'COMPACT'
            ? rootGetters.wallets.filter(w => !w.is_modulr)
            : rootGetters.wallets;
        const wallet = wallets.find(w => w.currency_id === id);
        if (wallet) rootState.activeWalletId = wallet.id;
    },

    closeApproveDialog({state}) {
        state.approveDialog = false;
    },
};

const getters = {
    climeDialog: (state) => state.climeDialog,
    alertDialog: (state) => state.alertDialog,
    approveDialog: (state) => state.approveDialog,
    declineDialog: (state) => state.declineDialog,
    alertDialogRole: (state) => state.alertDialogRole,
    activeItem: (state) => state.activeItem,
    expensesPayView: (state) => state.expensesPayView,
    expensesPayViewData: (state) => state.expensesPayViewData,
    getDataWatch: (state) => state.getDataWatch,
    paginate: (state) => state.paginate,
    queryData: (state) => state.queryData,
    proofDialog: (state) => state.proofDialog,
    payByEmployeeWalletDialog: (state) => state.payByEmployeeWalletDialog,
    proofDeclineRout: (state) => state.proofDeclineRout,
    proofDeclinedId: (state) => state.proofDeclinedId,
    axiosLoading: (state) => state.axiosLoading,
    activeTab: (state) => state.queryData.tab,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
