import Middleware from "~/routes/middleware/Middleware";
import Tracking from '~/modules/guest/utils/Tracking';

export default class tracking extends Middleware{

    currentUrlShouldBeFirstVisitUrl;

    constructor(...args) {
        super(...args);
    }

    async resolve() {
        if (this.getCurrentUrlShouldBeFirstVisitUrl() || !Tracking.getUrlFirstVisitCookie()) {
            Tracking.setUrlFirstVisitToCurrentUrl();
            Tracking.utmParamNames.forEach(name => this.saveUtmParamToCookie(name));
        }

        return this.responseNext();
    }

    getCurrentUrlShouldBeFirstVisitUrl() {
        if (this.currentUrlShouldBeFirstVisitUrl === undefined) {
            this.currentUrlShouldBeFirstVisitUrl = Tracking.utmParamNames.some(name => this.to.query[name]);
        }
        return this.currentUrlShouldBeFirstVisitUrl;
    }

    saveUtmParamToCookie(name) {
        const paramValue = this.to.query[name];
        if (paramValue) {
            Tracking.setCookie(name, paramValue);
        } else if (Tracking.getCookie(name)) {
            Tracking.deleteCookie(name);
        }
    }
}
