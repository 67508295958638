<template>
    <div :class="bottomOffset? 'mb-4': null">
        <div class="select-card-field-group">
            <template v-if="cardIsEmpty">
                <div class="no-card-wrapper">
                    <BaseIcon name="card" class="heading-1 color-dark-grey mb-3" />
                    <p class="text-lg font-medium color-black mb-2">There is no card</p>
                    <p class="text-sm color-dark-grey mb-3">
                        {{ isSuperAdmin? 'Member': 'You' }} have not connected any card yet.
                    </p>
                    <BaseButton
                        @click="cardDialog = true"
                        type="button"
                        class="btn-small btn-purple-outline text-sm font-medium color-purple w-100"
                    >
                        Add card
                    </BaseButton>
                </div>
            </template>
            <template v-else>
                <div class="position-relative mb-2">
                    <BaseSelect
                        avatar
                        :disabled="disabled"
                        :label="label"
                        :label-bottom-offset="labelBottomOffset"
                        :label-class-val="labelClassVal"
                        :placeholder="placeholder"
                        :items="cardAll"
                        item-value="id"
                        item-text="last4"
                        name="card"
                        v-model="val"
                        :error-messages="errorMessages"
                        labelClassVal="color-black custom-label mb-1 text-sm"
                        class="global-base-select-menu"
                        customClassActiveItem="custom-active"
                    >
                        <template v-slot:selection={item}>
                            <div class="d-flex align-center">
                                <span class="mr-2">**** **** **** {{ item.last4 }}</span>
                                <v-img
                                    contain
                                    height="20"
                                    width="20"
                                    :src="`${$store.state.imagePath}${item.brand_logo}`"
                                    :alt="item.brand"
                                />
                            </div>
                          <div class="d-flex align-center" v-if="canEditCard">
                            <v-btn @click.stop="$emit('editCard',item)" small icon color="#5152fb">
                              <icon size="16" name="icon-edit" color="#5152fb" class="mr-2"></icon>
                            </v-btn>

                          </div>
<!--                          <button  class="color-purple fs-13 mr-20 bold-text tm-initial">-->

<!--                            <icon size="16" name="icon-edit" color="#5152fb"></icon> Edit-->
<!--                          </button>-->
                        </template>
                        <template v-slot:item={item}>
                            <div class="d-flex align-center">
                                <span class="mr-2">**** **** **** {{ item.last4 }}</span>
                                <v-img
                                    contain
                                    height="20"
                                    width="20"
                                    :src="$store.state.imagePath + item.brand_logo"
                                    :alt="item.brand"
                                />
                            </div>
                        </template>
                    </BaseSelect>
                </div>
                <BaseButton
                    @click="cardDialog = true"
                    type="button"
                    class="btn-small btn-purple-outline text-sm font-medium color-purple w-100"
                >
                    Add new card
                </BaseButton>
            </template>
        </div>

<!--        TODO old remove-->
<!--        <template v-if="false">
            <tm-advanced-select
                v-if="cardAll && cardAll.length"
                :disabled="disabled"
                :bottom-offset="false"
                :label="label"
                :label-bottom-offset="labelBottomOffset"
                :label-class-val="labelClassVal"
                :placeholder="placeholder"
                :items="cardAll"
                item-value="id"
                item-text="last4"
                name="card"
                v-model="val"
                :error-messages="errorMessages"
            >
                <template v-slot:selection={item}>
                    <div class="d-flex align-center">
                        <span class="mr-2">**** **** **** {{ item.last4 }}</span>
                        <v-img contain height="20" width="20" :src="$store.state.imagePath + item.brand_logo" :alt="item.brand"></v-img>
                    </div>
                </template>
                <template v-slot:item={item}>
                    <div class="d-flex align-center">
                        <span class="mr-2">**** **** **** {{ item.last4 }}</span>
                        <v-img contain height="20" width="20" :src="$store.state.imagePath + item.brand_logo" :alt="item.brand"></v-img>
                    </div>
                </template>
            </tm-advanced-select>
            <div v-else class="position-relative">
                <p class="mb-1 empty-card-label">No cards</p>
                <p class="mb-4 empty-card-description">{{ isSuperAdmin? 'Member': 'You' }} have not connected any card yet</p>
                <error-message :messages="errorMessages"></error-message>
            </div>
            <div v-if="!isSuperAdmin" class="d-flex align-center justify-space-between" :class="__smallDesktop && 'mt-10' || '' ">
                <btn @click="cardDialog = true" :btn-text="true">
                    <div class="d-flex align-center tm-small-text tm-color-purple font-weight-medium custom-btn-tertiary mt-10">
                        <icon name="icon-plus-circle-outline" class="mr-1" :color="null" :size="null"></icon>
                        <span style="text-transform: initial">Add new</span>
                    </div>
                </btn>
                <div class="d-flex align-center mt-1 overflow-hidden">
                    <img :src="$store.state.imagePath+'visa.svg'" alt="visa" class="mr-1">
                    <img :src="$store.state.imagePath+'mastercard.svg'" alt="mastercard" class="mr-1">
                    <img :src="$store.state.imagePath+'discover.svg'" alt="mastercard" class="mr-1">
                    <img :src="$store.state.imagePath+'american-express.svg'" alt="mastercard">
                </div>
            </div>
        </template>-->


        <!-- Card dialog -->
        <CardForm
            v-model="cardDialog"
            @updateCard="updateCard"
            :hideBackgroundModals="hideBackgroundModals"
            :default-org="defaultOrg"
            :paymentType="paymentType"
        />
    </div>
</template>
<script>
import TmAdvancedSelect from "./TmAdvancedSelect";
import Btn from "../Btn";
import CardForm from "~/modules/dashboard/components/modals/CardForm";
import Helpers from "~/shared/mixins/Helpers";
import Screen from "~/shared/mixins/Screen";
import ErrorMessage from "./ErrorMessage";

export default {
    name: 'TmSelectCard',
    components: {
        ErrorMessage,
        CardForm,
        Btn,
        TmAdvancedSelect
    },
    mixins: [Helpers,Screen],
    props: {
        label: {
            default: 'Select card'
        },
        labelBottomOffset: {
            default: true
        },
        labelClassVal: {
            default: ''
        },
        placeholder: {
            default: 'Select card'
        },
        value: {
            default: null
        },
        cards: {
            default: () => []
        },
        errorMessages: {
            default: () => []
        },
        bottomOffset: {
            default: false
        },
        defaultOrg: {
            default: false
        },
        isSuperAdmin: {
            default: false
        },
        disabled: {
            default: false
        },
        hideBackgroundModals: {
            type: Boolean,
            default: true,
        },
        canEditCard:{
            default: false,
        },
        paymentType: {
            type: String,
            default: 'stripe',
            validator: value => ['stripe', 'stripe_client'].includes(value),
        },
    },
    data(){
        return {
            val: this.value,
            cardAll: this.cards,
            cardDialog: false
        }
    },
    computed: {
        cardIsEmpty() {
            return this.cards.length === 0 && this.cardAll.length === 0
        }
    },
    methods:{
        updateCard(cards){
            this.$emit('updateCard', cards)
            this.cardAll = cards
            let ids = cards.map(item => item.id);
            this.val = Math.max.apply(null, ids);
        }
    },
    watch: {
        value(){
            this.val = this.value;
        },
        val(){
            this.$emit('input', this.val);
        },
        cards() {
            this.cardAll = this.cards;
        },
    },
    created() {
       this.val = (!this.val && this.cards.length > 0) ?  this.cards[0].id : this.val
    }
}
</script>
