import { render, staticRenderFns } from "./BasePanelsOnBoarding.vue?vue&type=template&id=16fbf2c9"
import script from "./BasePanelsOnBoarding.vue?vue&type=script&lang=js"
export * from "./BasePanelsOnBoarding.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports