import {faker} from "@faker-js/faker";

export const namespaced = true;

import usersSimpleData from "~/assets/data/usersSimpleData.json";
import usersExtraData from "~/assets/data/usersExtraData.json";
import countriesData from "~/assets/data/countriesData.json";
import currenciesData from "~/assets/data/currenciesData.json";
import authUser from "~/assets/data/authUser.json";

export const state = {
    usersSimple: usersSimpleData,
    users: usersExtraData,
    countries: countriesData,
    user: authUser,
    currencies: currenciesData,
    occupations: [],
    cards: [],
    wallets: [],
};

export const mutations = {
    ADD_USER(state, user) {
        state.users.push(user);
    },

    ADD_USER_SIMPLE(state, user) {
        state.users.push(user);
    },

    UPDATE_USER(state, user) {
        const userIndex = state.users.findIndex(
            (element) => element.id === user.id
        );
        Object.assign(state.users[userIndex], user);
    },

    UPDATE_USER_SIMPLE(state, user) {
        const userIndex = state.usersSimple.findIndex(
            (element) => element.id === user.id
        );
        Object.assign(state.usersSimple[userIndex], user);
    },

    DELETE_USER(state, userId) {
        const userIndex = state.users.findIndex((element) => element.id === userId);
        state.users.splice(userIndex, 1);
    },

    DELETE_USER_SIMPLE(state, userId) {
        const userIndex = state.usersSimple.findIndex(
            (element) => element.id === userId
        );
        state.usersSimple.splice(userIndex, 1);
    },

    SET_USER_PLAN(state, plan) {
        state.user.plan = plan;
    },

    SET_USER_TRIAL(state, trial) {
        state.user.trial = trial;
    },

    SET_OCCUPATION_DATA(state, occupations) {
        state.occupations = occupations;
    },

    ADD_CARD(state, card) {
        card.amount = faker.finance.amount({min: 100, max: 10000});
        state.cards.push(card);
    },
};

export const actions = {
    addUser({commit}, user) {
        commit("ADD_USER", user);
    },

    addUserSimple({commit}, user) {
        commit("ADD_USER_SIMPLE", user);
    },

    updateUser({commit}, user) {
        commit("UPDATE_USER", user);
    },

    updateUserSimple({commit}, user) {
        commit("UPDATE_USER_SIMPLE", user);
    },

    deleteUser({commit}, userId) {
        commit("DELETE_USER", userId);
    },

    deleteUserSimple({commit}, userId) {
        commit("DELETE_USER_SIMPLE", userId);
    },

    setUserPlan({commit}, plan) {
        commit("SET_USER_PLAN", plan);
    },

    setUserTrial({commit}, trial) {
        commit("SET_USER_TRIAL", trial);
    },

    setInitialOccupationsData({commit}) {
        const occupations = [];

        commit("SET_OCCUPATION_DATA", occupations);
    },

    addCard({commit}, card) {
        commit("ADD_CARD", card);
    },
};

export const getters = {
    getUsersSimple: (state) => {
        return state.usersSimple;
    },

    getUsers: (state) => {
        return state.users;
    },

    getUser: (state) => {
        return state.user;
    },

    getUsersFromSelectedCountry: (state) => (countryName) => {
        return state.users.filter((user) => user.country.name === countryName);
    },

    getCountries: (state) => {
        return state.countries;
    },

    getCurrencies: (state) => {
        return state.currencies;
    },

    getOccupations: (state) => {
        return state.occupations;
    },

    getCards: (state) => {
        return state.cards;
    },

    getWallets: (state) => {
        return state.wallets;
    },
};
