<template>
    <div class="stripe-form">
        <form action="#">
            <div class="row">
                <div class="col-12 mb-4">
                    <BaseInput
                        custom-label-parent-classes="text-sm"
                        label="Card holder name"
                        force-placeholder="Type here"
                        v-validate="cardObj.rules.holder"
                        v-model="cardObj.values.holder"
                        name="holder"
                        id="card-holder"
                        :error-messages="errors.collect('holder')"
                    />
                </div>
                <div class="col-12 mb-4">
                    <label class="custom-label text-small color-black text-sm"
                        >Card number</label
                    >
                    <div id="card-number"></div>
                    <BaseErrorMessage :messages="cardNumberErrors" />
                </div>
                <div class="col-12 col-md-6 py-0 mb-4">
                    <label class="custom-label text-small color-black text-sm"
                        >Expiry date</label
                    >
                    <div id="card-expiry"></div>
                </div>
                <div class="col-12 col-md-6 py-0 mb-4">
                    <label class="custom-label text-small color-black text-sm">CVC</label>
                    <div id="card-cvc"></div>
                </div>
                <div class="col-12 mb-4">
                    <BaseSelect
                        avatar
                        autocomplete
                        label="Currency"
                        name="currency_id"
                        class="global-base-select-menu"
                        customClassActiveItem="custom-active"
                        labelClassVal="color-black custom-label mb-0 text-sm"
                        data-vv-as="currency"
                        item-text="code"
                        item-value="id"

                        :select-first="true"
                        v-model="cardObj.values.currency_id"
                        v-validate="cardObj.rules.currency_id"
                        :error-messages="errors.collect('currency_id')"
                        :items="cardCurrencies"
                    />
                </div>
                <div class="col-12 mb-4">
                    <BaseSelect
                        avatar
                        autocomplete

                        label="Country"
                        name="country"
                        placeholder="Select"
                        class="global-base-select-menu"
                        customClassActiveItem="custom-active"
                        labelClassVal="color-black custom-label mb-0 text-sm"
                        data-vv-as="Country"
                        item-value="code"

                        v-model="cardObj.values.billing_address.country"
                        v-validate="cardObj.rules.billing_address.country"
                        :error-messages="errors.collect('country')"

                        :search-autoload="true"
                        :search-route="$store.state.getPath+'search-countries'"
                        :search-params="{ code: cardObj.values.billing_address.country }"
                        search-key="value"
                    />
                </div>
                <div class="col-12 mb-4">
                    <BaseInput
                        label="City"
                        name="city"
                        customLabelParentClasses="text-sm mb-1"

                        v-model="cardObj.values.billing_address.city"
                        v-validate="cardObj.rules.billing_address.city"
                        :error-messages="errors.collect('city')"
                        data-vv-as="City"
                    />
                </div>
                <div class="col-12 mb-4">
                    <BaseInput
                        label="Address line 1"
                        name="line1"
                        customLabelParentClasses="text-sm mb-1"

                        v-model="cardObj.values.billing_address.line1"
                        v-validate="cardObj.rules.billing_address.line1"
                        :error-messages="errors.collect('line1')"
                        data-vv-as="Address Line 1"
                    />
                </div>
                <div class="col-12 mb-4">
                    <BaseInput
                        label="Address line 2 (optional)"
                        name="line2"
                        customLabelParentClasses="text-sm mb-1"

                        v-model="cardObj.values.billing_address.line2"
                        v-validate="cardObj.rules.billing_address.line2"
                        :error-messages="errors.collect('line2')"
                        data-vv-as="Address Line 2"
                    />
                </div>
                <div class="col-6 mb-4">
                    <BaseInput
                        label="Postal code"
                        name="postal_code"
                        customLabelParentClasses="text-sm mb-1"

                        v-model="cardObj.values.billing_address.postal_code"
                        v-validate="cardObj.rules.billing_address.postal_code"
                        :error-messages="errors.collect('postal_code')"
                        data-vv-as="Postal Code"
                    />
                </div>
                <div class="col-6 mb-4">
                    <BaseInput
                        label="State"
                        name="state"
                        customLabelParentClasses="text-sm mb-1"

                        v-model="cardObj.values.billing_address.state"
                        v-validate="cardObj.rules.billing_address.state"
                        :error-messages="errors.collect('state')"
                        data-vv-as="State"
                    />
                </div>
            </div>
            <BaseErrorMessage :messages="stripeErrors" />
            <BaseButton
                class="btn-big btn-purple color-white w-100 mt-4 mb-3"
                :disabled="isDisabled"
                :loading="loading"
                @click="onSubmit"
            >
                {{ submitBtnTxt }}
            </BaseButton>
        </form>
    </div>
</template>
<script>
import _ from "lodash";
import Card from '~/modules/dashboard/models/Card';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'StripeForm',
    components: {
    },
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        scenario: {
            required: true,
            type: String,
            validator: value => ['card', 'payment'].includes(value),
        },
        card: {
            default: null,
            type: Object,
        },
        modelId: {
            default: null,
            type: [String, Number],
        },
        modelType: {
            default: null,
            type: String,
            validator: value => ['invoice', 'salary'].includes(value),
        },
    },
    data() {
        return {
            cardForm: {
                cvc: '',
                number: '',
                expiry: '',
            },
            stripe: null,
            stripeClientSecret: null,
            cardNumber: null,
            cardExpiry: null,
            cardCvc: null,
            token: null,
            stripeErrors: [],
            cardNumberErrors: [],
            loading: false,
            cardObj: new Card(this.card || {}),
        };
    },

    computed: {
        submitBtnTxt() {
            switch (this.scenario) {
                case 'card':
                    return this.card?.id ? 'Update card' : 'Add card';
                case 'payment':
                    return 'Pay';
                default:
                    return 'Submit';
            }
        },
        isFormTouchedOrInvalid() {
            return Object.keys(this.fields).some(key => this.fields[key].touched || this.fields[key].invalid);
        },
        isNotFilled() {
            return !this.cardForm.cvc || !this.cardForm.number || !this.cardForm.expiry || this.errors.all().length > 0 || this.isFormTouchedOrInvalid;
        },
        hasErrors() {
            return 0 < this.stripeErrors.length || 0 < this.cardNumberErrors.length;
        },
        isDisabled() {
            if (!this.hasErrors && !this.isNotFilled) {
                this.$emit('validated');
            }
            return this.disabled || this.hasErrors || this.isNotFilled;
        },
        cardCurrencies() {
            return this.$store.getters.withdrawCurrencies || [];
        },
    },

    watch: {
        card() {
            this.cardForm = {
                cvc: '',
                number: '',
                expiry: '',
            };
            this.$validator.reset();
            this.cardObj = new Card(this.card || {});
            this.clearCardErrors();
            this.setUpStripe();
        },
    },

    mounted() {
        this.setUpStripe();
    },

    methods: {
        ...mapActions('stripe', ['cardRegisterIntent', 'saveCard']),
        setUpStripe() {
            if (window.stripe === undefined) {
                console.log('Stripe V3 library not loaded!');
            } else {
                this.stripe = window.stripe;

                const elements = this.stripe.elements({
                    fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Roboto' }],
                });

                const style = {
                    base: {
                        color: 'black',
                        fontFamily: 'Roboto, sans-serif',
                        fontSmoothing: 'antialiased',
                        fontSize: '13px',
                        fontWeight: '400',
                        '::placeholder': {
                            color: '#a8a8a8',
                        },
                    },
                    invalid: {
                        color: '#f80000',
                        iconColor: '#f80000',
                    },
                };

                if (document.getElementById('card-number')) {
                    this.cardNumber = elements.create('cardNumber', { style, placeholder: '1234 5678 1234 5678' });
                    this.cardNumber.mount('#card-number');
                    this.cardExpiry = elements.create('cardExpiry', { style, placeholder: '01/' + (new Date().getFullYear() + 1).toString().slice(-2) });
                    this.cardExpiry.mount('#card-expiry');
                    this.cardCvc = elements.create('cardCvc', { style, placeholder: '111' });
                    this.cardCvc.mount('#card-cvc');

                    this.listenForErrors();
                }
            }
        },
        listenForErrors() {
            this.cardNumber.addEventListener('change', event => {
                document.getElementById('card-number').classList.remove('has-error');
                this.toggleError(event);
                this.cardNumberErrors = [];
                this.cardForm.number = event.complete;
            });
            this.cardExpiry.addEventListener('change', event => {
                document.getElementById('card-expiry').classList.remove('has-error');
                this.toggleError(event);
                this.stripeErrors = [];
                this.cardForm.expiry = event.complete;
            });
            this.cardCvc.addEventListener('change', event => {
                document.getElementById('card-cvc').classList.remove('has-error');
                this.toggleError(event);
                this.stripeErrors = [];
                this.cardForm.cvc = event.complete;
            });
        },
        toggleError(event) {
            if (event.error) {
                this.stripeErrors.push(event.error.message);
            } else {
                this.stripeErrors = [];
            }
        },
        clearCardErrors() {
            this.stripeErrors = [];
            this.cardNumberErrors = [];
            document.getElementById('card-number').classList.remove('has-error');
            document.getElementById('card-expiry').classList.remove('has-error');
            document.getElementById('card-cvc').classList.remove('has-error');
        },
        async validate() {
            let valid = await this.$validator.validateAll();

            if (!this.cardForm.number) {
                valid = false;
                this.cardNumberErrors.push('The card number is required');
                document.getElementById('card-number').classList.add('has-error');
            }
            if (!this.cardForm.expiry) {
                valid = false;
                this.stripeErrors.push('The expiry date field is required');
                document.getElementById('card-expiry').classList.add('has-error');
            }
            if (!this.cardForm.cvc) {
                valid = false;
                this.stripeErrors.push('The CVC field is required');
                document.getElementById('card-cvc').classList.add('has-error');
            }

            if (this.stripeErrors.length > 0) {
                valid = false;
            }
            return valid;
        },
        async onSubmit() {
            switch (this.scenario) {
                case 'card':
                    return this.save();
                case 'payment':
                    return this.pay();
            }
        },
        async save() {
            this.clearCardErrors();
            const valid = await this.validate();
            if (valid) {
                this.loading = true;
                this.$emit('lockSubmit', true);
                try {
                    const registerIntent = await this.cardRegisterIntent();
                    const { setupIntent, error } = await this.stripeConfirmCardSetup({
                        clientSecret: registerIntent.client_secret,
                        data: {
                            payment_method: {
                                card: this.cardNumber,
                                billing_details: {
                                    name: this.cardObj.values.holder,
                                    address: this.cardObj.values.billing_address,
                                },
                                metadata: {
                                    currency: this.cardCurrencies.find(c => c.id === this.cardObj.values.currency_id)?.code,
                                },
                            },
                        },
                    });
                    if (setupIntent) {
                        const response = await this.saveCard({ id: this.card?.id, registerIntentId: setupIntent.id });
                        this.$emit('process', { card: response });
                    } else if (error) {
                        const title = this.card?.id ? "Card not updated" : "Card not added";
                        const text = 'We were not able to process your card at this moment. Please try again or <a class="color-purple" href="mailto:support@nteams.com">contact support</a>.';
                        this.$store.commit('setAlertModal', {type: 'error', data: { title, text, asHtml: true }});
                    }
                } catch (error) {
                    const title = this.card?.id ? "Card not updated" : "Card not added";
                    const text = 'We were not able to process your card at this moment. Please try again or <a class="color-purple" href="mailto:support@nteams.com">contact support</a>.';
                    this.$store.commit('setAlertModal', {type: 'error', data: { title, text, asHtml: true }});
                }
                this.loading = false;
            }
        },
        async pay() {
            this.clearCardErrors();
            const valid = await this.validate();
            if (valid) {
                this.loading = true;
                this.$emit('lockSubmit', true);
                try {
                    let paymentIntent = null;
                    if ('invoice' === this.modelType) {
                        paymentIntent = await this.$store.dispatch('stripe/createInvoicePaymentIntent', {
                            invoiceId: this.modelId,
                        });
                    } else {
                        paymentIntent = await this.$store.dispatch('stripe/createPaymentIntentRequest', {
                            salaryId: this.modelId,
                        });
                    }

                    const result = await this.stripeConfirmCardPayment({
                        clientSecret: paymentIntent.client_secret,
                        data: {
                            payment_method: {
                                card: this.cardNumber,
                                billing_details: {
                                    name: this.cardObj.values.holder,
                                    address: this.cardObj.values.billing_address,
                                },
                            },
                        }
                    });
                    if (result.error) {
                        let title = "Payment not successful";
                        let message = `We were not able to process your payment. Please try again or <a class="color-purple" href="mailto:support@nteams.com">contact support</a>.`;
                        if (['invalid_request_error', 'validation_error', 'card_error'].includes(result.error.type)) {
                            title = "Cancelled";
                            message = "Your payment has been cancelled successfully.";
                        }
                        this.$emit('process', { error: { title, message } });
                    } else {
                        if ('invoice' === this.modelType) {
                            const response = await this.$store.dispatch('stripe/createInvoicePayment', { paymentIntentId: result.paymentIntent.id, invoiceId: this.modelId });
                            this.$emit('process', { paymentIntent: response });
                        } else {
                            const response = await this.$store.dispatch('stripe/createPaymentIntentProcess', { paymentIntentId: result.paymentIntent.id, salaryId: this.modelId });
                            this.$emit('process', { paymentIntent: response });
                        }
                    }
                } catch (error) {
                    this.$emit('process', { error: { title: 'Payment not successful', message: 'We were not able to process your payment. Please try again or <a class="color-purple" href="mailto:support@nteams.com">contact support</a>.' } });
                }
                this.loading = false;
            }
        },
        async stripeConfirmCardPayment({clientSecret, data}) {
            return this.stripe.confirmCardPayment(clientSecret, data);
        },
        async stripeConfirmCardSetup({ clientSecret, data }) {
            return this.stripe.confirmCardSetup(clientSecret, data);
        },
    },
};
</script>
<style lang="scss">
.stripe-form {
    .row > div {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
.StripeElement {
    width: 100%;
    transition: .3s;
    min-height: 2.1875rem;
    border: 0.0625rem solid #dadfe6;
    border-radius: 0.1875rem;
    outline: none;
    background-color: transparent;
    font-style: normal;
    font-weight: 400;
    font-size: .8125rem;
    line-height: normal;
    color: #000000;
    padding: .5125rem 0 0 0.4125rem;
}
.StripeElement--focus {
    border: 0.0625rem solid #1e60eb;
}
.StripeElement--invalid, .has-error {
    border: 0.0625rem solid #f80000 !important;
}
.error-msg {
    color: #fa4769;
    font-size: 10px;
}
</style>
