import Middleware from "~/routes/middleware/Middleware";

export default class eorInvoiceGuest extends Middleware{
    constructor(...args) {
        super(...args);
    }

    async checkInvoice(token) {
        return await axios.post('eor-invoice/check/' + token)
            .then(response => response.data)
    }

    async resolve() {
        const token = this.to.params?.token;
        const response = await this.checkInvoice(token)

        return response && response.valid ?
            this.responseNext(response.data) :
            this.responseAbort('eor-invoice-expired')
    }
}
