import axios from "axios";
import {format, parseISO, isWithinInterval, isAfter} from 'date-fns';
import NativeCardRequest from "~/modules/dashboard/models/NativeCardRequest";

// initial state
const state = {
    loading: false,
    customer_status_loading: false,
    card_request: 0,
    isRecorded: false,
    card_request_error: false,
    // last_card_button_clicked: '',
    customer_status: null,
    customer_status_error: false,
    card_request_type: null,
    items: [],
    cards_live_expenses: [
        {
            service: 'unlimint',
            type: 'virtual',
            expenses: {}
        },
        {
            service: 'unlimint',
            type: 'physical',
            expenses: {}
        },
        {
            service: 'modulr',
            type: 'physical',
            expenses: {}
        },
        {
            service: 'paynetics',
            type: 'soloEntity',
            expenses: {}
        },
        {
            service: 'junction',
            type: 'soloEntity',
            expenses: {}
        },
        {
            service: 'native',
            type: 'physical',
            expenses: {}
        },
    ],
    cards_live_data: [
    ],
    cards_monthly_transactions: [
        /* {
            service: 'unlimint',
            type: 'virtual',
            total: 0,
            expenses: 0,
            count: 0,
            date_from: null,
            date_to: null,
            transactions: {}
        } */
    ],
    iframeUrl: [],
    statuses: [
        {
            name: "Success",
            value: "success",
        },
        {
            name: "Pending",
            value: "pending",
        },
        {
            name: "Declined",
            value: "declined",
        }
    ],
    cardServices: [
        'flutterwave',
        'unlimint',
        'native',
    ],

    types: [
        { name: 'Card transaction', value: 'card-transaction' },
        { name: 'Top Up', value: 'top-up' },
        { name: 'Withdrawal', value: 'withdraw' },
        { name: 'ATM fee', value: 'atm-fee' },
    ],
    cardRequests: [],
    nativeCardRequests: [],
    nativeCardRequestsOtherOrg: [],
    nativeCards: [],
    nativeCardsOtherOrg: [],
    nativeCardAvailableOrgIds: [],
    showBalanceLoader: true,
    infoModalVisible: false,
    infoModalData: {},
    cards_iframes: [],
    requested_activation_frame:[],
    showConfirm: false,
    storedLoading: false,
    storedCards: null
};

// getters
const getters = {
    getStatuses: state => state.statuses,
    getTypes: state => state.types,
    cardRequests: state => state.cardRequests,
    nativeCardRequests: state => state.nativeCardRequests,
    nativeCardAvailableOrgIds: state => state.nativeCardAvailableOrgIds,
    nativeCards: state => state.nativeCards,
    nativeCardsOtherOrg: state => state.nativeCardsOtherOrg,
    infoModalVisible: state => state.infoModalVisible,
    infoModalData: state => state.infoModalData,
    activeCardLiveExpenses: (state, getters, rootState) => {
        return state.cards_live_expenses.find(item => item.service === rootState.cardModule.activeCard.provider && item.type === (rootState.cardModule.activeCard.virtual ? 'virtual' : 'physical')).expenses;
    },
}

// mutations
const mutations = {
    SET_REQUEST_ACTIVATION_IFRAME(state, payload) {
        if(state.requested_activation_frame.findIndex(item => item.type === payload.type && item.card_id === payload.card_id) > -1) {
            let index = state.requested_activation_frame.findIndex(item => item.type === payload.type && item.card_id === payload.card_id);
            state.requested_activation_frame.splice(index, 1);
        }
        state.requested_activation_frame.push({
            card_id: payload.card_id,
            type: payload.type,
        });
    },
    SET_CARD_IFRAME(state, payload) {
        /**
         * card_id
         * url
         * name
         * caller
         * timestamp
         */
        if (state.cards_iframes.findIndex(item => item.caller === payload.caller && item.name === payload.name && item.card_id === payload.card_id ) > -1) {
            let index = state.cards_iframes.findIndex(item => item.caller === payload.caller && item.name === payload.name && item.card_id === payload.card_id);
            state.cards_iframes.splice(index, 1);
        }
        state.cards_iframes.push({
            card_id: payload.card_id,
            url: payload.url,
            name: payload.name,
            caller: payload.caller,
            timestamp: Date.now()
        });
    },
    SET_IFRAME_URL(state, data) {
        if (state.iframeUrl.findIndex(item => item.type === data.type) > -1) {
            let index = state.iframeUrl.findIndex(item => item.type === data.type);
            state.iframeUrl.splice(index, 1);
        }
        state.iframeUrl.push({
            type: data.type,
            url: data.url
        });
    },
    TOGGLE_LOADER(state) {
        state.loading = !state.loading;
    },
    SET_LOADER(state, isLoading) {
        state.loading = isLoading;
    },
    // SET_CARD_BUTTON_REQUEST_CLICKED(state, cardType) {
    //     state.last_card_button_clicked = cardType;
    // },
    TOGGLE_CUSTOMER_STATUS_LOADER(state, value) {
        state.customer_status_loading = value;
    },
    ADD_CARD_REQUEST(state, payload) {
        state.cardRequests.push(payload);
    },
    SET_CARD_REQUESTS(state, payload) {
        state.cardRequests = payload;
    },
    SET_RECORDED(state, payload) {
        state.isRecorded = payload;
    },
    CARD_REQUEST(state, status) {
        state.card_request = status;//0 not requested; 1 requested; 2 accepted
    },
    CARD_REQUEST_TYPE(state, type) {
        state.card_request_type = type;//virtual physical
    },
    CARD_REQUEST_ERROR(state, error) {
        state.card_request_error = error;
    },
    CUSTOMER_STATUS_ERROR(state, error) {
        state.customer_status_error = error;
    },
    CUSTOMER_STATUS(state, error) {
        state.customer_status = error;
    },
    SET_CARD_LIVE_DATA(state, payload) { // {service:'unlimint', type:'physical', current_balance: 245.00, available_balance:146.00}
        let service_data_selected = state.cards_live_data.findIndex(item => item.service === payload.service && item.id === payload.id && item.type === payload.type);
        if (service_data_selected !== -1) {
            let service_data = state.cards_live_data.find(item => item.service === payload.service && item.id === payload.id && item.type === payload.type);
            let data = {
                ...service_data,
                current_balance: payload.current_balance,
                available_balance: payload.available_balance
            };
            state.cards_live_data.splice(service_data_selected, 1, data);

        } else {
            const temp = {
                service: payload.service,
                type: payload.type,
                current_balance: 0,
                available_balance: 0,
                transactions: {}
            };
            const data = {
                ...temp,
                ...payload,
            };
            state.cards_live_data.push(data);
        }
    },
    //payload - transactions array
    SET_CARD_LIVE_DATA_TRANSACTIONS(state, payload) {
        let service_data_selected = state.cards_live_data.findIndex(item => item.service === payload.service && item.id === payload.id && item.type === payload.type);
        if (service_data_selected !== -1) {
            let service_data = state.cards_live_data.find(item => item.service === payload.service && item.id === payload.id && item.type === payload.type);
            let data = {
                ...service_data,
                transactions: payload.transactions
            }
            state.cards_live_data.splice(service_data_selected, 1, data);
        } else {
            const temp = {
                service: payload.service,
                type: payload.type,
                transactions: {}
            };
            const data = {
                ...temp,
                ...payload,
            };
            state.cards_live_data.push(data);
        }
    },
    SET_CARD_EXPENSE_REPORT_DATA(state, payload) {
        let service_data_selected = state.cards_live_expenses.findIndex(item => item.service === payload.service && item.type === payload.type);
        if (service_data_selected !== -1) {
            let service_data = state.cards_live_expenses.find(item => item.service === payload.service && item.type === payload.type);
            let data = {
                ...service_data,
                expenses: payload.expenses
            }
            state.cards_live_expenses.splice(service_data_selected, 1, data);
        }
    },
    SET_CARD_MONTHLY_TRANSACTIONS(state, payload) {
        let data = {};
        let service_data_selected = state.cards_monthly_transactions.findIndex(item => item.service === payload.service && item.type === payload.type && item.start_date === payload.start_date && item.end_date === payload.end_date);
        if (service_data_selected !== -1) {
            let service_data = state.cards_monthly_transactions.find(item => item.service === payload.service && item.type === payload.type && item.start_date === payload.start_date && item.end_date === payload.end_date);

            data = {
                ...service_data,
                year: getYear(payload.start_date),
                month: getMonth(payload.start_date),
                start_date: payload.start_date,
                end_date: payload.end_date,
                total: payload.data.total,
                expenses: payload.data.expenses,
                business_expenses: payload.data.business_expenses,
                transactions: payload.data.transactions,
                inverse_transactions: payload.data.inverse_transactions
            }
            state.cards_monthly_transactions.splice(service_data_selected, 1, data);
        } else {

            data = {
                id: state.cards_monthly_transactions.length + 1,
                service: payload.service,
                type: payload.type,
                year: getYear(payload.start_date),
                month: getMonth(payload.start_date),
                start_date: payload.start_date,
                end_date: payload.end_date,
                total: payload.data.total,
                expenses: payload.data.expenses,
                business_expenses: payload.data.business_expenses,
                transactions: payload.data.transactions,
                inverse_transactions: payload.data.inverse_transactions
            }
            state.cards_monthly_transactions.push(data);
        }
    },
    ADD_NATIVE_CARD_REQUEST(state, payload) {
        state.nativeCardRequests.push(payload);
    },
    SET_NATIVE_CARD_REQUESTS(state, payload) {
        state.nativeCardRequests = payload;
    },
    SET_NATIVE_CARD_REQUESTS_OTHER_ORG(state, payload) {
        state.nativeCardRequestsOtherOrg = payload;
    },
    SET_NATIVE_CARDS(state, payload) {
        state.nativeCards = payload;
    },
    SET_NATIVE_CARDS_OTHER_ORG(state, payload) {
        state.nativeCardsOtherOrg = payload;
    },
    SET_NATIVE_CARD_AVAILABLE_ORG_IDS(state, payload) {
        state.nativeCardAvailableOrgIds = payload;
    },
    SHOW_INFO_MODAL(state, payload) {
        state.infoModalVisible = true;
        state.infoModalData = payload;
    },
    HIDE_INFO_MODAL(state) {
        state.infoModalVisible = false;
    },
    SHOW_BALANCE_LOADER(state, loaderVisible) {
        state.showBalanceLoader = loaderVisible;
    },
    SHOW_CONFIRM(state, payload) {
        state.showConfirm = payload;
    },
    SET_STORED_LOADER(state, payload) {
        state.storedLoading = payload;
    },
    SET_STORED_CARDS(state, payload) {
        state.storedCards = payload;
    },
}

// actions
const actions = {
    /**
     * @param payload - { service, id, type }
     * - {String} service - 'unlimint','modulr','paynetics','junction
     * - {int||String} id - card id
     * - {String} type - 'virtual','physical','soloEntity'
    */
    getBalance({ commit, dispatch }, { service, id, type = 'physical' }) {
        if (!serviceExists(service) || isServiceEquals(service)) {
            return;
        }
        if (typeof id === 'undefined') {
            return;
        }
        commit("SHOW_BALANCE_LOADER", true);
        return axios
            .get(`/cards/${service}/${id}/balance`)
            .then(response => {
                if (service === 'unlimint') {
                    commit("SET_CARD_LIVE_DATA", { service: service, id: id, type: type, current_balance: response.data.balance.amount, available_balance: response.data.balance.amount });
                } else {
                    commit("SET_CARD_LIVE_DATA", { service: service, id: id, type: type, current_balance: response.data.current_balance, available_balance: response.data.available_balance });
                }
                commit("SHOW_BALANCE_LOADER", false);
                return response.data;
            })
            .catch(error => {
                let msg = error.response?.data?.message ? error.response.data.message : error.message;
                commit('setAlert', { type: 'error', message: msg }, { root: true });
                commit("SHOW_BALANCE_LOADER", false);
                throw error;
            });
    },
    /* getBalance({ commit, state, dispatch }) {
        commit("SET_CARD_LIVE_DATA", { service: 'unlimint', type: 'physical', current_balance: 245.00, available_balance: 146.00 });
        commit("SET_CARD_LIVE_DATA", { service: 'unlimint', type: 'virtual', current_balance: 15.10, available_balance: 6.30 });
        commit("SET_CARD_LIVE_DATA", { service: 'modulr', type: 'physical', current_balance: 11.01, available_balance: 11.30 });
        commit("SET_CARD_LIVE_DATA", { service: 'paynetics', type: 'soloEntity', current_balance: 8.30, available_balance: 2.33 });
        commit("SET_CARD_LIVE_DATA", { service: 'junction', type: 'soloEntity', current_balance: 7.60, available_balance: 1.40 });
    }, */
    getDetails({ commit, dispatch }, { service, id }) {
        return axios
            .get(`/cards/${service}/${id}/details`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                commit('setAlert', { type: 'error', message: error.message }, { root: true });
                throw error;
            });
    },
    /**
     * @param payload - { service, id, card_type, date_from, date_to, statuses, search, page, size }
     * - {String} service - 'unlimint','modulr','paynetics','junction
     * - {int||String} id - card id
     * - {String} card_type - 'virtual','physical','soloEntity'
     * - {String} date_from - date text
     * - {String} date_to - date text
     * - {String} statuses - search statuses
     * - {String} search - search text
     * - {String} page - current page
     * - {String} size - per page
     */
    async getQueryTransactions({ commit, state, dispatch }, payload) {
        if (!serviceExists(payload.service) || typeof payload.id === 'undefined') {
            return Promise.reject('Service does not exist or ID is undefined');
        }

        let page = payload.page || 1;
        let size = payload.size || 10;
        let stats = '';
        if (Array.isArray(payload.statuses)) {
            for (let status of payload.statuses) {
                stats += `&status[]=${status}`;
            }
        }

        let types = '';
        if (Array.isArray(payload.types)) {
            for (let type of payload.types) {
                types += `&type[]=${type}`;
            }
        }

        let expenseType = payload.expenseType || 'all';
        let query = `page=${page}&size=${size}${payload.date_from ? '&date_from=' + payload.date_from : ''}${payload.date_to ? '&date_to=' + payload.date_to : ''}&expenses=${expenseType}${payload.search ? '&search=' + payload.search : ''}${payload.outgoing ? '&outgoing=' + payload.outgoing : ''}${stats}${types}`;

        try {
            const response = await axios.get(`/cards/${payload.service}/${payload.id}/transactions?${query}`);
            let data = response.data.data.filter(item => {
                // Convert createdAt to Date object for comparison
                const itemDate = parseISO(item.createdAt);

                // Check if both dates are provided
                if (payload.date_from && payload.date_to) {
                  const startDate = parseISO(payload.date_from);
                  const endDate = parseISO(payload.date_to);
                  return isWithinInterval(itemDate, { start: startDate, end: endDate });
                }
                // Check if only date_from is provided
                else if (payload.date_from) {
                  const startDate = parseISO(payload.date_from);
                  // Include the day itself in comparison
                  return isAfter(itemDate, startDate) || format(itemDate, 'yyyy-MM-dd') === payload.date_from;
                }
                // If no date filtering is required, include all items
                return true;
              }).map(item => {
                // Perform any transformations needed on each item
                if (item.expenses) {
                  item.expenseType = item.expenses.type;
                }
                // Format the date for display
                item.date = format(parseISO(item.createdAt), 'yyyy-MM-dd');
                return item;
              });
            /* let data = response.data.data.map(item => {
                if (item.expenses) {
                    item.expenseType = item.expenses.type;
                }
                item.date = format(parseISO(item.createdAt), 'yyyy-MM-dd');
                // Check if both dates are provided
                if (payload.date_from && payload.date_to) {
                    const startDate = parseISO(payload.date_from);
                    const endDate = parseISO(payload.date_to);
                    return isWithinInterval(item.date, { start: startDate, end: endDate });
                }
                // Check if only date_from is provided
                else if (payload.date_from) {
                    const startDate = parseISO(payload.date_from);
                    return isAfter(item.date, startDate) || format(item.date, 'yyyy-MM-dd') === payload.date_from;
                }
                return item;
            }); */

            response.data = {
                ...response.data,
                data: data
            };
            commit("SET_CARD_LIVE_DATA_TRANSACTIONS", { service: payload.service, id: payload.id, type: payload.card_type, transactions: response.data });
            return response.data;
        } catch (error) {
            let msg = error.response?.data?.message ? error.response.data.message : error.message;
            commit('setAlert', { type: 'error', message: msg }, { root: true });
            throw error;
        }
    },
    async createTransaction({ commit, dispatch }, payload) {//cardTransaction details with updated expensesType
        return await axios.post(`/card-spend/add-expense-request/${payload.service}/card/${payload.id}`, payload.data);
    },
    async markTransactionMultiple({state, commit, rootState},payload) {
        return await axios.post(`${rootState.path}card-spend/add-expense-request-multiple/${payload.card.api_type}/card/${payload.card.id}`, payload.items)
            .then(response => {
            })
            .catch(error => {
                commit("setAlert", {type: 'error', message: error.response.data.message},{root: true});
            });
    },
    async addExpenseRequestToMultipleTransactions({state, commit, rootState}, payload) {
        return await axios.post(`${rootState.path}card-spend/add-expense-request-multiple/${payload.service}/card/${payload.id}`, payload.data)
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.response.data.message}, { root: true });
            });
    },
    async updateTransaction({ commit, dispatch }, payload) {//cardTransaction details with updated expensesType
        return await axios.post(`/card-spend/edit-expense-request/${payload.service}/card/${payload.id}/transaction/${payload.card_transaction_id}`, payload.data);
    },
    fundCard({ commit, dispatch }, payload) {
        return axios.post(`/cards/${payload.service}/${payload.id}/fund`, payload.data)
    },
    terminateEmail({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(`/cards/${payload.service}/${payload.id}/email-terminate`)
            .then(response => {
                dispatch('showDeactivateRequestSuccessful');
                resolve(response.data);
            })
            .catch(error => {
                commit("setAlert", {type: 'error', message: error.response.data.message}, { root: true });
            })
        });
    },
    async terminateCard({ commit, dispatch }, payload) {
        return await axios.patch(`/cards/${payload.service}/${payload.id}/terminate`)
            .then(response => {
                dispatch('showDeactivateRequestSuccessful');
                if (response?.data?.card) {
                    dispatch('cardModule/addOrUpdateUnlCard', response.data.card, { root: true });
                }
            })
            .catch(error => {
                if (error.response?.data?.error) {
                    commit('setAlert', { type: 'error', message: error.response.data.error }, { root: true });
                }
            });
    },
    getTransactions({ commit, dispatch }, { service, id, page, size }) {
        if (!serviceExists(service)) {
            return;
        }
        if (typeof id === 'undefined') {
            return;
        }
        page = page ? page : 1;
        size = size ? size : 10;
        return axios
            .get(`/cards/${service}/${id}/transactions?page=${page}&size=${size}`)
            .then(response => {
                console.log(response.data)
                return response.data;
            })
            .catch(error => {
                commit('setAlert', { type: 'error', message: error.message }, { root: true });
                throw error;
            });
    },
    async getCardExpenseReportsData({ commit, dispatch }, payload) {
        if (!serviceExists(payload.service)) {
            return;
        }
        if (typeof payload.id === 'undefined') {
            return;
        }
        let url = `/card-statement/business-expenses-new/${payload.service}/card/${payload.id}`;
        if (payload.start_date) {
            url = `/card-statement/business-expenses-new/${payload.service}/card/${payload.id}?start_date=${payload.start_date}`;
        }
        if (payload.end_date) {
            url = `/card-statement/business-expenses-new/${payload.service}/card/${payload.id}?start_date=${payload.start_date}&end_date=${payload.end_date}`;
        }

        return await axios.get(url)
            .then(response => {
                commit("SET_CARD_EXPENSE_REPORT_DATA", { service: payload.service, type: payload.card_type, expenses: response.data });
                return response.data;
            })
            .catch(error => {
                let msg = error.response?.data?.message ? error.response.data.message : error.message;
                commit('setAlert', { type: 'error', message: msg }, { root: true });
                throw error;
            });
    },
    getCardTransactionsMonthlyOverview({ commit, dispatch }, payload) {
        let baseUrl = `/card-spend/filter-by-type-and-sum-with-transactions/${payload.service}/card/${payload.id}`;
        let params = [];

        if (payload.start_date) {
            params.push(`date_from=${encodeURIComponent(payload.start_date)}`);
        }

        if (payload.end_date) {
            params.push(`date_to=${encodeURIComponent(payload.end_date)}`);
        }

        if (payload.transaction_type) {
            if (Array.isArray(payload.transaction_type)) {
                params = params.concat(payload.transaction_type.map(item => `transaction_type[]=${encodeURIComponent(item)}`));
            } else {
                params.push(`transaction_type=${encodeURIComponent(payload.transaction_type)}`);
            }
        }

        let url = baseUrl + (params.length ? '?' + params.join('&') : '');

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(response => {
                    commit("SET_CARD_MONTHLY_TRANSACTIONS", { service: payload.service, type: payload.card_type, transaction_type: payload.transaction_type, start_date: payload.start_date, end_date: payload.end_date, data: response.data });
                    resolve(response.data);
                })
                .catch(error => {
                    let msg = error.response?.data?.message ? error.response.data.message : error.message;
                    commit('setAlert', { type: 'error', message: msg }, { root: true });
                    reject(error);  // Reject promise with caught error
                });
        });
    },
    verifyPin({ commit, dispatch }, { service, id, pin }) {
        return axios
            .post(`/cards/${service}/${id}/verify-pin`, { pin })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                commit('setAlert', { type: 'error', message: error.message }, { root: true });
                throw error;
            });
    },
    updatePin({ commit, dispatch }, { service, id, oldPin, newPin }) {
        return axios
            .post(`/cards/${service}/${id}/pin`, { oldPin, newPin })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                commit('setAlert', { type: 'error', message: error.message }, { root: true });
                throw error;
            });
    },
    update3ds({ commit, dispatch }, { service, id, question, answer }) {
        return axios
            .post(`/cards/${service}/${id}/3ds`, { question, answer })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                commit('setAlert', { type: 'error', message: error.message }, { root: true });
                throw error;
            });
    },
    remove({ commit, dispatch }, { service, id }) {
        return axios
            .delete(`/cards/${service}/${id}`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                commit('setAlert', { type: 'error', message: error.message }, { root: true });
                throw error;
            });
    },
    async create({ commit, dispatch, rootState }, formData) {
        formData = {
            ...formData,
            first_name: formData.firstName,
            last_name: formData.lastName,
            birthday: formData.dateOfBirth,
            first_address: formData.firstAddress,
            post_code: formData.postCode,
        };

        delete formData['firstName'];
        delete formData['lastName'];
        delete formData['dateOfBirth'];
        delete formData['postCode'];
        delete formData['firstAddress'];

        commit("CARD_REQUEST_ERROR", false);
        commit("CARD_REQUEST", 0);
        commit("CARD_REQUEST_TYPE", formData['card_type'] ?? 'physical');
        commit('SET_LOADER', true);
        if ('native' === formData.service) {
            const nativeCard = new NativeCardRequest({
                name: formData.name ?? [formData.first_name, formData.last_name].join(' '),
                gender: formData.gender === 'M' ? 1 : (formData.gender === 'F' ? 2 : 3),
                first_address: formData.first_address,
                second_address: formData.secondAddress,
                country_id: formData.country_id,
                currency_id: formData.currency_id,
                organization_id: formData.organization_id,
                city: formData.city,
                post_code: formData.post_code,
                phone: formData.phone,
                birthday: formData.birthday,
            });
            /* rootState.path +  */
            return nativeCard.create('/request-native-card')
                .then(response => {
                    commit("CARD_REQUEST", 1);
                    if (response.data.card) {
                        commit('ADD_NATIVE_CARD_REQUEST', response.data.card);
                        dispatch('cardModule/addNativeCardRequest', response.data.card);
                    }
                })
                .catch(error => {
                    let msg = error.response?.data?.message ? error.response.data.message : error.message;
                    commit("CARD_REQUEST", 0);
                    commit("CARD_REQUEST_ERROR", msg);
                    //commit('setAlert', { type: 'error', message: msg }, { root: true });
                    throw error;
                })
                .finally(() => commit('SET_LOADER', false));
        }
        return await axios
            .post(`/cards/${formData.service}/requests`, formData)
            .then(response => {
                commit("SET_LOADER", false);
                commit("CARD_REQUEST", 1);
                if (response.data.card_request) {
                    commit("ADD_CARD_REQUEST", response.data.card_request);
                    if (formData.service === 'unlimint') {
                        dispatch('cardModule/addUnlimintCardRequest', response.data.card_request, { root: true });
                    }
                }
                //commit('setAlert', { type: 'info', message: 'Card request created successfully' }, { root: true });
                commit("SET_RECORDED", true);
            })
            .catch(error => {
                let msg = error.response?.data?.message ? error.response.data.message : error.message;
                commit("SET_LOADER", false);
                commit("CARD_REQUEST", 0);
                commit("CARD_REQUEST_ERROR", msg);
                //commit('setAlert', { type: 'error', message: msg }, { root: true });
                throw error;
            });
    },

    //organization_id , card_type, service
    async checkCustomerStatus({ state, commit, dispatch }, formData) {
        if (!state.customer_status && !state.customer_status_loading){
            commit("CUSTOMER_STATUS_ERROR", false);
            commit("TOGGLE_CUSTOMER_STATUS_LOADER", true);
            return await axios
                .get(`/cards/${formData.service}/check-customer-status-for-request`, { params: formData })
                .then(response => {
                    commit("TOGGLE_CUSTOMER_STATUS_LOADER", false);
                    if (response.data) {
                        commit("CUSTOMER_STATUS", response.data);
                    }
                })
                .catch(error => {
                    let msg = error.response?.data?.message ? error.response.data.message : error.message;
                    commit("CUSTOMER_STATUS_ERROR", msg);
                    commit("TOGGLE_CUSTOMER_STATUS_LOADER", false);
                    //commit('setAlert', { type: 'error', message: msg }, { root: true });
                    throw error;
                });
        }
    },
    async checkCustomerStatusFromAdmin({ commit, dispatch }, formData) {
        commit("TOGGLE_CUSTOMER_STATUS_LOADER", true);
        commit("CUSTOMER_STATUS_ERROR", false);
        return await axios
            .get(`/admin/unlimint/cards/${formData.service}/check-customer-status-for-request`, { params: formData })
            .then(response => {
                if (response.data) {
                    commit("CUSTOMER_STATUS", response.data);
                }
                return response;
            })
            .catch(error => {
                let msg = error.response?.data?.message ? error.response.data.message : error.message;
                commit("CUSTOMER_STATUS_ERROR", msg);
                //commit('setAlert', { type: 'error', message: msg }, { root: true });
                throw error;
            }).finally(() => {
                commit("TOGGLE_CUSTOMER_STATUS_LOADER", false);
            });
    },
    //test
    /* create({ commit, dispatch }, { service, formData }) {
        commit("TOGGLE_LOADER");
        //if (response.data.xxxx === xxxx) {
        commit("CARD_REQUEST", 1);
        //}
    }, */
    toggleLoaderState({ commit }) {
        commit("TOGGLE_LOADER");
    },
    setLoaderState({ commit }, isLoading) {
        commit("SET_LOADER", isLoading);
    },
    setCardIframe({ commit }, payload) {
        commit("SET_CARD_IFRAME", payload);
    },
    setCardButtonRequestClicked({ commit }, payload) {
        commit("SET_CARD_BUTTON_REQUEST_CLICKED", payload);
    },
    setActivationCardIFrameRequested({ commit }, payload) {
        commit("SET_REQUEST_ACTIVATION_IFRAME", payload);
    },
    setConfirm({ commit }, payload) {
        commit("SHOW_CONFIRM", payload);
    },
    async getIFrame({ commit }, { service, id, type }) {
        return new Promise((resolve, reject) => {
          axios
            .get(`/cards/${service}/${id}/iframe/${type}`)
            .then(response => {
                commit('SET_IFRAME_URL', {
                    url: response.data.url,
                    type: type
                });
                resolve(response);
            })
            .catch(error => {
                commit('setAlert', { type: 'error', message: error.message }, { root: true });
                reject(error);
                throw error;
            });
        });
    },

    async markArrivedCardRequest({ commit, rootState}, { service, id }) {
        if ('native' === service) {
            return axios.get(rootState.path + 'request-native-card-confirmed').catch(error => {
                commit('setAlert', { type: 'error', message: error.message }, { root: true });
                throw error;
            });
        }
        return axios
            .post(`/cards/${service}/card-arrived/${id}`, { _method: 'PUT' })
            .catch(error => {
                commit('setAlert', { type: 'error', message: error.message }, { root: true });
                throw error;
            });
    },

    async getNativeCardRequests({ commit }) {
        return await axios
            .get('/native-card-requests')
            .then(response => {
                commit('SET_NATIVE_CARD_REQUESTS', response.data.requests);
                commit('SET_NATIVE_CARDS', response.data.cards);
            })
            .catch(error => {
                console.log(error.message);
                throw error;
            });
    },

    async getNativeCardRequestsByOrg({ commit }, orgId) {
        return await axios
            .get(`/native-card-requests-by-org/${orgId}`)
            .then(response => {
                commit('SET_NATIVE_CARD_REQUESTS_OTHER_ORG', response.data.requests);
                commit('SET_NATIVE_CARDS_OTHER_ORG', response.data.cards);
            })
            .catch(error => {
                console.log(error.message);
                throw error;
            });
    },

    async getCardRequests({ commit, dispatch }, service) {
        if (!serviceExists(service) || isServiceEquals(service)) {
            return;
        }
        return await axios
            .get(`/cards/${service}/requests`)
            .then(response => {
                commit('SET_CARD_REQUESTS', response.data);
                dispatch('cardModule/setUnlimintCardRequests', response.data, { root: true });
            })
            .catch(error => {
                console.log(error.message);
                throw error;
            });
    },

    async getNativeCardAvailableOrgs({ commit }) {
        return await axios
            .get('/native-card-available-orgs')
            .then(response => {
                commit('SET_NATIVE_CARD_AVAILABLE_ORG_IDS', response.data);
            })
            .catch(error => {
                console.log(error.message);
                throw error;
            });
    },

    async getCurrencyCards({ commit }) {
        return await axios
            .get(`/cards/currency-cards`)
            .then(response => {
                commit('setData', {key: 'currencyCards', data: response.data}, { root: true });
            })
            .catch(error => {
                console.log(error.message);
                throw error;
            });
    },

    showDeactivateRequestSuccessful({ commit }) {
        commit('SHOW_INFO_MODAL', {
            icon: {
                name: 'check-circle-outline',
                class: 'color-green',
            },
            title: 'Request sent',
            text: 'Your request to deactivate this card has been successful.',
            footerBtnText: 'Close',
        });
    },

    closeInfoModal({ commit }) {
        commit('HIDE_INFO_MODAL');
    },

    async getStoredRequests({ commit, dispatch }, service) {
        commit('SET_STORED_LOADER', true);
        return await axios
            .get(`/cards/${service}/requests/stored`)
            .then(response => {
                commit('SET_STORED_CARDS', response.data);
                dispatch('cardModule/setStoredCardRequests', response.data, { root: true });
                return response.data;
            })
            .catch(error => {
                commit('setAlert', { type: 'error', message: error.message }, { root: true });
                throw error;
            })
            .finally(() => {
                commit('SET_STORED_LOADER', false);
            });
    },
}

function getYear(dateString) {
    return new Date(dateString).getFullYear();
}
function getMonth(dateString) {
    return new Date(dateString).getMonth() + 1; // getMonth() returns months as 0-11.
}

function serviceExists(service) {
    return state.cardServices.includes(service);
}

function isServiceEquals(service){
    return service === 'native'
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
