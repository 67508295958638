<template>
    <v-btn

        :target="target"
        :disabled="disabled"
        :outlined="btnOutlined"
        :small="small"
        :href="href"
        :loading="loading"
        class="tm-btn"
        :style="[
            {'min-width': minWidth? isNaN(minWidth)? minWidth: minWidth+'px': btnText? 0: null},
            {height: height? isNaN(height)? height: height+'px': small? '28px': null}
            ]"
        :class="[
            disabled? null: !btnOutlined && !btnText && !isHexColor? 'tm-bg-'+color: null,
            btnText? 'px-0': null,
            ]"
        :color="disabled? null: isHexColor? color: null"
        :text="btnText"
        :icon="!!fab"
        :depressed="noBoxShadow"
        @click.stop="onClick"
    >
        <div v-if="fab" :style="isHexColor? {'background-color': color}:{}" :class="!isHexColor? 'tm-bg-'+color: null" class="d-flex justify-center align-center" style="border-radius: 50%; width: 30px; height: 30px">
            <icon :color="textColor" size="16" :name="fab"></icon>
        </div>
        <div
            v-else
            :style="btnText || btnOutlined? isHexColor? {color: color}:{}: checkHex(textColor)? {color: textColor}: {}"
            :class="[
                btnText || btnOutlined? !isHexColor? 'tm-color-'+color:null: !checkHex(textColor)? 'tm-color-'+textColor: null,
                !textTransform? 'tm-transform-none': null
                ]"
            class="tm-small-text d-flex align-center"
        >
            <icon v-if="icon" :name="icon" size="null" color="null" class="mr-2"></icon>
            <span class="tm-initial" v-html="__sanitizeHtml(text)"></span>
            <slot name="default"></slot>
        </div>
    </v-btn>
</template>
<script>
import Confirmation from '~/shared/mixins/Confirmation';
import Helpers from '~/shared/mixins/Helpers';
export default {
    name: 'btn',
    props: {
        color: {
            default: 'purple'
        },
        loading: {
            type: Boolean,
            default: false
        },
        text: {
            default: null
        },
        icon: {
            default: null
        },
        href: {
            default: null
        },
        target: {
            default: null
        },
        small: {
            default: false
        },
        btnText: {
            default: false
        },
        btnOutlined: {
            default: false
        },
        noBoxShadow: {
            type: Boolean,
            default: false,
        },
        fab: {
            default: null
        },
        height: {
            default: null
        },
        textColor: {
            default: 'white'
        },
        textTransform: {
            type: Boolean,
            default: true
        },
        disabled:{
            default: false
        },
        minWidth: {
            default: null
        }
    },
    mixins: [Confirmation, Helpers],
    computed: {
        isHexColor () {
            return this.checkHex(this.color)
        },
    },
    methods: {
        checkHex(color){
            if(!color || typeof color !== 'string') return false;

            // Validate hex values
            if(color.substring(0, 1) === '#') color = color.substring(1);

            switch(color.length) {
                case 3: return /^[0-9A-F]{3}$/i.test(color);
                case 6: return /^[0-9A-F]{6}$/i.test(color);
                case 8: return /^[0-9A-F]{8}$/i.test(color);
                default: return false;
            }
        },
        onClick() {
            this.$emit('click');
            this.confirm();
        }
    }
}
</script>
<style scoped>
.v-btn:before{
    transition: none!important;
}
</style>
