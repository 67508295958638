import {__config} from "@/utils/config";

export function __initResource(resources, name) {
    let resource = resources.data? resources.data.filter(resource => resource.type === name): [];
    if (!resource.length)
        return null;
    return resource[0].items;
}

export function __changeRoute(router, url, force){
    const protocol = ['http://', 'https://', 'ftp://'].some(p => url.startsWith(p));
    const host = window.location.host;

    if (force || (protocol && !url.includes(host))){
        window.location.href = url;
        return;
    }
    if (url === window.location.href) {
        return;
    }

    let path = protocol?
        url.replace(/(^\w+:|^)\/\//, '').replace(host, ''):
        url.replace(/(^\w+:|^)\/\//, '');
    path = !path || path[0] !== '/'? '/' + path: path;

    router.replace(path).then(() => {});
}

export function __windowDownload(name, url){
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
}

export function __getStreamFilename(response, url){
    const disposition = response.headers['Content-Disposition'] || response.headers['content-disposition'];
    let filename = null;
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }else{
        console.error('Content-Disposition and Content-Type is not set up!')
        filename = url.split('/').join(' ');
    }
    return filename;
}

export function __downloadStream(url, params = {}, method = 'GET'){
    const options = {
        method: method,
        url: url,
        params: params,
        responseType: "blob"
    };
    return axios.request(options)
        .then(response => {
            const fileName = __getStreamFilename(response, url);
            const fileUrl = URL.createObjectURL(response.data);
            __windowDownload(fileName, fileUrl)
        });
}

export function __querySerialize(obj){
    const str = [];
    for (const p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

export function __makeId(length = 16) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const firstCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    for (let i = 0; i < length; i++) {
        const chars = i ? characters : firstCharacters;
        const length = chars.length;
        result += chars.charAt(Math.floor(Math.random() * length));
    }
    return result;
}

export function __event(type, data = null){
    const event = new CustomEvent('__core', { detail: {type, data} });
    window.document.dispatchEvent(event);
}

export function __setRecaptchaToken(callback,action = 'submit'){
    return new Promise((resolve, reject) => {
        if (__config('VUE_APP_RECAPTCHA_ENABLED')) {
            grecaptcha.ready(() => {
                grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {action})
                    .then(token => {
                        window.axios.defaults.headers.common['Recaptcha-Token'] = token;
                        callback();
                        resolve();
                    })
                    .catch(() => {
                        reject(new Error('Recaptcha issue'));
                    });
            });
        } else {
            callback();
            resolve();
        }
    });
}
