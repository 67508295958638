<template>
    <div>
        <modal v-model="dialog" :width="width" close-color="#8F8F8F" :class-names="`atm-upgrade-modal ` + classNames">
            <template slot="content">
                <div class=" withdraw-thank-you-modal center modal-sm-2 ">
                    <div class="modal-content">
                        <div class="modal-body d-flex flex-column align-items-center ">
                            <template v-if="contentModal === 'atm'">
                                <img :src="$store.state.imagePath+'nt-card-details.png'" alt="rocket.svg" class="pt-40 pb-20">
                                <h3 class="heading-onboarding-2 mb-9 color-purple text-center w-380"> Please upgrade your account to order an ATM card</h3>
                            </template>
                            <template v-else-if="contentModal === 'iban'">
                                <img :src="$store.state.wlstyle.st_logo_mon" alt="logo_small" class="iban-image mb-5">
                                <h1 class="heading-2 bold-text color-dark-blue text-center mb-10 mt-5">
                                    Upgrade to a paid account <br>
                                    to retrieve your IBAN
                                </h1>

                                <p class="paragraph color-dark-blue text-center">
                                    Any payments sent to this IBAN will be shown in this wallet.
                                </p>
                            </template>
                            <template v-else-if="contentModal==='soldo'">
                                <img  src="/images/settings/soldo/soldo-card.svg"  alt="soldo-card.svg" class="soldo-card-img mb-30"/>
                                <h1  class="heading-1 bold-text text-center color-dark-blue mb-30">
                                    Please upgrade your account to order Soldo Card!
                                </h1>
                            </template>

                          <button @click="upgradeDialog=true" class="btn btn-purple medium-text bold-text color-white mt-10 mb-10 upgrade-atm">
                                Upgrade Now
                            </button>
                        </div>

                    </div>
                </div>
            </template>
        </modal>

        <upgrade-plan
            v-model="upgradeDialog"
            :plan="$store.state.allPlans.find(item=>item.value === 'essence')"
            :card-select="!$store.state.primaryWorker.subscription.stripe"
            :default-org="isClient"
        ></upgrade-plan>
    </div>
</template>

<script>
    import Modal from "~/shared/components/Modal";
    import Helpers from '~/shared/mixins/Helpers';
    import ErrorMessage from "~/shared/components/form/ErrorMessage";
    import UpgradePlan from "../../components/modals/UpgradePlan";
    export default {
        name: 'CardUpgrade',
        components: { ErrorMessage,  Modal, UpgradePlan},
        mixins: [Helpers],
        props: {
            width: {
                default: '27.4375rem',
            },
            classNames: {
                default: null
            },
            value: {
                required: true,
                type: Boolean
            },
            contentModal: {
                default: 'atm',
            }
        },
        data(){
            return {
                load: false,
                dialog: this.value,
                loading: false,
                upgradeDialog: false,
            }
        },
        computed: {

        },
        watch: {
            value(){
                this.dialog = this.value
            },
            dialog(){
                this.$emit('input', this.dialog);
            },
        },
        methods: {

        },
      created() {

      }
    }
</script>
