import axios from "axios";
import router from "~/routes";
import {__changeRoute} from "~/utils/functions";
import {__config} from "@/utils/config";

export const namespaced = true;

export const state = {
    disabled: false,
    channel: null,
    notifications: [],
    page: 1,
    loadingMore: false,
    canLoadMore: null,
    unviewedTicketsCount: 0,
    categoriesOptions: [
        {
            text: "Payments",
        },
        {
            text: "Employment",
        },
        {
            text: "Tax",
        },
        {
            text: "General",
        },
        {
            text: "Benefits",
        },
        {
            text: "People",
        },
        {
            text: "Tickets",
        },
        {
            text: "Support",
        },
        {
            text: "Documents",
        },
    ],
    filters: {
        search: "",
        categories: [],
    },
    viewedRequests: [],
    markAllAsReadLoading: false,
};

export const mutations = {
    MARK_ALL_AS_READ(state) {
        state.notifications.forEach((notification) => {
            notification.isRead = true;
        });
    },

    MARK_AS_READ(state, notificationId) {
        const notificationIndex = state.notifications.findIndex(
            (notification) => notification.id === notificationId
        );
        state.notifications[notificationIndex].isRead = true;
    },

    MARK_AS_ACCEPTED(state, notificationId) {
        const notificationIndex = state.notifications.findIndex(
            (notification) => notification.id === notificationId
        );
        state.notifications[notificationIndex].accepted = true;
    },

    SET_FILTERS(state, filters) {
        state.filters = filters;
    },

    SET_DATA(state, data) {
        data.forEach(i => {
            const index = state.notifications.findIndex(j => j.id === i.id);
            if (-1 !== index) {
                state.notifications[index] = {...i};
            } else {
                state.notifications.push(i);
            }
        });
        state.notifications.sort((a, b) => (b.id - a.id));
    },

    SET_PAGE(state, data) {
        state.page = data;
    },

    SET_LOADING_MORE(state, value) {
        state.loadingMore = value;
    },

    SET_CAN_LOAD_MORE(state, value) {
        state.canLoadMore = value;
    },

    SET_DATA_ITEM(state, data) {
        state.notifications.unshift(data);
    },

    SET_UNVIEWED_TICKETS_COUNT(state, count) {
        state.unviewedTicketsCount = count;
    },

    SET_DISABLED(state, value) {
        state.disabled = value;
    },

    ADD_VIEWED_REQUESTS(state, value) {
        const index = state.viewedRequests.indexOf(value);
        if (-1 === index) {
            state.viewedRequests.push(value);
        }
    },

    DELETE_VIEWED_REQUESTS(state, value) {
        const index = state.viewedRequests.indexOf(value);
        if (-1 !== index) {
            state.viewedRequests.splice(index, 1);
        }
    },

    SET_MARK_ALL_AS_READ_LOADING(state, value) {
        state.markAllAsReadLoading = value;
    },

    SET_CHANNEL(state, channel) {
        state.channel = channel;
    },
};

export const actions = {
    async markAllAsRead({commit, state, rootState}) {
        if (state.markAllAsReadLoading) {
            return;
        }
        commit("SET_MARK_ALL_AS_READ_LOADING", true);
        return axios
            .post(`/notifications/viewed`)
            .then(() => {
                commit("MARK_ALL_AS_READ");
            })
            .catch(error => {
                commit('setAlert', {
                    type: 'error',
                    message: error.response?.data?.message ? error.response.data.message : 'Oops! Something went wrong.'
                }, {root: true});
            })
            .finally(() => {
                commit("SET_MARK_ALL_AS_READ_LOADING", false);
            });
    },

    async markAsRead({commit, rootState, state}, notificationId) {
        if (state.viewedRequests.includes(notificationId) || state.markAllAsReadLoading || state.notifications.find(n => n.id === notificationId)?.isRead) {
            return;
        }
        commit('ADD_VIEWED_REQUESTS', notificationId);
        return axios
            .post(`/notifications/viewed/${notificationId}`)
            .then(() => {
                commit("MARK_AS_READ", notificationId);
            })
            .catch(error => {
                commit('setAlert', {
                    type: 'error',
                    message: error.response?.data?.message ? error.response.data.message : 'Oops! Something went wrong.'
                }, {root: true});
            })
            .finally(() => {
                commit('DELETE_VIEWED_REQUESTS', notificationId);
            });
    },

    async markAsAccepted({commit, rootState}, notificationId) {
        return axios
            .get(`/notifications/accepted/${notificationId}`)
            .then(() => {
                commit("MARK_AS_ACCEPTED", notificationId);
            })
            .catch(error => {
                commit('setAlert', {
                    type: 'error',
                    message: error.response?.data?.message ? error.response.data.message : 'Oops! Something went wrong.'
                }, {root: true});
            });
    },

    setFilters({commit}, filters) {
        commit("SET_FILTERS", filters);
    },

    async getData({commit, rootState, state}, params) {
        return axios
            .get(`/notifications`, {
                params: {
                    page: params?.page,
                },
            })
            .then(response => {
                const result = {...response.data};
                result.data = Array.isArray(result.data) ? result.data : Object.values(result.data);
                commit('SET_DATA', result.data);
                commit('SET_UNVIEWED_TICKETS_COUNT', result.unviewed_tickets_count);
                if (null === state.canLoadMore) {
                    commit('SET_CAN_LOAD_MORE', result.last_page >= state.page);
                }
                return result;
            })
            .catch(error => {
                if (401 !== error.response.status && error.toJSON().message !== 'Network Error') {
                    commit('setAlert', {
                        type: 'error',
                        message: error.response?.data?.message ? error.response.data.message : 'Oops! Something went wrong.'
                    }, {root: true});
                }
            });
    },

    async loadMore({dispatch, state, commit}) {
        const page = state.page + 1;
        commit('SET_LOADING_MORE', true);
        return dispatch('getData', {page}).then((result) => {
            commit('SET_PAGE', page);
            commit('SET_CAN_LOAD_MORE', result.last_page > state.page);
        }).finally(() => {
            commit('SET_LOADING_MORE', false);
        });
    },

    setDisabled({commit}, value) {
        commit('SET_DISABLED', value);
    },

    init({dispatch, commit, state}, worker) {
        if (!worker) {
            console.error('Invalid worker for notifications.');
            return;
        }
        const channel = `notification.${worker.id}`;
        if (channel === state.channel) {
            return;
        }
        if (state.channel) {
            window.Echo.leave(state.channel);
        }
        commit('SET_DATA', []);
        commit('SET_CHANNEL', channel);
        window.Echo.private(`notification.${worker.id}`)
            .listen(`NotificationEvent`, (e) => {
                if (!state.disabled) {
                    commit('SET_DATA_ITEM', e);
                }
            });
        const tick = () => {
            state.disabled ? Promise.resolve(state.disabled) : dispatch('getData');
        };
        tick();
    },

    goToRoute({dispatch, commit}, notification) {
        if (!notification) return;
        let route = null;
        if (notification.url) {
            __changeRoute(router, notification.url);
            commit('globalMenuModule/TOGGLE_NOTIFICATION_MENU', false, {root: true});
            return;
        }
        if (!notification.value) return;
        let url = null;
        switch (notification.value) {
            case 'notification_booking_payed':
            case 'notification_booking_approved':
            case 'notification_booking_declined':
            case 'notification_create_booking':
                route = 'hotdesk-booking?tab=nav-bookings';
                break;

            /*    case 'notification_booking_owner':
                  route = '';
                  break*/

            case 'holiday_request':
            case 'holiday_approved':
            case 'absence_remoter':
            case 'holiday_rejected':
                route = 'absence';
                break;

            /*  case 'invite_accepted':
                route = '';*/

            /*        case 'team_member_invited':
                      route = '';*/

            case 'native_card_accept':
            case 'card_accepted':
            case 'native_card_decline':
            case 'card_declined':
            case 'native_card_sent':
            case 'card_sent':
            case 'native_card_arrived':
                route = 'settings?tab=native_card';
                break;

            case 'soldo_card_accept':
            case 'soldo_card_decline':
            case 'soldo_card_sent':
            case 'soldo_card_arrived':
                route = 'settings?tab=soldo_card';
                break;

            case 'bank_transfer_accept':
            case 'bank_transfer_decline':
            case 'withdrawal_accept':
            case 'withdrawal_decline':
            case 'funds_request':
                route = 'wallet';
                break;

            case 'admin_canceled_note':
                url = notification.url;
                break;

            case 'case_created':
            case 'case_created_employee':
            case 'case_created_employer':
            case 'case_approved':
            case 'case_declined':
            case 'case_reminder':
            case 'case_approved_by_employer':
            case 'type_reassign_report_bug':
                route = 'tickets';
                break;

            case 'payroll_accept':
            case 'payroll_decline':
                route = 'payroll';
                break;

            case 'salary_request_sent':
                route = 'payment-request?page=1&search=&tab=to_pay';
                break;

            case 'salary_decline':
            case 'salary_accept':
                route = 'payment-request';
                break;

            case 'worker_termination':
                route = __config('VUE_APP_DISPLAY_OLD_MEMBERS_CONTACTS_PAGE') ? 'members' : 'people';
                break;

            /*case 'salary_change_approved':
              route = 'salary_change_approved';
              break;
          case 'salary_change_rejected':
              route = 'salary_change_rejected';
              break;*/

            case 'salary_ticket_created':
                route = 'tickets';
                break;
            case 'invoice_to_payer':
                route = 'invoices?tab=to_pay';
                break;
            case 'expense_request_sent':
                route = 'expenses';
                break;
            case 'share_document':
                route = 'documents';
                break;

            /*   case 'iban_updated':
                  route = 'iban_updated';
                  break;*/

            case 'new_people_section':
                route = 'people';
                break;

            /*     case 'allowance_request_processed':
                    route = '';
                    break;*/

            case 'monthly_breakdown_available':
                route = 'employee-payroll';
                break;

            default:
                route = null;
        }

        if (url) {
            __changeRoute(router, url);
            commit('globalMenuModule/TOGGLE_NOTIFICATION_MENU', false, {root: true});
            return;
        }
        if (route) {
            __changeRoute(router, `/${route}`);
            commit('globalMenuModule/TOGGLE_NOTIFICATION_MENU', false, {root: true});
        }
    },
};

export const getters = {
    getNotifications: (state) => {
        return state.notifications;
    },

    getLastNotifications: (state, getters) => {
        return getters.getNotifications.slice(0, 10);
    },

    getFilteredNotifications: (state, getters) => {
        return getters.getNotifications.filter((notification) => {
            let isCategoryMatch = true;

            if (state.filters.categories.length > 0) {
                isCategoryMatch = state.filters.categories.some((category) => {
                    return category === notification.category.text;
                });
            }

            const isSearchMatch = notification.message
                .toLowerCase()
                .includes(state.filters.search.toLowerCase());

            if (state.filters.categories.length > 0) {
                return isCategoryMatch && isSearchMatch;
            } else {
                return isSearchMatch;
            }
        });
    },

    getUnreadNotifications: (state, getters) => {
        return getters.getNotifications.filter((notification) => {
            return notification.isRead === false;
        });
    },

    getTotalUnreadNotifications: (state, getters) => {
        return getters.getNotifications.filter((notification) => {
            return notification.isRead === false;
        }).length;
    },

    getCategoriesOptions: (state) => {
        return state.categoriesOptions;
    },

    getUnviewedTicketsCount: (state) => {
        return state.unviewedTicketsCount;
    },

    loadingMore: state => state.loadingMore,

    canLoadMore: state => state.canLoadMore,

    hasUnreadNotifications: (state, getters) => {
        return getters.getTotalUnreadNotifications > 0;
    },
};
