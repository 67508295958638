<template>
    <div class="container-fluid pt-5 bg-white shadow-sm base-wizard-navbar-responsive">
        <div class="row justify-content-between default-row px-3">
            <div class="col-9 col-sm-6 col-md-4 order-1 align-self-center">
                <div class="row">
                    <div class="col-4 align-self-center pr-0">
                        <a href="#">
                            <img
                                :src="$store.state.wlstyle.st_logo_black"
                                alt="logo black"
                                class="w-100"
                            />
                        </a>
                    </div>
                    <div class="col align-self-center ml-2">
                        <p class="text-sm font-medium color-grey-2">{{ title }}</p>
                    </div>
                </div>
            </div>

        <div
            class="col-12 col-md-4 order-3 order-md-2 align-self-center position-relative responsive-wizard-steps-list-container">
            <ul class="wizard-steps-list self-serve-wizard-steps-list responsive-wizard-steps-list mt-0">
                <NavbarWizardStep
                    v-for="(step, index) in steps"
                    :key="index"
                    :step="step"
                    :number="index"
                    :currentStep="currentStep"
                    customStepTitleClasses="responsive-step-title"
                    customWizardStepNumberClasses="responsive-wizard-step-number"
                />
            </ul>
        </div>

        <div class="col-auto col-md order-2 order-md-3 align-self-center text-right">
            <div v-if="closeShow" class="wizard-navbar-action ml-auto">
                <template v-if="!$slots['default']">
                    <button v-if="closeButton === 'x-button'" @click="$emit('close')"
                            class="btn btn-icon btn-p-0 color-dark-grey ml-auto" type="button" aria-label="Close">
                        <i class="icon-times"></i>
                    </button>

                    <BaseButton v-else @click="$emit('close')" class="btn-big custom-btn-secondary">
                        Close
                    </BaseButton>
                </template>
                <slot v-else></slot>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import NavbarWizardStep from "~/shared/components/NavbarWizard/NavbarWizardStep";

export default {
    name: "BaseWizardNavbarResponsive",

    components: {
        NavbarWizardStep,
    },

    props: {
        steps: {
            type: Array,
            required: true,
        },
        currentStep: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            required: '',
        },
        closeButton: {
            type: String,
            default: '',
        },
        closeShow: {
            type: Boolean,
            default: true
        }
    },
};
</script>
