/** @format */

import _ from 'lodash';
import {format} from "date-fns";
import { transformUrlsToLinks, sanitizeHtml, cleanHtml } from '../utils/text-utils';
import {
    __changeRoute,
    __initResource,
    __makeId,
    __downloadStream
} from "~/utils/functions";
import {__config} from "@/utils/config";

export default {
    data() {
        return {
            __dataLoading: false,
            countriesWithContractDoc: ['AM', 'CA', 'CY', 'DK', 'FR', 'GE', 'GR', 'HR', 'MA', 'MK', 'MT', 'NL', 'NO', 'PE', 'PH', 'RS', 'SK', 'TR', 'GB', 'US', 'ZA', 'NZ', 'IL', 'KE'],
            countriesWithContractTemplate: ['AL', 'AM', 'AT', 'AZ', 'BA', 'BG', 'CA', 'CY', 'DK', 'FR', 'GB', 'GE', 'GR', 'HR', 'HU', 'KS', 'MA', 'ME', 'MK', 'MT', 'NL', 'NG', 'NO', 'PE', 'PH', 'PL', 'PT', 'RO', 'RS', 'RU', 'SI', 'SK', 'TR', 'UA', 'US', 'ZA', 'NZ', 'IL', 'KE'],
            validSepaIbans: ['AT', 'AD', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GI', 'GR', 'HU', 'IS', 'IE', 'IT', 'LV', 'LI', 'LT', 'LU', 'MT', 'MC', 'NL', 'NO', 'PL', 'PT', 'RO', 'SM', 'SK', 'SI', 'ES', 'SE', 'CH', 'GB'],
            countriesWithoutPayrollCalculator: ['AR', 'BD', 'BR', 'BY', 'CA', 'CO', 'DZ', 'EG', 'GB', 'HR', 'IE', 'IN', 'KS', 'MX', 'NP', 'NZ', 'PE', 'RS', 'SE', 'TR', 'US', 'VN', 'ZA'],
            minimumAmount: null,
            debounceTimers: {},
            defaultNtOrg: 1,
        };
    },
    methods: {

        __performCheckAndExecute({
                                     checkForVerifiedUser = true,
                                     checkForFreeUser = true,
                                     checkFor2faEnabled = true,
                                     verifyCallback = null,
                                     freeUserCallback = null,
                                     twoFactorCallback = null,
                                     topupCallback = null
                                 }) {
            // First check: Verify the user
            if (checkForVerifiedUser) {
                if (!this.isVerified) {
                    if (verifyCallback) {
                        verifyCallback();  // Trigger the KYC modal
                    }
                    return;  // Exit the function if user is not verified
                }
                // If the user is verified, continue with the next checks
            }

            // Second check: Is the user a free user?
            if (checkForFreeUser && this.__isFreeCurrent) {
                if (freeUserCallback) {
                    freeUserCallback();
                    return;  // Exit after handling free user case
                }
            }

            // Third check: Is two-factor authentication enabled?
            if (checkFor2faEnabled && !this.enable2fa) {
                if (twoFactorCallback) {
                    twoFactorCallback();
                    return;  // Exit after handling 2FA case
                }
            }

            // If none of the conditions are met, execute the topupCallback
            if (topupCallback) {
                topupCallback();  // Default action if no specific conditions are met
            }
        },
        __changeRoute(url, force){
            return __changeRoute(this.$router, url, force);
        },

        returnResourceByName(data, name) {
            return __initResource(data, name);
        },

        __checkNetwork(error, callback, message = null) {
            if (error.toJSON().message === 'Network Error') {
                this.$store.commit('networkError/show', {callback: callback, message: message});
                this.$store.commit('setNetWorkError', {errorValue: true})
            }
        },
        __isSepaIban(iban) {
            let ibanCode = iban.substr(0, 2);

            return this.validSepaIbans.find(i => i === ibanCode) || false;
        },
        __openSupportChat() {
            if (window.openSupportChat) {
                window.openSupportChat();
            }
        },
        keypressForNumber() {
            document.querySelectorAll('input[type="number"]').forEach(input => {
                input.addEventListener('keypress', function (e) {
                    if (e.key === 'e' || e.key === 'E'
                        || e.key === '-' || e.key === '+') e.preventDefault()
                });
                input.addEventListener('keydown', function (e) {
                    let keyCodes = [40,38]
                    if (keyCodes.includes(e.keyCode)) e.preventDefault()
                });
            })
        },
        keypressForLetter(e) {
            let regexForNumber = /^[0-9]+$/;
            let regexForChar = /[@$!%*?^+\-\{\}\[\]\(\)&#~`:;.'"=_]+/;
            let char = String.fromCharCode(e.keyCode);
            if (regexForNumber.test(char) || regexForChar.test(e.key)) e.preventDefault();
        },
        keypressForLetterAndNumber(e) {
            let regex = /[@$!%*?^+\-\{\}\[\]\(\)&#~`:;.'"=_]+/;
            if (regex.test(e.key)) e.preventDefault();
        },
        handleScrollEventForDropdownSelect() {
            const onScroll = e => {
                const activeMenu = document.querySelector('.v-menu__content.menuable__content__active');
                if (activeMenu) {
                    const selectMenu = activeMenu.children[0];
                    if (!selectMenu.contains(e.target) && (e.deltaX >= 0 || e.deltaY >= 0)) {
                        activeMenu.style.display = 'none';
                        activeMenu.classList.remove('menuable__content__active');
                    }
                }
            };
            window.addEventListener('wheel', onScroll);
        },
        uidv4() {
            let number = new Date().getTime(); //Timestamp
            let dateNumber = ('undefined' !== typeof performance && performance.now && performance.now() * 1000) || 0;
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = Math.random() * 16;
                if (0 < number) {
                    r = (number + r) % 16 | 0;
                    number = Math.floor(number / 16);
                } else {
                    r = (dateNumber + r) % 16 | 0;
                    dateNumber = Math.floor(dateNumber / 16);
                }
                return ('x' === c ? r : (r & 0x3) | 0x8).toString(16);
            });
        },
        getCurrency(id) {
            if (!this.$store.getters.allCurrenciesTogether) {
                return {};
            }
            return this.$store.getters.allCurrenciesTogether.find(i => i.id === id) || {};
        },
        downloadFile(id, target = '_self') {
            if (isNaN(parseInt(id))) return;
            window.open(`/file/download/${parseInt(id)}`, target);
        },
        transferPercentData(currency, data = null) {
            if (this.wallet) {
                currency = this.wallet.currency.code;
            }
            const items = data ? data : this.$store.state.transferPercent;

            const newObj = {};
            Object.keys(items || {}).forEach(k => {
                const i = items[k];
                if ('object' === typeof i) {
                    const defaultVal = i['default'] || 0;
                    newObj[k] = i[currency] === undefined ? defaultVal : i[currency];
                } else {
                    newObj[k] = i;
                }
            });

            return newObj;
        },
        showBankInfo(currency) {
            const withoutBank = this.$store.state.addMoneyWithoutBank || [];
            return !withoutBank.includes(currency);
        },
        async getMinimumForBankWithdrawal(code, toCode = null) {
            await axios.get(this.$store.state.getPath + 'withdraw-minimum-by-currency', {
                params: {
                    code: code,
                    type: 'bank',
                    to_code: toCode || code
                }
            })
                .then(res => {
                    this.minimumAmount = res.data
                })
        },
        showIban(wallet){
            return wallet.currency?.code !== 'EUR' || wallet.is_solo_entity
        },
        closeAllButtonsTerminate(worker) {
            if (worker.terminated_at) {
                const items = document.querySelectorAll('.work-terminate');
                Object.keys(items).map(ind => {
                    const el = items[ind];
                    el.classList.add('disabled');
                    el.addEventListener(
                        'click',
                        e => {
                            e.stopPropagation();
                            e.preventDefault();
                        },
                        true,
                    );
                });
            }
        },
        stripeFeeByCurrency(currencyCode) {
            let cardData = this.$store.state.stripeFee['card']

            return currencyCode in cardData ? cardData[currencyCode] : cardData['default']
        },
        bankFee() {
            return this.$store.state.stripeFee['bank'];
        },
        checkPreviewType(type) {
            return ['jpeg', 'jpg', 'gif', 'png', 'pdf'].includes(type);
        },
        __makeId(length = 16) {
            return __makeId(length);
        },

        __copyObj(obj) {
            return JSON.parse(JSON.stringify(obj));
        },

        __download(url, target = '_blank') {
            if (Array.isArray(url)) {
                url.forEach(item => window.open(item, target));
            } else {
                window.open(url, target);
            }
        },
        __downloadStream(url, params = {}, method = 'GET'){
            return __downloadStream(url, params, method)
        },
        __toSettings(tab = null) {
            this.__changeRoute(`${this.$store.state.path}settings${(tab && '?tab=' + tab) || ''}`);
        },
        __toBack() {
            window.history.back();
        },
        getMaxFractionByCheckingBeginingZero(number, maxFraction = 2)
        {
            if(!number){
                return maxFraction
            }

            let numberString = number.toString();
            // Find the index of the decimal point
            let decimalIndex = numberString.indexOf('.');

            if (decimalIndex === -1) {
                return maxFraction;
            }

            let checkBeforePoint = numberString.slice(0, decimalIndex)

            if(parseFloat(checkBeforePoint) > 0){
                return maxFraction;
            }

            // Count the number of leading zeros
            let countZeros = 1;
            let existBeforeZero = false;
            for (let i = decimalIndex + 1; i < numberString.length; i++) {
                if (numberString[i] === '0') {
                    countZeros++;
                    existBeforeZero = true
                } else {
                    break;
                }
            }

            if(existBeforeZero){
                maxFraction = countZeros;
            }

            return maxFraction;
        },

        toFixedCustom(number, digits) {

            let maxFraction = this.getMaxFractionByCheckingBeginingZero(number, digits)

            console.log(number)
            // Use toFixed with the count of zeros
            return  Number(number).toFixed(maxFraction);

        },

        formatAmount(
            value,
            currency = this.$store.state.organization.currency.code,
            minFraction = 2,
            maxFraction = 2,
            checkMaxFraction = true,
        ) {

            if(checkMaxFraction){
                maxFraction = this.getMaxFractionByCheckingBeginingZero(value, maxFraction)
            }

            let currencyWithSymbol =
                value || ~value
                    ? Number(value).toLocaleString('en-US', {
                        style: 'currency',
                        currency: currency,
                        minimumFractionDigits: minFraction,
                        maximumFractionDigits: maxFraction,
                    })
                    : null;

            if (currencyWithSymbol) {
                const exceptions = {
                    TRY: '₺',
                    PLN: 'zł',
                };
                for (const [key, value] of Object.entries(exceptions)) {
                    if (currencyWithSymbol.includes(key)) {
                        currencyWithSymbol = currencyWithSymbol.replace(key, exceptions[key]).replace(/\u00a0/g, '');
                    }
                }
            }

            return currencyWithSymbol;
        },
        formatSalaryAmount(amount, currency) {
            const amountParsed = parseFloat(amount);
            const formattedAmount = amountParsed.toLocaleString(undefined, {minimumFractionDigits: 2});
            return `${formattedAmount} ${currency}`;
        },
        removeCurrencySymbol(priceWithCurrency) {
            let exceptions = ['HRK', 'RSD', 'PLN', 'BGN', 'CZK', 'RON', 'INR', 'IDR', 'NGN'];

            for (let i = 0; i < exceptions.length; i++) {
                priceWithCurrency = priceWithCurrency.replace(exceptions[i], '');
            }

            return priceWithCurrency.replace(/\s+/g, "");
        },
        formatAmountNoCurrency(value, minFraction = 0, maxFraction = 2, checkMaxFraction = true) {

            if(checkMaxFraction){
                maxFraction = this.getMaxFractionByCheckingBeginingZero(value, maxFraction)
            }

            return value || ~value
                ? Number(value).toLocaleString('en-US', {
                    minimumFractionDigits: minFraction,
                    maximumFractionDigits: maxFraction,
                })
                : null;
        },
        formatAmountNoCurrencyTwoDigits(value, minFraction = 0, maxFraction = 2, checkMaxFraction = true) {
            if (checkMaxFraction) {
                maxFraction = this.getMaxFractionByCheckingBeginingZero(value, maxFraction);
            }

            const numberValue = Number(value);

            const formattedValue = numberValue.toFixed(maxFraction);

            return formattedValue;
        },
        isoAmountFormat(value, code = null) {
            let amount = this.formatAmountNoCurrency(value, 2, 2);
            if (!amount && !~amount) {
                amount = '0.00';
            }
            return code ? code + ' ' + amount : amount;
        },
        cutText(text, length) {
            if (text) {
                return text.length > length ? text.slice(0, length - 1) + '...' : text;
            }
        },
        commaJoin(arr, key) {
            let values = arr.map(a => a[key]);
            return values.join(', ');
        },
        __keyToText(key) {
            if (!key || !key.length) {
                return '';
            }

            return key.replace('_', ' ');
        },
        randomString(length) {
            let result = '';
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            characters += 'abcdefghijklmnopqrstuvwxyz';
            characters += '0123456789';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        bytesToSize(bytes) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        },
        setFirstElementFromList(items, property, obj) {
            if (items.length && !obj.values[property]) {
                obj.values[property] = items[0].id;
            }
            return obj.values[property];
        },
        priceFormat(price) {
            let priceToNumber = this.priceReplace(price);

            /*if (!priceToNumber) {
                priceToNumber = this.invoiceObj.values.itemsList.price[index]
            }*/

            let num = Number(priceToNumber).toLocaleString('en-US');

            if (num === 'NaN') {
                return 0;
            } else {
                return this.priceReplace(num, ',', '.');
            }
        },

        priceReplace(str, find = '.', replace = '') {
            return str.split(find).join(replace);
        },
        emailValid(email) {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                );
        },
        formatDate(date, format = 'd/m/Y') {
            const monthNames = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ];

            let d = new Date(date),
                //month = '' + (d.getMonth() + 1),
                month = '' + d.getMonth(),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;

            if (day.length < 2) day = '0' + day;

            if (format === 'm d, Y') {
                return monthNames[d.getMonth()] + ' ' + day + ', ' + year;
            }

            return [day, month, year].join('/');
        },
        formatDateShortMonth(date, format = 'd/m/Y') {
            const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

            var d = new Date(date),
                //month = '' + (d.getMonth() + 1),
                month = '' + d.getMonth(),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;

            if (day.length < 2) day = '0' + day;

            if (format === 'm d, Y') {
                return monthNames[d.getMonth()] + ' ' + day + ', ' + year;
            }

            return [day, month, year].join('/');
        },
        getMonthAndYear(value) {
            // Split the filename by the first underscore
            const parts = value.split('_');

            if (parts.length < 3) {
                return 'Unknown';
            }

            const yearPart = parts[2];
            const monthPart = parts[1];
            const monthNumber = parseInt(monthPart, 10);

            if (isNaN(monthNumber) || monthNumber < 1 || monthNumber > 12) {
                return 'Unknown';
            }

            const date = new Date(2000, monthNumber - 1, 1);
            const monthName = date.toLocaleString('en', { month: 'long' });

            return `${monthName} ${yearPart}`;
        },
        isDevEnv() {
            return this.$store.state.env === 'dev';
        },
        __formatDate(date) {
            const [year, month, day] = date.split('-');
            return `${day ? day + '/' : ''}${month ? month + '/' : ''}${year || ''}`;
        },

        __getData(route = null, params = {}) {
            this.$data.__dataLoading = true;
            axios
                .get(this.$store.state.path + route, {params: params})
                .then(response => {
                    for (const [objKey, objValue] of Object.entries(response.data)) {
                        this[_.camelCase(objKey)] =
                            objValue && typeof objValue === 'object' && !Array.isArray(objValue)
                                ? {...objValue}
                                : objValue;
                    }
                    this.$data.__dataLoading = false;
                })
                .catch(error => {
                    this.$data.__dataLoading = false;
                    this.$store.commit('setAlert', {
                        type: 'error',
                        message: error.response.data.message,
                    });
                });
        },
        __copyText(containerId) {
            let r = document.createRange();
            r.selectNode(document.getElementById(containerId));
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(r);
            document.execCommand('copy');
            window.getSelection().removeAllRanges();
        },

        __copyToClipboard(text, changeTxt = '') {
            const textArea = document.createElement('textarea');

            // Place in the top-left corner of screen regardless of scroll position.
            textArea.style.position = 'fixed';
            textArea.style.top = 0;
            textArea.style.left = 0;

            // Ensure it has a small width and height. Setting to 1px / 1em
            // doesn't work as this gives a negative w/h on some browsers.
            textArea.style.width = '2em';
            textArea.style.height = '2em';

            // We don't need padding, reducing the size if it does flash render.
            textArea.style.padding = 0;

            // Clean up any borders.
            textArea.style.border = 'none';
            textArea.style.outline = 'none';
            textArea.style.boxShadow = 'none';

            // Avoid flash of the white box if rendered for any reason.
            textArea.style.background = 'transparent';

            textArea.value = text;

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                navigator.clipboard.writeText(textArea.value);
                const successful = document.execCommand('copy');
                const msg = successful ? 'successful' : 'unsuccessful';
                console.log('Copying text command was ' + msg);
            } catch (err) {
                console.log('Oops, unable to copy');
            }

            document.body.removeChild(textArea);

            if (changeTxt) {
                this.ctext = changeTxt;
            }
        },
        __getYears(add) {
            let arr = [];
            let i = 0;
            let current = parseInt(new Date().getFullYear()) + add;
            while (i < 40) {
                arr.push({name: current - i, value: String(current - i)});
                i++;
            }
            return arr;
        },
        _orderByData(items, val, sort = 'asc') {
            let notSortingData = items.filter(i => i.no_sort);

            let data = _.orderBy(
                items.filter(i => !i.no_sort),
                [
                    item => {
                        if (!item[val]) return '';
                        if (typeof item[val] === 'string') {
                            return item[val].toLowerCase();
                        }
                        return item[val];
                    },
                ],
                [sort],
            );
            if (notSortingData.length) {
                return [...notSortingData, ...data];
            }
            return data;
        },

        _extractContent(s) {
            const span = document.createElement('span');
            span.innerHTML = s;
            return span.textContent || span.innerText;
        },

        __checkPermission(val) {
            return this.$store.state.adminPage || this.$store.state.permissions.includes(val);
        },

        __middlewarePermission(val, callback, args = null) {
            this.__checkPermission(val) ? callback(args) : (this.$store.state.upgradeDialog = true);
        },
        async __middlewareVerified(callback, data = {}) {
            return await this.__checkVerified(data).then(verified => {
                if (verified){
                    callback()
                }
            });
        },

        __checkPlan(val) {
            const plan = this.$store.state.worker.plan.value;
            return Array.isArray(val) ? val.includes(plan) : plan === val;
        },
        __checkMenuItem(val) {
            return this.$store.state.availableMenus.includes(val);
        },
        __checkMenuItemWithUnchangedRoute(val) {
            return this.$store.state.menuItemsWithUnchangedRoutes.includes(val);
        },
        async __checkUserRestriction() {
            return await this.$store.dispatch('kyc/__checkRestriction');
        },
        async __checkVerified(data = {}) {
            return await this.$store.dispatch('kyc/__checkVerified', data);
        },
        _openWalletVerifyModal(dialog = true) {
            this.$store.commit('setData', {
                key: 'walletCurrencyDocModal',
                data: dialog,
            });
        },
        __checkWalletCurrencyDocModal(currency = null) {
            if (!currency) {
                currency =
                    this.$store.state.selectedWallet && this.$store.state.selectedWallet.currency
                        ? this.$store.state.selectedWallet.currency
                        : null;
            }

            if (!currency) {
                return false;
            }
            if (this.$store.state.walletCurrencyDoc.includes(currency.code)) {
                const status = this.$store.state.walletCurrencyDocVerified[currency.id];
                return status !== 'approve';
            }
            return false;
        },
        __changeOrganization(id, route = '') {
            this.$store.dispatch('initialization/__changeOrganization', {id, route}).then(() => {});
        },
        __goToPlansPage() {
            window.location.href = this.$store.state.path + 'add-ons/my-plan';
        },
        __goToSoloEntityPage() {
            window.location.href = this.$store.state.soloEntityPageRoute;
        },
        _getPageTitle() {
            return document.title;
        },
        _setPageTitle(title) {
            document.title = title;
            if (typeof gtag === 'function') {
                gtag('event', 'screen_view', {
                    screen_name: title,
                });
            }
        },
        // Month in JavaScript is 0-indexed (January is 0, February is 1, etc),
        // but by using 0 as the day it will give us the last day of the prior
        // month. So passing in 1 as the month number will return the last day
        // of January, not February
        daysInMonth(month, year) {
            return new Date(year, month, 0).getDate();
        },

        __hasAnyRole(roles) {
            const exists = this.$store.state.authUser.roles.find(item => {
                return roles.indexOf(item.name) !== -1;
            });

            return !!exists;
        },

        __getAllWallets() {
            return this.$store.state.soloEntityWallet
                ? [...this.$store.state.wallets, this.$store.state.soloEntityWallet]
                : this.$store.state.wallets;
        },

        __hasPermissionNtt(_permission, org) {
            const authUser = this.$store.state.authUser;
            const nttOrgId = this.$store.state.nttOrg;

            if (!org) {
                return false;
            }

            if (nttOrgId && nttOrgId == org.id) {
                return authUser.rolepermissions.indexOf(_permission) !== -1;
            }

            return true;
        },

        __hasPermission(_permission, item) {
            let permission;

            const authUser = this.$store.state.authUser;

            if (authUser.roles.find(item => item.name === 'app-owner')) {
                return true;
            }

            if (authUser.rolepermissions.indexOf(_permission) !== -1) {
                return true;
            } else if (
                item &&
                item.enterprise &&
                authUser.rolepermissionsenterprise &&
                (permission = _permission.match(/^([\w\-]+)\-(\w+)$/))
            ) {
                let enterpriseAccess;
                let enterpriseIds = [];

                if (Array.isArray(item.enterprise)) {
                    enterpriseIds = item.enterprise.map(item => item.id);
                } else {
                    enterpriseIds = [item.enterprise.id];
                }

                let permissionName = permission[1];
                let permissionAccess = permission[2];

                if (authUser.rolepermissions.indexOf(permissionName + '-enterprise') !== -1) {
                    for (const enterpriseId of enterpriseIds) {
                        if ((enterpriseAccess = authUser.rolepermissionsenterprise[enterpriseId])) {
                            return permissionAccess === enterpriseAccess;
                        }
                    }
                }
            }

            if (!this.$store.state.isSuperAdmin) {
                if (
                    this.$store.state.organization.creator_id &&
                    this.$store.state.worker.id &&
                    this.$store.state.organization.creator_id === this.$store.state.worker.id
                ) {
                    return true;
                }
            }

            return false;
        },
        checkNested(obj) {
            var args = Array.prototype.slice.call(arguments, 1);

            for (var i = 0; i < args.length; i++) {
                if (!obj || !obj.hasOwnProperty(args[i])) {
                    return false;
                }
                obj = obj[args[i]];
            }
            return true;
        },

        // Validates whether the phone number is complete (country code + number)
        checkPhoneNumber(phoneNumber) {
            if (!phoneNumber || !phoneNumber.length || phoneNumber.startsWith('+0')) return false;

            const phonePattern = /^\+?\d+$/;
            return phoneNumber.length > 5 && phonePattern.test(phoneNumber);
        },


        /*
            Debounce a function
            If you need to debounce multiple functions on the same page, you must specify a custom "key" parameter for each function. Otherwise, the default key is fine
        */
        debounce(func, key = 'debounceDefaultKey', wait = 450) {
            if (this.debounceTimers[key]) clearTimeout(this.debounceTimers[key]);
            this.debounceTimers[key] = setTimeout(() => {
                // Check for function to debounce parameters
                if (Array.isArray(func)) {
                    const [functionToDebounce, ...functionQueries] = func;
                    functionToDebounce(...functionQueries);
                } else {
                    func();
                }
            }, wait);
        },
        __dateFormat(date, formatString = "dd/MM/yyyy") {
            return format(new Date(date), formatString);
        },

        __stringToCamelCase(str) {
            return str
                .toLowerCase()
                .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                    return index === 0 ? word.toLowerCase() : word.toUpperCase();
                })
                .replace(/\s+/g, "");
        },
        getNewPeopleTitle() {
            if ((this.__isEmployerPlan || this.__isClientPlan || this.__isClientProPlan) && !this.__isDefaultOrg) {
                return 'Team';
            } else if (
                ((this.__isEmployerPlan || this.__isClientPlan || this.__isClientProPlan) && this.__isDefaultOrg)
                || this.__isEmployeePlan || this.__isClientSeatPlan || this.__isClientProSeatPlan || this.__individualPlans) {
                return 'Contacts';
            }
        },
        __sanitizeHtml(str) {
            return sanitizeHtml(str);
        },
        __cleanHtml(str) {
            return cleanHtml(str);
        },
        __transformUrlsToLinks(str) {
            return transformUrlsToLinks(str);
        },
        displayCreateOrganizationModal(worker) {
            return ['employer', 'client', 'grow', 'client_pro'].includes(worker?.plan?.value);
        },
    },
    computed: {
        getSwift() {
            if (this.wallet) {
                let details = this.$store.state.systemWallets.find(item => item.currency === this.wallet.currency.code).data;
                let swift = details.find(item => item.title == "SWIFT/BIC");
                return swift ? swift.value : null;
            }
        },
        getAccountHolder() {
            if (this.wallet) {
                let details = this.$store.state.systemWallets.find(item => item.currency === this.wallet.currency.code).data;
                let name = details.find(item => item.title == "Account holder");
                return name ? name.value : null;
            }
        },
        batchTransferEnabled() {
            return this.__employmentPlans.includes(this.$store.state.worker.plan.value);
        },
        __expenseOutEnabled() {
            return __config('VUE_APP_EXPENSE_OUT');
        },
        __isFree() {
            return this.$store.state.primaryWorker && this.$store.state.primaryWorker.plan.value === 'free';
        },
        __isFreePayments() {
            return this.$store.state.worker && this.$store.state.worker.plan && this.$store.state.worker.plan.value === 'free_payments';
        },
        __isLatestPayments() {
            return this.$store.state.worker && this.$store.state.worker.plan && this.$store.state.worker.plan.value === 'payments_metered_lower';
        },
        __isPaymentsOrContractor() {
            let plans = ['Payments'];
            return this.$store.state.worker
                && this.$store.state.worker.plan
                && plans.includes(this.$store.state.worker.plan.name);
        },
        __isFreeCurrent() {
            return (
                this.$store.state.worker &&
                this.$store.state.worker.plan &&
                this.$store.state.worker.plan.value === 'free'
            );
        },
        __isFreeWithoutUnlimintCards() {
            return (
                this.$store.state.worker &&
                this.$store.state.worker.plan &&
                this.$store.state.worker.plan.value === 'free' &&
                (!this.$store.state.worker.unlimint_cards || this.$store.state.worker.unlimint_cards.length == 0)
            );
        },
        __hasCard() {
            const hasUnlimintCards = this.$store.state.worker.unlimint_cards && this.$store.state.worker.unlimint_cards?.length > 0;
            const hasNativeCards = this.$store.state.worker.primary_worker && this.$store.state.worker.primary_worker?.native_cards?.length > 0;
            return hasUnlimintCards || hasNativeCards;
        },
        __isFreeNigeria() {
            return (
                this.$store.state.worker &&
                this.$store.state.worker.plan &&
                this.$store.state.worker.plan.value === 'free_nigeria'
            );
        },
        __isAnyFree() {
            return this.__isFreeCurrent || this.__isFreeNigeria;
        },
        __invoiceIBANUrl() {
            return this.__isAnyFree ? '/add-ons/my-plan' : '/invoice?tab=create'
        },
        __paymentRequestIBANUrl() {
            return '/payment-request?quickAction=request'
        },
        __isAnyPaid() {
            return !this.__isAnyFree;
        },
        __isSeat() {
            return (
                this.$store.state.worker &&
                this.$store.state.worker.plan &&
                this.$store.state.worker.plan.value === 'seat'
            );
        },
        __isAnySeat() {
            return (
                this.$store.state.worker &&
                this.$store.state.worker.plan &&
                this.$store.state.worker.plan.name === 'Seat'
            );
        },
        __isDev() {
            return this.$store.state.env === 'dev';
        },
        __isProd() {
            return this.$store.state.env === 'prod';
        },
        __getExactEnv() {
            return this.$store.state.envExact;
        },
        __isMakeOrganization() {
            if (this.__isBrandOrg && !this.__isBrandedEmployer) {
                return false;
            }

            return (
                this.$store.state.primaryWorker && this.__employmentPlans.includes(this.$store.state.primaryWorker.plan.value)
            );
        },
        __accountType() {
            if (this.$store.state.worker && this.$store.state.worker.plan) {
                if (this.$store.state.worker.plan.value === 'free') {
                    return 'free';
                } else if (this.$store.state.worker.plan.value === 'free_payments') {
                    return 'free_payments';
                } else if (this.__individualPlans.includes(this.$store.state.worker.plan.value)) {
                    return 'individual';
                } else if (this.$store.state.isOnBusinessLegacyPlan) {
                    return 'business_legacy';
                } else {
                    return 'business';
                }
            }
            return 'free';
        },
        __accountTypeName() {
            if (this.$store.state.worker && this.$store.state.worker.plan) {
                if (this.$store.state.worker.plan.value === 'free') {
                    return 'Free';
                } else if (this.__individualPlans.includes(this.$store.state.worker.plan.value)) {
                    return 'Individual';
                } else if (this.$store.state.isOnBusinessLegacyPlan) {
                    return 'Business Legacy';
                } else {
                    return 'Business';
                }
            }
            return 'Free';
        },
        __accountPlanName() {
            if (this.$store.state.worker && this.$store.state.worker.plan) {
                return this.$store.state.worker.plan.name
            }
            return 'Free';
        },
        __individualPlans() {
            return ['payments_metered', 'payments_metered_lower', 'payments_pro', 'remoter_higher', 'remoter_pro', 'tax_management_higher', 'native_company'];
        },
        __employmentPlans() {
            return ['grow', 'client', 'client_pro', 'employer'];
        },
        __paymentsOnlyPlans() {
            return ['payments_metered_lower', 'payments_metered'];
        },
        __clientSeatPlans() {
            return ['client_seat_lower', 'client_seat', 'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2'];
        },
        __clientProSeatPlans() {
            return ['client_seat_pro'];
        },
        __employerOfRecordSeatPlans() {
            return ['employer_seat', 'employer_seat_higher', 'seat'];
        },
        __anyRemoter() {
            return ['remoter_higher'];
        },
        __isOnPaymentsPlan() {
            return this.$store.state &&
                this.$store.state.worker &&
                this.$store.state.worker.plan &&
                this.__paymentsOnlyPlans.includes(this.$store.state.worker.plan.value);
        },
        isAdmin() {
            return (
                this.$store.state.worker &&
                this.$store.state.worker.role &&
                this.$store.state.worker.role.value === 'admin'
            );
        },
        isEmployee() {
            return (
                this.$store.state.worker &&
                this.$store.state.worker.role &&
                this.$store.state.worker.role.value === 'employee'
            );
        },
        isClient() {
            return (
                this.$store.state.worker &&
                this.$store.state.worker.role &&
                this.$store.state.worker.role.value === 'client'
            );
        },
        isCreator() {
            return this.$store.state.organization
                ? this.$store.state.organization.creator_id === this.$store.state.worker.id
                : false;
        },
        isTrialEmploymentAccount() {
            return this.$store.state.primaryWorker?.trial_employment_account
                || this.isAdditionalAdmin && this.$store.state.isAdminInvitedByTrialEmploymentAccount;
        },
        isBusinessAccount() {
            return this.$store.state.worker?.business_account;
        },
        isExpiredTrialEmploymentAccount() {
            return this.$store.state.primaryWorker?.expired_trial_employment_account;
        },
        isAdditionalAdmin() {
            return this.isAdmin && !this.isCreator;
        },
        isAppOwner() {
            return this.$store.state.authUser
                ? (this.$store.state.authUser.roles || []).find(item => item.name === 'app-owner')
                : null;
        },
        canAddOrg() {
            return this.__employmentPlans.includes(this.$store.state.primaryWorker.plan.value)
                && this.__employmentPlans.includes(this.$store.state.worker.plan.value)
                && !this.isAdditionalAdmin;
        },
        isNonEuUser() {
            return this.$store.state.isNonEuUser;
        },
        isEuUser() {
            return !this.isNonEuUser;
        },
        entityEnabled() {
            const employmentPlans = ['grow', 'client', 'employer'];
            return (
                this.$store.state.worker &&
                this.$store.state.worker.plan &&
                this.$store.state.organization &&
                this.$store.state.organization.id !== 1 &&
                employmentPlans.includes(this.$store.state.worker.plan.value)
            );
        },
        soloEntityEnabled() {
            return this.$store.state.soloEntityEnabled ?? false;
        },
        soloEntityActive() {
            return this.$store.state.soloEntityActive ?? false;
        },
        soloEntityUpgradingEnabled() {
            return this.$store.state.soloEntityUpgradingEnabled ?? false;
        },
        isTaxManagementAccount() {
            this.$store.state.worker.plan.value = 'tax_management';
        },
        __allWallets() {
            return this.__getAllWallets();
        },
        hasBalanceInWallets() {
            return this.__allWallets.some(wallet => wallet.available_balance > 0);
        },
        __isBrandedEmployer() {
            return this.$store.state.isBrandedEmployer ?? false;
        },
        __isBrandOrg() {
            return this.$store.state.isBrandedOrg ?? false;
        },
        __years() {
            let arr = [];
            let i = 0;
            let current = parseInt(new Date().getFullYear());
            while (i < 40) {
                arr.push({name: current - i, value: String(current - i)});
                i++;
            }
            return arr;
        },
        __months() {
            let arr = [];
            if (this.$store.state.monthNames) {
                this.$store.state.monthNames.forEach((item, index) => {
                    let val = String(index + 1);
                    arr.push({
                        name: item,
                        value: index + 1 < 10 ? '0' + val : val,
                    });
                });
            }

            return arr;
        },

        __openedBrowser() {
            let userAgent = navigator.userAgent;
            let browserName;

            if (userAgent.match(/chrome|chromium|crios/i)) {
                browserName = 'chrome';
            } else if (userAgent.match(/firefox|fxios/i)) {
                browserName = 'firefox';
            } else if (userAgent.match(/safari/i)) {
                browserName = 'safari';
            } else if (userAgent.match(/opr\//i)) {
                browserName = 'opera';
            } else if (userAgent.match(/edg/i)) {
                browserName = 'edge';
            } else {
                browserName = 'No browser detection';
            }

            return browserName;
        },



        __isIOS() {
            const expression = /(Mac|iPhone|iPod|iPad)/i;
            return expression.test(navigator.platform);
        },
        probationPeriodEnabled() {
            return __config('VUE_APP_EOR_PROBATION_PERIOD_ENABLED');
        },
        __isEmployerPlan() {
            const allowedPlans = ['grow', 'employer'];

            return allowedPlans.includes(this.$store.state.worker?.plan?.value);
        },
        __isEmployeePlan() {
            const allowedPlans = ['seat', 'employer_seat', 'employer_seat_higher'];

            return allowedPlans.includes(this.$store.state.worker?.plan?.value);
        },
        __isClientPlan() {
            return this.$store.state?.worker?.plan?.value === 'client';
        },
        __isClientSeatPlan() {
            return this.__clientSeatPlans.includes(this.$store.state.worker?.plan?.value);
        },
        __isClientProPlan() {
            return this.$store.state?.worker?.plan?.value === 'client_pro';
        },
        __isClientProSeatPlan() {
            return this.__clientProSeatPlans.includes(this.$store.state.worker?.plan?.value);
        },
        __isPaymentsPlan() {
            const allowedPlans = ['payments', 'payments_metered_lower', 'payments_metered', 'payments_pro'];
            return allowedPlans.includes(this.$store.state.worker?.plan?.value);
        },
        __isRemoterProPlan() {
            return this.$store.state?.worker?.plan?.value === 'remoter_pro';
        },
        __isRemoterPlan() {
            return this.$store.state?.worker?.plan?.value === 'remoter_higher';
        },
        __isPayrollServicePlan() {
            return this.$store.state.worker.plan.value === 'payroll_service';
        },
        __isDefaultOrg() {
            return this.$store.state.organization?.id === this.defaultNtOrg;
        },
        __isBusinessPlan() {
            return this.$store.state?.isBusiness;
        },
        __isIndividualPlan() {
            return this.$store.state?.isIndividual;
        },
        __isAnyProPlan() {
            const proPlans = ['payments_pro', 'remoter_pro', 'client_pro', 'client_seat_pro'];

            return proPlans.includes(this.$store.state.worker?.plan?.value);
        },
        defaultProfilePicture() {
            return 'users/user-3.png';
        },
        getNewPeopleUrl() {
            // TODO: Implement new logic when available
            return 'people';
        },
        isEmployerInNotDefaultOrg() {
            return this.__isEmployerPlan && !this.__isDefaultOrg;
        },
    },
};
