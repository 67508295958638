<template>
  <v-list-group :ripple="false" v-bind="$attrs" v-on="$listeners">
    <template v-slot:activator>
      <v-list-item-icon>
        <div class="icon-container">
          <BaseIcon :name="icon" />
        </div>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>
          <p class="text-base">{{ title }}</p>
        </v-list-item-title>
      </v-list-item-content>
    </template>

    <slot></slot>
  </v-list-group>
</template>

<script>
export default {
  name: "NavListGroup",

  props: {
    icon: {
      default: "",
    },
    title: {
      default: "",
    },
  },
};
</script>
