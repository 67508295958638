import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

const opts = {
    theme: {
        themes: {
            light: {
                primary: "#5152fb",
                secondary: "#424242",
                accent: "#6263b6",
                error: "#fa4769",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107",
                anchor: "#838a90",
            },
        },
    },
};

Vue.use(Vuetify);

const VBtn = Vue.component('VBtn')
VBtn.options.props.ripple.default = false;

export default new Vuetify(opts)
