const state = {
    item: null,
    benefits: [],
    message: '',
};

const getters = {
    item: (state) => state.item,
    benefits: (state) => state.benefits,
    message: (state) => state.message,
}

const mutations = {
    item(state, payload) {
        state.item = payload;
    },
    message(state, payload) {
        state.message = payload;
    },
    setItem(state, payload) {
        state.item = payload;
    },
}

const actions = {
    async getItem({ commit }, id) {
        return await axios.get(`/benefits/${id}`)
            .then(response => {
                commit('item', response.data);
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.response.data.message});
                throw error;
            });
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
