<template>
<div class="alert"
    :class="[
        customParentClass,
        alertClass,
        isBigContent ? 'big-content' : '',
        isAlternative ? 'alternative' : '',
    ]">
    <BaseIcon
        name="check-thin-circle-outline"
        class="mr-2 base-icon-alert-box"
        :size="iconSize"
        v-if="
        type === 'success' ||
        type === 'authorized' ||
        type === 'active' ||
        type === 'completed' ||
        type === 'employed' ||
        type === 'sent' ||
        type === 'paid' ||
        type === 'approved'
      "
    />
    <BaseIcon
        name="error-thin-circle-outline"
        class="mr-2 base-icon-alert-box"
        :size="iconSize"
        v-if="
        type === 'error' ||
        type === 'disabled' ||
        type === 'rejected' ||
        type === 'failed' ||
        type === 'declined' ||
        type === 'overdue' ||
        type === 'canceled'
      "
    />
    <BaseIcon
        name="warning"
        class="mr-2 base-icon-alert-box"
        :size="iconSize"
        v-if="type === 'warning' || type === 'pending' || type === 'reserved'"
    />
    <BaseIcon
        name="pending-circle-outline"
        class="mr-2 base-icon-alert-box"
        :size="iconSize"
        v-if="
        type === 'requested' ||
        type === 'scheduled' ||
        type === 'draft' ||
        type === 'unemployed' ||
        type === 'currentMonth' ||
        type === 'unpaid'
      "
    />
    <BaseIcon
        name="info-thin-circle-outline"
        class="mr-2 base-icon-alert-box"
        :size="iconSize"
        v-if="
        type === 'inactive' || type === 'unavailable' || type === 'archived'
      "
    />
    <BaseIcon
        name="info-thin-circle-outline"
        class="mr-2 base-icon-alert-box"
        :size="iconSize"
        :alertBoxCustomClasses="alertBoxCustomClasses"
        v-if="type === 'info'"
    />
    <p
        v-if="html"
        v-html="__sanitizeHtml(text)"
        :class="[isBigContent ? 'paragraph leading-120' : 'text-sm text-capitalize', customLabelClasses]"
    >
    </p>
    <p
        v-else
        :class="[isBigContent ? 'paragraph leading-120 tm-initial' : 'text-sm text-capitalize', customLabelClasses]"
    >
        {{ text }}
        <slot name="default"></slot>
    </p>
</div>
</template>

<script>
import Helpers from '~/shared/mixins/Helpers';
export default {
    name: "BaseAlert",
    mixins: [Helpers],
    props: {
        type: {
            type: String,
            default: "info",
        },
        text: {
            type: String,
            default: "",
        },
        isBigContent: {
            type: Boolean,
            default: false,
        },
        isAlternative: {
            type: Boolean,
            default: false,
        },
        iconSize: {
            type: String,
            default: '',
        },
        html: {
            type: Boolean,
            default: false
        },
        customParentClass: {
            type: String,
            default: ''
        },
        customLabelClasses: {
            type: String,
            default: '',
        },
        alertBoxCustomClasses: {
            type: String,
            default: '',
        },
    },

    computed: {
        alertClass() {
            if (this.type === 'approved') {
                return "alert-" + 'active';
            }
            if (this.type === 'canceled') {
                return "alert-" + 'declined';
            }
            return "alert-" + this.type;
        },
    },
};
</script>
