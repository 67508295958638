import Model from '~/shared/Model';

export default class CaseComment extends Model{

    constructor(obj = {}) {
        super();

        this.values = {
            case_id: obj.case_id || null,
            text: obj.text || null,
        }

        this.rules = {
            text: 'required|min:1|max:3000',
            case_id: 'required|int',
        };
    }
}
