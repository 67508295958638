<template>
    <div @click="handleClick" class="base-toggle-switch">
    <p
        v-if="leftLabel"
        class="text-medium mr-2"
        :class="!val || hasOnlyLeftLabel ? 'color-black' : 'color-dark-grey'"
    >
      {{ leftLabel }}
    </p>
    <div class="switcher-container" :class="{ active: val }">
        <div></div>
    </div>
    <template v-if="!$slots['label']">
      <p
          v-if="label"
          class="text-medium ml-2"
          :class="!val ? 'color-dark-grey' : activeClass"
      >
        {{ label }}
      </p>
    </template>
    <template v-else>
        <slot name="label"></slot>
    </template>
</div>
</template>
<script>
export default {
    name: "BaseSwitcher",

    props: {
        value: {
            default: 0,
        },
        trueValue: {
            default: 1,
        },
        falseValue: {
            default: 0,
        },
        label: {
            default: null,
        },
        leftLabel: {
            default: null,
        },
        hasOnlyLeftLabel: {
            type: Boolean,
            default: false,
        },
        activeClass: {
            type: String,
            default: "color-black",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            val: this.value,
        };
    },

    watch: {
        val() {
            this.$emit("input", this.val);
        },
        value() {
            this.val = this.value;
        },
    },
    methods: {
        handleClick() {
            if (!this.disabled) {
                this.val = this.val ? this.falseValue : this.trueValue;
                this.$emit("click", this.val); // emit click event with the updated value
            }
        },
    },
};
</script>
