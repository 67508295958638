<template>
    <div
        class="file-upload-wrapper"
        :class="{ dragged: isDragged && dragAndDrop }"
        @dragover.prevent="isDragged = true"
        @dragleave.prevent="isDragged = false"
        @drop.prevent="dropFile"
    >
        <slot></slot>
        <label :class="activatorClassNames" class="btn btn-small">
            <template v-if="!$slots['activator']">
                <WlBaseIcon name="attachment" class="mr-2" />
                Add an Attachment
            </template>
            <template v-else>
                <slot name="activator"></slot>
            </template>
            <v-file-input
                :accept="allowedDocumentTypes"
                :prepend-icon="null"
                hide-input
                :multiple="multiple"
                v-model="val"
            />
        </label>
    </div>
</template>

<script>
export default {
  name: "WlBaseFileUpload",

    props: {
        value: {
            default: null,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        allowedDocumentTypes: {
            type: [String, Array],
            default: "image/*,application/pdf",
        },
        activatorClassNames: {
            default: "btn-text text-sm font-medium color-purple",
        },
        dragAndDrop: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isDragged: false,

            val: this.multiple && !this.value ? [] : this.value,
            files: this.multiple && !this.value ? [] : this.value,
        };
    },

    watch: {
        value() {
            this.files = this.value;

            if (!this.multiple) {
                this.val = this.value;
            }
        },
        val(val) {
            this.files = this.multiple ? [...this.files, ...val] : val;
        },
        files(val) {
            this.$emit("input", val);
        },
    },

    methods: {
        dropFile(e) {
            this.isDragged = false;
            if (this.dragAndDrop) {
                if (this.multiple) {
                    this.files.push(...e.dataTransfer.files);
                } else {
                    this.files = e.dataTransfer.files[0] || null;
                }
                return true;
            }
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
.file-upload-box-wrapper {
    min-height: 12.5rem;
    border: 1px dashed var(--typography-grey-3-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.8125rem 0;
    flex-direction: column;
    transition: 0.3s all;
}

.file-upload-wrapper {
    transition: opacity 0.3s;
}

.mark-as-business-expense-file-uploader {
    border-radius: 24px;
    i {
        font-size: 30px;
    }
}

.file-upload-box-wrapper.dragged,
.file-upload-wrapper.dragged {
    opacity: 0.3 !important;
}
</style>
