const DEFAULT_ACTION_TEXT = 'request a card';
export const namespaced = true;
// initial state
export const state = {
    confirmation2faDialog: false,
    confirmation2faActionText: DEFAULT_ACTION_TEXT,
    form2faDialog: false,
    form2faDialogHash: '#show-2fa-dialog',
};

// getters
export const getters = {
    enable: (state, getters, rootState) => rootState.authUser.tfa_enable,
    type: (state, getters, rootState) => rootState.authUser.tfa_type,
    types: (state, getters, rootState) => rootState.tfaTypes || [],
    form2faDialog: state => state.form2faDialog,
    form2faDialogHash: state => state.form2faDialogHash,
};

// mutations
export const mutations = {
    confirmation2faDialog(state, payload) {
        state.confirmation2faDialog = payload;
    },
    confirmation2faActionText(state, payload) {
        state.confirmation2faActionText = payload;
    },
    form2faDialog(state, payload) {
        state.form2faDialog = payload;
    },
};

// actions
export const actions = {
    enable({ commit, rootState }, { type, password, hideAlert }) {
        return axios.post(`/settings/security/2fa/${type}`, { password }).then(response => {
            if (!hideAlert){
                commit('setAlert', { type: 'info', message: response.data.message }, { root: true });
            }
            const authUser = rootState.authUser;
            authUser.tfa_enable = true;
            commit('wlSecurity2fa/setEnabled', authUser.tfa_enable, {root: true})
            commit('setData', {
                key: 'authUser',
                data: authUser
            }, { root: true });
            return response.data;
        }).catch(error => {
            const errorMessage = error.response?.data?.message || error.message;
            commit('setAlert', { type: 'error', message: errorMessage }, { root: true });
            throw error;
        });
    },
    disable({ commit, rootState }, { type, password, hideAlert }) {
        return axios.delete(`/settings/security/2fa/${type}`, { data: { password } }).then(response => {
            if (!hideAlert){
                commit('setAlert', { type: 'info', message: response.data.message }, { root: true });
            }
            const authUser = rootState.authUser;
            authUser.tfa_enable = false;
            authUser.tfa_type = null;
            commit('setData', {
                key: 'authUser',
                data: authUser
            }, { root: true });
            commit('wlSecurity2fa/setEnabled', authUser.tfa_enable, {root: true})
            return response.data;
        }).catch(error => {
            if(error?.response?.data?.message){
                commit('setAlert', { type: 'error', message: error.response.data.message }, { root: true });
            }else{
                commit('setAlert', { type: 'error', message: error.message }, { root: true });

            }
            throw error;
        });
    },
    generate({ commit, rootState }, { type, config, hideAlert }) {
        return axios.post(`/settings/security/2fa/${type}/generate`, { type, config }).then(response => {
            if (!hideAlert){
                commit('setAlert', { type: 'info', message: response.data.message }, { root: true });
            }
            const authUser = rootState.authUser;
            authUser.tfa_type = type;
            commit('setData', {
                key: 'authUser',
                data: authUser
            }, { root: true });
            return response.data;
        }).catch(error => {
            commit('setAlert', { type: 'error', message: error.message }, { root: true });
            throw error;
        });
    },
    resend({ commit }, { type, hideAlert }) {
        return axios.post(`/settings/security/2fa/${type}/resend`).then(response => {
            if (!hideAlert){
                commit('setAlert', { type: 'info', message: response.data.message }, { root: true });
            }
            return response.data;
        }).catch(error => {
            commit('setAlert', { type: 'error', message: error.message }, { root: true });
            throw error;
        });
    },
    showConfirmation2fa({ commit }, actionText) {
        commit('confirmation2faDialog', true);
        if (actionText) {
            commit('confirmation2faActionText', actionText);
        }
    },
    hideConfirmation2fa({ commit }) {
        commit('confirmation2faDialog', false);
        commit('confirmation2faActionText', DEFAULT_ACTION_TEXT);
    },
    setForm2faDialog({ commit }, value) {
        commit('form2faDialog', value);
    },
    showForm2faDialog({ dispatch }) {
        dispatch('setForm2faDialog', true);
    },
    hideForm2faDialog({ dispatch }) {
        dispatch('setForm2faDialog', false);
    },
};
