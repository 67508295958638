<template>
    <div class="d-flex flex-column">
        <label
            :for="localId"
            v-if="label"
            class="custom-label text-sm color-black"
            :class="[labelClassVal]"
        >
            <slot v-if="$slots.label" name="label"></slot>
            <span v-else>{{ label }}</span>
        </label>
        <div class="position-relative">
            <div
                :style="addStyles"
                class="v-input input pl-0 d-flex align-center"
                :class="errorMessages.length ? 'error' : null"
            >
                <v-autocomplete
                    :items="items"
                    item-value="code"
                    item-text="dial_code"
                    v-model="selectCountryCode"
                    :search-input.sync="searchValue"
                    @update:search-input="search"
                    @input="input"
                    ref="autocomplete"
                    class="base-select-phone"
                >
                    <template slot="append">
                        <slot name="append">
                            <BaseIcon
                                name="chevron-down"
                                class="color-purple cursor-pointer animation-rotate"
                            />
                        </slot>
                    </template>
                    <template v-slot:selection="{ item }">
                        <div v-if="!isSearching" class="d-flex align-center">
                            <BaseAvatar
                                :contain="false"
                                size="1.25rem"
                                :elevation="false"
                                :avatar="$store.state.allCountries.find(c => c.code === item.code)?.flag"
                            ></BaseAvatar>
                            <span class="text-sm color-black mr-1">{{ item.dial_code }}</span>
                        </div>
                    </template>

                    <template v-slot:item="{ item }">
                        <div v-if="!isSearching" class="d-flex align-center">

                            <BaseAvatar
                                :contain="false"
                                size="1.25rem"
                                :elevation="false"
                                :avatar="$store.state.allCountries.find(c => c.code === item.code)?.flag"
                            ></BaseAvatar>
                            <span class="text-sm color-black mr-1">{{ item.dial_code }}</span>
                        </div>
                    </template>
                </v-autocomplete>

                <v-divider vertical></v-divider>
                <WlBaseInput
                    type="number"
                    :bordered="false"
                    :name="inputName"
                    :disabled="disabled"
                    autocomplete="off"
                    :id="localId"
                    v-model.number="number"
                    :placeholder="setCountryPlaceholder"
                    class="px-2 w-full phone-input"
                    @keyup="$emit('keyup', number)"
                    @keydown="$emit('keydown')"
                    @focus="$emit('focus')"
                    @focusout="onFocusOut"
                    @keyup.enter="$emit('onEnter')"
                />
                <div v-if="loading" class="pr-1">
                    <v-progress-circular
                        indeterminate
                        size="20"
                        width="2"
                        color="#5152fb"
                    ></v-progress-circular>
                </div>
                <WlBaseButton
                    v-if="appendIcon"
                    @click="$emit('appendClick')"
                    class="btn-icon"
                >
                    <WlBaseIcon :name="appendIcon"></WlBaseIcon>
                </WlBaseButton>
                <WlBaseButton
                    v-if="removable"
                    @click="$emit('remove')"
                    class="h-auto btn-text mx-1"
                >
                    <WlBaseIcon name="times"></WlBaseIcon>
                </WlBaseButton>
            </div>
            <WlBaseErrorMessage :messages="errorMessages"></WlBaseErrorMessage>
        </div>
    </div>
</template>
<script>
import parsePhoneNumber, {getExampleNumber} from "libphonenumber-js";
import examples from "libphonenumber-js/examples.mobile.json";

import Helpers from "~/shared/mixins/Helpers";

import axios from "axios";

import {isEmpty} from "lodash";

export default {
    name: "WlBaseSelectPhone",

    mixins: [Helpers],

    props: {
        id: {
            default: null,
        },
        labelBottomOffset: {
            default: true,
        },
        labelClassVal: {
            default: "",
        },
        countryCode: {
            default: null,
        },
        placeholder: {
            default: null,
        },
        label: {
            default: null,
        },
        value: {
            default: null,
        },
        addStyles: {
            type: Object,
            default() {
                return {};
            },
        },
        type: {
            default: "text",
        },
        removable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            default: false,
        },
        appendIcon: {
            default: false,
        },
        appendContent: {
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        inputName: {
            default: null,
        },
        required: {
            default: false,
        },
        countryCodeFirst: {
            default: null,
        },
        errorMessages: {
            type: Array,
            default() {
                return [];
            },
        },
        lazy: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            localId: this.id || this.__makeId(),
            isSearching: false,
            lastKnownValue: null,

            searchValue: "",
            items: [
                {
                    name: "Afghanistan",
                    flag: "🇦🇫",
                    code: "AF",
                    dial_code: "+93",
                },
                {
                    name: "Albania",
                    flag: "🇦🇱",
                    code: "AL",
                    dial_code: "+355",
                },
                {
                    name: "Algeria",
                    flag: "🇩🇿",
                    code: "DZ",
                    dial_code: "+213",
                },
                {
                    name: "Andorra",
                    flag: "🇦🇩",
                    code: "AD",
                    dial_code: "+376",
                },
                {
                    name: "Angola",
                    flag: "🇦🇴",
                    code: "AO",
                    dial_code: "+244",
                },
                {
                    name: "Antigua and Barbuda",
                    flag: "🇦🇬",
                    code: "AG",
                    dial_code: "+1268",
                },
                {
                    name: "Argentina",
                    flag: "🇦🇷",
                    code: "AR",
                    dial_code: "+54",
                },
                {
                    name: "Armenia",
                    flag: "🇦🇲",
                    code: "AM",
                    dial_code: "+374",
                },
                {
                    name: "Australia",
                    flag: "🇦🇺",
                    code: "AU",
                    dial_code: "+61",
                },
                {
                    name: "Austria",
                    flag: "🇦🇹",
                    code: "AT",
                    dial_code: "+43",
                },
                {
                    name: "Azerbaijan",
                    flag: "🇦🇿",
                    code: "AZ",
                    dial_code: "+994",
                },
                {
                    name: "Bahamas",
                    flag: "🇧🇸",
                    code: "BS",
                    dial_code: "+1242",
                },
                {
                    name: "Bahrain",
                    flag: "🇧🇭",
                    code: "BH",
                    dial_code: "+973",
                },
                {
                    name: "Bangladesh",
                    flag: "🇧🇩",
                    code: "BD",
                    dial_code: "+880",
                },
                {
                    name: "Barbados",
                    flag: "🇧🇧",
                    code: "BB",
                    dial_code: "+1246",
                },
                {
                    name: "Belarus",
                    flag: "🇧🇾",
                    code: "BY",
                    dial_code: "+375",
                },
                {
                    name: "Belgium",
                    flag: "🇧🇪",
                    code: "BE",
                    dial_code: "+32",
                },
                {
                    name: "Belize",
                    flag: "🇧🇿",
                    code: "BZ",
                    dial_code: "+501",
                },
                {
                    name: "Benin",
                    flag: "🇧🇯",
                    code: "BJ",
                    dial_code: "+229",
                },
                {
                    name: "Bhutan",
                    flag: "🇧🇹",
                    code: "BT",
                    dial_code: "+975",
                },
                {
                    name: "Bolivia, Plurinational State of bolivia",
                    flag: "🇧🇴",
                    code: "BO",
                    dial_code: "+591",
                },
                {
                    name: "Bosnia and Herzegovina",
                    flag: "🇧🇦",
                    code: "BA",
                    dial_code: "+387",
                },
                {
                    name: "Botswana",
                    flag: "🇧🇼",
                    code: "BW",
                    dial_code: "+267",
                },
                {
                    name: "Brazil",
                    flag: "🇧🇷",
                    code: "BR",
                    dial_code: "+55",
                },
                {
                    name: "Brunei Darussalam",
                    flag: "🇧🇳",
                    code: "BN",
                    dial_code: "+673",
                },
                {
                    name: "Bulgaria",
                    flag: "🇧🇬",
                    code: "BG",
                    dial_code: "+359",
                },
                {
                    name: "Burkina Faso",
                    flag: "🇧🇫",
                    code: "BF",
                    dial_code: "+226",
                },
                {
                    name: "Burundi",
                    flag: "🇧🇮",
                    code: "BI",
                    dial_code: "+257",
                },
                {
                    name: "Cambodia",
                    flag: "🇰🇭",
                    code: "KH",
                    dial_code: "+855",
                },
                {
                    name: "Cameroon",
                    flag: "🇨🇲",
                    code: "CM",
                    dial_code: "+237",
                },
                {
                    name: "Canada",
                    flag: "🇨🇦",
                    code: "CA",
                    dial_code: "+1",
                },
                {
                    name: "Cape Verde",
                    flag: "🇨🇻",
                    code: "CV",
                    dial_code: "+238",
                },
                {
                    name: "Central African Republic",
                    flag: "🇨🇫",
                    code: "CF",
                    dial_code: "+236",
                },
                {
                    name: "Chad",
                    flag: "🇹🇩",
                    code: "TD",
                    dial_code: "+235",
                },
                {
                    name: "Chile",
                    flag: "🇨🇱",
                    code: "CL",
                    dial_code: "+56",
                },
                {
                    name: "China",
                    flag: "🇨🇳",
                    code: "CN",
                    dial_code: "+86",
                },
                {
                    name: "Colombia",
                    flag: "🇨🇴",
                    code: "CO",
                    dial_code: "+57",
                },
                {
                    name: "Comoros",
                    flag: "🇰🇲",
                    code: "KM",
                    dial_code: "+269",
                },
                {
                    name: "Congo",
                    flag: "🇨🇬",
                    code: "CG",
                    dial_code: "+242",
                },
                {
                    name: "Costa Rica",
                    flag: "🇨🇷",
                    code: "CR",
                    dial_code: "+506",
                },
                {
                    name: "Cote d'Ivoire",
                    flag: "🇨🇮",
                    code: "CI",
                    dial_code: "+225",
                },
                {
                    name: "Croatia",
                    flag: "🇭🇷",
                    code: "HR",
                    dial_code: "+385",
                },
                {
                    name: "Cuba",
                    flag: "🇨🇺",
                    code: "CU",
                    dial_code: "+53",
                },
                {
                    name: "Cyprus",
                    flag: "🇨🇾",
                    code: "CY",
                    dial_code: "+357",
                },
                {
                    name: "Denmark",
                    flag: "🇩🇰",
                    code: "DK",
                    dial_code: "+45",
                },
                {
                    name: "Djibouti",
                    flag: "🇩🇯",
                    code: "DJ",
                    dial_code: "+253",
                },
                {
                    name: "Dominica",
                    flag: "🇩🇲",
                    code: "DM",
                    dial_code: "+1767",
                },
                {
                    name: "Dominican Republic",
                    flag: "🇩🇴",
                    code: "DO",
                    dial_code: "+1849",
                },
                {
                    name: "Ecuador",
                    flag: "🇪🇨",
                    code: "EC",
                    dial_code: "+593",
                },
                {
                    name: "Egypt",
                    flag: "🇪🇬",
                    code: "EG",
                    dial_code: "+20",
                },
                {
                    name: "El Salvador",
                    flag: "🇸🇻",
                    code: "SV",
                    dial_code: "+503",
                },
                {
                    name: "Equatorial Guinea",
                    flag: "🇬🇶",
                    code: "GQ",
                    dial_code: "+240",
                },
                {
                    name: "Eritrea",
                    flag: "🇪🇷",
                    code: "ER",
                    dial_code: "+291",
                },
                {
                    name: "Estonia",
                    flag: "🇪🇪",
                    code: "EE",
                    dial_code: "+372",
                },
                {
                    name: "Ethiopia",
                    flag: "🇪🇹",
                    code: "ET",
                    dial_code: "+251",
                },
                {
                    name: "Fiji",
                    flag: "🇫🇯",
                    code: "FJ",
                    dial_code: "+679",
                },
                {
                    name: "Finland",
                    flag: "🇫🇮",
                    code: "FI",
                    dial_code: "+358",
                },
                {
                    name: "France",
                    flag: "🇫🇷",
                    code: "FR",
                    dial_code: "+33",
                },
                {
                    name: "Gabon",
                    flag: "🇬🇦",
                    code: "GA",
                    dial_code: "+241",
                },
                {
                    name: "Gambia",
                    flag: "🇬🇲",
                    code: "GM",
                    dial_code: "+220",
                },
                {
                    name: "Georgia",
                    flag: "🇬🇪",
                    code: "GE",
                    dial_code: "+995",
                },
                {
                    name: "Germany",
                    flag: "🇩🇪",
                    code: "DE",
                    dial_code: "+49",
                },
                {
                    name: "Ghana",
                    flag: "🇬🇭",
                    code: "GH",
                    dial_code: "+233",
                },
                {
                    name: "Greece",
                    flag: "🇬🇷",
                    code: "GR",
                    dial_code: "+30",
                },
                {
                    name: "Grenada",
                    flag: "🇬🇩",
                    code: "GD",
                    dial_code: "+1473",
                },
                {
                    name: "Guatemala",
                    flag: "🇬🇹",
                    code: "GT",
                    dial_code: "+502",
                },
                {
                    name: "Guinea",
                    flag: "🇬🇳",
                    code: "GN",
                    dial_code: "+224",
                },
                {
                    name: "Guinea-Bissau",
                    flag: "🇬🇼",
                    code: "GW",
                    dial_code: "+245",
                },
                {
                    name: "Guyana",
                    flag: "🇬🇾",
                    code: "GY",
                    dial_code: "+592",
                },
                {
                    name: "Haiti",
                    flag: "🇭🇹",
                    code: "HT",
                    dial_code: "+509",
                },
                {
                    name: "Honduras",
                    flag: "🇭🇳",
                    code: "HN",
                    dial_code: "+504",
                },
                {
                    name: "Hungary",
                    flag: "🇭🇺",
                    code: "HU",
                    dial_code: "+36",
                },
                {
                    name: "Iceland",
                    flag: "🇮🇸",
                    code: "IS",
                    dial_code: "+354",
                },
                {
                    name: "India",
                    flag: "🇮🇳",
                    code: "IN",
                    dial_code: "+91",
                },
                {
                    name: "Indonesia",
                    flag: "🇮🇩",
                    code: "ID",
                    dial_code: "+62",
                },
                {
                    name: "Iran, Islamic Republic of Persian Gulf",
                    flag: "🇮🇷",
                    code: "IR",
                    dial_code: "+98",
                },
                {
                    name: "Iraq",
                    flag: "🇮🇶",
                    code: "IQ",
                    dial_code: "+964",
                },
                {
                    name: "Ireland",
                    flag: "🇮🇪",
                    code: "IE",
                    dial_code: "+353",
                },
                {
                    name: "Israel",
                    flag: "🇮🇱",
                    code: "IL",
                    dial_code: "+972",
                },
                {
                    name: "Italy",
                    flag: "🇮🇹",
                    code: "IT",
                    dial_code: "+39",
                },
                {
                    name: "Jamaica",
                    flag: "🇯🇲",
                    code: "JM",
                    dial_code: "+1876",
                },
                {
                    name: "Japan",
                    flag: "🇯🇵",
                    code: "JP",
                    dial_code: "+81",
                },
                {
                    name: "Jordan",
                    flag: "🇯🇴",
                    code: "JO",
                    dial_code: "+962",
                },
                {
                    name: "Kazakhstan",
                    flag: "🇰🇿",
                    code: "KZ",
                    dial_code: "+7",
                },
                {
                    name: "Kenya",
                    flag: "🇰🇪",
                    code: "KE",
                    dial_code: "+254",
                },
                {
                    name: "Kiribati",
                    flag: "🇰🇮",
                    code: "KI",
                    dial_code: "+686",
                },
                {
                    name: "Korea, Democratic People's Republic of Korea",
                    flag: "🇰🇵",
                    code: "KP",
                    dial_code: "+850",
                },
                {
                    name: "Korea, Republic of South Korea",
                    flag: "🇰🇷",
                    code: "KR",
                    dial_code: "+82",
                },
                {
                    name: "Kuwait",
                    flag: "🇰🇼",
                    code: "KW",
                    dial_code: "+965",
                },
                {
                    name: "Kyrgyzstan",
                    flag: "🇰🇬",
                    code: "KG",
                    dial_code: "+996",
                },
                {
                    name: "Laos",
                    flag: "🇱🇦",
                    code: "LA",
                    dial_code: "+856",
                },
                {
                    name: "Latvia",
                    flag: "🇱🇻",
                    code: "LV",
                    dial_code: "+371",
                },
                {
                    name: "Lebanon",
                    flag: "🇱🇧",
                    code: "LB",
                    dial_code: "+961",
                },
                {
                    name: "Lesotho",
                    flag: "🇱🇸",
                    code: "LS",
                    dial_code: "+266",
                },
                {
                    name: "Liberia",
                    flag: "🇱🇷",
                    code: "LR",
                    dial_code: "+231",
                },
                {
                    name: "Libyan Arab Jamahiriya",
                    flag: "🇱🇾",
                    code: "LY",
                    dial_code: "+218",
                },
                {
                    name: "Liechtenstein",
                    flag: "🇱🇮",
                    code: "LI",
                    dial_code: "+423",
                },
                {
                    name: "Lithuania",
                    flag: "🇱🇹",
                    code: "LT",
                    dial_code: "+370",
                },
                {
                    name: "Luxembourg",
                    flag: "🇱🇺",
                    code: "LU",
                    dial_code: "+352",
                },
                {
                    name: "Macedonia",
                    flag: "🇲🇰",
                    code: "MK",
                    dial_code: "+389",
                },
                {
                    name: "Madagascar",
                    flag: "🇲🇬",
                    code: "MG",
                    dial_code: "+261",
                },
                {
                    name: "Malawi",
                    flag: "🇲🇼",
                    code: "MW",
                    dial_code: "+265",
                },
                {
                    name: "Malaysia",
                    flag: "🇲🇾",
                    code: "MY",
                    dial_code: "+60",
                },
                {
                    name: "Maldives",
                    flag: "🇲🇻",
                    code: "MV",
                    dial_code: "+960",
                },
                {
                    name: "Mali",
                    flag: "🇲🇱",
                    code: "ML",
                    dial_code: "+223",
                },
                {
                    name: "Malta",
                    flag: "🇲🇹",
                    code: "MT",
                    dial_code: "+356",
                },
                {
                    name: "Marshall Islands",
                    flag: "🇲🇭",
                    code: "MH",
                    dial_code: "+692",
                },
                {
                    name: "Mauritania",
                    flag: "🇲🇷",
                    code: "MR",
                    dial_code: "+222",
                },
                {
                    name: "Mauritius",
                    flag: "🇲🇺",
                    code: "MU",
                    dial_code: "+230",
                },
                {
                    name: "Mexico",
                    flag: "🇲🇽",
                    code: "MX",
                    dial_code: "+52",
                },
                {
                    name: "Micronesia, Federated States of Micronesia",
                    flag: "🇫🇲",
                    code: "FM",
                    dial_code: "+691",
                },
                {
                    name: "Moldova",
                    flag: "🇲🇩",
                    code: "MD",
                    dial_code: "+373",
                },
                {
                    name: "Monaco",
                    flag: "🇲🇨",
                    code: "MC",
                    dial_code: "+377",
                },
                {
                    name: "Mongolia",
                    flag: "🇲🇳",
                    code: "MN",
                    dial_code: "+976",
                },
                {
                    name: "Montenegro",
                    flag: "🇲🇪",
                    code: "ME",
                    dial_code: "+382",
                },
                {
                    name: "Morocco",
                    flag: "🇲🇦",
                    code: "MA",
                    dial_code: "+212",
                },
                {
                    name: "Mozambique",
                    flag: "🇲🇿",
                    code: "MZ",
                    dial_code: "+258",
                },
                {
                    name: "Myanmar",
                    flag: "🇲🇲",
                    code: "MM",
                    dial_code: "+95",
                },
                {
                    name: "Namibia",
                    flag: "🇳🇦",
                    code: "NA",
                    dial_code: "+264",
                },
                {
                    name: "Nauru",
                    flag: "🇳🇷",
                    code: "NR",
                    dial_code: "+674",
                },
                {
                    name: "Nepal",
                    flag: "🇳🇵",
                    code: "NP",
                    dial_code: "+977",
                },
                {
                    name: "Netherlands",
                    flag: "🇳🇱",
                    code: "NL",
                    dial_code: "+31",
                },
                {
                    name: "New Zealand",
                    flag: "🇳🇿",
                    code: "NZ",
                    dial_code: "+64",
                },
                {
                    name: "Nicaragua",
                    flag: "🇳🇮",
                    code: "NI",
                    dial_code: "+505",
                },
                {
                    name: "Niger",
                    flag: "🇳🇪",
                    code: "NE",
                    dial_code: "+227",
                },
                {
                    name: "Nigeria",
                    flag: "🇳🇬",
                    code: "NG",
                    dial_code: "+234",
                },
                {
                    name: "Norway",
                    flag: "🇳🇴",
                    code: "NO",
                    dial_code: "+47",
                },
                {
                    name: "Oman",
                    flag: "🇴🇲",
                    code: "OM",
                    dial_code: "+968",
                },
                {
                    name: "Pakistan",
                    flag: "🇵🇰",
                    code: "PK",
                    dial_code: "+92",
                },
                {
                    name: "Palau",
                    flag: "🇵🇼",
                    code: "PW",
                    dial_code: "+680",
                },
                {
                    name: "Panama",
                    flag: "🇵🇦",
                    code: "PA",
                    dial_code: "+507",
                },
                {
                    name: "Papua New Guinea",
                    flag: "🇵🇬",
                    code: "PG",
                    dial_code: "+675",
                },
                {
                    name: "Paraguay",
                    flag: "🇵🇾",
                    code: "PY",
                    dial_code: "+595",
                },
                {
                    name: "Peru",
                    flag: "🇵🇪",
                    code: "PE",
                    dial_code: "+51",
                },
                {
                    name: "Philippines",
                    flag: "🇵🇭",
                    code: "PH",
                    dial_code: "+63",
                },
                {
                    name: "Poland",
                    flag: "🇵🇱",
                    code: "PL",
                    dial_code: "+48",
                },
                {
                    name: "Portugal",
                    flag: "🇵🇹",
                    code: "PT",
                    dial_code: "+351",
                },
                {
                    name: "Qatar",
                    flag: "🇶🇦",
                    code: "QA",
                    dial_code: "+974",
                },
                {
                    name: "Romania",
                    flag: "🇷🇴",
                    code: "RO",
                    dial_code: "+40",
                },
                {
                    name: "Russia",
                    flag: "🇷🇺",
                    code: "RU",
                    dial_code: "+7",
                },
                {
                    name: "Rwanda",
                    flag: "🇷🇼",
                    code: "RW",
                    dial_code: "+250",
                },
                {
                    name: "Saint Lucia",
                    flag: "🇱🇨",
                    code: "LC",
                    dial_code: "+1758",
                },
                {
                    name: "Saint Vincent and the Grenadines",
                    flag: "🇻🇨",
                    code: "VC",
                    dial_code: "+1784",
                },
                {
                    name: "Samoa",
                    flag: "🇼🇸",
                    code: "WS",
                    dial_code: "+685",
                },
                {
                    name: "San Marino",
                    flag: "🇸🇲",
                    code: "SM",
                    dial_code: "+378",
                },
                {
                    name: "Sao Tome and Principe",
                    flag: "🇸🇹",
                    code: "ST",
                    dial_code: "+239",
                },
                {
                    name: "Saudi Arabia",
                    flag: "🇸🇦",
                    code: "SA",
                    dial_code: "+966",
                },
                {
                    name: "Senegal",
                    flag: "🇸🇳",
                    code: "SN",
                    dial_code: "+221",
                },
                {
                    name: "Serbia",
                    flag: "🇷🇸",
                    code: "RS",
                    dial_code: "+381",
                },
                {
                    name: "Seychelles",
                    flag: "🇸🇨",
                    code: "SC",
                    dial_code: "+248",
                },
                {
                    name: "Sierra Leone",
                    flag: "🇸🇱",
                    code: "SL",
                    dial_code: "+232",
                },
                {
                    name: "Singapore",
                    flag: "🇸🇬",
                    code: "SG",
                    dial_code: "+65",
                },
                {
                    name: "Slovakia",
                    flag: "🇸🇰",
                    code: "SK",
                    dial_code: "+421",
                },
                {
                    name: "Slovenia",
                    flag: "🇸🇮",
                    code: "SI",
                    dial_code: "+386",
                },
                {
                    name: "Solomon Islands",
                    flag: "🇸🇧",
                    code: "SB",
                    dial_code: "+677",
                },
                {
                    name: "Somalia",
                    flag: "🇸🇴",
                    code: "SO",
                    dial_code: "+252",
                },
                {
                    name: "South Africa",
                    flag: "🇿🇦",
                    code: "ZA",
                    dial_code: "+27",
                },
                {
                    name: "South Sudan",
                    flag: "🇸🇸",
                    code: "SS",
                    dial_code: "+211",
                },
                {
                    name: "Spain",
                    flag: "🇪🇸",
                    code: "ES",
                    dial_code: "+34",
                },
                {
                    name: "Sri Lanka",
                    flag: "🇱🇰",
                    code: "LK",
                    dial_code: "+94",
                },
                {
                    name: "Sudan",
                    flag: "🇸🇩",
                    code: "SD",
                    dial_code: "+249",
                },
                {
                    name: "Suriname",
                    flag: "🇸🇷",
                    code: "SR",
                    dial_code: "+597",
                },
                {
                    name: "Sweden",
                    flag: "🇸🇪",
                    code: "SE",
                    dial_code: "+46",
                },
                {
                    name: "Switzerland",
                    flag: "🇨🇭",
                    code: "CH",
                    dial_code: "+41",
                },
                {
                    name: "Syrian Arab Republic",
                    flag: "🇸🇾",
                    code: "SY",
                    dial_code: "+963",
                },
                {
                    name: "Tajikistan",
                    flag: "🇹🇯",
                    code: "TJ",
                    dial_code: "+992",
                },
                {
                    name: "Tanzania, United Republic of Tanzania",
                    flag: "🇹🇿",
                    code: "TZ",
                    dial_code: "+255",
                },
                {
                    name: "Thailand",
                    flag: "🇹🇭",
                    code: "TH",
                    dial_code: "+66",
                },
                {
                    name: "Timor-Leste",
                    flag: "🇹🇱",
                    code: "TL",
                    dial_code: "+670",
                },
                {
                    name: "Togo",
                    flag: "🇹🇬",
                    code: "TG",
                    dial_code: "+228",
                },
                {
                    name: "Tonga",
                    flag: "🇹🇴",
                    code: "TO",
                    dial_code: "+676",
                },
                {
                    name: "Trinidad and Tobago",
                    flag: "🇹🇹",
                    code: "TT",
                    dial_code: "+1868",
                },
                {
                    name: "Tunisia",
                    flag: "🇹🇳",
                    code: "TN",
                    dial_code: "+216",
                },
                {
                    name: "Turkey",
                    flag: "🇹🇷",
                    code: "TR",
                    dial_code: "+90",
                },
                {
                    name: "Turkmenistan",
                    flag: "🇹🇲",
                    code: "TM",
                    dial_code: "+993",
                },
                {
                    name: "Tuvalu",
                    flag: "🇹🇻",
                    code: "TV",
                    dial_code: "+688",
                },
                {
                    name: "Uganda",
                    flag: "🇺🇬",
                    code: "UG",
                    dial_code: "+256",
                },
                {
                    name: "Ukraine",
                    flag: "🇺🇦",
                    code: "UA",
                    dial_code: "+380",
                },
                {
                    name: "United Arab Emirates",
                    flag: "🇦🇪",
                    code: "AE",
                    dial_code: "+971",
                },
                {
                    name: "United Kingdom",
                    flag: "🇬🇧",
                    code: "GB",
                    dial_code: "+44",
                },
                {
                    name: "United States",
                    flag: "🇺🇸",
                    code: "US",
                    dial_code: "+1",
                },
                {
                    name: "Uruguay",
                    flag: "🇺🇾",
                    code: "UY",
                    dial_code: "+598",
                },
                {
                    name: "Uzbekistan",
                    flag: "🇺🇿",
                    code: "UZ",
                    dial_code: "+998",
                },
                {
                    name: "Vanuatu",
                    flag: "🇻🇺",
                    code: "VU",
                    dial_code: "+678",
                },
                {
                    name: "Venezuela, Bolivarian Republic of Venezuela",
                    flag: "🇻🇪",
                    code: "VE",
                    dial_code: "+58",
                },
                {
                    name: "Vietnam",
                    flag: "🇻🇳",
                    code: "VN",
                    dial_code: "+84",
                },
                {
                    name: "Yemen",
                    flag: "🇾🇪",
                    code: "YE",
                    dial_code: "+967",
                },
                {
                    name: "Zambia",
                    flag: "🇿🇲",
                    code: "ZM",
                    dial_code: "+260",
                },
                {
                    name: "Zimbabwe",
                    flag: "🇿🇼",
                    code: "ZW",
                    dial_code: "+263",
                },
            ],
            code: null,
            number: "",
            location: null,
            selectCountryCode: null,
            setCountryPlaceholder: null,
            init: false,
        };
    },

    computed: {
        val() {
            return `${this.code || ""}${this.number}`;
        },
        selectCountry() {
            const el = this.items.find((item) => item.dial_code === this.code) || {};
            return el.code || null;
        },
    },

    watch: {
        val() {
            if (this.init) {
                if (!this.lazy) {
                    this.validate();   
                }
            } else {
                this.init = true;
            }
            this.setPlaceholderByCountry(this.selectCountry);
            this.$emit(
                "input",
                this.val && this.val[0] !== "+" ? "+" + this.val : this.val
            );
            this.$emit("code", this.code);
        },
        countryCode(code) {
            if (code) {
                this.findCode(code);
            }
        },
        value() {
            if (this.value !== this.val) {
                this.setCodeAndNumber();
            }
        },
        location() {
            this.setCodeByLocation();
        },
        selectCountryCode() {
            const el =
                this.items.find((item) => item.code === this.selectCountryCode) || {};
            if (el) {
                this.code = el.dial_code;
            }
            this.setPlaceholderByCountry(this.selectCountryCode);
        },
    },

    mounted() {
        this.keypressForNumber();
    },

    methods: {

        findCode(code) {
            const el = this.items.find((item) => item.code === code);
            if (el) {
                this.selectCountryCode = el.code;
                this.code = el.dial_code;

                this.lastKnownValue = el.code;
            }
        },
        search(value) {
            if (value) {
                const filtered = value.replace(/./g, "");

                if (value !== filtered) {
                    this.searchValue = filtered;
                } else {
                    this.searchValue = value;

                    this.isSearching = true;
                }

                this.$refs.autocomplete.lazySearch = filtered;
            }
        },
        input(value) {
            if (!value) {
                this.selectCountryCode = this.lastKnownValue;
                this.$refs.autocomplete.lazyValue = this.lastKnownValue;
            } else {
                this.lastKnownValue = this.selectCountryCode;
            }

            this.isSearching = false;
        },
        validate() {
            const phoneNumber = parsePhoneNumber(this.val, this.selectCountry);
            if (phoneNumber) {
                if (phoneNumber.isValid() || this.val === this.code) {
                    this.$emit("valid", {valid: true});
                } else {
                    this.$emit("valid", {
                        valid: false,
                        message: "The Phone field is not correct",
                    });
                }
            } else if (this.required) {
                this.$emit("valid", {
                    valid: false,
                    message: "The Phone field is required",
                });
            } else {
                if (!this.val || this.val === this.code) {
                    this.$emit("valid", {valid: true});
                } else {
                    this.$emit("valid", {
                        valid: false,
                        message: "The Phone field is not correct",
                    });
                }
            }
        },
        setCodeAndNumber() {
            if (this.value) {
                this.code = null;
                this.number = this.value.replace(/[^0-9,.]+/g, "");
                let val = this.value.replace("+", "");
                let find = this.items.find(
                    (item) =>
                        val.substring(0, 4) === item.dial_code.replace("+", "") ||
                        val.substring(0, 3) === item.dial_code.replace("+", "") ||
                        val.substring(0, 2) === item.dial_code.replace("+", "") ||
                        val.substring(0, 1) === item.dial_code.replace("+", "")
                );
                if (find) {
                    this.selectCountryCode = find.code;
                    this.code = find.dial_code;
                    this.number = val
                        .substring(find.dial_code.length - 1, val.length)
                        .replace(/[^0-9,.]+/g, "");
                    this.lastKnownValue = find.code;
                } else {
                    this.setCodeByLocation();
                }
            } else if (
                !isEmpty(this.countryCodeFirst) ||
                !isEmpty(this.countryCode)
            ) {
                this.findCode(this.countryCodeFirst || this.countryCode);
            } else {
                this.setCodeByLocation();
            }
        },
        setCodeByLocation() {
            if (!this.code && this.location) {
                let find = this.items.find(
                    (item) => this.location.country === item.code
                );
                if (find) {
                    let val = this.value ? this.value.replace("+", "") : "";
                    this.selectCountryCode = find.code;
                    this.code = find.dial_code;
                    this.number = val.substring(find.dial_code.length - 1, val.length);

                    this.lastKnownValue = find.code;
                } else {
                    this.number = this.value;
                }
            }
        },
        clear() {
            this.number = "";
        },
        setPlaceholderByCountry(countryCode) {
            if (countryCode && !this.placeholder) {
                const phoneNumber = getExampleNumber(countryCode, examples);
                this.setCountryPlaceholder =
                    phoneNumber.nationalNumber || this.placeholder;
            } else {
                this.setCountryPlaceholder = this.placeholder;
            }
        },
        onFocusOut() {
            if (this.lazy) {
                this.validate();
            }
            this.$emit('focusout');
        }
    },

    created() {
        this.setCodeAndNumber();
        axios.get("https://ipinfo.io?token=ipinfo.io").then((response) => {
            this.location = response.data;
        });
    },
};
</script>

<style lang="scss" scoped>
.v-select.v-select--is-menu-active .v-input__append-inner .animation-rotate,
.v-select.v-select--is-menu-active .v-input__icon--append .animation-rotate {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}
</style>

<style lang="scss">
.base-select-phone {
    max-width: 150px;

    .v-input__slot {
        padding: 0 16px !important;
    }
}
</style>
