import _ from "lodash";
import axios from "axios";
import {__changeRoute, __event, __initResource, __querySerialize} from "~/utils/functions";
import router from "@/routes";
export const namespaced = true;

export const state = {
    initialized: false,
    userFirstInitialized: false,
    userSecondInitialized: false,
    walletInitialized: false,
    loading: false,
    forceLoading: false,
    pageDataLoading: false,
    pageData: {},
    pageDataPath: '/data/'
};

export const mutations = {
    SET_LOADING(state, loading){
        state.loading = loading;
    },
    SET_FORCE_LOADING(state, loading){
        state.forceLoading = loading;
    },
    SET_PAGE_LOADING(state, loading){
        state.pageDataLoading = loading;
    },
    PUSH_PAGE_DATA(state, payload){
        for (const [key, value] of Object.entries(payload)) {
            state.pageData[_.camelCase(key)] = value;
        }
    },
    CLEAR_PAGE_DATA(state){
        state.pageData = {};
    },
    SET_INITIALIZED(state, value){
        state.initialized = value;
        if (value){
            __event('stateInitialized');
        }else{
            state.userFirstInitialized = false;
            state.userSecondInitialized = false;
            state.walletInitialized = false;
        }
    },
}

export const actions  = {
    __setStateData({commit}, data){
        for (const [objKey, objValue] of Object.entries(data)) {
            const val = objValue && 'object' === typeof objValue && !Array.isArray(objValue) ? {...objValue} : objValue;
            commit('setData', {key: _.camelCase(objKey), data: val}, {root: true});
        }
    },
    __resource(context, data) {
        return __initResource(data.resources, data.name);
    },
    __disableUserLoading({state, commit}, name){
        state[name] = true;
        if (state.walletInitialized && state.userFirstInitialized && state.userSecondInitialized){
            commit('SET_LOADING', false);
            commit('SET_INITIALIZED', true);
        }
    },
    __initialize({ commit, dispatch }, payload) {
        let additionalParams = payload.additionalParams ?? {};
        let resources = { resources: payload.resources?.join(',') };
        let mergedParams = {...resources, ...additionalParams};

        return axios.get(payload.url, {
            params: mergedParams
        })
    },
    __changeOrganization({commit, dispatch, rootState}, payload = {}){
        if (payload.id && payload.id !== rootState.organization?.id){
            commit('SET_LOADING', true);
            const requestRoute = `${rootState.path}change-organization/${payload.id}`;
            const serialized = payload.query? __querySerialize(payload.query): null;
            const redirectRoute = `${payload.route || '/'}${serialized? '&': ''}${serialized || ''}`
            return axios.post(requestRoute, {params: {query: payload.query}})
                .then((response) => {
                        if(response.data.success){
                            dispatch('__initState');
                            __changeRoute(router, redirectRoute);
                        }else{
                            commit('SET_LOADING', true);
                        }
                    }
                )
                .catch((error) => {
                    console.log(error);
                })
        }
    },
    async __initState({commit, dispatch, rootGetters}) {
        commit('SET_INITIALIZED', false);
        dispatch('__clearState', {}, {root: true});
        return rootGetters['auth/__isAuth']?
            await dispatch('__initAuth'):
            await dispatch('__initGuest')
    },
    async __initAuth({dispatch}){
        await dispatch('__initUser');
        await dispatch('__initWalletState');
        await dispatch('__initUserSecond');
    },
    __initUser({commit, dispatch}){
        commit('SET_LOADING', true);
        dispatch('__initialize', {url: `/initialization/user`}).then((response) => {
                if (response.status === 200){
                    dispatch('__resource', {resources: response.data, name: 'DefaultData'})
                        .then(r => dispatch('__setStateData', r));
                }else{
                    commit('SET_LOADING', false)
                }
        }).catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch('__disableUserLoading', 'userFirstInitialized');
        });
    },
    __initUserSecond({commit, dispatch}){
        commit('SET_LOADING', true);
        dispatch('__initialize', {url: `/initialization/user/second`}).then((response) => {
                if (response.status === 200){
                    dispatch('__resource', {resources: response.data, name: 'DefaultData'})
                        .then(r => {
                            dispatch('__setStateData', r);
                            dispatch('hubspot/trackVisitor', {}, { root: true });
                        });
                }else{
                    commit('SET_LOADING', false)
                }
        }).catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatch('__disableUserLoading', 'userSecondInitialized');
        });
    },
    __initWalletState({commit, dispatch, rootState}){
        commit('SET_LOADING', true);
        dispatch('__initialize', {
                url: `/initialization`,//or add also ?organization_id=3
                resources: [
                    'NavigationMenuItems',
                    'AvailableMenuItems',
                    'PayoneerParams',
                    'KYC',
                    'TFA',
                    'LegalEntityStatus',
                    'CanOpenMenuDropdown',
                    'WalletCurrencyDetails',//TODO remove with the old cards UI
                    'HolidayNotify'
                ]
            }).then((response) => {
                if (response.status === 200){
                    /*todo: check*/
                    dispatch('cardService/checkCustomerStatus', {service: 'unlimint', organization_id: rootState.authUser.current_organization_id}, {root: true})

                    /*Dear data from api*/
                    dispatch('__resource', {resources: response.data, name: 'NavigationMenuItems'})
                        .then(r => dispatch('globalMenuModule/setNavigationMenuItems', r, {root: true}));
                    dispatch('__resource', {resources: response.data, name: 'AvailableMenuItems'})
                        .then(r => dispatch('globalMenuModule/setAvailableMenuItems', r, {root: true}));
                    dispatch('__resource', {resources: response.data, name: 'PayoneerParams'})
                        .then(r => dispatch('globalMenuModule/setPayoneerParams', r, {root: true}));
                    dispatch('__resource', {resources: response.data, name: 'LegalEntityStatus'})
                        .then(r => dispatch('globalMenuModule/setLegalEntityData', r, {root: true}));
                    dispatch('__resource', {resources: response.data, name: 'KYC'})
                        .then(r => dispatch('kyc/__setKYCData', r, {root: true}));
                    dispatch('__resource', {resources: response.data, name: 'TFA'})
                        .then(r => dispatch('wlSecurity2fa/__setTFAData', r, {root: true}));
                    dispatch('__resource', {resources: response.data, name: 'TFA'})
                        .then(r => dispatch('wlSecurity2fa/__setTFAData', r, {root: true}));
                    dispatch('__resource', {resources: response.data, name: 'CanOpenMenuDropdown'})
                        .then(r => dispatch('globalMenuModule/setCanOpenMenu', r, {root: true}));
                    dispatch('__resource', {resources: response.data, name: 'WalletCurrencyDetails'})
                        .then(r => dispatch('dashboard/setWalletCurrencyDetails', r, {root: true}));
                    dispatch('__resource', {resources: response.data, name: 'HolidayNotify'})
                        .then(r => dispatch('globalMenuModule/setHolidayNotifyModal', r, {root: true}));
                }else{
                    commit('SET_LOADING', false)
                }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            dispatch('__disableUserLoading', 'walletInitialized');
        });
    },
    __initGuest({commit, dispatch}){
        commit('SET_LOADING', true);
        dispatch('__initialize', {url: `/initialization/guest`}).then((response) => {
            if (response.status === 200){
                dispatch('__resource', {resources: response.data, name: 'DefaultData'})
                    .then(r => dispatch('__setStateData', r));
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            commit('SET_LOADING', false);
            commit('SET_INITIALIZED', true);
        });
    },
    __initPageRequest({commit, state}, {path, query}){
        commit('SET_PAGE_LOADING', true);
        axios.get(path, {params: query})
            .then(response => {
                commit('PUSH_PAGE_DATA', response.data);
                commit('SET_PAGE_LOADING', false);
            })
            .catch(() => {
                commit('SET_PAGE_LOADING', false);
                console.error('Data not loaded');
            });
    },
    __initPage({commit, state, dispatch}, route){
        const routeData = route.meta.data;
        const requestData = {path: null, query: {}};
        if (!routeData){
            return;
        }

        if (routeData === true){
            const currentRoutePath = route.path;
            const fullPath = currentRoutePath[0] === '/' ? currentRoutePath.substring(1) : currentRoutePath;
            requestData.path = `${state.pageDataPath}${fullPath}`
            requestData.query = route.query;
        }else if(typeof routeData === 'object') {
            requestData.path = routeData.apiPath || null;
            requestData.query = routeData.apiQuery || {};
            commit('PUSH_PAGE_DATA', routeData.pageData);
        }

        if (requestData.path){
            dispatch('__initPageRequest', requestData)
        }
    }
};
