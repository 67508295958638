<template>
    <div class="file-input-wrapper position-relative">
        <label
            class="btn btn-grey-outline text-small font-medium color-purple"
        >
            <BaseIcon name="upload" class="color-purple mr-1"/>
            Upload Contract

            <v-file-input
                :prepend-icon="null"
                hide-input
                :multiple="multiple"
                v-model="val"
            >
            </v-file-input>
        </label>
    </div>
</template>

<script>

export default {
    name: "BaseFileInput",
    props: {
        value: {
            default: null
        },
        multiple: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            val: this.multiple && !this.value? []: this.value
        };
    },
    watch: {
        value(){
            this.val = this.value;
        },
        val(){
            this.$emit('input', this.val);
        }
    }
};
</script>
