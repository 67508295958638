<template>
  <div
    @click="handleClick"
    class="base-toggle-switch"
    :class="{ active: isActive }"
  >
      <p
          v-if="options.left"
          class="text-base mr-2"
          :class="[isActive ? 'color-dark-grey' : 'color-black']"
      >
      {{ options.left }}
    </p>
    <div class="switcher-container double-label" :class="{ active: isActive }">
      <div></div>
    </div>

      <p
          v-if="options.right"
          class="text-base ml-2"
          :class="[isActive ? 'color-black' : 'color-dark-grey']"
      >
      {{ options.right }}
    </p>
  </div>
</template>

<script>
export default {
  name: "WlBaseSwitch",

  props: {
    value: { type: String, default: "" },
    options: {
      type: Object,
      default: () => ({}),
    },
    hasOnlyLeftLabel: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isActive() {
      return this.value === this.options.right;
    },
  },

  methods: {
    handleClick() {
      const newValue = this.isActive ? this.options.left : this.options.right;
      this.$emit("input", newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-toggle-switch {
  cursor: pointer;
  display: flex;
  align-items: center;

  & > span {
    margin-top: 2px;
  }

  & > .switcher-container {
    width: 100%;
    min-width: 32px;
    max-width: 32px;
    height: 20px;
    border-radius: 100px;
    position: relative;
    transition: all 0.3s linear;
    background-color: var(--typography-light-grey-2-color);

    &.active {
      background-color: var(--main-purple-color);

      div {
        transform: translate(75%, -50%);
      }
    }

    div {
      position: absolute;
      height: 16px;
      width: 16px;
      top: 50%;
      left: 2px;
      transform: translate(0, -50%);
      border-radius: 50%;
      background-color: white;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      filter: drop-shadow(0px 0.5px 2.004px rgba(0, 0, 0, 0.15));
    }

    &.double-label {
      background-color: #d7e0ff;

      div {
        background-color: var(--main-purple-color);
      }
    }
  }
}
</style>
