export default class chatWidgetConfig{
    resolve() {
        window.hsConversationsSettings = {
            loadImmediately: false,
            inlineEmbedSelector: '',
            enableWidgetCookieBanner: false,
            disableAttachment: false
        };

        window.initSupportChat = () => { window.HubSpotConversations?.widget.load(); }
        window.openSupportChat = () => { window.HubSpotConversations?.widget.open(); }
        window.removeSupportChat = () => { window.HubSpotConversations?.widget.remove(); }
        window.showSupportChat = window.initSupportChat;
        // not load hubspot in iframe
        if (inIframe()) {
            return window.removeSupportChat();
        }
        window.HubSpotConversations?
            window.initSupportChat():
            window.hsConversationsOnReady = [() => { window.initSupportChat(); }];
    }
}

function inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}
