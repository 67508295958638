<!-- @format -->
<template>
    <v-dialog
        :value="dialog"
        @click:outside="closeModal"
        content-class="custom-modal modal-md"
    >
        <div class="modal-container">
            <div class="modal-header">
                <WlBaseButton
                    @click="closeModal"
                    class="btn-icon-sm btn-close-modal ml-auto"
                >
                    <BaseIcon name="times" class="text-lg" />
                </WlBaseButton>
            </div>
            <div class="modal-body">
                <img
                    :src="require('~/assets/images/documents/documents-intro.svg')"
                    class="documents-intro"
                />
                <h3 class="heading-3 font-bold color-black mb-12 mt-4">
                    Pending mobility request
                </h3>
                <p class="text-md description-text-color">
                    It looks like you have a pending mobility request! To ensure a swift
                    and hassle-free experience, we kindly remind you to continue the
                    process by completing the form. Action required:
                </p>
                <p class="text-md description-text-color mt-5">
                    <span class="font-bold">1. Add documents:</span> Upload necessary
                    files.
                </p>
                <p class="text-md description-text-color mt-4">
                    <span class="font-bold">2. Payment setup:</span> Securely input your
                    card details or top up your wallet.
                </p>
            </div>
            <div class="modal-footer">
                <WlBaseButton type="primary" class="w-full mb-3"  @click="redirectToMobility()">
                    Complete my request
                </WlBaseButton>
                <WlBaseButton type="secondary" class="w-full" @click="closeModal"> Later </WlBaseButton>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'ModalPendingMobilityRequest',
    data() {
        return {
            dialog: !this.$cookie.get('ModalPendingMobilityRequest'),
        };
    },
    computed: {
        ...mapGetters('cases', ['getDraftItem']),
    },
    methods: {
        closeModal() {
          this.$cookie.set('ModalPendingMobilityRequest', 1, 1);
          this.dialog = false;
        },
        redirectToMobility() {
            window.location.href = `${this.$store.state.path}mobility/?draft=${this.getDraftItem}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.description-text-color {
    color: #414243;
}
</style>
