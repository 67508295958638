import router from "@/routes";

const acceptPayment = () => import('~/modules/guest/views/AcceptPayment.vue');
const acceptPaymentStripe = () => import('~/modules/guest/views/AcceptPaymentStripe.vue');
const Payment = () => import('~/modules/guest/views/Payment.vue');
const TrialAccountWizard = () => import('~/modules/guest/views/TrialAccount/TrialAccountWizard.vue');
const MSAWizard = () => import('~/modules/guest/views/MSAWizard/MSAWizard.vue');
const EorWizard = () => import('~/shared/components/EorWizard/Wizard');

export default [
    {
        path: '/accept-payment',
        name: 'acceptPayment',
        component: acceptPayment,
        meta: {
            title: 'Accept Payment',
            app: 'empty',
        },
    },
    {
        path: '/accept-sca-payment/stripe',
        name: 'acceptPaymentStripe',
        component: acceptPaymentStripe,
        meta: {
            title: 'Accept Payment',
            app: 'empty',
        },
    },
    {
        path: '/verify',
        name: 'verifyToken',
        meta: {
            middleware: ['guest', 'verifyEmail']
        },
    },
    {
        path: '/eor-invoice/:token',
        name: 'DraftEorInvoice',
        component: () => import('~/modules/guest/views/EorInvoice/DraftEorInvoice.vue'),
        meta: {
            title: 'Eor invoice',
            app: 'empty',
            middleware: ['eorInvoiceGuest']
        },
    },
    {
        path: '/invoice/pay-view/:id',
        name: 'invoicePayView',
        component: Payment,
        meta: {
            title: 'Pay invoice',
            app: 'empty',
            middleware: ['invoiceGuestPay']
        },
    },
    {
        path: '/payment-request/non-logged-in-pay-view/:id',
        name: 'paymentRequestPayView',
        component: Payment,
        meta: {
            title: 'Pay payment request',
            app: 'empty',
            middleware: ['paymentRequestGuestPay']
        },
    },
    {
        path: '/trial-account/bdm',
        alias: ['/trial-account/brochure'],
        name: 'bdm',
        component: TrialAccountWizard,
        meta: {
            title: 'Trial account',
            app: 'empty',
            middleware: ['guest', 'tracking']
        },
    },
    {
        path: '/business-account',
        alias: ['/business-account/deposits', '/business-account/invoice-payment', '/business-account/invoice-payment/deposits'],
        name: 'msaWizard',
        component: TrialAccountWizard,
        meta: {
            title: 'Trial account',
            app: 'empty',
            middleware: ['guest', 'tracking', 'businessAccount']
        },
    },
    {
        path: '/business-with-msa',
        alias: ['/business-with-msa/deposits'],
        name: 'msaWizard',
        component: MSAWizard,
        meta: {
            title: 'MSA contracts wizard',
            app: 'empty',
            middleware: ['guest', 'tracking']
        },
    },
    {
        path: '/eor/self-serve',
        name: 'eorSelfServe',
        component: EorWizard,
        meta: {
            title: 'Self-serve contracts wizard',
            app: 'empty',
            middleware: ['guest', 'tracking', 'eorSelfServe']
        },
    },
];
