import axios from "axios";

export const namespaced = true;


export const state = {
    availableMenu: [],
    features: [],
    payoneerParams: null,
    legalEntityData: null,
    menuValue: false,
    profileMenuValue: false,
    notificationMenuValue: false,
    legalEntityIsActive: false,
    legalEntityEnabled: false,
    basicMenu: true,
    canOpenMenu: true,
    holidayNotifyModal: false,
};

export const mutations = {
    SET_AVAILABLE_MENU(state, menu) {
        state.availableMenu = menu;
    },
    SET_FEATURES(state, features) {
        state.features = features;
    },
    SET_PAYONEER_PARAMS(state, item) {
        state.payoneerParams = item;
    },
    SET_LEGAL_ENTITY_DATA(state, item) {
        state.legalEntityData = item[0];
        state.legalEntityIsActive = item[0]?.legal_entity_is_active;
        state.legalEntityEnabled = item[0]?.legal_entity_enabled;
    },
    TOGGLE_MENU(state, value) {
        state.menuValue = value;
    },
    TOGGLE_PROFILE_MENU(state, value) {
        state.profileMenuValue = value;
    },
    TOGGLE_NOTIFICATION_MENU(state, value) {
        state.notificationMenuValue = value;
    },
    SET_BASIC_MENU(state,item){
        state.basicMenu = item;
    },
    CAN_OPEN_MENU(state,item){
        state.canOpenMenu = item.can_open_menu_dropdown;
    },
    SET_HOLIDAY_NOTIFY_MODAL(state,item){
        state.holidayNotifyModal = item.show_holiday_modal;
    }
};

export const actions = {
    async setAvailableMenuItems({ commit }, items) {
        commit("SET_AVAILABLE_MENU", items);
        return items;
    },
    async setNavigationMenuItems({ commit }, items) {
        commit("SET_FEATURES", items);
        return items;
    },
    async setPayoneerParams({ commit }, item) {
        commit("SET_PAYONEER_PARAMS", item);
        return item;
    },
    async setLegalEntityData({ commit }, item) {
        commit("SET_LEGAL_ENTITY_DATA", item);
        return item;
    },
    setBasicMenu({commit},item){
        commit('SET_BASIC_MENU',item);
    },
    async setCanOpenMenu({commit},item){
        commit("CAN_OPEN_MENU",item)
        return item
    },
    async setHolidayNotifyModal({commit},item){
        commit("SET_HOLIDAY_NOTIFY_MODAL",item)
        return item
    }
};

export const getters = {
    legalEntityIsActive: (state) => state.legalEntityIsActive,

    legalEntityEnabled: (state) => state.legalEntityEnabled,

    holidayNotifyModal: (state) => state.holidayNotifyModal,
}
