import Model from '~/shared/Model';

export default class WalletToWalletTransfer extends Model {

    constructor(obj = {}) {
        super();

        this.values = {
            from: obj.from || '',
            to: obj.to || '',
            amount: obj.amount || '',
            transfer_bank: obj.transfer_bank || '',
            transfer_bank_id: obj.transfer_bank_id || '',
            type: obj.type || '',
            note: obj.note || ''
        }

        this.rules = {
            from: 'required',
            to: 'required',
            amount: 'required|min_value:1',
            type: 'required',
            note: 'required'
        };
    }
}
