const state = {
    employees: [],
    employeesCountries: [],
    employeesPagination: null,
    employeesQuery: {
        search: '',
        status: 'all',
        country: 'all',
        filter: 'all',
        sort_by: '',
        order: 'desc',
    },
    uninvitedEmployees: [],
    stats: {},
};

const getters = {
    employees: state => state.employees,
    employeesCountries: state => state.employeesCountries,
    employeesPagination: state => state.employeesPagination,
    employeesQuery: state => state.employeesQuery,
    uninvitedEmployees: state => state.uninvitedEmployees,
    stats: state => state.stats,
}

const mutations = {
    employees(state, payload) {
        state.employees = payload;
    },
    employeesCountries(state, payload) {
        state.employeesCountries = payload;
    },
    employeesPagination(state, payload) {
        state.employeesPagination = payload;
    },
    employeesQuery(state, payload) {
        state.employeesQuery = payload;
    },
    setUninvitedEmployees(state, payload) {
        state.uninvitedEmployees = payload;
    },
    setEmployeesQueryFilter(state, payload){
        state.employeesQuery.filter = payload;
    },
    stats(state, payload) {
        state.stats = payload;
    },
    setDefaultSortOrder(state) {
        state.employeesQuery.sort_by = '';
        state.employeesQuery.order = 'desc';
    },
    setSortOrder(state, payload) {
        if (state.employeesQuery.sort_by === payload) {
            state.employeesQuery.order = state.employeesQuery.order === 'desc' ? 'asc' : 'desc';
        } else {
            state.employeesQuery.sort_by = payload;
            state.employeesQuery.order = 'desc';
        }
    },
}

const actions = {
    async getEmployees({ state, commit }, q = {}) {
        const query = Object.assign({}, state.employeesQuery);
        query.page = q.page || 1;

        for (const [key, value] of Object.entries(q)) {
            if(key !== 'page'){
                query[key] = value;
            }
        }
        await axios
            .get('/employee-management-hub/employee/all', {
                params: query
            })
            .then(response => {
                commit('employees', response.data.paginatedData.data);
                commit('employeesCountries', response.data.countries);
                commit('employeesPagination', response.data.paginatedData.meta);
                commit('stats', response.data.stats);
                return response.data;
            })
            .catch(error => {
                let alertText = '';
                if (error.response.data.message) {
                    alertText = error.response.data.message;
                } else {
                    alertText = error.message;
                }
                commit('setAlert', {type: 'error', message: alertText}, { root: true });
                throw error;
            });
    },
    async getUninvitedEmployees(){
        await axios.get('/employee-management-hub/employee/uninvited')
            .then(response => {
                commit('uninvitedEmployees', response.data);
            })
            .catch(error => {
                this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}