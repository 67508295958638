export const namespaced = true;

export const state = {
    isLegalEntity: false,
    entitySwitch: "personal-wallet",
    companyName: "Teresa Limited Co.",
    status: "no-company", // no-company, legal-entity-not-set, legal-entity-pending, legal-entity-active
    loading: false,
};

export const mutations = {
    SET_LEGAL_ENTITY(state, isLegalEntity) {
        state.isLegalEntity = isLegalEntity;
    },

    SET_ENTITY_SWITCH(state, entitySwitch) {
        state.entitySwitch = entitySwitch;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
};

export const actions = {
    setLegalEntity({ commit }, isLegalEntity) {
        commit("SET_LEGAL_ENTITY", isLegalEntity);
    },

    setEntitySwitch({ commit }, entitySwitch) {
        commit("SET_ENTITY_SWITCH", entitySwitch);
    },
    startLoading({ commit }) {
        commit("SET_LOADING", true);
    },

    stopLoading({ commit }) {
        commit("SET_LOADING", false);
    },
};

export const getters = {
    isLegalEntity: (state) => state.isLegalEntity,

    entitySwitch: (state) => state.entitySwitch,

    companyName: (state) => state.companyName,

    isLoading: (state) => state.loading,
};
