import router from '~/routes/index';
import VueCookie from "vue-cookie";
import axios from "axios";
import {__changeRoute, __event} from "~/utils/functions";
export const namespaced = true;

export const state = {
    /*API routes*/
    createTokenRoute: '/auth/login',
    refreshTokenRoute: '/auth/refresh-token',
    verifyEmailRoute: '/auth/verify-email',
    logoutRoute: '/auth/logout',
    tfaVerifyRoute: '/auth/tfa/verify',
    checkInviteTokenRoute: '/auth/invite/check-token',
    acceptInvitePasswordRoute: '/auth/invite/accept-password',

    /*Local paths*/
    requestedPath: null,
    loginPath: '/login',
    afterLoginPath: '/',
    afterLogoutPath: '/login',
    tfaPath: '/auth/two-factor',

    /*Variables*/
    authorized: undefined,
    token: null,
    tokenKey: 'token',
    refreshTokenKey: 'refresh_token',
    tfaAuthorized: false,
    tfaType: null,
    tfaTypeKey: 'tfa_type',
    tfaTokenKey: 'tfa',
    tfaDisabledKey: 'tfa_disabled',
    tfaHeaderKey: 'tfa'
};

export const mutations = {
    SET_TOKEN(state, token){
        state.token = token;
    },
    SET_TFA_AUTHORIZED(state, payload){
        state.tfaAuthorized = payload;
    },
    SET_TFA_TYPE(state, payload){
        state.tfaType = payload;
    },
    SET_REQUESTED_PATH(state, payload){
        state.requestedPath = payload;
    },
    SET_AUTHORIZED(state, payload){
        if (state.authorized !== payload.value){
            state.authorized = payload.value;
            if (payload.trigger){
                payload.value? __event('userAuthorized'): __event('userUnauthorized');
            }
        }
    }
}

export const actions  = {
    async __resolveAuth({dispatch, state}) {
        if (!!state.token){
            return true;
        }

        const access_token = VueCookie.get(state.tokenKey) || null;
        const refresh_token = localStorage.getItem(state.refreshTokenKey) || null;
        // if (refresh_token){
        //     return await dispatch('__refreshToken', refresh_token)
        // }

        if (!access_token || !refresh_token){
            await dispatch('__unAuthorize');
            return false;
        }

        await dispatch('__authorize', {access_token, refresh_token, trigger: false});
        return true;
    },
    async __resolveTfaAuth({dispatch, commit, state}){
        if (state.tfaAuthorized){
            return true;
        }

        const token = VueCookie.get(state.tfaTokenKey) || null;
        const disabled = VueCookie.get(state.tfaDisabledKey) || null;
        const type = VueCookie.get(state.tfaTypeKey) || null;

        commit('SET_TFA_TYPE', type);

        if (token || disabled){
            await dispatch('__authorizeTfa', {token, trigger: false})
            return true;
        }else{
            await dispatch('__unAuthorizeTfa');
            return false;
        }
    },
    async __authorizeTfa({dispatch, commit, state}, payload = {}){
        if (payload.token){
            const token = payload.token;
            VueCookie.set(state.tfaTokenKey, token);
            axios.defaults.headers.common[state.tfaHeaderKey] = token;
        }else{
            VueCookie.set(state.tfaDisabledKey, true);
        }
        commit('SET_TFA_AUTHORIZED', true);
        if (state.token){
            commit('SET_AUTHORIZED', {value: true, trigger: payload.trigger})
        }
    },
    async __authorize({commit, state}, payload = {access_token: null, refresh_token: null}){
        const access_token = payload.access_token;
        const refresh_token = payload.refresh_token;
        VueCookie.set(state.tokenKey, access_token);
        localStorage.setItem(state.refreshTokenKey, refresh_token);
        commit('SET_TOKEN', access_token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
        if (state.tfaAuthorized){
            commit('SET_AUTHORIZED', {value: true, trigger: payload.trigger})
        }
    },
    __unAuthorize({commit, dispatch, state}){
        VueCookie.delete(state.tokenKey);
        localStorage.removeItem(state.refreshTokenKey);
        commit('SET_TOKEN', null);
        commit('SET_AUTHORIZED', {value: false, trigger: true});
        dispatch('__unAuthorizeTfa');
    },
    __unAuthorizeTfa({commit, state}){
        VueCookie.delete(state.tfaTokenKey);
        VueCookie.delete(state.tfaDisabledKey);
        commit('SET_TFA_AUTHORIZED', false);
        commit('SET_AUTHORIZED', {value: false, trigger: true});
    },
    __loginAuthorizeCallback({dispatch, commit}){
        // commit('initialization/SET_INITIALIZED', false, {root: true});
        dispatch('__redirectAfterLogin');
    },
    __verifyTfa({dispatch}, payload = {}){
        payload.trigger = true;
        dispatch('__authorizeTfa', payload);
        dispatch('__loginAuthorizeCallback');
    },
    __refreshToken({dispatch, state}, refresh_token){
        const config = {headers: { Authorization: 'Bearer ' + refresh_token } }
        return axios.post(state.refreshTokenRoute, {}, config)
            .then(response => {
                const access_token = response.data.data.access_token;
                const refresh_token = response.data.data.refresh_token;
                dispatch('__authorize', {access_token, refresh_token, trigger: false});
                return true;
            })
            .catch(() => {
                dispatch('__unAuthorize');
                return false;
            })
    },
    __redirectAfterLogin({commit, state}){
        const path = state.requestedPath || state.afterLoginPath;
        commit('SET_REQUESTED_PATH', null);
        __changeRoute(router, path);
    },
    async __redirectToTfa({dispatch, commit, state}, tfaData) {
        dispatch('__unAuthorizeTfa');
        commit('SET_TFA_TYPE', tfaData.type);
        VueCookie.set(state.tfaTypeKey, tfaData.type);
        const path = await dispatch('__tfaFullPath');
        __changeRoute(router, path);
    },
    async __tfaFullPath({state}){
        return state.tfaPath + '?type=' + state.tfaType;
    },
    __resetTfa({dispatch}, tfaData){
        const type = tfaData.type;
        if (type){
            dispatch('__unAuthorizeTfa');
            dispatch('__clearState', {}, {root: true});
            dispatch('initialization/__initState', {}, {root: true});
            dispatch('__redirectToTfa', {type});
        }else{
            dispatch('__logout');
        }
    },
    __verifyEmail({dispatch, state}, payload){
        return axios.post(state.verifyEmailRoute, {token: payload.token})
            .then(response => {
                response.data.valid?
                    dispatch('__login', response.data.data):
                    dispatch('__unAuthorize');

                return response.data.valid;
            })
            .catch(() => {
                dispatch('__unAuthorize');
                return false;
            })
    },
    __checkInviteToken({dispatch, state}, payload){
        return axios.post(state.checkInviteTokenRoute, {token: payload.token})
            .then(response => response.data.valid)
            .catch(() => false);
    },
    __login({dispatch, commit}, authObj){
        const authData = authObj.auth;
        const tfaData = authObj.tfa;
        const access_token = authData.access_token;
        const refresh_token = authData.refresh_token;
        dispatch('__authorize', {access_token, refresh_token, trigger: true});
        dispatch('__processTfaData', tfaData);

        // commit('initialization/SET_INITIALIZED', false, {root: true});
    },
    __processTfaData({dispatch, commit}, tfaData){
        if (tfaData.enabled){
            dispatch('__redirectToTfa', tfaData);
            return;
        }

        dispatch('__verifyTfa');
    },
    __logout({dispatch, commit, state}){
        /*restriction: do not work multiple times*/
        if (state.token){
            commit('initialization/SET_FORCE_LOADING', true, {root: true})
            axios.post(state.logoutRoute).finally(() => {
                commit('initialization/SET_FORCE_LOADING', false, {root: true})
                dispatch('__unAuthorize');
                __changeRoute(router, state.afterLogoutPath);
            })
        }else{
            dispatch('__unAuthorize');
        }
    }
};

export const getters = {
    __isAuth: (state) => state.authorized,
    __isAuthResolved: (state) => !!state.token,
    __isTfaResolved: (state) => state.tfaAuthorized
};
