<template>
    <div class="d-flex flex-column  input-filter-autocomplete" :class="[classVal, bottomOffset? 'mb-1-rem': 'mb-0']">
        <template v-if="$slots.label">
            <slot name="label"></slot>
        </template>
        <label
            :for="id"
            v-else-if="label"
            class=" font-weight-medium black--text d-flex"
            :class="[disabled? 'tm-color-dark-grey': 'black--text', labelClassVal, labelBottomOffset? 'mb-1': '']"
        >
            <span>{{label}}</span>
            <v-tooltip v-if="infoText" right>
                <template v-slot:activator="{ on, attrs }">
                    <button v-bind="attrs" v-on="on">
                        <icon
                            size="15"
                            :name="infoIcon"
                            color="#5152fb"
                            class="ml-2"
                        ></icon>
                    </button>
                </template>
                <p class="tm-color-white" style="max-width: 170px; font-size: 12px; line-height: 15px">{{ infoText }}</p>
            </v-tooltip>
            <span v-if="asterisk" class="color-red ml-1">*</span>
        </label>
        <div class="d-flex align-center" style="position: relative">
            <div :style="inlineStyle" class=" tm-input pl-0 d-flex align-center" :class="errorMessages.length? 'tm-err': null">
                <div v-if="appendContent || $slots.prepped" class="d-flex">
                    <div v-if="appendContent" class="pa-2 d-flex align-center justify-center" v-html="__sanitizeHtml(appendContent)"></div>
                    <slot name="prepped"></slot>
                    <v-divider vertical></v-divider>
                </div>
               <template v-if="$slots.prepend">
                    <slot name="prepend"></slot>
               </template>
                <v-btn v-else-if="prependIcon" @click="$emit('prependClick')" icon small :color="prependIconColor">
                    <icon :name="prependIcon" size="16" :color="prependIconColor"></icon>
                </v-btn>
                <input
                    :autofocus="autofocus"
                    :name="inputName"
                    :type="type"
                    :disabled="disabled"
                    :autocomplete="inputAutocomplete"
                    :id="id"
                    v-model="val"
                    :placeholder="__inputPlaceholder"
                    class="px-2 w-100"
                    :class="classForInput"
                    @keyup="keyup"
                    @keydown="$emit('keydown',$event)"
                    @blur="$emit('blur')"
                    @focus="$emit('focus')"
                    @focusout="$emit('focusout')"
                    v-on:keyup.enter="$emit('onEnter')"
                >
                <div v-if="loading" class="pr-1">
                    <v-progress-circular indeterminate size="20" width="2" color="#5152fb"></v-progress-circular>
                </div>
                <v-btn v-if="appendIcon" @click="$emit('appendClick')" icon small :color="appendIconColor">
                    <icon :name="appendIcon" size="16" :color="appendIconColor"></icon>
                </v-btn>
                <v-btn v-if="removable" @click="$emit('remove')" icon small color="#fa4769" class="ml-1">
                    <icon name="icon-times" color="#fa4769" size="16"></icon>
                </v-btn>
            </div>
            <error-message v-if="showErrorMessages" :messages="errorMessages"></error-message>
            <slot name="input-right"></slot>
        </div>

        <div v-if="autocomplete && show_autocomplete && autocompleteItems.length" class="autocomplete-parent">
            <div class="autocomplete-item" v-for="(item, index) in autocompleteItems">
                <div @click="autoClick(item[autocompleteValueFields])">{{item[autocompleteValueFields]}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import ErrorMessage from "./ErrorMessage";
import Helpers from '~/shared/mixins/Helpers';
import Form from "~/shared/mixins/Form";
export default {
    name: 'TmInput',
    components: {ErrorMessage},
    mixins: [Helpers, Form],
    props: {
        autofocus: {
            default: false
        },
        id: {
            default: null
        },
        bottomOffset: {
            default: true
        },
        classVal: {
            default: ''
        },
        classForInput: {
            default: ''
        },
        labelBottomOffset: {
            default: true
        },
        labelClassVal: {
            default: 'tm-small-text'
        },
        placeholder: {
            default: 'Type here'
        },
        label: {
            default: null
        },
        value: {
            default: null
        },
        addStyles: {
            type: Object,
            default: () => {}
        },
        type: {
           default: 'text'
        },
        removable:{
            default: false
        },
        autocomplete:{
            default: false
        },
        autocompleteItems:{
            type: Array
        },
        autocompleteValueFields:{
            default: 'name'
        },
        inputAutocomplete: {
            type: String,
            default: 'off',
        },
        disabled:{
            default: false
        },
        appendIcon:{
            default: false
        },
        appendIconColor:{
            default: '#5152fb'
        },
        appendContent:{
            default: false
        },
        prependIcon: {
            default: false
        },
        prependIconColor: {
            default: '#5152fb'
        },
        loading: {
            default: false
        },
        infoText: {
          default: null
        },
        infoIcon: {
          default: 'icon-info'
        },
        inputName: {
            default: null
        },
        clearOnDisabled: {
            default: false
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            }
        },
        showErrorMessages: {
            default: true
        },
        borderRadius: {
            default: '0.1875rem'
        },
        asterisk: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        if(this.type === 'number') {
            this.keypressForNumber();
        }
    },
    methods:{
        keyup(){
            this.show_autocomplete = !!this.value
            this.$emit('keyup', this.value)
        },

        autoClick(item){
            this.val = item
            this.show_autocomplete = false
        },
    },
    data(){
        return {
            val: this.value,
            show_autocomplete: false,
        }
    },
    computed: {
        inlineStyle(){
            return {... this.addStyles, ...{'border-radius': this.borderRadius}};
        },
    },
    watch: {
        val(){
            this.$emit('input', this.val)
        },
        value(){
            this.val = this.value;
        },
        disabled(){
            if (this.clearOnDisabled && this.disabled){
                this.val = null;
            }
        }
    },
}
</script>

<style scoped>
.autocomplete-parent{
    overflow: auto;
    max-height: 300px;
    border: 1px solid #bcbcbc;
    padding: 8px;
    border-radius: 5px;
}
.autocomplete-parent .autocomplete-item div {
    padding: 5px 14px;
    border-bottom: 1px solid #bcbcbc;
    cursor: pointer;
}
.tm-input /deep/ .v-btn:before {
    background-color:transparent;
}
</style>
