<template>
    <WlBaseModal v-if="secureCardObj.url || secureCardObj.form" :value="secureCardObj.dialog" class-names="custom-modal modal-md" :width="secureCardObj.done ? 355 : 500">
        <div class="position-relative" style="background-color: #ffffff">
            <v-btn v-if="displaySCAModalXButton" icon small class="position-absolute"
                   style="top: 15px; right: 15px; z-index: 99" @click="handleCloseSCAModal()">
                <v-icon size="1.5rem">mdi-close</v-icon>
            </v-btn>
            <iframe
                style="display: block; width: 100%; padding: 0; border: 0; height: 600px; margin: 0"
                :srcdoc="secureCardObj.form"
                :src="secureCardObj.url"
                :style="{ 'width': '100%', 'height': secureCardObj.done ? '396px' : '600px' }"
                frameborder="0"
                allowfullscreen
            />
        </div>
    </WlBaseModal>
</template>
<script>

export default {
    name: 'SecureCardModal',
    computed: {
        secureCardObj() {
            return this.$store.state.secureCardObj;
        },
        displaySCAModalXButton() {
            const hideSCAModalXButtonOnUrls = ['/eor', '/employee-management-hub', '/settings', '/invoices', '/payment-request', '/wallet/add-money'];
            return !hideSCAModalXButtonOnUrls.includes(window.location.pathname) && this.secureCardObj.done;
        },
    },
    methods: {
        paymentDone(e) {
            this.$store.commit('secureCardSetSucceed', e.detail.succeed);
        },
        scaActions(){
            const disableRedirectAfterSCAModalUrls = ['/people', '/team', '/contacts', '/eor', '/employee-management-hub', '/settings', '/invoices', '/payment-request', '/wallet/add-money'];
            window.document.addEventListener('toDashboard', () => {
                if (!disableRedirectAfterSCAModalUrls.includes(window.location.pathname)) {
                    this.__changeRoute(this.$store.state.path);
                }
                this.$store.commit('closeSecureCardDialog');
            }, false);
            window.document.addEventListener('paymentDone', this.paymentDone);
        },
        handleCloseSCAModal() {
            if (['/people', '/eor', '/team', '/contacts'].includes(window.location.pathname)) {
                const event = new CustomEvent('toDashboard');
                window.parent.document.dispatchEvent(event);
            } else {
                this.$store.commit('closeSecureCardDialog');
            }
        },
    },
    created() {
        this.scaActions();
    }
}
</script>
