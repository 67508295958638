<template>
    <div class="d-flex flex-column" :class="bottomOffset? 'mb-4': 'mb-0'" :style="{position: iconInput? 'absolute': 'relative'}">
        <label :for="id" v-if="label" class="tm-small-text font-weight-medium black--text mb-1">{{label}}</label>
        <div @drop="getDropFiles" @dragenter.prevent @dragleave.prevent="drag = false" @dragover.prevent="drag = true">
            <template v-if="$scopedSlots['dropArea']" >
                <label ref="label">
                    <input ref="input" @change="getFile" type="file" class="d-none" :accept="accept">
                </label>
                <slot name="dropArea" :preview-obj="val" :preview-url="previewUrl" :drag="drag"></slot>
            </template>
            <label
                v-else
                ref="label"
                :style="height? {height: !isNaN(height)? height+'px': height, border: iconInput && 'unset!important' || ''}: {}"
                class="tm-file-input-label w-100"
                :class="addClasses"
            >
                <template v-if="showPreview && preview">
                    <v-img height="100%" cover :src="previewUrl"></v-img>
                    <v-btn v-if="showRemove" @click.prevent="removeFile()" small icon color="#fa4769" style="position: absolute; right: 0; bottom: 0;">
                        <icon size="16" name="icon-trash" color="#fa4769"></icon>
                    </v-btn>
                </template>
                <template v-else>
                    <div v-if="$slots.placeholder" class="d-flex align-center tm-small-text tm-color-dark-grey not-text-transform">
                        <slot name="placeholder"></slot>
                    </div>
                    <div v-else class="d-flex align-center tm-small-text tm-color-dark-grey not-text-transform">
                        <span class="tm-color-purple mr-1 custom-btn-tertiary">Upload</span> or drag and drop your file here.
                    </div>
                </template>
                <input ref="input" @change="[getFile($event), $emit('change')]" type="file" class="d-none" :accept="accept">
            </label>
        </div>
        <slot v-if="$scopedSlots['bottomActions']" name="bottomActions" :preview="preview" :preview-obj="val"></slot>
        <template v-else-if="preview && !showPreview && showActions">
            <div class="mt-4">
                <div class="d-flex align-center justify-space-between mb-1">
                    <div class="d-flex align-center">
                        <icon color="red" :name="getFileType" class="mr-2" size="14"></icon>
                        <span class="tm-small-text font-weight-medium black--text text-none">{{ preview }}</span>
                    </div>
                    <v-btn x-small icon @click="removeFile()" class="px-0 w-auto">
                        <icon name="icon-times" size="12"></icon>
                    </v-btn>
                </div>
            </div>
            <v-divider class="mt-2"></v-divider>
        </template>
        <error-message :center="errorsCenter" :messages="errorMessages"></error-message>
    </div>
</template>
<script>
    import ErrorMessage from "./ErrorMessage";
    export default {
        name: "TmSingleUploadArea",
        components: {ErrorMessage},
        props: {
            accept: {
                default: '*',
            },
            filesTypes: {
                default() {
                    return [];
                }
            },
            filesMaxMb: {
                default() {
                    return null;
                }
            },
            value: {
                default: null
            },
            label: {
                default: null
            },
            addClasses: {
                default: null
            },
            previewFile: {
                default: null
            },
            iconInput: {
                default: false
            },
            showActions: {
                default: true
            },
            previewFileUrl: {
                default: null
            },
            showPreview: {
                default: false
            },
            bottomOffset: {
                default: true
            },
            id: {
                default: null
            },
            height: {
                default: null
            },
            errorMessages: {
                type: Array,
                default() {
                    return []
                }
            },
            errorsCenter: {
                default: false
            },
            showRemove: {
                default: true
            }
        },
        data(){
            return {
                val: this.value,
                preview: this.previewFile,
                previewUrl: this.previewFileUrl,
                drag: false,
                previews: [
                    {image: 'google-sheets.png', types: ['xls', 'xlsx', 'ods', 'csv', 'tsv', 'html'], value: 'table'},
                    {image: 'image.png', types: ['jpeg', 'jpg', 'gif', 'png'], value: 'image'},
                    {image: 'pdf.png', types: ['pdf'], value: 'pdf'},
                    {image: 'word.png', types: ['doc', 'docx'], value: 'word'},
                ],
            }
        },
        watch: {
            val(){
                this.drag = false;
                this.$emit('input', this.val)
                this.$emit('previewObject', this.preview)
            },
            value(){
                this.val = this.value;
                if (!this.value){
                    this.preview = null;
                    this.previewUrl = null;
                }
            },
            previewFile(){
                this.preview = this.previewFile;
            },
            previewFileUrl(){
                this.previewUrl = this.previewFileUrl;
            }
        },
        computed:{
            getFileType(){
                let fileType = this.preview.split('.').pop();
                if (fileType === 'csv'){
                    return 'icon-file-csv';
                }
                return 'icon-pdf';
            },
        },
        methods: {
            getDropFiles(e){
                e.preventDefault();
                if (e.dataTransfer.items) {
                    for (let i=0; i<e.dataTransfer.items.length; i++){
                        if (e.dataTransfer.items[i].kind === 'file') {
                            this.setFile(e.dataTransfer.items[i].getAsFile())
                        }
                    }
                }else {
                    for (let i = 0; i < e.dataTransfer.files.length; i++) {
                        this.setFile(e.dataTransfer.files[i])
                    }
                }
            },
            getFile(e){
                for (let i=0; i<e.target.files.length; i++){
                    this.setFile(e.target.files[i])
                }
            },
            setFile(file){
                if(!_.isEmpty(this.filesTypes)) {
                    if(!this.filesTypes.includes(file.type)) {
                        if(this.filesTypes.indexOf('image/*') > -1 ) {
                            if( file.type.indexOf('image') < 0) {
                                return;
                            }
                        } else  {
                            return;
                        }
                    }
                }

                if(this.filesMaxMb && file.size > (this.filesMaxMb * 1000000)) {
                    return;
                }
                this.val = file;
                this.preview = file.name;
                if(file.type.indexOf('image') < 0) {
                   const type =  file.name.split('.').pop();
                   const item = this.previews.find(i => i.types.includes(type));
                   if(item) {
                       this.previewUrl =  this.$store.state.imagePath+'default/' +item.image;
                   }
                } else {
                    this.previewUrl = URL.createObjectURL(file);
                }
            },
            removeFile(){
                this.previewUrl = null;
                this.preview = null;
                this.val = null;
                if (this.$refs.input){
                    this.$refs.input.value = '';
                }
                this.$emit('delete');
            },
        },
        created() {
            if (this.value){
                this.setFile(this.value)
            }
        }
    }
</script>
