import _ from 'lodash';

const state = {
    items: [],
};

const getters = {
    items: (state) => state.items,
}

const mutations = {
    items(state, payload) {
        state.items = payload;
    },
}

const actions = {
    async getItems({ commit, dispatch, getters, rootState }) {
        return await axios
            .get(rootState.path + 'banking-in-nigeria/banks')
            .then(response => {
                commit('items', response.data.data);
                return response.data;
            })
            .catch(error => {
                console.error(error.message);
                throw error;
            });
    },
    onceGetItems: _.once(function ({ dispatch }) {
        return dispatch('getItems');
    }),
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
