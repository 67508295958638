<template>
    <upgrade-s-s-dashboard
        v-if="$store.state.styleSystem === 'dashboard'"
        :value="value"
        v-bind="$attrs"
        v-on="$listeners"
    ></upgrade-s-s-dashboard>
    <upgrade-s-s-wallet
        v-else-if="$store.state.styleSystem === 'wallet'"
        :value="value"
        v-bind="$attrs"
        v-on="$listeners"
    ></upgrade-s-s-wallet>
</template>
<script>
import UpgradeSSDashboard from "~/modules/dashboard/components/modals/UpgradeSSDashboard.vue";
import UpgradeSSWallet from "~/modules/wallet/components/Modals/UpgradeSSWallet.vue";

export default {
    name: "UpgradeSwitcher",
    components: {UpgradeSSWallet, UpgradeSSDashboard},
    props: {
        value: {
            default: false
        }
    }
}
</script>
