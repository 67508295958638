<template>
    <ul class="uploaded-files-list" style="max-height: 400px; overflow-y: auto;">
        <li v-for="(item, index) in attachments" :key="index" class="uploaded-file">
            <BaseIcon name="file" class="mr-2" />

            <p :class="checkValid(item) ? 'color-black': 'color-red'" class="text-sm font-medium">
                {{ item.name }}
            </p>

            <div class="ml-auto d-flex align-center">

                <BaseIcon
                    v-if="checkMark"
                    :name="checkValid(item)? 'check-circle-outline': 'icon-info-circle-outline'"
                    :class="checkValid(item)? 'color-feedback-success': 'color-red'"
                    class="mr-2"
                />

                <a v-if="downloadable && getLink(item)" :href="getLink(item)" target="_blank">
                    <BaseIcon name="icon-download-short-arrow color-purple" style="font-size: 1rem" />
                </a>

                <BaseButton
                    v-if="removable"
                    class="btn-icon btn-remove-file text-xs color-black p-0"
                    @click="removeItem(index)"
                >
                    <BaseIcon name="times" />
                </BaseButton>
            </div>
        </li>
    </ul>
</template>
<script>
export default {
    name: 'BaseFilesList',
    props: {
        attachments: {
            default: () => []
        },
        checkMark: {
            type: Boolean,
            default: true
        },
        removable: {
            type: Boolean,
            default: true
        },
        downloadable: {
            type: Boolean,
            default: false
        },
        isInvalid: {
            type: Function|null,
            default: null
        }
    },
    methods: {
        removeItem(index){
            this.$emit('remove', index)
        },
        getLink(item){
            return item.download_link || item.url || item.uri;
        },
        checkValid(item){
            return !this.isInvalid || this.isInvalid(item);
        }
    },
}
</script>