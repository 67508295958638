<template>
    <div class="vue-repo-app">
        <div class="account-limit-modal">
        <BaseModal v-model="dialog" attach persistent>
            <div class="custom-new-ds-modal-container">
                <div class="modal-header">
                    <BaseButton class="btn-icon ml-auto" @click="closeModal">
                        <BaseIcon name="times"/>
                    </BaseButton>
                </div>
                <div class="modal-body">
                    <v-img
                        class="ml-auto mr-auto"
                        max-width="164px"
                        :src="$store.state.imagePath + 'upgrade-flow/account-limit-modal-image.svg'"
                    />
                    <p class="title-text font-strong">Wallet funds limit reached!</p>

                    <p class="text-sm color-grey-1 leading-150 mb-12">
                        Hi {{ $store.state.worker.name }},
                        <br/>
                        <br/>
                        We would like to inform you that your wallet funds have reached the limit of
                        {{ planCurrency.symbol }}500, and your account is automatically upgraded to the next tier, the
                        <span class="font-medium color-black">paid Payments</span> tier.
                    </p>

                    <p class="text-sm color-grey-1 leading-150 mb-12">
                        Here's what you can expect:
                    </p>

                    <ul>
                        <li>
                            <BaseIcon name="check-extra-thin"/>
                            <p class="text-sm leading-150 color-grey-1">
                                <span class="font-medium color-black">Seamless transition</span>
                                - Your account will be automatically upgraded without any action
                                required from your end.
                            </p>
                        </li>
                        <li>
                            <BaseIcon name="check-extra-thin"/>
                            <p class="text-sm leading-150 color-grey-1">
                                <span class="font-medium color-black">Monthly subscription</span>
                                - Please be aware that your account will be charged a monthly
                                fee of {{planCurrency.symbol}}19 for the Payments tier.
                            </p>
                        </li>
                    </ul>

                    <div class="deduction-container">
                        <p class="color-dark-grey font-medium">
                            Wallet deduction <span class="color-black">{{planCurrency.symbol}}19</span>
                        </p>
                        <div class="d-flex align-center">
                            <p
                                class="text-sm font-medium color-feedback-success status-text mr-1"
                            >
                                Completed
                            </p>
                            <BaseIcon
                                class="color-feedback-success"
                                name="check-thin-circle-outline"
                            />
                        </div>
                    </div>

                    <div class="info-container">
                        <BaseIcon name="info-thin-circle-outline"/>
                        <p class="text-sm">
                            Monthly subscription - Please be aware that your account will be
                            charged a monthly fee of {{planCurrency.symbol}}19 for the Payments tier.
                        </p>
                    </div>

                    <p class="text-sm leading-150 mb-0">
                        If you have any questions or require further assistance, please
                        don’t hesitate to reach out to us.
                    </p>
                </div>
                <div class="modal-footer">
                    <BaseButton @click="closeModal" class="btn-big btn-purple color-white w-100 mb-2">
                        Start Onboarding
                    </BaseButton>
                    <BaseButton
                        class="btn-big btn-purple-outline w-100"
                        @click="closeModal"
                    >
                        Skip
                    </BaseButton>
                </div>
            </div>
        </BaseModal>
    </div>
    </div>
</template>

<script>
export default {
    name: "ModalAccountLimit",

    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            dialog: true,
            workerBalance: this.$store.state.workerBalance
        };
    },
    computed: {
        planCurrency() {
            return this.$store.state.worker && this.$store.state.worker.subscription.plan && this.$store.state.worker.subscription.plan.currency
                ? this.$store.state.worker.subscription.plan.currency
                : this.$store.state.localCurrency;
        }
    },

    methods: {
        closeModal() {
            axios.post(this.$store.state.path + 'profile/set-limit-reached-viewed');
            this.dialog = false;
        }
    },
};
</script>

<style scoped>
.account-limit-modal >>> .v-dialog {
    box-shadow: unset !important;
}

.v-image {
    margin-bottom: 1.75rem;
}

.title-text {
    line-height: 100%;
    font-size: 2.5rem;
    margin-bottom: 0.75rem !important;
}

ul {
    margin-bottom: 0.75rem !important;

    li {
        display: flex;
        align-items: flex-start;

        &:not(:last-child) {
            margin-bottom: 1.125rem;
        }

        i {
            color: #30b362;
            font-size: 1.5rem;
            margin-right: 0.5rem;
        }
    }
}

.deduction-container {
    padding: 1.5rem 1rem;
    border-radius: 8px;
    border: 1px solid #37bf76;
    background: white;
    margin-bottom: 1.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > p {
        font-size: 1.125rem;
    }

    i {
        font-size: 1.125rem;
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .status-text {
        margin-top: 0.0625rem;
    }
}

.info-container {
    display: flex;
    align-items: flex-start;
    padding: 1.5rem 1rem;
    border-radius: 20px;
    background: #d4d5e8;
    color: var(--main-purple-color) !important;
    margin-bottom: 1.75rem;

    i {
        font-size: 0.8125rem;
        width: 1rem;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;
    }

    p {
        line-height: 124%;
    }
}

.mb-12 {
    margin-bottom: 0.75rem !important;
}

@media (max-width: 992px) {
    .title-text {
        font-size: 1.5rem;
        margin-bottom: 1.5rem !important;
    }

    .deduction-container {
        padding: 1rem !important;
        flex-direction: column;
        align-items: flex-start;
    }
}
</style>
