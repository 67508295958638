<template>
    <div
        @scroll="$emit('scroll', $event)"
        :style="disabled? {opacity: 0.5}: null"
        style="position: relative"
        class="d-flex flex-column"
        :class="[classVal, bottomOffset? 'mb-1-rem': 'mb-0']"
    >
        <div v-if="label"
            :class="['d-flex font-weight-medium black--text', labelClassVal, labelBottomOffset? 'mb-9': '', align? 'align-'+align: '']"
            :style="labelAutoHeight ? 'height: 1.25rem' : ''"
        >
            <span>{{label}}</span>
            <v-btn v-if="clearable && query" @click.stop="query = null" x-small icon>
                <icon size="10" name="icon-times"></icon>
            </v-btn>
        </div>
        <div class="d-flex" :class="[!horizontal? 'flex-column': 'align-center', classification]">
            <template v-if="$scopedSlots['item']">
                <slot v-for="item in items" name="item" v-bind:item="item"></slot>
            </template>
            <template v-else>
                <label
                    :class="[contentClass, align? 'align-'+align: '', horizontal? 'mr-4': contentBottomOffset? 'mb-9': null, bordered? 'tm-card-label': null]"
                    v-for="item in items"
                    :key="item[itemValue]"
                    :style="!disabled && disabledItems.includes(item[itemValue])? {opacity: 0.5}: null"
                >
                    <div v-if="labelBefore"
                        :class="[boldLabel? 'font-weight-medium': null, bordered? 'tm-medium-text': 'tm-small-text']"
                        class="d-flex align-center"
                    >
                        <template>
                            <slot v-if="$scopedSlots['label']" name="label" v-bind:item="item"></slot>
                            <span v-else class="tm-color-black tm-initial text-no-wrap">{{ item[itemText] }}</span>
                        </template>
                        <span v-if="itemPlaceholder" class="ml-2 tm-color-dark-grey">{{ item[itemPlaceholder] }}</span>
                    </div>
                    <input
                        v-show="type !== 'radio'"
                        :disabled="disabled || disabledItems.includes(item[itemValue])"
                        @change="$emit('change')"
                        v-model="query"
                        :type="type"
                        :value="item[itemValue]"
                        class="tm-input mr-2" :class="errorMessages.length? 'tm-err': null"
                    >
                    <template v-if="type === 'radio'">
                        <template  v-if="item[itemValue] === query">
                            <svg v-if=" $store.state.window.width < 1200"  :class="radioClass" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z" fill="white" stroke="#5152FB"/>
                                <path d="M5 7.33333C6.28866 7.33333 7.33333 6.28866 7.33333 5C7.33333 3.71134 6.28866 2.66667 5 2.66667C3.71134 2.66667 2.66667 3.71134 2.66667 5C2.66667 6.28866 3.71134 7.33333 5 7.33333Z" fill="#5152FB"/>
                            </svg>
                            <svg v-else :class="radioClass" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.5 14.5C11.366 14.5 14.5 11.366 14.5 7.5C14.5 3.63401 11.366 0.5 7.5 0.5C3.63401 0.5 0.5 3.63401 0.5 7.5C0.5 11.366 3.63401 14.5 7.5 14.5Z" fill="white" stroke="#5152FB"/>
                                <path d="M7.5 11C9.433 11 11 9.433 11 7.5C11 5.567 9.433 4 7.5 4C5.567 4 4 5.567 4 7.5C4 9.433 5.567 11 7.5 11Z" fill="#5152FB"/>
                            </svg>

                        </template>
                        <template v-else>
                            <svg v-if=" $store.state.window.width < 1200" :class="radioClass"  width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z" fill="white" stroke="#C0C0C0"/>
                            </svg>
                            <svg v-else :class="radioClass" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.5 14.5C11.366 14.5 14.5 11.366 14.5 7.5C14.5 3.63401 11.366 0.5 7.5 0.5C3.63401 0.5 0.5 3.63401 0.5 7.5C0.5 11.366 3.63401 14.5 7.5 14.5Z" fill="white" stroke="#C0C0C0"/>
                            </svg>
                        </template>
                    </template>

                    <div v-if="itemIcon && item[itemIcon]" :class="radioClass" style="width: 15px; height: 15px; border-radius: 50%; overflow: hidden">
                        <v-img :src="item[itemIcon]" height="15" width="15"></v-img>
                    </div>
                    <div v-if="!labelBefore"
                        :class="[boldLabel? 'font-weight-medium': null, bordered? 'tm-medium-text': 'tm-small-text', labelBeforeClass, align? 'align-'+align: '']"
                        class="d-flex pointer"
                    >
                        <template>
                            <slot v-if="$scopedSlots['label']" name="label" v-bind:item="item"></slot>
                            <span v-else class="tm-color-black tm-initial text-no-wrap">{{ item[itemText] }}</span>
                        </template>
                        <span v-if="itemPlaceholder" class="ml-2 tm-color-dark-grey">{{ item[itemPlaceholder] }}</span>
                    </div>
                </label>
            </template>
        </div>
        <error-message :messages="errorMessages"></error-message>
    </div>
</template>
<script>
import ErrorMessage from "./ErrorMessage";
export default {
    name: 'TmInputGroup',
    components: {ErrorMessage},
    props: {
        classVal: {
            default: ''
        },
        radioClass: {
            type: String,
            default: 'mr-2'
        },
        labelClassVal: {
            default: 'tm-small-text'
        },
        labelBeforeClass: {
            default: ''
        },
        labelAutoHeight: {
            default: true
        },
        labelBefore: {
            default: false
        },
        contentClass: {
            default: 'd-flex'
        },
        classification: {
            default: ''
        },
        itemValue: {
            default: 'id'
        },
        itemText: {
            default: 'name'
        },
        itemPlaceholder: {
            default: null
        },
        itemIcon: {
            default: null
        },
        value: {
            default(){
                return this.type === 'checkbox'? []: null;
            }
        },
        label: {
            default: null
        },
        type: {
            default: 'checkbox'
        },
        bottomOffset: {
            default: true
        },
        contentBottomOffset: {
            default: true
        },
        labelBottomOffset: {
            default: true
        },
        items: {
            default(){
                return [];
            }
        },
        clearable: {
            default: false
        },
        disabled: {
            default: false
        },
        horizontal: {
            type: Boolean,
            default: false
        },
        boldLabel: {
            default: false
        },
        bordered: {
            default: false
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            }
        },
        clearOnDisabled: {
            default: true
        },
        disabledItems: {
            default: () => []
        },
        align: {
            default: 'center'
        }
    },
    data(){
        return {
            query: this.value
        }
    },
    watch: {
        query: {
            deep: true,
            handler(){
                this.$emit('input', this.query);
            }
        },
        value(){
            this.query = this.value;
        },
        disabled(){
            if (this.disabled && this.clearOnDisabled){
                this.query = this.type === 'checkbox'? []: null;
            }
        }
    },
}
</script>
