 export default {
    created() {
        if (!this.dontHideOnScroll){
            this.$nextTick(() => {
                window.addEventListener(
                    "scroll",
                    (event) => {
                        if (this.$options.name === "BaseMenu") {
                            // BaseMenu
                            if (
                                !this.$el.contains(event.target) &&
                                this.$refs.menu?.$refs?.content &&
                                this.$refs.menu?.$refs?.content !== event.target &&
                                !this.$refs.menu?.$refs?.content.contains(event.target)
                            ) {
                                this.val = false;
                            }
                        } else if (['BaseSelect', 'NewBaseSelect'].includes(this.$options.name)) {
                            // BaseSelect
                            let menu = this.$refs?.select?.$children?.find((i) => {
                                return i.$options.name === "v-menu";
                            });
                            if (
                                menu &&
                                event.target !== menu?.$refs?.content &&
                                !menu?.$refs?.content?.contains(event.target)
                            ) {
                                menu.isActive = false;
                            }
                        } else {
                            // BaseTooltip
                            this.val = false;
                        }
                    },
                    true
                );
            });
        }
    },
};
