<template>
    <ModalKYCTemplate
        :modalData="modalData"
        :dialog="dialog"
        @confirm="confirm()"
        @close="close()"
    >
        <KYCSumsub v-if="step === 'sumsub'"></KYCSumsub>
    </ModalKYCTemplate>
</template>
<script>
import ModalKYCTemplate from "./ModalKYCTemplate.vue";
import {mapActions, mapState} from "vuex";
import KYCSumsub from "./KYCSumsub.vue";

export default {
    name: 'KYCVerification',
    components: {KYCSumsub, ModalKYCTemplate},
    data(){
        return {
            dialog: false,
            step: 'start', /*steps: start, sumsub, pending*/
        }
    },
    computed: {
        ...mapState('kyc', {
            verified: 'verified',
            value: 'dialog',
            options: 'options',
            modalOptions: 'modalOptions',
        }),
        isPending() {
            return this.options.status === 'pending' ||
                this.options?.temporary?.status === 'pending' ||
                this.options.sumsub_status === 'on_hold';
        },
        isDemo(){
            return this.options.type === 'demo' ||
                this.options.rejected?.type === 'demo'
        },
        isRejected(){
            return this.options.rejected?.type === 'sumsub';
        },
        modalData(){
            switch (this.step) {
                case 'start':
                    return this.startData;
                case 'sumsub':
                    return this.sumsubData;
                case 'pending':
                    return this.pendingData;
                default:
                    return {};
            }
        },
        startData(){
            const card = this.modalOptions.card;
            const option = this.modalOptions.option;
            if (card && card === 'unl' && this.options.KYC_level === this.$store.state.kyc.KYCLevel2){
                /*UNL card if user already have basic verification*/
                return {
                    image: "graphic-card.svg",
                    title: `Additional information required`,
                    text: `Please note that ordering this card requires
                            additional verification steps, even if you've completed
                            our initial KYC process. We appreciate your understanding
                            as we ensure the security and compliance of all card
                            orders. Proceed with the necessary steps to complete
                            your order.`,
                    btnText: "Start your verification",
                }
            }else if (card && card === 'unl'){
                /*UNL card if user doesn't have basic verification*/
                return {
                    image: "graphic-card.svg",
                    title: `Complete KYC for your card order today`,
                    text: `You're eligible to order our Unlimit card!
                            To proceed, we require additional information from you.
                            Once approved, your card will be automatically requested.
                            Let's gather the necessary details to expedite your order!`,
                    btnText: "Start your verification",
                }
            }else if (card){
                /*Other cards case*/
                return {
                    image: "graphic-identity.png",
                    title: `Verify your identity to order your card`,
                    text: `Before proceeding with your card order, please complete
                        our quick and easy KYC verification process. This ensures
                        security and compliance. Once your KYC is successfully passed,
                        you can proceed to order your card.`,
                    btnText: "Start your verification",
                }
            }else if (option === 'legal_entity'){
                /*Legal entity case*/
                return {
                    image: "graphic-identity.png",
                    title: `Complete KYC verification`,
                    text: `You're attempting to open a Native company.
                    Please complete our KYC process to ensure compliance
                    and access all features. It's a quick and straightforward
                    step towards unlocking your account's full potential.`,
                    btnText: "Start your verification",
                }
            }else if (this.isDemo){
                /*Demo case*/
                return {
                    image: "graphic-complete-kyc.png",
                    title: `Complete KYC verification`,
                    text: `We've noticed that your account activity has exceeded a
                    significant monetary threshold. To maintain account security
                    and compliance, please complete our KYC verification process.
                     By completing this process, you help us ensure our platform's
                     integrity and protect your account from potential risks.`,
                    btnText: "Start your verification",
                }
            }else if (this.isRejected){
                /*Rejected sumsub case*/
                return {
                    image: "graphic-rejected.png",
                    title: `KYC verification rejection notice`,
                    text: `We regret to inform you that your recent KYC verification
                    submission has been declined. The documentation or information you
                    provided did not meet our verification criteria. We apologise for
                    any inconvenience and appreciate your cooperation in resolving this matter.
                    If you need further assistance, please contact our support team.`,
                    btnText: "Start your verification",
                }
            }else {
                /*Default case*/
                return {
                    image: "graphic-complete-kyc.png",
                        title: `Verify your ID for added security!`,
                    text: `In order to unlock the full range of features offered by Native Teams
                    and to access the available cards in your country, it is necessary to
                    confirm your identity in accordance with KYC and AML regulations.`,
                    btnText: "Start your verification",
                };
            }
        },
        pendingData(){
            if (this.options?.temporary){
                return {
                    image: "graphic-kyc-pending.png",
                    title: "KYC verification pending",
                    text: `Your verification is currently pending. We appreciate your
                    patience and will notify you once the process is complete. If you
                    have any questions, please contact our support team.`,
                    // btnText: "Close",
                }
            }else{
                return {
                    image: "graphic-identity.png",
                    title: "KYC verification pending",
                    text: `Your KYC verification is currently pending. Our team is
                    reviewing your information, and we appreciate your patience.
                    We'll notify you once the process is complete. Please contact
                    our support team if you have any questions or need
                    assistance.`,
                    // btnText: "Close",
                }
            }
        },
        sumsubData(){
            return {/*width: '61.25rem'*/}
        },
    },
    watch: {
        value(){
            this.dialog = this.value;
            if (this.value){
                this.step = this.isPending? 'pending': 'start';
            }
        },
        isPending(){
            this.step = this.isPending? 'pending': 'start';
        }
    },
    methods: {
        ...mapActions('kyc', ['__closeDialog']),
        confirm(){
            switch (this.step) {
                case 'start':
                    this.step = 'sumsub';
                    break;
                case 'pending':
                    this.close();
                    break;
                default:
                    this.close();
                    break;
            }
        },
        close(){
            this.__closeDialog();
        }
    }
}
</script>
