<template>
    <i :class="iconClassName" v-on="$listeners"></i>
</template>

<script>
export default {
    name: "WlBaseIcon",

    props: {
        name: {
            type: String,
            required: true,
        },
    },

    computed: {
        iconClassName() {
            return this.name && this.name.startsWith("icon")
                ? this.name
                : `icon-${this.name}`;
        },
    },
};
</script>
