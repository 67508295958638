import { serialize } from "object-to-formdata";
import {__downloadStream} from "@/utils/functions";
const ROUTE = '/payroll-calculator/'

const state = {
    additionalInformationDefault: 'Additional Information: Please insert additional information to get a more accurate estimation of the employment cost.',
    roles: [
        { value: 'worker', name: "a Worker (e.g. Freelancer)" },
        { value: 'employer', name: "an Employer (e.g. Business owner)" }
    ],
    listOptions: [
        'AM',
        'RS',
        'HR',
        'TR',
        'ME',
        'RO',
        'BA',
        'ES',
        'US',
        'GB',
        'KS',
        'MT',
        'NO',
        'FI'
    ],
    countries: [],
    withoutCountries: [],
    selectedCountry: null,
    activeRole: null,
    dataForManualSet: [],
    calculateData: [],
    isFullBreakdownVisible: false,
    selectedSerbiaOrMacedonia: false,
    selectedUnitedStates: false,
    selectedCroatia: false,
    validationForSerbia: true,
    showComingSoonBlock: false,

    // Header options
    showOptionsBlock: false,
    additionalInformation: null,
    activeCountryOptions: [],
    customOption: {},
    // Header options end

    // Unique situation
    unitedStetsTaxes: []
};

const mutations = {
    // Header options
    setActiveCountryOptions(state, data) { state.activeCountryOptions = data },
    setAdditionalInformation(state, data) { state.additionalInformation = data },
    setCustomOption(state, data) { state.customOption = data },
    setShowOptionsBlock(state, data) { state.showOptionsBlock = data },
    // Header options end

    setCountries(state, data) { state.countries = data },
    setWithoutCountries(state, data) { state.withoutCountries = data },
    setCalculateData(state, data) { state.calculateData = data },
    setDataForManualSet(state, data) { state.dataForManualSet = data },
    setSelectedCountry(state, data) { state.selectedCountry = data },
    setActiveRole(state, data) { state.activeRole = data },
    setValidationForSerbia(state,data) { state.validationForSerbia = data },
    setShowComingSoon(state,data) { state.showComingSoonBlock = data },
    TOGGLE_FULL_BREAKDOWN(state) { state.isFullBreakdownVisible = !state.isFullBreakdownVisible },
    setSelectedSerbiaOrMacedonia(state) {
        state.selectedSerbiaOrMacedonia = state.selectedCountry ? state.selectedCountry.code === 'RS' || state.selectedCountry.code === 'MK' : false
    },
    setSelectedUnitedStates(state) {
        state.selectedUnitedStates = state.selectedCountry ? state.selectedCountry.code === 'US' : false
    },
    setSelectedCroatia(state) {
        state.selectedCroatia = state.selectedCountry ? state.selectedCountry.code === 'HR' : false
    },
    clearData(state) {
        state.calculateData = [];
        state.dataForManualSet = [];
        state.selectedCountry = null;
        state.isFullBreakdownVisible = false;

        // Header options
        state.activeCountryOptions = [];
        state.customOption = {};
        // Header options end
    },
};

const actions = {
    clearData({commit}) { commit("clearData") },
    actionValidationForSerbia({commit}, data) { commit("setValidationForSerbia", data) },
    toggleFullBreakdown({commit}) { commit("TOGGLE_FULL_BREAKDOWN") },
    setActiveRole({commit}, data) { commit("setActiveRole", data ?? null) },

    setHeaderInfo({state, commit}, data) {
        commit('setActiveCountryOptions', data);
        let obj = {};
        state.activeCountryOptions.forEach((c) => {
            commit('setAdditionalInformation', c.additionalInformation ?? state.additionalInformationDefault);
            if (_.isEmpty(c.key)) return;
            obj[c.key] = c.defaultVaLue || null;
        });
        commit('setCustomOption', obj);
    },

    clearDataForSerbia({dispatch,state}) {
        dispatch('actionValidationForSerbia',false);
        state.calculateData = [];
        state.dataForManualSet = [];
    },

    checkRequestData({commit, state, dispatch}, requestData) {
        if (state.showComingSoonBlock) return;
        let salary = requestData.net_salary || requestData.gross_salary;
        if (_.isEmpty(salary) && _.isEmpty(requestData.country_id)) return;
        let data = {
            salary: salary.replaceAll(',', ''),
            country_id: requestData.country_id,
            net_to_gross: requestData.net_to_gross ?? false,
            custom_option: state.customOption,
        };
        if (!_.isEmpty(requestData.downloadType)) {
            data.downloadType = requestData.downloadType
            dispatch('download', data);
        } else {
            dispatch('calculate', data);
        }
    },

    findActiveCountry({commit, state, dispatch}, id) {
        dispatch('clearData');
        commit('setSelectedCountry', id ? state.countries.find(item => item.id === id) : null);
        commit('setShowComingSoon', state.withoutCountries.includes(state.selectedCountry.code));
        commit('setSelectedSerbiaOrMacedonia');
        commit('setSelectedUnitedStates');
        commit('setSelectedCroatia');
        dispatch('checkForGetHeaderData');
    },

    checkForGetHeaderData({state, commit, dispatch}) {
        if (state.selectedCountry && state.listOptions.includes(state.selectedCountry.code)) {
            if (!state.selectedUnitedStates) {
                commit('setShowOptionsBlock',true);
            }else {
                commit('setShowOptionsBlock',false);
            }
            dispatch('getHeaderData');
            return;
        }
        commit('setShowOptionsBlock',false);
    },

    async getPageData({commit,dispatch}) {
        dispatch('clearData');
        await window.axios
            .get(`${ROUTE}page-data`)
            .then(response => {
                commit('setCountries', response.data.countries);
                commit('setWithoutCountries', response.data.withoutCountries);
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.message}, {root: true});
                throw error;
            });
    },

    async calculate({commit, dispatch, state}, requestData) {
        await window.axios
            .post(`${ROUTE}calculate`, requestData)
            .then(response => {
                if (state.selectedSerbiaOrMacedonia && (response.data.item.validate && response.data.item.validate === true)) {
                    dispatch('clearDataForSerbia');
                    state.selectedCountry.salary.minimum_salary = response.data.item.minimum_salary;
                    state.selectedCountry.salary.minimum_gross_salary = response.data.item.minimum_gross_salary;
                    return;
                }
                commit('setDataForManualSet', response.data.item);
                commit('setCalculateData', response.data.calculate_data);
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.message}, {root: true});
                throw error;
            });
    },

    async getHeaderData({state, commit, dispatch}) {
        if (!state.selectedCountry) return;
        const props = {
            country_id: state.selectedCountry.id,
            all_data: false,
        }
        await window.axios
            .get(`${ROUTE}header`, {params: props})
            .then(response => {
                dispatch('setHeaderInfo', response.data);
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.message}, {root: true});
                throw error;
            });
    },
    download({}, requestData) {
        const queryString = new URLSearchParams(Object.fromEntries(serialize(requestData, { indices: true }))).toString();
        __downloadStream(`${ROUTE}download-${requestData.downloadType}?${queryString}`)
    },
};

const getters = {
    // Header options
    additionalInformation: (state) => state.additionalInformation,
    activeCountryOptions: (state) => state.activeCountryOptions,
    customOption: (state) =>  state.customOption,
    // Header options end

    roles: (state) => state.roles,
    countries: (state) => state.countries,
    listOptions: (state) => state.listOptions,
    dataForManualSet: (state) => state.dataForManualSet,
    calculateData: (state) => state.calculateData,
    isFullBreakdownVisible: (state) => state.isFullBreakdownVisible,
    selectedCountry: (state) => state.selectedCountry,
    activeRole: (state) => state.activeRole,
    selectedSerbiaOrMacedonia: (state) => state.selectedSerbiaOrMacedonia,
    selectedUnitedStates: (state) => state.selectedUnitedStates,
    selectedCroatia: (state) => state.selectedCroatia,
    validationForSerbia: (state) => state.validationForSerbia,
    showComingSoonBlock: (state) => state.showComingSoonBlock,
    showOptionsBlock: (state) => state.showOptionsBlock,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
