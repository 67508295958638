<!-- @format -->

<template>
    <div class="d-flex flex-column base-select-iban-account-number">
        <label :for="localId" v-if="primaryLabel || secondaryLabel" class="text-sm color-black custom-label d-flex flex-column align-start" :class="[labelClassVal]">
                <span v-if="primaryLabel" class="mb-1">{{ primaryLabel }}</span>
                <span v-if="secondaryLabel" class="color-dark-grey font-normal">{{ secondaryLabel }}</span>
        </label>
        <div class="position-relative">
            <div
                class="input pl-0 d-flex align-center"
                :class="errors.collect('iban').length || errors.collect('account_number').length ? 'error' : null"
            >
                <BaseSelect
                    v-model="formData.is_iban"
                    :bordered="false"
                    menu-width="8rem"
                    name="acctype"
                    item-value="id"
                    view-box-class="base-select-iban-account-number-custom"
                    :items="[
                        { id: 'iban', name: 'IBAN' },
                        { id: 'account_number', name: 'Account number' },
                    ]"
                    class="base-bank-type-filter"
                    direction="between"
                    :title="true"
                    :class="formData.is_iban !== 'iban' ? 'drop-acc-iban-large-width' : 'drop-acc-iban-small-width'"
                />
                <v-divider vertical />
                <BaseInput
                    v-if="'iban' === formData.is_iban"
                    v-model="formData.iban"
                    :bordered="false"
                    v-validate="ibanCustomRules ? ibanCustomRules : 'iban_validation|min:15|max:32'"
                    class="w-100"
                    :placeholder="ibanPlaceholder ? ibanPlaceholder : 'e.g. AT483200000012345864'"
                    name="iban"
                    :customInputClasses="errorMessages.length ? 'input error' : null"
                    data-vv-as="IBAN"
                />
                <BaseInput
                    v-else
                    v-model="formData.account_number"
                    :bordered="false"
                    v-validate="accountNumberCustomRules ? accountNumberCustomRules : 'account_number|min:3|max:255'"
                    class="w-100"
                    :placeholder="accountNumberPlaceholder ? accountNumberPlaceholder : 'e.g. 5299 0123 0890 1234'"
                    name="account_number"
                    data-vv-as="Account number"
                />

                <div v-if="loading" class="pr-1">
                    <v-progress-circular indeterminate size="20" width="2" color="#5152fb" />
                </div>
            </div>
            <BaseErrorMessage :messages="errorMessages" />
        </div>
    </div>
</template>
<script>
import Helpers from '~/shared/mixins/Helpers';

export default {
    name: 'BaseSelectIbanAccountNumber',

    mixins: [Helpers],

    props: {
        id: {
            default: null,
        },
        labelBottomOffset: {
            default: true,
        },
        labelClassVal: {
            default: '',
        },
        ibanPlaceholder: {
            default: 'AA1234567890',
        },
        accountNumberPlaceholder: {
            default: null
        },
        primaryLabel: {
            default: null,
        },
        secondaryLabel: {
            default: null,
        },
        value: {
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        inputName: {
            default: null,
        },
        errorMessages: {
            type: Array,
            default() {
                return [];
            },
        },
        formData: {
            type: Object,
            default: () => {}
        },
        ibanCustomRules: {
            type: String,
            default: '',
        },
        accountNumberCustomRules: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            localId: this.id || this.__makeId(),
            code: null,
            number: '',
            location: null,
            selectCountryCode: null,
            setCountryPlaceholder: null,
        };
    },
};
</script>
