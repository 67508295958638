const state = {
    trackingIsInitialized: false,
    savedPath: null,
};

const mutations = {
    SET_TRACKING_IS_INITIALIZED(state, payload) {
        state.trackingIsInitialized = payload;
    },
    SET_SAVED_PATH(state, payload) {
        state.savedPath = payload;
    },
};

const actions = {
    trackVisitor({ commit, state, dispatch, rootState, rootGetters }, payload) {
        let path = payload.path;
        const email = payload.email;
        const _hsq = window._hsq = window._hsq || [];
        if (typeof _hsq !== undefined && rootState.hubspotTrackingCodeEnabled) {
            if (rootGetters['auth/__isAuth']) {
                const _hsTrackingIdentityData = {
                    id: rootState.authUser.id,
                };
                if (rootState.authUser?.options?.trackingIsInitialized !== 1 && !state.trackingIsInitialized) {
                    _hsTrackingIdentityData.email = rootState.authUser.email;
                    dispatch('setTrackingIsInitialized');
                }
                _hsq.push(['identify', _hsTrackingIdentityData]);
            } else if (email) {
                _hsq.push(['identify', { email: email }]);
            }
            if (!path && state.savedPath) {
                path = state.savedPath;
            }
            _hsq.push(['setPath', path]);
            _hsq.push(['trackPageView']);
        } else if (path) {
            commit('SET_SAVED_PATH', path);
        }
    },
    async setTrackingIsInitialized({ commit, dispatch }) {
        dispatch('updateUserOptions', { trackingIsInitialized: 1 }, { root: true })
            .then(() => commit('SET_TRACKING_IS_INITIALIZED', true));
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
