<template>
  <v-tooltip
    v-model="val"
    v-bind="$attrs"
    @input="(e) => $emit('input', e)"
    :content-class="contentClass"
  >
    <template v-slot:activator="slotProps">
      <slot name="activator" v-bind="slotProps" />
    </template>
    <slot></slot>
  </v-tooltip>
</template>

<script>

export default {
  name: "WlBaseTooltip",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      val: this.value,
    };
  },

  watch: {
    value(val) {
      this.val = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tooltip__content.new-wallet-tooltip-content {
    display: inline-flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 24px;
    background: white;
    /* Outer shadow */
    box-shadow: 0px 429px 120px 0px rgba(42, 43, 65, 0),
    0px 17px 38px 0px rgba(42, 43, 65, 0.05);
    opacity: 1;
    width: auto;
    max-width: 300px;

    p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
}
</style>
