<template>
    <ModalInfo
        :modalData="modalData"
        @close="$emit('close')"
        @on-confirm="$emit('start')"
    >
        <p class="mt-2 text-base color-grey-2 text-center mb-3">
            We regret to inform you that your recent KYC verification submission has been declined.
            The documentation or information you provided did not meet our verification criteria.
            We apologise for any inconvenience and appreciate your cooperation in resolving this matter.
            If you need further assistance, please contact our support team.
        </p>

        <template #underButton>
            <BaseButton
                href="mailto:support@nteams.com"
                class="btn-big btn-purple-outline w-100 mt-2"
            >
                Contact support
            </BaseButton>
        </template>
    </ModalInfo>
</template>
<script>
import ModalInfo from "~/shared/components/modals/ModalInfo";
export default {
    name: 'VerificationRejected',
    components: {ModalInfo},
    data(){
        return {
            modalData: {
                icon: {
                    name: "alert-thin-circle-outline",
                    class: "color-red-light",
                },
                title: "KYC verification rejection notice",
                btnText: "Resolve verification",
            }
        }
    },
}
</script>
