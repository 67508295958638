/** @format */

import Case from '~/modules/dashboard/models/Case';
import CaseComment from '~/modules/dashboard/models/CaseComment';

const state = {
    items: [],
    pagination: null,
    query: {
        is_admin: 0,
        member_id: null,
    },

    stats: {},
    caseModel: {},
    caseCommentModel: {},
    loadingItems: false,
    fee: 100,
    uploadingAttachments: false,
    selectedCaseId: null,
    selectedCase: {},
    editMode: false,
    getDraftItem: false,
    isMobility: 0,

    caseProcessFilterValuesMobility: [
        {text: 'All', filter: 'all', status: 'all', icon: "icon-loading-dots-circle", color: "#f29d39"},
        {text: 'Inquiry', filter: 'inquiry', status: 0, icon: "info-circle-outline", color: "#0066FF"},
        {text: 'Ongoing', filter: 'ongoing', status: 5, icon: "transaction-status-overdue", color: "#FF8A00"},
        {text: 'Accepted', filter: 'accepted', status: 7, icon: "check-circle-outline", color: "#30B362"},
        {text: 'Rejected', filter: 'rejected', status: 6, icon: "transaction-status-declined", color: "#FF0000"},
        {text: 'Cancelled', filter: 'declined', status: 3, icon: "transaction-status-cancelled", color: "#4D4D4D"},
        {text: 'Draft', filter: 'draft', status: 'draft', icon: "transaction-status-pending", color: "#FDBC42"},
        {text: 'Deleted', filter: 'deleted', status: 2, icon: "exclamation-outline", color: "#FF0000"},
    ],
    caseProcessFilterValues: [
        {text: 'All', value: 'all', status: 'all', icon: "icon-loading-dots-circle", color: "#f29d39"},
        {text: 'Pending', value: 'pending', status: 0, icon: "icon-loading-dots-circle", color: "#f29d39"},
        {text: 'Completed', value: 'completed', status: 1, icon: "check-circle", color: "#30b362"},
        {text: 'Declined', value: 'declined', status: 3, icon: "exclamation-outline", color: "red"},
        {text: 'Deleted', value: 'deleted', status: 2, icon: "exclamation-outline", color: "red"},
    ],
    caseAdminFilterValues: [
        {text: 'My tickets', value: 0},
        {text: 'Team tickets', value: 1},
    ],

    lastUpdatesTimestamp: '',
    unviewedTicketsCount: 0,
    organizationsWithCases: [],
    selectedStatus: "completed",
    selectedType: "all",

    mobileCaseDialog: false,
    modalOpenLearnMore: false,
    teamMembers : [],
    memberProfile : false,
};

const getters = {
    getDraftItem: state => state.getDraftItem,
    items: state => state.items,
    memberProfile: state => state.memberProfile,
    isMobility: state => state.isMobility,
    pagination: state => state.pagination,
    query: state => state.query,
    stats: state => state.stats,
    loadingItems: state => state.loadingItems,
    uploadingAttachments: state => state.uploadingAttachments,
    caseModel: state => state.caseModel,
    caseCommentModel: state => state.caseCommentModel,
    selectedCaseId: state => state.selectedCaseId,
    selectedCase: state => state.selectedCase,
    teamMembers: state => state.teamMembers,
    editMode: state => state.editMode,
    caseProcessFilterValues: state => state.caseProcessFilterValues,
    caseProcessFilterValuesMobility: state => state.caseProcessFilterValuesMobility,
    caseAdminFilterValues: state => state.caseAdminFilterValues,
    lastUpdatesTimestamp: state => state.lastUpdatesTimestamp,
    unviewedTicketsCount: state => state.unviewedTicketsCount,
    organizationsWithCases: state => state.organizationsWithCases,
    getTicketById: (state) => (id) => {
        return state.items.find((ticket) => ticket.id === id);
    },
    mobileCaseDialog: state => state.mobileCaseDialog,
    modalOpenLearnMore: state => state.modalOpenLearnMore,
};

const mutations = {
    pagination(state, payload) {
        state.pagination = {...payload};
    },
    getDraftItem(state, val) {
        state.getDraftItem = val;
    },
    isMobility(state, val) {
        state.isMobility = val;
    },
    setFee(state, val) {
        state.fee = val;
    },
    items(state, payload) {
        state.items = payload;
    },
    memberProfile(state, payload) {
        state.memberProfile = payload;
    },
    query(state, payload) {
        state.query = payload;
    },
    stats(state, payload) {
        state.stats = payload;
    },
    loadingItems(state, payload) {
        state.loadingItems = payload;
    },
    uploadingAttachments(state, payload) {
        state.uploadingAttachments = payload;
    },
    startEditingCase(state, payload) {
        state.caseModel = new Case(payload);
        state.editMode = !!state.caseModel.values.id;
    },
    stopEditingCase(state) {
        state.caseModel = new Case();
        state.mobileCaseDialog = false;
    },
    selectCase(state, payload) {
        state.selectedCaseId = payload;
        state.selectedCase = state.items.find(item => item.id === payload);
        state.caseCommentModel = new CaseComment({case_id: payload});
    },
    unselectCase() {
        state.selectedCase = {};
        state.selectedCaseId = null;
    },
    setSelectedCaseViewed() {
        state.selectedCase.is_unviewed = false;
    },
    updateSelectedCase(state) {
        state.selectedCase = state.items.find(item => item.id === state.selectedCaseId);
    },
    createCommentModel(state, payload) {
        state.caseCommentModel = new CaseComment({case_id: payload});
    },
    addNewCommentToSelectedCase(state, payload) {
        state.selectedCase.comments.push(payload);
    },
    initMainFilter(state, payload = 1) {
        let finded = state.caseProcessFilterValues.find((element) => element.status == payload);
        state.query.case_process = finded.value;
        state.query.is_admin = state.caseAdminFilterValues[0].value;
    },
    setOwnerIdFilter(state, payload) {
        if (payload) {
            state.query.owner_id = payload;
        }
    },
    setIsAdminFilter(state) {
        state.query.is_admin = 1;
    },
    clearItems(state) {
        state.items = [];
    },
    setCaseProcessFilter(state, payload) {
        state.query.case_process = payload;
    },
    lastUpdatesTimestamp(state, payload) {
        state.lastUpdatesTimestamp = payload;
    },
    setTeamMembers(state, payload) {
        state.teamMembers = payload;
    },
    unviewedTicketsCount(state, payload) {
        state.unviewedTicketsCount = payload;
    },
    organizationsWithCases(state, payload) {
        state.organizationsWithCases = payload;
    },
    SET_STATUS(state, status) {
        state.selectedStatus = status;
    },
    SET_TYPE(state, type) {
        state.selectedType = type;
    },

    SET_SEARCH_QUERY(state, query) {
        state.searchQuery = query;
    },
    UPDATE_TICKET_STATUS(state, ticket) {
        state.cases.forEach((item, index) => {
            if (item.id === ticket.id) {
                state.cases[index].status = ticket.status;
            }
        });
    },
    openMobileDialog(state) {
        state.mobileCaseDialog = true;
    },
    openModalOpenLearnMore(state){
        state.modalOpenLearnMore = !state.modalOpenLearnMore;
    }
};

const actions = {
    setMobility({ commit }, val) {
        commit('isMobility', val);
    },
    async getItems({ state, commit, rootState }, q = {}) {
        commit('loadingItems', true);
        commit('items', []);
        commit('pagination', null);
        commit('stats', {});
        const query = Object.assign({}, state.query);
        query.page = q.page || 1;

        for (const [key, value] of Object.entries(q)) {
            if ('page' !== key) {
                query[key] = value;
            }
        }
        await window.axios
            .get(rootState.path + 'cases/items', {
                params: query,
            })
            .then(response => {
                commit('items', response.data.data.cases.data);
                commit('pagination', response.data.data.cases.meta);
                commit('stats', response.data.data.stats);
                if (state.selectedCaseId) {
                    commit('updateSelectedCase', response.data.data);
                }
                return response.data;
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.message}, {root: true});
                throw error;
            })
            .finally(() => {
                commit('loadingItems', false);
            });
    },
    async save({commit, dispatch, state}) {
        const fn = state.caseModel?.values?.id ?
            state.caseModel.update('/cases/update/' + state.caseModel?.values?.id) :
            state.caseModel.create('/cases/create');
        return fn
            .then(response => {
                dispatch('getItems');
                return response;
            })
            .catch(error => {
                let alertText = '';
                if (error.response.data.success === false) {
                    alertText = error.response.data.error;
                } else if (error.response.data.message) {
                    alertText = error.response.data.message;
                } else {
                    alertText = error.message;
                }
                commit('setAlert', {type: 'error', message: alertText}, {root: true});
                throw error;
            });
    },
    delete({commit, dispatch, rootState}, id) {
        return window.axios
            .delete(rootState.path + `cases/delete/${id}`)
            .then(response => {
                dispatch('getItems');
                return response.data;
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.message}, {root: true});
                throw error;
            });
    },
    decline({commit, dispatch, rootState}, id) {
        return window.axios
            .get(rootState.path + `cases/decline/${id}`)
            .then(response => {
                dispatch('getItems');
                return response.data;
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.message}, {root: true});
                throw error;
            });
    },
    accept({commit, dispatch, rootState}, id) {
        return window.axios
            .get(rootState.path + `cases/accept/${id}`)
            .then(response => {
                dispatch('getItems');
                return response.data;
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.message}, {root: true});
                throw error;
            });
    },
    async addComment({commit, rootState, state}) {
        await state.caseCommentModel
            .create(rootState.path + 'cases/comments/create')
            .then(response => {
                commit('addNewCommentToSelectedCase', response.data.data);
                commit('createCommentModel', state.caseCommentModel.values.case_id);
                return response;
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.response.data.message}, {root: true});
                throw error;
            });
    },
    async addAttachments({commit, dispatch, rootState}, {case_id, files, type = false}) {
        const checkMimeType = (file) => {
            // Define allowed mime types (you can customize this array)
            const allowedMimeTypes = [
                'image/jpeg',
                'image/jpg',
                'image/png',
                'application/pdf',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ];
            // Check if the file's mime type is in the allowedMimeTypes array
            return allowedMimeTypes.includes(file.type);
        };

        for (const file of files) {
            if (!checkMimeType(file)) {
                const error = `The Files field must have a valid file type`;
                // Dispatch an error action or show an error message
                commit('setAlert', {type: 'error', message: error}, {root: true});
                return;
            }

            // Process the file and store it
            commit('uploadingAttachments', true);
            const formData = new FormData();
            formData.append('case_id', case_id);
            if (type) {
                formData.append('type', type);
            }
            for (let i = 0; i < files.length; i++) {
                formData.append(`files[${i}]`, files[i]);
            }
            await window.axios
                .post(rootState.path + 'cases/attachments', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    dispatch('getItems');
                    return response;
                })
                .catch(error => {
                    commit('setAlert', {type: 'error', message: error.message}, {root: true});
                    throw error;
                })
                .finally(() => commit('uploadingAttachments', false));
        }
    },
    async checkDraftMobility({commit, rootState}) {
        return window.axios.post(rootState.path + 'mobility/check-draft').then(r => {
            commit('getDraftItem', r.data?.id || '');
        });
    },
    async deleteAttachment({commit, dispatch, rootState}, id) {
        return window.axios
            .delete(rootState.path + `cases/attachments/${id}`)
            .then(response => {
                dispatch('getItems');
                return response;
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.message}, {root: true});
                throw error;
            });
    },
    async checkUpdates({commit, dispatch, rootState}) {
        await window.axios
            .get(rootState.path + 'cases/check-updates', {
                params: {...state.query, timestamp: state.lastUpdatesTimestamp},
            })
            .then(response => {
                commit('lastUpdatesTimestamp', response.data.timestamp);
                if (response.data.result) {
                    dispatch('getItems').then(() => {
                        if (0 < state.unviewedTicketsCount && state.selectedCase && state.selectedCase.is_unviewed) {
                            dispatch('setCaseViewed', state.selectedCase);
                        }
                    });
                }
                return response;
            })
            .catch(error => {
                console.log(error.message);
                throw error;
            });
    },
    async getTeamMembers({commit, rootState}) {
        await window.axios
            .get(rootState.getPath + 'search-team-members')
            .then(response => {
                commit('setTeamMembers', response.data);
            })
            .catch(error => {
                console.log(error.message);
                throw error;
            });
    },
    async setCasesViewed({commit, rootState}) {
        await window.axios
            .post(rootState.path + 'cases/set-viewed')
            .then(response => {
                commit('unviewedTicketsCount', 0);
                return response;
            })
            .catch(error => {
                console.log(error.message);
                throw error;
            });
    },
    async setCaseViewed({commit, dispatch, rootState}, caseObject) {
        let ownerId = caseObject.owner.id;
        if (!state.selectedCase.accepted) {
            ownerId = state.selectedCase.organization_creator_id
        }
        if (!ownerId) {
            return;
        }
        await window.axios
            .post(rootState.path + `cases/set-viewed/${ownerId}/${caseObject.id}`)
            .then(response => {
                if (state.selectedCase && state.selectedCase.is_unviewed) {
                    commit('setSelectedCaseViewed');
                }
                return response;
            })
            .catch(error => {
                console.log(error.message);
                throw error;
            });
    },
    async getOrgsWithCases({commit, rootState}) {
        await window.axios
            .get(rootState.path + 'cases/orgs-with-cases')
            .then(response => {
                commit('organizationsWithCases', response.data);
                return response.data;
            })
            .catch(error => {
                console.log(error.message);
                throw error;
            });
    },
    onceGetItems: _.once(({dispatch}) => dispatch('getItems')),
    setStatus({commit}, status) {
        commit("SET_STATUS", status);
    },
    setType({commit}, type) {
        commit("SET_TYPE", type);
    },

    setSearchQuery({commit}, query) {
        commit("SET_SEARCH_QUERY", query);
    },
    updateTicketStatus({commit}, ticket) {
        commit("UPDATE_TICKET_STATUS", ticket);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
