<template>
    <v-fade-transition hide-on-leave>
        <div v-if="filteredActions.length" class="actions-container" style="margin-bottom: 1rem;">
            <div class="text-sm font-medium title-text">Quick actions</div>

            <div class="row quick-actions-row">
                <div v-for="(action, index) in filteredActions" :key="index" class="col-4">
                    <QuickActionButton
                        :icon="action.icon"
                        :action='action'
                        :kyc="action.kyc"
                        :href="`/${action.link}`"
                    >
                        {{ action.name }}
                    </QuickActionButton>
                </div>
            </div>
        </div>
    </v-fade-transition>
</template>

<script>
import QuickActionButton from "~/components/NewNavigation/QuickActionButton.vue";
import {mapState} from "vuex";

export default {
    name: "QuickActionsSection",

    components: {QuickActionButton},

    props: {
        quickActions: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        ...mapState('globalMenuModule', ['legalEntityData']),
		currentWorker(){
			return this.$store.state.worker;
		},
		currentOrganization(){
			return this.$store.state.organization;
		},
        filteredActions() {
            const modifActions = [...this.quickActions];
            const index = modifActions.findIndex(action => action.value === "start_eor");
            if (index !== -1) {
                if(
                        this.currentWorker?.payroll &&
                        (this.currentWorker?.payroll?.status == 'accepted' || this.currentWorker?.payroll?.status == 'pending')
                ){
                    modifActions[index] = {
                        ...modifActions[index],
                        name: 'EOR status'
                    };
                }
            }

            return modifActions.filter(item => {

                if (
                    (this.currentOrganization.default && item.value === 'add_team_member') ||
                    (
                        item.value === 'legal_entity' &&
                        this.legalEntityData.legal_entity_details &&
                        (this.legalEntityData.legal_entity_details.status == 1 || this.legalEntityData.legal_entity_details.status == 2)
                    )
                ) {
                    return false;
                }
                if (item.value === 'send_money' && ['free', 'free_nigeria'].includes(this.currentWorker.plan.value)) {
                    item.link = '/add-ons/my-plan'
                }
                return item;
            })
        }
    }
};
</script>

<style scoped>
.actions-container {
    .title-text {
        color: #5a5a77;
        line-height: 1.4375rem;
        margin-bottom: 1.75rem;
    }
}

.quick-actions-row {
    margin: -0.875rem -0.5rem !important;

    & > div {
        display: flex;
        justify-content: center;
        padding: 0.875rem 0.5rem !important;


        @media (max-width: 768px) {
            max-width: 50% !important;
            flex: 0 0 50% !important;
        }
    }
}
</style>
