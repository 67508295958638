<template>
    <div class="mobile-filters-wrapper">
        <div v-if="showFilters" class="mobile-filters">
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="icon-chevron-down"
                    ><div>
                        <WlBaseIcon name="filter" class="color-purple"> </WlBaseIcon>
                        <span class="font-medium ml-2 text-base">Filter</span>
                    </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <slot></slot>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>

        <div
            v-if="$slots['additional-buttons']"
            class="additional-buttons"
            :class="{ 'mt-0': !showFilters }"
        >
            <slot name="additional-buttons"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "WlBaseMobileFilters",

    props: {
        showFilters: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 769px) {
    .mobile-filters-wrapper {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .mobile-filters {
        background: #fff;
        padding: 8px 16px;
        width: 100%;
        border-radius: 16px;
        transition: padding 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

        &:has(.v-expansion-panel--active) {
            padding: 32px 16px;
        }

        .v-expansion-panel {
            border: none !important;

            &::before {
                content: none;
            }

            .v-expansion-panel-header {
                padding: 10px 16px;
            }

            .v-expansion-panel-content {
                margin-top: 16px;

                .v-expansion-panel-content__wrap {
                    padding: 0 !important;
                }
            }
        }
    }

    .additional-buttons {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 24px;
        margin-bottom: 24px;
    }
}
</style>

