<template>
    <div class="modal-container custom-modal kyc-intro-modal">
        <div class="modal-header" style="align-items: baseline !important;">
            <h2 class="heading-2 font-medium color-black" style="width:90%">
                {{ title }}
            </h2>
            <button class="btn-p-0 color-dark-grey small-text ml-0 mt-20" @click="$emit('close')">
                <i class="icon-times"></i>
            </button>
        </div>
        <div class="modal-body">
            <v-img
                src="/images/kyc-modals/kyc-modal-img3.svg"
                width="70%"
                class="mb-5 m-auto"
            />
            <p class="text-base leading-130 color-grey-2 mb-2">
                {{ description }}
            </p>
        </div>

        <div class="modal-footer mt-3">
            <BaseButton
                type="button"
                class="btn-big mt-4 btn-purple color-white w-100 v-btn-prevent-opacity-change"
                @click="$emit('start')"
            >
                Start verification
            </BaseButton>
        </div>
    </div>
</template>

<script>
export default {
    name: "KYCIntroDefault",
    computed: {
        option(){
            return this.$store.state.kyc.modalOptions.option;
        },
        isDemo(){
            return this.$store.state.kyc.options.type === 'demo' ||
                this.$store.state.kyc.options.rejected?.type === 'demo'
        },
        title(){
            return this.option === 'legal_entity' || this.isDemo?
                'Complete KYC verification':
                'Verify your ID for added security!';
        },
        description(){
            return this.option === 'legal_entity'?
                    /*Request to legal entity*/
                `You're attempting to open a Native company. Please
                complete our KYC process to ensure compliance and access
                all features. It's a quick and straightforward step towards
                unlocking your account's full potential.`:
                this.isDemo?
                    /*Demo is over*/
                `We've noticed that your account activity has exceeded a
                significant monetary threshold. To maintain account security
                and compliance, please complete our KYC verification
                process. By completing this process, you help us ensure our
                platform's integrity and protect your account from potential
                risks.`:
                    /*Default*/
                `In order to unlock the full range of features offered by Native Teams
                and to access the available cards in your country, it is necessary to
                confirm your identity in accordance with KYC and AML regulations.`;
        }
    }
};
</script>
