<template>
<div>
    <modal
        close-color="#8F8F8F"
        v-model="dialog"
        :width="contactObj.values.worker && '34.4375rem' || '49.8125rem'"
        :padding="false"
        :close-icon-show="false"
        classNames="event-new"
    >
        <template slot="content">
            <div class="col-12 modal-dialog modal-xl add-team-member-modal"
                 :class="contactObj.values.worker && 'modal-md' || 'modal-sm'">
                <div class="">
                    <div class="modal-header d-flex justify-space-between align-center py-4">
                        <h3 class="modal-title heading-2 color-purple">
                            {{
                                contactObj.values.worker ? 'Edit Profile' : (contactObj.values.id && 'Edit Contact' || 'Add Contact')
                            }}
                        </h3>
                        <button class="tm-btn h-auto pa-0" @click="dialog = false">
                            <icon name="icon-times" size="14" color="#8F8F8F"></icon>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form class="modal-form add-team-member-form">
                            <div class="row form-row justify-space-between">
                                <div class="form-group col-12  pb-sm-3 pb-0 "
                                     :class="contactObj.values.worker && 'col-md-8' || 'col-md-6'">

                                    <div class="form-group">
                                        <tm-input
                                            label="Name of Contact"
                                            class="w-100"
                                            name="name"
                                            id="contactName"
                                            v-validate="contactObj.rules.name"
                                            v-model="contactObj.values.name"
                                            :error-messages="errors.collect('name')"
                                            data-vv-as="Name"
                                        >
                                            <template slot="label">
                                                <label for="contactName" class="small-text bold-text color-black mb-1">
                                                    Name of Contact <span class="color-red ml-1">*</span>
                                                </label>
                                            </template>
                                        </tm-input>
                                    </div>

                                    <div class="form-group">
                                        <tm-input
                                            label="Email Address"
                                            :placeholder="`e.g. `+$store.state.systemEmails.support"
                                            class="w-100"
                                            name="email"
                                            id="emailName"
                                            v-validate="emailRules"
                                            v-model="contactObj.values.email"
                                            :error-messages="emailErrorMsg"
                                            data-vv-as="Email"
                                        >
                                            <template slot="label">
                                                <label for="emailName" class="small-text bold-text color-black mb-1">
                                                    Email Address <span class="color-red ml-1">*</span>
                                                </label>
                                            </template>
                                        </tm-input>
                                    </div>
                                    <div class="form-group phone-form-group">
                                        <tm-select-phone
                                            v-if="dialog"
                                            class="w-100"
                                            id="phone"
                                            name="phone"
                                            label="Phone Number"
                                            v-model="contactObj.values.phone"
                                            :required="contactModel && contactModel.worker"
                                            @valid="phoneValid"
                                            @code="(e) => countryCode = e"
                                            :error-messages="errors.collect('phone')"
                                            :country-code="selectedCountry && (!contactObj.values.phone || countryCode === contactObj.values.phone) ? selectedCountry.code: false"
                                        >
                                            <template v-if="contactModel && contactModel.worker" slot="label">
                                                <label for="contactPhone" class="small-text bold-text color-black mb-1">
                                                    Phone <span class="color-red ml-1">*</span>
                                                </label>
                                            </template>
                                        </tm-select-phone>
                                    </div>
                                    <div class="form-group">
                                        <tm-input
                                            label="Company Name"
                                            class="w-100"
                                            name="organization_name"
                                            v-validate="contactObj.rules.organization_name"
                                            v-model="contactObj.values.organization_name"
                                            :error-messages="errors.collect('organization_name')"
                                            data-vv-as="company name"
                                        ></tm-input>
                                    </div>

                                    <!--                                        <div class="form-group">-->


                                    <!--                                            <tm-advanced-select-->
                                    <!--                                                :is-sort="false"-->
                                    <!--                                                id="country"-->
                                    <!--                                                label="Country"-->
                                    <!--                                                name="country"-->
                                    <!--                                                search-key="name"-->
                                    <!--                                                :search="true"-->
                                    <!--                                                :search-autoload="true"-->
                                    <!--                                                :search-route="$store.state.getPath+'search-countries'"-->
                                    <!--                                                :search-additional-params="{id: contactObj.values.country_id}"-->
                                    <!--                                                v-validate="contactObj.rules.country_id"-->
                                    <!--                                                v-model="contactObj.values.country_id"-->
                                    <!--                                                item-value="id"-->
                                    <!--                                                @inputObject="countrySelected"-->
                                    <!--                                                :error-messages="errors.collect('country')"-->
                                    <!--                                            >-->
                                    <!--                                                <template v-slot:selection="{ item }">-->
                                    <!--                                                    <div class="d-flex align-center">-->
                                    <!--                                                        <avatar size="20" :avatar="item.flag"></avatar>-->
                                    <!--                                                        <span class="tm-medium-text tm-color-black mr-1">{{ item.name }}</span>-->
                                    <!--                                                    </div>-->
                                    <!--                                                </template>-->
                                    <!--                                                <template v-slot:item="{ item }">-->
                                    <!--                                                    <div class="d-flex align-center">-->
                                    <!--                                                        <avatar size="20" :avatar="item.flag"></avatar>-->
                                    <!--                                                        <span class="tm-medium-text tm-color-black mr-1">{{ item.name }}</span>-->
                                    <!--                                                    </div>-->
                                    <!--                                                </template>-->
                                    <!--                                            </tm-advanced-select>-->
                                    <!--                                        </div>-->
                                    <!--                                        <div class="form-group mb-4">-->
                                    <!--                                            <tm-input-->
                                    <!--                                                label="ZIP Code"-->
                                    <!--                                                class="w-100"-->
                                    <!--                                                :bottom-offset="false"-->
                                    <!--                                                name="zip"-->
                                    <!--                                                v-validate="contactObj.rules.zip"-->
                                    <!--                                                v-model="contactObj.values.zip"-->
                                    <!--                                                :error-messages="errors.collect('zip')"-->
                                    <!--                                                data-vv-as="zip"-->
                                    <!--                                            ></tm-input>-->
                                    <!--                                        </div>-->
                                    <!--                                        <div class="form-group ">-->
                                    <!--                                            <tm-address-input-->
                                    <!--                                                :country="selectedCountry? selectedCountry.code: null"-->
                                    <!--                                                id="address"-->
                                    <!--                                                label="Address"-->
                                    <!--                                                v-model="contactObj.values.address"-->
                                    <!--                                                name="address"-->
                                    <!--                                                v-validate="contactObj.rules.address"-->
                                    <!--                                                :error-messages="errors.collect('address')"-->
                                    <!--                                                data-vv-as="address"-->
                                    <!--                                            ></tm-address-input>-->
                                    <!--                                        </div>-->
                                    <!--                                        <div class="form-group mbe.g. Fruskogorska 10-4" v-if="contactType !== 'worker'">
                                                                                <tm-input
                                                                                    label="VAT (optional)"
                                                                                    class="w-100"
                                                                                    :bottom-offset="false"
                                                                                    name="vat"
                                                                                    v-validate="contactObj.rules.vat"
                                                                                    v-model="contactObj.values.vat"
                                                                                    :error-messages="errors.collect('vat')"
                                                                                    data-vv-as="vat"
                                                                                ></tm-input>
                                                                            </div>-->
                                    <template v-if="selectedCountry && selectedCountry.code === 'US'">
                                        <div class="form-group">
                                            <tm-input
                                                label="Routing Number"
                                                class="w-100"
                                                name="routing_number"
                                                v-model="contactObj.values.routing_number"
                                                :error-messages="errors.collect('routing_number')"
                                                data-vv-as="Routing Number"
                                            ></tm-input>
                                        </div>
                                    </template>

                                    <template v-if="selectedCountry && selectedCountry.code === 'RO'">
                                        <div class="form-group">
                                            <tm-input
                                                label="CIF"
                                                class="w-100"
                                                name="romania_cif"
                                                v-validate="contactObj.rules.romania_cif"
                                                v-model="contactObj.values.romania_cif"
                                                :error-messages="errors.collect('romania_cif')"
                                                data-vv-as="CIF"
                                            ></tm-input>
                                        </div>

                                        <div class="form-group">
                                            <tm-input
                                                label="Registrul Comertului"
                                                class="w-100"
                                                name="romania_registrul_comertului"
                                                v-validate="contactObj.rules.romania_registrul_comertului"
                                                v-model="contactObj.values.romania_registrul_comertului"
                                                :error-messages="errors.collect('romania_registrul_comertului')"
                                                data-vv-as="Registrul Comertului"
                                            ></tm-input>
                                        </div>

                                    </template>


                                    <template v-if="bankDetails">
                                        <div class="form-group">
                                            <tm-input
                                                label="Bank Name"
                                                class="w-100"
                                                name="bank_name"
                                                id="contactBankName"
                                                v-validate="contactObj.rules.bank_name"
                                                v-model="contactObj.values.bank_name"
                                                :error-messages="errors.collect('bank_name')"
                                                data-vv-as="Bank Name"
                                            >
                                                <template slot="label">
                                                    <label for="contactBankName"
                                                           class="small-text bold-text color-black mb-1">
                                                        Bank Name <span class="color-red ml-1">*</span>
                                                    </label>
                                                </template>
                                            </tm-input>
                                        </div>

                                        <tm-advanced-select
                                            id="country"
                                            ref="countrySelect"
                                            v-model="contactObj.values.country_bank_id"
                                            v-validate="contactObj.rules.country_bank_id"
                                            class="w-100"
                                            :is-sort="false"
                                            label="Country of Bank"
                                            name="country_bank_id"
                                            search-key="name"
                                            :search="true"
                                            :select-first="false"
                                            :search-autoload="true"
                                            :search-route="$store.state.getPath + 'search-countries'"
                                            :search-additional-params="{ id: contactObj.values.country_bank_id }"
                                            item-value="id"
                                            :error-messages="errors.collect('country_bank_id')"
                                            data-vv-as="country bank_"
                                            @inputObject="selectCountry($event)"
                                        >
                                            <template slot="label">
                                                <label for="country" class="small-text bold-text color-black mb-1">
                                                    Country of Bank <span class="color-red ml-1">*</span>
                                                </label>
                                            </template>
                                            <template #selection="{ item }">
                                                <div class="d-flex align-center">
                                                    <avatar size="1.25rem" :object="item"></avatar>
                                                    <span class="tm-medium-text tm-color-black mr-1">{{
                                                            item.name
                                                        }}</span>
                                                </div>
                                            </template>
                                            <template #item="{ item }">
                                                <div class="d-flex align-center">
                                                    <avatar size="1.25rem" :object="item"/>
                                                    <span class="tm-medium-text tm-color-black mr-1">{{
                                                            item.name
                                                        }}</span>
                                                </div>
                                            </template>
                                        </tm-advanced-select>

                                        <label for="contactBankType" class="small-text bold-text color-black mb-1">
                                            Bank Details <span class="color-red ml-1">*</span>
                                        </label>
                                        <div class="d-flex w-100">
                                            <tm-advanced-select
                                                id="contactBankType"
                                                :width="bankType === 'iban'? '5rem': '9rem'"
                                                border-radius="0.1875rem 0 0 0.1875rem"
                                                v-model="bankType"
                                                item-value="value"
                                                :items="bankTypes"
                                                direction="between"
                                                :title="true"
                                                class="small-text drop-acc-iban"
                                                :class="bankType !== 'iban'? 'drop-acc-iban-large-width' : 'drop-acc-iban-small-width'"
                                            ></tm-advanced-select>
                                            <div class="w-100">
                                                <div v-show="bankType === 'iban'" class="skip-flex-wrapper">
                                                    <tm-input
                                                        border-radius="0 0.1875rem 0.1875rem 0"
                                                        class="w-100"
                                                        placeholder="AA1234567890"
                                                        name="iban"
                                                        v-model="contactObj.values.iban"
                                                        v-validate="bankType === 'iban'? contactObj.rules.iban: ''"
                                                        :error-messages="errors.collect('iban')"
                                                        data-vv-as="IBAN"
                                                    ></tm-input>
                                                </div>
                                                <div v-show="bankType === 'account_number'" class="skip-flex-wrapper">
                                                    <tm-input
                                                        border-radius="0 0.1875rem 0.1875rem 0"
                                                        class="w-100"
                                                        name="account_number"
                                                        v-model="contactObj.values.account_number"
                                                        v-validate="bankType === 'account_number'? contactObj.rules.account_number: ''"
                                                        :error-messages="errors.collect('account_number')"
                                                        data-vv-as="Account number"
                                                    ></tm-input>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="form-group">
                                            <tm-input
                                                label="SWIFT"
                                                class="w-100"
                                                name="swift"
                                                id="contactSWIFT"
                                                v-model="contactObj.values.swift"
                                                v-validate="swiftRules"
                                                :error-messages="errors.collect('swift')"
                                                data-vv-as="SWIFT"
                                            >
                                            </tm-input>
                                        </div>
                                    </template>


                                    <div class="form-group"
                                         v-if="selectedOutCurrency && selectedOutCurrency.code === 'MKD' && selectedBankCountry && selectedBankCountry.code === 'MK'">
                                        <tm-input
                                            label="Beneficiary's unique identifying number"
                                            class="w-100"
                                            name="identifying_number"
                                            v-validate="contactObj.rules.identifying_number"
                                            v-model="contactObj.values.identifying_number"
                                            :error-messages="errors.collect('identifying_number')"
                                            data-vv-as="beneficiary unique identifying number"
                                        ></tm-input>
                                    </div>

                                    <div class="form-group">
                                        <tm-input
                                            :label="selectedCountry && selectedCountry.code === 'PL' ? 'NIP (optional)' : 'VAT number'"
                                            class="w-100"
                                            name="vat_number"
                                            v-validate="contactObj.rules.vat"
                                            v-model="contactObj.values.vat"
                                            :error-messages="errors.collect('vat_number')"
                                            data-vv-as="VAT number"
                                        ></tm-input>
                                    </div>
                                </div>
                                <div class="form-group col-12 pt-sm-3 pt-0"
                                     :class="[contactObj.values.worker && 'col-md-8' || 'col-md-6']">


                                    <div class="form-group">
                                        <tm-advanced-select
                                            :is-sort="false"
                                            id="contactCountry"
                                            label="Country"
                                            name="country"
                                            search-key="name"
                                            :search="true"
                                            :search-autoload="true"
                                            :search-route="$store.state.getPath+'search-countries'"
                                            :search-additional-params="{id: contactObj.values.country_id}"
                                            v-validate="'required'"
                                            v-model="contactObj.values.country_id"
                                            item-value="id"
                                            @inputObject="countrySelected"
                                            :error-messages="errors.collect('country')"
                                            data-vv-as="Country"
                                        >
                                            <template slot="label">
                                                <label for="contactCountry"
                                                       class="small-text bold-text color-black mb-1">
                                                    Country <span class="color-red ml-1">*</span>
                                                </label>
                                            </template>
                                            <template v-slot:selection="{ item }">
                                                <div class="d-flex align-center">
                                                    <avatar size="20" :avatar="item.flag"></avatar>
                                                    <span class="tm-medium-text tm-color-black mr-1">{{
                                                            item.name
                                                        }}</span>
                                                </div>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <div class="d-flex align-center">
                                                    <avatar size="20" :avatar="item.flag"></avatar>
                                                    <span class="tm-medium-text tm-color-black mr-1">{{
                                                            item.name
                                                        }}</span>
                                                </div>
                                            </template>
                                        </tm-advanced-select>
                                    </div>

                                    <div class="form-group">
                                        <tm-input
                                            id="contactAddress"
                                            label="Address Line 1"
                                            v-model="contactObj.values.address"
                                            name="address"
                                            v-validate="contactObj.rules.address"
                                            :error-messages="errors.collect('address')"
                                            data-vv-as="Address"
                                        >
                                            <template slot="label">
                                                <label for="contactAddress"
                                                       class="small-text bold-text color-black mb-1">
                                                    Address Line 1 <span class="color-red ml-1">*</span>
                                                </label>
                                            </template>
                                        </tm-input>
                                    </div>
                                    <div class="form-group ">
                                        <tm-input
                                            id="address_two"
                                            label="Address Line 2"
                                            v-model="contactObj.values.address_two"
                                            name="address_two"
                                            v-validate="contactObj.rules.address_two"
                                            :error-messages="errors.collect('address_two')"
                                        ></tm-input>
                                    </div>
                                    <div class="form-group ">
                                        <tm-input
                                            id="town_city"
                                            label="Town or City"
                                            v-model="contactObj.values.town_city"
                                            name="town_city"
                                            v-validate="fromMoney ? 'required' : contactObj.rules.town_city"
                                            :error-messages="errors.collect('town_city')"
                                            data-vv-as="Town or City"
                                        >
                                            <template v-if="fromMoney" slot="label">
                                                <label for="town_city" class="small-text bold-text color-black mb-1">
                                                    Town or City <span class="color-red ml-1">*</span>
                                                </label>
                                            </template>
                                        </tm-input>
                                    </div>
                                    <div class="form-group ">
                                        <tm-input
                                            id="region"
                                            label="Region"
                                            v-model="contactObj.values.region"
                                            name="address"
                                            v-validate="contactObj.rules.region"
                                            :error-messages="errors.collect('region')"
                                        ></tm-input>
                                    </div>
                                    <div class="form-group">
                                        <tm-input
                                            label="ZIP Code"
                                            class="w-100"
                                            name="zip"
                                            v-validate="fromMoney ? 'required' : contactObj.rules.zip"
                                            v-model="contactObj.values.zip"
                                            :error-messages="errors.collect('zip')"
                                            data-vv-as="Zip"
                                        >
                                            <template v-if="fromMoney" slot="label">
                                                <label class="small-text bold-text color-black mb-1">
                                                    ZIP Code <span class="color-red ml-1">*</span>
                                                </label>
                                            </template>
                                        </tm-input>
                                    </div>
                                    <div class="form-group"
                                         v-if="selectedBankCountry?.code ==='GB' && bankType!=='iban'">
                                        <tm-input
                                            label="Sort Code"
                                            class="w-100"
                                            name="sort_code"
                                            v-validate="'required'"
                                            v-model="contactObj.values.sort_code"
                                            :error-messages="errors.collect('sort_code')"
                                            data-vv-as="sort_code"
                                        >
                                            <template slot="label">
                                                <label class="small-text bold-text color-black mb-1">
                                                    Sort Code <span class="color-red ml-1">*</span>
                                                </label>
                                            </template>
                                        </tm-input>
                                    </div>
                                </div>
                                <div class="form-group avatar-img-form-group col-12 col-md-4"
                                     v-if="contactObj.values.worker">
                                    <tm-avatar-input
                                        :preview="contactObj.values.avatar"
                                        id="avatar"
                                        name="avatar"
                                        v-validate="contactObj.rules.avatar_file"
                                        v-model="contactObj.values.avatar_file"
                                        :error-messages="errors.collect('avatar_file')"
                                        @clear="contactObj.clearAvatar()"
                                        @cancelClear="contactObj.values.clear_avatar = 0"
                                        :crop="true"
                                        :crop-rounded="true"
                                        :crop-height="200"
                                        :crop-width="200"
                                        :crop-dialog-width="400"
                                        :size="'7.5rem'"
                                    ></tm-avatar-input>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer py-0">
                        <v-row no-gutters>
                            <div class="d-flex justify-space-between align-center py-4"
                                 style="padding-top: 1.7rem!important;">
                                <btn
                                    @click="create()"
                                    :loading="loading"
                                    :text=" contactObj.values.worker ? 'Update Profile' : ( contactObj.values.id && 'Update Contact' || 'Create Contact') "
                                    :small="false"
                                ></btn>
                            </div>
                        </v-row>
                    </div>
                </div>
            </div>

        </template>
    </modal>
</div>
</template>
<script>
import Modal from "~/shared/components/Modal";
import Btn from "~/shared/components/Btn";
import TmInput from "~/shared/components/form/TmInput";
import TmAdvancedSelect from "~/shared/components/form/TmAdvancedSelect";
import TmAvatarInput from "~/shared/components/form/TmAvatarInput";
import Helpers from '~/shared/mixins/Helpers';
import InvoiceContact from "../../models/InvoiceContact";
import Avatar from "~/shared/components/Avatar";
import TmSelectPhone from "~/shared/components/form/TmSelectPhone";
import {mapState, mapGetters, mapActions} from "vuex";

export default {
    name: 'InvoiceContactForm',
    components: {
        TmSelectPhone,
        Avatar,
        TmAvatarInput,
        TmAdvancedSelect,
        TmInput,
        Btn,
        Modal
    },
    mixins: [Helpers],
    props: {
        value: {
            required: true,
            type: Boolean
        },
        contactModel: {
            default: null
        },
        contactType: {
            default: 'worker'
        },
        bankDetails: {
            default: false
        },
        selectedOutCurrency: {
            default: false
        },
        fromMoney: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {

            dialog: this.value,
            isValid: false,
            countryCode: false,
            loading: false,
            contactObj: new InvoiceContact(this.contactModel || {bank_details: this.bankDetails}),
            selectedCountry: null,
            selectedBankCountry: null,

            bankType: 'iban',
            bankTypes: [
                {value: 'account_number', name: 'Account number'},
                {value: 'iban', name: 'IBAN'}
            ]
        }
    },
    computed: {
        ...mapGetters(['euAndEeaCountriesWithGB']),
        swiftRules() {
            if (this.selectedBankCountry?.code && !this.euAndEeaCountriesWithGB.includes(this.selectedBankCountry.code)) {
                return 'required|max:255';
            } else {
                return 'max:255';
            }
        },
        emailRules() {
            return this.$store.state.info ?
                this.contactObj.rules.email + '|excluded:' + this.$store.state.info.email :
                this.contactObj.rules.email;
        },
        emailErrorMsg() {
            return this.errors.items.find(i => i.field === 'email' && i.rule === 'excluded') ?
                [`Recipient's email must be different from yours`] :
                this.errors.collect('email');
        }
    },
    watch: {
        'selectedCountry.code'(v) {
            if (v !== 'US') {
                this.contactObj.values.routing_number = '';
            }
        },
        /*contactObj() {
            if (this.contactObj.values.worker) {
                if (this.isAdmin) {
                    this.contactObj.rules.organization_name = 'required|min:3|max:100'
                }
            }
        },*/

        value() {
            this.dialog = this.value
        },
        dialog() {
            this.step = 1;
            this.contactObj = new InvoiceContact(this.contactModel || {bank_details: this.bankDetails});
            this.setBankType();
            this.$emit('input', this.dialog);

            this.dialog ? this.$validator.pause() : this.errors.clear();
        },
        bankType() {
            if (this.bankType === 'iban') {
                this.contactObj.values.account_number = null;
            } else {
                this.contactObj.values.iban = null;
            }
            this.$validator.reset();
        }
    },
    methods: {
        selectCountry(e) {
            this.selectedBankCountry = e
            this.countryOfBank = e
        },
        setBankType() {
            this.bankType = this.contactObj.values.iban || !this.contactObj.values.account_number ? 'iban' : 'account_number'
        },
        countrySelected(e) {
            if (e) {
                this.selectedCountry = e
            }
        },
        phoneValid(e) {
            this.errors.remove('phone');
            this.isValid = e.valid;
        },

        create() {
            this.$validator.resume();
            if (this.contactObj.values.phone && !this.isValid && this.countryCode !== this.contactObj.values.phone) {
                this.errors.add({
                    field: 'phone',
                    msg: 'The phone field is not correct'
                });
            }

            this.$validator.validateAll().then(valid => {
                let method;
                if (valid) {
                    this.loading = true;

                    this.contactObj.values.contactType = this.contactType

                    if (this.contactObj.values.worker) {
                        method = this.contactObj.update(this.$store.state.path + 'invoice/update-user-specific-fields');
                    } else {
                        method = this.contactObj.values.id ?
                            this.contactObj.update(this.$store.state.path + 'people/contacts/update/' + this.contactObj.values.id) :
                            this.contactObj.create(this.$store.state.path + 'people/contacts/store');
                    }

                    method.then(response => {
                        this.loading = false;
                        this.dialog = false;
                        // if (this.contactObj.values.worker) {
                        //
                        //     this.$store.state.primaryWorker.name = this.contactObj.values.name
                        //     this.$store.state.primaryWorker.email = this.contactObj.values.email
                        //     this.$store.state.primaryWorker.country_id = this.contactObj.values.country_id
                        //     this.$store.state.primaryWorker.avatar = response.data.avatar
                        //     this.$store.state.primaryWorker.phone = this.contactObj.values.phone
                        //     this.$store.state.primaryWorker.address = this.contactObj.values.address
                        //     this.$store.state.primaryWorker.zip = this.contactObj.values.zip
                        //     if(this.isAdmin){
                        //         this.$store.state.organization.name = this.contactObj.values.organization_name
                        //     }
                        //
                        //     this.$store.commit("updateData", {
                        //         key: 'primaryWorker',
                        //         data: this.$store.state.primaryWorker
                        //     });
                        // }
                        if (!this.contactObj.values.id) {
                            this.$emit('created', response.data.contact)
                        } else {
                            this.$emit('updated', this.contactObj.values.id)
                        }
                    }).catch(error => {
                        this.loading = false;
                        let erStr = [];
                        for (const [key, value] of Object.entries(error.response.data.errors)) {
                            erStr.push(`${key}: ${value}`);
                        }
                        this.$store.commit("setAlert", {
                            type: "error",
                            message: erStr.length > 0 ? error.response.data.message + ' (' + erStr.join(', ') + ')' : error.response.data.message,
                        });
                    })
                }
            })
        }
    },
    mounted() {
        this.setBankType();
    }
}
</script>
