import store from "~/store";
import {getAllWalletsAndCheck, getWalletsAndSetActiveOne, voultSetActiveCurrency} from "../../functions";

export default [
    {
        path: "/" ,
        alias: ["wallet-overview", "/dashboard"],
        name: "WalletOverview",
        components: {
            DataInsightsWrapper: () =>
                import("~/modules/wallet/components/WalletDashboard/DataInsightsWrapper.vue"),
            ProgressBar: () =>
                import("~/modules/wallet/components/WalletDashboard/ProgressBar.vue"),
            UpsellBanner: () =>
                import("~/modules/wallet/components/WalletDashboard/UpsellBanner.vue"),
            QuickActions: () =>
                import("~/modules/wallet/components/WalletDashboard/QuickActions.vue"),
            CardsList: () => import("~/modules/wallet/components/WalletDashboard/CardsList.vue"),
            CurrenciesList: () =>
                import("~/modules/wallet/components/WalletDashboard/CurrenciesList.vue"),
            WalletTransactionsTable: () =>
                import("~/modules/wallet/components/WalletDashboard/WalletTransactionsTable.vue"),
        },
        beforeEnter: (to, from, next) => {
            if (to.query["equalsCardStatus"]) {
                store
                    .dispatch(
                        "dashboard/setEqualsCardStatus",
                        to.query["equalsCardStatus"]
                    )
                    .then(() => {
                        next();
                    });
            } else {
                store.dispatch('cardModule/unsetActiveCard');
                next();
            }
        },
        meta: {
            title: "Wallet",
            app: 'wallet',
            middleware: 'auth'
        },
    },
    {
        path: "/wallet/empty",
        alias: "wallet-empty",
        name: "WalletOverviewEmpty",
        beforeEnter: (to, from, next) => {
            store
                .dispatch("dashboard/emptyState", null)
                .then(() => {
                    store.dispatch("dashboard/clearFilters", null).then(() => {
                        next();
                    });
                })
                .catch((error) => {
                    console.error("Error emptying wallet:", error);
                    // Handle the error, maybe redirect or show a message
                    next(false); // Optionally, stop navigation or redirect
                });
        },
        components: {
            DataInsightsWrapper: () =>
                import("~/modules/wallet/components/WalletDashboard/DataInsightsWrapper.vue"),
            UpsellBanner: () =>
                import("~/modules/wallet/components/WalletDashboard/UpsellBanner.vue"),
            QuickActions: () =>
                import("~/modules/wallet/components/WalletDashboard/QuickActions.vue"),
            CardsList: () => import("~/modules/wallet/components/WalletDashboard/CardsList.vue"),
            CurrenciesList: () =>
                import("~/modules/wallet/components/WalletDashboard/CurrenciesList.vue"),
            WalletTransactionsTable: () =>
                import("~/modules/wallet/components/WalletDashboard/WalletTransactionsTable.vue"),
        },
        meta: {
            title: "Wallet",
            app: 'wallet',
            middleware: 'auth'
        },
    },
    {
        path: "/wallet/wallet-table-view",
        name: "WalletTableView",
        components: {
            WalletTransactionsTable: () =>
                import("~/modules/wallet/components/WalletDashboard/WalletTransactionsTable.vue"),
        },
        meta: {
            title: "Wallet Table View",
            app: 'wallet',
            middleware: 'auth'
        },
    },
    {
        path: "/loading",
        alias: "wallet-loading",
        name: "WalletOverviewLoading",
        beforeEnter: (to, from, next) => {
            store
                .dispatch("dashboard/emptyState", null)
                .then(() => {
                    store.dispatch("dashboard/clearFilters", null).then(() => {
                        next();
                    });
                })
                .catch((error) => {
                    console.error("Error emptying wallet:", error);
                    // Handle the error, maybe redirect or show a message
                    next(false); // Optionally, stop navigation or redirect
                });
        },
        components: {
            DataInsightsWrapper: () =>
                import("~/modules/wallet/components/WalletDashboard/DataInsightsWrapper.vue"),
            UpsellBanner: () =>
                import("~/modules/wallet/components/WalletDashboard/UpsellBanner.vue"),
            QuickActions: () =>
                import("~/modules/wallet/components/WalletDashboard/QuickActions.vue"),
            CardsList: () => import("~/modules/wallet/components/WalletDashboard/CardsList.vue"),
            CurrenciesList: () =>
                import("~/modules/wallet/components/WalletDashboard/CurrenciesList.vue"),
            WalletTransactionsTable: () =>
                import("~/modules/wallet/components/WalletDashboard/WalletTransactionsTable.vue"),
        },
        meta: {
            title: "Wallet",
            layout: "FullWidthLayout",
        },
    },
    {
        path: "/wallet/:walletId/wallet-details",
        name: "WalletDetails",
        beforeEnter: (to, from, next) => {
            getWalletsAndSetActiveOne(store, to, from, next);
        },
        components: {
            WalletBalancesCard: () =>
                import("~/modules/wallet/components/WalletDashboard/WalletBalancesCard.vue"),
            WalletATMCards: () =>
                import("~/modules/wallet/components/WalletDashboard/WalletATMCards.vue"),
            WalletDetailsCard: () =>
                import("~/modules/wallet/components/WalletDashboard/WalletDetailsCard.vue"),
            SpecificWalletTransactionsTable: () =>
                import("~/modules/wallet/components/WalletDashboard/WalletTransactionsTable.vue"),
        },
        meta: {
            title: "Wallet Details",
            app: 'wallet',
            middleware: 'auth'
        },
    },
    {
        path: "/wallet/:walletId/wallet-statement",
        name: "WalletStatement",
        beforeEnter: (to, from, next) => {
            getAllWalletsAndCheck(store, to, from, next)
        },
        components: {
            WalletStatementTablesContainer: () =>
                import(
                    "~/modules/wallet/components/WalletDashboard/WalletStatementTablesContainer.vue"
                    ),
            WalletStatementMetricsCard: () =>
                import(
                    "~/modules/wallet/components/WalletDashboard/WalletStatementMetricsCard.vue"
                    ),
            StatementBalancesCard: () =>
                import("~/modules/wallet/components/WalletDashboard/StatementBalancesCard.vue"),
        },
        meta: {
            title: "Wallet Statement",
            app: 'wallet',
            middleware: 'auth'
        },
    },
    {
        path: "/wallet/:walletId/wallet-table-view",
        name: "SpecificWalletTableView",
        beforeEnter: (to, from, next) => {
            getWalletsAndSetActiveOne(store, to, from, next);
        },
        components: {
            SpecificWalletTransactionsTable: () =>
                import("~/modules/wallet/components/WalletDashboard/WalletTransactionsTable.vue"),
        },
        meta: {
            title: "Wallet Table View",
            app: 'wallet',
            middleware: 'auth'
        },
    },
    {
        path: "/wallet/:cardId/card-statement",
        name: "CardStatement",
        beforeEnter: (to, from, next) => {
            store
                .dispatch("cardModule/setActiveCardId", to.params.cardId)
                .then(() => {
                    store.dispatch("dashboard/clearFilters", null).then(() => {
                        next();
                    });
                })
                .catch((error) => {
                    console.error("Error setting selected card:", error);
                    // Handle the error, maybe redirect or show a message
                    next(false); // Optionally, stop navigation or redirect
                });
        },
        components: {
            CardStatementTablesContainer: () =>
                import(
                    "~/modules/wallet/components/WalletDashboard/CardStatementTablesContainer.vue"
                    ),
            CardStatementMetricsCard: () =>
                import("~/modules/wallet/components/WalletDashboard/CardStatementMetricsCard.vue"),
            CardStatementDetails: () =>
                import("~/modules/wallet/components/WalletDashboard/CardStatementDetails.vue"),
            ATMCardInfo: () =>
                import("~/modules/wallet/components/WalletDashboard/ATMCardInfo.vue"),
        },
        meta: {
            title: "Card Statement",
            app: 'wallet',
            middleware: 'auth'
        },
    },
    {
        path: "/wallet/:cardId/card-details",
        name: "CardDetails",
        beforeEnter: (to, from, next) => {
            store
                .dispatch("cardModule/setActiveCardId", to.params.cardId)
                .then(() => {
                    store.dispatch("dashboard/clearFilters", null).then(() => {
                        next();
                    });
                })
                .catch((error) => {
                    console.error("Error setting active card:", error);
                    // Handle the error, maybe redirect or show a message
                    next(false); // Optionally, stop navigation or redirect
                });
        },
        components: {
            ATMCardDetails: () =>
                import("~/modules/wallet/components/WalletDashboard/ATMCardDetails.vue"),
            ATMCardInfo: () =>
                import("~/modules/wallet/components/WalletDashboard/ATMCardInfo.vue"),
            CardTransactionsTable: () =>
                import("~/modules/wallet/components/WalletDashboard/CardTransactionsTable.vue"),
        },
        meta: {
            title: "Card Details",
            app: 'wallet',
            middleware: 'auth'
        },
    },
    {
        path: "/wallet/:cardId/card-table-view",
        name: "CardTableView",
        beforeEnter: (to, from, next) => {
            store
                .dispatch("cardModule/setActiveCardId", to.params.cardId)
                .then(() => {
                    store.dispatch("dashboard/clearFilters", null).then(() => {
                        next();
                    });
                })
                .catch((error) => {
                    console.error("Error setting selected  card:", error);
                    // Handle the error, maybe redirect or show a message
                    next(false); // Optionally, stop navigation or redirect
                });
        },
        components: {
            CardTransactionsTable: () =>
                import("~/modules/wallet/components/WalletDashboard/CardTransactionsTable.vue"),
        },
        meta: {
            title: "Card Table View",
            app: 'wallet',
            middleware: 'auth'
        },
    },
    {
        path: "/wallet/:vaultId/vault-overview",
        name: "VaultOverview",
        beforeEnter: (to, from, next) => {
            voultSetActiveCurrency(store, to, from, next);
        },
        components: {
            VaultInfoCard: () =>
                import("~/modules/wallet/components/WalletDashboard/VaultInfoCard.vue"),
            RewardTransactionsDataCard: () =>
                import(
                    "~/modules/wallet/components/WalletDashboard/RewardTransactionsDataCard.vue"
                    ),
            VaultTransactionsTable: () =>
                import("~/modules/wallet/components/WalletDashboard/VaultTransactionsTable.vue"),
        },
        meta: {
            title: "Vault Overview",
            app: 'wallet',
            middleware: 'auth'
        },
    },

    {
        path: "/wallet/:vaultId/vault-table-view",
        name: "VaultTableView",
        beforeEnter: (to, from, next) => {
            voultSetActiveCurrency(store, to, from, next);
        },
        components: {
            VaultTransactionsTable: () =>
                import("~/modules/wallet/components/WalletDashboard/VaultTransactionsTable.vue"),
        },
        meta: {
            title: "Vault Table View",
            app: 'wallet',
            middleware: 'auth'
        },
    },
    {
        path: "/wallet/:walletId/deposits-overview",
        name: "DepositsOverview",
        beforeEnter: (to, from, next) => {
            getWalletsAndSetActiveOne(store, to, from, next);
        },
        components: {
            DepositsInfoCard: () =>
                import("~/modules/wallet/components/WalletDashboard/DepositsInfoCard.vue"),
            DepositsTransactionsTable: () =>
                import(
                    "~/modules/wallet/components/WalletDashboard/DepositsTransactionsTable.vue"
                    ),
        },
        meta: {
            title: "Deposits Overview",
            app: 'wallet',
            middleware: 'auth'
        },
    },
    {
        path: "/wallet/:walletId/deposits-table-view",
        name: "DepositsTableView",
        beforeEnter: (to, from, next) => {
            getWalletsAndSetActiveOne(store, to, from, next);
        },
        components: {
            DepositsTransactionsTable: () =>
                import(
                    "~/modules/wallet/components/WalletDashboard/DepositsTransactionsTable.vue"
                    ),
        },
        meta: {
            title: "Deposits Table View",
            app: 'wallet',
            middleware: 'auth'
        },
    },
]
