<template>
  <v-tooltip
      v-model="val"
      v-bind="$attrs"
      @input="(e) => $emit('input', e)"
      :content-class="contentClass"
  >
    <template v-slot:activator="slotProps">
      <slot name="activator" v-bind="slotProps" />
    </template>
    <slot></slot>
  </v-tooltip>
</template>

<script>
import menuMixin from "~/shared/mixins/menuMixin";

export default {
  name: "BaseTooltip",

  mixins: [menuMixin],

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      val: this.value,
    };
  },

  watch: {
    value(val) {
      this.val = val;
    },
  },
};
</script>
