<template>
  <div class="base-filter-tabs">
    <!-- Iterate over options array to create filter buttons -->
    <BaseButton
      v-for="option in options"
      :key="option[itemValue]"
      :class="{
        'base-filter-tab-button--active': selectedOption === option[itemValue], // Apply active class if selectedOption matches option[itemValue]
      }"
      class="base-filter-tab-button"
      @click="handleClickFilterButton(option[itemValue])"
    >
      <!-- Display button text from option[itemText] -->
      <p class="text-base text-dark-blue-500">{{ option[itemText] }}</p>
    </BaseButton>
  </div>
</template>

<script>
export default {
  name: "WlBaseFilterTabs",

  props: {
    value: {
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    itemText: {
      type: String,
      default: "name", // Default prop for displaying button text
    },
    itemValue: {
      type: String,
      default: "value", // Default prop for identifying button value
    },
  },

  data() {
    return {
      selectedOption: this.value, // Track currently selected option
    };
  },

  methods: {
    handleClickFilterButton(val) {
      this.selectedOption = val; // Update selectedOption on button click
      this.$emit("input", val); // Emit input event to parent component with new value
    },
  },

  watch: {
    value(val) {
      this.selectedOption = val; // Update selectedOption when parent updates value prop
    },
  },
};
</script>

<style scoped lang="scss">
.base-filter-tabs {
  display: flex;
  padding: 4px;
  border-radius: 20px;
  background: white;
  overflow-y: auto;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */

  &::-webkit-scrollbar {
    display: none !important;
  }

  .base-filter-tab-button {
    min-width: 144px !important;
    padding: 16px 24px;
    flex: 1;
    border-radius: 16px !important;
    height: 48px !important;
    transition: background-color 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);

    &:hover {
      &::before {
        opacity: 0 !important; // Hide pseudo-element on hover
      }

      &:not(.base-filter-tab-button--active) {
        p {
          color: black !important; // Change text color on hover if not active
        }
      }
    }

    &.base-filter-tab-button--active {
      background-color: var(
        --main-purple-color
      )!important; // Background color when button is active

      p {
        font-weight: 500;
        color: white !important; // Text color when button is active
      }
    }

    p {
      line-height: 100% !important;
      transition: color 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
      font-weight: 400;
    }
  }
}
</style>
