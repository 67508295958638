<!-- @format -->

<template>
    <div>
        <div class="uploaded-files-list-container" :class="buttonStyles ? 'file-uploader' : ''" v-bind="$attrs">
            <BaseFileDropArea
                v-show="!hideButton"
                ref="Button"
                @input="input"
                :is-link="isLink"
                :multiple="multiple"
                :error-messages="errorMessages"
                :accept="accept"
            >
                <template v-if="$slots.default" v-slot:default="{ onClick }">
                    <slot name="default" />
                </template>
                <div v-else class="align-center d-flex flex-column px-2">
                    <i class="icon-file mb-2 color-grey-2"></i>
                    <p class="paragraph color-grey-2 mb-3 text-center">
                        {{ placeholder }}
                    </p>
                    <base-button v-if="!buttonStyles" class="btn-md btn-purple-outline">
                        Select file
                    </base-button>
                    <WlBaseButton v-else
                        :class="buttonStyles"
                    >
                        Select file
                    </WlBaseButton>
                </div>
            </BaseFileDropArea>
            <base-files-list
                v-if="!buttonStyles"
                :attachments="attachments"
                :checkMark="checkMark"
                :is-invalid="itemIsInvalid"
                @remove="removeItem($event)"
            >
                <template v-if="$scopedSlots['item-icon']" v-slot:icon="{item, index}">
                    <slot
                        name="item-icon"
                        v-bind="{ item, index }"
                    ></slot>
                </template>
            </base-files-list>
        </div>
        <div v-if="buttonStyles">
            <base-files-list
                :attachments="attachments"
                :checkMark="false"
                class="uploaded-list-style"
                :is-invalid="itemIsInvalid"
                @remove="removeItem($event)"
            >
                <template v-if="$scopedSlots['item-icon']" v-slot:icon="{item, index}">
                    <slot
                        name="item-icon"
                        v-bind="{ item, index }"
                    ></slot>
                </template>
            </base-files-list>
        </div>
    </div>
</template>

<script>
import { format } from 'date-fns';
import BaseFileDropArea from "./elements/BaseFileDropArea";

export default {
    name: 'BaseUploadFiles',
    components: {BaseFileDropArea},
    inheritAttrs: false,

    props: {
        value: {
            default: null,
        },
        isLink: {
            default: false,
        },
        preview: {
            default: () => [],
        },
        checkMark: {
            type: Boolean,
            default: true,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        hideButton: {
            type: Boolean,
            default: false,
        },
        accept: {
            type: [String, Array],
            default: '*',
        },
        errorMessages: {
            default: () => [],
        },
        placeholder: {
            default: 'Drop your files here'
        },
        itemIsInvalid: {
            type: Function|null,
            default: null
        },
        buttonStyles: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            val: this.value || (this.multiple? []: null),
            attachments: [],
            deletes: []
        };
    },

    computed: {
        fileAttachments() {
            return this.attachments.filter(a => 'index' in a);
        },

        previewAttachments() {
            return this.attachments.filter(a => 'id' in a);
        },
    },

    methods: {
        activate(){
            this.$refs.Button.activate();
        },

        input(val){
            if (val){
                this.val = Array.isArray(this.value)? this.value.concat(val): val;
                this.addFiles(Array.isArray(val)? val: [val]);
                this.$emit('input', this.val);
            }
        },

        addFiles(files) {
            const length = this.fileAttachments.length;
            for (let i = length; i < length + files.length; i++) {
                const file = files[i - length];
                this.setFile(file);
            }
        },

        setFile(file){
            this.attachments.push({
                index: this.fileAttachments.length,
                name: file.name,
                uri: URL.createObjectURL(file),
                datetime: format(new Date(), 'dd/MM/yyyy, hh:mm a'),
                signed: 0,
            });
        },

        removeItem(i) {
            if ('index' in this.attachments[i]) {
                this.removeFile(i);
            } else if ('id' in this.attachments[i]) {
                this.removePreview(i);
            }
        },

        removePreview(i) {
            const obj = {...this.attachments[i]};
            this.attachments.splice(i, 1);
            this.$emit('attachmentRemoved', obj.id);
            this.deletes.push(obj.id);
            this.$emit('delete', this.deletes);
        },

        removeFile(i) {
            const obj = { ...this.attachments[i] };
            this.attachments.splice(i, 1);
            if (this.val){
                this.multiple? this.val.splice(obj.index, 1): this.val = null;
            }
        },

        setAttachments(){
            this.attachments = [];
            this.attachments = [...this.preview];
            if (Array.isArray(this.value)){
                this.addFiles(this.value);
            }else if (this.value){
                this.addFiles([this.value]);
            }
        }
    },
    mounted() {
        this.setAttachments();
    }
};
</script>
