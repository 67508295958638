export default {
    namespaced: true,
    state: {
        callback: null,
        dialog: false,
        defaultMessage: 'Check your internet connection and try again.',
        message: null,
    },
    mutations: {
        show(state, data){
            if (data && data.callback)
            state.callback = data.callback;
            state.message = data.message || state.defaultMessage;
            state.dialog = true;
        },
        close(state){
            state.callback = null;
            state.message = null;
            state.dialog = false;
        },
    },
    actions: {
        retry({state, commit}) {
            if (state.callback && typeof state.callback === 'function') {
                state.callback()
            }
            commit('close');
            commit('setNetWorkError', { errorValue: false },{ root: true });
        }
    }
}
