var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-btn',{staticClass:"tm-btn",class:[
        _vm.disabled? null: !_vm.btnOutlined && !_vm.btnText && !_vm.isHexColor? 'tm-bg-'+_vm.color: null,
        _vm.btnText? 'px-0': null,
        ],style:([
        {'min-width': _vm.minWidth? isNaN(_vm.minWidth)? _vm.minWidth: _vm.minWidth+'px': _vm.btnText? 0: null},
        {height: _vm.height? isNaN(_vm.height)? _vm.height: _vm.height+'px': _vm.small? '28px': null}
        ]),attrs:{"target":_vm.target,"disabled":_vm.disabled,"outlined":_vm.btnOutlined,"small":_vm.small,"href":_vm.href,"loading":_vm.loading,"color":_vm.disabled? null: _vm.isHexColor? _vm.color: null,"text":_vm.btnText,"icon":!!_vm.fab,"depressed":_vm.noBoxShadow},on:{"click":function($event){$event.stopPropagation();return _vm.onClick.apply(null, arguments)}}},[(_vm.fab)?_c('div',{staticClass:"d-flex justify-center align-center",class:!_vm.isHexColor? 'tm-bg-'+_vm.color: null,staticStyle:{"border-radius":"50%","width":"30px","height":"30px"},style:(_vm.isHexColor? {'background-color': _vm.color}:{})},[_c('icon',{attrs:{"color":_vm.textColor,"size":"16","name":_vm.fab}})],1):_c('div',{staticClass:"tm-small-text d-flex align-center",class:[
            _vm.btnText || _vm.btnOutlined? !_vm.isHexColor? 'tm-color-'+_vm.color:null: !_vm.checkHex(_vm.textColor)? 'tm-color-'+_vm.textColor: null,
            !_vm.textTransform? 'tm-transform-none': null
            ],style:(_vm.btnText || _vm.btnOutlined? _vm.isHexColor? {color: _vm.color}:{}: _vm.checkHex(_vm.textColor)? {color: _vm.textColor}: {})},[(_vm.icon)?_c('icon',{staticClass:"mr-2",attrs:{"name":_vm.icon,"size":"null","color":"null"}}):_vm._e(),_c('span',{staticClass:"tm-initial",domProps:{"innerHTML":_vm._s(_vm.__sanitizeHtml(_vm.text))}}),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }