<template>
  <div class="field-group field-input-group position-relative vue-numeric">
    <div class="d-flex">
      <label
        v-if="label"
        class="text-sm custom-label"
        :for="localId"
      >
        {{ label }}
      </label>

      <slot v-if="$slots.label" name="label"></slot>
      <label
        v-if="$slots.requiredText || requiredText"
        class="custom-label ml-1 text-sm color-feedback-error"
      >
        <slot v-if="$slots.requiredText" name="requiredText"></slot>
        <span>{{ requiredText }}</span>
      </label>
    </div>

    <div class="mt-1" v-if="$slots.subLabel || subLabel">
      <slot v-if="$slots.subLabel" name="subLabel"></slot>

      <label v-else class="text-sm color-grey-2">
        <span>{{ subLabel }}</span>
      </label>
    </div>

    <template v-if="hasExtraSlot">
      <div class="formatted-input" :class="customFormattedInputClasses">
        <vue-numeric
          @blur="$emit('blur')"
          :class="errorMessages.length ? 'error' : ''"
          ref="vueNumeric"
          :currency="currency"
          separator=","
          :precision="2"
          :placeholder="placeholder"
          v-model="val"
          :read-only="readonly"
          :disabled="disabled"
        >
        </vue-numeric>

        <slot name="append"></slot>
      </div>
    </template>
    <template v-else>
      <vue-numeric
        class="formatted-input"
        @blur="$emit('blur')"
        :class="errorMessages.length ? 'error' : ''"
        ref="vueNumeric"
        :currency="currency"
        separator=","
        :precision="2"
        :placeholder="placeholder"
        v-model="val"
        :read-only="readonly"
        :disabled="disabled"
      >
      </vue-numeric>
    </template>

    <WlBaseErrorMessage :messages="errorMessages" />
  </div>
</template>

<script>
import VueNumeric from "vue-numeric";

import Helpers from "~/shared/mixins/Helpers";

import debounce from "lodash/debounce";

export default {
  name: "WlBaseFormattedInput",

  mixins: [Helpers],

  props: {
    id: {
      type: String || null,
      default: null,
    },
    label: {
      type: String || null,
      default: null,
    },
    subLabel: {
      type: String || null,
      default: null,
    },
    requiredText: {
      type: [String, null],
      default: null,
    },
    placeholder: {
      type: String || null,
      default: null,
    },
    value: {
      default: 0,
    },
    disabled: {
      default: false,
    },
    readonly: {
      default: false,
    },
    currency: {
      type: String,
      required: false,
      default: null,
    },
    precision: {
      type: Number,
      required: false,
      default: 2,
    },
    errorMessages: {
      default: () => [],
    },
    hasExtraSlot: {
      type: Boolean,
      default: false,
    },
    customFormattedInputClasses: {
      type: String,
      default: ''
    },
  },

  components: {
    VueNumeric,
  },

  watch: {
    val: function (newValue) {
      if (newValue !== null || newValue !== undefined) {
        this.$emit("input", newValue);

        this.debouncedValueFormatted(newValue);
      }
    },
    value: {
      handler() {
        if (this.value !== null || this.value !== undefined) {
          this.val = this.value;
        } else {
          this.val = "";
        }
      },
      immediate: true,
    },
  },

  data() {
    return {
      val: this.value,
      localId: this.id || this.__makeId(),
    };
  },

  methods: {
    blueVueNumeric() {
      // Access the vue-numeric component using the ref attribute
      const numericComponent = this.$refs.vueNumeric;

      // Check if the component exists and it has a blur method
      if (
        numericComponent &&
        numericComponent.$el &&
        numericComponent.$el.blur
      ) {
        numericComponent.$el.blur();
      }
    },
  },

  created() {
    this.debouncedValueFormatted = debounce(function (newValue) {
      this.blueVueNumeric();

      this.$emit(
        "value-formatted",
        newValue.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    }, 2000);
  },
};
</script>


<style lang="scss" scoped>
.formatted-input {
    height: 48px;
    width: 100%;
    border-radius: 16px;
    border: 1px solid var(--typography-light-grey-2-color);
    padding: 0 16px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%;
    color: black;
    padding: 0 16px;
    outline: none;
    background-color: transparent;

    &.error {
        border-color: var(--feedback-error-color) !important;
        background-color: transparent !important;
    }
}

.formatted-input::placeholder {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%;
    color: var(--typography-dark-grey-color) !important;
}

.clean-input.formatted__input {
    border: 0;

    input.formatted__input {
        border: 0 !important;
        padding: 0;
    }
}
.formatted__input .custom-label{
  color: #838a90;
}
</style>
