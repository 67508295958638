<template>
	<div class="module-actions-container">
		<div v-if="isMobile" class="btn-back-wrapper" @click="$emit('back')">
			<v-btn icon small :ripple="false" class="btn-back">
				<BaseIcon name="chevron-left-2"/>
			</v-btn>
			<p class="back-text">Native Company</p>
		</div>
		<!-- QUICK ACTIONS -->
		<QuickActionsSection
			v-if="legalEntityIsActive && legalEntityEnabled"
			:quick-actions="getQuickActions('nativeCompany', null)"
		/>
		<!-- FEATURES -->
		<FeaturesSection
			v-if="showFeaturesSection"
			:showUpgradeBtn="showUpgradeBtn"
			:availableMenu="availableMenu"
			:worker="worker"
			:features="featuresFinal"
		/>
		<LegalEntityNotSet v-if="showLegalEntityStart"/>
		<LegalEntityActive
			v-if="legalEntityIsActive"
		/>
	</div>
</template>

<script>
import FeaturesSection from "~/components/NewNavigation/FeaturesSection.vue";
import QuickActionsSection from "~/components/NewNavigation/QuickActionsSection.vue";

import {mapGetters, mapState} from "vuex";
import {commonHelpersMixin} from "~/shared/mixins/commonHelpersMixin";
import LegalEntityActive from "../LegalEntityActive.vue";
import LegalEntityNotSet from "../LegalEntityNotSet.vue";

export default {
	name: "NativeCompany",
	
	mixins: [commonHelpersMixin],
	
	components: {LegalEntityNotSet, LegalEntityActive, QuickActionsSection, FeaturesSection},
	props: {
		worker: {
			default: null,
		},
		features: {
			default: null,
		},
		availableMenu: {
			default: null,
		},
	},
	data() {
		return {
			user: this.$store.state.worker
		};
	},
	
	computed: {
		...mapState('globalMenuModule', ['legalEntityData', 'legalEntityIsActive', 'legalEntityEnabled']),//only one initialization is needed - done in App.vue
		...mapGetters({
			getQuickActions: "navigation/getQuickActions",
			getFeatures: "navigation/getFeatures"
		}),
		currentWorker(){
			return this.$store.state.worker;
		},
		showUpgradeBtn() {
			const allowedPlans = ['tax_management', 'tax_management_higher', 'native_company', 'employer', 'client']
			return !allowedPlans.includes(this.currentWorker.plan.value);
		},
		featuresFinal() {
			let features = this.getFeatures("nativeCompany", null);
			
			if (!this.legalEntityIsActive && !this.legalEntityEnabled && this.entityStatus !== 1) {
				return features;
			}
			
			if (this.entityStatus == 1) {
				features = [
					{
						icon: "entity-wallet",
						name: "Setup",
						title: "Setup",
						module: "nativeCompany",
						route: 'legal-entity-status',
						plans: [
							"free",
							"employer",
							"remoter",
							"payments",
							"client",
							"tax-management",
							"payroll-as-a-service",
						],
						locked: [],
						badge: "pending",
					},
					{
						icon: "document",
						name: "Invoices",
						title: "Invoices",
						module: "nativeCompany",
						plans: [
							"free",
							"employer",
							"remoter",
							"payments",
							"client",
							"tax-management",
							"payroll-as-a-service",
						],
						locked: ["free", "payments", "client", "payroll-as-a-service"],
					},
					{
						icon: "file-outline",
						name: "Tax reporting",
						title: "Tax reporting",
						module: "nativeCompany",
						plans: [
							"free",
							"employer",
							"remoter",
							"payments",
							"client",
							"tax-management",
							"payroll-as-a-service",
						],
						locked: ["free", "payments", "client", "payroll-as-a-service"],
					},
					{
						icon: "tag-2",
						name: "Expense categorisation",
						title: "Expense categorisation",
						module: "nativeCompany",
						plans: [
							"free",
							"employer",
							"remoter",
							"payments",
							"client",
							"employee-seat",
							"contractor-seat",
							"tax-management",
							"payroll-as-a-service",
						],
						locked: ["free", "payroll-as-a-service"],
					},
					{
						icon: "folder-outline-light",
						name: "Legal entity documents",
						title: "Legal entity documents",
						module: "nativeCompany",
						plans: [
							"free",
							"employer",
							"remoter",
							"payments",
							"client",
							"tax-management",
							"payroll-as-a-service",
						],
						locked: ["free", "payments", "client", "payroll-as-a-service"],
					},
				];
				
				return features.map((feature) => {
					if (feature.name === "Setup") {
						feature.locked = [];
					}
					
					return feature;
				});
			}
			
			return features;
		},
		showFeaturesSection() {
			if (this.entityStatus == 1) return true;
			if (this.canSeeLegalEntityCreateBlock) {
				return false;
			}
			return !this.legalEntityIsActive && !this.legalEntityEnabled;
		},
		entityStatus() {
			return this.legalEntityData?.legal_entity_details?.status;
		},
		showLegalEntityStart() {
			if (this.legalEntityIsActive || this.entityStatus === 1) return false;
			if (this.entityStatus === 0 || this.entityStatus === 3) return true;
			if (this.canSeeLegalEntityCreateBlock) return true;
			return !this.legalEntityIsActive && this.legalEntityEnabled
		},
		canSeeLegalEntityCreateBlock() {
			let plans = [
				'client',
				'client_seat_lower',
				'client_seat',
				'grow',
				'employer',
				'employer_seat',
				'employer_seat_higher',
				'employer_seat_no_eor_zone1',
				'employer_seat_no_eor_zone2'
			];
			return plans.includes(this.currentWorker.plan.value)
		}
		
	},
};
</script>

<style scoped>
.module-actions-container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	min-width: 0;

.actions-container {
	padding: 1.5rem;
	border-radius: 22px;
	background: #f2f5fa;

&
:not(:last-child) {
	margin-bottom: 1rem;
}

.title-text {
	color: #5a5a77;
	line-height: 1.4375rem;
	margin-bottom: 1.75rem;
}

}

.btn-back-wrapper {
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: 1rem;
}

.btn-back {
	color: #27276c;

&
:hover::before {
	opacity: 0;
}

margin-right:

0.75
rem

;

i {
	font-size: 1.25rem;
}

}

.back-text {
	font-size: 1.125rem;
	font-weight: 700;
	color: #27276c;
}

}
</style>
