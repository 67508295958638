import axios from "axios";

const state = {
    supported: false,
    items: [],
    itemsTotal: null,
    personalInfo: false,
    payroll: {},
    types: [],
    ticketTypes: [],
    anniversaryTypes: [],
    periodTypes: [],
    assistanceTypes: [],
    cbaTypes: [],
    giftTypes: [],
    regionTypes: [],
    totalProcessed: 0,
};

const mutations = {
    setData(state, payload) {
        for (const [key, value] of Object.entries(payload)) {
            state[key] = value && 'object' === typeof value && !Array.isArray(value) ?
                { ...value } : value;
        }
    }
}

const actions = {
    async getTaxAllowances({state, commit, rootState}) {
        let path = `${rootState.path}data/tax-allowances`;
        await axios
            .get(path)
            .then(response => {
                commit("setData", response.data.data);
            })
            .catch(error => {
                commit('setAlert', {
                    type: 'error',
                    message: error.response.data.message,
                });
            });
    },
}

const getters = {
    items: (state) => state.items,
    itemsTotal: (state) => state.itemsTotal,
    payroll: (state) => state.payroll,
    types: (state) => state.types,
    ticketTypes: (state) => state.ticketTypes,
    anniversaryTypes: (state) => state.anniversaryTypes,
    periodTypes: (state) => state.periodTypes,
    assistanceTypes: (state) => state.assistanceTypes,
    cbaTypes: (state) => state.cbaTypes,
    giftTypes: (state) => state.giftTypes,
    regionTypes: (state) => state.regionTypes,
    totalProcessed: (state) => state.totalProcessed,
    getWizardInfo: (state) => state.personalInfo,
    supported: (state) => state.supported,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}