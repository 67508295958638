const state = {
    selectedEmployeePlan: null,
    selectedContractorPlan: null,
    openAddPeopleModal: false,
    openLearnMoreModal: false,
    openAssignRoleModal: false,
    openAddBankModal: false,
    openUploadFilesModal: false,
    openCreateTicketModal: false,
    tmpActiveTab: 0,
    selectedContractor: null,
};

const mutations = {
    SET_EMPLOYEE_PLAN(state, selectedEmployeePlan) {
        state.selectedEmployeePlan = selectedEmployeePlan;
    },
    SET_CONTRACTOR_PLAN(state, selectedContractorPlan) {
        state.selectedContractorPlan = selectedContractorPlan;
    },
    SET_OPEN_ADD_PEOPLE_MODAL(state, openAddPeopleModal) {
        state.openAddPeopleModal = openAddPeopleModal;
    },
    SET_OPEN_LEARN_MORE_MODAL(state, openLearnMoreModal) {
        state.openLearnMoreModal = openLearnMoreModal;
    },
    SET_OPEN_ASSIGN_ROLE_MODAL(state, openAssignRoleModal) {
        state.openAssignRoleModal = openAssignRoleModal;
    },
    SET_OPEN_ADD_BANK_MODAL(state, openAddBankModal) {
        state.openAddBankModal = openAddBankModal;
    },
    SET_OPEN_UPLOAD_FILES_MODAL(state, openUploadFilesModal) {
        state.openUploadFilesModal = openUploadFilesModal;
    },
    SET_OPEN_CREATE_TICKET_MODAL(state, openCreateTicketModal) {
        state.openCreateTicketModal = openCreateTicketModal;
    },
    SET_TMP_ACTIVE_TAB(state, tmpActiveTab) {
        state.tmpActiveTab = tmpActiveTab;
    },
    SET_SELECTED_CONTRACTOR(state, worker) {
        state.selectedContractor = worker;
    }
};

const actions = {
    setEmployeePlan({ commit }, selectedEmployerPlan) {
        commit('SET_EMPLOYEE_PLAN', selectedEmployerPlan);
    },
    setContractorPlan({ commit }, selectedContractorPlan) {
        commit('SET_CONTRACTOR_PLAN', selectedContractorPlan);
    },
    setOpenAddPeopleModal({ commit }, openAddPeopleModal) {
        commit('SET_OPEN_ADD_PEOPLE_MODAL', openAddPeopleModal);
    },
    setOpenLearnMoreModal({commit}, openLearnMoreModal) {
        commit('SET_OPEN_LEARN_MORE_MODAL', openLearnMoreModal);
    },
    setOpenAssignRoleModal({commit}, openAssignRoleModal) {
        commit('SET_OPEN_ASSIGN_ROLE_MODAL', openAssignRoleModal);
    },
    setOpenAddBankModal({commit}, openAddBankModal) {
        commit('SET_OPEN_ADD_BANK_MODAL', openAddBankModal);
    },
    setOpenUploadFilesModal({commit}, openUploadFilesModal) {
        commit('SET_OPEN_UPLOAD_FILES_MODAL', openUploadFilesModal);
    },
    setOpenCreateTicketModal({commit}, openCreateTicketModal) {
        commit('SET_OPEN_CREATE_TICKET_MODAL', openCreateTicketModal);
    },
    setTmpActiveTab({commit}, tmpActiveTab) {
        commit('SET_TMP_ACTIVE_TAB', tmpActiveTab);
    },
    setSelectedContractor({commit}, worker) {
        commit('SET_SELECTED_CONTRACTOR', worker);
    }
};

const getters = {
    getSelectedEmployeePlan: (state) => {
        return state.selectedEmployeePlan;
    },
    getSelectedContractorPlan: (state) => {
        return state.selectedContractorPlan;
    },
    getOpenAddPeopleModal: (state) => {
        return state.openAddPeopleModal;
    },
    getOpenLearnMoreModal: (state) => {
        return state.openLearnMoreModal;
    },
    getOpenAssignRoleModal: (state) => {
        return state.openAssignRoleModal;
    },
    getOpenAddBankModal: (state) => {
        return state.openAddBankModal;
    },
    getOpenUploadFilesModal: (state) => {
        return state.openUploadFilesModal;
    },
    getOpenCreateTicketModal: (state) => {
        return state.openCreateTicketModal;
    },
    getTmpActiveTab: (state) => {
        return state.tmpActiveTab;
    },
    getSelectedContractor: (state) => {
        return state.selectedContractor;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
