<template>
    <div class="currency-amount-step">
        <h5 class="text-center heading-1 bold-text">
            What wallet do you want to add to?
        </h5>
        <p class="text-center medium-text color-dark-grey-6 lh-120">
            Choose wallet/amount
        </p>
        <div class="amount-and-currency position-relative mt-30 mb-2">
            <div class="transfer-amount-body pt-10 pb-10">
                <div class="transfer-amount-left">
                    <p class="transfer-amount-title">Wallet</p>
                    <tm-advanced-select
                        :items="wallets"
                        item-value="id"
                        item-text="name"
                        v-model="obj.values.wallet_id"
                        :padding-left="false"
                        :bordered="false"
                        :bottom-offset="false"
                        :select-first="!obj.values.wallet_id"
                        name="currency"
                        data-vv-as="Currency"
                        v-validate="obj.rules.wallet_id"
                        :error-messages="errors.collect('currency')"
                    >
                        <template v-slot:selection="{ item }">
                            <div class="d-flex align-center mr-2">
                                <avatar size="1.875rem" :object="item.currency"></avatar>
                                <span class="normal-text-2 color-black">
                                    {{ item.currency.code }} ({{ item.currency.symbol }})
                                    <i v-if="item.is_modulr" class="icon-card pr-2"></i>
                                </span>
                            </div>
                        </template>

                        <template v-slot:item="{ item }">
                            <div class="d-flex align-center mr-2">
                                <avatar size="1.875rem" :object="item.currency"></avatar>
                                <span class="normal-text-2 color-black">
                                    {{ item.currency.code }} ({{ item.currency.symbol }})
                                    <i v-if="item.is_modulr" class="icon-card pr-2"></i>
                                </span>
                            </div>
                        </template>
                    </tm-advanced-select>
                </div>
            </div>
            <div v-if="wallet" class="transfer-amount-footer bg-light-blue">
                Available balance:
                <span class="color-purple">
                    {{ formatAmountNoCurrency(wallet.available_balance, 2, 2) }} {{ wallet.currency.code }}
                </span>
            </div>
        </div>
        <div class="amount-and-currency position-relative">
            <div class="transfer-amount-body pb-10 pt-10">
                <div class="transfer-amount-left position-relative">
                    <p class="transfer-amount-title">Amount</p>
                    <input
                        placeholder="0.00"
                        autocomplete="off"
                        type="number"
                        v-model="obj.values.amount"
                        class="mt-1 w-100"
                        name="amount_in"
                        data-vv-as="Amount"
                        v-validate="amountRules"
                        @wheel="$event.target.blur()"
                    />
                </div>
                <div class="transfer-amount-right mt-0">
                    <p v-if="wallet" class="d-flex align-center">
                        <avatar size="1.875rem" :object="wallet.currency"></avatar>
                        <span class="normal-text-2 color-black">{{ wallet.currency.code }}</span>
                    </p>
                </div>
            </div>
            <v-slide-y-transition>
                <error-message class="w-100" :rounded="false" :absolute="false" :messages="errMessage"></error-message>
            </v-slide-y-transition>
        </div>
    </div>
</template>
<script>
import TmAdvancedSelect from '~/shared/components/form/TmAdvancedSelect';
import Avatar from '~/shared/components/Avatar';
import Helpers from '~/shared/mixins/Helpers';
import ErrorMessage from '~/shared/components/form/ErrorMessage';
export default {
    name: 'SelectWallet',
    components: { ErrorMessage, Avatar, TmAdvancedSelect },
    mixins: [Helpers],
    props: {
        obj: {
            required: true,
            type: Object,
        },
        wallet: {
            default: null,
        },
    },
    data() {
        return {
            walletId: this.$store.getters.selectedWallet,
            stripeMinCurrencyAmount: this.$store.state.minCurrencyAmountForStripe,
        };
    },
    computed: {
        activeWalletId(){
          return this.$store.state.activeWalletId;
        },
        wallets() {
            if (this.wallet && this.wallet.is_solo_entity === 1) {
                return [this.wallet];
            }

            if (this.activeWalletId && !this.obj.values.wallet_id) {
              this.obj.values.wallet_id = this.activeWalletId;
              //return [this.$store.getters.allWallets.find(w => w.id == this.activeWalletId)];
              // return this.$store.getters.allWallets.filter(w => w.id == activeWalletId);
            }
            return this.$store.getters.wallets.filter(w => !w.is_modulr);
            /* return this.modulrIsCompact
                ? this.$store.getters.wallets.filter(w => !w.is_modulr)
                : this.$store.getters.wallets; */
        },
        modulrIsCompact() {
            return process.env.VUE_APP_MODULR_INTEGRATION == 'COMPACT' ? true : false;
        },
        currencyCode() {
            return this.wallet ? this.wallet.currency.code : null;
        },
        amountRules() {
            let rules = this.obj.rules.amount;

            if (this.currencyMin) {
                rules += '|min_value:' + this.currencyMin;
            }

            return rules;
        },
        currencyMin() {
            return this.wallet
                ? this.wallet.currency.code in this.stripeMinCurrencyAmount
                    ? this.stripeMinCurrencyAmount[this.wallet.currency.code]
                    : this.stripeMinCurrencyAmount['default']
                : 0;
        },
        minErr() {
            const amount = parseFloat(this.obj.values.amount);
            return amount > 0 && this.currencyMin && amount < this.currencyMin;
        },
        errMessage() {
            return this.minErr
                    ? [`The Amount field must be ${this.currencyMin} ${this.wallet.currency.code} or more`]
                    : this.errors.collect('amount_in');
        },
        disabled() {
            return this.minErr || Object.keys(this.fields).some(key => this.fields[key].invalid);
        },
    },
    watch: {
        currencyCode() {
            this.$validator.reset();
        },
        disabled() {
            this.$emit('disabled', this.disabled);
        },
    },
    mounted(){
        this.keypressForNumber();
        if(!this.$store.getters['payoneer/accountsConnectedInitedAndLoaded']){
            this.$store.dispatch("payoneer/initAll").then(() => {

            });
        }
    }
};
</script>
