<template>
    <v-dialog
        fullscreen
        v-model="dialog"
        persistent
        content-class="tm-bg-white"
        no-click-animation
        :retain-focus="false"
    >
        <div class="card add-send-page mb-0 p-0 rounded-0">
            <div :class="__smallDesktop? 'px-0': 'pl-30 pr-40'" class="py-1 nav-header d-flex justify-content-between align-items-center flex-wrap">
                <img :src="$store.state.wlstyle.st_logo_black" class="ml-3 ml-sm-0" alt="logo black" style="width: 9.75rem">
                <div :class="__smallDesktop? 'order-md-2 order-last': ''" class="navbar-steps-circles position-relative">
                    <ul class=" wizard-steps-list self-serve-wizard-steps-list justify-center">
                        <li class="wizard-step "
                            @click="availableStep(item)? step = item.value: null"
                            :class="[step === item.value? 'active': '', item.completed && 'completed' || '']"
                            v-for="(item, index) in steps"
                            :key="index"
                        >
                          <span class="wizard-step-number">
                            <span class="number">{{ index + 1 }}</span>
                            <i class="icon-check fs-7 color-white"></i>
                          </span>
                            <p  class="medium-text bold-text color-dark-grey"> {{ item.name }}</p>
                        </li>
                    </ul>
                </div>
                <div class="d-flex justify-end align-center" style="width: 9.75rem">
                    <button @click="dialog = false" :class="__smallDesktop? 'mr-3': ''" class="order-md-last order-second mr-sm-0">
                        <i class="icon-times color-black"></i>
                    </button>
                </div>
            </div>
            <div class="card-body add-money-card-body pt-0 pb-30">
                <div class="mt-57 mx-auto">

                    <!-- UPLOAD FILE STEP -->
                    <div
                        v-if="step === 'upload_file'"
                        class="col-12 col-md-6 upload-file-step-container">
                        <h1 class="heading-1 bold-text color-black text-center mb-2">
                            Upload your file
                        </h1>
                        <p class="medium-text color-dark-grey text-center mb-60">
                            You can now upload .csv files to send batch payment requests.
                        </p>

                        <div class="upload-file-card" v-if="!generateLoading">
                            <div class="form-upload-file">
                                <tm-single-upload-area
                                    files-max-mb="8"
                                    accept="application/csv, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    :files-types="['application/csv', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']"
                                    v-model="csvFile"
                                    @previewObject="previewFile = $event"
                                    class-val="medium-text color-dark-grey mw-100 mw-100"
                                    :show-actions="false"
                                >
                                    <template slot="placeholder">
                                        <template  v-if="previewFile">
                                            <i class="icon-check-circle color-feedback-success"></i>
                                            <p class="file-name color-black mb-3 " v-if="previewFile">
                                                {{previewFile}}
                                            </p>
                                        </template>
                                        <template  v-else>
                                            <i class="icon-file"></i>

                                            <p class="mb-3">Drop your .csv or .xlsx file here</p>
                                        </template>
                                        <span type="button" class="btn btn-select-file btn-outline-purple btn-select-file medium-text bold-text color-purple">Upload new</span>
                                    </template>
                                </tm-single-upload-area>
                            </div>
                            <p class="medium-text color-dark-grey">
                                Don't have a file?
                                <a  @click="downloadCsv"  class="color-purple bold-text">Download template</a>
                            </p>
                        </div>

                        <div v-else-if="generateLoading" class="generating-csv-file-card" >
                            <i class="icon-loading-dots-circle color-feedback-warning"></i>
                            <h1 class="heading-1 bold-text color-black text-center mb-3">
                                Generating your CSV file
                            </h1>
                            <p class="normal-text color-dark-grey text-center mb-5">
                                Please do not close or refresh the page
                            </p>
                            <div class="custom-progress-bar-wrapper mb-4">
                                <div class="custom-progress-bar bg-purple"  :style="{width: `${generateProgress}%`}"></div>
                            </div>
                            <p class="normal-text color-black text-center">{{generateProgress}}%</p>
                        </div>
                    </div>

                    <review-csv
                        ref="reviewCsv"
                        v-else-if="!isFinished && step === 'review_csv'"
                        :all-data="generatedData"
                    ></review-csv>

                    <div v-if="isFinished && step === 'review_csv'" class="col-12 transfer-step-container">
                        <div class="transfer-success-card">
                            <i class="icon-check-circle color-feedback-success mb-4"></i>
                            <h1 class="heading-1 bold-text color-black mb-3">Success</h1>
                            <p class="normal-text color-dark-grey text-center mb-4">
                                Your requests has been sent successfully!
                                Go to Payment requests page to see the request details.
                            </p>
                            <a
                                href="/payment-request"
                                class="btn btn-big btn-outline-purple normal-text bold-text color-purple w-100"
                            >
                                Go to Payment requests
                            </a>
                        </div>
                    </div>


                    <!-- SELF SERVE ACTIONS -->
                    <div class="col-12 self-serve-wizard-actions"
                         v-if="!isFinished"
                    >
                        <template v-if="step !== 'upload_file'">
                            <btn
                                :btn-outlined="true"
                                @click="back"
                                class="btn btn-big btn-self-serve-wizard-back-step btn-outline-purple normal-text bold-text color-purple w-110 mr-3"
                            >
                                Back
                            </btn>
                            <btn
                                @click="next"
                                :loading="loading"
                                class="btn btn-self-serve-wizard-next-step btn-big btn-purple normal-text bold-text color-white w-110"
                            >
                                Send
                            </btn>
                        </template>

                        <btn
                            :loading="generateLoading"
                            v-if="step === 'upload_file' && csvFile"
                            @click="generateData()"
                            class="btn btn-self-serve-wizard-generate btn-big btn-purple normal-text bold-text color-white w-110"
                        >
                            Generate
                        </btn>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>

import Screen from "~/shared/mixins/Screen";
import Btn from "~/shared/components/Btn";
import Helpers from '~/shared/mixins/Helpers';
import Modal from "~/shared/components/Modal";
import TmUploadArea from "./form/TmUploadArea";
import TmSingleUploadArea from "./form/TmSingleUploadArea";
import { saveAs } from "file-saver";
import { unparse } from "papaparse";
import { serialize } from "object-to-formdata";
import ReviewCsv from "./PaymentRequest/ReviewCsv";
import {mapGetters} from "vuex";
import Avatar from "./Avatar";
import AvatarName from "./AvatarName";
import WalletToWalletBatch from "~/modules/dashboard/models/WalletToWalletBatch";
import TmCheckbox from "./form/TmCheckbox";
export default {
    name: 'PaymentRequest',
    mixins: [Screen, Helpers],
    components: {
        AvatarName,
        Avatar,
        ReviewCsv,
        TmSingleUploadArea,
        TmUploadArea,
        Modal,
        Btn,
        TmCheckbox
    },
    props: {
        value: {
            default: false,
        },
    },
    data(){
        return {
            dialog: this.value,
            step: 'upload_file',
            steps: [
                {name: 'Upload file', value: 'upload_file', completed: false},
                {name: 'Review', value: 'review_csv', completed: false},
                /*{name: 'Transfer', value: 'transfer', completed: false},*/
            ],
            csvFile: null,
            previewFile: null,
            generateLoading: false,
            generateProgress: 0,
            generatedData: {},
            items: [],
            transfer_bank: false,
            loading: false,
        }
    },
    watch: {
        dialog(){
            this.$emit('input', this.dialog);
        },
        value(){
            this.dialog = this.value;
        }
    },
    computed: {
        isFinished() {
            return this.step === 'review_csv'&&   this.steps[1].completed === true;
        },
        organization(){
            return this.$store.state.organization
        }
    },
    methods: {
        availableStep(item){
            const index = this.steps.findIndex(s => s.value === item.value);
            return this.steps[index - 1] && this.steps[index - 1].completed;
        },
        downloadCsv () {
            const dataExport = [
                {
                    "Payer Name": "Milan Tomic",
                    "Payer Email": 'milan66666@nteams.com',
                    "Currency": "EUR",
                    "Amount": "9000",
                    "Notes": "Please add notes here",
                    "Show Sender Name": "1",
                    "Frequency": "One-time",
                    "Exact day": "",
                    "Exact date": "",
                    "End date": "",
                },
                {
                    "Payer Name": "Milan Tomic second",
                    "Payer Email": 'milan7777@nteams.com',
                    "Currency": "USD",
                    "Amount": "9000",
                    "Notes": "Please add notes here",
                    "Show Sender Name": "0",
                    "Frequency": "Weekly",
                    "Exact day": "Monday",
                    "Exact date": "",
                    "End date": "11/06/2023",
                },
                {
                    "Payer Name": "Milan Tomic third",
                    "Payer Email": 'milan1342@nteams.com',
                    "Currency": "USD",
                    "Amount": "9000",
                    "Notes": "Please add notes here",
                    "Show Sender Name": "0",
                    "Frequency": "Monthly",
                    "Exact date": "11/05/2023",
                    "Exact day": "",
                    "End date": "11/06/2023",
                }
            ];

            const delimiter = false;
            const encoding =  "utf-8";
            const name = 'Template.csv';
            if (!dataExport) {
                console.error("No data to export");
                return;
            }

            let csv = unparse(
                dataExport,
                Object.assign(
                    {
                        delimiter: delimiter,
                        encoding: encoding
                    },
                    this.advancedOptions
                )
            );

            csv = "\ufeff" + csv;
            let blob = new Blob([csv], {
                type: "application/csvcharset=" + encoding
            });
            saveAs(blob, name);
        },

        transfer(){
            if(this.$refs.reviewCsv.items){
                this.loading = true;

                axios.post(`${this.$store.state.path}payment-request/batch-store`, this.$refs.reviewCsv.items)
                    .then(response => {
                        this.loading = false;
                        this.steps[1].completed = true;
                    })
                    .catch(error => {
                        this.loading = false;
                        this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                    });
            }
        },
        back() {
            const ind = this.steps.findIndex(i => this.step === i.value);
            if(ind > 0) {
                this.step = this.steps[ind - 1].value;
            }
        },
        next() {
            if(this.step === 'review_csv') {
                this.transfer();
                return;
            }
            const ind = this.steps.findIndex(i => this.step === i.value);
            if(ind < this.steps.length) {
                this.step = this.steps[ind + 1].value;
            }
        },

        generateData() {
            if(!this.csvFile) {
                return false;
            }
            this.generateLoading = true;
            axios.post(`${this.$store.state.path}payment-request/generate`, serialize({csv: this.csvFile}, { indices: true }),
                {
                    onUploadProgress: progressEvent => {
                        this.generateProgress = (progressEvent.loaded / progressEvent.total) * 100 - 3;
                    }
                })
                .then(response => {
                    this.generateProgress = 100;
                    this.steps[0].completed = true;
                    this.step = 'review_csv';
                    this.generatedData = response.data;
                    this.generateLoading = false;
                    this.generateProgress = false;
                })
                .catch(error => {
                    this.generateProgress = 100;
                    this.generateLoading = false;
                    this.generateProgress = false;
                    this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                })

        }
    }
}
</script>
<style lang="scss">
.currency-td {
    min-width: 54px;
    max-width: 47px;
    width: 100%;
    text-align: end;
}
.upload-file-card  {

    .tm-file-input-label > div {
        flex-direction: column;
        > i {
            font-size: 1.875rem;
            margin-bottom: 1rem;
        }
    }
}
</style>
