var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert",class:[
        _vm.customParentClass,
        _vm.alertClass,
        _vm.isBigContent ? 'big-content' : '',
        _vm.isAlternative ? 'alternative' : '',
    ]},[(
        _vm.type === 'success' ||
        _vm.type === 'authorized' ||
        _vm.type === 'active' ||
        _vm.type === 'completed' ||
        _vm.type === 'employed' ||
        _vm.type === 'sent' ||
        _vm.type === 'paid' ||
        _vm.type === 'approved'
      )?_c('BaseIcon',{staticClass:"mr-2 base-icon-alert-box",attrs:{"name":"check-thin-circle-outline","size":_vm.iconSize}}):_vm._e(),(
        _vm.type === 'error' ||
        _vm.type === 'disabled' ||
        _vm.type === 'rejected' ||
        _vm.type === 'failed' ||
        _vm.type === 'declined' ||
        _vm.type === 'overdue' ||
        _vm.type === 'canceled'
      )?_c('BaseIcon',{staticClass:"mr-2 base-icon-alert-box",attrs:{"name":"error-thin-circle-outline","size":_vm.iconSize}}):_vm._e(),(_vm.type === 'warning' || _vm.type === 'pending' || _vm.type === 'reserved')?_c('BaseIcon',{staticClass:"mr-2 base-icon-alert-box",attrs:{"name":"warning","size":_vm.iconSize}}):_vm._e(),(
        _vm.type === 'requested' ||
        _vm.type === 'scheduled' ||
        _vm.type === 'draft' ||
        _vm.type === 'unemployed' ||
        _vm.type === 'currentMonth' ||
        _vm.type === 'unpaid'
      )?_c('BaseIcon',{staticClass:"mr-2 base-icon-alert-box",attrs:{"name":"pending-circle-outline","size":_vm.iconSize}}):_vm._e(),(
        _vm.type === 'inactive' || _vm.type === 'unavailable' || _vm.type === 'archived'
      )?_c('BaseIcon',{staticClass:"mr-2 base-icon-alert-box",attrs:{"name":"info-thin-circle-outline","size":_vm.iconSize}}):_vm._e(),(_vm.type === 'info')?_c('BaseIcon',{staticClass:"mr-2 base-icon-alert-box",attrs:{"name":"info-thin-circle-outline","size":_vm.iconSize,"alertBoxCustomClasses":_vm.alertBoxCustomClasses}}):_vm._e(),(_vm.html)?_c('p',{class:[_vm.isBigContent ? 'paragraph leading-120' : 'text-sm text-capitalize', _vm.customLabelClasses],domProps:{"innerHTML":_vm._s(_vm.__sanitizeHtml(_vm.text))}}):_c('p',{class:[_vm.isBigContent ? 'paragraph leading-120 tm-initial' : 'text-sm text-capitalize', _vm.customLabelClasses]},[_vm._v(" "+_vm._s(_vm.text)+" "),_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }