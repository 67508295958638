/*Hidden*/
const card = () => import('~/modules/dashboard/views/Wallet/Card.vue');//old cards UI
// const Recruitment = () => import('~/modules/dashboard/views/Recruitment/Recruitment.vue');
// const appUpdates = () => import('~/modules/dashboard/views/AppUpdates/AppUpdates');
// const cardService = () => import('~/modules/dashboard/views/CardService.vue');
// const visaCard = () => import('~/modules/dashboard/views/CardManagement.vue');
// const benefit = () => import('~/modules/dashboard/views/Benefits.vue');

const createOrganizationModal = () => import('~/modules/dashboard/views/Wallet/CreateOrganizationModal.vue');
const addMoneyModal = () => import('~/modules/dashboard/views/Wallet/AddMoneyModal.vue');
const sendMoneyModal = () => import('~/modules/dashboard/views/Wallet/SendMoneyModal.vue');
const batchTransfer = () => import('~/modules/dashboard/views/Wallet/BatchTransferModal.vue');
const invoice = () => import('~/modules/dashboard/views/Invoice/Invoice.vue');
const paymentRequest = () => import('~/modules/dashboard/views/PaymentRequest.vue');
const expense = () => import('~/modules/dashboard/views/ExpenseRequest/ExpenseRequests.vue');
const supportService = () => import('~/modules/dashboard/views/SupportServices.vue');
const entityStatus = () => import('~/modules/dashboard/views/SoloEntity/SoloEntity.vue');
const referral = () => import('~/modules/dashboard/views/Referrals/Referrals.vue');
const teamMembers = () => import('~/modules/dashboard/views/TeamMembers/TeamMemberController.vue');
const teamMemberProfile = () => import('~/modules/dashboard/views/TeamMemberProfile.vue');
const settings = () => import('~/modules/dashboard/views/Settings/Settings.vue');
const settings_new = () => import('~/modules/dashboard/views/Settings_new/Settings.vue');
const wageAccess = () => import('~/modules/dashboard/views/WageAccess/WageAccess.vue');
const team = () => import('~/modules/dashboard/views/Contacts/Team.vue');
const caseMinView = () => import('~/modules/dashboard/views/Cases/Main.vue');
const TaxManagement = () => import('~/modules/dashboard/views/TaxManagement.vue');
const taxReporting = () => import('~/modules/dashboard/views/TaxReporting.vue');
const absenceManagement = () => import('~/modules/dashboard/views/AbsenceManagement.vue');
const payrollCalculator = () => import('~/components/GlobalPayrollCalculator/GlobalPayrollCalculator.vue');
const TaxHub = () => import('~/modules/dashboard/views/TaxHub.vue');
const Apps = () => import('~/modules/dashboard/views/AddOns/Apps.vue');
const Services = () => import('~/modules/dashboard/views/AddOns/Services.vue');
const MyPlan = () => import('~/modules/dashboard/views/AddOns/MyPlan.vue');
const Affiliates = () => import('~/modules/dashboard/views/Affiliates/Index.vue');
const KnowledgeBase = () => import('~/modules/dashboard/views/KnowledgeBase.vue');
const Mobility = () => import('~/modules/dashboard/views/Mobility.vue');
const Eor = () => import('~/modules/dashboard/views/EOR/Eor.vue');
const PayrollIntro = () => import('~/modules/dashboard/views/EmployeePayroll/Components/PayrollIntro.vue')
const Flights = () => import('~/modules/dashboard/views/TravelBooking/Flights.vue');
const DuffelRedirect = () => import('~/modules/dashboard/views/TravelBooking/DuffelRedirectPage.vue');
const ContractsDashboard = () => import('~/modules/wallet/views/Contractors/ContractsDashboard.vue');
const ContractInfoCard = () => import('~/modules/wallet/components/Contracts/ContractInfoCard.vue');
const ContractsOverviewDataCard = () => import('~/modules/wallet/components/Contracts/ContractsOverviewDataCard.vue');
const IndividualsContractsTable = () => import("~/modules/wallet/components/Contracts/IndividualsContractsTable.vue");
const ContractorsContractsTable = () => import("~/modules/wallet/components/Contracts/ContractorsContractsTable.vue");
const ClientWizard = () => import('~/modules/wallet/views/Contractors/ClientWizard.vue');
const ContractorWizard = () => import('~/modules/wallet/views/Contractors/ContractorWizard.vue');
const SignContract = () => import('~/modules/wallet/views/Contractors/SignContract.vue');
const PaymentContacts = () => import('~/modules/wallet/views/Contacts/PaymentContacts.vue');
const BdmForm = () => import('~/modules/dashboard/views/BDM/BdmForm.vue');
const Benefits = () => import('~/modules/wallet/views/Benefits.vue');
const BenefitsHeader = () => import('~/modules/wallet/views/Benefits/BenefitsHeader.vue');
const BenefitTypesContainer = () => import('~/modules/wallet/views/Benefits/BenefitTypesContainer.vue');
const CustomBenefitBanner = () => import('~/modules/wallet/views/Benefits/CustomBenefitBanner.vue');

export default [
    {
        path: '/tax-management',
        name: 'TaxManagement',
        component: TaxManagement,
        meta: {
            title: 'Tax Management',
            data: true,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/wallet/add-money',
        name: 'addMoneyModal',
        component: addMoneyModal,
        meta: {
            title: 'Add Money',
            data: false,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/create-organization',
        name: 'createOrganizationModal',
        component: createOrganizationModal,
        meta: {
            title: 'Create Organization',
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/wallet/send-money',
        name: 'sendMoneyModal',
        component: sendMoneyModal,
        meta: {
            title: 'Send Money',
            data: false,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/wallet/batch-transfer',
        name: 'batchTransfer',
        component: batchTransfer,
        meta: {
            title: 'Batch Transfer',
            data: false,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/cards-preview',
        name: 'card',
        component: card,
        meta: {
            title: 'Cards',
            data: true,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/invoices',
        alias: ['/invoice'],
        name: 'invoices',
        component: invoice,
        meta: {
            title: 'Invoices',
            mobileNavigationSlot: () =>
                import("~/modules/dashboard/views/Invoice/Mobile/MobileNavbarDropDown"),
            data: true,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/payment-request',
        name: 'paymentRequests',
        component: paymentRequest,
        meta: {
            title: 'Payment Requests',
            mobileNavigationSlot: () =>
                import("~/modules/dashboard/views/PaymentRequest/Mobile/MobileNavbarDropDown"),
            data: true,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/knowledge-base',
        name: 'KnowledgeBase',
        component: KnowledgeBase,
        meta: {
            title: 'Knowledge base',
            mobileNavigationSlot: () =>
                import("~/components/KnowledgeBase/Mobile/MobileNavbarDropDown.vue"),
            data: false,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/mobility',
        name: 'Mobility',
        component: Mobility,
        meta: {
            title: 'Mobility',
            mobileNavigationSlot: () =>
                import("~/components/Mobility/Mobile/MobileNavbarDropDown.vue"),
            data: false,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    // {
    //     path: '/visa-card',
    //     name: 'visaCard',
    //     component: visaCard,
    //     meta: {
    //         title: 'Visa card | Overview',
    //         data: true,
    //         app: 'dashboard',
    //         middleware: 'auth'
    //     },
    // },
    {
        path: '/expenses',
        name: 'expenses',
        component: expense,
        meta: {
            title: 'Expenses',
            mobileNavigationSlot: () =>
                import("~/modules/dashboard/views/ExpenseRequest/Mobile/MobileNavbarDropDown"),
            data: false,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/support-services',
        name: 'supportServices',
        component: supportService,
        meta: {
            title: 'Support services | Overview',
            data: false,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/legal-entity-status',
        name: 'entityStatus',
        component: entityStatus,
        meta: {
            title: 'Legal Entity',
            data: true,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/referrals',
        name: 'referrals',
        component: referral,
        meta: {
            title: 'Referrals',
            data: true,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/people/profile/:type/:workerId',
        name: 'TeamMemberProfile',
        alias: ['/team/profile/:type/:workerId', '/contacts/profile/:type/:workerId'],
        component: teamMemberProfile,
        meta: {
            title: 'Team Member Profile',
            mobileNavigationSlot: () =>
                import("~/components/Members/Mobile/TeamMembersMobileNavbarDropdown"),
            data: true,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/people',
        name: 'teamMembers',
        alias: ['/team', '/contacts'],
        component: teamMembers,
        meta: {
            title: '',
            mobileNavigationSlot: () =>
                import("~/components/Members/Mobile/MobileNavbarDropdown"),
            data: true,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/bdm-url-customisation',
        name: 'bdmForm',
        component: BdmForm,
        meta: {
            title: 'BDM URL Customisation form',
            data: false,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/wage-access',
        name: 'wageAccess',
        component: wageAccess,
        meta: {
            title: 'Earned Wage Access',
            mobileNavigationSlot: () =>
                import("~/modules/dashboard/views/WageAccess/Mobile/MobileNavbarDropDown"),
            data: true,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    // {
    //     path: '/benefits',
    //     name: 'benefit',
    //     component: benefit,
    //     meta: {
    //         title: 'Benefits',
    //         data: false,
    //         app: 'dashboard',
    //         middleware: 'auth'
    //     },
    // },
    {
        path: '/contacts',
        name: 'contract',
        component: team,
        meta: {
            title: 'Contracts | Overview',
        },
    },
    {
        path: '/tickets',
        name: 'caseMinView',
        component: caseMinView,
        meta: {
            title: 'Tickets',
            mobileNavigationSlot: () =>
                import("~/modules/dashboard/views/Cases/components/MobileNavbarDropDown"),
            data: false,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/settings/new',
        name: 'settings_new',
        component: settings_new,
        meta: {
            title: 'Settings',
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/settings',
        name: 'settings',
        component: settings,
        meta: {
            title: 'Settings account | Overview',
            data: true,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    // {
    //     path: '/card/:service/:id',
    //     name: 'cardService',
    //     component: cardService,
    //     meta: {
    //         title: 'Card | Overview',
    //         data: true,
    //         app: 'dashboard',
    //         middleware: 'auth'
    //     },
    // },
    {
        path: '/tax-reporting',
        name: 'tax_reporting',
        component: taxReporting,
        meta: {
            title: 'Tax reporting',
            mobileNavigationSlot: () =>
                import("~/components/TaxReporting/Mobile/MobileNavbarDropDown"),
            data: false,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/absence',
        name: 'absence_management',
        component: absenceManagement,
        meta: {
            title: 'Absence - Native Teams',
            data: true,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/payroll-calculator',
        name: 'payroll_calculator',
        component: payrollCalculator,
        meta: {
            title: 'Payroll Calculator',
            data: false,
            app: 'guest',
            middleware: 'tracking'
        },
    },
    // {
    //     path: '/app-updates',
    //     name: 'app-updates',
    //     component: appUpdates,
    //     meta: {
    //         title: 'App Updates - Native Teams',
    //         data: false,
    //         app: 'dashboard',
    //         middleware: 'auth'
    //     },
    // },
    {
        path: '/tax-onboarding',
        name: 'tax-hub',
        component: TaxHub,
        meta: {
            title: 'Tax Onboarding',
            mobileNavigationSlot: () =>
                import("~/components/TaxOnboarding/Mobile/MobileNavbarDropDown"),
            data: false,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/employee-payroll',
        name: 'employee-payroll',
        component: PayrollIntro,
        meta: {
            title: 'Payroll - Native Teams',
            data: true,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/eor',
        name: 'eor',
        component: Eor,
        meta: {
            title: 'EOR Application',
            data: true,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/add-ons/apps',
        name: 'apps',
        component: Apps,
        meta: {
            title: 'Apps',
            data: true,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/add-ons/services',
        name: 'services',
        component: Services,
        meta: {
            title: 'Services',
            data: true,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/add-ons/my-plan',
        name: 'my-plan',
        component: MyPlan,
        meta: {
            title: 'My plan',
            data: false,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/affiliates',
        name: 'affiliates',
        component: Affiliates,
        meta: {
            title: 'Affiliate Program',
            data: true,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    // {
    //     path: '/recruitment',
    //     name: 'recruitment',
    //     component: Recruitment,
    //     meta: {
    //         title: 'Jobs',
    //         data: true,
    //         app: 'dashboard',
    //         middleware: 'auth'
    //     },
    // },
    {
        path: '/flights',
        name: 'Flights',
        component: Flights,
        meta: {
            title: 'Flights',
            data: false,
            app: 'dashboard',
            middleware: 'auth'
        },
    },
    {
        path: '/flights/:status',
        name: 'DuffleRedirect',
        component: DuffelRedirect,
        meta: {
            title: 'Flights',
            data: false,
            app: 'dashboard'
        },
    },
    {
        path: '/contracts',
        name: 'Contracts',
        component: ContractsDashboard,
        children: [
        {
            path: "/",
            alias: "contracts-overview",
            name: "ContractsOverview",
            components: {
                ContractInfoCard,
                ContractsOverviewDataCard,
                IndividualsContractsTable,
                ContractorsContractsTable,
            },
            meta: {
                title: "Contracts",
                data: false,
                app: 'wallet',
                middleware: 'auth'
            },
        },
        {
            path: "/",
            alias: "contracts-table-view",
            name: "ContractsTableView",
            components: {
                IndividualsContractsTable,
                ContractorsContractsTable,
            },
            meta: {
                title: "Contracts Table View",
                layout: "FullWidthLayout",
                data: false,
                app: 'wallet',
                middleware: 'auth'
            },
        },
        ]
    },
    {
        path: "/payment-contacts",
        name: "PaymentContacts",
        component: PaymentContacts,
        meta: {
            title: "Payment Contacts",
            data: false,
            app: "wallet",
            middleware: "auth"
        }
    },
    {
        path: '/client-wizard',
        name: 'ClientWizard',
        component: ClientWizard,
        meta: {
            title: 'Client Wizard',
            data: false,
            app: 'wizard',
            middleware: 'auth'
        },
    },
    {
        path: '/contractor-wizard',
        name: 'ContractorWizard',
        component: ContractorWizard,
        meta: {
            title: 'Client Wizard',
            data: false,
            app: 'wizard',
            middleware: 'auth'
        },
    },
    {
        path: '/sign-contract',
        name: 'SignContract',
        component: SignContract,
        meta: {
            title: 'Sign Contract',
            data: false,
            app: 'wizard',
            middleware: null
        },
    },
    {
        path: '/benefits',
        name: 'Benefits',
        component: Benefits,
        meta: {
            title: 'Benefits',
            data: false,
            app: 'wallet',
            middleware: 'auth'
        },
        children: [
            {
                path: '/',
                name: 'Benefits',
                components: {
                    Header: BenefitsHeader,
                    Body: BenefitTypesContainer,
                    Footer: CustomBenefitBanner
                },
                meta: {
                    title: 'Benefits',
                    data: false,
                    app: 'wallet',
                    middleware: 'auth'
                }
            },
            {
                path: "/employee/benefits",
                name: "EmployeeBenefits",
                components: {
                    Header: () => import("~/modules/wallet/views/Benefits/BenefitsHeader.vue"),
                    Body: () => import("~/modules/wallet/views/Benefits/BenefitTypesContainer.vue"),
                    Footer: () => import("~/modules/wallet/views/Benefits/CustomBenefitBanner.vue"),
                },
                meta: {
                    title: "Benefits",
                    data: false,
                    app: 'wallet',
                    middleware: 'auth'
                },
            },
            {
                path: "/benefits/:type",
                name: "BenefitType",
                components: {
                    Header: () => import("~/modules/wallet/views/Benefits/TypeDedicatedPage/TypeDedicatedPageHeader.vue"),
                    Body: () => import("~/modules/wallet/views/Benefits/TypeDedicatedPage/BenefitsContainer.vue"),
                },
                meta: {
                    title: "Benefits",
                    data: false,
                    app: 'wallet',
                    middleware: 'auth'
                },
            },
            {
                path: "/team-benefits",
                name: "TeamBenefits",
                components: {
                    Header: () => import("~/modules/wallet/views/Benefits/TeamBenefitsPage/TeamBenefitsHeader.vue"),
                    Body: () => import("~/modules/wallet/views/Benefits/TeamBenefitsPage/TeamBenefitsTable.vue"),
                    Footer: () => import("~/modules/wallet/views/Benefits/CustomBenefitBanner.vue"),
                },
                meta: {
                    title: "Team benefits",
                    data: false,
                    app: 'wallet',
                    middleware: 'auth'
                },
            },
            {
                path: "/team-benefits/table-view",
                name: "TeamBenefitsTableView",
                components: {
                    Body: () => import("~/modules/wallet/views/Benefits/TeamBenefitsPage/TeamBenefitsTable.vue"),
                },
                meta: {
                    title: "Team benefits",
                    data: false,
                    app: 'wallet',
                    middleware: 'auth'
                },
            },
            {
                path: "/my-benefits",
                name: "MyBenefits",
                components: {
                    Header: () => import("~/modules/wallet/views/Benefits/MyBenefitsPage/MyBenefitsHeader.vue"),
                    Body: () => import("~/modules/wallet/views/Benefits/MyBenefitsPage/MyBenefitsTable.vue"),},
                meta: {
                    title: "My benefits",
                    data: false,
                    app: 'wallet',
                    middleware: 'auth'
                },
            },
            {
                path: "/my-benefits/table-view",
                name: "MyBenefitsTableView",
                components: {
                    Body: () => import("~/modules/wallet/views/Benefits/MyBenefitsPage/MyBenefitsTable.vue"),},
                meta: {
                    title: "My benefits",
                    data: false,
                    app: 'wallet',
                    middleware: 'auth'
                },
            },
        ]
    },
];
