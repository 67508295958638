import _ from "lodash";
import axios from "axios";

export const namespaced = true;

// initial state
export const state = {
    types: [],
    enabled: false,
    type: null,
    form2faDialog: false,
    form2faDialogHash: '#show-2fa-dialog',
};

// getters
export const getters = {
    __enabled: state => state.enabled,
    __type: state => state.type,
    __types: state => state.types || [],
    __form2faDialog: state => state.form2faDialog,
    __form2faDialogHash: state => state.form2faDialogHash,
};

// mutations
export const mutations = {
    confirmation2faDialog(state, payload) {
        state.confirmation2faDialog = payload;
    },
    confirmation2faActionText(state, payload) {
        state.confirmation2faActionText = payload;
    },
    form2faDialog(state, payload) {
        state.form2faDialog = payload;
    },
    setEnabled(state, payload) {
        state.enabled = payload;
    },
};

// actions
export const actions = {
    async __checkEnable({state, commit, dispatch}) {
        if (!state.enabled) {
            commit('form2faDialog', true)
            await dispatch('security2fa/showForm2faDialog', {}, {root: true});
            return false
        }

        return true;
    },
    __setTFAData({state}, data = {}) {
        for (const [key, value] of Object.entries(data)) {
            state[_.camelCase(key)] = value && 'object' === typeof value && !Array.isArray(value) ? {...value} : value
        }
    },
    __enable({dispatch, rootState, commit}, {type, password}) {
        return axios.post(`/settings/security/2fa/${type}`, {password})
            .then(response => {
                dispatch('__setTFAData', {type: type, enabled: true});
                return response.data;
            })
            .catch(error => {
                const errorMessage = error.response?.data?.message || error.message;
                commit('SET_ALERT_MODAL', {type: 'error', data: {title: "Error", text: errorMessage}}, {root: true});
                throw error;
            });
    },
    __disable({commit, rootState, dispatch}, {type, password}) {
        return axios.delete(`/settings/security/2fa/${type}`, {data: {password}}).then(response => {
            dispatch('__setTFAData', {type: null, enabled: false});
            return response.data;
        }).catch(error => {
            const errorMessage = error.response?.data?.message || error.message;
            commit('SET_ALERT_MODAL', {type: 'error', data: {title: "Error", text: errorMessage}}, {root: true});
            throw error;
        });
    },
    __generate({commit, rootState, dispatch}, {type, config}) {
        return axios.post(`/settings/security/2fa/${type}/generate`, {type, config})
            .then(response => {
                dispatch('__setTFAData', {type: type});
                return response.data;
            })
            .catch(error => {
                const errorMessage = error.response?.data?.message || error.message;
                commit('SET_ALERT_MODAL', {type: 'error', data: {title: "Error", text: errorMessage}}, {root: true});
                throw error;
            });
    },
    __resend({commit}, {type}) {
        return axios.post(`/settings/security/2fa/${type}/resend`)
            .then(response => response.data)
            .catch(error => {
                const errorMessage = error.response?.data?.message || error.message;
                commit('SET_ALERT_MODAL', {type: 'error', data: {title: "Error", text: errorMessage}}, {root: true});
                throw error;
            });
    },
    __setForm2faDialog({commit}, value) {
        commit('form2faDialog', value);
    },
};
