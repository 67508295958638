/** @format */
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';
// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import moment from 'moment';
import vClickOutside from 'v-click-outside';
import VueMask from 'v-mask';
import VTooltip from 'v-tooltip';
import VeeValidate from 'vee-validate';
import Vuelidate from "vuelidate"; /*@todo: check usage*/
import Vue from 'vue';
import VueCookie from 'vue-cookie';
import Croppa from 'vue-croppa';
import VueObserveVisibility from 'vue-observe-visibility';
import OnVisible from 'vue-on-visible';
import VueSocialSharing from 'vue-social-sharing';
import Vue2Editor from 'vue2-editor';
import * as VueGoogleMaps from 'vue2-google-maps';
import vuetify from '~/plugins/vuetify';
import router from '~/routes';
import store from '~/store';
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import App from "~/App.vue";
import Core from "~/providers/Core";
import _ from "lodash";
import VueLodash from 'vue-lodash'

/*Vue usages*/
Vue.prototype.moment = moment;
Vue.use(VeeValidate);
Vue.use(VueMask);
Vue.use(vClickOutside);
Vue.use(OnVisible);
Vue.use(VueSocialSharing);
Vue.use(Vue2Editor);
Vue.use(VTooltip);
Vue.use(VueObserveVisibility);
Vue.use(Croppa);
Vue.use(VueCookie);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);
// Vue.use(VueTheMask);
Vue.config.productionTip = false;
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_API_KEY,
        libraries: 'places',
    },
});

new Core().run();

Vue.use(VueLodash, { name: 'custom' , lodash: _ });

new Vue({
    router,
    vuetify,
    store,
    VueLodash,
    render: h => h(App),
}).$mount('#app');


