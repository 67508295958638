import Middleware from "~/routes/middleware/Middleware";
import store from "~/store";

export default class initPage extends Middleware{
    constructor(...args) {
        super(...args);
    }

    async resolve(){
        return this.to.meta.data?
            await store.dispatch('initialization/__initPage', this.to).then(() => this.responseNext()):
            this.responseNext();
    }
}
