import {Validator} from "vee-validate";

export default class validatorConfig{
    resolve() {
        Validator.extend("address", {
            getMessage: (field) =>
                `The ${field} lines should contain only latin letters, numbers, full stops, hyphens, commas, spaces, apostrophes, slashes, brackets`,
            validate: (value) => {
                const regex =
                    /^[\.a-zA-Z0-9, `()\-\/\\\[\]\'àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]*$/;
                return regex.test(String(value));
            },
        });

        Validator.extend("iban_validation", {
            getMessage: (field) => "IBAN should be consisted of a 2 letter country code, followed by 2 digits, and up to 35 alphanumeric characters.",
            validate: (value) => {
                const regex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;
                return regex.test(String(value));
            },
        });


        Validator.extend("except_mimetype", {
            getMessage: (field) => "'Not valid mime type.'",
            validate: (value) => {
                const mimeTypes = [
                    'application/vnd.rar', 'application/x-rar-compressed', 'application/octet-stream', 'application/x-rar', 'application/x-compressed', 'application/x-msdownload',
                    'application/zip', 'application/octet-stream', 'application/x-zip-compressed', 'multipart/x-zip',
                    'application/x-dosexec',
                    'text/plain'
                ];
                const val = value[0] ? value : [value];
                return !Object.values(val).find(i => !i.type ||mimeTypes.includes(i.type));
            },
        });

        Validator.extend("account_number", {
            getMessage: (field) => "Account number is not valid.",
            validate: (value) => {
                const regex = /^\d+[0-9]{0,255}[A-Z0-9]{0,255}$/;
                return regex.test(String(value));
            },
        });

        Validator.extend("doc-name", {
            getMessage: (field) =>
                `The ${field} - alphanumeric characters / Latin (including full stop, hyphen, apostrophe, caret and space)`,
            validate: (value) => {
                const regex = RegExp("^[a-zA-Z 0-9 .()À-Žà-žȘȚșț]{1,}$");
                return regex.test(String(value));
            },
        });

        Validator.extend("display-name", {
            getMessage: (field) =>
                `The ${field} - maximum of 20 alphanumeric characters / Latin (including full stop, hyphen, apostrophe, caret and space)`,
            validate: (value) => {
                const regex = /^[a-zA-Z 0-9À-Žà-žȘȚșț\'’.,^-]{1,20}$/;
                return regex.test(String(value));
            },
        });
        Validator.extend("first-last-name", {
            getMessage: (field) =>
                `The ${field} - maximum of 20 alphanumeric characters including space, hyphen and apostrophe.`,
            validate: (value) => {
                const regex = /^[\x20-\x7e]{1,20}$/;
                return regex.test(String(value));
            },
        });

        Validator.extend("accepted", {
            getMessage: (field) => `The ${field} - must be accepted.`,
            validate: (value) => {
                const acceptable = ["yes", "on", "1", 1, true, "true"];
                return acceptable.indexOf(value) !== -1;
            },
        });

        Validator.extend("salaryValidate", {
            validate: (value,[minimumVal]) => {
                value = value.replace(',','',value)
                if(value && minimumVal){
                    if(value < minimumVal){
                        return false;
                    }
                }
                return true;
            },
            getMessage: (field,[minimumVal]) => minimumVal > 0 ? `The ${field}  field must be ${minimumVal} or more .` : 'Country not selected',
        });
        Validator.extend(
            'salaryIncomeValidateAdvanced', {
                getMessage: (field,[minimumVal])  =>` The ${field} field must be ${minimumVal} or more .` ,
                validate: (value,[minimumVal]) => {
                    value = Number(value.replaceAll(',','',value))
                    minimumVal = Number(minimumVal);
                    if (value < minimumVal ) {
                        return false;
                    }
                    return true;
                }
            });
        Validator.extend(
            'salaryGrossValidateAdvanced', {
                getMessage: (field,[minimumVal])  =>` The ${field} field must be ${minimumVal} or more .` ,
                validate: (value,[minimumVal]) => {
                    value = Number(value.replaceAll(',','',value))
                    minimumVal = Number(minimumVal);
                    if (value < minimumVal ) {
                        return false;
                    }
                    return true;
                }
            });
        Validator.extend('cvv', {
            getMessage: field => ` The ${field} is invalid.`,
            validate: (value, [cardNumber]) => {
                if (cardNumber?.startsWith('3') && 4 === value.toString().length) {
                    return true;
                } else if (!cardNumber?.startsWith('3') && 3 === value.toString().length) {
                    return true;
                }
                return false;
            },
        });
    }
}
