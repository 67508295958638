<template>
    <ModalInfo
        :modalData="modalData"
        @close="$emit('close')"
    >
        <p class="mt-2 text-base color-grey-2 text-center mb-3">
            Something wrong with your verification status. Please connect to support.
        </p>
    </ModalInfo>
</template>
<script>
import ModalInfo from "~/shared/components/modals/ModalInfo";
export default {
    name: 'VerificationEmpty',
    components: {ModalInfo},
    data(){
        return {
            modalData: {
                icon: {
                    name: "alert-thin-circle-outline",
                    class: "color-red-light",
                },
                title: "We can't process your request",
                btnText: "Contact support",
                href: 'mailto:support@nteams.com'
            }
        }
    },
}
</script>
