import {serialize} from "object-to-formdata";
import axios from "axios";

export default class Model {
    constructor(path = null) {
        this.path = path;
        this.values = {};
        this.hidden = [];
        this.serverValidationErrors = {};
    }

    getData(obj = {}) {
        let data = {};
        for (const [key, value] of Object.entries(obj)) {
            data[key] = value;
        }

        return serialize(Object.assign(data, this.getVals()), { indices: true });
    }

    getVals() {
        let data = {};
        for (const [key, value] of Object.entries(this.values)) {
            if (!this.hidden.includes(key) && value !== undefined) {
                if (value instanceof Model) {
                    data[key] = value.getVals();
                } else if (value instanceof Array) {
                    data[key] = value.map((item) => (item instanceof Model ? item.getVals() : item));
                } else {
                    data[key] = value;
                }
            }
        }
        return data;
    }

    clear() {
        for (const [key, value] of Object.entries(this.values)) {
            this.values[key] = Array.isArray(value) ? [] : null;
        }
    }

    // setValues(obj = {}){
    //     for (const [key, value] of Object.entries(obj)) {
    //         this.values[key] = value;
    //     }
    // }

    create(path) {
        return axios.post(path || this.path + "create", this.getData());
    }
    draft(path) {
        return axios.post(path || this.path + "draft", this.getData());
    }
    update(path) {
        return axios.post(path || this.path + "update/" + this.values.id, this.getData({ _method: "PUT" }));
    }
    save(path) {
        return this.values.id? this.update(path): this.create(path);
    }
    destroy(path) {
        return axios.delete(path || this.path + "destroy/" + this.values.id);
    }

    clearServerValidationFieldErrors(fieldName) {
        if (this.serverValidationErrors[fieldName]) {
            delete this.serverValidationErrors[fieldName];
        }
    }

    addNestedServerValidationFieldErrors(errors) {
        for (let [key, value] of Object.entries(errors)) {
            const keys = key.split(".");
            if (keys.length === 1) {
                this.serverValidationErrors[key] = value;
            } else {
                key = keys.shift();
                if (this.values[key] instanceof Model) {
                    this.values[key].addNestedServerValidationFieldErrors({ [keys.join(".")]: value });
                } else if (this.values[key] instanceof Array) {
                    const arrayIndex = keys.shift();
                    if (this.values[key][arrayIndex] instanceof Model) {
                        this.values[key][arrayIndex].addNestedServerValidationFieldErrors({ [keys.join(".")]: value });
                    }
                }
            }
        }
    }

    addServerValidationFieldErrors(fieldName, errors) {
        this.serverValidationErrors[fieldName] = errors;
    }
}
