<!-- @format -->

<template>
    <div class="modal-container modal-info vue-repo-app custom-modal-info-class"  :class="customContainerClasses">
        <button v-if='closeModalIcon' @click="closeDialog" class="btn btn-close medium-text bold-text color-black ml-auto pr-0 mb-2 pb-2">
            <i class="icon-times pr-0"></i>
        </button>
        <div class="modal-header" :class="customHeaderClasses">
            <BaseIcon :name="modalData.icon.name" :class="modalData.icon.class" />
        </div>
        <div class="modal-body" :class="customBodyClasses">
            <h2 class="heading-2 font-medium color-black text-center mb-3">
                {{ modalData.title }}
            </h2>
            <template v-if="!$slots['default']">
                <p v-html="__sanitizeHtml(modalData.text)" class="paragraph text-base color-grey-2 text-center"></p>
            </template>
            <template v-else>
                <slot></slot>
            </template>
        </div>
        <div class="modal-footer row justify-content-center m-0">
            <BaseButton
                v-if="modalData.btnText"
                type="button"
                :loading="loading"
                :disabled="invalid"
                :href="modalData.href || null"
                @click="handlePrimaryButtonClickEvent()"
                class="btn-big btn-purple text-base font-medium color-white w-100"
            >
                {{ modalData.btnText }}
            </BaseButton>

            <BaseButton
                v-if="modalData.btnTextSecond"
                type="button"
                :disabled="invalid"
                @click="$emit('close-modal')"
                class="btn-big btn-purple-outline text-base font-medium w-100 mt-2"
            >
                {{ modalData.btnTextSecond }}
            </BaseButton>
            <slot name="underButton"></slot>
        </div>
    </div>
</template>

<script>
import BaseButton from '~/components/BaseElements/BaseButton.vue';
import BaseIcon from '~/components/BaseElements/BaseIcon.vue';
import Helpers from '~/shared/mixins/Helpers';

export default {
    name: 'ModalInfo',

    components: {
        BaseButton,
        BaseIcon,
    },

    mixins: [Helpers],

    props: {
        closeModalIcon: {
            type: Boolean,
            default: true,
        },
        modalData: {
            type: Object,
            default: () => {},
        },
        invalid: {
            type: Boolean,
            default: false,
        },
        customContainerClasses: {
            type: String,
            default: '',
        },
        customHeaderClasses: {
            type: String,
            default: '',
        },
        customBodyClasses: {
            type: String,
            default: '',
        },
        loading: {
            default: false
        },
        onClickEmitConfirmOnly: {
            default: false,
        },
        closeModalIcon: {
          default: true,
        }
    },

    methods: {
        handlePrimaryButtonClickEvent() {
            this.$emit('on-confirm');
            if (!this.onClickEmitConfirmOnly) this.$emit('close-modal');
        },
        closeDialog(){
            this.$emit('close-dialog');
        },
    }
};
</script>
