<template>
    <div v-resize="onResize">

        <!-- Loader -->
        <loader v-if="loading" :value="loading"></loader>

        <!-- Page content -->
        <template v-else>
            <wallet-app v-if="appLayout === 'wallet'" :page-data="pageData"></wallet-app>
            <dashboard-app v-else-if="appLayout === 'dashboard'" :page-data="pageData"></dashboard-app>
            <wizard-app v-else-if="appLayout === 'wizard'" :page-data="pageData"></wizard-app>
            <auth-app v-else-if="appLayout === 'auth'" :page-data="pageData"></auth-app>
            <guest-app v-else-if="appLayout === 'guest'" :page-data="pageData"></guest-app>
			<empty-app v-else-if="appLayout === 'empty'" :page-data="pageData"></empty-app>
        </template>

        <!--SCA Card Verification-->
        <secure-card-modal></secure-card-modal>

        <template v-if="__isAuth">

            <!--Upgrade Modal-->
            <upgrade-switcher v-model="$store.state.upgradeDialog"/>

            <!--Add money Modal-->
            <add-money
                :showModal="$store.state.addMoneyDialog"
                :value="$store.state.addMoneyDialog"
                :isModal="true"
            />
        </template>
      <ModalTooManyRequest
          v-if="tooManyRequestsModal"
          :dialog="tooManyRequestsModal"
      />
    </div>
</template>
<script>
import DashboardApp from "./layouts/DashboardApp.vue";
import WalletApp from "./layouts/WalletApp.vue";
import AuthApp from "./layouts/AuthApp.vue";
import WizardApp from "./layouts/WizardApp.vue";
import GuestApp from "./layouts/GuestApp.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import Loader from "~/shared/components/Loader.vue";
import Helpers from "~/shared/mixins/Helpers";
import UpgradeSwitcher from "~/shared/components/modals/UpgradeSwitcher.vue";
import AddMoney from "~/modules/dashboard/components/payment/AddMoney";
import StyleSystem from "~/plugins/StyleSystem";
import EmptyApp from "@/layouts/EmptyApp";
import SecureCardModal from "@/components/Modals/SecureCardModal.vue";
import ModalTooManyRequest from "@/modules/wallet/components/WalletDashboard/Modals/ModalTooManyRequest.vue";
import ModalHolidayNotify from "@/modules/wallet/components/WalletDashboard/Modals/ModalHolidayNotify.vue";

export default {
    name: 'App',
    components: {
      ModalHolidayNotify,
      ModalTooManyRequest,
        SecureCardModal,
        EmptyApp, GuestApp, UpgradeSwitcher, Loader, AuthApp, WalletApp, DashboardApp, WizardApp, AddMoney},
    mixins: [Helpers],
    data() {
        return {
            StyleSystem: new StyleSystem()
        }
    },
    computed: {
        ...mapState('initialization', {
            dataLoading: 'loading',
            pageDataLoading: 'pageDataLoading',
            forceLoading: 'forceLoading',
            pageData: 'pageData'
        }),
        ...mapGetters('auth', ['__isAuth']),
        ...mapState(['tooManyRequestsModal']),
        appLayout() {
            return this.$route.meta.app;
        },
        loading() {
            return this.pageDataLoading || this.dataLoading || this.forceLoading;
        },
    },
    methods: {
        ...mapActions("screen", ["setData"]),
        // showCardUpdate(cardId) {/*todo: fix layouts*/
        //     this.updateCard = this.$store.state.cards.find(item => parseInt(item.id) === parseInt(cardId));
        //     this.__changeRoute('settings?tab=atm_cards');
        // },
        onResize() {
            this.$store.commit('setData', {
                key: 'window',
                data: {width: window.innerWidth, height: window.innerHeight},
            });
            this.$store.commit('screen/SET_DATA', {
                key: 'window',
                data: {width: window.innerWidth, height: window.innerHeight},
            });
        },
    }
}
</script>
