<template>
    <div class="payoneer">
        <div class="payment-option-details-paypal">
            <div class="mb-30">
                <h5 class="text-center heading-1 bold-text">
                    Add money via Payoneer
                </h5>
                <p class="text-center medium-text color-dark-grey-6 lh-120">
                    Find instructions below
                </p>
            </div>
            <div class="card-group rounded-5 mb-30">
                <div class="card mb-0 pa-30">
                    <div class="card-body">
                        <div class="p-20 mb-9 bg-white rounded-5 credit-card-item">
                            <div class="d-flex justify-content-between">
                                <div><span class="noticeboard-img bg-pastel-blue d-flex align-items-center justify-content-center"><img class="w-100" src="/images/payoneer-icon.svg"></span></div>
                                <div style="width: 100%;">
                                    <span class="normal-text-3 bold-text mb-1 d-block">Payoneer</span>
                                    <div class="mt-2">
                                        <tm-advanced-select
                                            label="Balance"
                                            :placeholder="balances.length ? `Select balance` : `${wallet.currency.code} balance not found`"
                                            :disabled="balanceIdDisabled()"
                                            :text-capitalize="false"
                                            item-value="id"
                                            :items="balances"
                                            direction="between"
                                            class="margin-left-auto remove-margin-bottom"

                                            v-model="obj.values.payoneer.balance_id"
                                            v-validate="obj.rules.payoneer.balance_id"
                                            name="balance_id"
                                            data-vv-as="balance"
                                            :error-messages="errors.collect('balance_id')"

                                            @input="balanceChanged"
                                            
                                            :list-p="false"
                                            :list-item-p="false"
                                            list-item-class="ml-1 pl-1"
                                            list-item-group-class="pt-1"
                                            data-vv-validate-on="change"
                                        >
                                            <template v-slot:selection="{ item }">
                                                <div class="d-flex align-center">
                                                    <avatar size="1.25rem" :avatar="getBalanceFlagUrl(item)"></avatar>
                                                    <span class="tm-medium-text tm-color-black mr-1">{{ item.currency }}</span> <span class="bold-text tm-color-black mr-1">{{ item.available_balance }}</span>
                                                </div>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <div class="d-flex align-center">
                                                    <avatar size="1.25rem" :avatar="getBalanceFlagUrl(item)"></avatar>
                                                    <span class="tm-medium-text tm-color-black mr-1">{{ item.currency }}</span> <span class="bold-text tm-color-black mr-1">{{ item.available_balance }}</span>
                                                </div>
                                            </template>
                                        </tm-advanced-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TmInput from "~/shared/components/form/TmInput";
import TmTextArea from "~/shared/components/form/TmTextArea";
import TmAdvancedSelect from "~/shared/components/form/TmAdvancedSelect";
import LoaderBox from "~/shared/components/LoaderBox";
import { mapGetters } from 'vuex';
import Avatar from "~/shared/components/Avatar";

export default {
    name: 'AddByPayoneer',
    components: {TmTextArea, TmInput, TmAdvancedSelect, LoaderBox, Avatar},
    props: {
        obj: {
            required: true,
            type: Object
        },
        wallet: {
            required: true,
            type: Object
        },
    },
    computed: {
        ...mapGetters({
            balancesReadyToken: "payoneer/balancesReadyToken",
            getBalanceFlagUrl: "payoneer/getBalanceFlagUrl",
            findAccountByBalanceId: "payoneer/findAccountByBalanceId",
            
            dailyUsedTopupCurrency: "payoneer/dailyUsedTopupCurrency",
            todayTransactions: "payoneer/todayTransactions",
            dailyLimitTopupCurrency: "payoneer/dailyLimitTopupCurrency",
            maxDailyTransactions: "payoneer/maxDailyTransactions",

            monthlyUsedTopupCurrency: "payoneer/monthlyUsedTopupCurrency",
            tomonthTransactions: "payoneer/tomonthTransactions",
            monthlyLimitTopupCurrency: "payoneer/monthlyLimitTopupCurrency",
            maxMonthlyTransactions: "payoneer/maxMonthlyTransactions",

            minLimitTopupCurrency: "payoneer/minLimitTopupCurrency",
        }),
        balances(){
            return this.balancesReadyToken.filter(i => i.currency == this.wallet.currency.code);
        }
    },
    methods: {
        balanceChanged(){
            const balanceId = this.obj.values.payoneer.balance_id;
            const account = this.findAccountByBalanceId(balanceId);
            if(account){
                const balance = account.balances.find(i=>i.id==balanceId);

                this.obj.setPayoneerCurrency(balance.currency);
                this.obj.setPayoneerAccountModelId(account.token_model.id);
            }else{
                this.obj.setPayoneerCurrency(null);
                this.obj.setPayoneerAccountModelId(null);
            }
        },
        amountAddError(){
            const currency = this.wallet.currency.code;
            const amount = parseFloat(this.obj.values.amount);

            const minLimitTopup = this.minLimitTopupCurrency(currency);

            const dailyUsedTopup = this.dailyUsedTopupCurrency(currency);
            const todayTransactions = this.todayTransactions(currency);
            const dailyLimitTopup = this.dailyLimitTopupCurrency(currency);
            const maxDailyTransactions = this.maxDailyTransactions(currency);

            const monthlyUsedTopup = this.monthlyUsedTopupCurrency(currency);
            const tomonthTransactions = this.tomonthTransactions(currency);
            const monthlyLimitTopup = this.monthlyLimitTopupCurrency(currency);
            const maxMonthlyTransactions = this.maxMonthlyTransactions(currency);

            if(minLimitTopup && (amount < minLimitTopup)){
                this.obj.values.payoneer.balance_id = null;
                this.errors.add({
                    field: 'balance_id',
                    msg: `Minimum amount ${this.wallet.currency.code} ${minLimitTopup.toFixed(2)}`
                });
            } else if(dailyLimitTopup && ((dailyUsedTopup + amount) > dailyLimitTopup)){
                this.obj.values.payoneer.balance_id = null;
                this.errors.add({
                    field: 'balance_id',
                    msg: `Maximum daily amount ${this.wallet.currency.code} ${dailyLimitTopup.toFixed(2)}. ${this.wallet.currency.code} ${dailyUsedTopup.toFixed(2)} used.`
                });
            } else if(monthlyLimitTopup && ((monthlyUsedTopup + amount) > monthlyLimitTopup)){
                this.obj.values.payoneer.balance_id = null;
                this.errors.add({
                    field: 'balance_id',
                    msg: `Maximum monthly amount ${this.wallet.currency.code} ${monthlyLimitTopup.toFixed(2)}. ${this.wallet.currency.code} ${monthlyUsedTopup.toFixed(2)} used.`
                });
            } else if(maxDailyTransactions && todayTransactions >= maxDailyTransactions){
                this.obj.values.payoneer.balance_id = null;
                this.errors.add({
                    field: 'balance_id',
                    msg: `Daily max transactions count ${maxDailyTransactions}.`
                });
            } else if(maxMonthlyTransactions && tomonthTransactions >= maxMonthlyTransactions){
                this.obj.values.payoneer.balance_id = null;
                this.errors.add({
                    field: 'balance_id',
                    msg: `Monthly max transactions count ${maxMonthlyTransactions}.`
                });
            }
        },
        balanceIdDisabled(){
            return !this.balances.length || this.errors.has('balance_id');
        }
    },
    mounted(){
        this.amountAddError();
    },
}
</script>
