<!-- @format -->

<template>
    <div class="payment-option-step">
        <h5 class="text-center heading-1 bold-text"> How would you like to add money? </h5>
        <p class="text-center medium-text color-dark-grey-6 lh-120"> Choose payment option </p>
        <div class="mt-30">
            <ul class="how-list pb-20">
                <li
                    v-for="item in visibleOptions"
                    :key="item.value"
                    class="mb-9 bg-white rounded-5 how-list-item pointer"
                    :class="[item.value === obj.values.option ? 'active' : '', item.class, 'd']"
                    @click="updateOption(item)"
                >
                    <div v-if="!item.hidden" class="d-flex mb-3 justify-content-between p-20">
                        <label class="align-start mt-2 cursor-pointer">
                            <div v-if="item.iconimg">
                                <span
                                    class="noticeboard-img bg-pastel-blue d-flex align-items-center justify-content-center"
                                >
                                    <img class="w-100" :src="item.iconimg" />
                                </span>
                            </div>
                            <div v-else>
                                <span
                                    class="noticeboard-img bg-pastel-blue d-flex align-items-center justify-content-center"
                                >
                                    <i :class="item.icon" class="heading-2 color-dark-blue lh-18"></i>
                                </span>
                            </div>
                            <div class="w-100">
                                <span class="normal-text-3 bold-text mb-1 d-block">{{ item.name }}</span>

                                <!--Bank Option Content-->
                                <template v-if="'bank' === item.value">
                                    <span class="small-text color-dark-grey lh-18 d-block mt-2">
                                        Manually transfer the money to wallet using your bank.
                                    </span>
                                    <span class="mt-2 small-text color-feedback-info lh-18 d-block mb-1">
                                        <i class="icon-info-circle color-feedback-info fs-14"></i>
                                        Will be processed within 3 working days.
                                    </span>
                                </template>

                                <!--Card Option Content-->
                                <template v-else-if="'card' === item.value">
                                    <span class="small-text color-dark-grey lh-18 d-block mt-2">
                                        Fees calculated in next steps.
                                    </span>
                                    <span class="small-text color-dark-grey lh-18 d-block">
                                        Send from your Visa or Mastercard.
                                    </span>
                                    <span class="mt-2 small-text color-feedback-info lh-18 d-block mb-1">
                                        <i class="icon-info-circle color-feedback-info fs-14"></i>
                                        Immediate transfer.
                                    </span>
                                    <span>
                                        <img src="/images/wallet/visa.svg" alt="visa.svg" width="15px" />
                                        <img src="/images/wallet/mastercard.svg" alt="mastercard.svg" width="15px" />
                                        <img
                                            src="/images/wallet/american-express.svg"
                                            alt="american-express.svg"
                                            width="18px"
                                        />
                                        <img src="/images/wallet/discover.svg" alt="discover.svg" width="18px" />
                                    </span>
                                </template>

                                <!--Paypal Option Content-->
                                <template v-else-if="'paypal' === item.value">
                                    <span class="small-text color-dark-grey lh-18 d-block mt-2">
                                        Fees calculated in next steps.
                                    </span>
                                    <span class="small-text color-dark-grey lh-18 d-block">
                                        Send from your Visa or Mastercard.
                                    </span>
                                    <span class="mt-2 small-text color-feedback-info lh-18 d-block">
                                        <i class="icon-info-circle color-feedback-info fs-14"></i>
                                        Will be processed within 24 working hours.
                                    </span>
                                </template>

                                <!--Payoneer Option Content-->
                                <template v-else-if="'payoneer' === item.value">
                                    <template v-if="currencies.includes(wallet.currency.code)">
                                        <template v-if="accountsConnectedInitedAndLoaded">
                                            <div v-if="accountsConnectedActiveReady.length" class="mt-2">
                                                <div>
                                                    <span class="small-text color-dark-grey lh-18 d-block mt-2">
                                                        Fees calculated in next steps.
                                                    </span>
                                                    <span class="small-text color-dark-grey lh-18 d-block">
                                                        Send from your Payoneer account.
                                                    </span>
                                                    <span class="mt-2 small-text color-feedback-info lh-18 d-block">
                                                        <i class="icon-info-circle color-feedback-info fs-14"></i>
                                                        Immediate transfer.
                                                    </span>
                                                </div>
                                            </div>
                                            <payoneer-not-ready v-else></payoneer-not-ready>
                                        </template>
                                        <template v-else>
                                            <div class="w-300 mt-2">
                                                <loader-box class="w-300" min-height="auto"></loader-box>
                                            </div>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <span class="small-text color-dark-grey lh-18 d-block mt-2">
                                            Available only for {{ currencies.join(', ') }}
                                        </span>
                                    </template>
                                </template>

                                <!--Payment Request Option Content-->
                                <template v-else-if="'payment_request' === item.value">
                                    <span class="small-text color-dark-grey lh-18 d-block mt-2">
                                        Fees calculated in next steps.
                                    </span>
                                    <span class="small-text color-dark-grey lh-18 d-block">
                                        Make a Payment Request to your Client.
                                    </span>
                                    <span class="mt-2 small-text color-feedback-info lh-18 d-block">
                                        <i class="icon-info-circle color-feedback-info fs-14"></i>
                                        Will be processed within 3 working days.
                                    </span>

<!--                                    <div class="tm-err-box rounded mt-1 vue-repo-app" style="" v-if="ibanNotRequested">
                                      <span style="font-size: 0.75rem;">
                                             <template v-if="walletIban.iban_request">
                                                  Please note, you have not yet received an IBAN.
                                                  <a :href="`mailto:`+$store.state.systemEmails.support" class="color-white font-bold">Contact Support</a>
                                             </template>
                                             <template v-else>
                                                Please note: You have not yet requested an IBAN for receiving bank transfers.
                                                  <button
                                                      @click="$event.preventDefault(); ibanDialog = true"
                                                      class=""
                                                      :class="'color-white font-bold'"
                                                  >
                                                    <div>
                                                        <span class="tm-initial">Request now</span>
                                                    </div>
                                                </button>
                                             </template>

                                          </span>
                                    </div>-->
                                </template>
                            </div>
                        </label>
                        <tm-radio v-model="radioOption" :disabled="isActive(item)" :item-value="item.value"></tm-radio>
                    </div>

                    <div v-if="'card' === item.value" class="banner info border-radius-0 mb-0">
                        <i class="icon-info-circle color-feedback-info mr-1 custom-icon-info-css"/>
                        <p class="small-text color-black">
                            <template v-if="['USD', 'EUR', 'GBP', 'NGN'].includes(wallet.currency.code)">
                                <template v-if="isModule">
                                    Currently unavailable. Please top up the wallet via bank transfer or <a :href="`mailto:`+$store.state.systemEmails.support"
                                                                                                            :style="isModule ? 'text-decoration: underline;' : ''"
                                >contact us</a>
                                </template>
                                <template v-else-if="selectedCountryIsNigeria()">
                                    I understand that a fee of 2% will be applied to the card purchase.
                                </template>
                                <template v-else>
                                    I understand that a fee of {{ transferPercent.card }}% will be applied to the card purchase.
                                </template>
                            </template>
                            <template v-else>
                                I understand that a fee of {{ transferPercent.card }}% will be applied to the card purchase.
                            </template>
                        </p>
                    </div>

                    <template v-if="!closeBank">
                        <div
                            v-if="'payment_request' === item.value && showCurrencyBanner"
                            class="banner info border-radius-0 mb-0"
                        >
                            <i class="icon-info-circle-fill mr-1"></i>
                            <p class="small-text color-black">
                                While we are in the process of supporting direct card payments for this currency, there
                                will be a fee deducted if your recipient pays by card. To avoid this fee, please ask
                                your recipient to pay via bank transfer.
                            </p>
                        </div>
                    </template>
                </li>
            </ul>
        </div>
        <iban-request-form
            v-model="ibanDialog"
            :wallet="walletIban"
        ></iban-request-form>

    </div>
</template>
<script>
import { mapGetters } from 'vuex';

import LoaderBox from '~/shared/components/LoaderBox';
import Helpers from '~/shared/mixins/Helpers';
import WalletIban from '~/shared/mixins/Wallet/WalletIban';

import TmRadio from '~/shared/components/form/TmRadio';
import PayoneerNotReady from '../_payoneer/PayoneerNotReady';
import IbanRequestForm from "../../modals/IbanRequestForm";
export default {
    name: 'SelectOption',

    components: {IbanRequestForm, TmRadio, PayoneerNotReady, LoaderBox },

    mixins: [Helpers, WalletIban],

    props: {
        obj: {
            required: true,
            type: Object,
        },
        wallet: {
            default: null,
        },
    },

    data() {

        return {
            ibanTab: this.wallet ? this.wallet.currency.code : this.ibanTab,
            contactUrl: this.$store.state.contactUrl,
            radioOption: null,
        };
    },
    watch:{

    },

    computed: {
        isModule(){
            return this.wallet && this.wallet.is_modulr
        },
        transferPercent() {
            let currency = null;
            if (this.wallet) {
                currency = this.wallet.currency.code;
            }
            return this.transferPercentData(currency);
        },
        closeBank() {
            const withoutBank = this.$store.state.addMoneyWithoutBank || [];
            const currency = this.wallet ? this.wallet.currency.code : null;
            return withoutBank.includes(currency);
        },
        visibleOptions() {
            if (Array.isArray(this.options)) {
                return this.options.filter(option => !option.hidden);
            }
            return this.options;
        },
        options() {
            let data = [
                { name: 'Credit or Debit Card', icon: 'icon-admin-card', class: 'credit-card-item', value: 'card' },
                { name: 'PayPal', icon: 'icon-paypal', class: 'paypal-item', value: 'paypal', hidden:true },
                {
                    name: 'Payoneer',
                    iconimg: '/images/payoneer-icon.svg',
                    disabled:
                        !this.enabled ||
                        !this.accountsConnectedInitedAndLoaded ||
                        !this.validPayoneerCurrency ||
                        !this.accountsConnectedActiveReady.length,
                    class: 'payoneer-item',
                    value: 'payoneer',
                    hidden: !this.enabled || !this.validPayoneerCurrency,
                },
                {
                    name: 'Request Payment',
                    icon: 'icon-request-payment',
                    class: 'request-payment-item',
                    value: 'payment_request',
                    hidden: this.isSoloEntityWallet,
                },
            ];

            if ('NGN' !== this.wallet.currency.code) {
                data.unshift({
                    name: 'Bank Account',
                    icon: 'icon-bank-outline',
                    class: 'bank-account-item',
                    value: 'bank',
                });
            }

            if (this.closeBank) {
                data = data.filter(i => 'bank' !== i.value);
            }

            if (this.obj.values.option && !data.find(i => i.value === this.obj.values.option)) {
                this.obj.values.option = data[0].value;
            }
            this.radioOption = this.obj.values.option;
            return data;
        },
        showCurrencyBanner() {
            return this.wallet && !this.$store.state.stripeRegisteredCurrencies.includes(this.wallet.currency.code);
        },

        ...mapGetters({
            enabled: 'payoneer/enabled',
            accountsConnectedInitedAndLoaded: 'payoneer/accountsConnectedInitedAndLoaded',

            currencies: 'payoneer/currencies',
            accountsConnectedActiveReady: 'payoneer/accountsConnectedActiveReady',
            wallets : 'wallets'
        }),
        validPayoneerCurrency() {
            return this.currencies.includes(this.wallet.currency.code);
        },
        isSoloEntityWallet(){
            return this.wallet?.is_solo_entity;
        }
    },

    mounted() {
        const activeOptions = this.options.filter((i) => {
            const hidden = i.hidden || false;
            const disabled = i.disabled;
            return !hidden && !disabled;
        });

        const selectedOptionActive = activeOptions
            .find(i => i.value === this.radioOption);
        if(!selectedOptionActive){
            this.updateOption(activeOptions[0]);
        }
    },

    methods: {
        selectedCountryIsNigeria() {
            return 'NGN' === this.wallet.currency.code;
        },
        isActive(item) {
            return this.radioOption == this.obj.values.option;
        },
        updateOption(item) {
            const disabled = item.disabled || false;
            const prevOption = this.obj.values.option;
            this.obj.values.option = item.value;

            if (disabled) {
                this.obj.values.option = prevOption;
            }

            this.radioOption = this.obj.values.option;
        },
    },
};
</script>
