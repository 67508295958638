export const namespaced = true;
export const state = {
    tab_type: 'all_documents',
    folderTabs: [],
    headerData: {},
    selectedItem: null,
    paginate: {
        current_page: 1,
        per_page: 8,
        total: 0
    },
    path: '/documents/',
    members: [],
    documents: [],
    filesDocuments: [],
    selectedFolder: null,
    updated: false,
    filter: { status: 0, folderId: -1 },
    types: ['folder', 'file'],
    uploadFileDialog: false,
    categories: [{value: 'document', title: 'Files'}, {value: 'folder', title: 'Folders'}],
};

export const mutations = {
    SET_DATA(state, params) {
        state[params.key] = params.value;
    },
    setFolderTabs(state, items) {
        state.folderTabs = Object.values(items);
    },
    SET_DOCUMENTS(state, documents) {
        state.documents = (documents.data || []).filter(i => 1 !== i.is_file);
        state.filesDocuments = (documents.data || []).filter(i => 1 === i.is_file);
        state.paginate = {
            current_page: documents.current_page,
            per_page: documents.per_page,
            total: documents.total,
        };
    },
};

export const actions = {
    setMembers({state, commit}, params = {}) {
        const path = '/get/search-team-members';
        axios.get(path, {params}).then(response => {
            commit('SET_DATA', {key: 'members', value: response.data});
        });
    },
    setSelectedItem({commit}, selectedItem = null) {
        commit('SET_DATA', {key: 'selectedItem', value: selectedItem});
    },
    updated({commit}, value) {
        commit('SET_DATA', {key: 'updated', value});
    },
    setTabType({commit}, val) {
        commit('SET_DATA', {key: 'tab_type', value: val});
    },
    setHeaderData({state, commit}) {
        axios.post(`${state.path}header-data`).then(({data}) => {
            commit('SET_DATA', {key: 'headerData', value: data});
        });
    },
    setFolderTabs({state, commit, dispatch}) {
        const allData = [];
        allData[0] = {
            text: '',
            disabled: false,
            value: null,
        };
        const titles = {
            employees_folders: 'Employees',
            organizations_not_access: 'Company',
        };
        if (titles[state.tab_type]) {
            if(state.selectedItem) {
                allData[0].text = `${titles[state.tab_type]} ${state.selectedItem ? 'Folders' : ''}`;
            } else  {
                allData[0].text = '';
            }
            if (state.selectedItem) {
                allData.push({
                    text: state.selectedItem?.name,
                    disabled: false,
                    value: state.selectedItem?.id,
                    not_folder: 1,
                });
            }
        }

        if (state.selectedFolder) {
            axios.post(`${state.path}folder-tabs/${state.selectedFolder}`).then(({data}) => {
                Object.keys(data).forEach(i => {
                    allData.push({
                        text: data[i],
                        disabled: false,
                        value: i,
                    });
                });
                allData[0].text = allData[0].text || 'Folders';
                allData[allData.length - 1].disabled = true;
                commit('setFolderTabs', allData);
            });
        } else {
            allData[allData.length - 1].disabled = true;
            commit('setFolderTabs', allData);
        }
    },
    setSelectedFolder({commit, dispatch}, value) {
        commit('SET_DATA', {key: 'selectedFolder', value});
        dispatch('setFolderTabs');
    },
    setDocuments({state, commit, dispatch}, params = {}) {
        dispatch('global/startLoading', {}, {root: true});
        params.limit = state.paginate.per_page;
        dispatch('setSelectedFolder', params?.parentId);

        if (params.categories.length && params.categories.length !== state.categories.length) {
            params.data_type = params.categories[0];
        }

        axios.get(`${state.path}paginate${params?.parentId ? '/' + params?.parentId : ''}`, {params})
            .then(response => {
                commit('SET_DOCUMENTS', response.data);

                if (params.parentValue) {
                    const items = response.data.data || [];
                    dispatch('setSelectedFolder', items[0]?.parent_id || items[0]?.folder_id || null);
                }
            })
            .catch(error => {
                commit('setAlert', {type: 'error', message: error.response.data.message}, {root: true});
            }).finally(() => {
            dispatch('global/stopLoading', {}, {root: true});
            commit('SET_DATA', {key: 'updated', value: false});
        });
    },
    editName({commit, state}, item) {
        if (!item.name) {
            return false;
        }
        const path = item.is_file ? 'update/' : 'folder-update/';

        axios.put(`${state.path}${path}${item.id}`, {name: item.name}).then(response => {
            commit('SET_DATA', {key: 'updated', value: true});
        })
            .catch(error => {
            });

    },

    setUpdated({commit, state}) {
        commit('SET_DATA', {key: 'updated', value: !state.updated});
    },

    restoreAction({commit, state}, document) {
        const path = document.is_file ? 'restore' : 'folder-restore';
        axios.get(`${state.path}${path}/${document.id}`).then(() => {
            commit('SET_DATA', {key: 'updated', value: true});
        });
    },

    deleteAction({commit, state}, document) {
        const path = document.is_file ? 'destroy' : 'folder-destroy';
        axios
            .delete(`${state.path}${path}/${document.id}`)
            .then(() => {
                commit('SET_DATA', {key: 'updated', value: true});
            })
            .catch(error => {
                this.$store.commit('setAlert', {type: 'error', message: error.response.data.message});
            });
    },
};

export const getters = {
    headerData: state => state.headerData,
    paginate: state => state.paginate,
    members: state => state.members,
    selectedFolder: state => state.selectedFolder,
    tab_type: state => state.tab_type,
    selectedItem: state => state.selectedItem,
    folderTabs: state => state.folderTabs,
    getDocuments: state => state.documents,
    getFiles: state => state.filesDocuments,
    updated: state => state.updated,
    categories: state => state.categories,
    getRecentDocumentsTop: state => Object.values(state.headerData?.recent_files || []),


    getActiveFolder() {
        const folder = state.documents.find((document) => {
            return document.folderId == state.filter.folderId;
        });
        if (folder) return folder.name;
        return "All";
    },
};
