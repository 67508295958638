<template>
  <v-date-picker
    v-model="val"
    v-bind="$attrs"
    v-on="$listeners"
    prev-icon="icon-chevron-left"
    next-icon="icon-chevron-right"
    :picker-date.sync="pickerDate"
    :events="computedEvents"
    :allowed-dates="allowedDates"
  ></v-date-picker>
</template>

<script>
import { format, parseISO } from "date-fns";

export default {
  name: "WlBaseDatePicker",

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Array,
      default: () => [],
    },
    reservedDates: {
      type: Array,
      default: () => [],
    },
    isAbsenceManagement: {
      type: Boolean,
      default: false,
    },
    defaultToday: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      val: this.value,
      today: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),

      pickerDate: null,
    };
  },

  watch: {
    val() {
      this.$emit("input", this.val);
    },
    value() {
      this.val = this.value;
    },
  },

  methods: {
    computedEvents(date) {
      const eventColors = [];

      if (this.isAbsenceManagement) {
        const event = this.events.find((event) => event.date === date);

        if (event) {
          if (event.hasPublicDay)
            eventColors.push("bg-feedback-success-normal");
          if (event.hasSickDay) eventColors.push("bg-feedback-error-normal");
          if (event.hasHolidayDay) eventColors.push("bg-purple");
          return eventColors;
        }
      }

      return false;
    },

    allowedDates(date) {
      return !this.reservedDates.includes(date);
    },
  },
};
</script>
