<template>
    <div class="payment-last-all-steps">
        <div class="card-group rounded-5">
            <div class="card p-0 mb-0">
                <div class="card-body pb-20">
                    <div class="pt-30 text-center">
                        <div class="mb-4">
                            <img class="w-40" src="/images/wallet/check.svg" alt="check">
                        </div>
                        <p class="heading-1 bold-text mb-20">{{ title }}</p>
                        <div v-if="option === 'paypal'" class="px-30">
                            <ul>
                                <li class="d-flex border-grey rounded-5 pt-10 pl-10 pr-5 pb-10 text-left mb-20">
                                    <span class="color-purple heading-1 bold-text mr-3">1</span>
                                    <p class="normal-text-2 lh-22">
                                        Login to PayPal and send money to
                                        <strong class="d-block color-dark-blue">
                                            {{$store.state.systemEmails.paypal}}
                                        </strong>
                                        <span class="d-flex" v-if=" wallet.reference">
                                            Please use reference number: <strong class="ml-1 color-dark-blue">{{ wallet.reference }}</strong>
                                        </span>
                                    </p>
                                </li>
                                <li class="d-flex border-grey rounded-5 pt-10 pl-10 pr-5 pb-10 text-left mb-30">
                                    <span class="color-purple heading-1 bold-text mr-3">2</span>
                                    <p class="normal-text-2 lh-22">
                                        You will see your funds in your wallet within 72 hours.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div v-if="option === 'payoneer'" class="px-30 mb-20">
                            <p class="normal-text-2">
                                Your Payoneer transfer is
                                completed. Please contact <a target="_blank" :href="`mailto:`+this.$store.state.systemEmails.support">{{ this.$store.state.systemEmails.support }}</a> if you have not
                                received your payment.
                            </p>
                        </div>
                        <p class="small-text color-dark-grey mb-2">
                            {{
                                option === 'card'?
                                    'Available balance is':
                                    option === 'payment_request'?
                                        'After the request is confirmed, your balance will be':
                                        'After the payment your balance will be'
                            }}
                            <span class="color-purple bold-text">
                                {{ formatAmountNoCurrency(balance, 2, 2)}} {{ wallet.currency.code }}
                            </span>
                        </p>
                        <a
                            :href="$store.state.path"
                            class="small-text-2 bold-text color-purple"
                        >
                            Go to Dashboard
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Helpers from '~/shared/mixins/Helpers';

export default {
    name: 'PaymentReceived',
    mixins: [Helpers],
    props: {
        wallet: {
            required: true,
            type: Object
        },
        obj: {
            required: true,
            type: Object
        },
        option: {
            required: true,
            type: String
        },
        result: {
            required: true,
            type: Number
        }
    },
    computed: {
        balance(){
            return this.option === 'card' || this.option === 'payoneer' ?
                this.wallet.available_balance:
                parseFloat(this.wallet.available_balance) + this.result;
        },
        title(){
            switch (this.option) {
                case 'bank':
                    return 'Request Received';
                case 'card':
                    return 'You have added money';
                case 'payoneer':
                    return 'Payoneer Cash-in is Being Processed!';
                case 'paypal':
                    return 'Request Received';
                case 'payment_request':
                    return 'Payment request has been sent!';
            }
        }
    }
}
</script>
