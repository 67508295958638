<template>
    <ModalKYCTemplate
        :modal-data="modalData"
        :dialog="dialog"
        @on-confirm="confirm"
        @close="close()"
    >
        <div v-html="__sanitizeHtml(modalData.content)"></div>
<!--        <template v-slot:underButton>-->
<!--            <WlBaseButton-->
<!--                type="button"-->
<!--                class="btn-md btn-outline-purple text-sm font-medium color-purple w-full mt-2"-->
<!--                @click="close()"-->
<!--            >-->
<!--                Close-->
<!--            </WlBaseButton>-->
<!--        </template>-->
    </ModalKYCTemplate>
</template>

<script>
import {mapActions} from "vuex";
import ModalKYCTemplate from "./ModalKYCTemplate.vue";
import Helpers from '~/shared/mixins/Helpers';
export default {
    name: "UserRestriction",
    components: {ModalKYCTemplate},
    mixins: [Helpers],
    props: {},
    data() {
        return {
            dialog: false
        };
    },
    computed: {
        value(){
            return this.$store.state.kyc.restrictionDialog;
        },
        modalData(){
            switch (this.$store.state.kyc.restrictionType) {
                case 'aml':
                    return {
                        icon: {
                            name: "warning",
                            class: "color-feedback-warning",
                        },
                        titleCenter: true,
                        title: "Temporary suspension on payment actions",
                        content: `<p class="mt-2 text-base color-grey-2 text-center">
                            Additional documents may be requested, potentially causing slight
                            transaction delays.
                        </p>
                        <p class="mt-2 text-base color-grey-2 text-center">
                            There will be a temporary suspension on your ability to proceed with any
                            transactions on our platform. We appreciate your patience and
                            cooperation.
                        </p>
                        <p class="mt-2 text-base color-grey-2 text-center">
                            For concerns, please contact our support team.Thank you for your
                            understanding.
                        </p>`,
                        btnText: "Contact us",
                        href: 'mailto:support@nteams.com'
                    }
                case 'block':
                case 'final':
                    return {
                        icon: {
                            name: "warning",
                            class: "color-feedback-error",
                        },
                        titleCenter: true,
                        title: "Final rejection",
                        content: `<p class="mt-2 text-base color-grey-2 text-center">
                            We regret to inform you that your verification has been rejected.
                        </p>
                        <p class="mt-2 text-base color-grey-2 text-center">
                            For further details and assistance,
                            please contact our support team who will be able to provide you with
                            more information and guide you through the next steps.
                        </p>`,
                        btnText: "Contact us",
                        href: 'mailto:support@nteams.com'
                    }
                default:
                    return {};
            }
        }
    },
    watch: {
        value() {
            this.dialog = this.value;
        },
        dialog(){
           if (!this.dialog){
               this.close();
           }
        }
    },
    methods: {
        ...mapActions('kyc', ['__closeRestrictionDialog']),
        confirm() {
            this.$emit("confirm");
            this.close();
        },
        close(){
            this.__closeRestrictionDialog()
        }
    },
};
</script>
