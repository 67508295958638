<template>
    <modal v-model="dialog" width="25.0625rem">
        <div class="modal-dialog center modal-sm modal-downgrade-subscription tm-bg-white rounded">
            <div class="modal-content">
                <div class="modal-body">
                    <template>
                        <img :src="$store.state.imagePath+'party-icon.svg'" alt="party-icon.svg" class="mb-30">
                        <h1 class="heading-1 color-black text-center mb-2">
                            Thank You!
                        </h1>
                        <p class="paragraph text-center color-black mb-30">
                            You have successfully upgraded your plan subscription.
                        </p>
                        <router-link to="/" class="btn btn-purple w-100 medium-text bold-text color-white">Go to Dashboard</router-link>
                    </template>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import Modal from "~/shared/components/Modal";
export default {
    name: 'PlanChangeSuccess',
    components: {Modal},
    props: {
        value: {
            required: true,
            type: Boolean
        }
    },
    data() {
        return {
            dialog: this.value,
        }
    },
    watch: {
        value() {
            this.dialog = this.value
        },
        dialog() {
            this.$emit('input', this.dialog);
        },
    },
    methods: {
        closeDialog(){
            this.dialog = false;
            window.location.reload();
        }
    }
}
</script>
