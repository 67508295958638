<template>
    <div :style="{'min-height': minHeight}" class="d-flex align-center justify-center w-100 h-100">
        <v-progress-circular
            :size="50"
            :width="2"
            color="#5152fb"
            indeterminate
        ></v-progress-circular>
    </div>
</template>
<script>
export default {
    name: 'WlBaseLoaderBox',
    props: {
        minHeight: {
            default: '720px'
        }
    }
}
</script>
