import { render, staticRenderFns } from "./SingTermination.vue?vue&type=template&id=00a9bf80&scoped=true"
import script from "./SingTermination.vue?vue&type=script&lang=js"
export * from "./SingTermination.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00a9bf80",
  null
  
)

export default component.exports