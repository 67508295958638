<template>
    <error-alert
        title="Network error"
        btn-text="Try again"
        :message="message"
        :value="dialog"
        @input="toggle"
        @action="retry"
    ></error-alert>
</template>
<script>
import ErrorAlert from "~/shared/components/ErrorAlert";
export default {
    components: {ErrorAlert},
    computed: {
        message(){
            return this.$store.state.networkError.message;
        },
        dialog(){
            return this.$store.state.networkError.dialog;
        }
    },
    methods: {
        toggle(e){
            if (!e){
                this.$store.commit('networkError/close');
            }
        },
        retry(){
            this.$store.dispatch('networkError/retry');
        }
    }
}
</script>
