var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.customImg)?_c('div',{class:[
    !_vm.avatarSrc ? 'v-avatar' : '',
    _vm.offsetRight ? 'mr-2' : null,
    _vm.elevation ? 'elevation-4' : null,
    _vm.contentImgClass && !_vm.avatarSrc ? _vm.contentImgClass : '',
  ],style:({
    'background-color': !_vm.avatarSrc ? _vm.generateColor : null,
    'border-color': _vm.border ? (!_vm.avatarSrc ? _vm.generateColor : null) : null,
  }),on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.avatarSrc)?_c('img',{class:_vm.contentImgClass,attrs:{"src":`/images/${_vm.avatarSrc}`}}):_c('span',{staticClass:"white--text text-uppercase",style:({ 'font-size': _vm.fontSize })},[_vm._v(_vm._s(_vm.initials))])]):_c('v-avatar',{class:[
    _vm.offsetRight ? 'mr-2' : null,
    _vm.offsetBottom ? 'mb-05' : null,
    _vm.elevation ? 'elevation-4' : null,
    _vm.contentImgClass && !_vm.avatarSrc ? _vm.contentImgClass : '',
  ],attrs:{"rounded":_vm.rounded,"color":_vm.color ? _vm.color : !_vm.avatarSrc ? _vm.generateColor : null,"size":!_vm.width && !_vm.height ? _vm.size : null,"width":_vm.width ? _vm.width : _vm.height ? '100%' : null,"height":_vm.height ? _vm.height : _vm.width ? '100%' : null},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.avatarSrc)?_c('v-img',{staticClass:"shadow-sm",class:_vm.contentImgClass,attrs:{"contain":_vm.contain,"src":_vm.uploadPreview
        ? _vm.avatarSrc
        : _vm.outsourcedAvatar
        ? _vm.avatarSrc
        : `/images/${_vm.avatarSrc}`},on:{"error":function($event){_vm.imgError = true}}}):_c('span',{staticClass:"white--text text-uppercase",style:({ 'font-size': _vm.fontSize })},[_vm._v(_vm._s(_vm.initials))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }