<template>
    <div>
        <modal v-model="dialog" width="400" :close-top="'1.25rem'">
            <template slot="title" v-if="plan">
                <span class="tm-heading-2 tm-color-purple">{{ plan.name }}/<small>{{ plan.type.name }}</small></span>
            </template>
            <template slot="content" v-if="plan">
                <div class="d-flex flex-column justify-center align-center w-100 my-10 mt-0">
                    <span v-if="errorMsg" class="red-status">{{errorMsg}}</span>
                </div>
                <div class="d-flex flex-column justify-center align-center w-100 my-10">
                    <img class="ma-auto mb-10" :src="$store.state.imagePath+'pos.svg'" alt="pos"/>
                    <p class="text-no-wrap plan-card-price tm-color-black mb-4">
                        <span class="price bold-text">
                            {{ formatAmount(plan.amount, $store.state.localCurrency.code) }}
                        </span>
                        <span class="tm-small-text"> /{{ plan.type.name }} </span>
                    </p>
                    <!--v-if="cardSelect"-->
                    <tm-select-card
                        style="width: 250px;"
                        :bottom-offset="true"
                        v-model="card_id"
                        :cards="cardsList"
                        v-validate="'required'"
                        name="card"
                        @updateCard="updateCard"
                        :error-messages="errors.collect('card')"
                    ></tm-select-card>
                </div>
                <div v-if="plan" class="d-flex justify-space-between">
                    <v-spacer></v-spacer>
                    <btn @click="upgrade()" :loading="loading" :text="'Get '+plan.name"></btn>
                </div>

                <!--Card Form-->
                <card-form :default-org="defaultOrg" v-model="addCard" :to-worker="true"></card-form>
            </template>
        </modal>

        <!--Success Dialog-->
        <plan-change-success v-model="successDialog"></plan-change-success>
    </div>
</template>
<script>
import Modal from "~/shared/components/Modal";
import Btn from "~/shared/components/Btn";
import Helpers from '~/shared/mixins/Helpers';
import axios from "axios";
import TmAdvancedSelect from "~/shared/components/form/TmAdvancedSelect";
import CardForm from "./CardForm";
import TmSelectCard from "~/shared/components/form/TmSelectCard";
import PlanChangeSuccess from "./PlanChangeSuccess";

export default {
    name: 'UpgradePlan',
    components: {PlanChangeSuccess, CardForm, TmAdvancedSelect, Btn, Modal, TmSelectCard},
    props: {
        value: {
            required: true,
            type: Boolean
        },
        plan: {
            default: null
        },
        worker: {
            default: null
        },
        cardSelect: {
            default: false
        },
        defaultOrg: {
            default: false
        }
    },
    mixins: [Helpers],
    data() {
        return {
            cardsList: this.defaultOrg ? this.$store.state.defaultCards : this.$store.state.cards,
            dialog: this.value,
            loading: false,
            errorMsg: null,
            card_id: null,
            addCard: false,

            successDialog: false,
            showSuccess: !this.worker
        }
    },
    watch: {
        value() {
            this.dialog = this.value
        },
        dialog() {
            this.addCard = false;
            this.$emit('input', this.dialog);
        },
    },
    computed: {
        upgradePath() {
            return this.worker ? this.$store.state.path + 'upgrade-worker-plan' : this.$store.state.path + 'upgrade-plan';
        },
        upgradeData() {
            return this.worker ? {plan_id: this.plan.id, worker_id: this.worker.id} : {plan_id: this.plan.id};
        },
    },
    methods: {
        updateCard(cards){
            this.cardsList = cards;
        },
        upgradeCallback(){
            if (this.showSuccess){
                this.successDialog = true;
            }
            this.dialog = false;
            this.$emit('upgraded');
        },
        upgrade() {
            this.$validator.validateAll().then(valid => {
                if (valid) {
                    this.errorMsg = null;
                    this.loading = true;
                    let data = this.upgradeData;
                    data.card_id = this.card_id;
                    /*if (this.cardSelect){
                        data.card_id = this.card_id;
                    }*/
                    axios.post(this.upgradePath, data)
                        .then(response => {
                            this.loading = false;

                            if (response.data.redirect){
                                let data = {
                                    url: response.data.redirect,
                                    callbackSucceed: () => {
                                        this.upgradeCallback();
                                    },
                                    callbackFailed: () => {
                                        this.dialog = false;
                                    }
                                }
                                this.$store.commit('showSecureCardDialog', data);
                            }else {
                                this.upgradeCallback();
                            }
                        })
                        .catch(error => {
                            if (error.response.status === 399) {
                                if (error.response.data.value === 'stripe_subscription_create_fail'){
                                    this.errorMsg = error.response.data.message;
                                }
                            } else {
                                this.errorMsg = 'We are unable to upgrade your user account at the moment, please contact support!';
                            }
                            this.loading = false;
                            // this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                        })
                }
            })
        }
    }
}
</script>
