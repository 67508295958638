<template>
    <v-row style="margin:0px !important">
        <v-col cols="12" lg="2" class="modal-onboarding-progress step-4-active">
            <img :src="$store.state.imagePath+'logo-tilted-o-5-big.png'" alt="logo-tilted-o-5-big.png"/>
        </v-col>
        <v-col cols="12" lg="10" class="modal-onboarding-content">
            <div class="modal-header">
                <h2 class="heading-2 bold-text color-black mb-10 mt-10">
                    Invite people and start your
                    <span class="strong-text color-purple">Native Teams</span> journey!
                </h2>
                <p class="normal-text color-dark-grey">
                    Inviting your clients and collaborating is the first step towards your Native engagement.
                </p>
            </div>
            <div class="modal-body">
                <div class="share-step onboarding-modal-step step-4 active">
                    <div class="form-group">
                        <label class="medium-text color-black mb-10">Please enter the email addresses of the people
                            you would like to invite to Native Teams.</label>
                        <div class="flex-form-group">
                            <div class="onboarding-invite-emails mr-2 d-flex tm-input flex-wrap">
                                <v-chip class="ma-1 px-1 ml-0" v-for="item in emails" :key="item" label small color="#ddf3ff">
                                    <span class="font-weight-medium advanced-chip-style" style="font-size: 0.8125rem">{{ item }}</span>
                                    <v-btn @click.prevent.stop="emailClear(item)" x-small icon class="ml-1 ">
                                        <icon class="icon-times" :rem="true" size="0.625"></icon>
                                    </v-btn>
                                </v-chip>
                                <input
                                    name="email"
                                    v-model="greetingObj.values.invite_email"
                                    :placeholder="placeholderText"
                                    class="px-2 py-2"
                                    style="flex: 1"
                                    v-on:keyup.enter="checkEmail"
                                >
                            </div>
                            <div class="welcome-send-invite">
                                <btn :loading="loading" @click="sendInvites()" class="btn btn-send-invite btn-purple small-text bold-text color-white">
                                    Send invite
                                </btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer d-flex justify-space-between pr-0 pl-0" style="margin-right:-10px;">
                <button @click="$emit('back')" class="btn btn-onboarding-back btn-outline-standard medium-text bold-text tm-color-dark-grey">
                    Go back
                </button>
                <div class="d-flex">
                    <button
                            :disabled="!isValid"
                            @click="$emit('done')"
                            class="btn btn-onboarding-skip btn-purple medium-text bold-text color-white">
                        Finish setup
                    </button>
                </div>
            </div>
        </v-col>
    </v-row>
</template>
<script>
import Modal from "~/shared/components/Modal";
import TmAdvancedSelect from "~/shared/components/form/TmAdvancedSelect";
import Btn from "~/shared/components/Btn";
import TmInput from "~/shared/components/form/TmInput";
import Chip from "~/shared/components/Chip";
import ErrorMessage from "~/shared/components/form/ErrorMessage";
export default {
    name: 'InvitePeople',
    components: {ErrorMessage, Chip, TmInput, Btn, TmAdvancedSelect, Modal},
    props: {
        greetingObj: {
            required: true,
            type: Object
        },
    },
    data(){
        return {
            loading: false,
            value: '',
            emails: [],
        }
    },
    computed: {
        isValid() {
            return !Object.keys(this.fields).some(key => this.fields[key].invalid)
        },
        placeholderText: () => 'Add an email and press enter',
    },
    methods: {
        emailClear(key){
            let index = this.emails.indexOf(key);
            this.emails.splice(index, 1)
        },
        validateEmail(value){
            return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
        },
        checkEmail(e) {
            if (!this.validateEmail(this.greetingObj.values.invite_email))
                this.$store.commit("setAlert", {type: 'error', message: 'You entered an invalid email address!'});
            else {
                if(this.emails.indexOf( this.greetingObj.values.invite_email) > -1) {
                    // this.$store.commit("setAlert", {type: 'error', message: 'Email unique'});
                    return;
                }
                this.greetingObj.create(this.$store.state.createPath+'free-invites')
                    .then(response => {
                        this.emails = [...this.emails, this.greetingObj.values.invite_email];
                        this.greetingObj.values.invite_email = '';
                    })
                    .catch(error => {
                        this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                    })
            }
        },
        saveCallback(response){
            this.loading = false;
            this.$store.commit("setAlert", {type: 'info', message: response.data.message});
        },
        sendInvites(){
            if(this.greetingObj.values.invite_email) {
                this.checkEmail();
                return false;
            }
            this.$validator.validateAll().then(valid => {
                if (valid){
                    this.loading = true;
                    this.greetingObj.create(this.$store.state.createPath+'free-invite-people')
                        .then(response => { this.saveCallback(response) })
                        .catch(error => {
                            this.loading = false;
                            this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                        })
                }
            })
        },
    },
}
</script>
