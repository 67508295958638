export const namespaced = true;

export const state = {
    steps: [
        "Start",
        "Legal entity",
        "EOR",
        "Allowances",
        "Business expenses",
        "Reporting",
    ],

    currentStep: 1,

    videoUrl: null,

    showVideos: false,
    eor: false,
    videoUrls: {
        'start_here': {
            id: 'XQLw35NYB0g',
            url: null,
            img: '/assets/tax/onboarding/introduction.svg',
        },
        'uk_legal_entity': {
            id: 'TcEIg-LrFsw',
            url: 'legal-entity-status',
            img: '/assets/tax/onboarding/uk.svg',
        },
        'employer_of_record_(eor)': {
            id: 'e1cRCsTzH84',
            url: 'eor',
            img: '/assets/tax/onboarding/eor.svg',
        },
        'tax_allowances': {
            id: 'm64j1YkE1f8',
            url: 'tax-allowances',
            img: '/assets/tax/onboarding/tax-allowance.svg',
        },
        'marking_expenses': {
            id: 'GCfjnylOmNY',
            url: 'wallet',
            img: '/assets/tax/onboarding/expenses.svg',
        },
        'tax_reporting': {
            id: '5xV3E4H5bNw',
            url: 'tax-reporting',
            img: '/assets/tax/onboarding/tax-reporting.svg',
        },
    },
    modalTitle:'',
};

export const getters = {
    getSteps: (state) => {
        return state.steps;
    },

    getCurrentStep: (state) => {
        return state.currentStep;
    },

    getVideoUrl: (state) => {
        return state.videoUrl;
    },

    getShowVideos: (state) => {
        return state.showVideos;
    },

    getStepperCompleted: (state) => {
        return state.currentStep > state.steps.length;
    },
    getEorInfo(state) {
        return state.eor;
    },
    getVideoUrls(state) {
        return state.videoUrls;
    },
    getModalTitle(state){
        return state.modalTitle;
    }
};

export const mutations = {
    INCREMENT_STEP(state) {
        state.currentStep++;
    },

    SET_VIDEO_URL(state, url) {
        state.videoUrl = url;
    },

    TOGGLE_SHOW_VIDEOS(state) {
        state.showVideos = !state.showVideos;
    },
    SET_EOR(state, payload) {
        state.eor = payload.eor;
    },
    SET_COMPLETED(state) {
        return state.currentStep = state.steps.length + 1
    },
    SET_MODAL_TITLE(state, payload) {
        return state.modalTitle = payload;
    },
    SET_STEP(state,payload){
        return state.currentStep = payload;
    }

};

export const actions = {
    incrementStep({commit}) {
        commit("INCREMENT_STEP");
    },

    setVideoUrl({commit}, url) {
        commit("SET_VIDEO_URL", url);
    },

    toggleShowVideos({commit}) {
        commit("TOGGLE_SHOW_VIDEOS");
    },
    GET_EOR({commit}) {
        axios.get('/tax-onboarding/eor').then(res => {
            commit("SET_EOR", res.data)
        })
    },
    SET_COMPLETED({commit}) {
        return commit("SET_COMPLETED");
    },
    setModalTitle({commit},payload){
        return commit("SET_MODAL_TITLE",payload)
    },
    setStep({commit},payload){
        commit("SET_STEP",payload)
    }
};
