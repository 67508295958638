<!-- @format -->

<template>
    <v-btn
        class="btn"
        :disabled="disabled"
        :loading="loading"
        :ripple="ripple"
        :href="href"
        v-on="$listeners"
    >
        <slot name="default"></slot>
    </v-btn>
</template>

<script>
export default {
    name: 'BaseButton',

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        ripple: {
            type: Boolean,
            default: true,
        },
        href: {
            default: null
        }
    },
};
</script>
