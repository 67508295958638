<template>
  <div class="loadingContainer">
    <div class="ball ball1"></div>
    <div class="ball ball2"></div>
    <div class="ball ball3"></div>
  </div>
</template>

<script>
export default {
  name: "WlBaseLoader",
};
</script>

<style>
.loadingContainer {
  position: relative;
}
.loadingContainer .ball {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #d4d8dc; /* Set the color for the balls */
  float: left;
  margin: 0 3px;
  transform: scale(0);
  animation: grow 4.4s infinite ease-in-out;
}
.loadingContainer .ball1 {
  animation-delay: 0s;
}
.loadingContainer .ball2 {
  animation-delay: 0.3s;
}
.loadingContainer .ball3 {
  animation-delay: 0.6s;
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
</style>
