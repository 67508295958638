<template>
    <div class="setup-profile-step w-100 h-100 mb-0 d-flex flex-column justify-space-between">
        <div>
            <h1 class="tm-transform-none tm-heading-1 font-weight-medium tm-color-black text-center mb-9">
                What’s your current status?
            </h1>
            <p class="tm-normal-text tm-color-dark-grey text-center mb-50">
                Help us make the app better suited to your needs.
            </p>
            <div class="d-flex justify-center h-100 align-center">
                <tm-input-group
                    content-class="mb-5 d-flex align-center"
                    :content-bottom-offset="false"
                    type="radio"
                    :items="types"
                    item-value="id"
                    item-text="name"
                    name="type"
                    v-validate="greetingObj.rules.type_id"
                    v-model="greetingObj.values.type_id"
                    :error-messages="errors.collect('type')"
                    data-vv-as="status"
                >
                    <template v-slot:label="{ item }">
                        <span class="tm-medium-text font-weight-medium tm-color-black">{{ item.name }}</span>
                    </template>
                </tm-input-group>
            </div>
        </div>
        <div class="d-flex justify-space-between align-center welcome-page-footer" >
            <button @click="$emit('back')" class="tm-btn h-auto p-0 tm-medium-text tm-color-dark-grey">
                <i class="icon-long-arrow-left pr-2"></i> Back
            </button>
            <btn :disabled="!isValid || !selectedType" @click="$emit('next', selectedType? selectedType.value: null)" height="2.625rem">
                <span class="font-weight-regular tm-medium-text px-6 py-2 tm-color-white">Next step</span>
            </btn>
        </div>
    </div>
</template>
<script>
import TmInputGroup from "~/shared/components/form/TmInputGroup";
import Btn from "~/shared/components/Btn";
export default {
    name: 'CurrentStatus',
    components: {Btn, TmInputGroup},
    props: {
        types: {
            required: true,
            type: Array
        },
        greetingObj: {
            required: true,
            type: Object
        },
    },
    data(){
        return {}
    },
    computed: {
        isValid() {
            return !Object.keys(this.fields).some(key => this.fields[key].invalid)
        },
        selectedType(){
            return this.greetingObj.values.type_id? this.types.find(item => item.id === this.greetingObj.values.type_id): null
        }
    }
}
</script>
