<template>
    <modal
        v-model="dialog"
        :class-names="classNames"
        :content-class="newDesign ? 'custom-modal' : null"
        :width="width"
        :position="position"
        :close-icon-show="closeIconShow"
        :close-small="true"
        close-color="#8F8F8F"
        :padding="false"
        :close-top="closeTop"
    >
        <div v-if="newDesign" class="modal-container">
            <div class="modal-header">
                <WlBaseButton
                    @click="onClose"
                    class="btn-icon-sm btn-close-modal ml-auto"
                >
                    <WlBaseIcon name="times" class="text-lg" />
                </WlBaseButton>
                <h1 class="heading-1 font-bold color-black">Please confirm your action</h1>
            </div>
            <div class="modal-body">
                <WlBaseInput
                    input-autocomplete="off"
                    label="Please enter your password"
                    type="password"
                    v-model="password"
                    v-validate="'required'"
                    data-vv-name="password"
                    :error-messages="errors.collect('password')"
                    :show-error-messages="false"
                    :append-icon="currentShow ? 'icon-eye' : 'icon-no-eye'"
                    :appendIconColor="null"
                    data-vv-validate-on="change"
                    @appendClick="currentShow = !currentShow"
                />
            </div>
            <div class="modal-footer">
                <WlBaseButton
                    type="primary"
                    class="w-full"
                    @click="onConfirm"
                    :loading="loading"
                >{{ confirmText }}</WlBaseButton>
            </div>
        </div>
        <div v-else class="modal-container custom-modal">
            <div class="modal-header vue-repo-app d-flex align-center justify-between">
                <h2 class="heading-2 font-medium color-black">Please confirm your action</h2>
                <BaseButton class="btn-icon text-lg color-grey-2" @click="onClose">
                    <BaseIcon name="times"/>
                </BaseButton>
            </div>
            <div class="modal-body">
                <p class="tm-paragraph tm-color-black mb-8" style="white-space: pre-line;">
                    <tm-input
                        :bottom-offset="false"
                        input-autocomplete="off"
                        label="Please enter your password"
                        :type="currentShow ? 'text' : 'password'"
                        :append-icon="currentShow ? 'icon-eye' : 'icon-no-eye'"
                        :appendIconColor="null"
                        @appendClick="currentShow = !currentShow"
                        v-model="password"
                        v-validate="'required'"
                        data-vv-name="password"
                        :error-messages="errors.collect('password')"
                        :show-error-messages="false"
                    ></tm-input>
                    <BaseErrorMessage :messages="errors.collect('password')" />
                </p>
                <div class="d-flex justify-center mb-2">
                    <btn @click="onConfirm"
                         :loading="loading"
                         :text="confirmText"
                         :color="archive ? 'white' : deleteButtonColor"
                         :class="[btnSpace ? btnSpace : '', archive ? 'btn-purple' : 'btn-outline-red']"
                         :btn-outlined="true"
                         class="btn w-100">
                    </btn>
                    <btn v-if="showCancelButton"
                         @click="onClose"
                         :small="true"
                         :btn-text="true"
                         :text="cancelText"
                         color="dark-grey">
                    </btn>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from "./Modal";
import TmInput from "./form/TmInput";
import Btn from "./Btn";

export default {
    name: 'Confirmation',
    components: { Btn, Modal, TmInput },
    props: {
        type: {
            default: 'user_password',
        },
        position: {
            default: 'center',//start/end
        },
        classNames: '',
        deleteButtonColor: {
            default: 'red', //orange
        },
        confirmText: {
            default: 'Confirm',
        },
        cancelText: {
            default: 'Cancel',
        },
        showCancelButton: {
            default: false,
        },
        closeIconShow: {
            default: true,
        },
        btnSpace: {
            default: false,
        },
        width: {
            default: '28rem',
        },
        archive: {
            default: false,
        },
        closeTop: {
            default: 19,
        },
        newDesign: {
            default: false,
        },
    },
    data() {
        return {
            password: null,
            currentShow: false,
        };
    },
    computed: {
        ...mapGetters('confirmation', {
            loading: 'loading',
        }),
        dialog: {
            get() {
                return this.$store.state.confirmation.dialog;
            },
            set(value) {
                return value ? this.show() : this.hide();
            },
        },
    },
    watch: {
        dialog() {
            if (this.dialog) {
                switch (this.type) {
                    case 'user_password':
                        break;
                    default:
                        this.verify();
                }
            } else {
                this.clear();
            }
        },
    },
    methods: {
        verify() {
            return this.$store.dispatch('confirmation/verify', { type: this.type });
        },
        confirm() {
            return this.$store.dispatch('confirmation/confirm', { type: this.type, password: this.password });
        },
        show() {
            return this.$store.dispatch('confirmation/show');
        },
        hide() {
            return this.$store.dispatch('confirmation/hide');
        },
        clear() {
            this.password = null;
            this.errors.clear();
            this.$validator.reset();
        },
        onConfirm() {
            this.$validator.validate()
                .then((valid) => {
                    if(valid){
                        this.confirm()
                        .then(() => {
                            this.hide();
                            this.$emit('confirmed');
                        })
                        .catch(error => {
                            this.errors.add({
                                field: 'password',
                                msg: error.response.data.message
                            });
                        });
                    }
                });
        },
        onClose() {
            this.hide();
        },
    },
};
</script>
