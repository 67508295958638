<template>
    <div
        class="radio-group-container"
        :class="hasParagraph ? '' : 'simple-radio-group-container'"
    >
        <label
            class="radio-group-box"
            :for="option.value"
            v-for="option in options"
            :key="option.value"
            :class="{
                selected: option.value === selectedValue,
            }"
        >
            <div class="radio-group-box-content">
                <div
                    class="radio-group-box-header"
                    :class="[hasParagraph ? '' : 'simple-radio-group']"
                >
                    <BaseIcon :name="option.iconName" v-if="option.iconName" />
                    <div class="radio-group-box-body">
                        <p
                            class="leading-130 color-black radio-group-heading-margin"
                            :class="hasParagraph ? 'text-base' : 'text-sm'"
                        >
                            {{ option.heading }}
                        </p>
                        <div class="radio-label">
                            <p class="text-sm leading-130 color-grey-2 mt-1">
                                {{ option.paragraph }}
                            </p>
                            <img
                                class="radio-image"
                                :class="option.image ? 'mt-2' : ''"
                                v-if="option.image"
                                :src="`/images/${option.image}`"
                            />
                        </div>
                    </div>
                    <input
                        type="radio"
                        :name="name"
                        :id="option.value"
                        :value="option.value"
                        :disabled="disabled"
                        @change="handleChange(option.value)"
                        class="input-radio"
                        :checked="option.value === selectedValue"
                    />
                </div>
            </div>
        </label>
    </div>
</template>

<script>
export default {
    name: "BaseRadioGroup",

    props: {
        name: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        value: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            selectedValue: this.value || "",
        };
    },

    methods: {
        handleChange(selectedOptionValue) {
            this.selectedValue = selectedOptionValue;

            this.$emit("input", selectedOptionValue);
        },
    },

    computed: {
        hasParagraph() {
            return this.options.some((option) => option.paragraph);
        },
    },

    watch: {
        value: {
            immediate: true,
            handler(newValue) {
                this.selectedValue = newValue;
            },
        },
    },
};
</script>
