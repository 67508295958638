<template>
    <img
        :src="imageSrc"
        alt="account-manager.png"
        :width="imageSize"
        :height="imageSize"
        :class="classesVal"
    />
</template>

<script>
export default {
    name: 'AccountManagerAvatar',
    props: {
        imageKey: {
            required: true,
        },
        imageSize: {
            default: 60
        },
        classesVal: {
            default: 'rounded-circle account-manager-avatar'
        },
        folderPath: {
            default: 'account-managers/',
        },
    },
    computed: {
        imageSrc(){
            return `/images/${this.folderPath}${this.imageKey}.png`;
        },
    },
}
</script>
