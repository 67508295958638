<template>
    <v-menu
        :attach="true"
        :close-on-content-click="false"
        :nudge-bottom="18"
        :value="$store.state.globalMenuModule.notificationMenuValue"
        @input="$store.commit('globalMenuModule/TOGGLE_NOTIFICATION_MENU', $event)"
        offset-y
        activator=".btn-notifications-menu"
        min-width="0"
        content-class="notifications-menu"
    >
        <div class="notifications-menu-container" id="notification-menu">
            <div
                v-if="isWelcomingContainerShown"
                class="notifications-container welcoming-container mb-3"
            >
<!--                <BaseButton
                    class="btn-icon-sm btn-close-modal btn-close-welcoming-container ml-auto"
                    @click="hideWelcomingContainer"
                >
                    <BaseIcon name="times" class="text-xl"/>
                </BaseButton>-->

                <div class="content-wrapper">
                    <p class="title-text font-bold leading-120">
                        Welcome to your brand new Native Teams experience!
                    </p>
                    <BaseButton class="btn-big btn-primary w-full w-100 mb-3" @click="isModalNewNativeTeamsVisible=true">
                        Check video
                    </BaseButton>
                    <BaseButton class="btn-big btn-secondary w-100 w-full" @click="handleWalletGuideClick">
                        Wallet guide
                    </BaseButton>
                </div>
            </div>
            <div class="notifications-container">
                <div class="d-flex align-center justify-content-between">
                    <p class="title-text">Notifications</p>
                    <BaseButton class="btn-secondary" @click="markAllRead">
                        Mark all as read
                    </BaseButton>
                </div>

                <v-divider></v-divider>

                <div v-if="notifications.length" class="notification-items">
                    <NotificationItem
                        v-for="(notification, index) in notifications"
                        :key="index"
                        :notification="notification"
                        @click.native="onMarkAsRead(notification)"
                    />
                </div>
                <div v-else>
                    <TableEmptyState
                        title="No new notifications"
                        description=""
                    >
                        <template v-slot:icon>
                            <v-img
                                :eager="true"
                                src="/images/empty-states/notifications-empty.png"
                                max-width="182px"
                                height="182px"
                                width="182px"
                            />
                        </template>
                    </TableEmptyState>
                </div>
            </div>
        </div>
        <ModalNewNativeTeams
            :dialog="isModalNewNativeTeamsVisible"
            @close-modal="closeModalNewNativeTeams"
        />
    </v-menu>
</template>
<script>
import NotificationItem from "~/components/NewNavigation/NotificationItem.vue";
import Cookie from '~/shared/mixins/Cookie';
import {mapActions, mapGetters} from "vuex";
import ModalNewNativeTeams from "~/modules/wallet/components/WalletDashboard/Modals/ModalNewNativeTeams.vue";
import TableEmptyState from "~/modules/wallet/components/Tables/TableEmptyState";
import Helpers from "~/shared/mixins/Helpers";

export default {
    name: "NotificationsMenu",

    data() {
        return {
            // isWelcomingContainerShown: this.getCookie('welcome_wallet_section_notification') ? false : true,
            isWelcomingContainerShown: true,
            isModalNewNativeTeamsVisible: false,
        }
    },
    mixins: [Cookie, Helpers],
    props: {
        worker: {
            default: null,
        },
    },

    components: {
        NotificationItem,
        ModalNewNativeTeams,
        TableEmptyState
    },

    computed: {
        ...mapGetters("notifications", {
            notifications: "getNotifications",
            hasUnreadNotifications: "hasUnreadNotifications"
        }),
    },

    methods: {
        ...mapActions("notifications", ["markAllAsRead", "markAsRead", "init", "goToRoute"]),

        onMarkAsRead(notification) {
            if (notification.value === 'verification_required'){
                this.forceVerification();
                return;
            }

            if (!notification.isRead) {
                this.markAsRead(notification.id);
            }
            this.goToRoute(notification);
        },
        forceVerification(){
            this.$store.dispatch('kyc/__checkVerified', {force: true});
        },
        closeModalNewNativeTeams() {
            this.isModalNewNativeTeamsVisible = false;
        },
        hideWelcomingContainer() {
            this.isWelcomingContainerShown = false;
            //this.setCookie('welcome_wallet_section_notification', true)
        },
        handleWalletGuideClick(){
            setTimeout(()=>{
                window.open('https://i.nativeteams.com/assets/new-dashboard/pdf/wallet-guide.pdf','_blank')
            })
        },
        markAllRead(){
            if(!this.hasUnreadNotifications){
                return ;
            }
            return this.markAllAsRead()
        }
    },

    watch: {
        worker: {
            handler: function () {
                if (this.worker) {
                    this.init(this.worker);
                }
            },
            immediate: true,
        },
    }
};
</script>

<style lang="scss" scoped>
.notifications-menu {
    background-color: unset !important;
    left: unset !important;
    right: 0 !important;
    max-width: 35.75rem;
    width: 100%;
    box-shadow: unset !important;
    overflow: unset !important;
    contain: unset !important;

    .welcoming-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .title-text {
            font-size: 20px;
            margin-bottom: 20px;
        }

        .content-wrapper {
            max-width: 288px;
        }
    }

    .notifications-menu-container {
        margin-bottom: 1rem;
        border-radius: 30px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
        display: flex;
        flex-direction: column;
        padding: 1.875rem;
        background: white;

        @media (max-width: 991px) {
            padding: 1.5rem;
        }
    }

    .notifications-container {
        width: 100%;
        padding: 1.5rem;
        border-radius: 24px;
        background: #f2f5fa;
    }

    .title-text {
        font-size: 1.125rem;
        font-weight: 700;
        margin-right: 0.75rem;
    }

    .v-divider {
        border-color: #d6d9dd !important;
        margin: 1.5rem 0;
    }

    .notification-items {
        max-height: 26.75rem;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

@media (max-width: 992px) {
    .btn-close-welcoming-container {
        margin-bottom: 8px;
    }
}
</style>

<!--Custom styles to work on new dashboard -->
<style lang="scss">
#notification-menu{
    .btn.btn-purple{
        background-color: var(--main-purple-color) !important;
    }

    .v-btn.btn-close-modal {
        color: #000 !important;
    }

    .welcoming-container {
        .title-text {
            font-size: 20px !important;
            margin-bottom: 20px !important;
        }
    }

    .btn-close-modal {
        border: none !important;
    }

    .btn-icon-sm.btn-close-modal,
    .v-btn.btn-icon-sm.btn-close-modal,
    .v-btn:not(.v-btn--round).v-size--default.btn-icon-sm.btn-close-modal {
        width: 32px !important;
        height: 32px !important;
        padding: 0 !important;
        min-width: 32px !important;
        max-width: 32px !important;
    }

    .btn-icon-sm.btn-close-modal i,
    .v-btn.btn-icon-sm.btn-close-modal i,
    .v-btn:not(.v-btn--round).v-size--default.btn-icon-sm.btn-close-modal i {
        font-size: 24px !important;
    }
}
</style>
