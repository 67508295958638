export const namespaced = true;

export const state = {
    cards: [],
};

export const getters = {
    cards: state => state.cards,
};

export const mutations = {
    SET_CARDS(state, payload) {
        payload.forEach(i => {
            const index = state.cards.findIndex(j => j.id === i.id);
            if (-1 !== index) {
                state.cards.splice(index, 1, i);
            } else {
                state.cards.push(i);
            }
        });
    },
};

export const actions = {
    async cardRegisterIntent({ commit }) {
        return axios
            .post('/v2/card-register-intent')
            .then(response => {
                return response.data.data;
            }).catch((error) => {
                commit("setAlert", { type: 'error', message: error?.response?.data?.message ?? error.message }, { root: true });
                throw error;
            });
    },

    async getCards({ commit }) {
        return axios
            .get('/v2/cards')
            .then(response => {
                commit('SET_CARDS', response.data.data);
                return response.data.data;
            }).catch((error) => {
                commit("setAlert", { type: 'error', message: error?.response?.data?.message ?? error.message }, { root: true });
                throw error;
            });
    },

    async getCardById({ commit }, id) {
        return axios
            .get(`/v2/cards/${id}`)
            .then(response => {
                return response.data.data;
            }).catch((error) => {
                commit("setAlert", { type: 'error', message: error?.response?.data?.message ?? error.message }, { root: true });
                throw error;
            });
    },

    async updateCard({ commit }, { id, registerIntentId }) {
        return axios
            .put(`/v2/cards/${id}`, {
                register_intent_id: registerIntentId,
            })
            .then(response => {
                return response.data.data;
            }).catch((error) => {
                commit("setAlert", { type: 'error', message: error?.response?.data?.message ?? error.message }, { root: true });
                throw error;
            });
    },

    async storeCard({ commit }, registerIntentId) {
        return axios
            .post('/v2/cards', {
                register_intent_id: registerIntentId,
            })
            .then(response => {
                return response.data.data;
            }).catch((error) => {
                commit("setAlert", { type: 'error', message: error?.response?.data?.message ?? error.message }, { root: true });
                throw error;
            });
    },

    async saveCard({ dispatch }, { id, registerIntentId }) {
        if (id) {
            return dispatch('updateCard', { id, registerIntentId });
        } else {
            return dispatch('storeCard', registerIntentId);
        }
    },

    async deleteCard({ commit }, id) {
        return axios
            .delete(`/v2/cards/${id}`)
            .then(response => {
                return response.data.data;
            }).catch((error) => {
                commit("setAlert", { type: 'error', message: error?.response?.data?.message ?? error.message }, { root: true });
                throw error;
            });
    },

    async createInvoicePaymentIntent({ commit }, { invoiceId }) {
        return axios
            .post(`/v2/create-invoice-payment-intent`, { invoice_id: invoiceId })
            .then(response => {
                return response.data.data;
            }).catch((error) => {
                commit("setAlert", { type: 'error', message: error?.response?.data?.message ?? error.message }, { root: true });
                throw error;
            });
    },

    async createInvoicePayment({ commit }, { paymentIntentId, invoiceId }) {
        return axios
            .post(`/v2/invoice-payment`, {
                payment_intent_id: paymentIntentId,
                invoice_id: invoiceId,
            })
            .then(response => {
                return response.data.data;
            })
            .catch((error) => {
                if (error.response && 500 === error.response.status) {
                    const message = "We are not able to process your request, please try again later or contact support.";
                    commit("setAlert", { type: 'error', message: message }, { root: true });
                    throw new Error(message);
                } else {
                    commit("setAlert", { type: 'error', message: error?.response?.data?.message ?? error.message }, { root: true });
                    throw error;
                }
            });
    },

    async createPaymentIntentRequest({ commit }, { salaryId }) {
        return axios
            .post(`/v2/payment-intent-request`, {
                salary_id: salaryId,
            })
            .then(response => {
                return response.data.data;
            })
            .catch((error) => {
                commit("setAlert", { type: 'error', message: error?.response?.data?.message ?? error.message }, { root: true });
                throw error;
            });
    },

    async createPaymentIntentProcess({ commit }, { paymentIntentId, salaryId }) {
        return axios
            .post(`/v2/payment-intent-process`, {
                payment_intent_id: paymentIntentId,
                salary_id: salaryId,
            })
            .then(response => {
                return response.data.data;
            })
            .catch((error) => {
                if (error.response && 500 === error.response.status) {
                    const message = "We are not able to process your request, please try again later or contact support.";
                    commit("setAlert", { type: 'error', message: message }, { root: true });
                    throw new Error(message);
                } else {
                    commit("setAlert", { type: 'error', message: error?.response?.data?.message ?? error.message }, { root: true });
                    throw error;
                }
            });

    },

    async createPay({ commit, dispatch }, {
        provider,
        amount,
        currency,
        paymentType,
        card_id,
        wallet_id,
        reason,
        user_note,
        callbackSucceed,
        callbackFailed,
    }) {

        const shopperResultUrl = window.location.origin + '/accept-sca-payment/stripe';
        amount = parseFloat(amount)
        return axios
            .post(`/v2/process-payment`, {
                provider,
                amount,
                currency,
                paymentType,
                card_id,
                wallet_id,
                reason,
                user_note,
                shopperResultUrl
            })
            .then(response => {
                const redirect = response.data?.data?.redirect;
                redirect ?
                    commit('showSecureCardDialog', {url: redirect, callbackSucceed, callbackFailed}, {root: true}):
                    ('paid' === response.data?.data?.status || ('pending' === response.data?.data?.status && 'bank' === provider) ? callbackSucceed() : callbackFailed());
            })
            .catch((error) => {
                if (error.response && 500 === error.response.status) {
                    const message = "We are not able to process your request, please try again later or contact support.";
                    commit("setAlert", { type: 'error', message: message }, { root: true });
                    throw new Error(message);
                } else {
                    commit("setAlert", { type: 'error', message: error?.response?.data?.message ?? error.message }, { root: true });
                    throw error;
                }
            });
    },

    async checkPayment({ commit, dispatch }, {id}) {
        return axios
            .post(`/v2/process-payment-secure`, {secure_intent_id: id})
            .then(response => response.data.data)
            .catch((error) => {
                if (error.response && 500 === error.response.status) {
                    const message = "We are not able to process your request, please try again later or contact support.";
                    commit("setAlert", { type: 'error', message: message }, { root: true });
                    throw new Error(message);
                } else {
                    commit("setAlert", { type: 'error', message: error?.response?.data?.message ?? error.message }, { root: true });
                    throw error;
                }
            });
    },

    async createPayInvoice({ commit }, {
        provider,
        paymentType,
        invoice_id,
        card_id,
        callbackSucceed,
        callbackFailed,
    }) {
        const shopperResultUrl = window.location.origin + '/accept-sca-payment/stripe';
        return axios
            .post(`/v2/process-payment`, {
                provider,
                paymentType,
                invoice_id,
                card_id,
                shopperResultUrl,
            })
            .then(response => {
                const redirect = response.data?.data?.redirect;
                redirect ?
                    commit('showSecureCardDialog', {url: redirect, callbackSucceed, callbackFailed}, {root: true}):
                    ('paid' === response.data?.data?.status ? callbackSucceed() : callbackFailed());
            })
            .catch((error) => {
                if (error.response && 500 === error.response.status) {
                    const message = "We are not able to process your request, please try again later or contact support.";
                    commit("setAlert", { type: 'error', message: message }, { root: true });
                    throw new Error(message);
                } else {
                    commit("setAlert", { type: 'error', message: error?.response?.data?.message ?? error.message }, { root: true });
                    throw error;
                }
            });
    },

    async createPaymentRequestTopUp({ commit }, {
        paymentType,
        wallet_id,
        card_id,
        obj_id,
        callbackSucceed,
        callbackFailed,
    }) {
        const shopperResultUrl = window.location.origin + '/accept-sca-payment/stripe';
        return axios
            .post(`/v2/payment-request/top-up`, {
                paymentType,
                wallet_id,
                card_id,
                obj_id,
                shopperResultUrl,
            })
            .then(response => {
                const redirect = response.data?.data?.redirect;
                redirect ?
                    commit('showSecureCardDialog', {url: redirect, callbackSucceed, callbackFailed}, {root: true}):
                    ('paid' === response.data?.data?.status ? callbackSucceed() : callbackFailed());
            })
            .catch((error) => {
                if (error.response && 500 === error.response.status) {
                    const message = "We are not able to process your request, please try again later or contact support.";
                    commit("setAlert", { type: 'error', message: message }, { root: true });
                    throw new Error(message);
                } else {
                    commit("setAlert", { type: 'error', message: error?.response?.data?.message ?? error.message }, { root: true });
                    throw error;
                }
            });
    },
};
