const state = {
    preselectedContact: null,
    displayIntroScreen: null,
};

const getters = {
    preselectedContact: (state) => state.preselectedContact,
    displayIntroScreen: (state) => state.displayIntroScreen,
}

const mutations = {
    SET_PRESELECTED_CONTACT(state, payload) {
        state.preselectedContact = payload;
    },
    SET_DISPLAY_INTRO_SCREEN(state, payload) {
        state.displayIntroScreen = payload;
    },
}

const actions = {
    setPreselectedContact({ commit }, contact) {
        commit('SET_PRESELECTED_CONTACT', contact);
    },
    setDisplayIntroScreen({ commit }, displayIntroScreen) {
        commit('SET_DISPLAY_INTRO_SCREEN', displayIntroScreen);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
