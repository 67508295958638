import initBroadcast from "~/listeners/actions/initBroadcast";
import initNotifications from "~/listeners/actions/initNotifications";
import setWLStyles from "~/listeners/actions/setWLStyles";
import initState from "@/listeners/actions/initState";

export default class Core {
    #listeners = {
        userAuthorized: [
            initState
        ],
        userUnauthorized: [
            initState
        ],
        stateInitialized: [
            initBroadcast,
            initNotifications,
            setWLStyles
        ]
    };

    run = async (e) => {
        for (const l of this.#listeners[e.detail.type]) {
            await new l().resolve(e.detail.data)
        }
    }
}
