<template>
    <div class="request-payment">
        <div class="payment-option-details-request-payment">
            <div class="mb-30">
                <h5 class="text-center heading-1 bold-text">Request Payment</h5>
                <p class="text-center medium-text color-dark-grey-6 lh-120">
                    Select from recent contacts or invite by email
                </p>
            </div>
            <div class="flex-form-group mb-30 justify-content-center">
                <tm-input-group
                    type="radio"
                    :horizontal="true"
                    :bottom-offset="false"
                    item-text="name"
                    item-value="value"
                    :items="types"
                    v-model="obj.values.payment_request.my_recipient"
                    @input="obj.values.payer = null"
                ></tm-input-group>
            </div>
            <div class="bg-white border-grey rounded-5 pa-30 mb-30">
                <div class="modal-form">

                    <div v-show="myRecipient">
                        <tm-select-contact
                            label="Select payer"
                            contact-type="salary_request"
                            name="contact_id"
                            :new-contact-modal="true"
                            v-model="obj.values.payment_request.contact_id"
                            v-validate="myRecipient? obj.rules.payment_request.contact_id: ''"
                            @inputObject="obj.values.payer = $event"
                            :error-messages="errors.collect('contact_id')"
                            data-vv-as="Payer"
                            :view-selected-avatar="true"
                        ></tm-select-contact>
                    </div>

                    <div v-show="!myRecipient">
                        <tm-input
                            label="Payer's name"
                            placeholder="e.g. Milan Tomic"
                            v-model="obj.values.payment_request.name"
                            v-validate="!myRecipient? obj.rules.payment_request.name: ''"
                            name="name"
                            data-vv-as="Name"
                            :error-messages="errors.collect('name')"
                        >
                        </tm-input>
                        <tm-input
                            label="Payer's email"
                            placeholder="e.g. milan@nativeteams.com"
                            v-model="obj.values.payment_request.email"
                            v-validate="!myRecipient? obj.rules.payment_request.email: ''"
                            name="email"
                            data-vv-as="Email"
                            :error-messages="errors.collect('email')"
                        >
                        </tm-input>
                    </div>

                    <tm-text-area
                        label="Description"
                        placeholder="Describe your work"
                        v-model="obj.values.payment_request.description"
                        v-validate="obj.rules.payment_request.description"
                        name="description"
                        data-vv-as="Description"
                        :error-messages="errors.collect('description')"
                        :bottom-offset="false"
                    >
                    </tm-text-area>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TmTextArea from "~/shared/components/form/TmTextArea";
import TmSelectContact from "~/shared/components/form/TmSelectContact";
import TmInput from "~/shared/components/form/TmInput";
import TmInputGroup from "~/shared/components/form/TmInputGroup";
export default {
    name: 'AddByPaymentRequest',
    components: {TmInputGroup, TmInput, TmSelectContact, TmTextArea},
    props: {
        obj: {
            required: true,
            type: Object
        }
    },
    data(){
        return {
            types: [
                {name: 'My recipient', value: 1},
                {name: 'Invite by email', value: 0},
            ]
        }
    },
    computed: {
        myRecipient(){
            return this.obj.values.payment_request.my_recipient
        }
    }
}
</script>
<style lang="scss">
.edit-contact{
    .fs-10{
        font-size: 12px!important;
    }
}
.remove-contact-invoice{
    .fs-10{
        font-size: 15px!important;
    }
}
</style>
