import toBoolean from "to-boolean";
import {datadogRum} from "@datadog/browser-rum";
import {__config} from "@/utils/config";

export default class datadogConfig{
    resolve() {
        if (__config('VUE_APP_DATADOG_RUM_ENABLED')) {
            let mappedConfig = {
                applicationId: __config('VUE_APP_DATADOG_RUM_APP_ID'),
                clientToken: __config('VUE_APP_DATADOG_RUM_CLIENT_TOKEN'),
                site: __config('VUE_APP_DATADOG_RUM_SITE'),
                service: __config('VUE_APP_DATADOG_RUM_SERVICE'),
                env: __config('VUE_APP_DATADOG_RUM_ENV'),
                version: __config('VUE_APP_DATADOG_RUM_VERSION'),
                sessionSampleRate: __config('VUE_APP_DATADOG_RUM_SESSION_SAMPLE_RATE'),
                sessionReplaySampleRate: __config('VUE_APP_DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE'),
                trackUserInteractions: __config('VUE_APP_DATADOG_RUM_TRACK_USER_INTERACTIONS'),
                trackResources: __config('VUE_APP_DATADOG_RUM_TRACK_RESOURCES'),
                trackLongTasks: __config('VUE_APP_DATADOG_RUM_TRACK_LONG_TASKS'),
                defaultPrivacyLevel: __config('VUE_APP_DATADOG_RUM_DEFAULT_PRIVACY_LEVEL')
            };
            datadogRum.init(mappedConfig);
            datadogRum.startSessionReplayRecording();
        }
    }
}
