import Model from '~/shared/Model';

export default class AddMoney extends Model {

    constructor(obj = {}) {
        super();

        this.hidden = ['payer'];

        this.values = {
            wallet_type: obj.wallet_type || '',

            wallet_id: obj.wallet_id || '',
            amount: obj.amount || '',
            option: obj.option || 'bank',
            payer: obj.payer || null,
            reason: obj.reason || null,
            note: obj.note || null,

            card: {
                card_id: null,
            },

            bank: {
                inside_usa: 1,
            },

            paypal: {
                name: null,
                note: null
            },

            payoneer: {
                balance_id: null,
                token_model_id: null,
                currency: null,

                charge_fee: 0,
                target_amount: 0,
                commit_id: null,
            },

            payment_request: {
                my_recipient: 1,
                contact_id: null,
                name: null,
                email: null,
                description: null
            },
        }

        this.rules = {
            wallet_id: 'required',
            amount: 'required|max_value:1000000',
            option: 'required',
            reason: 'required',
            note: 'required',

            card: {
                card_id: 'required',
            },

            bank: {
                inside_usa: '',
                reference: ''
            },

            paypal: {
                name: 'required',
                note: 'required'
            },

            payoneer: {
                balance_id: 'required',
            },

            payment_request: {
                my_recipient: '',
                contact_id: 'required',
                name: 'required',
                email: 'required|email',
                description: 'required'
            },
        };
    }

    setPayoneerCurrency(currency){
        this.values.payoneer.currency = currency ?? null;
    }

    setPayoneerAccountModelId(id){
        this.values.payoneer.token_model_id = id ?? null;
    }


}
