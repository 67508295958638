/** @format */

export const namespaced = true;


function setCookie(name,value,days) {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = `; expires=${  date.toUTCString()}`;
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
}
export const state = {
    step: 'tax_management',
    mainVideo: {
        preview: '/images/tax-management/tax-position-preview.svg',
        src: 'https://www.youtube.com/embed/858v57X19p8',
    },

    features: {
        eor: {
            key: 'eor',
            icon: 'team-members',
            title: 'Employer of record (EOR)',
            description: 'Get your employment status through our EOR wizard.',
            btn_text: "Let's set it up",
            see_more: '/eor',
            on_boarding_title: 'EOR',
            on_boarding_image: 'Frame 427320702.png',
            on_boarding_video: 'https://www.youtube.com/embed/e1cRCsTzH84',
        },
        vat: {
            key: 'vat',
            icon: 'nav-documents',
            title: 'VAT claiming',
            description: 'Get the most of your expenses with VAT claiming.',
            btn_text: 'Coming soon',
            disabled: true,
            see_more: '/expenses',
            on_boarding_title: 'VAT claiming',
            on_boarding_image: 'Frame 427320707.png',
            on_boarding_video: 'https://www.youtube.com/embed/PQOT6xMFc9I',
        },
        taxReporting: {
            key: 'taxReporting',
            icon: 'clipboard-document-list',
            title: 'Tax reporting and accounting',
            description: 'Make reports of your wage and non-wage payments.',
            description_success: 'You can see your full report here.',
            btn_text: 'Coming soon',
            disabled: true,
            see_more: '',
            on_boarding_title: 'Tax reporting',
            on_boarding_image: 'Frame 427320705.png',
            on_boarding_video: 'https://www.youtube.com/embed/5xV3E4H5bNw',
        },
        taxEfficient: {
            key: 'taxEfficient',
            icon: 'nav-home',
            title: 'Tax efficient benefits',
            description: 'Apply for tax benefits you’re eligible for.',
            btn_text: 'Coming soon',
            disabled: true,
            see_more: '',
            // on_boarding_title: 'Tax benefits',
            // on_boarding_image: 'tax-position-preview.svg',
            // on_boarding_video: 'https://www.youtube.com/embed/qkwr222lRvM',
        },
        taxAllowances: {
            key: 'taxAllowances',
            icon: 'calculator-2',
            title: 'Tax allowances',
            description: 'Apply for tax allowances you’re eligible for.',
            btn_text: "Let's apply",
            see_more: '/tax-allowances',
            on_boarding_title: 'Tax allowances',
            on_boarding_image: 'Frame 427320704.png',
            on_boarding_video: 'https://www.youtube.com/embed/m64j1YkE1f8',
        },
        legalEntity: {
            key: 'legalEntity',
            icon: 'building-library',
            title: 'UK legal entity',
            description: 'Set up your limited company with Native Teams.',
            btn_text: "Let's set it up",
            see_more: '/legal-entity-status',
            on_boarding_title: 'UK legal entity',
            on_boarding_image: 'Frame 427320706.png',
            on_boarding_video: 'https://www.youtube.com/embed/TcEIg-LrFsw',
        },
        markingExpense: {
            key: 'markingExpense',
            icon: 'nav-expenses',
            title: 'Marking expenses',
            description: 'This feature is not mandatory to reach chosen tax position.',
            btn_text: "Let's mark",
            see_more: '/wallet?statement=true',
            on_boarding_title: 'Marking expenses',
            on_boarding_image: 'Frame 427320703.png',
            on_boarding_video: 'https://www.youtube.com/embed/GCfjnylOmNY',
        },
    },

    taxPositions: [
        {
            key: 'legal_entity',
            icon: 'coins-1',
            name: 'Min-tax',
            description:
                'You pay the least amount in taxes and contributions by having all of your income go through the UK legal entity.',
            features: [
                {
                    key: 'vat',
                    name: 'VAT claiming',
                    optional: false,
                },
                {
                    key: 'taxReporting',
                    name: 'Tax reporting and accounting',
                    optional: false,
                },
                {
                    key: 'legalEntity',
                    name: 'Legal entity',
                    optional: false,
                },
                {
                    key: 'markingExpense',
                    name: 'Marking business vs personal expenses',
                    optional: false,
                },
                {
                    key: 'taxEfficient',
                    name: 'Tax efficient benefits (optional)*',
                    optional: true,
                },
            ],
        },
        {
            key: 'employed_status',
            icon: 'coins-2',
            name: 'Med-tax',
            description:
                'Have a portion of your income go through the payroll and the rest through your individual or legal entity wallet.',
            features: [
                {
                    key: 'eor',
                    name: 'EOR',
                    optional: false,
                },
                {
                    key: 'vat',
                    name: 'VAT claiming',
                    optional: false,
                },
                {
                    key: 'taxReporting',
                    name: 'Tax reporting and accounting',
                    optional: false,
                },
                {
                    key: 'taxAllowances',
                    name: 'Tax allowances',
                    optional: true,
                },
                {
                    key: 'legalEntity',
                    name: 'Legal entity',
                    optional: true,
                },
                {
                    key: 'markingExpense',
                    name: 'Marking business vs personal expenses',
                    optional: true,
                },
                {
                    key: 'taxEfficient',
                    name: 'Tax efficient benefits',
                    optional: true,
                },
            ],
        },
        {
            key: 'max_tax',
            icon: 'coins-3',
            name: 'Max-tax',
            description:
                'Your whole monthly income will be going through the payroll and you will pay the most in taxes and contributions.',
            features: [
                {
                    key: 'eor',
                    name: 'EOR',
                    optional: false,
                },
                {
                    key: 'vat',
                    name: 'VAT claiming',
                    optional: false,
                },
                {
                    key: 'taxAllowances',
                    name: 'Tax allowances',
                    optional: true,
                },
                {
                    key: 'legalEntity',
                    name: 'Legal entity',
                    optional: true,
                },
                {
                    key: 'taxEfficient',
                    name: 'Tax efficient benefits',
                    optional: false,
                },
            ],
        },
    ],
    selectedPosition: 'Max-tax',
    isChangePosition: false,
    tax_countries: [],
    countries: [],
};

export const getters = {
    getTaxPositions: state => state.taxPositions,

    features: state => state.features,

    getSelectedPosition: state => state.selectedPosition,

    isChangePosition: state => state.isChangePosition,

    countries: state => state.countries,
    taxCountries: state => state.tax_countries,

    mainVideo: state => state.mainVideo,
    step: state => state.step,
};

export const mutations = {
    SET_DATA(state, params) {
        state[params.key] = params.value;
    },
    setPosition(state, position) {
        setCookie('tax_management_position', position);
        state.selectedPosition = position;
    },
    changeFeatures(state, data) {
        const items = state.features;
        Object.keys(data).forEach(key => {
            const item = data[key];
            if(items[key]) {
                items[key].connected = item ? 1 : 0;
                items[key].data = item;
                if ('legalEntity' === key && 'applied' === item) {
                    items[key].connected = 0;
                    items[key].status = item;
                }
                if ('eor' === key && item && 'accepted'  !== item.status) {
                    items[key].connected = 0;
                    items[key].status = 'applied';
                }
            }
            if('taxAllowances_enabled' === key) {
                items['taxAllowances'].btn_text = item? 'Let\'s apply' : 'Coming soon';
                items['taxAllowances'].disabled = !item;
            }
        });
        state.features = {...items};
    },
    setIsChangePosition(state, position) {
        state.isChangePosition = position;
    },
};

export const actions = {
    setPosition({ commit }, position) {
        commit('setPosition', position);
    },
    getCountries({ commit, dispatch }) {
        axios
            .get(`/get/search-countries`, { params: { limit: 200 } })
            .then(response => {
                commit('SET_DATA', { key: 'countries', value: Object.values(response.data) });
                dispatch('getTaxCountries');
            })
            .catch(error => {
                this.$store.commit('setAlert', { type: 'error', message: error.response.data.message }, { root: true });
            });
    },
    getTaxCountries({ commit }) {
        axios
            .post(`/tax-optimiser/countries`)
            .then(response => {
                commit('SET_DATA', { key: 'tax_countries', value: Object.values(response.data) });
            })
            .catch(error => {
                this.$store.commit('setAlert', { type: 'error', message: error.response.data.message }, { root: true });
            });
    },
    confirmAction({commit}) {
        axios.post('/tax-management/check-steps').then(r => {
            commit('changeFeatures', r.data);
        });
    },
    changeStep({ commit }, value) {
        setCookie('tax_management_step', value, 2);
        commit('SET_DATA', { key: 'step', value });
    },

    setIsChangePosition({ commit }, position) {
        commit('setIsChangePosition', position);
    },
};
