<template>
    <div class="custom-alert" :class="`alert-${type}`">
        <BaseIcon
            name="check-thin-circle-outline"
            class="text-base text-green-500"
            v-if="type === 'success'"
        />

        <BaseIcon
            name="info-circle-outline"
            class="text-base text-blue-600"
            v-if="type === 'info'"
        />

        <BaseIcon
            name="icon-minus-circle-outline"
            class="text-base text-danger"
            v-if="type === 'danger'"
        />

        <BaseIcon
            name="warning"
            class="mr-2 text-base text-warning"
            v-if="type === 'warning'"
        />

        <p class="text-sm leading-130">{{ text }}</p>

        <div
            class="base-alert-text"
            :class="'alert-text-' + type"
            v-if="$slots['default']"
        >
            <slot></slot>
        </div>
        <slot name="cta"></slot>
    </div>
</template>

<script>
export default {
    name: "WlBaseAlert",

    props: {
        type: {
            type: String,
            required: false,
            default: "info",
        },

        text: {
            type: String,
            required: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-alert {
    border-radius: 20px;
    display: flex;
    align-items: flex-start;
    padding: 24px 16px;
    gap: 8px;

    .base-alert-text {
        line-height: 22.5px !important;

        p {
            line-height: 22.5px !important;
        }

        &.alert-text-success {
            color: #131414;
        }

        &.alert-text-danger {
            color: #131414;
        }

        &.alert-text-info {
            color: #2f2f9c;
        }
    }

    &.alert-success {
        background-color: #e9f2ea;

        p {
            color: #131414;
        }
    }

    &.alert-danger {
        background: #ffebe9;

        p {
            color: #131414;
        }
    }

    &.alert-info {
        background: #d7e0ff;

        p {
            color: #2f2f9c;
        }
    }

    &.alert-warning {
        background: #fefaf5;

        p {
            color: #f29d39;
        }
    }

    i {
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
