<template>
    <div class="created-organization-container mb-0 p-0">
        <div class="container-fluid h-100vh">
            <div class="row justify-content-center custom-scrollbar">
                <!-- HEADER -->

                <div
                    class="col-12 add-send-money-header bg-transparent shadow-0 justify-end"
                    style="background: transparent; height: auto; padding-top: 1.5rem"
                >
                    <button v-if="displayCloseButton" @click="close()" class="btn btn-close btn-big btn-outline-purple color-purple normal-text bold-text bg-transparent w-90 mt-3 mr-3">
                        Close
                    </button>
                </div>

                <!-- CONTENT -->
                <div class="row justify-content-center">
                    <div class="col-11 col-sm-7 col-md-auto create-organization-container bg-white shadow onboarding-step-container organisation-details-step step-1 active vertical-center-div">
                        <div class="d-flex flex-column align-items-center">
                            <div class="text-center">
                                <i class="icon-check-circle color-green fs-78"></i>
                                <h2 class="heading-1 bold-text color-black text-center mt-5 pt-4 mb-3">
                                    Organisation created
                                </h2>
                                <div class="mb-5" style="color: #838A90">
                                    You have successfully created a new <br>
                                    organisation <span v-if="obj.values.organization.name">called {{ obj.values.organization.name }} </span> 
                                </div>
                            </div>
                            <div class="row w-100">
                                <div class="col-12 px-0">
                                    <btn
                                        @click="handleButtonClick"
                                        class="btn btn-big btn-next-step custom-btn-primary normal-text elevation-0 go-to-organization-button w-100"
                                        style="text-transform: unset"
                                    >
                                        <span class="normal-text color-white bold-text">Go to Organisation</span>
                                    </btn>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import Btn from '~/shared/components/Btn.vue';

export default {
    name: 'EmployerFinishStep',
    components: {
        Btn
    },
    data() {
        return {
            displayCloseButton: false,
        }
    },
    props: {
        obj: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        close(){
            this.$store.commit('hideOrgDialog');
            this.$emit('resetFlow');
        },
        handleButtonClick() {
            if (!this.disabledButtonClick) window.location.href = this.$store.state.path;
            this.disabledButtonClick = true;
        }
    },
}
</script>

<style scoped>
.create-organization-container {
    @media (min-width: 768px) {
        width: 400px;
    }

    padding: 3rem 2rem;
    border-radius: 8px;

    .header-1 {
        margin-top: 0.75rem;
    }

    .go-to-organization-button {
        padding: 1.5rem 0 !important;
    }
}
</style>
