<template>
    <modal v-model="dialog" width="34.688rem"
            :padding="false" :close-icon-show="false">
        <template slot="content">
            <div class="pt-30 pb-30">
                <div class="modal-header d-flex justify-between align-center mb-35">
                    <h2 class="heading-2 bold-text color-black">
                        Termination of Employment Contract
                    </h2>
                    <button type="button" class="btn btn-icon btn-p-0 color-dark-grey small-text ml-0"
                            data-dismiss="modal" aria-label="Close">
                        <i class="icon-times" id="closeModal" @click="dialog = false"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <p class="medium-text color-dark-grey mb-10">Please let us know if you have signed the contract.</p>
                    <tm-input-group
                        :bottom-offset="false"
                        :bold-label="true"
                        class="mb-10"
                        :horizontal="true"
                        type="radio"
                        v-model="signVal"
                        :items="[{value: 1, name: 'I`ve signed the Termination Contract'}, {value: 0, name: 'Sign Later'}]"
                        item-value="value"
                        item-text="name"
                    >
                    </tm-input-group>
                    <div class="d-flex align-center mb-1-rem w-100 justify-center mt-2-rem">
                        <button class="btn btn-purple small-text w-130" @click="getData">Save</button>
<!--                        <a href="#" class="small-text color-dark-grey bold-text underlined ml-3" @click="dialog = false">Sign Later</a>-->
                    </div>
                    <p class="small-text-3 color-dark-grey">Your Employee account will remain open for 2 days and
                        then you will go onto a Free account.</p>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import Modal from "~/shared/components/Modal";
import axios from "axios";
import TmCheckbox from "~/shared/components/form/TmCheckbox";
import TmInputGroup from "~/shared/components/form/TmInputGroup";

export default {
    name: "SingTermination",
    components: {
        TmInputGroup,
        TmCheckbox,
        Modal
    },
    data() {
        return {
            dialog: this.$store.state.signedModal,
            signVal: 1
        }
    },
    watch: {},
    methods: {
        getData() {
            if(!this.signVal) {
                this.dialog = false;
                return false;
            }
            axios.delete(this.$store.state.path + 'contacts/sign')
                .then(response => {
                   this.dialog = false;
                    this.$store.commit("setAlert", {type: 'info', message: response.data.message});
                })
                .catch(error => {
                    this.$store.commit("setAlert", {type: 'error', message: error.response.data.message});
                })
        },
    }
}
</script>

<style scoped>

</style>
