<template>
    <div>
        <BaseModal
            v-model="dialog"
            width="32.5rem"
            class-names="custom-modal security-modal"
            attach=".vue-repo-app"
            container-class="pa-0"
            @close="close"
        >
            <template v-slot:header>
                <h2 class="heading-2 font-medium color-black">
                    Two-factor authentication (2FA)
                </h2>
                <button
                    type="button"
                    class="btn btn-icon color-black wallet-btn"
                    aria-label="Close"
                    @click="close"
                >
                    <i class="icon-times fs-20"></i>
                </button>
            </template>
            <template v-slot:body>
                <div v-if="activeForm === 'enable2fa'"
                    id="twoFactorCode"
                    class="mb-6"
                >
                    <p class="text-base leading-130 color-grey-2 mb-6">
                        Two-factor authentication (2FA) strengthens access security by
                        requiring two methods (also referred to as factors) to verify your
                        identity. Two-factor authentication protects against phishing,
                        social engineering and password brute force attacks and secures your
                        logins from attackers exploiting weak or stolen credentials.
                    </p>
                    <div>
                        <div v-for="(item, index) in types"
                             :key="index"
                             class="d-flex"
                             :class="{ 'mb-20': index === 0 }"
                        >
                            <label class="radio-container radio-group-box">
                                <div class="ml-3-rem">
                                    <h2 class="two-step-title leading-130 fs-15">
                                        {{ item.title }}
                                    </h2>
                                    <p class="two-step-info leading-130 fs-15">
                                        {{ item.description }}
                                    </p>
                                </div>
                                <tm-radio :item-value="item.value" v-model="type"></tm-radio>
                            </label>
                        </div>
                    </div>
                </div>
                <div v-else-if="activeForm === 'enableGoogle2fa'"
                    id="twoFactorAuthorise"
                >
                    <p class="text-base leading-130 color-grey-2 mb-6">
                        Google Authenticator is a free 2FA app. Download it on your mobile
                        device by searching for ‘Google Authenticator’ on the Play Store or
                        App Store.
                    </p>
                    <div>
                        <div>
                            <p class="second-step-text mb-2 fs-20">
                                1. Scan QR
                            </p>
                            <p class="leading-130 color-grey-2 mb-2 fs-15">
                                Scan this QR code with your Google Authenticator App.
                                Alternatively, you can use this code:
                            </p>
                            <BaseButton
                                class="btn-small bg-transparent text-sm font-medium color-purple mb-3"
                                @click="_initEnableGoogle2fa"
                            >
                                Code generated
                            </BaseButton>
                        </div>
                        <div class="qr-code-box mb-3 p-0">
                            <img :src="google2faQRCodeUrl" alt=""/>
                        </div>
                        <div>
                            <p class="second-step-text mb-2 fs-20">
                                2. Enter PIN
                            </p>
                            <p class="leading-130 color-grey-2 mb-2 fs-15">
                                Enter the pin from Google Authenticator.
                            </p>
                        </div>
                        <div class="google-code-container mt-3">
                            <tm-input
                                type="tel"
                                placeholder="Enter PIN"
                                :bottom-offset="false"
                                classVal="w-100"
                                v-model="google2faOTPEnable"
                            ></tm-input>
                        </div>
                    </div>
                </div>
                <div v-else-if="activeForm === 'disableGoogle2fa'"
                    id="twoFactorDisable"
                >
                    <div class="alert w-100 mb-6 alert-info big-content alternative">
                        <i class="mr-1 icon-info-circle-outline" style="margin-top: 2px"></i>
                        <p class="paragraph">2FA is currently enabled on your account.</p>
                    </div>
                    <p class="text-sm settings-text-paragraph color-grey-2 mb-6">
                        If you are looking to disable Two-factor authentication, please
                        confirm your Google Pin and Click Disable.
                    </p>
                    <div>
                        <p class="password-disable mb-2">Google PIN</p>
                        <tm-input
                            type="tel"
                            class="w-100"
                            placeholder="Enter PIN"
                            :bottom-offset="false"
                            v-model="google2faOTPDisable"
                        ></tm-input>
                    </div>
                </div>
                <div v-else-if="activeForm === 'configSMS2fa'"
                    id="twoFactorEnterPhone"
                >
                    <p class="two-step-info leading-130 mb-4 fs-15">
                        Enter your phone number.
                        <br>
                        Make sure you are able to receive SMS.
                    </p>
                    <div class="phone-field">
                        <BaseSelectPhone
                            customInputClasses="pl-1 pl-md-0"
                            class="w-100"
                            id="phone"
                            name="phone"
                            placeholder="77282828"
                            inputFieldPlaceholder="77282828"
                            label="Phone number"
                            v-model="smsPhoneNumber"
                            :error-messages="errors.collect('config.phone')"
                            data-vv-as="phone number"
                        >
                            <template>
                                <label class="text-sm font-medium mb-1"> Phone number</label>
                            </template>
                        </BaseSelectPhone>
                    </div>
                </div>
                <div v-else-if="activeForm === 'enableSMS2fa'"
                    id="twoFactorEnterPhone"
                >
                    <p class="two-step-info mb-4 fs-15">
                        We’ve sent you a code to:
                        <span class="color-purple text-uppercase bold-text">{{ smsPhoneNumber }}</span>
                    </p>
                    <div class="w-100">
                        <p class="password-disable mb-2 bold-text">
                            SMS code
                        </p>
                        <div class="google-code-container mb-4">
                            <tm-input
                                classVal="w-100"
                                placeholder="Enter code"
                                type="tel"
                                :bottom-offset="false"
                                v-model="smsCodeEnable"
                            ></tm-input>
                        </div>
                        <p class="color-grey-2 fs-15">
                            Didn’t get it?
                            <a href="#" class="btn btn-small bg-transparent text-sm font-medium color-purple"
                               @click.prevent.stop="resend"
                            >
                                Resend
                            </a>
                        </p>
                    </div>
                </div>
                <div v-else-if="activeForm === 'disableSMS2fa'"
                    id="twoFactorDisable"
                >
                    <div class="alert w-100 mb-6 alert-info big-content alternative">
                        <i class="mr-1 icon-info-circle-outline"></i>
                        <p class="paragraph">2FA is currently enabled on your account.</p>
                    </div>
                    <p class="text-sm settings-text-paragraph color-grey-2 mb-6">
                        If you are looking to disable Two-factor authentication, please
                        confirm your SMS code and Click Disable.
                    </p>
                    <div>
                        <p class="password-disable mb-2">SMS code</p>
                        <tm-input
                            type="tel"
                            class="w-100"
                            placeholder="Enter code"
                            :bottom-offset="false"
                            v-model="smsCodeDisable"
                        ></tm-input>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
<!--        Button Enable Two-Factor Authentication-->
                <BaseButton
                    v-if="activeForm === 'enable2fa'"
                    id="chosenVer"
                    @click="nextForm2fa"
                    :disabled="isNextDisable"
                    class="btn-big btn-purple text-base font-medium color-white w-100"
                >
                    Continue
                </BaseButton>
<!--        Buttons for Google Authenticator step-->
                <template v-else-if="activeForm === 'enableGoogle2fa' || activeForm === 'enableSMS2fa'">
                    <BaseButton
                        @click="prev"
                        class="btn-big btn-purple-outline text-base font-medium color-purple w-100"
                    >
                        Back
                    </BaseButton>
                    <BaseButton
                        @click="enable"
                        :loading="updateLoader"
                        :disabled="activeForm === 'enableGoogle2fa' ? !google2faOTPEnable : !smsCodeEnable"
                        class="btn-big btn-purple text-base font-medium color-white w-100"
                    >
                        Continue
                    </BaseButton>
                </template>
<!--        Buttons for SMS Authenticator step-->
                <template v-else-if="activeForm === 'configSMS2fa'">
                    <BaseButton
                        @click="prev"
                        class="btn-big btn-purple-outline text-base font-medium color-purple w-100"
                    >
                        Back
                    </BaseButton>
                    <BaseButton
                        :loading="updateLoader"
                        :disabled="!smsPhoneNumber"
                        @click="savePhone"
                        class="btn-big btn-purple text-base font-medium color-white w-100"
                    >
                        Continue
                    </BaseButton>
                </template>
<!--        Buttons for disable Authenticator-->
                <template v-if="activeForm === 'disableGoogle2fa' || activeForm === 'disableSMS2fa'">
                    <BaseButton
                        @click="disable"
                        :loading="updateLoader"
                        :disabled="activeForm === 'disableGoogle2fa' ? !google2faOTPDisable : !smsCodeDisable"
                        class="btn-big btn-purple text-base font-medium color-white w-100"
                    >
                        Disable
                    </BaseButton>
                </template>
            </template>
        </BaseModal>
<!--        Success modal-->
        <BaseModal
            v-model="successModal"
            attach=".vue-repo-app"
            width="26rem"
        >
            <ModalInfo :modalData="modalDataCheck === 'enableSuccess2fa' ? modalDataEnable : modalDataDisable"
                       @on-confirm="onCloseClick"
                       heightClass="success-modal-2fa"
            ></ModalInfo>
        </BaseModal>
    </div>
</template>
<script>
import TmInput from "~/shared/components/form/TmInput";
import TmRadio from "~/shared/components/form/TmRadio";
import Btn from "~/shared/components/Btn";
import ModalInfo from "~/components/Modals/ModalInfo";
import { mapGetters } from 'vuex';

export default {
    name: "Security2faForm",
    components: {Btn, TmInput, TmRadio, ModalInfo},
    data() {
        return {
            type: this.$store.getters['security2fa/type'],
            google2faQRCodeUrl: null,
            google2faOTPEnable: null,
            google2faOTPDisable: null,
            smsPhoneNumber: null,
            smsCodeEnable: null,
            smsCodeDisable: null,
            steps: [],
            activeForm: "enable2fa",
            modalDataCheck: null,
            successModal: false,
            modalDataEnable: {
                icon: {
                    name: "check-circle-outline",
                    class: "color-feedback-success fs-96",
                },
                title: " 2FA is enabled successfully!",
                text: "You chose to enable 2FA. If you change your mind, you can come back at any time in Settings/Security tab and disable it again.",
                btnText: "Close",
            },
            modalDataDisable: {
                icon: {
                    name: "check-circle-outline",
                    class: "color-feedback-success fs-96",
                },
                title: "2FA is disabled successfully!",
                text: "You chose to disable 2FA. If you change your mind, you can come back at any time at Settings/Security tab and enable it again.",
                btnText: "Close",
            },
            hash: this.$store.getters['security2fa/form2faDialogHash'],
            location: window.location,
            history: window.history,
            updateLoader: false
        };
    },
    computed: {
        ...mapGetters("security2fa", {
            enabled: "enable",
            form2faDialog: "form2faDialog",
            types: "types",
        }),
        isNextDisable() {
            return this.type == null;
        },
        dialog: {
            get() {
                return this.form2faDialog;
            },
            set(value) {
                this.$store.dispatch('security2fa/setForm2faDialog', value);
            },
        },
    },
    watch: {
        form2faDialog(value) {
            this.dialog = value;
            if (this.dialog) {
                if (!this.enabled) {
                    this.activeForm = "enable2fa";
                } else {
                    if (this.type === "google2fa") {
                        this.activeForm = "disableGoogle2fa";
                    } else if (this.type === "sms") {
                        this.activeForm = "disableSMS2fa";
                    }
                }
            } else {
                this.google2faOTPEnable = null;
                this.google2faOTPDisable = null;
                this.smsPhoneNumber = null;
                this.smsCodeEnable = null;
                this.smsCodeDisable = null;
            }
            this.errors.clear();
        },
        activeForm(value) {
            switch (value) {
                case "enableGoogle2fa":
                    this._initEnableGoogle2fa();
                    break;
                case "disableSMS2fa":
                    this.resend();
                    break;
            }
        },
        smsPhoneNumber() {
            this.errors.remove('config.phone');
        },
        location: {
            handler: function(location) {
                if(location.hash === this.hash) {
                    this.dialog = true;
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        _initEnableGoogle2fa() {
            this.$store
                .dispatch("security2fa/generate", {
                    type: this.type,
                    hideAlert: true
                })
                .then((data) => {
                    this.google2faQRCodeUrl = data.google2faQRCodeUrl;
                });
        },
        close() {
            this.dialog = false;
            this.steps = [];
            this.activeForm = "enable2fa";
            this.history.replaceState({}, '', this.location.href.split('#')[0]);
        },
        nextForm2fa() {
            if (this.type === "google2fa") {
                this.next("enableGoogle2fa");
            } else if (this.type === "sms") {
                this.next("configSMS2fa");
            }
        },
        next(form) {
            this.steps.push(this.activeForm);
            this.activeForm = form;
        },
        prev() {
            this.activeForm = this.steps.pop();
        },
        enable() {
            this.modalDataCheck = 'enableSuccess2fa';
            let password = this.smsCodeEnable;
            if (this.type === "google2fa") {
                password = this.google2faOTPEnable;
            }
            let url = "security2fa/enable";
            let type = "enableSuccess2fa";
            this.updateLoader = true;
            this.sendData(url, password, type)
        },
        disable() {
            this.modalDataCheck = 'disableSuccess2fa';
            let password = this.smsCodeDisable;
            if (this.type === "google2fa") {
                password = this.google2faOTPDisable;
            }
            let url = "security2fa/disable";
            let type = "disableSuccess2fa";
            this.updateLoader = true;
            this.sendData(url, password, type)
        },
        sendData(url, password, type){
            this.$store.dispatch(url,{type: this.type, password: password, hideAlert: true })
                .then(() => {
                    this.next(type);
                    this.dialog = false;
                    this.successModal = true;
                    this.updateLoader = false;
                }).catch(() => this.updateLoader = false);
        },
        savePhone() {
            return this.$store
                .dispatch("security2fa/generate", {
                    type: this.type,
                    config: {phone: this.smsPhoneNumber},
                    hideAlert: true
                })
                .then(() => {
                    this.next("enableSMS2fa");
                }, (error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        for (const [key, value] of Object.entries(error.response.data.errors)) {
                            this.errors.add({
                                field: key,
                                msg: value[0] || value
                            })
                        }
                    }
                });
        },
        resend() {
            return this.$store.dispatch("security2fa/resend", {type: this.type, hideAlert: true});
        },
        onCloseClick() {
            this.successModal = false;
            const previousPath = window.sessionStorage.getItem('previousPath');
            if (previousPath) {
                window.sessionStorage.removeItem('previousPath');
                window.location.href = previousPath;
            }
        },
    },
};
</script>
