import { render, staticRenderFns } from "./Icon.vue?vue&type=template&id=fd90c672&scoped=true"
import script from "./Icon.vue?vue&type=script&lang=js"
export * from "./Icon.vue?vue&type=script&lang=js"
import style0 from "./Icon.vue?vue&type=style&index=0&id=fd90c672&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd90c672",
  null
  
)

export default component.exports