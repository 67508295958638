import Model from '~/shared/Model';

export default class InvoiceContact extends Model{

    constructor(obj = {}) {
        super();

        this.hidden = ['id','avatar']

        this.values = {
            id: obj.id || null,
            name: obj.name || null,
            organization_name: obj.organization_name || null,
            email: obj.email || null,
            country_id: obj.country_id || null,
            zip: obj.zip || null,
            address: obj.address || null,
            address_two: obj.address_two || null,
            town_city: obj.town_city || null,
            city: obj.city || null,
            terminated_at: obj.terminated_at || null,
            region: obj.region || null,
            phone: obj.phone || null,
            avatar_file: obj.avatar_file || '',
            avatar: obj.avatar || undefined,
            worker: obj.worker || '',
            contactType:  obj.contactType || '',
            contactForType:  obj.contactForType || '',
            usageFor:  obj.usageFor || [],
            vat:  obj.vat || '',
            clear_avatar: 0,

            bank_details: obj.bank_details? 1: 0,
            bank_name: obj.bank_details? obj.bank_details.name: '',
            country_bank_id: obj.bank_details? obj.bank_details.country_id: '',
            iban: obj.bank_details? obj.bank_details.iban: '',
            swift: obj.bank_details? obj.bank_details.swift: '',
            ifsc: obj.bank_details? obj.bank_details.ifsc: '',
            account_number: obj.bank_details? obj.bank_details.account_number: '',
            romania_cif: obj.romania_cif || '',
            romania_registrul_comertului: obj.romania_registrul_comertului || '',
            routing_number: obj.routing_number || '',
            identifying_number: obj.identifying_number || '',
            sort_code: obj.sort_code || '',
            share_contact_across_organizations: !obj.share_contact_across_organizations ? 0 : 1,
        }

        this.rules = {
            name: 'required|alpha_spaces',
            email: 'required|email',
            country_id: 'required',
            address: '',
            address_two: '',
            phone: '',
            town_city: '',
            region: '',
            zip: '',
            avatar_file: 'mimes:jpeg,jpg,gif,png',

            bank_name: 'required',
            country_bank_id: 'required',
            account_number: 'required|min:3|max:255|account_number',
            iban: 'required|min:15|max:32|iban_validation',
            swift: 'required',
            romania_cif: 'required',
            romania_registrul_comertului: 'required',
            identifying_number: 'required',
            vat: ''
        };

        /*if (obj && obj.from_people) {
            this.rules = {
                name: 'required|alpha_spaces',
                email: 'required|email',
                country_id: 'required',
                address: '',
                address_two: '',
                phone: '',
                town_city: '',
                region: '',
                zip: '',
                avatar_file: 'mimes:jpeg,jpg,gif,png',

                bank_name: 'required',
                country_bank_id: 'required',
                account_number: 'required|min:3|max:255|account_number',
                iban: 'required|min:15|max:32|iban_validation',
                swift: 'required',
                romania_cif: 'required',
                romania_registrul_comertului: 'required',
                identifying_number: 'required',
                vat: ''
            };
        } else {
            this.rules = {
                name: 'required|alpha_spaces',
                email: 'required|email',
                country_id: obj?.someone_wallet || 'required',
                address: obj?.someone_wallet || 'required',
                address_two: '',
                phone: obj?.someone_wallet || 'required',
                town_city: obj?.someone_wallet || 'required',
                region: obj?.someone_wallet || 'required',
                zip: obj?.someone_wallet || 'required',
                avatar_file: 'mimes:jpeg,jpg,gif,png',

                bank_name: obj?.someone_wallet || 'required',
                country_bank_id: obj?.someone_wallet || 'required',
                account_number: 'required|min:3|max:255|account_number',
                iban: 'required|min:15|max:32|iban_validation',
                swift: 'required',
                romania_cif: 'required',
                romania_registrul_comertului: 'required',
                identifying_number: 'required',
                vat: ''
            };
        }*/
    }

    clearAvatar(){
        this.values.avatar = null;
        this.values.clear_avatar = 1;
    }
}
