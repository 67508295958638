<template>
    <div class="bank-account">
        <div class="payment-option-details-bank-account">
            <div class="mb-30">
                <h5 class="text-center heading-1 bold-text">
                    Add money via bank transfer
                </h5>
                <p class="text-center medium-text color-dark-grey-6 lh-120 mb-30">
                    Find instructions below
                </p>
                <div v-show="systemWalletKeys" class="flex-form-group mb-30 justify-content-center">
                    <tm-input-group
                        type="radio"
                        :horizontal="true"
                        :bottom-offset="false"
                        item-text="name"
                        item-value="value"
                        :items="systemWalletKeys"
                        v-model="systemWalletValue"
                    ></tm-input-group>
                </div>
            </div>
            <div class="card-group rounded-5 mb-30">
                <div class="card p-0 mb-0 overflow-hidden">
                    <div class="card-body pl-20 pr-20 pt-20" style="padding: 1.25rem;">
                        <div class="px-2 pt-2">
                            <ul v-if="systemWallet">


                                <li v-for="(item, index) in systemWalletData" :key="index" class="mb-30">

                                    <template v-if="typeof item === 'string'">
                                        <p v-html="__sanitizeHtml(item)" class="font-weight-bold"></p>
                                    </template>

                                    <template v-else>
                                        <template v-if="item.value">
                                            <p class="medium-text color-dark-grey mb-7">
                                                {{ item.title }}
                                            </p>
                                            <p v-html="__sanitizeHtml(item.value)"
                                               class="medium-text bold-text"
                                               :class="['SWIFT/BIC', 'BIC/SWIFT'].includes(item.title)?  wallet.currency.code === 'EUR' && 'text-black' ||  'color-purple' : ''"
                                            ></p>
                                        </template>
                                    </template>
                                </li>

                                <template v-if="systemWalletAddress">
                                    <li class="mb-30">
                                        <p class="medium-text color-dark-grey mb-7">
                                            Address
                                        </p>
                                        <p v-html="__sanitizeHtml(systemWalletAddress)" class="medium-text bold-text"></p>
                                    </li>
                                </template>

                                <li class="mb-2p">
                                    <p class="small-text color-dark-grey mb-2">
                                        You must use reference:
                                    </p>
                                    <p class="normal-text color-purple bold-text">
                                        {{ obj.values.bank.reference || wallet.reference }}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-footer border-0" style="padding: 0.75rem 1.25rem; background-color: rgba(0,0,0,.03);">
                        <div class="pl-2 py-1">
                            <p class="small-text color-dark-grey" style="margin-bottom: 0.375rem">
                                Money will be added to your wallet once bank transfer is
                                processed. Payments take 1-2 working days.
                            </p>
                        </div>
                    </div>
<!--                    <div class="card-footer check-footer border-0">
                        <div class="pl-30 pt-30 pb-30">
                            <span class="color-light-red small-text">
                                Please do not continue until the money has been sent
                            </span>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>

        <div class="top-up-reason-container mb-5">
            <div class="form-group mb-3">
                <div class="dropdown mb-0 purple-arrow-drop">
                    <tm-advanced-select
                        v-model="obj.values.reason"
                        label=" Choose top up reason *"
                        :items="$store.state.transferTypes"
                        item-value="value"
                        item-text="name"
                        v-validate="obj.rules.reason"
                        name="reason"
                        data-vv-as="Reason"
                        :error-messages="errors.collect('reason')"
                    ></tm-advanced-select>
                </div>
            </div>
            <div class="form-group mb-5" v-if="obj.values.reason === 'other'">
                <tm-text-area
                    label="Describe the reason of top up *"
                    placeholder="Please add notes here."
                    v-model="obj.values.note"
                    v-validate="obj.rules.note"
                    name="note"
                    data-vv-as="note"
                    :bottom-offset="false"
                    :error-messages="errors.collect('note')"
                >
                </tm-text-area>
            </div>
        </div>
    </div>
</template>
<script>
import TmInputGroup from "~/shared/components/form/TmInputGroup";
import TmCheckbox from "~/shared/components/form/TmCheckbox";
import Helpers from '~/shared/mixins/Helpers';
import TmAdvancedSelect from "~/shared/components/form/TmAdvancedSelect";
import TmTextArea from "~/shared/components/form/TmTextArea";
export default {
    name: 'AddByBank',
    mixins: [Helpers],
    components: {TmTextArea, TmAdvancedSelect, TmCheckbox, TmInputGroup},
    props: {
        obj: {
            required: true,
            type: Object
        },
        wallet: {
            default: null
        },
    },
    data(){
        return {
            insideItems: [
                {name: 'Inside USA', value: 1},
                {name: 'Outside USA', value: 0},
            ],
            systemWalletValue: 0,
        }
    },
    computed: {
        systemWalletKeys(){
            return this.systemWallet && this.systemWallet.type === 'checkbox'?
                this.systemWallet.keys.map((k, index) => { return { name: k, value: index} }): null;
        },
        systemWalletData(){
            return this.systemWallet?
                this.systemWallet.type === 'checkbox'?
                    this.systemWallet.data[this.systemWalletValue]:
                    this.systemWallet.data:
                [];
        },
        systemWalletAddress(){

            if(!this.systemWallet){
                return ''
            }

            var joinedString = "";

            let addressCheck = this.checkNested(this.systemWallet, 'address', 'many_in');

            if(addressCheck){
                Object.entries(this.systemWallet.address.many_in).forEach(entry => {
                    const [key, value] = entry;
                    if(key === 'name'){
                        return;
                    }
                    let valueNew = value ? ( value.includes("-") ?  "<br/> "+ value: value) : ''
                    joinedString =  joinedString ?  joinedString + ", " + valueNew : valueNew;
                });
            }

            return joinedString;
        },
        hasModulr(){
            //let wlt = !!this.$store.getters.wallets.find(item => item.is_modulr && item.iban);
            //if (process.env.VUE_APP_MODULR_INTEGRATION == 'COMPACT')
            let wlt = !!this.$store.getters.wallets.find(item => !item.is_modulr && item.iban && item.currency.code === "EUR");
            return wlt;
        },
        systemWallet() {
            return this.$store.state.systemWallets.find(item => item.currency === this.wallet.currency.code);
        },
    },
    watch: {
       /* hasModulr(){
            this.setReference();
        }*/
    },
    methods: {
       /* setReference(){
            this.obj.values.bank.reference = this.hasModulr && this.wallet.currency.code === 'EUR'? 'TP-' + this.__makeId(10).toUpperCase(): null;
            this.$forceUpdate();
        }*/
    },
    mounted() {
       // this.setReference();
    }
}
</script>
