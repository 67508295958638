<template>
  <v-menu ref="menu" v-model="val" v-bind="{ ...$props, ...$attrs }">
    <template v-slot:activator="{ on, attrs }">
      <slot
        v-if="$scopedSlots['activator']"
        name="activator"
        :on="on"
        :attrs="attrs"
      />
      <WlBaseButton
          type="ghost"
        class="btn-icon-sm color-grey-2"
        v-bind="attrs"
        v-on="on"
        v-else
      >
        <WlBaseIcon :class="classes" name="vertical-dots" />
      </WlBaseButton>
    </template>
    <slot></slot>
  </v-menu>
</template>

<script>
import Form from "~/modules/wallet/mixins/Form";
import Helpers from "~/shared/mixins/Helpers";
import { commonHelpersMixin } from "~/modules/wallet/mixins/commonHelpersMixin";

export default {
  name: "WlBaseMenu",

  inheritAttrs: false,

  mixins: [Form, Helpers, commonHelpersMixin],

  props: {
    // VUETIFY PROPS

    attach: {
      default: false,
    },
    eager: {
      type: Boolean,
      default: false,
    },
    offsetY: {
      type: Boolean,
      default: true,
    },
    offsetOverflow: {
      type: Boolean,
      default: true,
    },
    left: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    nudgeBottom: {
      default: "4px",
    },

    // CUSTOM PROPS

    loading: {
      type: Boolean,
      default: false,
    },
    classNames: {
      default: "",
    },
    contentClass: {
      default: "",
    },
  },

  data() {
    return {
      val: this.value,
    };
  },

  methods: {
    calcPosition() {
      this.$refs?.menu?.callActivate();
    },
  },

  watch: {
    value(val) {
      this.val = val;
    },
    val(value) {
      this.$emit("input", value);
    },
    loading() {
      this.$refs?.menu?.callActivate();
    },
  },

  computed: {
    classes() {
      return [this.classNames, "position-relative base-menu-dots-icon"].join(
        " "
      );
    },
  },
};
</script>
