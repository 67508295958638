<template>
    <div class="date-range-picker-container">
        <base-select
            height="1.5625rem"
            :bordered="false"
            class="clean-select"
            :items="dateRangeOptions"
            v-model="selectedDateRange"
            item-value="value"
            item-text="name"
        />
        <v-menu
            v-model="activeDatePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <div class="date-range-input-wrapper">
                    <BaseIcon name="calendar" class="color-black mr-2"/>
                    <v-text-field
                        v-model="dateRangeText"
                        class="empty-input"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </div>
            </template>
            <v-date-picker
                :value="value"
                @input="$emit('input', $event)"
                @change="activeDatePicker = false"
                range
            ></v-date-picker>
        </v-menu>
    </div>
</template>

<script>
import {
    format,
    endOfYesterday,
    startOfToday,
    startOfMonth,
    endOfMonth,
    subDays,
    subMonths,
} from "date-fns";

export default {
    name: "BaseDateRangePicker",
    props: {
        dateRangeOptions: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            selectedDateRange: 'today',
            activeDatePicker: false,
        };
    },

    watch: {
        selectedDateRange() {
            if (this.selectedDateRange === "yesterday") {
                return this.$emit("input", [format(endOfYesterday(), "yyyy-MM-dd")]);
            }

            if (this.selectedDateRange === "today") {
                return this.$emit("input", [format(startOfToday(), "yyyy-MM-dd")]);
            }

            if (this.selectedDateRange === "last_7_days") {

                return this.$emit("input", [
                    format(subDays(startOfToday(), 7), "yyyy-MM-dd"),
                    format(startOfToday(), "yyyy-MM-dd"),
                ]);
            }

            if (this.selectedDateRange === "last_30_days") {
                return this.$emit("input", [
                    format(subDays(startOfToday(), 30), "yyyy-MM-dd"),
                    format(startOfToday(), "yyyy-MM-dd"),
                ]);
            }

            if (this.selectedDateRange === "this_month") {
                return this.$emit("input", [
                    format(startOfMonth(startOfToday()), "yyyy-MM-dd"),
                    format(startOfToday(), "yyyy-MM-dd"),
                ]);
            }

            if (this.selectedDateRange  === "last_month") {
                return this.$emit("input", [
                    format(subMonths(startOfMonth(startOfToday()), 1), "yyyy-MM-dd"),
                    format(subMonths(endOfMonth(startOfToday()), 1), "yyyy-MM-dd"),
                ]);
            }

            if (this.selectedDateRange  === "last_6_months") {
                console.log("ti ginete re?");
                return this.$emit("input", [
                    format(subMonths(startOfMonth(startOfToday()), 6), "yyyy-MM-dd"),
                    format(startOfToday(), "yyyy-MM-dd"),
                ]);
            }

            if (this.selectedDateRange  === "last_12_months") {
                return this.$emit("input", [
                    format(subMonths(startOfMonth(startOfToday()), 12), "yyyy-MM-dd"),
                    format(startOfToday(), "yyyy-MM-dd"),
                ]);
            }

            return this.$emit("input", this.value);
        },
    },
    computed: {
        dateRangeText() {
            return this.value.join(" ~ ");
        }
    },
};
</script>
