<template>
    <div :class="customContainerClasses ? customContainerClasses : 'base-wizard-stepper-container'">
        <div class="base-wizard-stepper">
            <template v-if="!hideNavbar">
                <BaseWizardNavbar
                    v-if="!$slots['navbar']"
                    @close="$emit('close')"
                    :mobileTitle="mobileTitle"
                    :title="title"
                    :steps="steps"
                    :current-step="currentStep"
                    :button-text="buttonText"
                >
                    <slot name="navbar-button"></slot>
                </BaseWizardNavbar>
                <slot v-else name="navbar"></slot>
            </template>
            <template v-for="(step, index) in steps">
                <slot
                    :name="__stringToCamelCase(step)"
                    v-if="currentStep === index + 1"
                ></slot>
            </template>
            <slot></slot>
            <div class="col-12 nav-wizard-actions mt-auto" :class="navWizardActionsClasses">
                <template v-if="!$scopedSlots['actions']">
                    <BaseButton
                        v-if="currentStep > 1"
                        @click="back"
                        class="btn-big btn-purple-outline"
                    >
                        Back
                    </BaseButton>
                    <BaseButton class="btn-big btn-purple color-white" @click="next">
                        Next
                    </BaseButton>
                </template>
                <slot v-else name="actions" :next="next" :back="back"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import Helpers from "~/shared/mixins/Helpers";

export default {
    name: "BaseWizardStepper",

    mixins: [Helpers],

    props: {
        mobileTitle: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: "Add team member",
        },
        buttonText: {
            type: String,
            default: "Close",
        },
        steps: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Number,
            default: 0,
        },
        validSteps: {
            type: Array,
            required: false,
        },
        hideNavbar: {
            type: Boolean,
            default: false,
        },
        navWizardActionsClasses: {
            type: String,
            default: '',
        },
        customContainerClasses: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            currentStep: this.value,
        };
    },

    watch: {
        value(val) {
            this.currentStep = val;
        },
        currentStep(val) {
            this.$emit("input", val);
        },
    },

    methods: {
        next() {
            this.currentStep++;
        },
        back() {
            this.currentStep--;
        },
    },
};
</script>
