import {__config} from "@/utils/config";

const DISPLAY_CONTRACTORS_CONTRACTS = __config('VUE_APP_DISPLAY_CONTRACTORS_CONTRACTS');

const state = {
    //TODO: store in DB
    quickActions: [
        // NATIVE PAY
        {
            icon: "plus",
            name: "Add money",
            module: "nativePay",
            link: "wallet/add-money",
            kyc: {
                permission: 'money_in',
                level: 'kyc_level_2'
            },
            plans: [
                'free', 'free_nigeria', 'free_payments',
                'payments', 'payments_metered_lower', 'payments_metered', 'payments_pro',
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher', 'native_company',
                'client', 'client_seat_lower', 'client_seat', 'client_pro', 'client_seat_pro',
                'seat', 'grow', 'employer', 'employer_seat', 'employer_seat_higher', 'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2',
                'payroll_service'
            ],
        },
        {
            icon: "send-money",
            name: "Send money",
            module: "nativePay",
            link: "wallet/send-money",
            value: "send_money",
            action: 'send_money',
            kyc: {
                permission: 'money_out',
                level: 'kyc_level_2'
            },
            plans: [
                /*'free', 'free_nigeria', */'free_payments',
                'payments', 'payments_metered_lower', 'payments_metered', 'payments_pro',
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher', 'native_company',
                'client', 'client_seat_lower', 'client_seat', 'client_pro', 'client_seat_pro',
                'seat', 'grow', 'employer', 'employer_seat', 'employer_seat_higher', 'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2',
                'payroll_service'
            ],
        },
        {
            icon: "payment-request-2",
            name: "Request payment",
            module: "nativePay",
            link: "payment-request?quickAction=request",
            kyc: {
                permission: 'money_in',
                level: 'kyc_level_2'
            },
            value: "request_payment",
            plans: [
                'free', 'free_nigeria', 'free_payments',
                'payments', 'payments_metered_lower', 'payments_metered', 'payments_pro',
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher', 'native_company',
                'client', 'client_seat_lower', 'client_seat', 'client_pro', 'client_seat_pro',
                'seat', 'grow', 'employer', 'employer_seat', 'employer_seat_higher', 'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2',
                'payroll_service'
            ],
        },
        {
            icon: "team-payroll",
            name: "Batch transfer",
            module: "nativePay",
            link: "wallet/batch-transfer",
            plans: [
                'grow', 'employer', 'client', 'client_pro'
            ],
        },
        {
            icon: "invoice-2",
            name: "Create invoice",
            module: "nativePay",
            link: "invoices?quickAction=create",
            kyc: {
                permission: 'money_in',
                level: 'kyc_level_2'
            },
            plans: [
                'free_payments',
                'payments', 'payments_metered_lower', 'payments_metered', 'payments_pro',
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher', 'native_company',
                'client', 'client_seat_lower', 'client_seat', 'client_pro', 'client_seat_pro',
                'seat', 'grow', 'employer', 'employer_seat', 'employer_seat_higher', 'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2',
                'payroll_service'
            ],
        },
        {
            icon: "convert",
            name: "Convert",
            module: "nativePay",
            kyc: {},
            link: "wallet?quickAction=convert",
            value: "convert",
            action: "convert",
            plans: [
                'free', 'free_nigeria', 'free_payments',
                'payments', 'payments_metered_lower', 'payments_metered', 'payments_pro',
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher', 'native_company',
                'client', 'client_seat_lower', 'client_seat', 'client_pro', 'client_seat_pro',
                'seat', 'grow', 'employer', 'employer_seat', 'employer_seat_higher', 'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2',
                'payroll_service'
            ],
        },
        {
            icon: "search-flights",
            name: "Search flights",
            module: "nativePay",
            kyc: {
                permission: 'money_out',
                level: 'kyc_level_2'
            },
            link: "flights",
            plans: [
                'free', 'free_nigeria', 'free_payments',
                'payments', 'payments_metered_lower', 'payments_metered', 'payments_pro',
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher', 'native_company',
                'client', 'client_seat_lower', 'client_seat', 'client_pro', 'client_seat_pro',
                'seat', 'grow', 'employer', 'employer_seat', 'employer_seat_higher', 'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2',
                'payroll_service'
            ],
        },
        {
            icon: "search-accommodation",
            name: "Search accommodation",
            module: "nativePay",
            kyc: {},
            link: "tickets?create_type=13",
            plans: [
                'free', 'free_nigeria', 'free_payments',
                'payments', 'payments_metered_lower', 'payments_metered', 'payments_pro',
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher', 'native_company',
                'client', 'client_seat_lower', 'client_seat', 'client_pro', 'client_seat_pro',
                'seat', 'grow', 'employer', 'employer_seat', 'employer_seat_higher', 'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2',
                'payroll_service'
            ],
        },

        // NATIVE WORK
        {
            icon: "briefcase-thin",
            name: "Start EOR",
            module: "nativeWork",
            link: "eor?quickAction=wizard",
            value: "start_eor",
            kyc: {
                permission: 'money_out',
                level: 'kyc_level_2'
            },
            plans: [
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher',
                'seat', 'employer_seat', 'employer_seat_higher',
            ],
        },
        {
            icon: "add-user-2",
            name: "Add team member",
            module: "nativeWork",
            value: 'add_team_member',
            link: "team?quickAction=invite",
            plans: [
                'grow', "employer", "client", "client_pro"
            ],
        },
        {
            icon: "beach-holiday",
            name: "Schedule absence",
            module: "nativeWork",
            link: "absence",
            plans: [
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher',
                'seat', 'employer_seat', 'employer_seat_higher'
            ],
        },
        {
            icon: "nav-expenses",
            name: "Claim expenses",
            module: "nativeWork",
            link: "expenses",
            kyc: {
                permission: 'money_in',
                level: 'kyc_level_2'
            },
            plans: [
                'seat', 'employer_seat', 'employer_seat_higher'
            ],
        },
        {
            icon: "entity-wallet",
            name: "Create organisation",
            module: "nativeWork",
            link: "/",
            action: 'add_organization',
            kyc: {},
            plans: [
                "grow", "employer", "client", "client_pro"
            ],
        },
        {
            icon: "nav-documents",
            name: "Go to payslips",
            module: "nativeWork",
            link: "documents?folder_val=payslips",
            plans: [
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'seat', 'grow', 'employer', 'employer_seat', 'employer_seat_higher',
            ],
        },
        {
            icon: "paycheck",
            name: "Add payroll seat",
            module: "nativeWork",
            link: "employee-payroll",
            plans: [],
        },
        {
            icon: "dollar",
            name: "Apply for tax allowances",
            module: "nativeWork",
            link: "tax-allowances",
            plans: [
                'employee-seat',
            ],
        },
        {
            icon: "icon-clipboard-document-list",
            name: "Add contract",
            value: "contracts",
            module: "nativeWork",
            link: "contractor-wizard",
            plans: [
                'payments_metered_lower',
            ],
        },

        // NATIVE COMPANY
        {
            icon: "entity-wallet",
            name: "Set up legal entity",
            module: "nativeCompany",
            link: "legal-entity-status?quickAction=wizard",
            value: 'legal_entity',
            kyc: {
                permission: 'legal_entity_and_euqals',
                level: 'kyc_level_2'
            },
            plans: [
                'free', 'free_nigeria',
                'seat', 'employer_seat', 'employer_seat_higher',
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher',
                "grow", "employer", "client", 'native_company',
                'payments', 'payments_metered_lower', 'payments_metered', 'payments_pro',
                'client_seat', 'client_seat_lower', 'client_pro', 'client_seat_pro',
                'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2'
            ],
        },

        // NATIVE MOBILITY
        {
            icon: "invoice-2",
            name: "Request visa",
            module: "nativeMobility",
            link: "mobility?modal=open",
            plans: [
                'free_payments',
                "free",
                "employer",
                "remoter",
                "payments",
                "client",
                "employee-seat",
                "contractor-seat",
                "tax-management",
                "payroll-as-a-service",
                "essence",
                "remoter_higher",
                "remoter_pro",
                "payments_metered_lower",
                "payments_metered",
                "payments_pro",
                "tax_management_higher",
                'native_company',
                "seat", "employer_seat", "employer_seat_higher",
                'client_seat', 'client_seat_lower',
                'client_pro', 'client_seat_pro',
                'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2'
            ],
        },
        // LEGAL ENTITY
        {
            icon: "square-rounded-plus",
            name: "Add money",
            module: "legalEntity",
            link: "wallet/add-money",
            kyc: {
                permission: 'money_in',
                level: 'kyc_level_2'
            },
            plans: [
                'free', 'free_nigeria',
                'seat', 'employer_seat', 'employer_seat_higher',
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher',
                "grow", "employer", "client", 'native_company',
                'payments', 'payments_metered_lower', 'payments_metered', 'payments_pro',
                'client_seat', 'client_seat_lower', 'client_pro', 'client_seat_pro',
                'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2'
            ],
        },
        {
            icon: "send-money-2",
            name: "Send money",
            module: "legalEntity",
            link: "wallet/send-money",
            kyc: {
                permission: 'money_out',
                level: 'kyc_level_2'
            },
            plans: [
                'free', 'free_nigeria',
                'seat', 'employer_seat', 'employer_seat_higher',
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher',
                "grow", "employer", "client", 'native_company',
                'payments', 'payments_metered_lower', 'payments_metered', 'payments_pro',
                'client_seat', 'client_seat_lower', 'client_pro', 'client_seat_pro',
                'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2'
            ],
        },
        {
            icon: "invoice-2",
            name: "Create invoice",
            module: "legalEntity",
            link: "invoices?quickAction=create&nativeCompany=true",
            kyc: {
                permission: 'money_in',
                level: 'kyc_level_2'
            },
            plans: [
                'free', 'free_nigeria',
                'seat', 'employer_seat', 'employer_seat_higher',
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher',
                "grow", "employer", "client", 'native_company',
                'payments', 'payments_metered_lower', 'payments_metered', 'payments_pro',
                'client_seat', 'client_seat_lower', 'client_pro', 'client_seat_pro',
                'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2'
            ],
        },
    ],
    features: [
        // NATIVE PAY
        {
            icon: "wallet3",
            name: "Wallet",
            module: "nativePay",
            plans: [
                "free",
                "employer",
                "remoter",
                "payments",
                "client",
                "client_pro",
                "employee-seat",
                "contractor-seat",
                "tax-management",
                "payroll-as-a-service",
            ],
            locked: [],
        },
        {
            icon: "invoice-2",
            name: "Invoices",
            module: "nativePay",
            plans: [
                "free",
                "employer",
                "remoter",
                "payments",
                "client",
                "client_pro",
                "employee-seat",
                "contractor-seat",
                "tax-management",
                "payroll-as-a-service",
            ],
            locked: ["payroll-as-a-service"],
        },
        {
            icon: "transaction-payment-request",
            name: "Payment requests",
            module: "nativePay",
            plans: [
                "free",
                "employer",
                "remoter",
                "payments",
                "client",
                "client_pro",
                "employee-seat",
                "contractor-seat",
                "tax-management",
                "payroll-as-a-service",
            ],
            locked: ["payroll-as-a-service"],
        },
        {
            icon: "address-book",
            name: "Payment contacts",
            module: "nativePay",
            plans: [
                "free",
                "employer",
                "remoter",
                "payments",
                "client",
                "client_pro",
                "employee-seat",
                "contractor-seat",
                "tax-management",
                "payroll-as-a-service",
            ],
            locked: ["payroll-as-a-service"],
        },

        // NATIVE WORK
        {
            icon: "team-members",
            name: "Team",
            module: "nativeWork",
            plans: [
                "free",
                "payments",
                "employer",
                "client",
                "client_pro",
                "contractor-seat",
                "payroll-as-a-service",
            ],
            locked: ["free", "payments", "contractor-seat", "payroll-as-a-service"],
        },
        // {
        //     icon: "payroll-changes",
        //     name: "Payroll changes",
        //     module: "nativeWork",
        //     plans: [
        //         "free",
        //         "payments",
        //         "employer",
        //         "client",
        //         "contractor-seat",
        //         "payroll-as-a-service",
        //     ],
        //     locked: ["free", "payments", "contractor-seat", "payroll-as-a-service"],
        // },

        {
            icon: "laptop",
            name: "Team member profile",
            module: "nativeWork",
            plans: ["contractor-seat"],
            locked: ["contractor-seat"],
        },
        {
            icon: "briefcase-thin",
            name: "EOR application",
            module: "nativeWork",
            plans: ["employee-seat"],
            locked: [],
        },
        {
            icon: "beach-holiday",
            name: "Absence management",
            module: "nativeWork",
            plans: [
                "free",
                "payments",
                "employer",
                "remoter",
                "client",
                "client_pro",
                "employee-seat",
                "contractor-seat",
            ],
            locked: ["free", "payments", "client", "client_pro", "contractor-seat"],
        },
        {
            icon: "nav-expenses",
            name: "Expenses",
            module: "nativeWork",
            plans: [
                "free",
                "payments",
                "employer",
                "client",
                "client_pro",
                "employee-seat",
                "contractor-seat",
            ],
            locked: ["free", "payments", "client", "client_pro", "contractor-seat"],
        },
        {
            icon: "world",
            name: "Organisation Switcher",
            module: "nativeWork",
            plans: ["employer"],
            locked: [],
        },
        {
            icon: "money-bill",
            name: "Earned wage access",
            module: "nativeWork",
            plans: [
                "free",
                "payments",
                "employer",
                "remoter",
                "client",
                "client_pro",
                "employee-seat",
                "contractor-seat",
            ],
            locked: ["free", "payments", "client", "client_pro", "contractor-seat"],
        },
        {
            icon: "money-bag",
            name: "Tax allowances",
            module: "nativeWork",
            plans: [
                "free",
                "payments",
                "employer",
                "remoter",
                "client",
                "client_pro",
                "employee-seat",
                "contractor-seat",
            ],
            locked: ["free", "payments", "client", "client_pro", "contractor-seat"],
        },
        {
            icon: "tiles",
            name: "White labelled",
            module: "nativeWork",
            plans: [
                "employee-seat",
                "contractor-seat",
                "tax-management",
                "payroll-as-a-service",
            ],
            locked: [
                "employee-seat",
                "contractor-seat",
                "tax-management",
                "payroll-as-a-service",
            ],
        },
        {
            icon: "calculator-2",
            name: "Payroll calculator",
            module: "nativeWork",
            plans: ["free", "payments", "employer", "remoter", "client", "client_pro"],
            locked: [],
        },
        {
            icon: "paycheck",
            name: "Payroll as a service",
            module: "nativeWork",
            plans: [
                "free",
                "payments",
                "employer",
                "remoter",
                "client",
                "client_pro",
                "contractor-seat",
                "payroll-as-a-service",
            ],
            locked: ["free", "payments", "remoter", "client", "client_pro", "contractor-seat"],
        },

        // NATIVE COMPANY
        {
            icon: "entity-wallet",
            name: "Legal entity",
            module: "nativeCompany",
            plans: [
                "free",
                "employer",
                "remoter",
                "payments",
                "client",
                "tax-management",
                "payroll-as-a-service",
            ],
            locked: ["free", "payments", "client", "payroll-as-a-service"],
        },

        // NATIVE MOBILITY
        {
            icon: "mobility",
            name: "Mobility",
            module: "nativeMobility",
            link: "add-ons/services?type=mobility",
            plans: [
                'free', 'free_nigeria',
                'payments', 'payments_metered_lower', 'payments_metered', 'payments_pro',
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher',
                'client', 'client_seat_lower', 'client_seat', 'client_pro', 'client_seat_pro',
                'seat', 'grow', 'employer', 'employer_seat', 'employer_seat_higher', 'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2',
                'payroll_service'
            ],
            locked: [],
        },
        // LEGAL ENTITY
        {
            icon: "entity-wallet",
            name: "My legal entity",
            title: "My legal entity",
            route: 'legal-entity-status',
            module: "legalEntity",
            value: 'entity_status',
            plans: [
                'free', 'free_nigeria',
                'seat', 'employer_seat', 'employer_seat_higher',
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher',
                "grow", "employer", "client", 'native_company',
                'payments', 'payments_metered_lower', 'payments_metered', 'payments_pro',
                'client_seat', 'client_seat_lower', 'client_pro', 'client_seat_pro',
                'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2'
            ],
            locked: [],
        },
        {
            icon: "invoice-2",
            name: "Invoices",
            title: "Invoices",
            value: "entity_status",
            route: 'invoices?nativeCompany=true',
            module: "legalEntity",
            plans: [
                'free', 'free_nigeria',
                'seat', 'employer_seat', 'employer_seat_higher',
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher',
                "grow", "employer", "client", 'native_company',
                'payments', 'payments_metered_lower', 'payments_metered', 'payments_pro',
                'client_seat', 'client_seat_lower', 'client_pro', 'client_seat_pro',
                'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2'
            ],
            locked: [],
        },
        {
            icon: "folder-outline-light",
            name: "Legal entity documents",
            title: "Legal entity documents",
            value: "entity_status",
            module: "legalEntity",
            route: 'documents?folder_val=legal_entity_post',
            plans: [
                'free', 'free_nigeria',
                'seat', 'employer_seat', 'employer_seat_higher',
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher',
                "grow", "employer", "client", 'native_company',
                'payments', 'payments_metered_lower', 'payments_metered', 'payments_pro',
                'client_seat', 'client_seat_lower', 'client_pro', 'client_seat_pro',
                'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2'
            ],
            locked: [],
        },
        {
            icon: "wallet3",
            name: "Wallet",
            title: "Wallet",
            value: "wallet",
            module: "legalEntity",
            route: 'wallet',
            plans: [
                'free', 'free_nigeria',
                'seat', 'employer_seat', 'employer_seat_higher',
                'essence', 'remoter', 'remoter_higher', 'remoter_pro',
                'tax_management', 'tax_management_higher',
                "grow", "employer", "client", 'native_company',
                'payments', 'payments_metered_lower', 'payments_metered', 'payments_pro',
                'client_seat', 'client_seat_lower', 'client_pro', 'client_seat_pro',
                'client_seat_zone1', 'client_seat_zone2', 'employer_seat_no_eor_zone1', 'employer_seat_no_eor_zone2'
            ],
            locked: [],
        },
    ],
};

const getters = {
    getQuickActions:
        (state) =>
            (module = null, plan = null) => {
                return state.quickActions.filter((i) => {
                    if (
                        i.value === 'contracts' &&
                        DISPLAY_CONTRACTORS_CONTRACTS !== 'true'
                    ) {
                        return false;
                    }
                    return (
                        (module ? i.module === module : true) &&
                        (plan ? i?.plans.includes(plan) : true)
                    );
                });
            },
    getFeatures:
        (state) =>
            (module = null, plan = null) => {
                return state.features.filter((i) => {
                    return (
                        (module ? i.module === module : true) &&
                        (plan ? i?.plans.includes(plan) : true)
                    );
                });
            },
};

export default {
    namespaced: true,
    state,
    getters,
};
