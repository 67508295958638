<template>
    <v-menu
        :value="$store.state.globalMenuModule.menuValue"
        @input="$store.commit('globalMenuModule/TOGGLE_MENU', $event)"
        :attach="true"
        :close-on-content-click="false"
        :nudge-bottom="18"
        offset-y
        activator=".btn-modules-menu"
        min-width="0"
        :content-class="`navigation-bar-menu  ${isBasicMenu ? '' : 'menu-short'}`"
    >
        <div class="navigation-bar-menu-container">
            <!-- MODULE BUTTONS -->
            <v-slide-x-transition hide-on-leave>
                <div v-if="showModuleButtons" class="module-buttons-container">
                    <ModuleButton
                        v-if="features?.pay?.length"
                        v-model="activeModule"
                        name="nativePay"
                        @click="changeModule"
                    >
                        <v-img
                            contain
                            src="/images/new-navigation/native-pay-black.svg"
                        />
                    </ModuleButton>
                    <ModuleButton
                        v-if="features?.work?.length"
                        v-model="activeModule"
                        name="nativeWork"
                        @click="changeModule"
                    >
                        <v-img
                            contain
                            src="/images/new-navigation/native-work-black.svg"
                        />
                    </ModuleButton>
                    <ModuleButton
                        v-if="features?.company?.length"
                        v-model="activeModule"
                        name="nativeCompany"
                        @click="changeModule"
                    >
                        <v-img
                            contain
                            src="/images/new-navigation/native-company-black.svg"
                        />
                    </ModuleButton>
                    <ModuleButton
                        v-if="features?.mobility?.length"
                        v-model="activeModule"
                        name="nativeMobility"
                        @click="changeModule"
                    >
                        <v-img
                            contain
                            src="/images/new-navigation/native-mobility-black.svg"
                        />
                    </ModuleButton>
                    <WlBaseButton
                        v-if="isMobile"
                        @click="redirectToReferrals"
                        type="secondary"
                        class="w-full"
                    >
                        {{ titleToAffileReferal() }}
                    </WlBaseButton>

                </div>
            </v-slide-x-transition>

            <!-- MODULE ACTIONS -->
            <!-- DESKTOP TRANSITION-->
            <v-slide-x-transition v-if="!isMobile" hide-on-leave>
                <component
                    v-if="showSelectedModule"
                    :is="moduleComponent"
                    @back="showModule = false"
                    :worker="worker"
                    :features="features"
                    :availableMenu="availableMenu"
                ></component>
            </v-slide-x-transition>

            <!-- MOBILE TRANSITION-->
            <v-slide-x-reverse-transition v-else hide-on-leave>
                <component
                    v-if="showSelectedModule"
                    :is="moduleComponent"
                    @back="showModule = false"
                    :worker="worker"
                    :features="features"
                    :availableMenu="availableMenu"
                ></component>
            </v-slide-x-reverse-transition>
        </div>
    </v-menu>
</template>

<script>
import ModuleButton from "~/components/NewNavigation/ModuleButton.vue";
import NativePay from "~/components/NewNavigation/Modules/NativePay.vue";
import NativeWork from "~/components/NewNavigation/Modules/NativeWork.vue";
import NativeCompany from "~/components/NewNavigation/Modules/NativeCompany.vue";
import NativeMobility from "~/components/NewNavigation/Modules/NativeMobility.vue";
import LegalEntity from "~/components/NewNavigation/Modules/LegalEntity.vue";

import {mapActions, mapGetters, mapState} from "vuex";
import {commonHelpersMixin} from "~/shared/mixins/commonHelpersMixin";

import SoloEntitySwitcher from "~/shared/mixins/SoloEntity/SoloEntitySwitcher";
import Helpers from '~/shared/mixins/Helpers';

export default {
    name: "ModulesMenu",

    mixins: [commonHelpersMixin,SoloEntitySwitcher,Helpers],


    components: {
        ModuleButton,
        NativePay,
        NativeWork,
        NativeCompany,
        NativeMobility,
        LegalEntity
    },
    props: {
        worker: {
            default: null,
        },
    },

    data() {
        return {
            activeModule: "nativePay",
            showModule: false,
        };
    },
    watch: {},

  computed: {
    ...mapState('globalMenuModule', ['features','availableMenu','features','legalEntityIsActive','basicMenu']),//only one initialization is needed - done in App.vue
    ...mapState('dashboard', ['walletSwitcher']),

      accessAffiliates(){
          return this.$store.state.accessAffiliates;
      },

      isBasicMenu() {
          if(this.moduleExists('dashboard/')){
              return this.walletSwitcher !== "legalEntity"
          }
          return this.basicMenu;

      },
    moduleComponent() {
        if (!this.isBasicMenu) return LegalEntity;
      switch (this.activeModule) {
        case "nativeWork":
          return NativeWork;
        case "nativeCompany":
          return NativeCompany;
        case "nativeMobility":
          return NativeMobility;
        default:
          return NativePay;
      }
    },

    showModuleButtons() {
        if (!this.isBasicMenu) return false;
        if (!this.isMobile) return true;
      return !this.showModule;
    },

    showSelectedModule() {
        if (!this.isMobile || !this.isBasicMenu) return true;
      return this.showModule;
    },
  },

    methods: {
        changeModule() {
            if (this.isMobile) this.showModule = true;
        },
        redirectToReferrals() {
          let url = this.accessAffiliates ? '/affiliates' : '/referrals';
          this.$store.commit('globalMenuModule/TOGGLE_MENU', false)
          this.__changeRoute(url)
        },
        titleToAffileReferal() {
            return this.accessAffiliates ? 'Affiliates' : 'Referrals';
        },
    },
};
</script>

<style scoped lang="scss">
.navigation-bar-menu {
    background-color: unset !important;
    left: unset !important;
    right: 0 !important;
    max-width: 58.5rem;
    width: 100%;
    box-shadow: unset !important;
    overflow: unset !important;
    contain: unset !important;

    &::v-deep {
        .btn-secondary {
            width: 100%;
            background-color: transparent !important;
            color: var(--main-purple-color) !important;
            border: 1px solid var(--main-purple-color) !important;

            &:hover {
                background-color: var(--main-purple-color) !important;
                color: white !important;

                &:before {
                    content: none;
                }

                i {
                    color: white !important;
                }
            }

            &:active,
            &:focus {
                background-color: #d7e0ff !important;
                color: var(--main-purple-color) !important;

                &:before {
                    content: none;
                }

                i {
                    color: var(--main-purple-color) !important;
                }
            }
        }
     }

    .navigation-bar-menu-container {
        margin-bottom: 1rem;
        border-radius: 30px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
        display: flex;
        padding: 1.875rem;
        background: white;

        @media (max-width: 991px) {
            padding: 1.5rem;
        }
    }

    .module-buttons-container {
        max-width: 26.25rem;
        width: 100%;
        margin-right: 1rem;

        @media (max-width: 991px) {
            max-width: 22.25rem;
        }

        @media (max-width: 640px) {
            max-width: unset;
            margin-right: 0;
        }
    }
}
.menu-short {
    max-width: 31.25rem;

    .actions-container {
        padding: 1.5rem;
        border-radius: 22px;
        background: #f2f5fa;
        width: 100%;

        .quick-actions {
            display: flex;
            flex-direction: column;
            gap: 1.75rem;

            .quick-actions-buttons {
                display: flex;
                gap: 16px;

                .button-wrapper {
                    width: 120px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
</style>
