<template>
    <component v-bind="{...$attrs, ...$props}"  @input="setVal" :is="currentInputComponent" />
</template>
<script>
import WlBaseInput from "~/modules/wallet/components/BaseComponents/WlBaseInput";

export default {
    name: 'BaseLazyInput',
    components: {
        WlBaseInput
    },
    props: {
        value: {
            default: null
        },
        lazy : {
            default: false
        },
        delay : {
            default: 400
        }
    },
    data(){
        return {
            timeout: null,
        }
    },
    methods: {
        setVal(e){
            if (e === this.value){
                return;
            }

            if (this.timeout){
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => this.$emit('input', e), this.delay);
        }
    },
    computed : {
        currentInputComponent() {
            return this.lazy
                ? "WlBaseInput"
                : "BaseInput";
        },
    }
}
</script>
