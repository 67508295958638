import Middleware from "~/routes/middleware/Middleware";
import store from "@/store";

export default class eorSelfServe extends Middleware{
    constructor(...args) {
        super(...args);
    }

    async getData(query) {
        return await axios.post('eor/self-serve', query)
            .then(response => response.data)
    }

    async resolve() {
        const data = await this.getData(this.to.query)
        data.value = true;
        data.isLoggedIn = store.getters['auth/__isAuth'];
        return this.responseNext(data)
    }
}
