export const modalsMixinWithProps = {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
  },
};
