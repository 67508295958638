<template>
    <v-dialog
        v-if="!isUpgrade"
        :fullscreen="fullscreen"
        no-click-animation
        :content-class="classes"
        v-model="dialog"
        :persistent="persistent"
        :width="width"
        :max-width="maxWidth"
        :hide-overlay="hide"
        :retain-focus="retainFocus"
        :attach="attach"
    >
        <slot v-if="$slots.default" name="default"></slot>
        <div v-else>
            <v-card
            style="position: relative; min-height: 100px"
            :class="classNames"
            :tile="tile"
        >
            <v-btn
                v-if="closeIconShow"
                :small="!closeBg && !closeSmall"
                :x-small="!!closeBg || closeSmall"
                :icon="!closeBg"
                :fab="!!closeBg"
                @click="dialog = false"
                style="position: absolute; z-index: 9"
                :style="{ top: isNaN(closeTop) ? closeTop : (closeTop + 'px'), right: isNaN(closeRight) ? closeRight : (closeRight + 'px') }"
                :color="closeBg || 'black'"
                :class="closeDialogLeft ? 'position-left-10' : ''"
            >
                <icon
                    :size="closeSize / 16"
                    :rem="true"
                    name="icon-times"
                    :color="closeColor"
                ></icon>
            </v-btn>
            <v-card-text :class="padding ? 'tm-card-p' : 'pa-0'">
                <div v-if="title || $slots.title" class="mb-1-rem">
                    <span v-if="title" class="tm-heading-2 tm-color-purple">{{ title }}</span>
                    <slot v-else name="title"></slot>
                </div>
                <slot name="content"></slot>
            </v-card-text>
        </v-card>
        </div>
    </v-dialog>
</template>
<script>
import Helpers from '~/shared/mixins/Helpers';

export default {
    name: "Modal",
    mixins: [Helpers],
    props: {
        attach: {
            default: false
        },
        closeIconShow: {
            default: true,
            type: Boolean,
        },
        value: {
          required: true,
          type: Boolean,
        },
        persistent: {
          default: true,
        },
        padding: {
          default: true,
        },
        closeDialogLeft: {
          default: false,
        },
        width: {
          default: 500,
        },
        maxWidth: {
          default: null,
        },
        title: {
          default: null,
        },
        classNames: {
          default: null,
        },
        closeTop: {
          default: 20,
        },
        closeRight: {
          default: 20,
        },
        closeSize: {
          default: 16,
        },
        closeBg: {
          default: null,
        },
        closeColor: {
          default: "black",
        },
        closeSmall: {
          default: false,
        },
        position: {
          default: "middle", //start
        },
        fullscreen: {
          default: false,
        },
        tile: {
          default: false,
        },
        contentClass: {
          default: null,
        },
        permission: {
            default: null,
        },
        retainFocus: {
            default: false,
        },
        hideable: {
            default: true,
        },
    },
    data() {
        return {
            dialog: this.value,
            closedModals: [],
            hide: false
        };
    },
    computed: {
        classes(){
            return [
                'dialog-class-'+this.classNames,
                'align-self-'+this.position,
                (this.tile? 'rounded-0' : null),
                this.contentClass,
                this.hide? 'd-none': null
            ].join(' ');
        },
        isUpgrade(){
            return this.permission && !this.__checkPermission(this.permission);
        }
    },
    watch: {
        value() {
            this.dialog = this.value;
            this.value? this.closeModals(this.$root): this.openModals(this.$root);

            if (this.dialog && this.isUpgrade){
                this.$store.state.upgradeDialog = true;
            }
        },
        dialog() {
            this.$emit("input", this.dialog);
            if (!this.dialog) {
                this.$emit("close");
            }
        },
        $route(){
            this.dialog = false;
        },
    },
    methods: {
        closeModals(obj){
            if (obj.$options.name === 'Modal' && obj._uid !== this._uid && obj.dialog && !obj.hide && obj.$props.hideable){
                this.closedModals.push(obj._uid);
                obj.hide = true;
            }
            obj.$children.forEach(item => this.closeModals(item));
        },

        openModals(obj){
            if (obj.$options.name === 'Modal' && this.closedModals.includes(obj._uid)){
                obj.hide = false;
                this.closedModals = this.closedModals.filter(item => item !== obj._uid);
            }
            obj.$children.forEach(item => this.openModals(item));
        }
    },
    created() {
        if (this.dialog){
            this.closeModals(this.$root)
        }
        if (this.dialog && this.isUpgrade){
            this.$store.state.upgradeDialog = true;
        }
    }
};
</script>
<style scoped>
.v-dialog {
  overflow-y: auto !important;
  scrollbar-width: thin !important;
}
.v-dialog .modal-content{
  overflow-y: hidden !important;
  max-height: unset !important;
}
</style>
