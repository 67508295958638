<template>
    <div class="col-12 base-intro-screen-container">
        <div class="base-intro-screen">
            <div class="base-intro-screen-wrapper">
                <div class="text-wrapper">
                    <div v-if="steps.length > 1" class="bullets">
                        <div
                            v-for="i in steps.length"
                            :key="i"
                            :class="{ active: i <= step }"
                            class="item"
                        />
                    </div>
                    <p class="title-text">{{ currentStep.title }}</p>
                    <ul v-if="!$scopedSlots['custom-description']">
                        <li
                            v-for="(text, index) in showTexts"
                            :key="index"
                            :class="{ 'color-grey-2': greyPrevious && index < step - 1 }"
                        >
                            {{ text }}
                        </li>
                    </ul>
                    <slot name="custom-description" :item="currentStep" :step="step" />

                    <div class="mt-auto w-100">
                        <BaseButton
                            class="btn-big btn-purple color-white mt-auto"
                            @click="next"
                            :class="{ 'first-button': $slots['second-button'] }"
                        >
                            {{ currentStep.btnText || "Next" }}
                        </BaseButton>

                        <span class="second-button">
              <slot name="second-button" :step="step"></slot>
            </span>
                    </div>

                    <slot name="additional-content"></slot>
                </div>
                <div v-if="!getVideoObj" class="image-wrapper">
                    <v-img v-if="!!getImage" :src="getImage" />
                    <slot name="extra-content" :step="step" />
                </div>
                <div v-else class="video-wrapper">
                    <BaseModal
                        attach
                        :title="getVideoObj.title"
                        v-model="videoDialog"
                        width="64.375rem"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-img
                                v-on="on"
                                v-bind="attrs"
                                :aspect-ratio="16 / 9"
                                :src="getVideoObj.preview"
                                class="cursor-pointer"
                            />
                        </template>
                        <template v-slot:body>
                            <div class="iframe-wrapper">
                                <iframe
                                    v-if="videoDialog"
                                    :src="getVideoObj.url"
                                    allowfullscreen="allowfullscreen"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                ></iframe>
                            </div>
                        </template>
                    </BaseModal>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "BaseIntroScreen",

  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    image: {
        default: null,
    },
    videoObj: {
        type: Object,
        default: null,
    },
    greyPrevious: {
        type: Boolean,
        default: false,
    },
    image: {
      default: null,
    },
    showBullets: {
      type: Boolean,
      default: true,
    },
    greyPrevious: {
      type: Boolean,
      default: false,
    },
    titleTextClasses: {
      type: String,
      default: '',
    },
    baseIntroScreenClasses: {
      type: String,
      default: '',
    },
  },

    data() {
      return {
          step: 1,

          videoDialog: false,
      };
    },

    computed: {
      currentStep() {
          return this.steps[this.step - 1];
      },

      getImage() {
          return this.image || this.currentStep?.image;
      },

      getImageWrapperCustomClasses() {
        return this.image || this.currentStep?.imageWrapperCustomClasses;
      },

      getVideoObj() {
        return this.videoObj || this.currentStep?.videoObj;
      },

      imageMaxWidth() {
        return "28rem";
      },

      showTexts() {
        let steps = this.steps.filter((item, index) => index + 1 <= this.step);
        return steps.map((i) => i.description);
      },

      imageMaxWidth() {
        return "28rem";
      },

      showTexts() {
        let steps = this.steps.filter((item, index) => index + 1 <= this.step);
        return steps.map((i) => i.description);
      },
  },

    methods: {
      next() {
          this.step < this.steps.length ? this.step++ : this.$emit("finish");
      },
    },
};
</script>
