import Pusher from "pusher-js";
import Core from "~/listeners/Core";

export default class definitions{
    resolve() {
        /*Pusher*/
        window.Pusher = Pusher;
        /*Cookie*/
        document.cookie = `timezone = ${Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'UTC'}`;
        /*Events*/
        const listener = new Core();
        window.document.addEventListener("__core", (e) => listener.run(e),false);
    }
}
