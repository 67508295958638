<template>
    <v-dialog
        :width="modalData.width || null"
        :value="dialog"
        @click:outside="close()"
        content-class="custom-modal modal-md kyc-modal"
    >
        <div class="modal-container position-relative">
            <div class="modal-header">
                <WlBaseButton
                    v-if="modalData.showBtnCloseModal"
                    @click="close()"
                    class="btn-icon-sm ml-auto btn-close-modal"
                >
                    <WlBaseIcon name="times" class="text-lg" />
                </WlBaseButton>

                <div v-if="modalData.image" class="img-container">
                    <img
                        :src="`/images/kyc/${modalData.image}`"
                        alt="KYC"
                    />
                </div>
                <div v-if="modalData.icon" class="d-flex w-100" :class="modalData.icon?.justify ?? 'justify-center'" style="font-size: 9rem;">
                    <WlBaseIcon
                        :name="modalData.icon.name"
                        :class="modalData.icon.class"
                    />
                </div>
            </div>
            <div class="modal-body" style="position: unset">
                <h2 class="heading-2 font-bold color-black mb-4" :class="modalData.titleCenter? 'text-center': ''">
                    {{ modalData.title }}
                </h2>

                <slot v-if="$slots.default"></slot>

                <p v-else-if="modalData.text" class="text-sm leading-150 color-grey-2">
                    {{ modalData.text }}
                </p>
            </div>
            <div class="modal-footer">
                <WlBaseButton
                    v-if="modalData.btnText"
                    type="primary"
                    :loading="loading"
                    :href="modalData.href || null"
                    :disabled="invalid || modalData.invalid"
                    class="w-full"
                    @click="$emit('confirm')"
                >
                    {{ modalData.btnText }}
                </WlBaseButton>

                <slot name="underButton"></slot>
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    name: "ModalKYCTemplate",

    props: {
        dialog: {
            type: Boolean,
            required: true,
        },
        modalData: {
            type: Object,
            default: () => {},
        },
        invalid: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
    watch: {
        dialog(){
            if (!this.dialog){
                this.close();
            }
        }
    },
    methods: {
        close(){
            this.$emit('close');
            this.$emit('close-modal')
        }
    }
};
</script>

<style lang="scss" scoped>
.kyc-modal {
    .modal-header {
        .img-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }

    .heading-2 {
        font-size: 40px;
    }
}
</style>
