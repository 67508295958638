import Middleware from "~/routes/middleware/Middleware";
import store from "~/store";

export default class documentTitle extends Middleware{
    constructor(...args) {
        super(...args);
    }

    resolve() {
        document.title = this.to?.meta?.title || store.state.defaultTitle;
    }
}
