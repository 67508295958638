export const commonMutations = {
    LOADER(state, value) {
        state.loader = value;
    },
    SET_ALERT(state, alert) {
        if (!state.netWorkError) {
            state.alert.value = true;
            state.alert.type = alert.type;
            state.alert.message = alert.message;
        }
    },
    CLOSE_ALERT(state) {
        state.alert.value = false;
        state.alert.type = null;
        state.alert.data = {};
    },
    SET_ALERT_MODAL(state, alertModal) {
        state.alertModal = {
            value: true,
            type: alertModal.type,
            data: { title: '', text: '', btnText: 'Close', icon: { name: 'check-circle-outline', class: 'color-feedback-error' } },
            onClose: (() => { }),
        };

        if (state.alertModal.type === 'error') {
            state.alertModal.data = Object.assign(state.alertModal.data, {
                icon: { name: 'exclamation-outline', class: 'color-feedback-error' }//https://app.asana.com/0/1201373713566097/1206292244155238
            });
        } else if (state.alertModal.type === 'warning') {
            state.alertModal.data = Object.assign(state.alertModal.data, {
                icon: { name: 'warning', class: 'color-feedback-warning' }
            });
        } else {
            state.alertModal.data = Object.assign(state.alertModal.data, {
                icon: { name: 'check-circle-outline', class: 'color-feedback-success' }
            });
        }

        state.alertModal.data = Object.assign(state.alertModal.data, {
            title: alertModal?.data?.title || state.alertModal.data.title,
            text: alertModal?.data?.text || state.alertModal.data.text,
            asHtml: alertModal?.data?.asHtml || state.alertModal.data.asHtml,
            btnText: alertModal?.data?.btnText || state.alertModal.data.btnText,
        });

        state.alertModal.onClose = alertModal.onClose || (() => { });
    },
    CLOSE_ALERT_MODAL(state) {
        state.alert.value = false;
        state.alert.type = null;
        state.alertModal.data = { title: '', text: '', btnText: '', icon: { name: 'check-circle-outline', class: 'color-feedback-error' } };
    },
    setData(state, values) {
        state[values.key] =
            values.data && typeof values.data === 'object' && !Array.isArray(values.data)
                ? { ...values.data }
                : values.data;
    },
};
