<!-- @format -->

<template>
    <i
        :class="iconClassName"
        v-on="$listeners"
        :style="{
          'font-size': size,
          color: color
        }"
    />
</template>

<script>
export default {
    name: 'BaseIcon',
    props: {
        name: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {};
    },
    computed: {
        iconClassName() {
            return this.name && this.name.startsWith('icon')? this.name : `icon-${this.name}`;
        },
    },
};
</script>
