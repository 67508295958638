import Model from '~/shared/Model';

export default class SoloEntity extends Model{

    constructor(obj = {}) {
        super();

        this.hidden = ['logo'];

        this.values = {
            email: obj.email || '',
            company_name: obj.company_name || '',
            alt_company_name: obj.alt_company_name || '',
            director_name: obj.director_name || '',
            shareholder_name: obj.shareholder_name || '',
            agreed_director: obj.agreed_director || 0,
            agreed_shareholder: obj.agreed_shareholder || 0,
            accepted_terms: obj.accepted_terms || 0,
            not_a_pep: obj.not_a_pep || 0,
            security_register: obj.security_register || 0,
            secure_register_name: obj.secure_register_name || '',
            is_draft: obj.is_draft || 0,
            logo: obj.logo || undefined,
            logo_file: obj.logo_file || undefined,
            address_data: this.initAddressFields(obj.address_data),
            bank_details: this.initBankDetailsFields(obj.bank_details),
            country_id: obj.country_id || '',
            start_date: obj.start_date || '',
            has: !!obj.id || false,
            company_number: obj.company_number || '',
            business_email: obj.business_email || '',
        }

        this.rules = {
            company_name: 'required|min:3|max:255',
            alt_company_name: 'required|min:3|max:255',
            director_name: 'required|min:3|max:255',
            shareholder_name: 'required|min:3|max:255',
            business_email: 'email|max:255',
        };
    }

    removeStatus(){
        delete this.values.is_draft;
    }

    removeBooleans(){
        delete this.values.agreed_director;
        delete this.values.agreed_shareholder;
        delete this.values.accepted_terms;
    }

    setDraftStatus(){
        this.values.is_draft = 1;
    }

    removeDraftStatus(){
        this.values.is_draft = 0;
    }

    removeLogo(){
        this.values.delete_logo = 1;
    }

    checkDirectorStep(){
        return [
            this.values.agreed_director,
            this.values.agreed_shareholder,
            this.values.not_a_pep,
        ].every(el => this.isTrue(el));
    }

    checkPscStep(){
        return this.isTrue(this.values.agreed_psc);
    }

    checkTermsStep(){
        return this.isTrue(this.values.accepted_terms);
    }

    isTrue(value){
        return value === 1;
    }

    initAddressFields(address){
        if(!address){
            address = {};
        }
        return {
            address_line_1: address.address_line_1 || '',
            address_line_2: address.address_line_2 || '',
            address_line_3: address.address_line_3 || '',
            post_code: address.post_code || '',
            city: address.city || '',
        }
    }

    initBankDetailsFields(details){
        if(!details){
            details = {};
        }
        return {
            account_number: details.account_number || '',
            iban: details.iban || '',
            swift: details.swift || '',
        }
    }
}
