<template>
    <i
        :class="{'color-green': payeeStatus.status.type == 1, 'color-yellow': payeeStatus.status.type == 0, 'color-red': payeeStatus.status.type == 99}"
        class="icon-check-circle-filled fs-18 mr-1"
    ></i>
</template>
<script>

export default {
    name: 'StatusIcon',
    mixins: [],
    props: {
        payeeStatus: {
            required: true,
            type: Object
        },
    },
    computed: {

    },
    methods: {

    },
    mounted(){

    }
}
</script>
