import axios from "axios";

export const namespaced = true;


export const state = {
    annual_date: '',
    threshold: 0,
    threshold_second: 0,
    threshold_third: 0,
    total_gross: 0,
    total_business: 0,
    total_personal_expense: 0,
    currency: '',
    countryName: '',
    tax_details: '',
    tax_form: '',
    countryOptions: [],
    solo_entity_total_gross: 0,
    solo_entity_total_business: 0,
    solo_entity_total_personal: 0,
    solo_entity_tax_form: '',
    solo_entity_tax_details: '',
    show_default_data: true,
    countryData: {},
    soloEntityCountryData: {},
    tax_data: {},
    solo_entity_tax_data: {},
    tax_date: null,
    tax_management_plan: false,
    loading: true,
    tax_manager_avatar: 'https://i.nativeteams.com/assets/tax/onboarding/avatar/marijana.png',

};

export const mutations = {
    SET_DATA(state, payload) {
        state.annual_date = payload.taxData?.annual_date;
        state.threshold = payload.taxData?.treshold;
        state.threshold_second = payload.taxData?.tax_threshold_two;
        state.threshold_third = payload.taxData?.tax_threshold_three;
        state.total_gross = payload.gross;
        state.total_personal_expense = payload.personal_expense;
        state.total_business = payload.business_expense;
        state.currency = payload.currency;
        state.countryName = payload.country_name;
        state.tax_details = payload.taxData?.tax_details;
        state.tax_form = payload.taxData?.tax_form_file;
        state.solo_entity_total_gross = payload.soloEntityGrossTotal;
        state.solo_entity_total_personal = payload.soloEntityPersonalTotal;
        state.solo_entity_total_business = payload.soloEntityBusinessTotal;
        state.countryData = payload.countryData;
        state.soloEntityCountryData = payload.soloEntityCountryData;
        state.tax_data = payload.taxData;
        state.solo_entity_tax_data = payload.soloEntityCountryTaxData;
        state.tax_date = payload.countryData.tax_date;
        state.tax_management_plan = payload.managementPlan;
        if (payload.soloEntity) {
            const legalEntityCountry = {
                'name': payload.soloEntityCountryData?.country_name,
                'value': payload.soloEntityCountryData?.id,
                'avatar': payload.soloEntityCountryData?.flag,
                'solo_entity': true
            }
            state.countryOptions.push(legalEntityCountry)
        }
        state.loading = false;
    },
    SET_DEFAULT_DATA(state, payload) {
        state.show_default_data = payload;
        if (payload) {
            state.countryName = state.soloEntityCountryData?.country_name;
            state.tax_form = state.solo_entity_tax_data?.tax_form_file;
            state.tax_details = state.solo_entity_tax_data?.tax_details;
            state.annual_date = state.solo_entity_tax_data?.annual_date;
            state.threshold = state.solo_entity_tax_data?.treshold;
            state.tax_date = state.soloEntityCountryData?.tax_date;
        } else {
            state.countryName = state.countryData?.country_name;
            state.tax_form = state.tax_data?.tax_form_file;
            state.tax_details = state.tax_data?.tax_details;
            state.annual_date = state.tax_data?.annual_date;
            state.threshold = state.tax_data?.treshold;
            state.tax_date = state.countryData?.tax_date;

        }
    }
};

export const actions = {
    async getData({commit}) {
        return await axios
            .get('tax-reporting/get-data',)
            .then(response => {
                commit('SET_DATA', response.data)
            })
    },
    defaultData({commit, state}, payload) {
        commit('SET_DEFAULT_DATA', payload)
    },
    exportData({commit}, payload) {
        console.log(payload)
        const from = payload[0];
        const to = payload[1];
        window.open(`/tax-reporting/export?from=${from}&to=${to}&legal_entity=${state.show_default_data}`, '_blank');

    }

};

export const getters = {
    get_annual_date(state) {
        return state.annual_date
    },
    get_threshold(state) {
        return state.threshold;
    },
    get_total_gross(state) {
        return state.total_gross;
    },
    get_business(state) {
        return state.total_business;
    },
    get_total_personal_expense(state) {
        return state.total_personal_expense;
    },
    get_country_options(state) {
        return state.countryOptions;
    },
    get_solo_entity_total_gross(state) {
        return state.solo_entity_total_gross;
    },
    get_solo_entity_total_business(state) {
        return state.solo_entity_total_business;
    },
    get_solo_entity_total_personal(state) {
        return state.solo_entity_total_personal;
    },
    get_default_data(state) {
        return state.show_default_data;
    },
    get_tax_date(state) {
        return state.tax_date;
    },
    get_tax_management_plan(state) {
        return state.tax_management_plan
    },
    get_loading(state) {
        return state.loading;
    }

};
