<!-- @format -->

<template>
  <div
      ref="Select"
      :class="[parentBlockClass ? parentBlockClass : null]"
      v-click-outside="clickOutside"
      class="position-relative"
      @click="toggleOpen(!open)"
  >
      <label
          v-if="$slots.label || label"
          :class="labelBottomOffset ? 'mb-0' : null"
          class="custom-label text-small color-black"
      >
          <slot v-if="$slots.label" name="label"></slot>
          <span :class="[labelClassVal]" v-else>
              {{ label }}
              <span v-if="asterisk" class="red--text">*</span>
          </span>
      </label>
      <p
          v-if="$slots.subLabel || subLabel"
          class="text-sm color-grey-3 leading-130 mb-1"
      >
          <slot v-if="$slots.subLabel" name="subLabel"></slot>
          <span v-else>{{ subLabel }}</span>
      </p>
      <div
          :class="[classes, bordered ? 'nt-input' : 'd-flex align-center pl-2', disabled? '': 'pointer']"
          :style="{ 'max-width': maxWidth, height: multiple ? 'auto' : height, 'min-height': height, 'background-color': backgroundColor }"
          style="position: relative"
      >
          <div class="w-100 d-flex align-center" :class="selectedItemStyle ? 'selected-item-text' : ''">

              <!-- Prepend Slot -->
              <slot name="prepend-inner"></slot>

              <!-- Something Selected -->
              <template v-if="isSelected">
                  <!-- Multiple -->
                  <div v-if="multiple" class="pointer d-flex flex-wrap">
                      <template v-for="(item, index) in selected">
                          <div :key="index" class="d-flex align-center selected-option-item my-1 mr-2">
                              <template>
                                  <slot
                                      v-if="$scopedSlots['selection']"
                                      name="selection"
                                      v-bind="{ item, index }"
                                  ></slot>
                                  <base-select-item
                                      v-else
                                      :item="item"
                                      :avatar="avatar"
                                      :avatar-obj="avatarObj"
                                      :item-text="itemText"
                                      :item-value="itemValue"
                                  ></base-select-item>
                              </template>

                              <button v-if="clearable && !clearableItem" class="ml-1" @click.prevent.stop="clear(item[itemValue])">
                                  <base-icon name="times" size="0.5rem" color="red" />
                              </button>
                          </div>
                      </template>
                  </div>

                  <!-- Single -->
                  <div
                      v-else
                      class="pointer d-flex align-center"
                      :class="autocomplete ? '' : 'w-100 justify-space-between pr-2'"
                  >
                      <template>
                          <slot
                              v-if="$scopedSlots['selection']"
                              name="selection"
                              v-bind="{ item: showSelected(selected) }"
                          ></slot>
                          <base-select-item
                              v-else
                              :item="selected"
                              :avatar="avatar"
                              :avatar-obj="avatarObj"
                              :item-text="itemText"
                              :item-value="itemValue"
                          />
                      </template>

                      <button v-if="clearable && !clearableItem" class="ml-2" @click.stop.prevent="clear()">
                          <base-icon name="times" size="0.7rem" />
                      </button>
                  </div>
              </template>

              <!-- Input -->
              <template v-if="autocomplete || !isSelected">
                  <input
                      v-if="autocomplete && !disabled && !readonly"
                      :value="isSelected ? '' : autocompleteText"
                      :placeholder="isSelected ? '' : __selectPlaceholder"
                      class="w-100"
                      @keydown="keyDownAutocomplete"
                      @keyup="keyupAutocomplete"
                      @change="$emit('change', val)"
                  />
                  <input v-else-if="!isSelected" class="w-100" readonly :placeholder="__selectPlaceholder" />
              </template>
          </div>

          <!-- Loading  -->
          <div v-if="loading" class="w-100" style="position: absolute; bottom: 0; left: 0">
              <v-progress-linear
                  indeterminate
                  height="2"
                  color="#5152fb"
              ></v-progress-linear>
          </div>

          <!-- Chevron Icon  -->
          <BaseIcon name="chevron-down" :style="chevronIconStyle" class="color-purple mr-2" />
      </div>

      <div ref="Box" v-observe-visibility="visibility" class="position-relative w-100 custom-class-box">
          <div ref="BoxContent" style="z-index: 9">
              <v-fade-transition>
                  <div
                      v-if="open"
                      class="elevation-4 rounded py-2 custom-class-box-inside"
                      style="z-index: 1; background: white"
                      :style="viewBoxStyles"
                      :class="viewBoxClass"
                      @click.prevent.stop
                  >
                      <button v-if="clearable && clearableItem"
                              class="d-flex align-center w-100 nt-select-item color-dark-grey"
                              :class="[
                                  itemPadding ? 'px-4' : '',
                                  itemClass,
                              ]"
                              :style="{ height: `${viewItemHeight}px` }"
                              @click.prevent.stop="clear"
                      >
                          {{ clearText }}
                      </button>
                      <v-virtual-scroll
                          v-if="visibleItems.length"
                          :items="visibleItems"
                          :height="scrollHeight"
                          :item-height="viewItemHeight"
                          class="overflow-x-hidden"
                          v-scroll.self="onScroll"
                      >
                          <template #default="{ item, index }">
                              <div class="pb-1" :style="{ height: `${viewItemHeight}px` }">
                                  <button
                                      :class="[
                                  checkSelected(item[itemValue]) && !checkDisabledItem(item) ? customClassActiveItem : '',
                                  checkDisabledItem(item) ? 'nt-select-item-disabled' : '',
                                  itemPadding ? 'px-4' : '',
                                  itemClass,
                              ]"
                                      class="d-flex align-center w-100 h-100 nt-select-item"
                                      @click.stop.prevent="!checkDisabledItem(item) ? toggleSelected(item[itemValue]) : null"
                                  >
                                      <slot v-if="$scopedSlots['item']" name="item" v-bind="{ item, index }"></slot>
                                      <base-select-item
                                          v-else
                                          :item="item"
                                          :avatar="avatar"
                                          :avatar-obj="avatarObj"
                                          :multiple="multiple"
                                          :item-text="itemText"
                                          :item-value="itemValue"
                                      />
                                  </button>
                              </div>
                          </template>
                      </v-virtual-scroll>

                      <div v-else class="d-flex justify-center align-center" style="height: 40px">
                          <span class="text-small">No data available</span>
                      </div>

                      <!-- Append Item -->
                      <slot name="append-item"></slot>

                  </div>
              </v-fade-transition>
          </div>
      </div>
      <base-error-message :messages="errorMessages" />
  </div>
</template>

<script>
import BaseIcon from '~/shared/components/BaseElements/BaseIcon.vue';

import BaseSelectItem from '../BaseElements/elements/BaseSelectItem.vue';
import Form from "~/shared/mixins/Form";

export default {
    name: 'BaseSelect',
    components: { BaseIcon, BaseSelectItem },
    mixins: [Form],
    props: {
        attach: {
            default: false
        },
        avatar: {
            default: false,
            type: [Boolean, Function],
        },
        avatarObj: {
            type: [String, null],
            default: null,
        },
        bordered: {
            type: Boolean,
            default: true,
        },
        maxWidth: {
            type: String,
            default: 'auto',
        },
        labelBottomOffset: {
            type: Boolean,
            default: false,
        },
        parentBlockClass: {
            type: String,
            default: 'auto',
        },
        viewBoxClass: {
            type: String,
            default: () => ' py-2',
        },
        autocomplete: {
            default: false,
            type: Boolean,
        },
        asterisk: {
            default: false,
            type: Boolean,
        },
        selectFirst: {
            type: Boolean,
            default: false,
        },
        errorMessages: {
            default: () => [],
        },
        label: {
            type: [String, null],
            default: null,
        },
        subLabel: {
            type: [String, null],
            default: null,
        },
        labelClassVal: {
            type: String | null,
            default: null,
        },
        chevronIconStyleEnabled: {
            default: true,
        },
        value: {
            default: null,
        },
        placeholder: {
            type: [String, null, Boolean],
            default: 'Select',
        },
        forcePlaceholder: {
            default: null,
        },
        height: {
            default: '2.1875rem',
        },
        backgroundColor: {
            default: '#ffffff00',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disabledItems: {
            type: Function,
            default: null,
        },
        // appendIcon: { @todo
        //     type: String,
        //     default: '$dropdown'
        // },
        clearable: Boolean,
        clearableItem: {
            type: Boolean,
            default: false,
        },
        clearText: {
            type: String,
            default: "Clear",
        },
        items: {
            type: Array,
            default: () => [],
        },
        itemText: {
            type: [String, Function],
            default: 'name',
        },
        itemValue: {
            type: String,
            default: 'id',
        },
        itemPadding: {
            default: true,
        },
        itemClass: {
            default: '',
        },
        customClassActiveItem: {
            type: String,
            default: 'active',
        },
        absolute: Boolean,
        multiple: Boolean,
        returnObject: {
            type: Boolean,
            default: true,
        },
        // checkbox: {@todo
        //     required: false,
        //     type: Boolean,
        // },
        loadRoute: {
            default: null,
        },
        selectedItemStyle: {
            default: false
        },
        menuWidth: {
            default: null,
        },

        /*Search*/
        searchAutoload: {
            type: Boolean,
            default: true,
        },
        searchRoute: {
            type: String|null,
            default: null,
        },
        searchParams: {
            type: Object,
            default: () => {},
        },
        searchKey: {
            type: String,
            default: 'search',
        },
        maxLengthName: {
            type: Number,
            default: null,
        },
        classes:{
            type: String,
            default: '',
        },
        boxStyles: {
            type: Object,
            default: () => {},
        }
    },
    data() {
        return {
            localItems: this.items,
            loading: false,
            val: this.multiple && !this.value ? [] : this.value,
            open: false,
            autocompleteText: '',
            viewItemCount: 6,
            viewItemHeight: 40,

            viewBoxLeft: 0,
            viewBoxTop: 0,
            viewBoxWidth: 0,
            viewBoxTranslateX: 0,
            viewBoxTranslateY: 0,

            searchTimeout: null,
            oldScroll: 0
        };
    },
    computed: {
        selected() {
            return this.multiple
                ? this.localItems.filter(i => this.val.includes(i[this.itemValue]) && !this.checkDisabledItem(i))
                : this.localItems.find(i => i[this.itemValue] === this.val && !this.checkDisabledItem(i));
        },
        isSelected() {
            return this.multiple ? !!this.selected.length : !!this.selected;
        },
        visibleItems() {
            return this.autocomplete && this.autocompleteText && !this.searchRoute
                ? this.localItems.filter(i =>
                    this.toSearch(i[this.itemText]).includes(this.toSearch(this.autocompleteText))
                )
                : this.localItems;
        },
        scrollHeight() {
            return this.visibleItems.length > this.viewItemCount
                ? this.viewItemCount * this.viewItemHeight + 5
                : this.visibleItems.length * this.viewItemHeight;
        },
        viewBoxStyles() {
            return {
                left: `${this.viewBoxLeft}px`,
                top: `${this.viewBoxTop}px`,
                width: this.menuWidth? isNaN(this.menuWidth)? this.menuWidth: `${this.menuWidth}px` : `${this.viewBoxWidth}px`,
                transform: `translate(${this.viewBoxTranslateX}%, ${this.viewBoxTranslateY}%)`,
                position: this.absolute ? 'absolute' : 'fixed',
                ...this.boxStyles};
        },
        chevronIconStyle() {
            let style = this.chevronIconStyleEnabled ? 'font-size: 1rem;margin-right: 0.35rem;' : '';
            if (this.open) {
                style += 'transform: rotate(180deg);';
            }
            return style;
        },
    },
    watch: {
        items() {
            this.localItems = this.items;
        },
        val() {
            this.$emit('input', this.val);
            if (this.returnObject) {
                this.$emit('object', this.selected);
            }
        },
        value() {
            this.val = this.multiple && !this.value ? [] : this.value;
        },
        open() {
            if (this.open) {
                this.calcPosition();
                const el = document.querySelector(this.attach);
                if(el) {
                    el.append(this.$refs.BoxContent);
                }
            }
        },
        selected(){
            if (this.returnObject) {
                this.$emit('object', this.selected);
            }
        }
    },
    methods: {
        clickOutside(e){
            if (!this.attach){
                this.toggleOpen(false);
            }
        },
        showSelected(item) {
            return this.maxLengthName ?
                { ...item, name: item.name.length < this.maxLengthName
                        ? item.name
                        : item.name.substring(0, this.maxLengthName) + "..." }
                : { ...item, name: item.name};
        },
        toSearch(val) {
            return val ? val.replace(/ /g, '').toLowerCase() : '';
        },
        calcPosition() {
            this.viewBoxLeft = this.absolute ? 0 : this.$refs.Box.getBoundingClientRect().left;
            this.viewBoxTop = this.absolute ? 0 : this.$refs.Box.getBoundingClientRect().top;
            this.viewBoxWidth = this.$refs.Box.getBoundingClientRect().width;
            this.viewBoxTranslateY = this.absolute
                ? 0
                : this.viewBoxTop + this.scrollHeight > window.innerHeight
                    ? -100
                    : 0;
            this.viewBoxTranslateX = this.absolute
                ? 0
                : this.viewBoxLeft + this.viewBoxWidth > window.innerWidth
                    ? -100
                    : 0;
        },
        visibility(e) {
            if (!e) {
                this.open = false;
            }
        },
        toggleOpen(val = false) {
            this.open = val && (this.disabled || this.readonly) ? false : val;
        },
        toggleSelected(val) {
            if (this.multiple) {
                const index = this.val.findIndex(v => v === val);
                ~index ? this.val.splice(index, 1) : this.val.push(val);
            } else if (!this.checkSelected(val)) {
                this.val = val;
                this.toggleOpen(false);
            } else {
                this.toggleOpen(false);
            }
        },
        checkSelected(val) {
            return this.multiple ? this.val.includes(val) : this.val === val;
        },
        checkDisabledItem(item) {
            return this.disabledItems && this.disabledItems(item);
        },
        clear(val = null) {
            if (val && this.multiple) {
                const index = this.val.findIndex(v => v === val);
                if (~index) {
                    this.val.splice(index, 1);
                }
            } else {
                this.val = this.multiple ? [] : null;
            }
            this.toggleOpen(!this.clearableItem);
        },
        keyDownAutocomplete(e) {
            if (this.isSelected && !this.multiple && 'Backspace' !== e.key) {
                e.preventDefault();
            }
        },
        keyupAutocomplete(e) {
            const text = e.target.value;
            const oldText = this.autocompleteText;

            this.toggleOpen(true);

            if ('Backspace' === e.key) {
                /*Backspace*/
                if (text) {
                    this.autocompleteText = text;
                } else {
                    this.autocompleteText = '';
                    if (this.isSelected) {
                        this.multiple ? this.val.splice(this.val.length - 1, 1) : (this.val = null);
                    }
                }
            } else if (text && (!this.isSelected || this.multiple)) {
                /*Input*/
                this.autocompleteText = text;
            }

            /*Search by route*/
            if (this.searchRoute && oldText !== this.autocompleteText){
                if (this.searchTimeout){
                    clearTimeout(this.searchTimeout);
                }

                this.searchTimeout = setTimeout(() => this.search(), 800);
            }
        },
        load() {
            this.loading = true;
            window.axios
                .get(this.loadRoute)
                .then(response => {
                    this.loading = false;
                    this.localItems = response.data;
                })
                .catch(error => {
                    this.loading = false;
                    this.$store.commit('setAlert', { type: 'error', message: error.response.data.message });
                });
        },
        onScroll(e){
            if (this.searchRoute){
                const k = 20;
                const child = e.target.firstChild
                const childHeight = child? child.clientHeight: 0;
                const boxHeight = e.target.clientHeight;
                const scrolled = e.target.scrollTop;
                const scrollBottom = this.oldScroll < scrolled;

                if (scrollBottom && (childHeight < boxHeight + scrolled + k) && !this.loading){
                    this.search(true);
                }

                this.oldScroll = scrolled;
            }
        },
        isScrollable(ele) {
            const hasScrollableContent = ele.scrollHeight > ele.clientHeight;

            const overflowYStyle = window.getComputedStyle(ele).overflowY;
            const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

            return hasScrollableContent && !isOverflowHidden;
        },
        getScrollableParent(ele){
            return !ele || ele === document.body
                ? document
                : this.isScrollable(ele)
                    ? ele
                    : this.getScrollableParent(ele.parentNode);
        },
        hideOnScroll(){
            const scrollParent = this.getScrollableParent(this.$refs.Select);
            const vDialog = document.querySelector('.v-dialog');

            [scrollParent, vDialog].forEach(element => {
                if (element) element.addEventListener('scroll', () => this.toggleOpen(false));
            });
        },
        search(offset = false){
            if (this.searchTimeout){
                clearTimeout(this.searchTimeout);
            }

            this.loading = true;
            const params = {}

            for (const [key, value] of Object.entries(this.searchParams)) {
                params[key] = value;
            }

            if (this.autocompleteText){
                params[this.searchKey] = this.autocompleteText;
            }

            if (offset){
                params.offset = (Object.keys(this.searchParams).length && this.selected)?
                    this.multiple?
                        this.localItems.length - this.selected.length:
                        this.localItems.length - 1: this.localItems.length;
            }

            axios.get(this.searchRoute, {params: params})
                .then(response => {
                    this.loading = false;
                    const li = [...this.localItems];
                    const iv = this.itemValue;
                    const data = [...response.data];
                    this.localItems = offset?
                        li.concat(data.filter(i => !li.map(l => l[iv]).includes(i[iv]))):
                        data;
                })
                .catch(error => {
                    this.loading = false;
                    this.$store.commit('setAlert', {type: 'error', message: error.response.data.message});
                })
        },
        closeDropdown(){
            this.toggleOpen(false);
        }
    },
    mounted() {
        if (this.searchAutoload && this.searchRoute){
            this.search();
        }else if (this.loadRoute) {
            this.load();
        }
        this.$nextTick(() => {
            this.hideOnScroll();
            window.addEventListener('scroll', this.closeDropdown);
        })

    },
    created() {
        if (this.selectFirst && !this.value) {
            if (!this.items[0]) return;
            const firstItem = this.items[0];
            const keys = Object.keys(firstItem);
            let value = firstItem;
            if (keys.includes(this.itemValue)) {
                value = firstItem[this.itemValue];
            } else if (keys.includes(this.itemText)) {
                value = firstItem[this.itemText];
            }
            this.val = this.multiple ? this.val.push(value) : value;
        }
    },
};
</script>
<style scoped>
.nt-select-item {
    transition: 0.3s;
}

.nt-select-item:not(.nt-select-item-disabled):hover {
    background-color: rgba(0, 0, 0, 0.1);
}
.nt-select-item-disabled {
    cursor: default !important;
}
.nt-select-item.active {
    background-color: rgba(0, 0, 0, 0.1);
}
.selected-option-item {
    padding: 4px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
}
</style>
