export const state = {
  isIntroScreenShown: true,

  filters: {
    type: "",
    categories: [],
    search: "",
    country: null,
  },

  teamBenefitsFilters: {
    types: [],
    country: null,
    search: "",
    status: "all",
  },
};

export const mutations = {
  SET_IS_INTRO_SCREEN_SHOW(state, value) {
    state.isIntroScreenShown = value;
  },

  SET_FILTERS(state, filters) {
    state.filters = filters;
  },

  SET_TEAM_BENEFITS_FILTERS(state, filters) {
    state.teamBenefitsFilters = filters;
  },
};

export const actions = {
  setIsIntroScreenShown: ({ commit }, value) => {
    console.log('setIsIntroScreenShown', value)
    commit("SET_IS_INTRO_SCREEN_SHOW", value);
  },

  setFilters({ commit }, filters) {
    commit("SET_FILTERS", filters);
  },

  setTeamBenefitsFilters({ commit }, filters) {
    commit("SET_TEAM_BENEFITS_FILTERS", filters);
  },

  clearFilters({ commit }) {
    const filters = {
      type: "",
      categories: [],
      search: "",
      country: null,
    };
    commit("SET_FILTERS", filters);
  },

  clearTeamBenefitsFilters({ commit }) {
    const filters = {
      types: [],
      country: null,
      search: "",
      status: "all",
    };
    commit("SET_TEAM_BENEFITS_FILTERS", filters);
  },
};

export const getters = {
  isIntroScreenShown: (state) => state.isIntroScreenShown,

  types: (state) => state.types,

  categories: (state) => state.categories,

  filteredBenefits: (state) => {
    return state.benefits.filter((i) => {
      return (
        (i.type === state.filters.type || !state.filters.type) &&
        (i.name.toLowerCase().includes(state.filters.search.toLowerCase()) ||
          !state.filters.search) &&
        (i.country.name.toLowerCase() ===
          (state.filters.country?.toLowerCase() || "") ||
          !state.filters.country) &&
        (state.filters.categories.length === 0 ||
          state.filters.categories.includes(i.category))
      );
    });
  },

  filteredTeamBenefits: (state) => {
    return state.teamBenefits.filter((i) => {
      return (
        (state.teamBenefitsFilters.types.includes(i.benefit.type) ||
          !state.teamBenefitsFilters.types.length) &&
        (i.user.country.name.toLowerCase() ===
          (state.teamBenefitsFilters.country?.toLowerCase() || "") ||
          !state.teamBenefitsFilters.country) &&
        (i.user.name
          .toLowerCase()
          .includes(state.teamBenefitsFilters.search.toLowerCase()) ||
          !state.teamBenefitsFilters.search) &&
        (i.status.toLowerCase() ===
          state.teamBenefitsFilters.status.toLowerCase() ||
          state.teamBenefitsFilters.status === "all")
      );
    });
  },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
