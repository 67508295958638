import Middleware from "~/routes/middleware/Middleware";
import store from "~/store";

export default class checkResetPasswordToken extends Middleware{
    constructor(...args) {
        super(...args);
    }

    async resolve() {
        const token = this.to.query.t;
        const email = this.to.query.e;

        return await axios.post(store.state.path + 'auth/password/check-token', {email, token})
            .then(() => this.responseNext())
            .catch(() => this.responseRedirect())
    }
}
