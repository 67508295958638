import {loadStripe} from "@stripe/stripe-js";
import store from "~/store";

export default class stripeConfig{
    async resolve() {
        if (process.env.VUE_APP_STRIPE_KEY){
            window.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY, {canCreateRadarSession: true});
            window.stripe.createRadarSession().then(result => {
                result.error ?
                    console.error(result.error) :
                    store.commit('setData', {key: 'radarSessionId', data: result.radarSession.id})
            });
        }else {
            console.error('Stripe is not configured!')
        }
    }
}
