import init from "~/routes/middleware/global/init";
import resolveAuth from "~/routes/middleware/global/resolveAuth";
import resolveTfa from "~/routes/middleware/global/resolveTfa";
import guest from "~/routes/middleware/route/guest";
import auth from "~/routes/middleware/route/auth";
import nextPage from "~/routes/middleware/global/nextPage";
import Middleware from "~/routes/middleware/Middleware";
import tfaGuest from "~/routes/middleware/route/tfaGuest";
import checkInviteToken from "~/routes/middleware/route/checkInviteToken";
import verifyEmail from "~/routes/middleware/route/verifyEmail";
import eorInvoiceGuest from "~/routes/middleware/route/eorInvoiceGuest";
import changeOrganization from "~/routes/middleware/route/changeOrganization";
import taxAllowanceAccess from "~/routes/middleware/route/taxAllowancesAccess";
import checkResetPasswordToken from "~/routes/middleware/route/checkResetPasswordToken";
import importStyles from "~/routes/middleware/afterEnter/importStyles";
import trackVisitor from "~/routes/middleware/afterEnter/trackVisitor";
import documentTitle from "~/routes/middleware/afterEnter/documentTitle";
import initPage from "~/routes/middleware/global/initPage";
import invoiceGuestPay from "@/routes/middleware/route/invoiceGuestPay";
import paymentRequestGuestPay from "@/routes/middleware/route/paymentRequestGuestPay";
import tracking from "@/routes/middleware/route/tracking";
import businessAccount from "@/routes/middleware/route/businessAccount";
import clearPageData from "@/routes/middleware/global/clearPageData";
import eorSelfServe from "~/routes/middleware/route/eorSelfServe";

export default class Kernel {
    constructor(args) {
        this.args = args;
    }

    #global = [
        resolveAuth,
        resolveTfa,
        clearPageData,
        nextPage,
        init,
        initPage
    ]

    #route = {
        guest,
        auth,
        tfaGuest,
        checkInviteToken,
        checkResetPasswordToken,
        verifyEmail,
        eorInvoiceGuest,
        eorSelfServe,
        changeOrganization,
        taxAllowanceAccess,
        invoiceGuestPay,
        paymentRequestGuestPay,
        businessAccount,
        tracking
    }

    #afterEnter = [
        importStyles,
        trackVisitor,
        documentTitle
    ];

    #currents = () => typeof this.args?.to?.meta?.middleware === "string" ?
        [this.args.to.meta.middleware] :
        (this.args?.to?.meta?.middleware || [])

    #currentObjects = () => this.#currents().map(c => this.#route[c]).filter(m => m);

    #runGlobal = async () => {
        let result = new Middleware(this.args).responseNext();
        for (const m of this.#global) {
            result = await new m(this.args).resolve();
            if (result && !result.success) {
                return result;
            }
        }

        return result;
    }

    #runRoute = async () => {
        let result = new Middleware(this.args).responseNext();
        for (const m of this.#currentObjects()) {
            result = await new m(this.args).resolve();
            if (result && !result.success) {
                return result;
            }
        }
        return result;
    }

    runAfterEnter = () => {
        this.#afterEnter.forEach(m => new m(this.args).resolve());
    }

    run = async () => {
        const globalsResult = await this.#runGlobal();
        return globalsResult.success ?
            this.#runRoute() : globalsResult;
    }
}
