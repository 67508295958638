<template>
    <ModalInfo :modalData="modalData" @close="$emit('close')" @on-confirm="$emit('toDashboard')">
        <p class="mt-2 text-base color-grey-2 text-center mb-3">
            Thanks! We will notify you when you have passed this verification
            step.
        </p>

        <p class="text-center text-black font-medium">
            It takes 24-48 business hours to verify.
        </p>
        <template #underButton>
            <BaseButton class="btn-big btn-purple-outline w-100 mt-2" @click="$emit('close')"> Ok, close this </BaseButton>
        </template>
    </ModalInfo>
</template>
<script>
import ModalInfo from "~/shared/components/modals/ModalInfo";
export default {
    name: 'VerificationSuccess',
    components: {ModalInfo},
    data(){
        return {
            modalData: {
                icon: {
                    name: "check-circle-outline",
                    class: "color-feedback-success",
                },
                title: "ID Verification is now in progress",
                btnText: "Go to dashboard",
            }
        }
    },
}
</script>
