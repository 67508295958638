<!-- @format -->

<template>
    <div class="tabs-container position-relative" :class="{ 'vertical-tabs': vertical }">
        <ul class="nav-tabs" :class="marginBottomClass ?? ''">
            <li class="nav-item" v-for="tab in tabs" :key="tab.title" @click="selectTab(tab.localValue)">
                <BaseButton
                    style="font-size: 1rem; padding: 0 0.5rem 0.5rem;"
                    class="btn-tab text-base"
                    :class="tab.localValue === val ? 'color-purple selected fw-500' : 'color-grey-3 fw-400'"
                    @click="selectTab(tab)"
                    v-html="tab.titleWithLineBreak"
                >
                    {{ tab.title }}
                </BaseButton>
            </li>
            <div :class="customTopClasses ? customTopClasses : 'ml-auto'" v-if="$slots['top']">
                <slot name="top" />
            </div>
            <div class="ml-auto" v-if="$slots['total-costs']">
                <slot name="total-costs" />
            </div>
        </ul>
        <slot />
        <v-progress-linear
            ref="loader"
            class="tabs-loader"
            absolute
            bottom
            height="3px"
            v-show="loading"
            indeterminate
            :color="loadingColor"
            :background-color="loadingBgColor"
            :background-opacity="loadingBgOpacity"
        />
    </div>
</template>

<script>
import { cleanHtml } from '~/shared/utils/text-utils';
export default {
    name: "BaseTabs",

    props: {
        value: {
            default: 0,
        },
        vertical: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },

        loadingColor: {
            type: String,
            default: "#5152fb",
        },

        loadingBgColor: {
            type: String || null,
            default: null,
        },

        groupClass: {
            type: String || null,
            default: null,
        },

        itemClass: {
            type: String || null,
            default: null,
        },

        customTopClasses: {
            type: String,
            default: '',
        },

        marginBottomClass: {
            type: String,
            default: '',
        }
    },

    data() {
        return {
            val: this.value, // the index of the selected tab,
            tabs: [], // all the tabs
        };
    },

    computed: {
        activeIndex() {
            return this.tabs.findIndex((t) => t.localValue === this.val);
        },

        loadingBgOpacity() {
            if (!this.loadingBgColor) {
                return 0;
            }
            return 0.3;
        },

        tabsWithTitleLineBreak() {
            return this.tabs.map((tab) => {
                tab.titleWithLineBreak = cleanHtml(tab.title).replace(/\n/g, "<br>");
                return tab;
            });
        },
    },

    watch: {
        value() {
            this.selectTab(this.value);
        },
        val() {
            this.$emit("input", this.val);
        },
    },

    methods: {
        setValues() {
            this.tabs.forEach((tab, index) => {
                if (typeof tab.localValue === "undefined") {
                    tab.localValue = index;
                }
                tab.isActive = tab.localValue === this.val;
            });
        },
        selectTab(value) {
            const find = this.tabs.find((t) => t.localValue === value);

            if (find && !find.disabled) {
                this.val = value;
                this.tabs.forEach((t) => {
                    t.isActive = t.localValue === find.localValue;
                });
            }
        },
        next(enable = false) {
            const index = this.activeIndex + 1;
            if (this.tabs[index] && (!this.tabs[index].disabled || enable)) {
                this.tabs[index].disabled = false;
                this.selectTab(this.tabs[index].localValue);
            }
        },
        back() {
            const index = this.activeIndex - 1;
            if (this.tabs[index] && !this.tabs[index].disabled) {
                this.selectTab(this.tabs[index].localValue);
            }
        },
    },

    mounted() {
        this.$children.forEach((el) => {
            if (el.$options.name === "BaseTab") {
                this.tabs.push(el);
            }
        });
        this.setValues();
        this.$emit("setTabs", this.tabs);
        this.tabsWithTitleLineBreak;
    },
};
</script>
