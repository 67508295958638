// src/store/mixins/commonStates.js
export const commonStates = {
    imagePath: '/images/new/',
    loadImg: '/images/output-onlinegiftools.gif',
    loader: false,
    alert: {
        value: false,
        type: null,
        message: null,
    },
    alertModal: {
        value: false,
        type: null,
        data: {
            title: '',
            text: '',
            btnText: 'Close',
            icon: { name: 'check-circle-outline', class: 'color-feedback-error' },
        },
        onClose: (() => { }),
    },
    authUser: {},
    window: {
        height: 0,
        width: 0,
    },
}