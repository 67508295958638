<!-- @format -->
<template>
    <div class="id-verification-step-wrapper overflow-y-auto" style="height: 82vh">
        <div v-show="!loading" id="sumsub-websdk-container"></div>
        <div v-if="loading" class="position-absolute w-100 h-100" style="top: 0; left: 0;">
            <div class="preloader-verify"></div>
        </div>
    </div>
</template>

<!--suppress JSUnusedGlobalSymbols -->
<script>
import snsWebSdk from '@sumsub/websdk';
import {mapActions, mapMutations} from "vuex";

export default {
    name: 'KYCSumsub',
    props: {},
    data() {
        return {
            loading: false
        };
    },
    computed: {
        email() {
            return this.$store.state.dashboard?.authent?.info?.email;
        },
        phone() {
            return this.$store.state.dashboard?.authent?.info?.phone;
        },
        url() {
            return this.$store.state.basePath + 'verification/sumsub-data';
        },
    },
    methods: {
        ...mapActions('kyc', ['__setKYCData']),
        checkComplete(){
            this.loading = true;
            axios.get(this.$store.state.basePath + 'verification/check-complete')
                .then(response => {
                    this.makeCompleted(response.data);
                    this.loading = false;
                })
                .catch(error => {
                    this.$store.commit("SET_ALERT", {type: 'error', message: error.response.data.message});
                    this.loading = false;
                })
        },
        makeCompleted(data){
            const tData = {
                verified: data.verified,
                options: data.verificationOptions,
                restrictionType: data.restrictionType
            }
            this.__setKYCData(tData);
        },
        launch(accessToken, applicantEmail, applicantPhone = null) {
            const snsWebSdkInstance = snsWebSdk
                .init(accessToken,
                    // token update callback, must return Promise
                    // Access token expired
                    // get a new one and pass it to the callback to re-initiate the WebSDK
                    () => this.getNewAccessToken(),
                )
                .withConf({
                    lang: 'en', //language of WebSDK texts and comments (ISO 639-1 format)
                    email: applicantEmail,
                    phone: applicantPhone,
                    uiConf: {
                        // customCss: 'https://url.com/styles.css',
                        // URL to css file in case you need change it dynamically from the code
                        // the similar setting at Customizations tab will rewrite customCss
                        // you may also use to pass string with plain styles `customCssStr:`
                    },
                    onMessage: (type, payload) => {
                        // console.log('WebSDK onMessage', type, payload)
                    },
                    onError: (error) => {
                        console.error('WebSDK onError', error)
                    },
                })
                .withOptions({ addViewportTag: false, adaptIframeHeight: true })
                // see below what kind of messages WebSDK generates
                .on('idCheck.onApplicantStatusChanged', payload => {
                    if (
                        payload.reviewStatus === 'pending' ||
                        (payload.reviewStatus === 'completed' && payload.reviewResult.reviewAnswer === 'GREEN')
                    ) {
                        this.checkComplete()
                    }
                })
                .onMessage((type, payload) => {

                })
                .on('idCheck.onApplicantLoaded', payload => {

                })
                .build();
            snsWebSdkInstance.launch('#sumsub-websdk-container');
        },
        getNewAccessToken() {
            return new Promise((resolve, reject) => {
                this.loading = true;
                const params = {kyc_level: this.$store.state.kyc.modalOptions?.level || null}
                axios.get(this.url, {params: params})
                    .then(response => {
                        this.loading = false;
                        if (response.data.token){
                            resolve(response.data.token);
                        }else if (response.data.continue){
                            this.checkComplete();
                        }else{
                            this.$store.commit("SET_ALERT", {type: 'error', message: 'Oops! Something went wrong.'});
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        reject(error.response.data);
                    })
            })
        },
        async initForm() {
            const token = await this.getNewAccessToken();
            this.launch(token, this.email, this.phone);
        },
    },
    mounted() {
        this.initForm();
    },
};
</script>

<style scoped>
.preloader-verify {
    top: 0;
    left: 0;
    background-color: #333333;
    background-image: url(_/public/images/graphics/logo-animation.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 7rem!important;
    height: 100%;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    z-index: 99999;
}
</style>
