import { serialize } from 'object-to-formdata';
import Model from '~/shared/Model';

export default class OwnEntity extends Model{

    constructor(obj = {}) {
        super();

        this.hidden = ['id', 'logo']

        this.values = {
            id: obj.id || null,
            name: obj.name || null,
            email: obj.email || '',
            address: obj.address || null,
            city: obj.city || '',
            zip_code: obj.zip_code || '',
            country_id: obj.country_id || null,
            company_type: obj.company_type > -1 ? obj.company_type : null,
            vat_number: obj.vat_number || '',
            phone_number: obj.phone_number || null,
            vat_number: obj.vat_number || '',
            bank_name: obj.bank_name || null,
            bank_account_number: obj.bank_account_number || null,
            iban: obj.iban || null,
            swift: obj.swift || null,
            payroll_number: obj.payroll_number || null,

            contracts_files: undefined,

            logo: obj.logo || '',
            logo_file: undefined,

            members: obj.members || [],
        };

        this.rules = {
            name: 'required|min:3|max:255',
            email: 'required|email|min:3|max:255',
            address: 'required|min:3|max:255',
            country_id: 'required',
            city: 'min:1|max:30',
            zip_code: 'min:3|max:20',
            company_type: 'required',
            vat_number: 'numeric|min:3|max:30',
            phone_number: 'required|numeric',
            bank_name: 'required|min:3|max:50',
            bank_account_number: 'required|numeric|min:3|max:50',
            iban: 'required|min:3|max:50',
            swift: 'required|min:3|max:50',
            payroll_number: 'required|min:3|max:30',
            contracts_files: 'ext:doc,docx,pdf|max:10485760',
        };
    }

    removeDocument(index) {
        if (index > -1) {
            this.values.contracts_files.splice(index, 1);
        }
    }

    getId() {
        return this.values.id;
    }

    hasMembers() {
        return Array.isArray(this.values.members) && this.values.members.length > 0;
    }

    getMembers() {
        return this.values.members;
    }

    getMember() {
        return this.member.values;
    }

    hasContracts() {
        return Array.isArray(this.values.contracts_files) && this.values.contracts_files.length > 0;
    }

    getContracts() {
        return this.serializeData({ contracts_files: this.values.contracts_files }, false);
    }

    getDetails() {
        return this.serializeData({
            id: this.values.id,
            name: this.values.name,
            email: this.values.email,
            country_id: this.values.country_id,
            company_type: this.values.company_type,
            vat_number: this.values.vat_number,
            phone_number: this.values.phone_number,
            logo_file: this.values.logo_file,
        });
    }

    getAddress() {
        return this.serializeData({
            id: this.values.id,
            address: this.values.address,
            city: this.values.city,
            zip_code: this.values.zip_code,
            country_id: this.values.country_id,
        });
    }

    getBankDetails() {
        return this.serializeData({
            id: this.values.id,
            bank_name: this.values.bank_name,
            bank_account_number: this.values.bank_account_number,
            iban: this.values.iban,
            swift: this.values.swift,
            payroll_number: this.values.payroll_number,
        });
    }

    serializeData(data, addPutMethod = true) {
        return serialize(Object.assign(addPutMethod ? {_method: 'PUT'} : {}, data), { indices: true });
    }

    clearContractsList() {
        this.values.contracts_files = undefined;
    }
}
