import Model from '~/shared/Model';

export default class NativeCardRequest extends Model{

    constructor(obj = {}) {
        super();

        this.hidden = ['id']

        this.values = {
            id: obj.id || null,
            name: obj.name || '',
            gender: obj.gender || '',
            first_address: obj.first_address || '',
            second_address: obj.second_address || '',
            country_id: obj.country_id || '',
            currency_id: obj.currency_id || '',
            organization_id: obj.organization_id || '',
            city: obj.city || '',
            post_code: obj.post_code || '',
            phone: obj.phone? obj.phone.replace(/[^0-9]+/g, ""): '',
            birthday: obj.birthday || '',
            for_worker: obj.for_worker || null
        }

        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();

        let startDate = new Date();
        let fromDate = startDate.setFullYear((currentYear - 125));
        let fromStr = (new Date(fromDate)).toISOString().split('T')[0];

        let endDate = new Date();
        let toDate = endDate.setFullYear((currentYear - 18));
        let toStr = (new Date(toDate)).toISOString().split('T')[0];

        let birthdayValidation = 'required|date_format:yyyy-MM-dd|date_between:' + fromStr + ',' + toStr;

        this.rules = {
            //name: 'required|max:255',
            gender: 'required',
            first_address: 'required|max:255',
            second_address: 'max:255',
            country_id: 'required|max:255',
            currency_id: 'required',
            city: 'required|max:255',
            post_code: 'required|max:255',
            phone: 'required|max:255',
            birthday: birthdayValidation
        };
    }

}
