<template>
  <div class="base-carousel">
    <div class="slider-header">
      <p class="text-base font-medium">{{ title }}</p>
      <div class="arrows">
        <BaseButton
          :ripple="false"
          class="arrow-btn"
          :disabled="model <= 0"
          @click="model--"
        >
          <BaseIcon name="chevron-left" />
        </BaseButton>
        <BaseButton
          :ripple="false"
          class="arrow-btn"
          :disabled="model >= length"
          @click="model++"
        >
          <BaseIcon name="chevron-right" />
        </BaseButton>
      </div>
    </div>
    <v-carousel v-model="model" height="auto" :show-arrows="false" ref="slider">
      <slot></slot>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: "BaseCarousel",

  props: {
    title: {
      type: String,
    },
  },

  data() {
    return {
      model: 0,
      length: 0,
    };
  },

  created() {
    this.$nextTick(() => {
      let children = this.$refs.slider.$children.filter(
        (child) => child.$options.name === "v-carousel-item"
      );
      this.length = children.length - 1;
    });
  },
};
</script>
