<template>
    <div class="base-filter-wrapper">
        <v-menu v-model="menu" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="btn btn-filter btn-small btn-circle text-small color-dark-grey"
                    v-bind="attrs"
                    v-on="on"
                >
                    <BaseIcon
                        name="plus-circle-filled"
                        class="color-dark-grey fs-17 mr-1"
                    />
                    <span>{{ title }}</span>

                    <template v-if="multiple && selected.length">
                        <span
                            v-for="(item, index) in selected"
                            :key="index"
                            class="selected-filter font-medium color-purple ml-2"
                        >
                            <base-avatar v-if="avatar" size="0.9375rem" :object="item"></base-avatar>
                            <span>
                                 {{ typeof itemText === 'function'? itemText(item): __findByPath(item, itemText) }}
                            </span>
                            <BaseIcon
                                name="chevron-down-filled"
                                class="text-mini color-purple ml-2 mt-1"
                            />
                        </span>
                    </template>
                    <span
                        class="selected-filter font-medium color-purple ml-2"
                        v-else-if="!multiple && selected"
                    >
                        <base-avatar v-if="avatar" size="0.9375rem" :object="selected"></base-avatar>
                        <span>
                             {{ typeof itemText === 'function'? itemText(selected): __findByPath(selected, itemText) }}
                        </span>
                        <BaseIcon
                            name="chevron-down-filled"
                            class="text-mini color-purple ml-2 mt-1"
                        />
                    </span>

                </v-btn>
            </template>
            <div class="clear-filter-wrapper">
                <BaseButton
                    v-if="clearable && isSelected"
                    type="button"
                    class="btn-text text-small font-medium color-dark-pink"
                    @click="clear()"
                >
                    Clear filter
                </BaseButton>
            </div>
            <div v-if="search || searchRoute" class="search-filter-wrapper py-0">
                <BaseInput
                    height="1.5625rem"
                    :bordered="false"
                    placeholder="Search"
                    v-model="searchQuery"
                >
                    <template slot="prepend">
                        <BaseIcon name="search" class="color-purple"/>
                    </template>
                </BaseInput>
            </div>
            <div @scroll="scroll" style="max-height: 400px; overflow-y: auto">
                <ul ref="List" class="filter-list-options">
                    <li
                        v-for="(item, index) in localItems"
                        :key="index"
                        class="filter-item"
                        :class="checkSelected(item)? 'active': ''"
                        @click="checkSelected(item)? '' : select(item)"
                    >
                        <slot v-if="$scopedSlots['item']" name="item" v-bind="{item, index}"></slot>
                        <template v-else>
                            <base-avatar v-if="avatar" size="0.9375rem" :object="item"></base-avatar>
                            <span class="text-small color-black">
                                {{ typeof itemText === 'function'? itemText(item): __findByPath(item, itemText) }}
                            </span>
                        </template>
                    </li>
                </ul>
            </div>
        </v-menu>
    </div>
</template>

<script>
import Form from '~/shared/mixins/Form';
export default {
    name: "BaseFilter",
    mixins: [Form],
    props: {
        value: {
            default: null
        },
        multiple: {
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        searchKeys: {
            type: Array,
            default: () => ['name']
        },
        itemText: {
            type: String|Function,
            default: 'name'
        },
        itemValue: {
            type: String,
            default: 'id'
        },
        items: {
            type: Array,
            default: () => []
        },
        avatar: {
            type: Boolean,
            default: false
        },
        search: {
            type: Boolean,
            default: true
        },
        searchRoute: {
            type: String|null,
            default: null
        },
        searchAdditionalParams: {
            type: Object,
            default: () => {}
        },
        clearable: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            searchQuery: null,
            val: this.multiple && !this.value? []: this.value,
            menu: false,
            loading: false,
            findItems: []
        };
    },

    computed: {
        localItems(){
            return this.searchRoute? this.findItems:
                this.searchQuery? this.items.filter(i => this.checkObj(i)):
                    this.items;
        },

        selected(){
            const arr = this.searchRoute? this.findItems: this.items;
            return this.multiple?
                arr.filter(i => this.val.includes(i[this.itemValue])):
                arr.find(i => i[this.itemValue] === this.val);
        },

        isSelected(){
            return this.multiple? !!this.val.length: !!this.val;
        }
    },

    watch: {
        val(){
            this.$emit('input', this.val);
        },
        value(){
            this.val = this.value;
        },
        searchQuery(){
            if (this.searchRoute){
                this.searchByRoute();
            }
        }
    },

    methods: {
        checkObj(obj){
            return this.searchKeys.find(k =>
                this.searchFormat(obj[k]) &&
                this.searchFormat(obj[k]).includes(this.searchFormat(this.searchQuery))
            );
        },

        searchFormat(str){
            return typeof str === 'string'?
                str.toLowerCase().trim():
                typeof str === 'number'? str.toString(): null;
        },

        select(item) {
            this.multiple? this.val.push(item[this.itemValue]): this.val = item[this.itemValue];
            this.searchQuery = null;
            this.menu = !!this.multiple;
        },

        clear() {
            this.val = this.multiple? []: null;
            this.searchQuery = null;
            this.menu = false;
        },

        checkSelected(item){
            return this.multiple? this.val.includes(item[this.itemValue]): this.val === item[this.itemValue];
        },

        searchByRoute(offset = 0){
            let params = !offset? {...this.searchAdditionalParams}: {};
            params.value = this.searchQuery;
            params.offset = offset;
            this.loading = true;
            axios.get(this.searchRoute, {params: params})
                .then(response => {
                    this.loading = false;

                    if (offset){
                        response.data.forEach(i => {
                            if (!this.findItems.find(f => f[this.itemValue] === i[this.itemValue])){
                                this.findItems.push(i);
                            }
                        })
                    }else{
                        this.findItems = response.data;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$store.commit('setAlert', {type: 'error', message: error.response.data.message});
                })
        },

        scroll(e){
            if (
                this.searchRoute &&
                !this.loading &&
                this.findItems.length &&
                e.target.scrollTop + e.target.clientHeight > this.$refs.List.clientHeight - 50
            ){
                this.searchByRoute(this.findItems.length)
            }
        }
    },
    mounted() {
        if (this.searchRoute){
            this.searchByRoute();
        }
    }
};
</script>
