<template>
    <div class="paypal">
        <div class="payment-option-details-paypal">
            <div class="mb-30">
                <h5 class="text-center heading-1 bold-text">
                    Add money via Paypal
                </h5>
                <p class="text-center medium-text color-dark-grey-6 lh-120">
                    Find instructions below
                </p>
            </div>
            <div class="card-group rounded-5 mb-30">
                <div class="card mb-0 pa-30">
                    <div class="card-body">
                        <div class="paypal-info w-100">
                            <div class="paypal-hide">
                                <div class="rounded-5 mb-20">
                                    <p class="small-text color-red-light mb-15">
                                        IMPORTANT NOTE:
                                    </p>
                                    <p class="small-text mb-7">
                                        Please add the name and note of who is sending the
                                        money so we know where to add.
                                    </p>
                                    <p class="small-text mb-15">
                                        If you or your client sends money via Paypal with no
                                        note, it will take much longer for us to transfer
                                        money.
                                    </p>
                                </div>
                                <div class="bg-grey-2 rounded-5 border-grey mb-20">
                                    <div class="p-3">
                                        <div class="modal-form form-paypal-fill">
                                            <tm-input
                                                :add-styles="{background: 'white'}"
                                                label="Name of Sender"
                                                placeholder="e.g. Milan Tomic"
                                                v-model="obj.values.paypal.name"
                                                v-validate="obj.rules.paypal.name"
                                                name="name"
                                                data-vv-as="Name"
                                                :error-messages="errors.collect('name')"
                                            >
                                            </tm-input>
                                            <tm-text-area
                                                :add-styles="{background: 'white'}"
                                                label="Note"
                                                placeholder="Something goes here"
                                                v-model="obj.values.paypal.note"
                                                v-validate="obj.rules.paypal.note"
                                                name="note"
                                                data-vv-as="Note"
                                                :error-messages="errors.collect('note')"
                                            >
                                            </tm-text-area>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TmInput from "~/shared/components/form/TmInput";
import TmTextArea from "~/shared/components/form/TmTextArea";
export default {
    name: 'AddByPaypal',
    components: {TmTextArea, TmInput},
    props: {
        obj: {
            required: true,
            type: Object
        }
    },
}
</script>
