export default {
    computed: {
        __inputPlaceholder(){
            const force = this.$attrs['force-placeholder'] || this.forcePlaceholder;
            const name = this.$attrs['data-name'] || this.$attrs['data-vv-name'] || this.$attrs.name || this.name;
            const type = this.$attrs.type || this.type;

            /*Force placeholder case*/
            if (force){
                return force;
            }

            /*No placeholder case*/
            if (this.placeholder === false){
                return '';
            }

            /*Filter case*/
            if (this.$attrs['data-type'] === 'filter'){
                return `Filter by ${this.placeholder}`
            }

            /*Search case*/
            if (this.search || this.__checkKey(this.label, 'search') || this.__checkKey(name, 'search')){
                return 'Search';
            }

            /*Amount case*/
            if (this.__checkKey(name, ['amount', 'salary', 'price'])){
                return '0.00';
            }

            /*Date case*/
            if (this.__checkKey(name, 'date')){
                return 'Select date';
            }

            /*Phone case*/
            if (this.__checkKey(name, 'phone')){
                return '66 1234 567';
            }

            /*ZIP case*/
            if (this.__checkKey(name, ['zip', 'post'])){
                return '11000';
            }

            /*Iban case*/
            if (this.__checkKey(name, ['iban', 'account_number'])){
                return 'AA1234567890';
            }

            /*SWIFT case*/
            if (this.__checkKey(name, 'swift')){
                return 'DBDBRSBG123';
            }

            /*VAT case*/
            if (this.__checkKey(name, 'vat') && !this.__checkKey(name, 'vat_number')){
                return 'Type here';
            }

            /*Card number case*/
            if (this.__checkKey(name, 'card_number')){
                return '1234 5678 1234 5678';
            }

            /*CVV case*/
            if (this.__checkKey(name, ['cvv', 'cvc'])){
                return '111';
            }

            /*Numeric case*/
            if (this.__checkKey(type, 'number') && !this.__checkKey(name, 'phone')){
                return '1';
            }

            /*No label case*/
            if (!this.label){
                return this.placeholder || 'Type here';
            }

            /*Default case*/
            return 'Type here';
        },

        __selectPlaceholder(){
            const force = this.$attrs['force-placeholder'] || this.forcePlaceholder;

            /*Force placeholder case*/
            if (force){
                return force;
            }

            /*Filter case*/
            if (this.$attrs['data-type'] === 'filter'){
                return `Filter by ${this.placeholder}`
            }

            /*No placeholder case*/
            if (this.placeholder === false){
                return '';
            }

            /*No label case*/
            if (!this.label){
                return this.placeholder || 'Select';
            }

            /*Card case*/
            if (this.__checkKey(this.$attrs.name, 'card')){
                return 'Select card';
            }

            if (this.placeholder){
                return this.placeholder;
            }
            /*Default case*/
            return 'Select';
        },
    },
    methods: {
        __checkKey(val, key){
            return !!(typeof val === 'string' && Array.isArray(key)?
                key.find(k => val.toLowerCase().includes(k.toLowerCase())):
                typeof val === 'string' && typeof key === 'string' && val.toLowerCase().includes(key.toLowerCase()));
        },
        __findByPath(obj, path){
            let paths = path.split('.'), current = obj, i;

            for (i = 0; i < paths.length; ++i) {
                if (!current || current[paths[i]] === undefined) {
                    return undefined;
                } else {
                    current = current[paths[i]];
                }
            }
            return current;
        }
    }
}
