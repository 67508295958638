<template>
    <v-row style="margin:0px !important">
        <v-col cols="12" lg="2" class="modal-onboarding-progress step-3-active">
            <img :src="$store.state.imagePath+'logo-tilted-o-5-big.png'" alt="logo-tilted-o-5-big.png"/>
        </v-col>
        <v-col cols="12" lg="10" class="modal-onboarding-content">
            <div class="modal-header">
                <h2 class="heading-2 bold-text color-black mb-10 mt-10">
                    Welcome to
                    <span class="strong-text color-purple">Native Teams!</span>
                </h2>
                <p class="normal-text color-dark-grey">
                    Let us know about yourself, and we’ll personalise your Native Teams experience to suit your needs perfectly.
                </p>
            </div>
            <div class="modal-body">
                <div class="where-step onboarding-modal-step step-3 active">
                    <form class="onboarding-form">
                        <div class="form-group">
                            <label class="medium-text color-dark-grey mb-3">3. WHERE ARE YOU FROM?</label>
                            <div class="flex-form-group mt-1">
                                <h2 class="heading-2 light-weight color-black">
                                    I am from
                                </h2>
                                <tm-advanced-select
                                    name="country"
                                    v-validate="greetingObj.rules.country_id"
                                    v-model="greetingObj.values.country_id"
                                    item-value="id"
                                    :error-messages="errors.collect('country')"
                                    class="transparent-select"
                                    search-key="name"
                                    :search="true"
                                    :search-autoload="true"
                                    :search-route="$store.state.getPath+'search-countries'"
                                    :search-additional-params="{id: greetingObj.values.country_id}"
                                    data-vv-as="country"
                                    :bottomOffset="false"
                                >
                                    <template v-slot:selection="{ item }">
                                        <div class="d-flex align-center">
                                            <avatar size="20" :object="item"></avatar>
                                            <span class=" tm-color-black mr-1">{{ item.name }}</span>
                                        </div>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <div class="d-flex align-center">
                                            <avatar size="20" :object="item"></avatar>
                                            <span class=" tm-color-black mr-1">{{ item.name }}</span>
                                        </div>
                                    </template>
                                </tm-advanced-select>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer d-flex justify-space-between pr-0 pl-0" style="margin-right:-10px;">
                <button @click="$emit('back')" class="btn btn-onboarding-back btn-outline-standard medium-text bold-text tm-color-dark-grey">
                    Go back
                </button>
                <div class="d-flex">
                    <button
                            @click="$emit('skip')"
                            class="btn btn-onboarding-back btn-outline-purple medium-text bold-text color-purple mr-10">
                        Skip
                    </button>
                    <button
                            :disabled="!isValid"
                            @click="$emit('next')"
                            class="btn btn-onboarding-skip btn-purple medium-text bold-text color-white">
                        Next
                    </button>
                </div>
            </div>
        </v-col>
    </v-row>
</template>
<script>
import TmInput from "~/shared/components/form/TmInput";
import TmAdvancedSelect from "~/shared/components/form/TmAdvancedSelect";
import Avatar from "~/shared/components/Avatar";
import Btn from "~/shared/components/Btn";
export default {
    name: 'BasedLocation',
    components: {Btn, Avatar, TmInput, TmAdvancedSelect},
    props: {
        greetingObj: {
            required: true,
            type: Object
        },
    },
    data(){
        return {}
    },
    computed: {
        isValid() {
            return !Object.keys(this.fields).some(key => this.fields[key].invalid)
        }
    },
}
</script>
