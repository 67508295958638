import { mapGetters } from "vuex";

export const commonHelpersMixin = {
  computed: {
    ...mapGetters("screen", ["window"]),

    isDesktop() {
      return this.$store.state.window.width > 992;
    },

    isScreen1550To992() {
      return this.$store.state.window.width <= 1550 && this.$store.state.window.width >= 992;
    },

    isScreen1550To992AndHeightLessThan840() {
      return (
        this.$store.state.window.width <= 1550 &&
        this.$store.state.window.width > 992 &&
        this.$store.state.window.height < 840
      );
    },

    isScreenBiggerThan1550() {
      return this.$store.state.window.width > 1550;
    },

    isScreen768To992() {
      return this.$store.state.window.width <= 991 && this.$store.state.window.width > 767;
    },

    isScreen768To1199() {
      return this.$store.state.window.width <= 1199 && this.$store.state.window.width > 767;
    },

    isScreenBiggerThan1199() {
      return this.$store.state.window.width > 1199;
    },

    isScreenBiggerThan1024() {
      return this.$store.state.window.width >= 1024;
    },

    isBigTablet() {
      return this.$store.state.window.width <= 992;
    },

    isSmallTablet() {
      return this.$store.state.window.width < 768;
    },

    isLargeMobile() {
      return this.$store.state.window.width <= 425;
    },

    isScreen641To992() {
      return this.$store.state.window.width <= 992 && this.$store.state.window.width >= 641;
    },

    isMobile() {
      return this.$store.state.window.width <= 640;
    },
  },
};
