const state = {
    bulkTableData: [],
    uploadedFiles: [],

    isTableValid: true,

    isTableLoading: false,
};

const mutations = {
    SET_UPLOADED_FILES(state, uploadedFiles) {
        state.uploadedFiles = uploadedFiles;
    },

    SET_BULK_DATA(state, bulkTableData) {
        state.bulkTableData = bulkTableData;
    },

    SET_TABLE_LOADING(state, isTableLoading) {
        state.isTableLoading = isTableLoading;
    },

    SET_TABLE_VALID(state, isTableValid) {
        state.isTableValid = isTableValid;
    },
};

const actions = {
    setUploadedFiles({ commit }, uploadedFiles) {
        commit("SET_UPLOADED_FILES", uploadedFiles);

        if (!uploadedFiles.length) {
            commit("SET_BULK_DATA", []);
        }
    },

    setBulkData({ commit }, bulkTableData) {
        commit("SET_BULK_DATA", bulkTableData);
    },

    setTableLoading({ commit }, isTableLoading) {
        commit("SET_TABLE_LOADING", isTableLoading);
    },

    setTableValid({ commit }, isTableValid) {
        commit("SET_TABLE_VALID", isTableValid);
    },
};

const getters = {
    getUploadedFiles: (state) => {
        return state.uploadedFiles;
    },

    getBulkData: (state) => {
        return state.bulkTableData;
    },

    getTableLoading: (state) => {
        return state.isTableLoading;
    },

    getTableValid: (state) => {
        return state.isTableValid;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
