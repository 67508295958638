<!-- @format -->
<template>
    <div class="id-verification-step-wrapper overflow-y-auto" style="height: 82vh">
        <div v-show="!loading" id="sumsub-websdk-container"></div>
        <div v-if="loading" class="position-absolute w-100 h-100" style="top: 0">
            <div class="preloader-verify"></div>
        </div>
    </div>
</template>

<!--suppress JSUnusedGlobalSymbols -->
<script>
import snsWebSdk from '@sumsub/websdk';

export default {
    name: 'SumSub',
    props: {
        recipientModel: {
            type: Object,
            default: null,
        },
        loadingVal: {
            default: false
        },
    },
    data() {
        return {
            loading: this.loadingVal,
            reloadForm: false,
        };
    },

    methods: {
        launch(accessToken, applicantEmail, applicantPhone = null) {
            const snsWebSdkInstance = snsWebSdk
                .init(accessToken,() => this.getNewAccessToken())
                .withConf({
                    lang: 'en', //language of WebSDK texts and comments (ISO 639-1 format)
                    email: applicantEmail,
                    phone: applicantPhone,
                    uiConf: {},
                    onMessage: (type, payload) => {
                        // console.log('WebSDK onMessage', type, payload)
                    },
                    onError: (error) => {
                        console.error('WebSDK onError', error)
                    },
                })
                .withOptions({ addViewportTag: false, adaptIframeHeight: true })
                // see below what kind of messages WebSDK generates
                .on('idCheck.onApplicantStatusChanged', payload => {
                    if (
                        payload.reviewStatus === 'pending' ||
                        (payload.reviewStatus === 'completed' && payload.reviewResult.reviewAnswer === 'GREEN')
                    ) {
                        this.$emit('continue')
                    }
                })
                .build();
            // you are ready to go:
            // just launch the WebSDK by providing the container element for it
            snsWebSdkInstance.launch('#sumsub-websdk-container');
        },
        getNewAccessToken() {
            return new Promise((resolve, reject) => {
                this.loading = true;
                const url = this.sumsubDataurl;
                const params = {kyc_level: this.$store.state?.kyc.modalOptions?.level || null}
                axios.get(url, {params: params})
                    .then(response => {
                        this.loading = false;
                        if (response.data.token){
                            resolve(response.data.token);
                        }else if (response.data.continue){
                            this.$emit('continue');
                        }else{
                            this.$store.commit("setAlert", {type: 'error', message: 'Oops! Something went wrong.'});
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        reject(error.response.data);
                    })
            })
        },
        requestForced() {
            axios.post(this.$store.state.path + 'request-iban-number', {
                wallet_id: this.$store.state.selectedWallet ? this.$store.state.selectedWallet.id : this.$store.state.defaultWallet.id,
            }).then(response => {
                this.$store.commit("setAlert", { type: 'success', message: 'Iban request will be recorded after the verification' });
            }).catch(error => {
                this.$store.commit("setAlert", { type: 'info', message: error.response.data.message + ' Please finish the verification first.' });
            })
        },

        async initForm() {
            const token = await this.getNewAccessToken();
            this.launch(token, this.sumsubEmail, this.sumsubPhone);
        },
    },

    computed: {
        sumsubEmail() {
            return this.recipientModel ? this.recipientModel.recipient.email : this.$store.state.info.email;
        },
        sumsubPhone() {
            return this.recipientModel ? this.recipientModel.recipient.phone : this.$store.state.info.phone;
        },
        sumsubDataurl() {
            return this.recipientModel ?
                this.$store.state.path + 'smsb-invoice/' + (this.recipientModel.recipient_type.match(/TmWorker$/) ? 'worker/' : '') + 'data/' + this.recipientModel.recipient.id :
                this.$store.state.path + 'verification/sumsub-data';
        },
    },

    mounted() {
        this.initForm();
    },

    watch: {
        loadingVal(){
            this.loading = this.loadingVal;
        },
    }
};
</script>
