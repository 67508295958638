import router from "./index";
import {__initResource} from "~/utils/functions";
function redirectToDashboard() {
    router.push('/').then(() => {})
}

function returnResourceByName(data, name) {
    return __initResource(data, name);
}

function setSelectedWalletFunction(store, to, next) {
    store.dispatch("dashboard/setSelectedWallet", to.params.walletId)
        .then(() => store.dispatch("dashboard/clearWalletFilters", null).then(() => next()))
        .catch((error) => console.error("Error setting selected  wallet:", error));
}

function setSelectedVoultFunction(store, to, next) {
    store
        .dispatch("dashboard/setVaultWallet", to.params.vaultId)
        .then(() => store.dispatch("dashboard/clearVaultFilters", null).then(() => next()))
        .catch((error) => console.error("Error setting selected  wallet:", error));
}

function getWalletStatementData(store, to, next, wallet){
    store.dispatch("dashboard/getWalletStatementData", wallet)
        .then(() => setSelectedWalletFunction(store, to, next))
}
export function voultSetActiveCurrency(store, to, from, next) {
    store.dispatch('initialization/__initialize', {
        url: `/initialization`,
        resources: [
            'AllowedVoultCurrencies',
            'VaultWallets',
            'HasVaultAccess',
            'VaultTotalData',
            'RewardStatuses',
        ],
        additionalParams: {
            currency: to.params.vaultId
        },
    }).then(res => {
        let allowedVaultCurrencies = returnResourceByName(res.data, 'AllowedVoultCurrencies');
        let vaultWallets = returnResourceByName(res.data, 'VaultWallets');
        let hasVaultAccess = returnResourceByName(res.data, 'HasVaultAccess');
        let rewardTotalData = returnResourceByName(res.data, 'VaultTotalData');
        let rewardStatuses = returnResourceByName(res.data, 'RewardStatuses');
        let selectedCurrency = to.params.vaultId
        let allowedCurrency = allowedVaultCurrencies && allowedVaultCurrencies.includes(selectedCurrency)

        if (
            !allowedCurrency
            || (!rewardTotalData || rewardTotalData && (typeof rewardTotalData !== 'object' || _.isEmpty(rewardTotalData)))
            || (!hasVaultAccess || hasVaultAccess && hasVaultAccess.length && !hasVaultAccess[0])
        ) {
            redirectToDashboard()
            return false

        }

        store.dispatch("dashboard/setRewardStatuses", rewardStatuses)
        store.dispatch("dashboard/setRewardTotalData", rewardTotalData)
        store.dispatch("dashboard/setVaultWallets", vaultWallets).then(() => {
            setSelectedVoultFunction(store, to, next)
        })
            .catch((error) => {
                console.log(error)
                redirectToDashboard()
            });

    }).catch(error => {
        console.log(error)
        next(false);
    });
}

export function getWalletsAndSetActiveOne(store, to, from, next) {
    if (!store.getters['dashboard/wallets'].length) {
        store.dispatch('initialization/__initialize', {
            url: `/initialization`,
            resources: [
                'AllWallets',
            ]
        }).then(res => {

            let data = returnResourceByName(res.data, 'AllWallets');
            let walletId = to.params.walletId
            if (walletId) walletId = parseInt(walletId);
            let walletExist = data && data.find(w => w.id === walletId)

            if (!data || (data && !data.length) || !walletExist) {
                redirectToDashboard()
                return false
            }

            store.dispatch("dashboard/setAllWallets", data)
                .then(() => {
                    setSelectedWalletFunction(store, to, next)
                })
                .catch((error) => {
                    console.log(error)
                });
        }).catch(error => {
            console.log(error)
        });
    } else {
        setSelectedWalletFunction(store, to, next)
    }
}

export function getAllWalletsAndCheck(store, to, from, next) {
    if (!store.getters['dashboard/wallets'].length) {
        store.dispatch('initialization/__initialize', {
            url: `/initialization`,
            resources: [
                'AllWallets',
            ]
        }).then(res => {

            let data = returnResourceByName(res.data, 'AllWallets');

            let walletId = parseInt(to.params.walletId);

            let walletExist = data && data.find(w => w.id === walletId)
            if (!data || (data && !data.length) || !walletExist) {
                redirectToDashboard()
                return false
            }
            store.dispatch("dashboard/setAllWallets", data)
                .then(() => {
                    return store.dispatch("dashboard/getWalletStatementData", walletExist).then(() => {
                        setSelectedWalletFunction(store, to, next)
                    })
                        .catch((error) => {
                            redirectToDashboard()
                        });

                }).then(() => {
                setSelectedWalletFunction(store, to, next)
            })
                .catch((error) => {
                    redirectToDashboard()
                });

        }).catch(error => {
            next(false);
        });
    } else {
        getWalletStatementData(store, to, next, {id: to.params.walletId})
        setSelectedWalletFunction(store, to, next)
    }
}
