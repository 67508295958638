<!-- @format -->

<template>
    <v-app class="new-wallet-app">
        <v-main class="tm-bg-body">
            <div class="container-fluid new-wallet-layout">
                <!--Navigation-->
                <NewNavigation class="p-0" :newMixin="false"></NewNavigation>
                <!--Content Box-->
                <div class="vue-repo-app">
                    <div class="row main-page-row">
                        <router-view v-bind="pageData"></router-view>
                    </div>
                </div>
            </div>

            <!-- ============================= MODALS ============================== -->

            <!-- KYC status note dialog -->
            <KYCStatusDialog></KYCStatusDialog>

            <!--User Verifying Modal-->
            <user-verified/>

            <!--Sign termination modal Modal-->
            <sing-termination v-if="$store.state.signedModal"/>

            <!--Network error alert-->
            <network-error/>

            <!--Confirmation alert-->
            <confirmation type="user_password"/>
            <confirmation2fa :closeIconShow="false"/>
            <confirmation-upgrade :closeIconShow="false"/>

            <!-- Contact Form -->
            <ContactDialog v-if="contactModal" v-model="contactModal"/>

            <!-- Payment Suspension -->
            <payments-suspension></payments-suspension>

            <!-- 2fa form -->
            <security2fa-form></security2fa-form>

            <v-snackbar v-model="alert.value" :color="alert.type" left shaped bottom>
                <div class="d-flex justify-space-between align-center">
                    <span>{{ alert.message }}</span>
                    <v-btn icon small @click="$store.commit('closeAlert')">
                        <v-icon small>mdi-close</v-icon>
                    </v-btn>
                </div>
            </v-snackbar>

            <WlBaseModal v-model="alertModal.value" class-names="custom-modal modal-md" width="25rem">
                <ModalInfo 
                    v-model="alertModal.value"
                    :modal-data="alertModal.data"
                    @on-confirm="alertModal.value = false, alertModal.onClose()"
                />
            </WlBaseModal>

            <!-- Wallet Currency Doc Verified-->
            <template v-if="walletCurrencyDocVerified">
                <wallet-currency-doc-verified/>
            </template>

            <!--Card Update Modal-->
            <card-form v-model="cardUpdateDialog" :card="updateCard" :show-primary="false"/>

            <!--Greeting Steps-->
            <greeting
                v-if="$store.state.greetingSteps && !$store.state.settings.verify"
                :job-to-apply="$store.state.jobToApply"
            />


            <!--Welcome Screen for Employer-->
            <employer-welcome
                v-if="displayCreateOrganizationModal($store.state.worker) && !isBusinessAccount"
                :showModal="!$store.state.employmentWelcomeRedirect"
                :value="employerOnboarding"
            ></employer-welcome>

            <!--Welcome Screen for Invitee-->
            <invitee-welcome
                v-if="($store.state.regularInviteeWelcome || $store.state.eorInviteeWelcome) && (!verifyDialog || !$store.state.settings.verify) "
                :eor="$store.state.eorInviteeWelcome"
            />

            <!--Reaching Next Plan Tier Modal-->
            <ModalAccountLimit v-if="__isLatestPayments && $store.state.workerBalance && $store.state.workerBalance.limit_reached"/>

            <!--Payment Request Transfer-->
            <payment-request
                v-if="$store.state.paymentRequestTransfer"
                :value="$store.state.paymentRequestTransfer"
                @input="$store.commit('openPaymentRequestTransfer', $event)"
            />

            <!-- Holiday notify modal -->
            <holiday-notify :value="!!holidayModal"></holiday-notify>
        </v-main>
    </v-app>
</template>

<script>

import {mapActions, mapState, mapGetters} from "vuex";

import PaymentRequest from '~/shared/components/PaymentRequest';
import Helpers from '~/shared/mixins/Helpers';
import Cookie from '~/shared/mixins/Cookie';
import Screen from '~/shared/mixins/Screen';

import CardForm from '~/modules/dashboard/components/modals/CardForm';
import Greeting from '~/modules/dashboard/components/modals/Greeting/Greeting';
import NetworkError from '~/modules/dashboard/components/modals/NetworkError';
import UserVerified from '~/modules/dashboard/components/modals/UserVerified/UserVerified';
import InviteeWelcome from '~/modules/dashboard/components/modals/Welcome/InviteeWelcome';
import SingTermination from '~/modules/dashboard/views/SingTermination';
import WalletCurrencyDocVerified from '~/modules/dashboard/views/WalletCurrencyDocVerified';
import Confirmation from '~/shared/components/Confirmation';
import Confirmation2fa from '~/shared/components/Confirmation2fa';
import ConfirmationUpgrade from '~/shared/components/ConfirmationUpgrade';
import HolidayNotify from "~/modules/dashboard/components/modals/HolidayNotify";
import ModalInfo from "~/modules/wallet/components/Modals/ModalInfo.vue";
import ContactDialog from '~/components/Contact/ContactDialog';
import PaymentsSuspension from "~/modules/dashboard/components/modals/UserVerified/UserRestriction.vue";
import Security2faForm from "~/modules/dashboard/components/modals/Security2faForm.vue";
import KYCStatusDialog from "~/modules/dashboard/components/modals/UserVerified/KYCStatusDialog";
import EmployerWelcome from '~/modules/dashboard/components/modals/Welcome/Employer/EmployerWelcome';

import ModalAccountLimit from "~/components/UpgradeFlow/Modals/ModalAccountLimit.vue";
import NewNavigation from "~/components/NewNavigation/NewNavigation.vue";
export default {
    name: "DashboardApp",
    components: {
        ModalAccountLimit,
        KYCStatusDialog,
        PaymentsSuspension,
        HolidayNotify,
        NetworkError,
        UserVerified,
        PaymentRequest,
        WalletCurrencyDocVerified,
        InviteeWelcome,
        Greeting,
        CardForm,
        SingTermination,
        Confirmation,
        Confirmation2fa,
        ConfirmationUpgrade,
        ModalInfo,
        ContactDialog,
        Security2faForm,
        NewNavigation,
        EmployerWelcome
    },
    mixins: [Screen, Helpers, Cookie],
    props: {
        pageData: {
            default: () => {}
        }
    },
    data() {
        return {
            verifyDialog: true,
            cardUpdateDialog: false,
            updateCard: null,
            appendActions: {
                checked: false,
                approved: false,
            },
            filters: {
                checked: false,
                approved: false,
            },
        };
    },
    computed: {
        employerOnboarding(){
            return (
                (!!this.$store.state.primaryWorker.first_seat_worker_id || this.$store.state.addOrg) &&
                (!this.verifyDialog || !this.$store.state.settings.verify)
            );
        },
        ...mapState('cardService', {
            customer_status: 'customer_status'
        }),
        ...mapGetters('contactsDialog', {
            showContactDialog: 'showContactDialog'
        }),
        contactModal: {
            get() {
                return this.showContactDialog;
            },
            set() {
            },
        },
        alert() {
            return this.$store.state.alert;
        },
        alertModal() {
            return this.$store.state.alertModal;
        },
        walletCurrencyDocVerified() {
            return this.$store.state.walletCurrencyDocVerified;
        },
        holidayModal() {
            return this.$store.state.holidayModal ?? false;
        },
        paymentRequestExist() {
            return this.$store.state.paymentRequestExist;
        }
    },
    watch: {
        verifyDialog(v) {
            if (!v) {
                const data = {...this.$store.state.settings, verify: false};
                this.$store.commit('setData', {key: 'settings', data});
            }
        },
    },
    methods: {
        ...mapActions('cardService', ['checkCustomerStatus']),
    },
    mounted() {
        let payload = {
            service: 'unlimint',
            organization_id: this.$store.state.worker.organization_id ?? null
        };
        this.checkCustomerStatus(payload);
    },
};
</script>

<style scoped lang="scss">
.new-wallet-layout {
    margin-top: 30px;
    padding: 0 44px;

    .new-navigation-bar-class {
        margin-bottom: 32px;
    }
}

.main-page-row > section {
    width: 100%;
}

@media screen and (max-width: 1024px) {
    .new-wallet-layout {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 992px) {
    .new-wallet-layout {
        .new-navigation-bar-class {
            margin-bottom: 24px;
        }
    }
}

@media screen and (max-width: 640px) {
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }

    .new-wallet-layout {
        padding-left: 20px;
        padding-right: 20px;
    }
}
</style>
