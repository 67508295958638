<template>
    <li
        class="wizard-step"
        :class="{
            completed: number + 1 < currentStep,
            active: number + 1 === currentStep,
        }"
    >
        <span class="wizard-step-number" :class="customWizardStepNumberClasses">
            <span class="number">{{ number + 1 }}</span>
            <BaseIcon name="check-extra-thin" class="color-white" style="padding-left: 1px;" />
        </span>
        <p class="step-title text-sm font-medium text-center" :class="customStepTitleClasses">
            {{ step }}
        </p>
    </li>
</template>

<script>
export default {
    name: "NavbarWizardStep",

    props: {
        step: {
            type: String,
            required: true,
        },
        currentStep: {
            type: Number,
            required: true,
        },
        number: {
            type: Number,
            required: true,
        },
        customStepTitleClasses: {
            type: String,
            default: '',
        },
        customWizardStepNumberClasses: {
            type: String,
            default: '',
        },
    },
};
</script>
