<template>
    <div class="d-flex flex-column" :class="bottomOffset? 'mb-4': 'mb-0'" style="position: relative">
        <label v-if="label" :for="id" class="tm-small-text font-weight-medium black--text mb-1">{{label}}</label>
        <div
            class="d-flex align-center tm-input"
            :class="errorMessages.length? 'tm-err': null"
            :style="!border? {border: 'none!important'}: {}"
        >
            <input
                :disabled="disabled"
                v-mask="'##/##'"
                v-model="val"
                autocomplete="off"
                :id="id"
                class="w-100 tm-color-black mr-2"
                :placeholder="placeholder"
            >
            <v-btn v-if="clearable && val" @click="val = null" x-small icon class="mr-2">
                <icon size="12" name="icon-times"></icon>
            </v-btn>
            <icon v-if="showIcon" size="16" name="icon-date" :color="disabled? '#dadfe6': '#5152fb'" class="mr-2"></icon>
        </div>
        <error-message :messages="errorMessages"></error-message>
    </div>
</template>
<script>
import ErrorMessage from "./ErrorMessage";

export default {
    name: 'TmSelectCardDate',
    components: {ErrorMessage},
    props: {
        id: {
            default: null
        },
        placeholder: {
            default: 'mm/yy'
        },
        label: {
            default: null
        },
        showIcon: {
            default: true
        },
        value: {
            default: null
        },
        bottomOffset: {
            default: true
        },
        border: {
            default: true
        },
        clearable: {
            default: false
        },
        disabled: {
            default: false
        },
        errorMessages: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data(){
        return {
            val: this.value,
        }
    },
    watch: {
        val(){
            this.$emit('input', this.val);
        },
        value(){
            this.val = this.value;
        },
    },
}
</script>
