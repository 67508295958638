<template>
    <v-btn
        class="btn-new"
        :class="`btn-${this.type} btn-${this.size}`"
        v-on="$listeners"
        :disabled="disabled"
        :loading="loading"
        :ripple="ripple"
        :to="to"
        :href="href"
        :large="size === 'lg'"
    >
        <slot name="default"></slot>
    </v-btn>
</template>

<script>
export default {
    name: "WlBaseButton",

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        ripple: {
            type: Boolean,
            default: false,
        },
        to: {
            type: [String, Object],
        },
        type: {
            type: String,
            default: "",
        },
        size: {
            type: String,
            default: "md",
        },
        href: {
            default: null
        }
    },
};
</script>
