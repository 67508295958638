import {mapActions, mapGetters, mapState} from "vuex";

export default {
    props: {

    },
    data() {
        return {}
    },
    computed:{
        ...mapGetters('dashboard', ['availableWallets','legalEntityWallets','selectedMetric', 'selectedWallet']),
        ...mapState('globalMenuModule', ['legalEntityData']),
        nameSlice() {
            if (!this.legalEntityData){
                return false;
            }

            if (this.legalEntityData?.legal_entity_details?.company_name.length > 19 && this.isScreenBiggerThan1024) {
                return this.legalEntityData.legal_entity_details.company_name.slice(0, 13) + "...";
            }

            if (this.legalEntityData?.legal_entity_details?.company_name.length > 22  && this.isScreenBiggerThan1024) {
                return this.legalEntityData.legal_entity_details.company_name.slice(0, 19) + "...";
            }

            if (this.legalEntityData?.legal_entity_details?.company_name.length > 17 && !this.isSmallTablet) {
                return this.legalEntityData.legal_entity_details.company_name.slice(0, 14) + "...";
            }

            if (
                this.legalEntityData?.legal_entity_details?.company_name.length > 20 &&
                this.isSmallTablet &&
                !this.isLargeMobile
            ) {
                return this.legalEntityData?.legal_entity_details?.company_name.slice(0, 17) + "...";
            }

            if (this.legalEntityData?.legal_entity_details?.company_name.length > 15 && this.isLargeMobile) {
                return this.legalEntityData.legal_entity_details.company_name.slice(0, 12) + "...";
            }

            return false;
        },
        walletDetailsPage(){
            return this.$route?.name === 'WalletDetails';
        }

    },
    methods: {
        ...mapActions('dashboard', [
            'setWalletSwitcher',
            'setMetricData',
            'setSelectedWallet',
            'setSelectedWalletEmpty',
            'setGettingPaginationData',
            'getMetricData',
        ]),
        ...mapActions('globalMenuModule',['setBasicMenu']),
        setActiveWallet(type) {
            const dashboardModule = this.moduleExists('dashboard/');
            if(dashboardModule){
                this.setSelected(type)
                if(this.walletDetailsPage){
                    return this.$router.push({name: 'WalletOverview'})
                }

            }else{
                this.setBasicMenu(false)
            }


        },
        moduleExists(moduleName) {
            return Boolean(this.$store._modulesNamespaceMap[moduleName]);
        },
        setSelected(type){
            this.setWalletSwitcher(type);
            let selectedWallet;
            this.setGettingPaginationData(true)
            if (type === 'legalEntity') {
                selectedWallet = this.legalEntityWallets?.find(item => item.is_solo_entity);
                if(selectedWallet){
                    this.setSelectedWallet(selectedWallet.id);
                    this.$store.dispatch('dashboard/getWalletTransactions');
                    this.setBasicMenu(false)
                }

            } else {
                selectedWallet = this.availableWallets.find(item => item.currency.code === 'EUR' || item.currency.code === 'USD')
                if(!this.walletDetailsPage) {
                    this.setSelectedWalletEmpty();
                }

                this.$store.dispatch('dashboard/getWalletTransactions');
                this.setBasicMenu(true)
            }
            if(selectedWallet){
                console.log(selectedWallet)
                this.setMetricData({type: this.selectedMetric.label, amount: selectedWallet.available_balance});
                this.getMetricData({currency: selectedWallet.currency?.code})
            }
        }
    },
    created() {
        if(this.walletDetailsPage){
          if(this.selectedWallet?.is_solo_entity){
              this.setSelected('legalEntity')
          }
        }
    }
}
