<template>
    <BaseMenu
        v-bind="menuProps"
        :content-class="menuClasses"
        v-model="menu"
        :left="false"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        :max-width="fullWidth ? width : 'auto'"
        :width="fullWidth ? '100%' : ''"
        :min-width="!fullWidth ? 'auto' : ''"
        :attach="attach"
    >
        <template v-slot:activator="{ on, attrs }">
            <div ref="input" v-if="isYearOnly" @click="openMenu()">
                <BaseIcon name="calendar" class="mr-2" />
                <BaseInput
                    :label="label"
                    :labelClassVal="labelClassVal"
                    :class="classNames"
                    :placeholder="placeholder"
                    readonly
                    :value="formatYear"
                    v-bind="attrs"
                    v-on="on"
                    @blur="$emit('blur')"
                    :error-messages="[...localErrorMessages, ...errorMessages]"
                >
                    <template v-slot:label>
                        <slot name="label"></slot>
                    </template>
                </BaseInput>
            </div>
            <div v-else-if="isMonthOnly" v-on="on" v-bind="attrs">
                <BaseInput
                    ref="input"
                    :label="label"
                    :labelClassVal="labelClassVal"
                    :class="classNames"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    readonly
                    :value="formatMonth"
                    v-bind="attrs"
                    v-on="on"
                    @blur="$emit('blur')"
                    :error-messages="[...localErrorMessages, ...errorMessages]"
                >
                    <template v-slot:label>
                        <slot name="label"></slot>
                    </template>

                    <template v-slot:append-outer>
                        <BaseIcon
                            name="chevron-down"
                            class="color-purple no-pointer-events"
                        />
                        <BaseIcon
                            name="chevron-up"
                            class="color-purple no-pointer-events"
                        />
                    </template>
                </BaseInput>
            </div>
            <div v-else v-on="on" v-bind="attrs">
                <BaseInput
                    ref="input"
                    :label="label"
                    :labelClassVal="labelClassVal"
                    :class="classNames"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    :readonly="!isInput"
                    v-mask="'##/##/####'"
                    v-model="inputVal"
                    v-bind="$attrs"
                    @blur="$emit('blur')"
                    :error-messages="[...localErrorMessages, ...errorMessages]"
                    name="date"
                >
                    <template v-slot:label>
                        <slot name="label"></slot>
                    </template>

                    <template v-slot:append-outer>
                        <BaseIcon
                            name="calendar"
                            class="color-purple pointer-events-auto"
                        />
                    </template>
                </BaseInput>
            </div>
        </template>
        <v-date-picker
            :class="!roundedButtons ? 'non-rounded' : ''"
            :type="type"
            v-model="val"
            :active-picker.sync="activePicker"
            no-title
            scrollable
            :min="calcMin"
            :max="calcMax"
            prev-icon="icon-chevron-left"
            next-icon="icon-chevron-right"
            :reactive="isYearOnly"
            @input="menu = false"
            :max-width="fullWidth ? width : 'auto'"
            :width="fullWidth ? '100%' : '290'"
            style="width: 100%"
            :show-current="customDefaultDate ? customDefaultDate : true"
            v-bind="disabledDates.length ? { 'allowed-dates': getAllowedDates } : {}"
            full-width
            :disabled="disabled"
        ></v-date-picker>
    </BaseMenu>
</template>

<script>
import {
    format,
    parseISO,
    parse,
    isMatch,
    isValid,
    isEqual,
    isAfter,
    isBefore,
    subDays,
    addDays,
} from "date-fns";

export default {
    name: "BaseInputDate",

    props: {
        label: {
            type: String || null,
            default: null,
        },
        labelClassVal: {
            type: String | null,
            default: null,
        },
        value: {
            type: String || null,
            default: null,
        },
        placeholder: {
            type: String || null,
            default: "Select date",
        },
        errorMessages: {
            default: () => [],
        },
        min: {
            default: null,
        },
        max: {
            default: null,
        },
        defaultToday: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "date",
        },
        isYearOnly: {
            type: Boolean,
            default: false,
        },
        classNames: {
            type: [Object, String, Array],
            default: () => [],
        },
        fullWidth: {
            type: Boolean,
            default: true,
        },
        roundedButtons: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        menuProps: {
            type: Object,
            default: () => {},
        },
        isInput: {
            type: Boolean,
            default: false,
        },
        customDefaultDate: {
            default: false,
        },
        disabledDates: {
            type: Array,
            default: () => [],
        },
        attach: {
            default: false,
        },
        customWidth: {
            type: [String, Number],
            default: null,
        },
    },

    data() {
        return {
            activePicker: null,
            today: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
            menu: false,
            val: this.value,
            inputVal: this.value
                ? format(parseISO(this.value), "dd/MM/yyyy")
                : this.value,
            width: 290,
            valid: true,
            messages: {
                valid: "Please follow the format dd/mm/yyyy",
            },
        };
    },

    computed: {
        localErrorMessages() {
            return [!this.valid ? this.messages.valid : null].filter((i) => !!i);
        },
        formatYear() {
            if (this.isYearOnly) {
                if (this.value.length > 4) {
                    const tempYear = this.value.slice(0, -8) + "-01-01";
                    return format(parseISO(tempYear), "yyyy");
                }
                const tempYear = this.value + "-01-01";
                return format(parseISO(tempYear), "yyyy");
            }
            return "Select date";
        },

        formatMonth() {
            const tempMonth = this.value + "-01";
            return format(parseISO(tempMonth), "MMMM yyyy");
        },

        isMonthOnly() {
            return this.type === "month";
        },

        calcMin() {
            return this.getBorderDate(this.min);
        },

        calcMax() {
            return this.getBorderDate(this.max);
        },
        menuClasses() {
            return `${this.menuProps?.contentClass} vue-repo-app`;
        },
    },

    watch: {
        menu(menuVal) {
            this.isYearOnly &&
            menuVal &&
            setTimeout(() => (this.activePicker = "YEAR"));

            if (this.customWidth) {
                this.width = parseInt(this.customWidth);
            } else {
                if (typeof this.$refs.input === "object") {
                    this.width = this.$refs.input.$el.clientWidth;
                } else {
                    this.width = this.$refs.input.clientWidth;
                }
            }

            if (!menuVal) {
                this.activePicker = "DATE";
            }
        },
        val() {
            if (
                this.val &&
                (!this.inputVal ||
                    !isEqual(
                        parse(this.inputVal, "dd/MM/yyyy", new Date()),
                        parseISO(this.val)
                    ) ||
                    !this.isInput)
            ) {
                this.inputVal = format(parseISO(this.val), "dd/MM/yyyy");
            }else if (!this.val && this.inputVal){
              this.inputVal = null;
            }
            this.$emit("input", this.val);
        },
        inputVal(val) {
            if (!this.isInput) return false;
            let valid = false;
            let parsedDate = parse(this.inputVal, "dd/MM/yyyy", new Date());
            if (isMatch(this.inputVal, "dd/MM/yyyy") && isValid(parsedDate)) {
                valid =
                    !(this.min && !isAfter(parsedDate, subDays(parseISO(this.min), 1))) &&
                    !(this.max && !isBefore(parsedDate, addDays(parseISO(this.max), 1)));
            }
            this.valid = val ? valid : true;
            this.val = valid ? format(parsedDate, "yyyy-MM-dd") : null;
        },
        value() {
            if (this.isYearOnly) {
                this.val = this.formatYear;
            } else {
                this.val = this.value;
            }
        },
    },

    methods: {
        setDateParams(value) {
            if (!value) return null;
            if (value.search("T")) {
                value = value.split("T")[0];
            }
            const [year, month, day] = value.split("-");
            return `${year ? year + "-" : "1970-"}${month ? month + "-" : "01-"}${
                day || "01"
            }`;
        },

        getBorderDate(value) {
            return value === "today"
                ? new Date().toISOString().split("T")[0]
                : this.setDateParams(value);
        },

        openMenu() {
            this.menu = true;
        },
        getAllowedDates(date) {
            return !this.disabledDates.includes(date);
        }
    },

    mounted() {
        this.val = !this.value && this.defaultToday ? this.today : this.value;
    },
};
</script>
